import { Component, Input } from '@angular/core';

@Component({
  selector: 'xen-score-card',
  templateUrl: './score-card.component.html',
  styleUrls: ['./score-card.component.scss'],
})
export class ScoreCardComponent {
  @Input() card_title: string;
  @Input() tooltip: boolean;
  @Input() tooltip_text: string;
  @Input() icon: string;
  @Input() num_one: boolean;
  @Input() num_one_class: string;
  @Input() num_one_value: number;
  @Input() num_one_value_perc: boolean;
  @Input() num_two_value: number;
  @Input() num_two_value_perc: boolean;
  @Input() num_two_add: boolean;
  @Input() num_two_value_add: number;
  @Input() num_two_add_value_perc: boolean;
  @Input() num_two_class: string;
  @Input() num_three: boolean;
  @Input() num_three_value: number;
  @Input() num_three_value_perc: boolean;
  @Input() num_three_text: string;
}
