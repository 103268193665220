import { BillingContractModalComponent } from './../contract-modal/contract-modal.component';
import { Table } from 'primeng/table';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Contract, Partner, BillingAccount } from '../../../../../interfaces/_index.billing.interfaces';
import {
  PartnerService,
  ContractService,
  BillingAccountService,
  CommercialAgreementService,
} from '../../../../../services/_index-billing.services';
import { NotificationService, WebAdminService } from './../../../../../../../@core/services/_index-core.services';
import { OrganizationSelector } from './../../../../../../entities/interfaces/_index-entities.interfaces';
import { saveAs } from 'file-saver';

@Component({
  selector: 'tgx-admin-billing-contracts-table',
  templateUrl: './contracts-table.component.html',
  styleUrls: ['./contracts-table.component.scss'],
})
export class BillingContractsTableComponent implements OnInit, OnDestroy {
  @ViewChild('dt', { static: false }) dataTable!: Table;
  $subs: Subscription[] = [];

  header = [
    { label: 'ID', field: 'id' },
    { label: 'Currency', field: 'currency' },
    { label: 'CheckinDate', field: 'bookCheckinNorDate' },
    { label: 'ActivationDate', field: 'activationDateTime' },
    { label: 'End date', field: 'endDate' },
    { label: 'Type', field: 'partnerType' },
    { label: 'SubType', field: 'partnerSubType' },
    { label: 'Model Type', field: 'modelType' },
    { label: 'Rate Type', field: 'rateType' },
    { label: 'Options', field: 'Options' },
  ];

  isLoading: boolean;

  orgSelected: OrganizationSelector;
  partner: Partner;

  isEditor = false;

  contractsLoadSource: any[];

  mapContractAgreement: Map<number, number>;

  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private webAdminService: WebAdminService,
    private partnerService: PartnerService,
    private contractService: ContractService,
    private billingAccountService: BillingAccountService,
    private commercialAgreementService: CommercialAgreementService,
  ) {}

  ngOnInit(): void {
    this.buildOptions();

    this.isLoading = false;

    this.$subs.push(
      this.webAdminService.orgSelected.subscribe(async (orgSelected) => {
        if (orgSelected?.code && orgSelected.code !== 'web-admin') {
          this.orgSelected = orgSelected;
          this.mapContractAgreement = new Map<number, number>();
          await this.getAgreements();
          await this.partnerService.getPartner(orgSelected.code).then((partner) => {
            if (partner) {
              this.partner = partner;
              this.getContracts(this.partner.orgCode);
            } else {
              this.partner = null;
            }
          });
        }
      }),
    );
  }

  ngOnDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
  }

  buildOptions() {
    this.isEditor = this.webAdminService.isBillingEditorOrAdmin();
  }

  getContracts(orgCode: string) {
    this.isLoading = true;
    this.contractsLoadSource = [];

    if (this.dataTable) {
      this.dataTable.clearFilterValues();
    }

    if (orgCode) {
      this.contractService
        .getContracts(orgCode)
        .then((cnts) => {
          if (cnts.length > 0) {
            cnts.forEach((cnt) => {
              this.contractsLoadSource.push({
                'id': cnt.id,
                'currency': cnt.currency,
                'bookCheckinNorDate': cnt.bookCheckinNorDate,
                'activationDateTime': cnt.activationDateTime,
                'endDate': cnt.endDate,
                'partnerType': cnt.partnerType,
                'partnerSubType': cnt.ContractType?.name,
                'modelType': cnt.modelType,
                'rateType': cnt.rateType,
                // Not showed in table but show them in the add/edit modal
                'lookToBook': cnt.lookToBook,
                'excess': cnt.excess,
                'enabled': cnt.enabled,
                'reference': cnt.reference,
                'minimumFee': cnt.minimumFee,
                'minimumUnit': cnt.minimumUnit,
                'manualInvoice': cnt.manualInvoice,
                'partiallyInvoiced': cnt.partiallyInvoiced,
                'lookToBookCheckinNorDate': cnt.lookToBookCheckinNorDate,
                'billingAccountId': cnt.BillingAccount?.id,
                'reviewFeeDate': cnt.reviewFeeDate,
                'expirationDays': cnt.expirationDays,
                'remarks': cnt.remarks,
                'supexc': cnt.suppliersExcluded,
                'defaultPaymentType': cnt.defaultPaymentType,
              });
            });
            this.contractsLoadSource = [...this.contractsLoadSource];
            this.contractsLoadSource.sort((a, b) => (a.id < b.id ? 1 : -1));
          }

          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
          this.isLoading = false;
        });
    }
  }

  getFilterType(field: string) {
    switch (field) {
      case 'bookCheckinNorDate':
      case 'excess':
        return 'boolean';
      default:
        return 'text';
    }
  }

  async onCreate(): Promise<void> {
    if (!this.partner) {
      this.notificationService.warning(
        'This organization has not partner in billing section, first create it and then you will be able to manage its contracts.',
      );
      return;
    }

    let billingAccounts: BillingAccount[];
    await this.billingAccountService.getBillingAccounts(this.partner.orgCode).then((bas) => {
      billingAccounts = bas;
    });

    if (billingAccounts?.length === 0) {
      this.notificationService.warning(
        'This partner has not any billing account, first create it and then you will be able to manage its contracts.',
      );
      return;
    }

    const activeModal = this.modalService.open(BillingContractModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = 'Create Contract';
    activeModal.componentInstance.partnerId = this.partner.id;
    activeModal.componentInstance.billingAccounts = billingAccounts;
    activeModal.componentInstance.isEditor = this.isEditor;

    const self = this;
    activeModal.result.then((res) => {
      // OK
      if (typeof res === 'object') {
        self.getContracts(self.partner.orgCode);
      }
    });
  }

  async onEdit(event): Promise<void> {
    const contract: Contract = event;

    let billingAccounts: BillingAccount[];
    await this.billingAccountService.getBillingAccounts(this.partner.orgCode).then((bas) => {
      billingAccounts = bas;
    });

    const activeModal = this.modalService.open(BillingContractModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = (this.isEditor ? 'Edit ' : 'View ') + 'Contract';
    activeModal.componentInstance.contract = contract;
    activeModal.componentInstance.partnerId = this.partner.id;
    activeModal.componentInstance.billingAccounts = billingAccounts;
    activeModal.componentInstance.isEditor = this.isEditor;

    const self = this;
    activeModal.result.then((res) => {
      // OK
      if (typeof res === 'object') {
        self.getContracts(self.partner.orgCode);
      }
    });
  }

  async getAgreements() {
    await this.commercialAgreementService
      .getAgrementsByPartner(this.orgSelected?.code)
      .then((rs) => {
        if (rs?.response) {
          rs.response?.forEach((agr) => {
            this.mapContractAgreement.set(agr.ContractId, agr.ID);
          });
        }
      })
      .catch((err) => {
        this.notificationService.handleError(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  async onDownload($event) {
    let agreementId = 0;

    if (this.mapContractAgreement.has($event.data.id)) {
      agreementId = this.mapContractAgreement.get($event.data.id);
    }

    if (agreementId > 0) {
      await this.commercialAgreementService
        .downloadPdfTemplate('agreement', agreementId)
        .then((rs) => {
          if (rs.response?.base64) {
            const byteArray = Uint8Array.from(
              atob(rs.response?.base64)
                .split('')
                .map((char) => char.charCodeAt(0)),
            );

            const blob = new Blob([byteArray], { type: 'application/pdf' });
            saveAs(blob, rs.response.fileName);
          }
        })
        .catch((err) => {
          this.notificationService.handleError(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.notificationService.warning('This contract has not any agreement uploaded right now.');
    }
  }

  filter(value: any, field: string) {
    if (value) {
      this.dataTable.filter(value, field, 'contains');
    }
  }
}
