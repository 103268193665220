import { gql } from 'apollo-angular';

export const getAccesses = gql`
  query ($groups: [ID!]) {
    admin {
      accesses(filter: { group: $groups }) {
        edges {
          node {
            accessData {
              name
              isActive
              code
              isTest
              owner {
                code
              }
            }
            error {
              code
              type
              description
            }
          }
        }
      }
    }
  }
`;
