import { AdviseMessage, Organization } from './../_index-entities.interfaces';
import { Supplier } from './suppliers.interface';

export interface PageInfo {
  endCursor: string;
  hasNextPage: boolean;
}

export interface Parameters {
  key: string;
  value: string;
}

export interface Urls {
  search?: string;
  quote?: string;
  book?: string;
  generic?: string;
}

export interface Groups {
  edge: {
    node: {
      code: string;
    };
  };
}

export interface AccessData {
  id?: number;
  code?: string;
  name?: string;
  isActive?: Boolean;
  isTest?: Boolean;
  isShared?: Boolean;
  isSchedulerActive?: Boolean;
  owner?: Organization;
  groups?: Groups;
  supplier?: Supplier;
  user?: string;
  password?: string;
  urls?: Urls;
  parameters?: [Parameters];
  markets?: string;
  master?: {
    code: string;
  };
  shared: {
    code: string;
  };
  rateRules?: string;
  descriptiveInfoLimit?: string;
  updateList?: string;
  updateDateRange?: string;
  updateDescriptiveInfo?: number;
}
export interface Access {
  accessData?: AccessData;
  adviseMessage?: [AdviseMessage];
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export interface AccessEdge {
  node?: Access;
  cursor?: String;
}

export interface AccessConnection {
  totalCount?: number;
  pageInfo?: PageInfo;
  edges?: AccessEdge[];
}
