import { gql } from 'apollo-angular';

export const deleteInvoice = gql`
  mutation DeleteInvoice($where: InvoiceWhereUniqueInput!) {
    deleteInvoice(where: $where) {
      id
      amount
      createdAt
      startDate
      endDate
    }
  }
`;
