import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'xen-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {
  @Input() page: number;
  @Output() pageChange = new EventEmitter<number>();

  @Input() pageSize: number;
  @Input() maxSize: number;
  @Input() collectionSize: number;
  @Input() autoHidden: boolean;
  @Input() screenScroll?: boolean;
  @Input() isDisabled: boolean;

  hasHidden: boolean;

  ngOnChanges(): void {
    this.hasHidden = this.autoHidden && (!this.collectionSize || this.collectionSize <= this.pageSize);
  }

  onPageChange(page): void {
    if (this.screenScroll === undefined || this.screenScroll === true) {
      window.scroll(0, 0);
    }

    this.pageChange.emit(page);
  }
}
