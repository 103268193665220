import { ReplaySubject, Subject, firstValueFrom, of } from 'rxjs';

declare type GetDataHandler<T> = () => Promise<T>;

export class Cacheable<T> {
  protected subjectData: Subject<T>;
  public data: T;
  public getHandler: GetDataHandler<T>;

  constructor() {
    this.subjectData = new ReplaySubject(1);
  }

  public async getData(): Promise<T> {
    if (!this.getHandler) {
      throw new Error('getHandler is not defined');
    }
    if (!this.data) {
      try {
        this.data = await this.getHandler();
        this.subjectData.next(this.data);
      } catch (err) {
        console.log(err);
      }
    }
    return firstValueFrom(of(this.data));
  }

  public resetCache(): void {
    this.data = null;
  }

  public refresh(): void {
    this.resetCache();
    this.getData();
  }
}
