<nb-card>
  <nb-card-header>
    <div class="row">
      <div class="org-container">
        <h3>Organizations</h3>
        <h1 class="small-text">({{ totalOrgs }})</h1>
      </div>
      <div class="d-flex justify-content-end">
        <form [formGroup]="queryForm">
          <div class="d-flex align-items-center">
            <span class="p-2">Show archived</span>
            <nb-toggle [checked]="false" formControlName="includeArchived" (change)="initTable()"></nb-toggle>
          </div>
        </form>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <p-table
      #dt1
      [value]="allOrgs"
      [paginator]="true"
      styleClass="p-datatable-gridlines p-datatable-striped"
      [rows]="10"
      [showCurrentPageReport]="true"
      [tableStyle]="{ 'min-width': '50rem' }"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
    >
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="organization">
            Organization
            <p-sortIcon field="organization"></p-sortIcon>
          </th>
          <th pSortableColumn="owner.ownerField">
            Owner
            <p-sortIcon field="owner.ownerField"></p-sortIcon>
          </th>
          <th pSortableColumn="ownerMember">
            Owner member
            <p-sortIcon field="ownerMember"></p-sortIcon>
          </th>
          <th>Test</th>
          <th>Blacklisted</th>
          <th>Archived</th>
          <th>Options</th>
        </tr>
        <tr>
          <th>
            <p-columnFilter
              type="text"
              field="organization"
              matchMode="contains"
              (input)="dt1.filter($event.target.value, 'organization', 'contains')"
            ></p-columnFilter>
          </th>
          <th>
            <p-columnFilter
              type="text"
              field="owner.ownerField"
              matchMode="contains"
              (input)="dt1.filter($event.target.value, 'owner.ownerField', 'contains')"
            ></p-columnFilter>
          </th>
          <th>
            <p-columnFilter
              type="text"
              field="ownerMember"
              matchMode="contains"
              (input)="dt1.filter($event.target.value, 'ownerMember', 'contains')"
            ></p-columnFilter>
          </th>
          <th><p-columnFilter type="boolean" field="isTest"></p-columnFilter></th>
          <th><p-columnFilter type="boolean" field="isBlacklisted"></p-columnFilter></th>

          <th><p-columnFilter type="boolean" field="isArchived"></p-columnFilter></th>
          <th width="3rem"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-organization>
        <tr>
          <td>{{ organization.organization }}</td>
          <td>{{ organization.owner.ownerField }}</td>
          <td>{{ organization.ownerMember }}</td>
          <td>
            <!-- <p-checkbox [ngModel]="organization.isTest" [binary]="true" inputId="binary" disabled="true"></p-checkbox> -->
            <p-inputSwitch [(ngModel)]="organization.isTest" (onChange)="onToggleTest(organization)"></p-inputSwitch>
          </td>
          <td>
            <p-inputSwitch
              [(ngModel)]="organization.isBlacklisted"
              (onChange)="onToggleBlacklisted(organization)"
            ></p-inputSwitch>
          </td>
          <td>
            <p-inputSwitch
              [(ngModel)]="organization.isArchived"
              (onChange)="onToggleArchive(organization)"
            ></p-inputSwitch>
          </td>
          <td>
            <i title="Edit organization" class="nb-edit icon" (click)="onEdit(organization)"></i>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="10" class="text-center">No organizations found.</td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
  <div *ngIf="isLoading">
    <tgx-spinner [class]="'routingLoading'" [isLoading]="isLoading" [top]="'100px'"></tgx-spinner>
  </div>
</nb-card>
