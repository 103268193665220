import { gql } from 'apollo-angular';

export const addMemberToOrganization = gql`
  mutation ($where: MemberToOrganizationInput!, $data: MemberDataInOrganization!) {
    admin {
      addMemberToOrganization(where: $where, data: $data) {
        code
        adviseMessage {
          code
          description
        }
      }
    }
  }
`;
