<div class="modal-header">
  <span>{{ header }}</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="GiataForm">
    <nb-card>
      <nb-card-header> GIATA information </nb-card-header>
      <nb-card-body>
        <div class="row"></div>

        <!--code-->
        <div class="row mt-2">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="label">Code</label>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group hubuser_datafields_modal">
              {{ GiataForm.get('code').value }}
            </div>
          </div>
        </div>

        <!--giataType-->
        <div class="row mt-2">
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger': GiataForm.get('giataType')?.errors && GiataForm.get('giataType').touched,
              }"
            >
              <label class="mb-1" for="label">Giata type</label>
              <tooltip-error [element]="GiataForm.get('giataType')" [errors]="GiataForm.get('giataType').errors">
              </tooltip-error>
            </div>
          </div>
          <div class="col-6">
            <p-dropdown
              [options]="giataTypes"
              [(ngModel)]="selectedGiataType"
              [virtualScroll]="true"
              itemSize="30"
              [showClear]="true"
              [filter]="true"
              optionsValue="giataType.code"
              filterby="giataType.label"
              appendTo="body"
              baseZIndex="999"
              placeholder="Select an Giata type"
              formControlName="giataType"
            >
            </p-dropdown>
          </div>
        </div>

        <!--giataCode-->
        <div class="row mt-2">
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger': GiataForm.get('giataCode')?.errors && GiataForm.get('giataCode').touched,
              }"
            >
              <label class="mb-1" for="label">Giata Code</label>
              <tooltip-error [element]="GiataForm.get('giataCode')" [errors]="GiataForm.get('giataCode').errors">
              </tooltip-error>
            </div>
          </div>
          <div class="col-6">
            <textarea
              type="text"
              class="form-control"
              formControlName="giataCode"
              [ngClass]="{
                'form-control-danger': GiataForm.get('giataCode')?.errors && GiataForm.get('giataCode').touched,
              }"
              name="giataCode"
            >
             {{ GiataForm.get('giataCode').value }}
            </textarea>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-primary" (click)="onSave()">Update</button>
  <button class="btn btn-md btn-danger" (click)="closeModal()">Close</button>
</div>
