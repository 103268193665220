import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from 'apps/web-admin/src/app/@core/shared/not-found/not-found.component';
import { BillingLoadConnectionsComponent } from './components/connections-load/connections-load.component';
import { BillingConnectionsTableComponent } from './components/connections-table/connections-table.component';
import { BillingConnetionsComponent } from './connections.component';
import { BillingConnectionsRelationsComponent } from './components/connections-relations/connections-relations.component';
import { BillingInconclusiveTableComponent } from './components/connections-inconclusive-table/connections-inconclusive-table.component';
import { BillingConnectionsMainTableComponent } from './components/connections-main-table/connections-main-table.component';
import { BillingConnectionDiscountTableComponent } from './discounts/components/connection-discount-table/connection-discount-table.component';

const routes: Routes = [
  {
    path: '',
    component: BillingConnetionsComponent,
    children: [
      {
        path: 'main',
        component: BillingConnectionsMainTableComponent,
      },
      {
        path: 'inconclusive',
        component: BillingInconclusiveTableComponent,
      },
      {
        path: 'relations',
        component: BillingConnectionsRelationsComponent,
      },
      {
        path: 'contract',
        component: BillingConnectionsTableComponent,
      },
      {
        path: 'discounts',
        component: BillingConnectionDiscountTableComponent,
      },
      {
        path: 'load',
        component: BillingLoadConnectionsComponent,
      },
      {
        path: '',
        redirectTo: 'contracts',
        pathMatch: 'full',
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ConnectionRoutingModule {}
