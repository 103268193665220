import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'xen-title-page',
  templateUrl: './title-page.component.html',
  styleUrls: ['./title-page.component.scss'],
})
export class TitlePageComponent implements OnInit {
  @Input() title = [];
  @Input() subtitle: string;

  constructor() {}

  ngOnInit(): void {}
}
