import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SubcategoriesRoutingModule } from './subcategories.routing-module';
import { SharedModule } from '../../../../@core/shared/shared.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { NgModule } from '@angular/core';
import { NbCardModule, NbCheckboxModule } from '@nebular/theme';
import { CalendarModule } from 'primeng/calendar';
import { SubcategoriesTableComponent } from './components/subcategories-table/subcategories-table.component';
import { SubcategoriesComponent } from './components/subcategories.component';
import { NewSubcategoryFormComponent } from './components/new-subcategory-form/new-subcategory-form.component';
import { UpdateSubcategoryFormComponent } from './components/update-subcategory-form/update-subcategory-form.component';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';

@NgModule({
  imports: [
    SubcategoriesRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NbCardModule,
    TableModule,
    CheckboxModule,
    ButtonModule,
    NbCheckboxModule,
    AutoCompleteModule,
    MultiSelectModule,
    CommonModule,
    DropdownModule,
    CalendarModule,
  ],
  declarations: [
    SubcategoriesComponent,
    SubcategoriesTableComponent,
    NewSubcategoryFormComponent,
    UpdateSubcategoryFormComponent,
  ],
})
export class SubcategoriesModule {}
