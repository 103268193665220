import { Table } from 'primeng/table';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { CommercialAgreementService, ContractTypeService } from '../../../../services/_index-billing.services';
import { NotificationService, WebAdminService } from './../../../../../../@core/services/_index-core.services';
import { BillingAgreementCommercialTemplateModalComponent } from '../commercial-templates-modal/commercial-templates-modal.component';
import { CommercialTemplateResponse, CommercialTemplate } from '../../../../interfaces/_index.billing.interfaces';
import { saveAs } from 'file-saver';

@Component({
  selector: 'tgx-admin-billing-agreement-commercial-template-table',
  templateUrl: './commercial-templates-table.component.html',
  styleUrls: ['./commercial-templates-table.component.scss'],
})
export class BillingAgreementsCommercialTemplateTableComponent implements OnInit, OnDestroy {
  @ViewChild('dt', { static: false }) dataTable!: Table;
  $subs: Subscription[] = [];

  header = [
    { label: 'ID', field: 'ID' },
    { label: 'Type', field: 'Type' },
    { label: 'SubType', field: 'ContractTypeName' },
    { label: 'Name', field: 'Name' },
    { label: 'Version', field: 'Version' },
    { label: 'Created At', field: 'CreatedAt' },
    { label: 'Options', field: 'Options' },
  ];

  modelTypes = [
    { value: 'BUYER', label: 'BUYER' },
    { value: 'SELLER', label: 'SELLER' },
    { value: 'OTHER', label: 'OTHER' },
  ];

  subTypes = [{ value: '-', label: 'Empty' }];

  templatesLoadSource: any[];
  isLoading: boolean;
  isEditor = false;

  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private webAdminService: WebAdminService,
    private commercialAgreementService: CommercialAgreementService,
    private readonly contractTypeService: ContractTypeService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.buildOptions();
    await this.getCommercialTemplates();
    await this.getContractTypes();
  }

  ngOnDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
  }

  buildOptions() {
    this.isEditor = this.webAdminService.isBillingEditorOrAdmin();
  }

  async getContractTypes() {
    await this.contractTypeService
      .getContractTypes()
      .then((rs) => {
        rs?.forEach((ct) => {
          this.subTypes.push({ value: ct.name, label: ct.name });
        });
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      });
  }

  async getCommercialTemplates() {
    this.isLoading = true;
    this.templatesLoadSource = [];

    let rs: CommercialTemplateResponse;

    await this.commercialAgreementService
      .getTemplates()
      .then((response) => {
        rs = response;

        if (rs?.success === true) {
          rs.response?.forEach((tmplt: CommercialTemplate) => {
            if (tmplt.ContractTypeName === '') {
              tmplt.ContractTypeName = '-';
            }
            this.templatesLoadSource.push(tmplt);
          });

          this.templatesLoadSource = [...this.templatesLoadSource];
          this.templatesLoadSource.sort((a, b) => (a.id < b.id ? 1 : -1));
          this.isLoading = false;
        }
      })
      .catch((err) => {
        this.notificationService.handleError(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  async onDownload($event) {
    await this.commercialAgreementService
      .downloadPdfTemplate('template', $event.ID)
      .then((rs) => {
        if (rs.response?.base64) {
          const byteArray = Uint8Array.from(
            atob(rs.response?.base64)
              .split('')
              .map((char) => char.charCodeAt(0)),
          );

          const blob = new Blob([byteArray], { type: 'application/pdf' });
          saveAs(blob, rs.response.fileName + '.pdf');
        }
      })
      .catch((err) => {
        this.notificationService.handleError(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  async onCreate(): Promise<void> {
    const activeModal = this.modalService.open(BillingAgreementCommercialTemplateModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = 'Create Template';

    const self = this;
    activeModal.result.then((res) => {
      // OK
      if (typeof res === 'string' && res.includes('successfully')) {
        self.getCommercialTemplates();
      }
    });
  }

  filter(value: any, field: string) {
    if (value) {
      this.dataTable.filter(value, field, 'contains');
    }
  }
}
