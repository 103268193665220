import { Component, Input, OnChanges } from '@angular/core';

interface Link {
  router: LinkRouter;
  label: string;
  icon: string;
}

type LinkRouter =
  | 'stats'
  | 'logging'
  | 'alerts'
  | 'speed'
  | 'traffic-optimizer'
  | 'insights/hotel-portfolio'
  | 'inventory'
  | 'distribution'
  | 'billing'
  | 'connections'
  | 'connections/content'
  | 'connections/settings'
  | 'b2b';

@Component({
  selector: 'xen-meta-links',
  templateUrl: './meta-links.component.html',
  styleUrls: ['./meta-links.component.scss'],
})
export class MetaLinksComponent implements OnChanges {
  @Input() linkRouter: LinkRouter;
  @Input() linkRouterTwo: LinkRouter;
  @Input() linkRouterThree: LinkRouter;

  @Input() linkInfo: string;

  links: Link[] = [];

  ngOnChanges(): void {
    this.links = [];
    if (this.linkRouter) this.links.push(this.createLink(this.linkRouter));
    if (this.linkRouterTwo) this.links.push(this.createLink(this.linkRouterTwo));
    if (this.linkRouterThree) this.links.push(this.createLink(this.linkRouterThree));
  }

  private createLink(router: LinkRouter): Link {
    const linkProperties: { [key in LinkRouter]: { label: string; icon: string } } = {
      stats: { label: 'Stats', icon: 'fa-regular fa-chart-simple' },
      logging: { label: 'Logging', icon: 'fa-regular fa-file-binary' },
      alerts: { label: 'Alerts', icon: 'fa-regular fa-bells' },
      speed: { label: 'Speed', icon: 'fa-regular fa-gauge-high' },
      'traffic-optimizer': { label: 'Traffic optimizer', icon: 'fa-regular fa-wrench' },
      'insights/hotel-portfolio': { label: 'Hotel Portfolio', icon: 'fa-regular fa-lightbulb' },
      inventory: { label: 'Inventory', icon: 'fa-regular fa-bed' },
      distribution: { label: 'Distribution', icon: 'fa-regular fa-chart-network' },
      connections: { label: 'My Connections', icon: 'fa-regular fa-plug' },
      'connections/content': { label: 'Connections content', icon: 'fa-regular fa-hotel' },
      'connections/settings': { label: 'API Settings', icon: 'fa-regular fa-cogs' },
      b2b: { label: 'B2B', icon: 'fa-regular fa-globe' },
      billing: { label: 'Billing', icon: '' },
    };

    return {
      router,
      label: linkProperties[router].label,
      icon: linkProperties[router].icon,
    };
  }
}
