import { SharedModule } from './../../../../@core/shared/shared.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { SchedulerRoutingModule } from './scheduler.routing-module';
import { NgModule } from '@angular/core';
import { NbCardModule, NbCheckboxModule } from '@nebular/theme';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SchedulerComponent } from './components/scheduler.component';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { SchedulerTableComponent } from './components/scheduler-table/scheduler-table.component';
import { SchedulerDetailsComponent } from './components/scheduler-details/scheduler-details.component';
import { SchedulerInfoComponent } from './components/scheduler-info/scheduler-info.component';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { SharedUiStorybookModule } from '@tgx/shared/ui/storybook';

@NgModule({
  imports: [
    TableModule,
    InputTextModule,
    SharedModule,
    SchedulerRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NbCardModule,
    NbCheckboxModule,
    CommonModule,
    AutoCompleteModule,
    CalendarModule,
    MultiSelectModule,
    NgbModule,
    SharedUiStorybookModule,
  ],
  declarations: [SchedulerComponent, SchedulerTableComponent, SchedulerDetailsComponent, SchedulerInfoComponent],
  providers: [NgbTooltipConfig],
})
export class SchedulerModule {}
