<div class="modal-header">
  <span>Create SubCategory</span>
  <button class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <nb-card>
      <nb-card-header>SubCategory</nb-card-header>
      <nb-card-body>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="code">Name </label>
              <tooltip-error [element]="form.get('name')" [errors]="form.get('name').errors"> </tooltip-error>
              <input
                type="text"
                class="form-control"
                [ngClass]="{ 'form-control-danger': form.get('name').errors && form.get('name').touched }"
                name="name"
                formControlName="name"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="description">Description </label>
              <tooltip-error [element]="form.get('description')" [errors]="form.get('description').errors">
              </tooltip-error>
              <input
                type="text"
                class="form-control"
                [ngClass]="{ 'form-control-danger': form.get('description').errors && form.get('description').touched }"
                name="description"
                formControlName="description"
              />
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="isBuyer">Is Buyer?</label>

              <div class="form-group">
                <input type="checkbox" baseZIndex="999" formControlName="isBuyer" />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="isSeller">Is Seller?</label>

              <div class="form-group">
                <input type="checkbox" baseZIndex="999" formControlName="isSeller" />
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="isDemandBridge">Is Demand Bridge?</label>

              <div class="form-group">
                <input type="checkbox" baseZIndex="999" formControlName="isDemandBridge" />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="isSupplyBridge">Is Supply Bridge?</label>

              <div class="form-group">
                <input type="checkbox" baseZIndex="999" formControlName="isSupplyBridge" />
              </div>
            </div>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()">Close</button>
  <button class="btn btn-md btn-primary" [disabled]="form.invalid" (click)="sendCreateSubCategory()">
    Create Category
  </button>
</div>
