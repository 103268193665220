import { gql } from 'apollo-angular';

export const getConnectionsFullInfo = gql`
  query {
    connectionsFullInfo {
      id
      buyerCode
      buyerName
      sellerCode
      sellerName
      isPush
      api
      createdAt
      updatedAt
    }
  }
`;
