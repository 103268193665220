import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../@core/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LegacyConnectionsRoutingModule } from './legacy-connections-routing.module';
import { LegacyConnectionsModalComponent } from './components/legacy-connections-modal/legacy-connections-modal.component';
import { LegacyConnectionsTableComponent } from './components/legacy-connections-table/legacy-connections-table.component';
import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ButtonModule } from 'primeng/button';

import { LegacyConnectionsComponent } from './components/legacy-connections.component';

import { NbActionsModule, NbCardModule, NbCheckboxModule, NbIconModule } from '@nebular/theme';

@NgModule({
  declarations: [LegacyConnectionsModalComponent, LegacyConnectionsTableComponent, LegacyConnectionsComponent],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LegacyConnectionsRoutingModule,
    NbActionsModule,
    NbCardModule,
    NbCheckboxModule,
    NbIconModule,
    TableModule,
    InputSwitchModule,
    ButtonModule,
  ],
})
export class LegacyConnectionsModule {}
