import { gql } from 'apollo-angular';

export const deactivateApikey = gql`
  mutation ($where: ApikeyOrganizationInput!) {
    admin {
      deactivateApikey(where: $where) {
        code
        adviseMessage {
          code
          description
        }
      }
    }
  }
`;
