<div class="modal-header">
  <span>{{ header }}</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <nb-card>
    <nb-card-header> Data </nb-card-header>
    <nb-card-body>
      <form [formGroup]="connectionForm">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="connectionId">ID</label>
              <input type="text" class="form-control" name="connectionId" formControlName="connectionId" />
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="connectionType"
                >ConnectionType
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="connectionForm.get('connectionType')"
                [errors]="connectionForm.get('connectionType').errors"
              ></tooltip-error>
              <div class="form-group">
                <p-dropdown
                  [options]="connectionTypes"
                  [appendTo]="'body'"
                  baseZIndex="999"
                  formControlName="connectionType"
                  optionLabel="label"
                  filterBy="label"
                >
                  <ng-template pTemplate="selectedItem">
                    <div *ngIf="connectionForm.get('connectionType').value">
                      <div>{{ connectionForm.get('connectionType').value.label }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-ci pTemplate="ci">
                    <div>
                      <div>{{ ci.label }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-3">
            <div class="form-group">
              <label class="mb-1" for="partnerId">PartnerID</label>
              <input type="text" class="form-control" name="partnerId" formControlName="partnerId" />
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label class="mb-1" for="partnerName">PartnerName</label>
              <input type="text" class="form-control" name="partnerName" formControlName="partnerName" />
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label class="mb-1" for="partnerOrgCode">PartnerOrgCode</label>
              <input type="text" class="form-control" name="partnerOrgCode" formControlName="partnerOrgCode" />
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label class="mb-1" for="partnerType">PartnerType</label>
              <input type="text" class="form-control" name="partnerType" formControlName="partnerType" />
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-4">
            <div class="form-group">
              <label class="mb-1" for="contractId">ContractID</label>
              <input type="text" class="form-control" name="contractId" formControlName="contractId" />
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label class="mb-1" for="modelType">ModelType</label>
              <input type="text" class="form-control" name="modelType" formControlName="modelType" />
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label class="mb-1" for="partnerSubType">SubType</label>
              <input type="text" class="form-control" name="partnerSubType" formControlName="partnerSubType" />
            </div>
          </div>
        </div>
      </form>
    </nb-card-body>
  </nb-card>
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()">Cancel</button>
  <button class="btn btn-md btn-primary" [disabled]="!validForm()" (click)="onSave()">Update</button>
</div>
