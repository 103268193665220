import { gql } from 'apollo-angular';

export const getAllApikeys = gql`
  query allAPIKeys($include_archived: Boolean) {
    admin {
      allAPIKeys(where: { includeArchived: $include_archived }) {
        edges {
          node {
            code
            deletedAt
            apikeysData {
              code
              isActive
              label
              role
              organization {
                code
                label
              }
            }
          }
        }
      }
    }
  }
`;
