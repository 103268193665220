<form [formGroup]="relationForm">
  <nb-card>
    <nb-card-body>
      <div class="row mt-2">
        <div class="col-6">
          <div class="form-group">
            <label class="mb-1" for="buyer"
              >Buyer
              <span class="mandatory ml-1">*</span>
            </label>
            <tooltip-error
              [element]="relationForm.get('buyer')"
              [errors]="relationForm.get('buyer').errors"
            ></tooltip-error>
            <div class="form-group">
              <p-autoComplete
                [appendTo]="'body'"
                baseZIndex="999"
                [inputStyle]="{ 'width': '626px', 'max-width': '700px' }"
                [formControl]="relationForm.get('buyer')"
                [suggestions]="buyersAutocomplete"
                field="label"
                class="form-control"
                [ngClass]="{
                  'form-control-danger': relationForm.get('buyer').errors && relationForm.get('buyer').touched,
                }"
                (completeMethod)="autoCompleteBuyers($event)"
              ></p-autoComplete>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label class="mb-1" for="seller"
              >Seller
              <span class="mandatory ml-1">*</span>
            </label>
            <tooltip-error
              [element]="relationForm.get('seller')"
              [errors]="relationForm.get('seller').errors"
            ></tooltip-error>
            <div class="form-group">
              <p-autoComplete
                [appendTo]="'body'"
                baseZIndex="999"
                [inputStyle]="{ 'width': '626px', 'max-width': '700px' }"
                [formControl]="relationForm.get('seller')"
                [suggestions]="sellersAutocomplete"
                field="label"
                class="form-control"
                [ngClass]="{
                  'form-control-danger': relationForm.get('seller').errors && relationForm.get('seller').touched,
                }"
                (completeMethod)="autoCompleteSellers($event)"
              ></p-autoComplete>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer mt-4">
        <button
          class="btn btn-md btn-primary"
          [disabled]="!validForm() || isLoading"
          (click)="getContractConnections()"
        >
          Search
        </button>
      </div>
    </nb-card-body>
  </nb-card>
</form>

<nb-card>
  <nb-card-header>Connections</nb-card-header>
  <nb-card-body>
    <p-table
      #dt
      [value]="connectionsLoadSource"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
      [tableStyle]="{ 'min-width': '50rem' }"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
      [resizableColumns]="true"
      columnResizeMode="expand"
      [loading]="isLoading"
      loadingIcon="pi pi-spin pi-spinner"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let head of header" [pSortableColumn]="head.field">
            {{ head.label }}
            <p-sortIcon [field]="head.field"></p-sortIcon>
          </th>
        </tr>
        <tr>
          <th *ngFor="let head of header">
            <p-columnFilter
              [type]="getFilterType(head.field)"
              [field]="head.field"
              [showMenu]="false"
              [showClearButton]="false"
              (input)="filter($event.target.value, head.field)"
            ></p-columnFilter>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-connection>
        <tr>
          <td>{{ connection.id }}</td>
          <td>{{ connection.activationDateTime }}</td>
          <td>{{ connection.endDate }}</td>
          <td>
            <p-checkbox [ngModel]="connection.isBuy" [binary]="true" inputId="binary" disabled="true"></p-checkbox>
          </td>
          <td>{{ connection.connectionType }}</td>
          <td>{{ connection.partnerId }}</td>
          <td>{{ connection.partnerName }}</td>
          <td>{{ connection.partnerOrgCode }}</td>
          <td>{{ connection.contractId }}</td>
          <td>{{ connection.partnerType }}</td>
          <td>{{ connection.partnerSubType }}</td>
          <td>{{ connection.modelType }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="15" class="text-center">No connections found.</td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>
