<div class="modal-header">
  <span>Add member to organization</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form [formGroup]="form">
    <nb-card>
      <nb-card-body>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="member">Member</label>
              <tooltip-error [element]="form.get('member')" [errors]="form.get('member').errors"> </tooltip-error>
              <input
                type="text"
                class="form-control"
                [ngClass]="{ 'form-control-danger': form.get('member').errors && form.get('member').touched }"
                name="member"
                class="form-control"
                formControlName="member"
                name="member"
                type="text"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="org">Organization</label>
              <p-dropdown
                [appendTo]="'body'"
                [options]="allOrgs"
                baseZIndex="999"
                filter="true"
                filterBy="label"
                formControlName="org"
                optionValue="code"
                placeholder="Please select an organization"
              >
              </p-dropdown>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="role">Role</label>
              <p-dropdown
                [appendTo]="'body'"
                [options]="memberRoleOptions"
                baseZIndex="999"
                filter="true"
                filterBy="label"
                formControlName="role"
                optionValue="code"
                placeholder="Please select a role"
              >
              </p-dropdown>
            </div>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </form>

  <!-- <form [formGroup]="form_save_anyway"> -->
  <nb-card class="anyway_card" *ngIf="!isCardHidden" º>
    <nb-card-body id="confirmation_card">
      <div class="row">
        <div class="col-12">
          <div class="form-group anyway_text">
            The email domain is not previously associated with the organization.<br />
            Do you still want to proceed?
          </div>
        </div>
        <div class="col-12">
          <div class="form-group anyway_btn">
            <button class="btn btn-md btn-danger" (click)="closeModal()">Close</button> &nbsp;
            <button class="btn btn-md btn-primary" [disabled]="!isFormReady" (click)="onSaveAnyway()">
              Save anyway
            </button>
          </div>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
  <!-- </form> -->
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()" [disabled]="!isCardHidden">Close</button>
  <button class="btn btn-md btn-primary" [disabled]="!isFormReady || !isCardHidden" (click)="onSave()">Save</button>
</div>
