import { SharedModule } from '../../../../@core/shared/shared.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { EnqueuerRoutingModule } from './enqueuer.routing-module';
import { NgModule } from '@angular/core';
import { EnqueuerComponent } from './components/enqueuer.component';
import { NbCardModule, NbCheckboxModule } from '@nebular/theme';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { EnqueuerTableComponent } from './components/enqueuer-main/enqueuer-table.component';

@NgModule({
  imports: [
    SharedModule,
    EnqueuerRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NbCardModule,
    NbCheckboxModule,
    CommonModule,
    AutoCompleteModule,
    CalendarModule,
    MultiSelectModule,
    NgbModule,
    DropdownModule,
  ],
  declarations: [EnqueuerTableComponent, EnqueuerComponent],
  providers: [NgbTooltipConfig],
})
export class EnqueuerModule {}
