<div class="modal-header">
  <span>{{ header }}</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="partnerForm">
    <nb-card>
      <nb-card-header> Data </nb-card-header>
      <nb-card-body>
        <div class="row mt-2">
          <div class="col-4">
            <div
              class="form-group"
              [ngClass]="{ 'form-control-danger': partnerForm.get('name').errors && partnerForm.get('name').touched }"
            >
              <label for="name"
                >Name
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="partnerForm.get('name')"
                [errors]="partnerForm.get('name').errors"
              ></tooltip-error>
              <input
                type="text"
                class="form-control"
                formControlName="name"
                [ngClass]="{ 'form-control-danger': partnerForm.get('name').errors && partnerForm.get('name').touched }"
                placeholder="Enter a name..."
                name="name"
              />
            </div>
          </div>
          <div class="col-4">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger': partnerForm.get('orgCode').errors && partnerForm.get('orgCode').touched,
              }"
            >
              <label for="orgCode"
                >orgCode
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="partnerForm.get('orgCode')"
                [errors]="partnerForm.get('orgCode').errors"
              ></tooltip-error>
              <input
                type="text"
                class="form-control"
                formControlName="orgCode"
                [ngClass]="{
                  'form-control-danger': partnerForm.get('orgCode').errors && partnerForm.get('orgCode').touched,
                }"
                placeholder="Enter a unique orgCode.."
                name="orgCode"
              />
            </div>
          </div>
          <div class="col-4" *ngIf="!isCreating && odooCodeEditable">
            <div class="form-group">
              <label class="mb-1" for="odooCode">OdooCode</label>
              <input type="number" class="form-control" formControlName="odooCode" name="odooCode" />
            </div>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </form>
</div>
<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()">{{ isEditor ? 'Cancel' : 'Close' }}</button>
  <button
    *ngIf="isEditor"
    class="btn btn-md btn-primary"
    [disabled]="partnerForm.invalid || !this.isEditor"
    (click)="onSave()"
  >
    {{ isCreating ? 'Create' : 'Update' }}
  </button>
</div>
