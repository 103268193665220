import { gql } from 'apollo-angular';

export const SetOrganizationTestValue = gql`
  mutation SetOrganizationTestValue($isTest: Boolean!, $where: OrganizationWhereUniqueInput!) {
    admin {
      setOrganizationTestValue(isTest: $isTest, where: $where) {
        code
        createdAt
        updatedAt
        adviseMessage {
          code
          description
        }
      }
    }
  }
`;
