<nb-card>
  <nb-card-header> Global Requests </nb-card-header>
  <nb-card-body>
    <p-table
      #dt
      [value]="requestsSource"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
      [tableStyle]="{ 'min-width': '50rem' }"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
      [resizableColumns]="true"
      columnResizeMode="expand"
      [loading]="isLoading"
      loadingIcon="pi pi-spin pi-spinner"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let head of header" [pSortableColumn]="head.field">
            {{ head.label }}
            <p-sortIcon [field]="head.field"></p-sortIcon>
          </th>
        </tr>
        <tr>
          <th *ngFor="let head of header">
            <p-columnFilter
              *ngIf="
                head.field !== 'currentConnectionType' &&
                head.field !== 'requestedConnectionType' &&
                head.field !== 'requestStatus'
              "
              type="text"
              [field]="head.field"
              [showMenu]="false"
              [showClearButton]="false"
              (input)="filterDT($event.target.value, head.field)"
            ></p-columnFilter>
            <p-columnFilter
              [field]="head.field"
              matchMode="equals"
              [showMenu]="false"
              *ngIf="head.field === 'currentConnectionType' || head.field === 'requestedConnectionType'"
            >
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-dropdown
                  [ngModel]="value"
                  [options]="rateTypes"
                  (onChange)="filter($event.value)"
                  placeholder="Any"
                  [showMenu]="false"
                  [showClearButton]="false"
                  [appendTo]="'body'"
                  baseZIndex="999"
                >
                  <ng-template let-option pTemplate="item">
                    <span>{{ option.label }}</span>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>
            <p-columnFilter
              [field]="head.field"
              matchMode="equals"
              [showMenu]="false"
              *ngIf="head.field == 'requestStatus'"
            >
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-dropdown
                  [ngModel]="value"
                  [options]="statuses"
                  (onChange)="filter($event.value)"
                  placeholder="Any"
                  [appendTo]="'body'"
                  baseZIndex="999"
                  [showMenu]="false"
                  [showClearButton]="false"
                >
                  <ng-template let-option pTemplate="item">
                    <span>{{ option.label }}</span>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-request>
        <tr>
          <td>{{ request.id }}</td>
          <td>{{ request.partnerFromName }}</td>
          <td>{{ request.partnerToName }}</td>
          <td>{{ request.currentConnectionType }}</td>
          <td>{{ request.requestedConnectionType }}</td>
          <td>{{ request.requestStatus }}</td>
          <td>{{ request.requestUser }}</td>
          <td>{{ request.responseUser }}</td>
          <td>{{ request.createdAt }}</td>
          <td>{{ request.updatedAt }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="15" class="text-center">No requests found.</td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>
