import { Injectable } from '@angular/core';
import { PlatformInfoService } from './platform-info.service';

declare let dataLayer: any[];

@Injectable({
  providedIn: 'root',
})
export class GtmDataLayerService {
  private user: string;

  constructor(private platformInfoService: PlatformInfoService) {
    // The dataLayer needs to be initialized
    if (this.platformInfoService.isBrowser()) {
      if (typeof dataLayer !== 'undefined' && dataLayer) {
        dataLayer = (window as any).dataLayer = (window as any).dataLayer || [];
      }

      this.platformInfoService.userAuthenticated$.subscribe((profile) => {
        if (profile) {
          this.user = profile.email;
        }
      });
    }
  }

  // Send virtualPageviews via  dataLayer.push
  // Change this dataLayer event as you see fit
  pageTrack(isPublic: boolean = false) {
    // However you wish to send event
    dataLayer.push({
      'event': 'virtualPageview',
      'is_public': isPublic,
      'user_id': this.user,
      'organization_label': this.platformInfoService.getOrgLabel()?.trim(),
      'organization_code': this.platformInfoService.orgCode()?.trim(),
    });
  }

  // setOrganization(org: Organization) {
  setOrganization(org: any) {
    // However you wish to send event
    dataLayer.push({
      'event': 'setOrganization',
      'user_id': this.user,
      'organization_label': org.label.trim(),
      'organization_code': org.code.trim(),
    });

    this.pageTrack(false);
  }

  // triggerNetworkSearch(searchInput: string) {
  triggerNetworkSearch(searchInput: string) {
    // send event with the input searched
    dataLayer.push({
      'event': 'searchNetwork',
      'user_id': this.user,
      'text_input': searchInput,
    });

    this.pageTrack(false);
  }

  customClick(clickId: string, userEmail?: string) {
    if (userEmail) {
      dataLayer.push({
        'event': 'customClick',
        'click_id': clickId,
        'user_id': userEmail,
      });
    } else {
      dataLayer.push({
        'event': 'customClick',
        'click_id': clickId,
      });
    }
  }
}
