import { Injectable } from '@angular/core';
import {
  Hotel,
  HotelList,
  Master,
  MasterSons,
  GenericMappingResponse,
  MasterList,
  HotelId,
} from '../modules/mapping_tool/interfaces/models/mapping.interfaces';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Subject, lastValueFrom } from 'rxjs';
import { PlatformBrowserService } from '@tgx/shared/services';
import { MasterType, OptionMapping } from '../modules/mapping_tool/interfaces/models/enums.interfaces';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MappingService {
  // MASTER NON VALIDATED
  nonValidateCount = new BehaviorSubject<number>(0);
  nonValidateMasters = new BehaviorSubject<MasterList[]>([]);

  // MASTER VALIDATED
  validateCount = new BehaviorSubject<number>(0);
  validateMasters = new BehaviorSubject<MasterList[]>([]);

  // UNMAPPED LIST
  unmappedCount = new BehaviorSubject<number>(0);
  unmappedList = new BehaviorSubject<HotelList[]>([]);

  // ERRORS
  errorNonValidate = new BehaviorSubject<string>(null);
  errorValidate = new BehaviorSubject<string>(null);
  errorUnmapped = new BehaviorSubject<string>(null);
  errorHotels = new BehaviorSubject<string>(null);

  //HOTELS CHILDREN
  nonValidateHotels = new BehaviorSubject<HotelList[]>([]);
  validateHotels = new BehaviorSubject<HotelList[]>([]);

  offset = 0;
  limit = 10;

  httpClient: HttpClient;

  constructor(
    private http: HttpClient,
    private platformBrowserService: PlatformBrowserService,
  ) {}

  async loadData(formParams: { countryMapping: string; hotelMapping: string }, option?: OptionMapping) {
    let nonValidate: Promise<Master>;
    let validate: Promise<Master>;
    let unmapped: Promise<Hotel>;
    let nonValidateResult: PromiseSettledResult<Master>;
    let validateResult: PromiseSettledResult<Master>;
    let unmappedResult: PromiseSettledResult<Hotel>;

    switch (option) {
      case OptionMapping.UNMAP:
        this.resetData(option);
        unmapped = lastValueFrom(
          this.buildAndLaunchRequestWithParams(
            this.getUnmappedHotelsEndPoint(),
            formParams.countryMapping,
            this.offset,
            this.limit,
            formParams.hotelMapping,
          ),
        ) as Promise<Hotel>;
        [unmappedResult] = await Promise.allSettled([unmapped]);
        return this.initUnmapData(unmappedResult);
      case OptionMapping.ALL:
        this.resetData(option);
        nonValidate = lastValueFrom(
          this.buildAndLaunchRequestWithParams(
            this.getNonValidatedMasterEndPoint(),
            formParams.countryMapping,
            this.offset,
            this.limit,
            formParams.hotelMapping,
          ),
        ) as Promise<Master>;
        validate = lastValueFrom(
          this.buildAndLaunchRequestWithParams(
            this.getValidatedMasterEndPoint(),
            formParams.countryMapping,
            this.offset,
            this.limit,
            formParams.hotelMapping,
          ),
        ) as Promise<Master>;
        unmapped = lastValueFrom(
          this.buildAndLaunchRequestWithParams(
            this.getUnmappedHotelsEndPoint(),
            formParams.countryMapping,
            this.offset,
            this.limit,
            formParams.hotelMapping,
          ),
        ) as Promise<Hotel>;
        [nonValidateResult, validateResult, unmappedResult] = await Promise.allSettled([
          nonValidate,
          validate,
          unmapped,
        ]);
        return this.initAllData(nonValidateResult, validateResult, unmappedResult);
      default:
        this.resetData(option);
        nonValidate = lastValueFrom(
          this.buildAndLaunchRequestWithParams(
            this.getNonValidatedMasterEndPoint(),
            formParams.countryMapping,
            this.offset,
            this.limit,
            formParams.hotelMapping,
          ),
        ) as Promise<Master>;
        validate = lastValueFrom(
          this.buildAndLaunchRequestWithParams(
            this.getValidatedMasterEndPoint(),
            formParams.countryMapping,
            this.offset,
            this.limit,
            formParams.hotelMapping,
          ),
        ) as Promise<Master>;
        [nonValidateResult, validateResult] = await Promise.allSettled([nonValidate, validate]);
        return this.initValidateInvalidateData(nonValidateResult, validateResult);
    }
  }

  private resetData(option: OptionMapping) {
    const resetUnmapped = () => {
      this.unmappedCount.next(0);
      this.unmappedList.next([]);
      this.errorUnmapped.next(null);
    };
    const resetValidateInvalidate = () => {
      this.nonValidateCount.next(0);
      this.nonValidateMasters.next([]);
      this.validateCount.next(0);
      this.validateMasters.next([]);
      this.errorNonValidate.next(null);
      this.errorValidate.next(null);
    };
    if (option === OptionMapping.UNMAP) {
      resetUnmapped();
    } else if (option === OptionMapping.ALL) {
      resetValidateInvalidate();
      resetUnmapped();
    } else {
      resetValidateInvalidate();
    }
  }

  private async handleResult(
    result: PromiseSettledResult<any>,
    errorSubject: Subject<string>,
    countSubject: Subject<number>,
    listSubject: Subject<HotelList[] | MasterList[]>,
    noDataMessage: string,
    countKey: string,
    listKey: string,
  ) {
    if (result?.status === 'fulfilled') {
      if (result.value[listKey].length === 0) {
        errorSubject.next(noDataMessage);
      } else {
        countSubject.next(result.value[countKey]);
        listSubject.next(result.value[listKey]);
      }
    }
  }

  private async initUnmapData(unmapResult: PromiseSettledResult<Hotel>) {
    await this.handleResult(
      unmapResult,
      this.errorUnmapped,
      this.unmappedCount,
      this.unmappedList,
      'No unmapped hotels found',
      'hotel_count',
      'hotel_list',
    );
  }

  private async initAllData(
    nonValidateResult: PromiseSettledResult<Master>,
    validateResult: PromiseSettledResult<Master>,
    unmappedResult: PromiseSettledResult<Hotel>,
  ) {
    await this.handleResult(
      nonValidateResult,
      this.errorNonValidate,
      this.nonValidateCount,
      this.nonValidateMasters,
      'No Non-Validated masters found',
      'master_count',
      'master_list',
    );
    await this.handleResult(
      validateResult,
      this.errorValidate,
      this.validateCount,
      this.validateMasters,
      'No Validated masters found',
      'master_count',
      'master_list',
    );
    await this.handleResult(
      unmappedResult,
      this.errorUnmapped,
      this.unmappedCount,
      this.unmappedList,
      'No unmapped hotels found',
      'hotel_count',
      'hotel_list',
    );
  }

  private async initValidateInvalidateData(
    nonValidateResult: PromiseSettledResult<Master>,
    validateResult: PromiseSettledResult<Master>,
  ) {
    await this.handleResult(
      nonValidateResult,
      this.errorNonValidate,
      this.nonValidateCount,
      this.nonValidateMasters,
      'No Non-Validated masters found',
      'master_count',
      'master_list',
    );
    await this.handleResult(
      validateResult,
      this.errorValidate,
      this.validateCount,
      this.validateMasters,
      'No Validated masters found',
      'master_count',
      'master_list',
    );
  }

  updateHotels(hotels: HotelList[], type: MasterType) {
    if (type === MasterType.VALIDATE) {
      this.validateHotels.next(hotels);
    } else if (type === MasterType.NON_VALIDATE) {
      this.nonValidateHotels.next(hotels);
    }
  }

  //GET.
  buildAndLaunchRequestWithParams(url: string, country: string, offset: number, limit: number, name?: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + this.platformBrowserService.getToken());
    headers = headers.set('accept', 'application/json');

    let params = new HttpParams();
    if (name) {
      params = params.set('name', name);
    }
    params = params.set('country', country);
    params = params.set('offset', offset);
    params = params.set('limit', limit);
    return this.http.get<Master | Hotel | HotelList>(url, { headers, params });
  }
  // NON-VALIDATED MASTER
  getNonValidatedMasterEndPoint() {
    return environment.apis.mapping + 'masters/not_validated/';
  }
  // VALIDATED MASTER
  getValidatedMasterEndPoint() {
    return environment.apis.mapping + 'masters/validated/';
  }
  // UNMAPPED HOTELS
  getUnmappedHotelsEndPoint() {
    return environment.apis.mapping + 'hotels/unmapped_hotels/';
  }

  // GET. HOTELS
  async buildAndLaunchMasterSonsRequestWithParams(apiUrl: string): Promise<MasterSons> {
    return new Promise<MasterSons>((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', 'Bearer ' + this.platformBrowserService.getToken());
      headers = headers.set('accept', 'application/json');

      this.http.get<MasterSons>(apiUrl, { headers }).subscribe((res: MasterSons) => {
        if (res) {
          resolve(res);
        } else {
          reject('Error');
        }
      });
    });
  }

  // HOTELS VALIDATED'S SONS
  async getValidatedMasterSons(masterId: string): Promise<MasterSons> {
    const apiUrl = environment.apis.mapping + `masters/${masterId}/hotels`;
    return await this.buildAndLaunchMasterSonsRequestWithParams(apiUrl);
  }
  // HOTELS NON-VALIDATED'S SONS
  async getNonValidatedMasterSons(masterId: string): Promise<MasterSons> {
    const apiUrl = environment.apis.mapping + `masters/${masterId}/hotels_not_validated`;
    return await this.buildAndLaunchMasterSonsRequestWithParams(apiUrl);
  }

  // SIMILAR HOTELS
  async getSimilarHotels(hotelId: string, offset: number, limit: number): Promise<MasterList[]> {
    return new Promise<MasterList[]>((resolve, reject) => {
      const apiUrl = environment.apis.mapping + 'hotels/similar_masters/';
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', 'Bearer ' + this.platformBrowserService.getToken());
      headers = headers.set('accept', 'application/json');

      let params = new HttpParams();
      params = params.set('hotel_id', hotelId);
      params = params.set('offset', offset);
      params = params.set('limit', limit);

      this.http.get<MasterList[]>(apiUrl, { headers, params }).subscribe((res: MasterList[]) => {
        if (res) {
          resolve(res);
        } else {
          reject('Error');
        }
      });
    });
  }

  // POST.
  buildAndLaunchPostRequestWithParams(apiUrl: string, body?: string[]): Promise<GenericMappingResponse[]> {
    return new Promise<GenericMappingResponse[]>((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', 'Bearer ' + this.platformBrowserService.getToken());
      headers = headers.set('accept', 'application/json');

      if (!body) {
        body = [];
      }
      this.http.post<GenericMappingResponse[]>(apiUrl, body, { headers }).subscribe((res: GenericMappingResponse[]) => {
        if (res) {
          resolve(res);
        } else {
          reject('Error');
        }
      });
    });
  }
  // VALIDATE MASTER
  async validateMaster(masterId: string): Promise<GenericMappingResponse[]> {
    const apiUrl = environment.apis.mapping + `hotels/${masterId}/validate`;
    return await this.buildAndLaunchPostRequestWithParams(apiUrl);
  }
  // INVALIDATE MASTER
  async invalidateMaster(masterId: string): Promise<GenericMappingResponse[]> {
    const apiUrl = environment.apis.mapping + `hotels/${masterId}/invalidate`;
    return await this.buildAndLaunchPostRequestWithParams(apiUrl);
  }
  // UNMAP HOTEL FROM MASTER
  async unmapHotelFromMaster(hotelId: string[]): Promise<GenericMappingResponse[]> {
    const apiUrl = environment.apis.mapping + 'hotels/unmap/';
    return await this.buildAndLaunchPostRequestWithParams(apiUrl, hotelId);
  }
  // REMAP HOTEL
  async remapHotel(masterId: string, hotelId: string): Promise<GenericMappingResponse[]> {
    return new Promise<GenericMappingResponse[]>((resolve, reject) => {
      const apiUrl = environment.apis.mapping + `hotels/${masterId}/remap`;
      const body = [hotelId];
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', 'Bearer ' + this.platformBrowserService.getToken());
      headers = headers.set('accept', 'application/json');
      this.http.post<GenericMappingResponse[]>(apiUrl, body, { headers }).subscribe((res: GenericMappingResponse[]) => {
        if (res) {
          resolve(res);
        } else {
          reject('Error');
        }
      });
    });
  }

  // CREATE HOTEL ID
  getHotelId(selected: HotelId | HotelId[]): string | string[] {
    if (!selected) {
      return null;
    }
    if (Array.isArray(selected)) {
      return selected.map((hotel) => `${hotel.supplier_code}_${hotel.hotel_code}`);
    } else {
      return `${selected.supplier_code}_${selected.hotel_code}`;
    }
  }
}
