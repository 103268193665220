import { gql } from 'apollo-angular';

export const getOnboardingDates = gql`
  query ($where: ProfileWhereUniqueInput!) {
    social {
      profile(where: $where) {
        profileData {
          buyerOnboardingStart
          buyerOnboardingEnd
          sellerOnboardingStart
          sellerOnboardingEnd
        }
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
