import { gql } from 'apollo-angular';

export const getAccessesBySupplierCode = gql`
  query getAccessesBySupplierCode($where: SupplierWhereInput) {
    admin {
      allSuppliers(where: $where) {
        edges {
          node {
            supplierData {
              code
              name
              context
              isActive
              accesses {
                edges {
                  node {
                    createdAt
                    updatedAt
                    accessData {
                      code
                      name
                      isActive
                      isTest
                      owner {
                        code
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
