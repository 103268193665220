import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppsRoutingModule } from './apps-routing.module';
import { SharedModule } from '../../@core/shared/shared.module';
import { AppsComponent } from './apps.component';
import { AppsService } from './services/apps.service';
import { ReactiveFormsModule } from '@angular/forms';
import { NbCardModule } from '@nebular/theme';

@NgModule({
  declarations: [AppsComponent],
  imports: [CommonModule, AppsRoutingModule, SharedModule, ReactiveFormsModule, NbCardModule],
})
export class AppsModule {
  static forRoot(): ModuleWithProviders<AppsModule> {
    return {
      ngModule: AppsModule,
      providers: [AppsService],
    };
  }
}
