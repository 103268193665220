import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { clone } from '../../../../../../@core/utils/utils';
import { Options } from './../../../../../../@core/interfaces/_index.interfaces';
import { NotificationService, WebAdminService } from './../../../../../../@core/services/_index-core.services';
import {
  MemberToOrganizationInput,
  CompanyRole,
  ContactImportance,
  Members,
  UpdateMemberInput,
  MemberDataInOrganization,
} from './../../../../interfaces/_index-entities.interfaces';
import { MemberNetworkService } from './../../../../services/_index-entities.services';
import { OrganizationSelector } from '../../../../../entities/interfaces/_index-entities.interfaces';

@Component({
  selector: 'tgx-member-modal',
  styleUrls: ['./members-modal.component.scss'],
  templateUrl: 'members-modal.component.html',
})
export class MembersModalComponent implements OnInit, OnDestroy {
  @Input() member: any = null;
  @Input() header: string;
  @Input() selectedOrgs: OrganizationSelector[];

  isLoading: boolean;
  isCreating: boolean;
  memberAux: Members;
  bulkingMode: boolean;

  public memberForm: UntypedFormGroup;
  public activeOrgs: OrganizationSelector[];

  subscriptions: Subscription[] = [];

  contactImportanceOptions: Options[] = [{ code: null, label: 'Select One' }];
  companyRoleOptions: Options[] = [{ code: null, label: 'Select One' }];

  $subs: Subscription[] = [];

  org: OrganizationSelector;

  memberRoleOptions: Options[] = [
    { code: null, label: 'Select One' },
    { code: 'USER', label: 'User' },
    { code: 'ADMIN', label: 'Admin' },
  ];
  constructor(
    private notificationService: NotificationService,
    private webAdminService: WebAdminService,
    private activeModal: NgbActiveModal,
    private memberNetworkService: MemberNetworkService,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    this.activeOrgs = this.selectedOrgs;
    this.$subs.push(
      this.webAdminService.orgSelected.subscribe((orgSelected) => {
        if (orgSelected) {
          this.org = orgSelected;
        }
      }),
    );

    this.contactImportanceOptions.push(
      ...Object.values(ContactImportance).map((value) => ({ code: value, label: value })),
    );
    this.companyRoleOptions.push(...Object.values(CompanyRole).map((value) => ({ code: value, label: value })));

    if (!this.bulkingMode) {
      this.isCreating = true;
    }

    if (this.member) {
      this.isCreating = false;
    }

    this.memberForm = this.fb.group({
      code: [{ value: this.member ? this.member.code : '', disabled: !this.isCreating }],
      label: [this.member ? this.member.label : ''],
      firstName: [this.member ? this.member.firstName : ''],
      lastName: [this.member ? this.member.lastName : ''],
      cmrID: [this.member ? this.member.cmrData?.crmID : ''],
      isActive: [this.member ? this.member.isActive : ''],
      contactImportance: [''],
      companyRole: [''],
      memberRole: '',
      memberStatus: '',
    });

    if (!this.isCreating && !this.bulkingMode) {
      if (this.member.cmrData?.contactImportance) {
        const index = this.contactImportanceOptions.findIndex(
          (x) => x.label === this.member.cmrData?.contactImportance,
        );
        if (index > -1) {
          this.memberForm.get('contactImportance').setValue(this.contactImportanceOptions[index]);
        }
      } else {
        this.memberForm.get('contactImportance').setValue(this.contactImportanceOptions[0]);
      }

      if (this.member.cmrData?.companyRole) {
        const index = this.companyRoleOptions.findIndex((x) => x.label === this.member.cmrData?.companyRole);
        if (index > -1) {
          this.memberForm.get('companyRole').setValue(this.companyRoleOptions[index]);
        }
      } else {
        this.memberForm.get('companyRole').setValue(this.companyRoleOptions[0]);
      }
      this.memberAux = clone(this.member);
    }

    if (this.bulkingMode) {
      this.memberForm.removeControl('code');
      this.memberForm.removeControl('label');
      this.memberForm.removeControl('firstName');
      this.memberForm.removeControl('lastName');
      this.memberForm.removeControl('cmrID');
      this.memberForm.removeControl('contactImportance');
      this.memberForm.removeControl('companyRole');
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  closeModal() {
    this.member = clone(this.memberAux);
    this.activeModal.close(true);
  }

  onSave() {
    if (this.bulkingMode) {
      const update: UpdateMemberInput = {
        member: '',
        isActive: this.memberForm.get('isActive').value,
      };
      this.activeModal.close(update);
    } else {
      if (this.isCreating) {
        const memberCode = this.memberForm.get('code').value;
        const memberRole = this.memberForm.get('memberRole').value.code;
        if (memberCode !== '') {
          this.activeOrgs.forEach((org, index) => {
            const add: MemberToOrganizationInput = {
              member: memberCode,
              organization: org.code,
            };
            const role: MemberDataInOrganization = {
              role: memberRole,
            };

            this.memberNetworkService
              .addMemberToOrg(add, role)
              .then((rs) => {
                this.notificationService.success('Member succesfully added to ' + org.code + '.', 'Success');
                if (index === this.activeOrgs.length - 1) {
                  this.activeModal.close(rs);
                }
              })
              .catch((err) => {
                this.notificationService.handleGatewayAndGraphqlErrors(err);
              });
          });
        } else {
          this.notificationService.error('You have to set the member code');
        }
      } else {
        const update: UpdateMemberInput = {
          member: this.memberForm.get('code').value,
          label: this.memberForm.get('label').value,
          //isActive: this.memberForm.get('isActive').value,
          firstName: this.memberForm.get('firstName').value,
          lastName: this.memberForm.get('lastName').value,
          crmData: {
            crmID: this.memberForm.get('cmrID').value ? this.memberForm.get('cmrID').value : '',
          },
        };

        if (this.memberForm.get('contactImportance').value.code !== null) {
          update.crmData.contactImportance = this.memberForm.get('contactImportance').value.label;
        }

        if (this.memberForm.get('companyRole').value.code !== null) {
          update.crmData.companyRole = this.memberForm.get('companyRole').value.label;
        }

        this.memberNetworkService
          .updateMember(update)
          .then((rs) => {
            this.notificationService.success('Member succesfully updated.', 'Success');
            this.activeModal.close(rs);
          })
          .catch((err) => {
            this.notificationService.handleGatewayAndGraphqlErrors(err);
          });
      }
    }
  }
}
