export enum OrganizationContractType {
  CONTRACT_TCP = 'CONTRACT_TCP',
  CONTRACT_AFFILIATE = 'CONTRACT_AFFILIATE',
  CONTRACT_RESELLER = 'CONTRACT_RESELLER',
  ANNEX_POWER_BUYER = 'ANNEX_POWER_BUYER',
  ANNEX_PREMIUM_SELLER = 'ANNEX_PREMIUM_SELLER',
  ANNEX_SELECTIVE_PREMIUM_SELLER = 'ANNEX_SELECTIVE_PREMIUM_SELLER',
  ANNEX_STANDARD_SELLER = 'ANNEX_STANDARD_SELLER',
  ANNEX_STANDARD_BUYER = 'ANNEX_STANDARD_BUYER',
  ANNEX_PREFERRED_SELLER = 'ANNEX_PREFERRED_SELLER',
}

export enum ConnectionType {
  HOTEL_X_BUYER_ONLY = 'HOTEL_X_BUYER_ONLY',
  HOTEL_LEGACY_BUYER_ONLY = 'HOTEL_LEGACY_BUYER_ONLY',
  CHANNEL_X_BUYER_ONLY = 'CHANNEL_X_BUYER_ONLY',
  HOTEL_PULL_LEGACY_SELLER_ONLY = 'HOTEL_PULL_LEGACY_SELLER_ONLY',
  HOTEL_PULL_SELLER_GENERIC = 'HOTEL_PULL_SELLER_GENERIC',
  HOTEL_PUSH_LEGACY_SELLER_ONLY = 'HOTEL_PUSH_LEGACY_SELLER_ONLY',
  HOTEL_PUSH_SELLER_GENERIC = 'HOTEL_PUSH_SELLER_GENERIC',
  DMC_X = 'DMC_X',
  FLIGHTS_LEGACY_SELLER_ONLY = 'FLIGHTS_LEGACY_SELLER_ONLY',
  PAYMENT_LEGACY_SELLER_ONLY = 'PAYMENT_LEGACY_SELLER_ONLY',
  B2B_VIRTUAL_AGENCY_TOOL_BUYER_ONLY = 'B2B_VIRTUAL_AGENCY_TOOL_BUYER_ONLY',
  TRANSPORTATION_FLIGHT_LEGACY_BUYER_ONLY = 'TRANSPORTATION_FLIGHT_LEGACY_BUYER_ONLY',
  FLIGHT_X_BUYER_ONLY = 'FLIGHT_X_BUYER_ONLY',
  DIRECT_X = 'DIRECT_X',
  DISTRIBUTE = 'DISTRIBUTE',
  INVENTORY = 'INVENTORY',
  ACTIVITIES_LEGACY = 'ACTIVITIES_LEGACY',
  CARS_LEGACY = 'CARS_LEGACY',
  TRANSFERS_LEGACY = 'TRANSFERS_LEGACY',
  VIRTUAL_ACCOUNT_LEGACY = 'VIRTUAL_ACCOUNT_LEGACY',
}

export interface Country {
  value: string;
  text: string;
}
