export function clone(obj) {
  let copy;

  // Handle the 3 simple types, and null or undefined
  if (null === obj || 'object' !== typeof obj) {
    return obj;
  }

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = [];
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = clone(obj[i]);
    }
    return copy;
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {};
    for (const attr in obj) {
      if (obj.hasOwnProperty(attr)) {
        copy[attr] = clone(obj[attr]);
      }
    }
    return copy;
  }

  throw new Error('Unable to copy obj! Its type is not supported.');
}

export function buildNeededColorsEchart(colors: any, numberNeeded: number) {
  const arrayColors = ([] = [
    colors.primaryLight,
    colors.successLight,
    colors.infoLight,
    colors.warningLight,
    colors.dangerLight,
    colors.primary,
    colors.success,
    colors.info,
    colors.warning,
    colors.danger,
    colors.bg,
    colors.fg,
    colors.fgHeading,
    colors.fgText,
    colors.fgHighlight,
    colors.layoutBg,
    colors.separator,
  ]);

  return arrayColors.slice(0, numberNeeded);
}
