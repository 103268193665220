import { gql } from 'apollo-angular';

export const getBuyerTechPlatform = gql`
  query {
    social {
      allProfilesV2(where: { OR: [{ isSupplyBridge: true }, { isDemandBridge: true }] }) {
        adviseMessage {
          description
        }
        edges {
          node {
            adviseMessage {
              description
            }
            profileData {
              id
              name
              buyerCategory
              isSupplyBridge
              isDemandBridge
              status
              owner {
                organizationsData {
                  code
                  isTest
                }
              }
            }
          }
        }
      }
    }
  }
`;
