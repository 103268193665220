<table class="xen-table" [ngClass]="classes">
  <tr>
    <th><a class="sortable sortable--up">Monday</a></th>
    <th><a class="sortable sortable--down">Tuesday</a></th>
    <th>Wednesday</th>
    <th>Thursday</th>
    <th>Friday</th>
    <th>Saturday</th>
    <th>Sunday</th>
  </tr>
  <tr>
    <td>Bread</td>
    <td>Bread</td>
    <td>Cookies</td>
    <td>Juice</td>
    <td>Bread</td>
    <td>Cookies</td>
    <td>Cake</td>
  </tr>
  <tr>
    <td>Fruit</td>
    <td>Fruit</td>
    <td>Fruit</td>
    <td>Fruit</td>
    <td>Fruit</td>
    <td>Fruit</td>
    <td>Fruit</td>
  </tr>
  <tr>
    <td>Meat</td>
    <td>Vegetables</td>
    <td>Rice</td>
    <td>Vegetables</td>
    <td>Fish</td>
    <td>Vegetables</td>
    <td>Paste</td>
  </tr>
  <tr>
    <td>Sandwich</td>
    <td>Fruit</td>
    <td>Sandwich</td>
    <td>Fruit</td>
    <td>Sandwich</td>
    <td>Fruit</td>
    <td>Sandwich</td>
  </tr>
  <tr>
    <td>Soup</td>
    <td>Omelette</td>
    <td>Pizza</td>
    <td>Egg</td>
    <td>Soup</td>
    <td>Egg</td>
    <td>Soup</td>
  </tr>
</table>
