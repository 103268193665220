<nb-card>
  <nb-card-header> Billing Account Contacts </nb-card-header>

  <nb-card-body>
    <div *ngIf="isEditor && selectedBA" class="mb-3">
      <p-button label="Create" icon="pi pi-plus" (onClick)="onCreate()"></p-button>
    </div>
    <p-table
      #dt2
      [value]="contactsLoadSource"
      [paginator]="false"
      [rows]="contactsLoadSource?.length"
      [showCurrentPageReport]="false"
      styleClass="p-datatable-gridlines p-datatable-striped"
      [tableStyle]="{ 'min-width': '50rem' }"
      [resizableColumns]="true"
      columnResizeMode="expand"
      [loading]="isLoading"
      loadingIcon="pi pi-spin pi-spinner"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let head of header">
            {{ head.label }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-contact>
        <tr>
          <td>{{ contact.id }}</td>
          <td>{{ contact.email }}</td>
          <td>
            <p-checkbox
              [ngModel]="contact.receiveInvoices"
              [binary]="true"
              inputId="binary"
              disabled="true"
            ></p-checkbox>
          </td>
          <td>{{ contact.odooCode }}</td>
          <td>{{ contact.createdAt | date: 'dd-MM-yyyy' }}</td>
          <td>{{ contact.updatedAt | date: 'dd-MM-yyyy' }}</td>
          <td>
            <i
              title="View Details"
              class="icon"
              [ngClass]="{ 'nb-search': !isEditor, 'nb-edit': isEditor }"
              (click)="onEdit(contact)"
            ></i>
            <i *ngIf="isEditor" title="Delete" class="icon nb-trash" (click)="onDelete(contact)"></i>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="10" class="text-center">No billing account contacts found.</td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>
