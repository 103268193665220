import { gql } from 'apollo-angular';

export const updateMemberRole = gql`
  mutation UpdateRoleInOrganization($data: MemberDataInOrganization!, $where: MemberToOrganizationInput!) {
    admin {
      updateMemberDataInOrganization(data: $data, where: $where) {
        code
        adviseMessage {
          code
          description
        }
      }
    }
  }
`;

export const updateMemberServiceRole = gql`
  mutation ($data: MemberServiceRoleInOrganization!, $where: MemberToOrganizationInput!) {
    admin {
      updateMemberServiceRole(data: $data, where: $where) {
        code
        adviseMessage {
          code
          description
        }
      }
    }
  }
`;
export const removeMemberServiceRole = gql`
  mutation ($data: RemoveServiceRoleInOrganization!, $where: MemberToOrganizationInput!) {
    admin {
      removeMemberServiceRole(data: $data, where: $where) {
        code
        adviseMessage {
          code
          description
        }
      }
    }
  }
`;
