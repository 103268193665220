import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../../../@core/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AccessesTableComponent } from './components/accesses-table/accesses-table.component';
import { AccessesComponent } from './components/accesses.component';
import { AccessesRoutingModule } from './accesses-routing.module';

import { NbActionsModule, NbCardModule, NbCheckboxModule, NbIconModule, NbToggleModule } from '@nebular/theme';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { AccessesModalComponent } from './components/accesses-modal/accesses-modal.component';
import { AccessesGroupsModalComponent } from './components/accesses-groups-modal/accesses-groups-modal.component';
import { AccessesSafeDeactivateModalComponent } from './components/accesses-safeDeactivate-modal/accesses-safeDeactivate-modal.component';
import { TabViewModule } from 'primeng/tabview';
import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [
    AccessesComponent,
    AccessesTableComponent,
    AccessesModalComponent,
    AccessesGroupsModalComponent,
    AccessesSafeDeactivateModalComponent,
  ],
  imports: [
    TableModule,
    InputSwitchModule,
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NbActionsModule,
    NbCardModule,
    NbCheckboxModule,
    NbIconModule,
    AccessesRoutingModule,
    MultiSelectModule,
    DropdownModule,
    NbToggleModule,
    TabViewModule,
    ButtonModule,
  ],
})
export class AccessesModule {}
