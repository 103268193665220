import { createProfile } from './createProfile';
import { deleteProfile } from './deleteProfile';
import { updateProfile } from './updateProfile';
import { createCategory } from './createCategory';
import { createSubCategory } from './createSubCategory';
import { updateSubCategory } from './updateSubCategory';
import { updateCategory } from './updateCategory';
import { deleteCategory } from './deleteCategory';
import { deleteSubCategory } from './deleteSubCategory';
import { archiveOrganization } from './archiveOrganization';
import { unArchiveOrganization } from './unArchiveOrganization';

export const GraphqlNetworkMutations = {
  createSubCategory: createSubCategory,
  createCategory: createCategory,
  deleteCategory: deleteCategory,
  deleteSubCategory: deleteSubCategory,
  updateSubCategory: updateSubCategory,
  updateCategory: updateCategory,
  createProfile: createProfile,
  updateProfile: updateProfile,
  deleteProfile: deleteProfile,
  archiveOrganization: archiveOrganization,
  unArchiveOrganization: unArchiveOrganization,
};
