export enum OrganizationTypeInput {
  BUYER = 'BUYER',
  SELLER = 'SELLER',
  BOTH = 'BOTH',
}

export interface OrganizationsQueryInput {
  codes?: string[];
  label?: string;
  contains?: string;
  organizationType?: OrganizationTypeInput;
  first?: number;
  last?: number;
  before?: string;
  after?: string;
  isPublic?: boolean;
  includeArchived?: boolean;
}
