import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegionNetworkService } from '../../../../services/region-network.services';
import { ToastsService } from '@tgx/shared/toasts';
import { NewManageRegionsFormComponent } from '../new-manage-regions-form/new-manage-regions-form.component';
import { ModalDeleteComponent } from 'apps/web-admin/src/app/@core/shared/modal-delete/modal-delete.component';

@Component({
  selector: 'tgx-manage-regions-table',
  templateUrl: './manage-regions-table.component.html',
  styleUrls: ['./manage-regions-table.component.scss'],
})
export class ManageRegionsTableComponent implements OnInit {
  regionsList: string[];
  isLoading: boolean;

  constructor(
    private regionNetworkService: RegionNetworkService,
    private toastsService: ToastsService,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.getRegions();
  }

  onCreate() {
    const activeModal = this.modalService.open(NewManageRegionsFormComponent, {
      size: 'lg',
      container: 'nb-layout',
    });

    activeModal.result.then(() => {
      this.getRegions();
    });
  }

  onDelete(region) {
    const activeModal = this.modalService.open(ModalDeleteComponent, {
      size: 'lg',
      container: 'nb-layout',
    });

    activeModal.componentInstance.title = 'Delete Region';
    activeModal.componentInstance.message = 'You are going to delete the following Region:';
    activeModal.componentInstance.dataName = region;
    activeModal.componentInstance.buttonOkTitle = 'Delete Region';

    activeModal.result.then((res) => {
      if (res) {
        this.regionNetworkService.deleteRegion(region).subscribe({
          next: () => {
            this.getRegions();
          },
          error: (er) => {
            this.toastsService.addToast('Error', 'bg-danger', er.error.message);
          },
        });
      }
    });
  }

  private getRegions() {
    this.isLoading = true;
    this.regionNetworkService.allRegions().subscribe({
      next: (resp) => {
        this.regionsList = resp;
        this.isLoading = false;
      },
      error: (er) => {
        this.isLoading = false;
        this.toastsService.addToast('ERROR', 'bg-danger', er.error.message);
      },
    });
  }
}
