import { gql } from 'apollo-angular';

export const getWidgetProfiles = gql`
  query GetProfiles {
    social {
      allProfilesV2 {
        edges {
          node {
            code
            profileData {
              name
              owner {
                code
              }
            }
          }
        }
      }
    }
  }
`;
