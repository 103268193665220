<div class="modal-header">
  <span>{{ header }}</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="defaultCostForm">
    <nb-card>
      <nb-card-header> Data </nb-card-header>
      <nb-card-body>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="id">ID</label>
              <input type="text" class="form-control" name="id" formControlName="id" />
            </div>
          </div>
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger': defaultCostForm.get('amount').errors && defaultCostForm.get('amount').touched,
              }"
            >
              <label class="mb-1" for="amount"
                >Amount
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="defaultCostForm.get('amount')"
                [errors]="defaultCostForm.get('amount').errors"
              ></tooltip-error>
              <input
                type="number"
                class="form-control"
                formControlName="amount"
                [ngClass]="{
                  'form-control-danger': defaultCostForm.get('amount').errors && defaultCostForm.get('amount').touched,
                }"
                name="amount"
              />
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="partnerType"
                >Partner Type
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="defaultCostForm.get('partnerType')"
                [errors]="defaultCostForm.get('partnerType').errors"
              ></tooltip-error>
              <div class="form-group">
                <p-dropdown
                  (onHide)="validType('partnerType')"
                  [options]="partnerTypes"
                  [appendTo]="'body'"
                  baseZIndex="999"
                  formControlName="partnerType"
                  optionLabel="label"
                  filterBy="label"
                >
                  <ng-template pTemplate="selectedItem">
                    <div *ngIf="defaultCostForm.get('partnerType').value">
                      <div>{{ defaultCostForm.get('partnerType').value.label }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-ci pTemplate="ci">
                    <div>
                      <div>{{ ci.label }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="paymentType"
                >Payment Type
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="defaultCostForm.get('paymentType')"
                [errors]="defaultCostForm.get('paymentType').errors"
              ></tooltip-error>
              <div class="form-group">
                <p-dropdown
                  (onHide)="validType('paymentType')"
                  [options]="paymentTypes"
                  [appendTo]="'body'"
                  baseZIndex="999"
                  formControlName="paymentType"
                  optionLabel="label"
                  filterBy="label"
                >
                  <ng-template pTemplate="selectedItem">
                    <div *ngIf="defaultCostForm.get('paymentType').value">
                      <div>{{ defaultCostForm.get('paymentType').value.label }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-ci pTemplate="ci">
                    <div>
                      <div>{{ ci.label }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()">{{ isEditor ? 'Cancel' : 'Close' }}</button>
  <button *ngIf="isEditor" class="btn btn-md btn-primary" [disabled]="!validForm()" (click)="onSave()">
    {{ isCreating ? 'Create' : 'Update' }}
  </button>
</div>
