import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../../../../../../@core/services/_index-core.services';
import { ClientsEntitiesService } from '../../../../services/clients-entities.service';

@Component({
  selector: 'tgx-clients-safeDeactivate-modal',
  templateUrl: './clients-safeDeactivate-modal.component.html',
  styleUrls: ['./clients-safeDeactivate-modal.component.scss'],
})
export class ClientsSafeDeactivateModalComponent implements OnInit {
  @Input() client: any;
  @Input() header: string;

  public clientsSafeDeactivateForm: UntypedFormGroup;

  constructor(
    private activeModal: NgbActiveModal,
    private notificationsService: NotificationService,
    private clientsService: ClientsEntitiesService,
  ) {}

  ngOnInit(): void {}

  closeModal() {
    this.activeModal.close(true);
  }

  async onSave() {
    const input = { search: this.client.name, searchBy: 'NAME' };
    const result = await this.clientsService.deactivateClient(input);
    const hasEmptyAdviseMessage = result && result.adviseMessage == null;

    if (hasEmptyAdviseMessage) {
      this.notificationsService.success('Client was successfully deactivated');
    } else {
      this.notificationsService.error('There was an error deactivating the Client, please contact your admin');
    }
    this.activeModal.close(true);
  }
}
