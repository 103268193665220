<nb-card>
  <nb-card-header> Partners </nb-card-header>

  <nb-card-body>
    <div *ngIf="isEditor" class="mb-3">
      <p-button label="Create" icon="pi pi-plus" (onClick)="onCreate()"></p-button>
    </div>
    <p-table
      #dt
      [value]="partnersLoadSource"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      styleClass="p-datatable-gridlines p-datatable-striped"
      [tableStyle]="{ 'min-width': '50rem' }"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
      [resizableColumns]="true"
      columnResizeMode="expand"
      [loading]="isLoading"
      loadingIcon="pi pi-spin pi-spinner"
    >
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="id">
            ID
            <p-sortIcon field="id"></p-sortIcon>
          </th>
          <th pSortableColumn="name">
            Name
            <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th pSortableColumn="orgCode">
            Org code
            <p-sortIcon field="orgCode"></p-sortIcon>
          </th>
          <th pSortableColumn="odooCode">
            Odoo code
            <p-sortIcon field="odooCode"></p-sortIcon>
          </th>
          <th>Created at</th>
          <th>Updated at</th>
          <th>Options</th>
        </tr>
        <tr>
          <th>
            <p-columnFilter
              [showClearButton]="false"
              [showMenu]="false"
              placeholder="ID"
              type="text"
              field="id"
              (input)="filter($event.target.value, 'id')"
            ></p-columnFilter>
          </th>
          <th>
            <p-columnFilter
              [showClearButton]="false"
              [showMenu]="false"
              placeholder="Name"
              type="text"
              field="name"
              (input)="filter($event.target.value, 'name')"
            ></p-columnFilter>
          </th>
          <th>
            <p-columnFilter
              [showClearButton]="false"
              placeholder="OrgCode"
              [showMenu]="false"
              type="text"
              field="orgCode"
              (input)="filter($event.target.value, 'orgCode')"
            ></p-columnFilter>
          </th>
          <th>
            <p-columnFilter
              [showClearButton]="false"
              placeholder="OdooCode"
              [showMenu]="false"
              type="text"
              field="odooCode"
              (input)="filter($event.target.value, 'odooCode')"
            ></p-columnFilter>
          </th>
          <th></th>
          <th></th>
          <th width="3rem"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-partner>
        <tr>
          <td>{{ partner.id }}</td>
          <td>{{ partner.name }}</td>
          <td>{{ partner.orgCode }}</td>
          <td>{{ partner.odooCode }}</td>
          <td>{{ partner.createdAt | date: 'dd-MM-yyyy' }}</td>
          <td>{{ partner.updatedAt | date: 'dd-MM-yyyy' }}</td>
          <td>
            <i
              title="{{ isEditor ? 'Edit' : 'View' }} partner"
              [ngClass]="{
                'nb-search': !isEditor,
                'nb-edit': isEditor,
              }"
              class="icon"
              (click)="onEdit(partner)"
            ></i>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="10" class="text-center">No partners found.</td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>
