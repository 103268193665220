<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel">Update {{ member }} role</h4>
</div>
<div class="modal-body">
  <div>
    <p class="desc">{{ message }}</p>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="onCancel()">Cancel</button>
  <button class="btn btn-md btn-primary" (click)="onSave()">Save</button>
</div>
