import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GqlService } from '@tgx/shared/data-access/graphql';
import { OrganizationsData } from '@tgx/shared/interfaces';
import { sharedAdminQueries } from '@tgx/shared/data-access/graphql';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(
    private http: HttpClient,
    private gqlService: GqlService,
  ) {}

  // get members by org code
  async getAllMembersByOrgV2(code_in: string): Promise<OrganizationsData> {
    const body = {
      where: {
        search: code_in,
        searchBy: 'CODE',
      },
    };

    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(sharedAdminQueries.getAllMembersByOrgV2, body)
        .then((response) => {
          if (response['admin']['organization']['organizationsData']) {
            resolve(response['admin']['organization']['organizationsData']);
          } else if (response['admin']['organization']['adviseMessage']) {
            resolve(null);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          console.log(error);
          resolve(null);
        });
    });
  }
}
