import { gql } from 'apollo-angular';

export const getBillingAccountContacts = gql`
  query billingAccountContacts($where: BillingAccountContactWhereInput!) {
    billingAccountContacts(where: $where) {
      id
      email
      receiveInvoices
      odooCode
      createdAt
      updatedAt
    }
  }
`;
