<nb-card>
  <nb-card-body>
    <div class="row mb-3">
      <div class="col-1">
        <label class="mt-2">Contract: </label>
      </div>
      <div class="col-6">
        <p-dropdown
          (onHide)="getRequests()"
          [options]="contractsSelector"
          [appendTo]="'body'"
          baseZIndex="999"
          [disabled]="contractsSelector[0].code === null"
          [(ngModel)]="selectedContract"
          optionLabel="label"
          filterBy="label"
        >
          <ng-template pTemplate="selectedItem">
            <div *ngIf="selectedContract">
              <div>{{ selectedContract?.label }}</div>
            </div>
          </ng-template>
          <ng-template let-ci pTemplate="ci">
            <div>
              <div>{{ ci.label }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
  </nb-card-body>
</nb-card>

<div class="row" *ngIf="selectedContract?.code">
  <div class="col-12">
    <nb-card>
      <nb-tabset fullWidth class="mt-4">
        <nb-tab tabTitle="Recieved">
          <nb-card>
            <nb-card-header> Requests Recieved </nb-card-header>
            <nb-card-body>
              <p-table
                #dt1
                [value]="requestRecievedLoadSource"
                [paginator]="true"
                [rows]="10"
                [showCurrentPageReport]="true"
                styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
                [tableStyle]="{ 'min-width': '50rem' }"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [rowsPerPageOptions]="[10, 25, 50]"
                [resizableColumns]="true"
                columnResizeMode="expand"
                [loading]="isLoading"
                loadingIcon="pi pi-spin pi-spinner"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th *ngFor="let head of header" [pSortableColumn]="head.field">
                      {{ head.label }}
                      <p-sortIcon [field]="head.field"></p-sortIcon>
                    </th>
                  </tr>
                  <tr>
                    <th *ngFor="let head of header">
                      <p-columnFilter
                        *ngIf="head.field !== 'createdAt' && head.field !== 'updatedAt'"
                        type="text"
                        [field]="head.field"
                        [showMenu]="false"
                        [showClearButton]="false"
                        (input)="filterDT1($event.target.value, head.field)"
                      ></p-columnFilter>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-connection>
                  <tr>
                    <td>{{ connection.id }}</td>
                    <td>{{ connection.contractConnectionId }}</td>
                    <td>{{ connection.buyer }}</td>
                    <td>{{ connection.seller }}</td>
                    <td>{{ connection.currentConnectionType }}</td>
                    <td>{{ connection.requestedConnectionType }}</td>
                    <td>{{ connection.status }}</td>
                    <td>{{ connection.requestUser }}</td>
                    <td>{{ connection.responseUser }}</td>
                    <td>{{ connection.createdAt | date: 'dd-MM-yyyy' }}</td>
                    <td>{{ connection.updatedAt | date: 'dd-MM-yyyy' }}</td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="15" class="text-center">No request found.</td>
                  </tr>
                </ng-template>
              </p-table>
            </nb-card-body>
          </nb-card>
        </nb-tab>
        <nb-tab tabTitle="Send">
          <nb-card>
            <nb-card-header> Requests Send </nb-card-header>
            <nb-card-body>
              <p-table
                #dt2
                [value]="requestSendLoadSource"
                [paginator]="true"
                [rows]="10"
                [showCurrentPageReport]="true"
                styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
                [tableStyle]="{ 'min-width': '50rem' }"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [rowsPerPageOptions]="[10, 25, 50]"
                [resizableColumns]="true"
                columnResizeMode="expand"
                [loading]="isLoading"
                loadingIcon="pi pi-spin pi-spinner"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th *ngFor="let head of header" [pSortableColumn]="head.field">
                      {{ head.label }}
                      <p-sortIcon *ngIf="head.field !== 'Options'" [field]="head.field"></p-sortIcon>
                    </th>
                  </tr>
                  <tr>
                    <th *ngFor="let head of header" [style]="head.field === 'Options' ? 'width: 3rem' : ''">
                      <p-columnFilter
                        type="text"
                        *ngIf="head.field !== 'createdAt' && head.field !== 'updatedAt'"
                        [field]="head.field"
                        [showClearButton]="false"
                        [showMenu]="false"
                        (input)="filterDT2($event.target.value, head.field)"
                      ></p-columnFilter>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-connection>
                  <tr>
                    <td>{{ connection.id }}</td>
                    <td>{{ connection.contractConnectionId }}</td>
                    <td>{{ connection.buyer }}</td>
                    <td>{{ connection.seller }}</td>
                    <td>{{ connection.currentConnectionType }}</td>
                    <td>{{ connection.requestedConnectionType }}</td>
                    <td>{{ connection.status }}</td>
                    <td>{{ connection.requestUser }}</td>
                    <td>{{ connection.responseUser }}</td>
                    <td>{{ connection.createdAt | date: 'dd-MM-yyyy' }}</td>
                    <td>{{ connection.updatedAt | date: 'dd-MM-yyyy' }}</td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="15" class="text-center">No connections found.</td>
                  </tr>
                </ng-template>
              </p-table>
            </nb-card-body>
          </nb-card>
        </nb-tab>
      </nb-tabset>
    </nb-card>
  </div>
</div>
