export const environment = {
  production: false,
  env: 'dev',
  hotjar_site_id: 3360733,
  auth0: {
    domain: 'xtg.eu.auth0.com',
    // clientId: 'z5bs7Yo5L5tZ18hU7aHskyQu7nutyagO',
    // domain: 'login.travelgate.com',
    clientId: 'DuRsmfRHDwsbgL8mkO5ng3fbgfzVlJ75',
  },
  recaptchaV3: {
    key: '6Ldr0usiAAAAAMWOpeWqAvuLqDys7YsMzCS4xCfU',
  },
  apis: {
    gateway: 'https://api.travelgatex.com/',
    gateway_public: 'https://api-public.travelgatex.com/',
    billing_api: 'https://dev-billing.travelgatex.com/',
    billing_calculation: 'https://dev-billing-calculation.travelgatex.com/v1/',
    iam_web: 'https://api-iam.travelgatex.com/controller/query',
    network_service: 'https://network-service.travelgatex.com/',
    data: 'https://data-gc-ew.travelgatex.com/data-catalog/query',
    insights_api: 'https://insights.travelgatex.com/',
    social: 'https://social.travelgatex.com/',
    notifications_service: 'https://dev-platform.travelgatex.com/notifications-service/api/',
    distribution: {
      ftp: 'https://distribution-az-we.travelgatex.com/files-api/ftp',
    },
    content: 'https://content-tu-we.travelgatex.com/taskmanager/',
    tickets_service: 'https://dev-platform.travelgatex.com/tickets-service/api/',
    mapping: 'https://map-hotel.travelgate.com/wizard-api/',
    task_manager: 'https://content-tu-we.travelgatex.com/taskmanager',
  },
  urlServerWeb: 'https://dev-app.travelgate.com/',
};
