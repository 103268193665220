import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BestConnection, BestPartner } from '../interfaces/_index.billing.interfaces';
import { GraphqlApiBillingQueries } from './queries/_index-billing-queries';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  urlBillingApi: string;

  constructor(
    @Inject('urlBillingApi') urlBillingApi: string,
    private http: HttpClient,
  ) {
    this.urlBillingApi = urlBillingApi;
  }

  async getBestConnections(): Promise<BestConnection[]> {
    return await new Promise((resolve, reject) => {
      this.http
        .post(this.urlBillingApi, {
          query: GraphqlApiBillingQueries.getBestFiveConnections,
        })
        .subscribe(
          (res: any) => {
            if (res && res.data && res.data.bestFiveConnections?.length > 0) {
              resolve(res.data.bestFiveConnections);
            } else {
              reject(res);
            }
          },
          (err) => {
            reject(err);
          },
        );
    });
  }

  async getBestPartners(): Promise<BestPartner[]> {
    return await new Promise((resolve, reject) => {
      this.http
        .post(this.urlBillingApi, {
          query: GraphqlApiBillingQueries.getBestFivePartners,
        })
        .subscribe(
          (res: any) => {
            if (res && res.data && res.data.bestFivePartners?.length > 0) {
              resolve(res.data.bestFivePartners);
            } else {
              reject(res);
            }
          },
          (err) => {
            reject(err);
          },
        );
    });
  }
}
