import { gql } from 'apollo-angular';

export const deactivateClient = gql`
  mutation ($where: ClientWhereUniqueInput!) {
    admin {
      deactivateClient(where: $where) {
        clientData {
          code
          name
          isActive
          group {
            code
          }
        }
        adviseMessage {
          code
          description
        }
      }
    }
  }
`;
