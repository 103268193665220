import { SharedModule } from '../../../../@core/shared/shared.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { NbCardModule, NbCheckboxModule } from '@nebular/theme';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { MappingToolComponent } from './components/mapping-tool.component';
import { SharedUiStorybookModule } from '@tgx/shared/ui/storybook';
import { SharedUiStylesModule } from '@tgx/shared/ui/styles';
import { NonValidateTableComponent } from './components/non-validate-table/non-validate-table.component';
import { UnmapModalComponent } from './components/unmap-modal/unmap-modal.component';
import { MergeModalComponent } from './components/merge-modal/merge-modal.component';
import { InvalidateModalComponent } from './components/invalidate-modal/invalidate-modal.component';
import { ValidateTableComponent } from './components/validate-table/validate-table.component';
import { UnmapedTableComponent } from './components/unmaped-table/unmaped-table.component';
import { ValidateModalComponent } from './components/validate-modal/validate-modal.component';
import { SkeletonModule } from 'primeng/skeleton';
import { RemapModalComponent } from './components/remap-modal/remap-modal.component';

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NbCardModule,
    NbCheckboxModule,
    CommonModule,
    AutoCompleteModule,
    CalendarModule,
    MultiSelectModule,
    NgbModule,
    DropdownModule,
    SharedUiStorybookModule,
    SharedUiStylesModule,
    SkeletonModule,
  ],
  declarations: [
    MappingToolComponent,
    NonValidateTableComponent,
    ValidateTableComponent,
    UnmapedTableComponent,
    UnmapModalComponent,
    RemapModalComponent,
    MergeModalComponent,
    InvalidateModalComponent,
    ValidateModalComponent,
  ],
  providers: [NgbTooltipConfig],
})
export class MappingToolModule {}
