import { gql } from 'apollo-angular';

export const getAllMembersByOrgV2 = gql`
  query ($code_in: [ID!], $include_archived: Boolean) {
    admin {
      allOrganizations(where: { code_in: $code_in, includeArchived: $include_archived }) {
        totalCount
        edges {
          node {
            organizationsData {
              code
              label
              members {
                role
                member {
                  code
                  isActive
                }
              }
            }
          }
        }
      }
    }
  }
`;
