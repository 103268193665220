import { gql } from 'apollo-angular';

export const setOrganizationOwner = gql`
  mutation setOrganizationOwner($where: OrganizationWhereUniqueInput!, $ownerCode: String!) {
    admin {
      setOrganizationOwner(where: $where, ownerCode: $ownerCode) {
        code
        createdAt
        updatedAt
        adviseMessage {
          code
          description
        }
      }
    }
  }
`;
