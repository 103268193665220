<div class="xen-input">
  <label class="form-label flex" *ngIf="label" [for]="id">
    {{ label }}
    <span class="is-required" *ngIf="required">*</span>
    <span class="xen-input__tooltip-icon" *ngIf="tooltip" ngbTooltip="{{ tooltip_text }}" tooltipClass="xen-tooltip">
      <i class="fas fa-info-square ms-1"></i>
    </span>
  </label>
  <div [formGroup]="form" class="input-group xen-input-group">
    <span class="xen-input__icon" [ngClass]="iconClasses" *ngIf="icon"><i class="{{ icon_snippet }}"></i></span>
    <input
      type="{{ type }}"
      [id]="id"
      [name]="formControlName"
      [formControlName]="formControlName"
      [value]="value"
      [ngClass]="inputClasses"
      placeholder="{{ placeholder }}"
      [required]="required"
      [disabled]="disabled"
      [readOnly]="readOnly"
    />
  </div>
  <div class="xen-input__feedback" [ngClass]="feedbackClasses" *ngIf="validation">{{ feedback }}</div>
</div>
