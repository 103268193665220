import { gql } from 'apollo-angular';

export const getAllSuppliers = gql`
  query ($where: SupplierWhereInput) {
    admin {
      allSuppliers(where: $where) {
        totalCount
        edges {
          node {
            supplierData {
              code
              name
              serviceApi
              api
              isActive
              isPublic
              provider {
                code
                isActive
              }
              sharedDefault {
                code
              }
              owner {
                code
                organizationData {
                  label
                }
              }
              context
              groupContent {
                code
              }
            }
            deletedAt
          }
        }
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
