import { gql } from 'apollo-angular';

export const getDefaultCosts = gql`
  {
    defaultCosts(orderBy: { partnerType: asc }) {
      id
      amount
      partnerType
      paymentType
      createdAt
      updatedAt
      ContractType {
        id
        name
      }
    }
  }
`;
