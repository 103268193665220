import { gql } from 'apollo-angular';

export const getConnectionsFullInfoById = gql`
  query getConnectionsById($id: IntFilter!) {
    connectionsFullInfo(id: $id) {
      id
      buyerCode
      buyerName
      sellerCode
      sellerName
      isPush
      api
      createdAt
      updatedAt
    }
  }
`;
