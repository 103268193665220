<div class="toast-container position-fixed top-0 end-0 p-3">
  <ng-container *ngFor="let toast of toasts">
    <xen-toast
      header="{{ toast.header }}"
      content="{{ toast.body }}"
      [type]="
        toast.type === 'bg-danger'
          ? 'error'
          : toast.type === 'bg-primary'
            ? 'info'
            : toast.type === 'bg-success'
              ? 'success'
              : toast.type === 'bg-warning'
                ? 'warning'
                : 'default'
      "
    ></xen-toast>
  </ng-container>
</div>
