<div class="modal-header">
  <span>{{ header }}</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <nb-card>
    <nb-card-header> Dates </nb-card-header>
    <nb-card-body>
      <form [formGroup]="datesForm">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="month">Month<span class="mandatory ml-1">*</span> </label>
              <p-calendar
                [appendTo]="'body'"
                baseZIndex="999"
                class="form-control p-0 border-0 w-100"
                formControlName="month"
                view="month"
                dateFormat="mm/yy"
                [showIcon]="true"
                inputId="icon"
                [readonlyInput]="true"
                [maxDate]="minDateVal"
                placeholder="Select a month.."
              ></p-calendar>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="executionDate">Execution Date<span class="mandatory ml-1">*</span></label>
              <p-calendar
                [appendTo]="'body'"
                baseZIndex="999"
                class="form-control p-0 border-0 w-100"
                formControlName="executionDate"
                dateFormat="dd/mm/yy"
                [showIcon]="true"
                inputId="icon"
                [minDate]="minDateVal"
                [readonlyInput]="true"
                placeholder="Enter a date.."
              ></p-calendar>
            </div>
          </div>
        </div>
      </form>
    </nb-card-body>
  </nb-card>
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()">Cancel</button>
  <button class="btn btn-md btn-primary" [disabled]="datesForm.invalid" (click)="onSave()">Schedule</button>
</div>
