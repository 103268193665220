import { gql } from 'apollo-angular';

export const getContractConnectionsByBuyerAndSeller = gql`
  query getContractConnectionsByBuyerAndSeller($where: ContractConnectionWhereInput!) {
    contractconnections(where: $where, orderBy: { id: desc }) {
      id
      setupPaid
      free
      isBuy
      activationDateTime
      endDate
      connectionType
      Contract {
        id
        partnerType
        modelType
        ContractType {
          id
          name
        }
        Partner {
          id
          name
          orgCode
        }
      }
    }
  }
`;
