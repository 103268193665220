import { ProfileModalComponent } from './components/profiles-modal/profile-modal.component';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ProfilesComponent } from './components/profiles.component';
import { ProfilesRoutingModule } from './profiles.routing-module';
import { SharedModule } from './../../../../@core/shared/shared.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { NgModule } from '@angular/core';
import { NbCardModule, NbCheckboxModule } from '@nebular/theme';
import { ProfilesTableComponent } from './components/profiles-table/profiles-table.component';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { SharedUiStorybookModule } from '@tgx/shared/ui/storybook';
import { FormAutoFocusDirective } from './components/profiles-modal/form.autofocus';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { InputSwitchModule } from 'primeng/inputswitch';

@NgModule({
  imports: [
    TableModule,
    SharedModule,
    ProfilesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NbCardModule,
    NbCheckboxModule,
    AutoCompleteModule,
    MultiSelectModule,
    CommonModule,
    DropdownModule,
    CalendarModule,
    SharedUiStorybookModule,
    NgbPopoverModule,
    InputSwitchModule,
  ],
  declarations: [FormAutoFocusDirective, ProfilesComponent, ProfilesTableComponent, ProfileModalComponent],
})
export class ProfilesModule {}
