import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HotelList, MasterList, GenericMappingResponse } from '../../interfaces/models/mapping.interfaces';
import { hsCountryListIsoCodes } from '@tgx/shared/utils';
import { MappingService } from '../../../../services/mapping.service';
import { ToastsService } from '@tgx/shared/toasts';

@Component({
  selector: 'tgx-remap-modal',
  templateUrl: './remap-modal.component.html',
  styleUrls: ['./remap-modal.component.scss'],
})
export class RemapModalComponent {
  @Input() selectedHotel: HotelList;
  @Input() formParams: { countryMapping: string; hotelMapping: string };

  readonly pageSize = 3;
  private readonly limit = 3;
  private readonly offset = 0;
  page = 1;

  isLoading: boolean = false;
  similarMasterHotels: MasterList[];
  selectedMaster: MasterList;
  hotelId: string;

  tHead = [
    { label: 'Hotel master', field: 'name' },
    { label: 'Id', field: 'id' },
    { label: 'Geocode', field: 'location' },
    { label: 'Country', field: 'country' },
    { label: 'Address', field: 'address' },
  ];

  constructor(
    private activeModal: NgbActiveModal,
    private mappingService: MappingService,
    private toast: ToastsService,
  ) {}

  async ngOnInit() {
    this.isLoading = true;
    this.hotelId = this.mappingService.getHotelId(this.selectedHotel) as string;
    const similarMasters = await this.mappingService.getSimilarHotels(this.hotelId, this.offset, this.limit);
    await this.paginateSimilarMasters(similarMasters);
    this.isLoading = false;
  }

  async onPageChange(page: any) {
    let similarMasters: MasterList[];
    let results: PromiseSettledResult<any>[];
    this.page = page;
    let offset = (page - 1) * this.pageSize;
    this.isLoading = true;

    similarMasters = await this.mappingService.getSimilarHotels(this.hotelId, offset, this.limit);
    results = await Promise.allSettled([similarMasters]);

    if (results[0].status === 'fulfilled') {
      if (results[0].value.length === 0) {
        this.similarMasterHotels = [];
        this.noSimilarMastersFound();
      } else {
        await this.paginateSimilarMasters(results[0].value);
      }
    }

    this.isLoading = false;
  }

  async paginateSimilarMasters(similarMasters: MasterList[]) {
    const initialPosition = this.pageSize * this.page - this.pageSize;
    const finalPosition = this.pageSize * this.page;
    this.similarMasterHotels = similarMasters.slice(initialPosition, finalPosition);
  }

  noSimilarMastersFound() {
    return 'No similar masters found';
  }

  onSelectionChange(selectedMaster: MasterList, event: any) {
    if (selectedMaster) {
      this.selectedMaster = selectedMaster;
    } else {
      this.selectedMaster = null;
    }
  }

  async unmapHotelFromMaster() {
    if (this.selectedMaster == null) {
      this.toast.addToast('Warning!', 'bg-warning', 'Please select a master hotel', 5);
    } else {
      await this.mappingService
        .remapHotel(this.selectedMaster.id, this.hotelId)
        .then((res: GenericMappingResponse[]) => {
          if (res) {
            this.toast.addToast('Success', 'bg-success', 'Hotel successfully remapped', 5);
            this.close(res);
          }
        })
        .catch((err) => {
          this.toast.addToast('Error', 'bg-danger', 'Failed to remap hotel', 5);
          this.close();
        });
    }
  }

  findNameCountry(isoCode2: string) {
    const country = hsCountryListIsoCodes.find((x) => x.isoCode2 === isoCode2);
    if (country) {
      return country.name;
    }
    return '';
  }

  close(unmapHotelInfo?: GenericMappingResponse[]) {
    this.activeModal.close(unmapHotelInfo);
  }
}
