import { Component, Input, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import {
  ConnectionFullInfo,
  ContractConnection,
  Invoice,
  InvoiceBreakdown,
  InvoiceBreakdownUpdateInput,
} from '../../../../../interfaces/_index.billing.interfaces';
import {
  ConnectionService,
  ContractConnectionService,
  InvoiceBreakdownService,
} from '../../../../../services/_index-billing.services';
import { NotificationService } from './../../../../../../../@core/services/_index-core.services';
import { ModalDeleteComponent } from './../../../../../../../@core/shared/modal-delete/modal-delete.component';

@Component({
  selector: 'tgx-admin-billing-invoice-breakdowns-table',
  templateUrl: './invoice-breakdowns-table.component.html',
  styleUrls: ['./invoice-breakdowns-table.component.scss'],
})
export class BillingInvoiceBreakdownsTableComponent implements OnDestroy, OnChanges {
  @Input() invoiceId: number;
  @Input() isEditor: boolean;
  @Input() totalInvoice: number;
  @Input() selectedInvoice: Invoice;

  @ViewChild('dt2', { static: false }) dataTable2!: Table;

  $subs: Subscription[] = [];

  header = [
    { label: 'ChargeType', field: 'chargeType' },
    { label: 'PaymentType', field: 'paymentType' },
    { label: 'DataType', field: 'dataType' },
    { label: 'CostType', field: 'costType' },
    { label: 'Amount', field: 'amount' },
    { label: 'Quantity', field: 'quantity' },
    { label: 'Buyer', field: 'buyer' },
    { label: 'Seller', field: 'seller' },
    { label: 'Client', field: 'client' },
    { label: 'Supplier', field: 'supplier' },
    { label: 'Options', field: 'Options' },
  ];

  breakdownsLoadSource: any[];

  breakdowns: InvoiceBreakdown[];

  isLoading: boolean;

  totalSum: string;

  constructor(
    private notificationService: NotificationService,
    private invoiceBreakdownService: InvoiceBreakdownService,
    private contractConnectionService: ContractConnectionService,
    private connectionService: ConnectionService,
    private modalService: NgbModal,
  ) {}

  ngOnChanges() {
    this.isLoading = true;
    this.totalSum = '0';

    if (this.invoiceId) {
      this.getBreakdowns();
    } else {
      this.isLoading = false;
    }
  }

  ngOnDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
  }

  async getBreakdowns() {
    this.isLoading = true;
    this.breakdownsLoadSource = [];

    if (this.dataTable2) {
      this.dataTable2.clearFilterValues();
    }

    let bkds: InvoiceBreakdown[] = [];
    await this.invoiceBreakdownService
      .getInvoiceBreakdowns(this.invoiceId)
      .then((rs) => {
        bkds = rs;
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
        this.isLoading = false;
        return;
      });

    let ccIds: number[] = [];
    bkds.forEach((bkd) => {
      const index = ccIds.findIndex((x) => x === bkd.contractConnection);
      if (index === -1 && bkd.contractConnection) {
        ccIds.push(bkd.contractConnection);
      }
    });

    let ccs: ContractConnection[] = [];
    if (ccIds?.length > 0) {
      await this.contractConnectionService
        .getContractConnectionsByIds(ccIds)
        .then((rs) => {
          ccs = rs;
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
          this.isLoading = false;
          return;
        });
    }

    let mapCC: Map<number, ContractConnection> = new Map<number, ContractConnection>();
    let cIds: number[] = [];
    ccs?.forEach((cc) => {
      if (!mapCC.has(cc.id)) {
        mapCC.set(cc.id, cc);
      }
      const index = cIds.findIndex((x) => x === cc.connection);
      if (index === -1 && cc.connection) {
        cIds.push(cc.connection);
      }
    });

    let mapConnections: Map<number, ConnectionFullInfo> = new Map<number, ConnectionFullInfo>();

    if (cIds?.length > 0) {
      await this.connectionService
        .getConnectionsFullInfoById(cIds)
        .then((rs) => {
          rs?.forEach((cf) => {
            if (!mapConnections.has(cf.id)) {
              mapConnections.set(cf.id, cf);
            }
          });
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
          this.isLoading = false;
          return;
        });
    }

    await this.invoiceBreakdownService
      .getInvoiceBreakdowns(this.invoiceId)
      .then((bkds) => {
        let total = 0;

        if (bkds.length > 0) {
          bkds.forEach((bkd) => {
            total += Number(bkd.amount);

            let con: ContractConnection;
            if (mapCC.has(bkd.contractConnection)) {
              con = mapCC.get(bkd.contractConnection);
            }

            let mainCon: ConnectionFullInfo;

            if (con && mapConnections.has(con.connection)) {
              mainCon = mapConnections.get(con.connection);
            }

            const buyer = mainCon ? `${mainCon.buyerName} (${mainCon.buyerCode})` : '';
            const seller = mainCon ? `${mainCon.sellerName} (${mainCon.sellerCode})` : '';

            this.breakdownsLoadSource.push({
              'id': bkd.id,
              'chargeType': bkd.chargeType ? bkd.chargeType : bkd.concept,
              'costType': bkd.costType,
              'paymentType': bkd.paymentType,
              'dataType': bkd.subDataType,
              'amount': parseFloat(Number(bkd.amount).toFixed(2)).toLocaleString('en'),
              'quantity': parseFloat(Number(bkd.quantity).toFixed(2)).toLocaleString('en'),
              'buyer': buyer,
              'seller': seller,
              'client': bkd.client ? bkd.client : '',
              'supplier': bkd.supplier ? bkd.supplier + (bkd.supplierCode ? ' (' + bkd.supplierCode + ')' : '') : '',
            });
          });
          this.isLoading = false;
          this.totalSum = parseFloat(total.toFixed(2)).toLocaleString('en');
          this.breakdownsLoadSource = [...this.breakdownsLoadSource];
          this.breakdownsLoadSource.sort((a, b) => (a.id < b.id ? 1 : -1));
        } else {
          this.isLoading = false;
        }
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
        this.isLoading = false;
      });
  }

  async onEdit(event): Promise<void> {
    const updateData: InvoiceBreakdownUpdateInput = {
      amount: { set: event.amount.replaceAll(',', '') },
      quantity: { set: event.quantity.replaceAll(',', '') },
    };

    this.invoiceBreakdownService
      .updateInvoiceBreakdown(updateData, { id: event.id })
      .then((_inv) => {
        this.notificationService.success(
          'Breakdown succesfully updated, remember match all breakdowns amounts with the invoice total amount.',
          'Success',
        );
        this.ngOnChanges();
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      });
  }

  onDelete(event): void {
    const modalRef = this.modalService.open(ModalDeleteComponent, {
      backdrop: 'static',
      container: 'nb-layout',
    });

    modalRef.componentInstance.message =
      'Are you sure that you want to delete breakdown ' +
      event.id.toString() +
      '? Remember match all breakdowns amounts with the invoice total amount!';
    modalRef.componentInstance.title = 'Delete Breakdown';
    modalRef.componentInstance.buttonOkTitle = 'Delete';

    const self = this;

    modalRef.result
      .then((res) => {
        // on close
        if (res) {
          self.invoiceBreakdownService
            .deleteInvoiceBreakdown({ id: event.id })
            .then((rs) => {
              this.notificationService.success('Breakdown succesfully deleted', 'Success');
              modalRef.close(rs);
              self.ngOnChanges();
            })
            .catch((err) => {
              this.notificationService.handleGatewayAndGraphqlErrors(err);
            });
        }
      })
      .catch((error) => {
        self.notificationService.handleGatewayAndGraphqlErrors(error);
      });
  }

  recalculateAmounts() {
    let total = 0;

    this.breakdownsLoadSource.forEach((data) => {
      if (data?.length > 0) {
        data.forEach((row) => {
          total += Number(row.amount);
        });
      }
    });

    this.totalSum = parseFloat(total.toFixed(2)).toLocaleString('en');
  }

  filterDT2(value: any, field: string) {
    if (value) {
      this.dataTable2.filter(value, field, 'contains');
    }
  }
}
