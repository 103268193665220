<div class="form-check xen-radio" [ngClass]="classes">
  <input
    class="form-check-input"
    [ngClass]="inputClasses"
    type="radio"
    id="{{ id }}"
    [name]="name"
    [checked]="checked"
    [disabled]="disabled"
  />
  <label class="form-check-label" [for]="id">
    {{ label }}
    <span class="xen-radio__tooltip-icon" *ngIf="tooltip" ngbTooltip="{{ tooltip_text }}" tooltipClass="xen-tooltip">
      <i class="fas fa-info-square ms-1"></i>
    </span>
  </label>
  <div class="xen-radio__feedback" [ngClass]="feedbackClasses" *ngIf="validation">{{ feedback }}</div>
</div>
