import { Table } from 'primeng/table';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import {
  Contract,
  Partner,
  Cost,
  ChargeType,
  PartnerModelType,
} from '../../../../../interfaces/_index.billing.interfaces';
import { PartnerService, ContractService, CostsService } from '../../../../../services/_index-billing.services';
import { NotificationService, WebAdminService } from './../../../../../../../@core/services/_index-core.services';
import { OrganizationSelector } from './../../../../../../entities/interfaces/_index-entities.interfaces';
import { Options } from './../../../../../../../@core/interfaces/_index.interfaces';
import { BillingCostModalComponent } from '../cost-modal/cost-modal.component';
import { ModalDeleteComponent } from './../../../../../../../@core/shared/modal-delete/modal-delete.component';

@Component({
  selector: 'tgx-admin-billing-costs-table',
  templateUrl: './costs-table.component.html',
  styleUrls: ['./costs-table.component.scss'],
})
export class BillingCostsTableComponent implements OnInit, OnDestroy {
  @ViewChild('dt', { static: false }) dataTable!: Table;
  $subs: Subscription[] = [];

  header = [
    { label: 'ID', field: 'id' },
    { label: 'IsPush', field: 'isPush' },
    { label: 'Min', field: 'min' },
    { label: 'Max', field: 'max' },
    { label: 'Amount', field: 'amount' },
    { label: 'StartDate', field: 'startDate' },
    { label: 'EndDate', field: 'endDate' },
    { label: 'ChargeType', field: 'chargeType' },
    { label: 'PaymentType', field: 'paymentType' },
    { label: 'Options', field: 'Options' },
  ];

  costsLoadSource: any[];

  contracts: Contract[];

  costs: Cost[];

  isLoading: boolean;

  orgSelected: OrganizationSelector;
  partner: Partner;

  contractsSelector: Options[] = [{ code: null, label: 'Without contracts' }];
  selectedContract: Options;

  isEditor = false;

  chargeTypes: ChargeType[];

  chargeTypesOptions: Options[] = [{ code: null, label: 'Select One' }];

  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private webAdminService: WebAdminService,
    private partnerService: PartnerService,
    private contractService: ContractService,
    private costsService: CostsService,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;

    this.buildOptions();

    this.$subs.push(
      this.webAdminService.orgSelected.subscribe(async (orgSelected) => {
        if (orgSelected?.code) {
          this.orgSelected = orgSelected;
          await this.partnerService.getPartner(orgSelected.code).then((partner) => {
            if (partner) {
              this.partner = partner;
              this.getContracts(this.partner.orgCode);
            } else {
              this.partner = null;
              this.isLoading = false;
            }
          });
        }
      }),
    );
  }

  ngOnDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
  }

  buildOptions() {
    this.isEditor = this.webAdminService.isBillingEditorOrAdmin();
  }

  withoutContracts() {
    this.contractsSelector = [];
    this.contractsSelector.push({ code: null, label: 'Without contracts' });
  }

  getFilterType(field: string) {
    switch (field) {
      case 'isPush':
        return 'boolean';
      default:
        return 'text';
    }
  }

  getContracts(orgCode: string) {
    if (orgCode) {
      this.contractService
        .getContracts(orgCode)
        .then((cnts) => {
          // Descartamos aquí en el selector los que son de newPricing, ya que no tienen costes personalizados,
          // tienen unos costes genéricos que se pintan en otra sección a modo solo de lectura.

          if (cnts?.length > 0) {
            this.contractsSelector = [];
            this.contracts = cnts.filter((x) => x.modelType !== PartnerModelType.CM22);
            if (this.contracts?.length === 0) {
              this.withoutContracts();
            }
            this.buildSelector();
            this.selectedContract = this.contractsSelector[0];
            this.getCosts();
          } else {
            this.withoutContracts();
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
          this.isLoading = false;
        });
    }
  }

  buildSelector() {
    if (this.contracts?.length > 0) {
      this.contracts.forEach((c) => {
        this.contractsSelector.push({
          code: c.id.toString(),
          label:
            '(' +
            c.id +
            ') ' +
            c.partnerType +
            (c.modelType ? ' - ' + c.modelType : '') +
            (c.ContractType ? ' - ' + c.ContractType.name : ''),
        });
      });
    }
  }

  async getCosts() {
    this.isLoading = true;
    this.costsLoadSource = [];

    if (this.dataTable) {
      this.dataTable.clearFilterValues();
    }

    if (this.selectedContract?.code) {
      const costs = await this.costsService.getCosts(Number(this.selectedContract?.code));

      if (costs.length > 0) {
        costs.forEach((c) => {
          const { id, isPush, startDate, endDate, min, max, amount, createdAt, updatedAt, chargeType, paymentType } = c;

          this.costsLoadSource.push({
            id,
            isPush,
            startDate,
            endDate,
            min,
            max,
            amount,
            createdAt,
            updatedAt,
            chargeType,
            paymentType,
          });
        });
        this.isLoading = false;
        this.costsLoadSource = [...this.costsLoadSource];
        this.costsLoadSource.sort((a, b) => (a.id < b.id ? 1 : -1));
      } else {
        this.isLoading = false;
      }
    } else {
      this.isLoading = false;
    }
  }

  async onCreate(): Promise<void> {
    if (this.selectedContract.code === null) {
      this.notificationService.warning('You can not create a cost, first you must to create a contract.');
      return;
    }

    const activeModal = this.modalService.open(BillingCostModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = 'Create Cost';
    activeModal.componentInstance.contractId = Number(this.selectedContract.code);
    activeModal.componentInstance.isEditor = this.isEditor;

    const self = this;
    activeModal.result.then((res) => {
      // on close
      if (typeof res === 'object') {
        self.getCosts();
      }
    });
  }

  async onEdit(event): Promise<void> {
    const cost: Cost = event;

    const activeModal = this.modalService.open(BillingCostModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = (this.isEditor ? 'Edit ' : 'View ') + 'Cost';
    activeModal.componentInstance.cost = cost;
    activeModal.componentInstance.contractId = Number(this.selectedContract.code);
    activeModal.componentInstance.isEditor = this.isEditor;

    const self = this;
    activeModal.result.then((res) => {
      // on close
      if (typeof res === 'object') {
        self.getCosts();
      }
    });
  }

  onDelete(event): void {
    const modalRef = this.modalService.open(ModalDeleteComponent, {
      backdrop: 'static',
      container: 'nb-layout',
    });

    modalRef.componentInstance.message =
      'Are you sure that you want to delete cost ' + event.id.toString() + ' of type ' + event.chargeType + '?';
    modalRef.componentInstance.title = 'Delete Cost';
    modalRef.componentInstance.buttonOkTitle = 'Delete';

    const self = this;

    modalRef.result
      .then((res) => {
        // on close
        if (res) {
          self.costsService
            .deleteCost({ id: event.id })
            .then((rs) => {
              this.notificationService.success('Cost succesfully deleted', 'Success');
              modalRef.close(rs);
              self.getCosts();
            })
            .catch((err) => {
              this.notificationService.handleGatewayAndGraphqlErrors(err);
            });
        }
      })
      .catch((error) => {
        self.notificationService.handleGatewayAndGraphqlErrors(error);
      });
  }

  filter(value: any, field: string) {
    if (value) {
      this.dataTable.filter(value, field, 'contains');
    }
  }
}
