<nb-card>
  <nb-card-header> Individual Connections </nb-card-header>

  <nb-card-body>
    <p-table
      #dt2
      [value]="connectionsLoadSource"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      styleClass="p-datatable-gridlines p-datatable-striped"
      [tableStyle]="{ 'min-width': '50rem' }"
      editMode="row"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
      [resizableColumns]="true"
      columnResizeMode="expand"
      [loading]="isLoading"
      loadingIcon="pi pi-spin pi-spinner"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let head of header">
            {{ head.label }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-subconnection let-editing="editing">
        <tr [pSelectableRow]="subconnection" [pEditableRow]="subconnection">
          <td>{{ subconnection.partnerId }}</td>
          <td>{{ subconnection.partnerName }}</td>
          <td>{{ subconnection.partnerOrgCode }}</td>
          <td>{{ subconnection.contractId }}</td>
          <td>{{ subconnection.partnerType }}</td>
          <td>{{ subconnection.partnerSubType }}</td>
          <td>{{ subconnection.modelType }}</td>
          <td>{{ subconnection.connectionId }}</td>
          <td>{{ subconnection.connectionType }}</td>
          <td *ngIf="!isEditor"></td>
          <td *ngIf="isEditor">
            <div class="flex align-items-center justify-content-center gap-2">
              <button
                [disabled]="subconnection.conBreakdownId > 0"
                pButton
                pRipple
                type="button"
                pInitEditableRow
                icon="pi pi-pencil"
                (click)="onEdit(subconnection)"
                class="p-button-rounded p-button-text"
              ></button>
              <button
                [disabled]="subconnection.conBreakdownId === 0"
                pButton
                pRipple
                type="button"
                icon="pi pi-replay"
                (click)="onClone(subconnection)"
                class="p-button-rounded p-button-text"
              ></button>
              <button
                [disabled]="subconnection.conBreakdownId > 0"
                pButton
                pRipple
                type="button"
                icon="pi pi-times"
                (click)="onDelete(subconnection)"
                class="p-button-rounded p-button-text"
              ></button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="10" class="text-center">No connections found.</td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>
