import { gql } from 'apollo-angular';

export const getSuppliers = gql`
  query getSuppliersSimple {
    admin {
      allSuppliers {
        edges {
          node {
            supplierData {
              code
              name
              isActive
              owner {
                code
              }
              groups {
                edges {
                  node {
                    code
                  }
                }
              }
            }
          }
        }
        adviseMessage {
          code
          description
        }
      }
    }
  }
`;
