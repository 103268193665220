import { Injectable } from '@angular/core';
import {
  ObjectWhereUniqueInputById,
  ContractConnectionDiscount,
  ContractConnectionDiscountUpdateInput,
  ContractConnectionDiscountCreateInput,
} from './../interfaces/_index.billing.interfaces';
import { GraphqlApiBillingMutations } from './mutations/_index-billing-mutations';
import { GraphqlApiBillingQueries } from './queries/_index-billing-queries';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class ContractConnectionDiscountService {
  constructor(private apollo: Apollo) {}

  async getContractConnectionDiscounts(contractId: number): Promise<ContractConnectionDiscount[]> {
    const where = { ContractConnection: { is: { contract: { equals: contractId } } } };

    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .watchQuery<any>({
          query: GraphqlApiBillingQueries.getContractConnectionDiscounts,
          variables: { where: where },
          fetchPolicy: 'network-only',
        })
        .valueChanges.subscribe((res: any) => {
          if (res?.data) {
            resolve(((res || {}).data || {}).contractConnectionDiscounts);
          } else {
            reject(res);
          }
        });
    });
  }

  async createContractConnectionDiscount(
    create: ContractConnectionDiscountCreateInput,
  ): Promise<ContractConnectionDiscount> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.createContractConnectionDiscount,
          variables: { data: create },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).createContractConnectionDiscount);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }

  async updateContractConnectionDiscount(
    update: ContractConnectionDiscountUpdateInput,
    where: ObjectWhereUniqueInputById,
  ): Promise<ContractConnectionDiscount> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.updateContractConnectionDiscount,
          variables: { data: update, where: where },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).updateContractConnectionDiscount);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }
}
