<tgx-spinner [class]="'routingLoading'" [isLoading]="isLoading" [top]="'180px'"></tgx-spinner>

<nb-card *ngIf="!isLoading">
  <nb-card-header>
    <div class="row">
      <div class="col-3" *ngIf="selectedGroupContentContext?.length === 0">
        <span>Group Content | Context</span>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <p-table
      #dt1
      [value]="groupContentDataLoadSource"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      [tableStyle]="{ 'min-width': '50rem' }"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
      styleClass="p-datatable-gridlines p-datatable-striped"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let head of header" [pSortableColumn]="head.field">
            {{ head.label }}
            <p-sortIcon *ngIf="head.field !== 'Options'" [field]="head.field"></p-sortIcon>
          </th>
        </tr>
        <tr>
          <th *ngFor="let head of header">
            <p-columnFilter
              *ngIf="head.field !== 'Options'"
              [showClearButton]="false"
              [placeholder]="head.placeholder"
              type="text"
              [field]="head.field"
              matchMode="contains"
              (input)="dt1.filter($event.target.value, head.field, 'contains')"
            >
            </p-columnFilter>
            <p-button
              *ngIf="head.field === 'Options'"
              title="Add new Provider"
              class="p-button"
              icon="pi pi-plus"
              (onClick)="onCreate()"
            ></p-button>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-group>
        <tr>
          <td>{{ group.groupCode }}</td>
          <td>{{ group.contextCode }}</td>
          <td width="3rem"></td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>
