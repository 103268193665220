import { ActionReducerMap } from '@ngrx/store';

import { userReducer } from './user/reducer';
import { UserState, initialState as user } from './user/state';

export interface RootState {
  user: UserState;
}

export const reducers: ActionReducerMap<RootState> = {
  user: userReducer,
};

export const initialRootState: RootState = { user };
