import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'custom-list-renderer',
  templateUrl: './custom-list-renderer.html',
  styleUrls: ['./custom-list-renderer.scss'],
})
export class CustomListRendererComponent implements OnInit {
  renderValue: string[];

  @Input() value: string;
  @Input() rowData: any;

  ngOnInit() {
    this.renderValue = <string[]>(<unknown>this.value);
  }
}
