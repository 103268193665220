import { OrganizationsQueryInput, Organization, Group } from './../interfaces/_index-entities.interfaces';
import { Injectable } from '@angular/core';
import { GraphqlGatewayQueries } from './queries/_index-entities.queries';
import { GqlService } from '@tgx/shared/data-access/graphql';
import { pullAt } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class OrganizationEntitiesService {
  constructor(private gqlService: GqlService) {}

  async getGroupsByCode(codes: String[]): Promise<Group[]> {
    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getGroupsByOrg, codes)
        .then((response) => {
          const groups: any[] = [];
          if (
            response['admin']['allOrganizations']['edges'][0]['node']['organizationsData']['groups']['edges'].length > 0
          ) {
            const edges =
              response['admin']['allOrganizations']['edges'][0]['node']['organizationsData']['groups']['edges'];
            edges.forEach((edge) => {
              const group = edge.node.groupData;
              const option = { code: group.code, label: group.label };
              groups.push(option);
            });
          }
          resolve(groups);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async getOrgGroupsByOrgCode(input: OrganizationsQueryInput): Promise<Organization[]> {
    const variables = await this.getVariablesForOrganizationQueryInput(input);

    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getOrganizationsDescendents, variables)
        .then((response) => {
          if (response['admin']['allOrganizations']['edges'].length > 0) {
            resolve(response['admin']['allOrganizations']['edges']);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async getOrgByGroupCode(input: OrganizationsQueryInput): Promise<Organization[]> {
    const variables = await this.getVariablesForOrganizationQueryInput(input);

    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getOrgByGroupCode, variables)
        .then((response) => {
          if (response['admin']['allGroups']['edges'].length > 0) {
            resolve(response['admin']['allGroups']['edges']);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async getVariablesForOrganizationQueryInput(input: OrganizationsQueryInput) {
    const variables: any = {};

    if (input.after) {
      variables['after'] = input.after;
    }
    if (input.codes?.length > 0) {
      variables['codes'] = input.codes;
    }
    if (input.contains) {
      variables['contains'] = input.contains;
    }
    if (input.label) {
      variables['label'] = input.label;
    }
    if (input.organizationType) {
      variables['organizationType'] = input.organizationType;
    }
    if (input.first) {
      variables['first'] = input.first;
    }
    if (input.last) {
      variables['last'] = input.last;
    }
    if (input.before) {
      variables['before'] = input.before;
    }
    if (input.isPublic !== undefined) {
      variables['isPublic'] = input.isPublic;
    }
    return variables;
  }

  async getLabelsForGroups(groupsCode: String[]): Promise<any> {
    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getLabelForGroups, { codes: groupsCode })
        .then((response) => {
          if (response['admin']['allGroups']['edges'].length > 0) {
            const nodes = response['admin']['allGroups']['edges'];

            const codeLabels = [];
            nodes.forEach((node) => {
              codeLabels[node.node.groupData.code] = node.node.groupData.label;
            });
            resolve(codeLabels);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async getTypesForGroups(groupsCode: String[]): Promise<any> {
    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getTypeForGroups, { codes: groupsCode })
        .then((response) => {
          if (response['admin']['allGroups']['edges'].length > 0) {
            const nodes = response['admin']['allGroups']['edges'];

            const codeLabels = [];
            nodes.forEach((node) => {
              codeLabels[node.node.groupData.code] = node.node.groupData.type;
            });
            resolve(codeLabels);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }
}
