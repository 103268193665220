<nb-card>
  <nb-card-body>
    <div class="row mb-3">
      <div class="col-1">
        <label class="mt-2">Contract: </label>
      </div>
      <div class="col-6">
        <p-dropdown
          (onHide)="getInvoices()"
          [options]="contractsSelector"
          [appendTo]="'body'"
          baseZIndex="999"
          [disabled]="contractsSelector[0].code === null"
          [(ngModel)]="selectedContract"
          optionLabel="label"
          filterBy="label"
        >
          <ng-template pTemplate="selectedItem">
            <div *ngIf="selectedContract">
              <div>{{ selectedContract?.label }}</div>
            </div>
          </ng-template>
          <ng-template let-ci pTemplate="ci">
            <div>
              <div>{{ ci.label }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
  </nb-card-body>
</nb-card>

<nb-card>
  <nb-card-header> Invoices (Select one row to view its breakdowns) </nb-card-header>

  <nb-card-body>
    <p-table
      #dt
      [value]="invoicesLoadSource"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      styleClass="p-datatable-gridlines p-datatable-striped"
      [tableStyle]="{ 'min-width': '50rem' }"
      editMode="row"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
      selectionMode="single"
      [(selection)]="selectedInvoice"
      dataKey="id"
      [loading]="isLoading"
      loadingIcon="pi pi-spin pi-spinner"
      [resizableColumns]="true"
      columnResizeMode="expand"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let head of header" [pSortableColumn]="head.field">
            {{ head.label }}
            <p-sortIcon *ngIf="head.field !== 'Options'" [field]="head.field"></p-sortIcon>
          </th>
        </tr>
        <tr>
          <th *ngFor="let head of header" [style]="head.field === 'Options' ? 'width: 3rem' : ''">
            <p-columnFilter
              type="text"
              *ngIf="head.field !== 'Options'"
              [field]="head.field"
              [showMenu]="false"
              [showClearButton]="false"
              (input)="filter($event.target.value, head.field)"
            ></p-columnFilter>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-invoice let-editing="editing">
        <tr [pSelectableRow]="invoice" [pEditableRow]="invoice">
          <td>{{ invoice.id }}</td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="invoice.amount" required />
              </ng-template>
              <ng-template pTemplate="output"> {{ invoice.amount }} </ng-template>
            </p-cellEditor>
          </td>
          <td>{{ invoice.currency }}</td>
          <td>{{ invoice.createdAt }}</td>
          <td>{{ invoice.startDate }}</td>
          <td>{{ invoice.endDate }}</td>
          <td>
            <p-checkbox [ngModel]="invoice.visible" [binary]="true" inputId="binary" disabled="true"></p-checkbox>
          </td>
          <td *ngIf="!isEditor"></td>
          <td *ngIf="isEditor">
            <div class="flex align-items-center justify-content-center gap-2">
              <button
                *ngIf="!editing"
                [disabled]="!isEditor"
                pButton
                pRipple
                type="button"
                pInitEditableRow
                icon="pi pi-pencil"
                class="p-button-rounded p-button-text"
              ></button>
              <button
                *ngIf="editing"
                pButton
                pRipple
                type="button"
                pSaveEditableRow
                icon="pi pi-check"
                (click)="onEdit(invoice)"
                class="p-button-rounded p-button-text p-button-success mr-2"
              ></button>
              <button
                *ngIf="editing"
                pButton
                pRipple
                type="button"
                pCancelEditableRow
                icon="pi pi-times"
                class="p-button-rounded p-button-text p-button-danger"
              ></button>
              <button
                [disabled]="!isEditor"
                pButton
                pRipple
                type="button"
                icon="pi pi-times"
                (click)="onDelete(invoice)"
                class="p-button-rounded p-button-text"
              ></button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="10" class="text-center">No invoices found.</td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>

<tgx-admin-billing-invoice-breakdowns-table
  [isEditor]="isEditor"
  [totalInvoice]="getInvoiceAmount()"
  [invoiceId]="selectedInvoice?.id"
  *ngIf="selectedInvoice"
  [selectedInvoice]="selectedInvoice"
></tgx-admin-billing-invoice-breakdowns-table>
