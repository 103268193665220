import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../../@core/shared/shared.module';
import { WebRoutingModule } from './web-routing.module';
import { WebComponent } from './web.component';
import { WebService } from './services/web.service';
import { WebBannerModule } from './modules/web-banner/web-banner.module';

@NgModule({
  declarations: [WebComponent],
  imports: [CommonModule, WebRoutingModule, SharedModule, WebBannerModule],
})
export class WebModule {
  static forRoot(): ModuleWithProviders<WebModule> {
    return {
      ngModule: WebModule,
      providers: [WebService],
    };
  }
}
