<div class="modal-header">
  <span>{{ header }}</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="contractForm">
    <nb-card>
      <nb-card-header> Data </nb-card-header>
      <nb-card-body>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="id">ID</label>
              <input type="text" class="form-control" name="id" formControlName="id" />
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label class="mb-1" for="enabled">Enabled</label>
              <nb-checkbox class="form-control" name="enabled" formControlName="enabled"></nb-checkbox>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label class="mb-1" for="excess">CheckinDate</label>
              <nb-checkbox
                class="form-control"
                name="bookCheckinNorDate"
                formControlName="bookCheckinNorDate"
              ></nb-checkbox>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="activationDateTime"
                >Activation Date
                <span class="mandatory ml-1">*</span>
              </label>
              <p-calendar
                [appendTo]="'body'"
                baseZIndex="999"
                class="form-control p-0 border-0 w-100"
                formControlName="activationDateTime"
                dateFormat="dd/mm/yy"
                [showIcon]="true"
                inputId="icon"
                [readonlyInput]="true"
                placeholder="Enter a date.."
              ></p-calendar>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="endDate">End Date</label>
              <p-calendar
                [appendTo]="'body'"
                baseZIndex="999"
                class="form-control p-0 border-0 w-100"
                formControlName="endDate"
                dateFormat="dd/mm/yy"
                [showIcon]="true"
                inputId="icon"
                [readonlyInput]="true"
                placeholder="Enter a date.."
              ></p-calendar>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="reference"
                >Reference
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="contractForm.get('reference')"
                [errors]="contractForm.get('reference').errors"
              ></tooltip-error>
              <input
                type="text"
                class="form-control"
                formControlName="reference"
                [ngClass]="{
                  'form-control-danger': contractForm.get('reference').errors && contractForm.get('reference').touched,
                }"
                placeholder="Enter a reference..."
                name="reference"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="currency">Currency</label>
              <tooltip-error
                [element]="contractForm.get('currency')"
                [errors]="contractForm.get('currency').errors"
              ></tooltip-error>
              <div class="form-group">
                <p-dropdown
                  [options]="currencies"
                  optionLabel="label"
                  [filter]="true"
                  filterBy="label"
                  class="form-control p-0 border-0 w-100"
                  formControlName="currency"
                  [ngClass]="{
                    'form-control-danger': contractForm.get('currency').errors && contractForm.get('currency').touched,
                  }"
                  placeholder="Select a currency..."
                  name="currency"
                  >>
                  <ng-template pTemplate="selectedItem">
                    <div *ngIf="contractForm.get('currency')?.value">
                      <div>{{ contractForm.get('currency').value.label }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-cur pTemplate="cur">
                    <div>
                      <div>{{ cur.label }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger': contractForm.get('minimumFee').errors && contractForm.get('minimumFee').touched,
              }"
            >
              <label class="mb-1" for="minimumFee">Minimum Fee</label>
              <tooltip-error
                [element]="contractForm.get('minimumFee')"
                [errors]="contractForm.get('minimumFee').errors"
              ></tooltip-error>
              <input
                type="number"
                class="form-control"
                formControlName="minimumFee"
                [ngClass]="{
                  'form-control-danger':
                    contractForm.get('minimumFee').errors && contractForm.get('minimumFee').touched,
                }"
                name="minimumFee"
              />
            </div>
          </div>
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger':
                  contractForm.get('minimumUnit').errors && contractForm.get('minimumUnit').touched,
              }"
            >
              <label class="mb-1" for="minimumUnit">Minimum Unit </label>
              <tooltip-error
                [element]="contractForm.get('minimumUnit')"
                [errors]="contractForm.get('minimumUnit').errors"
              ></tooltip-error>
              <input
                type="number"
                class="form-control"
                formControlName="minimumUnit"
                [ngClass]="{
                  'form-control-danger':
                    contractForm.get('minimumUnit').errors && contractForm.get('minimumUnit').touched,
                }"
                name="minimumUnit"
              />
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger': contractForm.get('lookToBook').errors && contractForm.get('lookToBook').touched,
              }"
            ></div>
            <label class="mb-1" for="lookToBook">L2B</label>
            <tooltip-error [element]="contractForm.get('lookToBook')" [errors]="contractForm.get('lookToBook').errors">
            </tooltip-error>
            <input
              type="number"
              class="form-control"
              formControlName="lookToBook"
              [ngClass]="{
                'form-control-danger': contractForm.get('lookToBook').errors && contractForm.get('lookToBook').touched,
              }"
              name="lookToBook"
            />
          </div>
          <div class="col-3">
            <div class="form-group">
              <label class="mb-1" for="excess">L2BByCheckinDate</label>
              <nb-checkbox
                class="form-control"
                name="lookToBookCheckinNorDate"
                formControlName="lookToBookCheckinNorDate"
              ></nb-checkbox>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label class="mb-1" for="excess">Excess</label>
              <nb-checkbox class="form-control" name="excess" formControlName="excess"></nb-checkbox>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4">
            <div class="form-group">
              <label class="mb-1" for="partnerType">Partner Type</label>
              <tooltip-error
                [element]="contractForm.get('partnerType')"
                [errors]="contractForm.get('partnerType').errors"
              >
              </tooltip-error>
              <p-dropdown
                (onHide)="validDropdown('partnerType'); checkSelectsContractType(); validateRateTypes()"
                class="from-group p-0 border-0 w-100"
                [options]="partnerTypes"
                formControlName="partnerType"
                [appendTo]="'body'"
                baseZIndex="999"
                optionLabel="label"
                filterBy="label"
              >
                <ng-template pTemplate="selectedItem">
                  <div *ngIf="contractForm.get('partnerType').value">
                    <div>{{ contractForm.get('partnerType').value.label }}</div>
                  </div>
                </ng-template>
                <ng-template let-cr pTemplate="cr">
                  <div>
                    <div>{{ cr.label }}</div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label class="mb-1" for="modelType">Model Type</label>
              <tooltip-error
                [element]="contractForm.get('modelType')"
                [errors]="contractForm.get('modelType').errors"
              ></tooltip-error>
              <div class="form-group">
                <p-dropdown
                  (onHide)="validDropdown('modelType'); checkSelectsContractType(); validateRateTypes()"
                  [options]="modelTypes"
                  [appendTo]="'body'"
                  baseZIndex="999"
                  formControlName="modelType"
                  optionLabel="label"
                  filterBy="label"
                >
                  <ng-template pTemplate="selectedItem">
                    <div *ngIf="contractForm.get('modelType').value">
                      <div>{{ contractForm.get('modelType').value.label }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-ci pTemplate="ci">
                    <div>
                      <div>{{ ci.label }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label class="mb-1" for="partnerSubType">SubType</label>
              <tooltip-error
                [element]="contractForm.get('partnerSubType')"
                [errors]="contractForm.get('partnerSubType').errors"
              ></tooltip-error>
              <div class="form-group">
                <p-dropdown
                  (onHide)="validateRateTypes()"
                  [options]="partnerSubTypes"
                  [appendTo]="'body'"
                  baseZIndex="999"
                  formControlName="partnerSubType"
                  optionLabel="label"
                  filterBy="label"
                >
                  <ng-template pTemplate="selectedItem">
                    <div *ngIf="contractForm.get('partnerSubType').value">
                      <div>{{ contractForm.get('partnerSubType').value.label }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-ci pTemplate="ci">
                    <div>
                      <div>{{ ci.label }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="billingAccount">BillingAccount</label>
              <tooltip-error
                [element]="contractForm.get('billingAccount')"
                [errors]="contractForm.get('billingAccount').errors"
              ></tooltip-error>
              <div class="form-group">
                <p-dropdown
                  (onHide)="validDropdown('billingAccount')"
                  [options]="billingAccountOptions"
                  [appendTo]="'body'"
                  baseZIndex="999"
                  formControlName="billingAccount"
                  optionLabel="label"
                  filterBy="label"
                >
                  <ng-template pTemplate="selectedItem">
                    <div *ngIf="contractForm.get('billingAccount').value">
                      <div>{{ contractForm.get('billingAccount').value.label }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-ci pTemplate="ci">
                    <div>
                      <div>{{ ci.label }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="rateType">RateType</label>
              <tooltip-error
                [element]="contractForm.get('rateType')"
                [errors]="contractForm.get('rateType').errors"
              ></tooltip-error>
              <div class="form-group">
                <p-dropdown
                  [options]="rateTypes"
                  [appendTo]="'body'"
                  baseZIndex="999"
                  formControlName="rateType"
                  optionLabel="label"
                  filterBy="label"
                >
                  <ng-template pTemplate="selectedItem">
                    <div *ngIf="contractForm.get('rateType').value">
                      <div>{{ contractForm.get('rateType').value.label }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-ci pTemplate="ci">
                    <div>
                      <div>{{ ci.label }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4" *ngIf="isCreating && showLoadDefaultPDF()">
            <div class="form-group">
              <label class="mb-1" for="defaultpdf"><b>Load Default PDF</b></label>
              <nb-checkbox class="form-control" name="defaultpdf" formControlName="defaultpdf"></nb-checkbox>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label class="mb-1" for="manualInvoice">ManualInvoice</label>
              <nb-checkbox class="form-control" name="manualInvoice" formControlName="manualInvoice"></nb-checkbox>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label class="mb-1" for="partiallyInvoiced">PartiallyInvoiced</label>
              <nb-checkbox
                class="form-control"
                name="partiallyInvoiced"
                formControlName="partiallyInvoiced"
              ></nb-checkbox>
            </div>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
    <nb-card>
      <nb-card-header> Extra data </nb-card-header>
      <nb-card-body>
        <div class="row mt-2" *ngIf="showExtraProperties()">
          <div class="col-12">
            <div class="form-group">
              <label class="mb-1" for="remarks">Excluded Suppliers (Set supplier codes separated by comma)</label>
              <tooltip-error
                [element]="contractForm.get('supexc')"
                [errors]="contractForm.get('supexc').errors"
              ></tooltip-error>
              <textarea
                type="text"
                class="form-control"
                formControlName="supexc"
                [ngClass]="{
                  'form-control-danger': contractForm.get('supexc').errors && contractForm.get('supexc').touched,
                }"
                placeholder="Enter supplier codes.."
                name="supexc"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="row mt-2" *ngIf="showExtraProperties()">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="activationDateTime">Review Fee Date</label>
              <p-calendar
                [appendTo]="'body'"
                baseZIndex="999"
                class="form-control p-0 border-0 w-100"
                formControlName="reviewFeeDate"
                dateFormat="dd/mm/yy"
                [showIcon]="true"
                inputId="icon"
                [readonlyInput]="false"
                placeholder="Enter a date.."
              ></p-calendar>
            </div>
          </div>
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger':
                  contractForm.get('expirationDays').errors && contractForm.get('expirationDays').touched,
              }"
            >
              <label class="mb-1" for="expirationDays">Billing Duedate Days</label>
              <tooltip-error
                [element]="contractForm.get('expirationDays')"
                [errors]="contractForm.get('expirationDays').errors"
              ></tooltip-error>
              <input
                type="number"
                class="form-control"
                formControlName="expirationDays"
                [ngClass]="{
                  'form-control-danger':
                    contractForm.get('expirationDays').errors && contractForm.get('expirationDays').touched,
                }"
                name="expirationDays"
              />
            </div>
          </div>
        </div>
        <div class="row mt-2" *ngIf="isNp23()">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="remarks">Default Payment Type</label>
              <tooltip-error
                [element]="contractForm.get('defaultPaymentType')"
                [errors]="contractForm.get('defaultPaymentType').errors"
              ></tooltip-error>
              <div class="form-group">
                <p-dropdown
                  [options]="paymentTypes"
                  [appendTo]="'body'"
                  baseZIndex="999"
                  formControlName="defaultPaymentType"
                  optionLabel="label"
                  filterBy="label"
                >
                  <ng-template pTemplate="selectedItem">
                    <div *ngIf="contractForm.get('defaultPaymentType').value">
                      <div>{{ contractForm.get('defaultPaymentType').value.label }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-ci pTemplate="ci">
                    <div>
                      <div>{{ ci.label }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2" *ngIf="isNp23()">
          <div class="col-4">
            <div class="form-group">
              <label class="mb-1" for="hasRappel">Rappel active</label>
              <nb-checkbox class="form-control" name="hasRappel" formControlName="hasRappel"></nb-checkbox>
            </div>
          </div>
          <div class="col-4">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger':
                  contractForm.get('rappelDiscount').errors && contractForm.get('rappelDiscount').touched,
              }"
            >
              <label class="mb-1" for="rappelDiscount">Rappel discount</label>
              <tooltip-error
                [element]="contractForm.get('rappelDiscount')"
                [errors]="contractForm.get('rappelDiscount').errors"
              ></tooltip-error>
              <input
                type="number"
                class="form-control"
                formControlName="rappelDiscount"
                [ngClass]="{
                  'form-control-danger':
                    contractForm.get('rappelDiscount').errors && contractForm.get('rappelDiscount').touched,
                }"
                name="rappelDiscount"
              />
            </div>
          </div>
          <div class="col-4">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger':
                  contractForm.get('rappelMinimum').errors && contractForm.get('rappelMinimum').touched,
              }"
            >
              <label class="mb-1" for="rappelMinimum">Rappel minimum GMV</label>
              <tooltip-error
                [element]="contractForm.get('rappelMinimum')"
                [errors]="contractForm.get('rappelMinimum').errors"
              ></tooltip-error>
              <input
                type="number"
                class="form-control"
                formControlName="rappelMinimum"
                [ngClass]="{
                  'form-control-danger':
                    contractForm.get('rappelMinimum').errors && contractForm.get('rappelMinimum').touched,
                }"
                name="rappelMinimum"
              />
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <div class="form-group">
              <label class="mb-1" for="remarks">Remarks</label>
              <tooltip-error
                [element]="contractForm.get('remarks')"
                [errors]="contractForm.get('remarks').errors"
              ></tooltip-error>
              <textarea
                type="text"
                class="form-control"
                formControlName="remarks"
                [ngClass]="{
                  'form-control-danger': contractForm.get('remarks').errors && contractForm.get('remarks').touched,
                }"
                placeholder="Enter remarks.."
                name="remarks"
              ></textarea>
            </div>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()">
    {{ ended ? 'Close' : isEditor ? 'Cancel' : 'Close' }}
  </button>
  <button *ngIf="isEditor && !ended" class="btn btn-md btn-primary" [disabled]="!validForm()" (click)="onSave()">
    {{ isCreating ? 'Create' : 'Update' }}
  </button>
</div>
