import { allEntityAudits } from './allEntitiesAudits';
import { getAllAccesses } from './getAllAccesses';
import { getAllAccessesGroups } from './getAllAccessesGroups';
import { getAllClients } from './getAllClients';
import { getAllClientsByOwner } from './getAllClientsByOwner';
import { getAllGroupContentContext } from './getAllGroupContentContext';

import { getAllMembersByOrgV2 } from './getAllMembersByOrgV2';
import { getAllMembersV2 } from './getAllMembersV2';
import { getAllProviders } from './getAllProviders';
import { getAllSuppliers } from './getAllSuppliers';
import { getAllSuppliersGroups } from './getAllSuppliersGroups';
import { getAllSuppliersWithoutFilter } from './getAllSuppliersWithoutFilter';
import { getGroupsByAccessCode } from './getGroupsByAccessCode';
import { getGroupsByOrg } from './getGroupsByOrg';
import { getGroupsBySupplierCode } from './getGroupsBySupplierCode';
import { getHubUserInformationByName } from './getHubUserInformationByName';
import { getLabelForGroups } from './getLabelForGroups';
import { getLegacyConnectionByClient } from './getLegacyConnectionByClient';
import { getMinInfoSupplier } from './getMinInfoSupplier';
import { getOrgByGroupCode } from './getOrgByGroupCode';
import { getOrganizationsDescendents } from './getOrganizationsDescendents';
import { getSuppliersWithClients } from './getSuppliersWithClients';
import { getTypeForGroups } from './getTypeForGroups';
import { getAllApikeys } from './getAllApikeys';
import { getMinInfoOrganizations } from './getMinInfoOrganizations';
import { getOrganizationGiata } from './getOrganizationGiata';
import { getOrganizations } from './getOrganizations';
import { getSupplier } from './getSupplier';

import { getAllUserProfiles } from './allUserProfiles';
import { getLegacyPasswordByClient } from './getLegacyPasswordByClient';
import { getSupplierOrgCode } from './getSupplierOrgCode';
import { getAllConnections } from './getAllConnections';
import { getSupplierMetaInfo } from './getSupplierMetaInfo';
import { getHotelXGroupsByOrg } from './getHotelXGroupsByOrg';

export const GraphqlGatewayQueries = {
  getAllClientsByOwner: getAllClientsByOwner,
  getAllConnections: getAllConnections,
  getLegacyConnectionByClient: getLegacyConnectionByClient,
  getHubUserInformationByName: getHubUserInformationByName,
  getSuppliersWithClients: getSuppliersWithClients,
  getAllSuppliers: getAllSuppliers,
  getSupplier: getSupplier,
  getAllProviders: getAllProviders,
  getOrganizationsDescendents: getOrganizationsDescendents,
  getGroupsByOrg: getGroupsByOrg,
  getGroupsBySupplierCode: getGroupsBySupplierCode,
  getAllGroupContentContext: getAllGroupContentContext,
  getAllSuppliersGroups: getAllSuppliersGroups,
  getAllAccesses: getAllAccesses,
  getAllAccessesGroups: getAllAccessesGroups,
  getGroupsByAccessCode: getGroupsByAccessCode,
  getMinInfoSupplier: getMinInfoSupplier,
  getAllSuppliersWithoutFilter: getAllSuppliersWithoutFilter,
  getAllClients: getAllClients,
  getLabelForGroups: getLabelForGroups,
  getOrgByGroupCode: getOrgByGroupCode,
  getTypeForGroups: getTypeForGroups,
  allEntityAudits: allEntityAudits,
  getAllMembersV2: getAllMembersV2,
  getAllMembersByOrgV2: getAllMembersByOrgV2,
  getMinInfoOrganizations: getMinInfoOrganizations,
  getOrganizations: getOrganizations,
  getOrganizationGiata: getOrganizationGiata,
  getAllApikeys: getAllApikeys,
  getAllUserProfiles: getAllUserProfiles,
  getLegacyPasswordByClient: getLegacyPasswordByClient,
  getSupplierOrgCode: getSupplierOrgCode,
  getSupplierMetaInfo: getSupplierMetaInfo,
  getHotelXGroupsByOrg: getHotelXGroupsByOrg,
};
