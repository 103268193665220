import { gql } from 'apollo-angular';

export const getOrganizationsDescendents = gql`
  query Organizations($codes: [ID!]) {
    admin {
      allOrganizations(where: { code_in: $codes }) {
        edges {
          node {
            adviseMessage {
              code
              description
              level
            }
            organizationsData {
              code
              groups {
                edges {
                  node {
                    groupData {
                      code
                      type
                    }
                  }
                }
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`;
