import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HotelList, MasterList, GenericMappingResponse } from '../../interfaces/models/mapping.interfaces';
import { MappingService } from '../../../../services/mapping.service';
import { ToastsService } from '@tgx/shared/toasts';
import { hsCountryListIsoCodes } from '@tgx/shared/utils';

@Component({
  selector: 'tgx-unmap-modal',
  templateUrl: './unmap-modal.component.html',
  styleUrls: ['./unmap-modal.component.scss'],
})
export class UnmapModalComponent {
  @Input() selectedHotels: HotelList[];
  @Input() nonValidateMasters: MasterList;
  @Input() nonValidate: MasterList;

  constructor(
    private activeModal: NgbActiveModal,
    private mappingService: MappingService,
    private toast: ToastsService,
  ) {}

  async unmapHotelFromMaster() {
    let hotelId = this.mappingService.getHotelId(this.selectedHotels) as string[];
    await this.mappingService
      .unmapHotelFromMaster(hotelId)
      .then((res: GenericMappingResponse[]) => {
        if (res) {
          this.toast.addToast('Success', 'bg-success', 'Hotel successfully unmapped', 5);
          this.close(res);
        }
      })
      .catch((err) => {
        this.toast.addToast('Error', 'bg-danger', 'Failed to unmap hotel', 5);
        this.close();
      });
  }

  findNameCountry(isoCode2: string) {
    const country = hsCountryListIsoCodes.find((x) => x.isoCode2 === isoCode2);
    if (country) {
      return country.name;
    }
    return '';
  }

  close(unmapHotelInfo?: GenericMappingResponse[]) {
    this.activeModal.close(unmapHotelInfo);
  }
}
