<div class="modal-header">
  <span>{{ header }}</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="billingAccountContactForm">
    <nb-card>
      <nb-card-header> Data </nb-card-header>
      <nb-card-body>
        <div class="row mt-2">
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger':
                  billingAccountContactForm.get('email').errors && billingAccountContactForm.get('email').touched,
              }"
            >
              <label for="email"
                >Email
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="billingAccountContactForm.get('email')"
                [errors]="billingAccountContactForm.get('email').errors"
              ></tooltip-error>
              <input
                type="text"
                class="form-control"
                formControlName="email"
                [ngClass]="{
                  'form-control-danger':
                    billingAccountContactForm.get('email').errors && billingAccountContactForm.get('email').touched,
                }"
                placeholder="Enter an email..."
                name="email"
              />
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label class="mb-1" for="receiveInvoices">ReceiveInvoices</label>
              <nb-checkbox class="form-control" name="receiveInvoices" formControlName="receiveInvoices"></nb-checkbox>
            </div>
          </div>
          <div class="col-3" *ngIf="!isCreating && !billingAccountContact?.odooCode">
            <div class="form-group">
              <label class="mb-1" for="odooCode">OdooCode</label>
              <input type="number" class="form-control" formControlName="odooCode" name="odooCode" />
            </div>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()">{{ isEditor ? 'Cancel' : 'Close' }}</button>
  <button
    *ngIf="isEditor"
    class="btn btn-md btn-primary"
    [disabled]="billingAccountContactForm.invalid || !this.isEditor"
    (click)="onSave()"
  >
    {{ isCreating ? 'Create' : 'Update' }}
  </button>
</div>
