import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { PlatformInfoService } from '../../../services/src/lib/platform-info.service';
import { Subscription } from 'rxjs';
import { ActiveCardOrg, SelectorOrganization } from '@tgx/shared/interfaces';
import { ManagePermissionsService, SharedConnectionsService } from '@tgx/shared/services';
import { WebComponent, WebFeature } from '@tgx/shared/enums';

@Injectable({
  providedIn: 'root',
})
export class MappingGuard implements CanActivate, OnDestroy {
  private subscription: Subscription;
  typeSelected: string;

  constructor(
    private router: Router,
    private platformInfoService: PlatformInfoService,
    private managePermissionsService: ManagePermissionsService,
  ) {
    // Subscribe to changes in `organizationSelected` only once in the constructor.
    this.subscription = this.platformInfoService.organizationSelected.subscribe({
      next: (data) => {
        if (data) {
          this.typeSelected = data.typeSelected;
        }
      },
    });
  }

  async canActivate(): Promise<boolean> {
    // Only check permissions if selected organization type is a buyer
    if (this.typeSelected === 'buyer') {
      try {
        let permissionsVisibility;
        permissionsVisibility = this.managePermissionsService.checkVisibility(
          WebComponent.MY_CONNECTIONS,
          WebFeature.MAPPING,
        );

        if (!permissionsVisibility.isVisible) this.router.navigateByUrl('connections');

        return permissionsVisibility.isVisible;
      } catch (error) {
        return false;
      }
    } else {
      return true;
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
