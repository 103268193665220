import { Options } from './../../../../../../@core/interfaces/_index.interfaces';
import { NotificationService } from './../../../../../../@core/services/notification.service';
import {
  ConnectionService,
  ContractConnectionService,
  PartnerService,
} from './../../../../services/_index-billing.services';
import { Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { clone } from '../../../../../../@core/utils/utils';
import {
  ContractConnection,
  ContractConnectionCreateInput,
  ContractConnectionUpdateInput,
  ObjectWhereUniqueInputById,
  ConnectionType,
  ConnectionFullInfo,
  Partner,
} from '../../../../interfaces/_index.billing.interfaces';
import { WebAdminService } from '../../../../../../@core/services/web-admin.service';

@Component({
  selector: 'tgx-admin-billing-connections-modal',
  styleUrls: ['./connections-modal.component.scss'],
  templateUrl: 'connections-modal.component.html',
})
export class BillingConnectionsModalComponent implements OnInit, OnDestroy {
  @Input() connection: any = null;
  @Input() header: string;
  @Input() contractId: number;
  @Input() isEditor: boolean;
  @Input() contractConnections: ContractConnection[];

  isCreating: boolean;
  connectionAux: ContractConnection;
  ended = false;

  connections: ConnectionFullInfo[];
  partners: Partner[];

  buyerOptions: any[];
  sellerOptions: any[];

  public connectionForm: UntypedFormGroup;

  subscriptions: Subscription[] = [];

  connectionTypes: Options[] = [{ code: null, label: 'Select One' }];

  isLoading = false;
  isBillingAdmin = false;

  bookStatus: string[] = [
    //'OK',
    //'CANCELLED',
    'ON_REQUEST',
    'PENDING_COMMIT',
    'UNKNOWN',
    'KO',
  ];

  bookStatusSelector: any[];

  constructor(
    private notificationService: NotificationService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private contractConnectionService: ContractConnectionService,
    private webAdminService: WebAdminService,
    private connectionService: ConnectionService,
    private partnerService: PartnerService,
  ) {}

  async ngOnInit() {
    this.isLoading = true;
    this.isCreating = true;

    this.isBillingAdmin = this.webAdminService.isBillingEditorOrAdmin(true);
    if (this.connection) {
      this.isCreating = false;
    }

    if (this.isCreating) {
      await this.connectionService
        .getConnectionsFullInfo()
        .then((cons) => {
          this.connections = cons;
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });

      await this.partnerService
        .getAllPartners()
        .then((partns) => {
          this.partners = partns;
          this.buildBuyerSellerDropdown();
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    } else {
      await this.connectionService
        .getConnectionsFullInfoById([this.connection?.connection])
        .then((cons) => {
          this.connections = cons;
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });

      this.partners = [];
      if (this.connections?.length > 0) {
        await this.partnerService
          .getPartner(this.connections[0].buyerCode)
          .then((part) => {
            this.partners.push(part);
          })
          .catch((err) => {
            this.notificationService.handleGatewayAndGraphqlErrors(err);
          });
        await this.partnerService
          .getPartner(this.connections[0].sellerCode)
          .then((part) => {
            this.partners.push(part);
          })
          .catch((err) => {
            this.notificationService.handleGatewayAndGraphqlErrors(err);
          });
      }
    }

    this.buildForm();
    this.buildBookStatusDropdown();
    this.buildConnectionTypesSelect();

    if (!this.isCreating) {
      this.connectionForm.get('id').setValue(this.connection.id);
      this.connectionForm.get('setupPaid').setValue(this.connection.setupPaid);
      this.connectionForm.get('activationDateTime').setValue(new Date(this.connection.activationDateTime));
      if (this.connection.endDate) {
        this.connectionForm.get('endDate').setValue(new Date(this.connection.endDate));
      }

      this.connectionForm.get('isBuy').setValue(this.connection.isBuy);
      this.connectionForm
        .get('connectionType')
        .setValue(
          this.connectionTypes[this.connectionTypes.findIndex((x) => x.code === this.connection.connectionType)],
        );
      this.connectionForm.get('free').setValue(this.connection.free);

      let con: ConnectionFullInfo;
      let index = this.connections.findIndex((x) => x.id === this.connection?.connection);

      if (index > -1) {
        con = this.connections[index];
      }

      let buyer: Partner;
      index = this.partners.findIndex((x) => x.orgCode === con.buyerCode);
      if (index > -1) {
        buyer = this.partners[index];
        this.buyerOptions = [];
        this.buyerOptions.push({
          value: buyer.orgCode,
          title: `${buyer.name} (${buyer.orgCode})`,
        });
        this.connectionForm.get('buyer').setValue(this.buyerOptions[0]);
      }

      let seller: Partner;
      index = this.partners.findIndex((x) => x.orgCode === con.sellerCode);
      if (index > -1) {
        seller = this.partners[index];
        this.sellerOptions = [];
        this.sellerOptions.push({
          value: seller.orgCode,
          title: `${seller.name} (${seller.orgCode})`,
        });
        this.connectionForm.get('seller').setValue(this.sellerOptions[0]);
      }

      let canBeModified = true;

      if (this.connection?.endDate) {
        const endDate = new Date(this.connection.endDate);
        if (endDate < new Date() && !this.isBillingAdmin) {
          canBeModified = false;
          if (this.isEditor) {
            this.notificationService.warning('Connection ended, edit is disabled', 'Disabled');
            this.ended = true;
          }
        }
      }

      if (this.connection?.bookStatus?.length > 0) {
        const selected: any[] = [];
        this.connection?.bookStatus?.forEach((st) => {
          selected.push({ code: st, name: st });
        });
        this.connectionForm.get('bookStatus').setValue(selected);
      }

      this.connectionForm.get('buyer').disable();
      this.connectionForm.get('seller').disable();

      if (!this.isBillingAdmin) {
        this.connectionForm.get('activationDateTime').disable();
      }

      this.connectionForm.get('connectionType').disable();

      if (!canBeModified || !this.isEditor) {
        this.connectionForm.get('setupPaid').disable();
        this.connectionForm.get('activationDateTime').disable();
        this.connectionForm.get('endDate').disable();
        this.connectionForm.get('isBuy').disable();
        this.connectionForm.get('connectionType').disable();
        this.connectionForm.get('free').disable();
        this.connectionForm.get('bookStatus').disable();
      }
    } else {
      if (!this.isBillingAdmin) {
        this.connectionForm.get('endDate').disable();
      }

      this.connectionForm.get('connectionType').setValue(this.connectionTypes[0]);
      this.connectionForm.get('buyer').setValue(this.buyerOptions[0]);
      this.connectionForm.get('seller').setValue(this.sellerOptions[0]);
    }

    this.isLoading = false;
  }

  buildBookStatusDropdown() {
    this.bookStatusSelector = [];
    this.bookStatus.forEach((status) => {
      this.bookStatusSelector.push({
        code: status,
        name: status,
      });
    });
  }

  buildBuyerSellerDropdown() {
    if (this.isCreating) {
      this.buyerOptions = [{ value: null, title: 'Select One' }];
      this.sellerOptions = [{ value: null, title: 'Select One' }];

      this.partners.forEach((p) => {
        this.buyerOptions.push({
          value: p.orgCode,
          title: `${p.name} (${p.orgCode})`,
        });
        this.sellerOptions.push({
          value: p.orgCode,
          title: `${p.name} (${p.orgCode})`,
        });
      });
    } else {
      this.buyerOptions = [];
      this.sellerOptions = [];
    }
  }

  buildForm() {
    this.connectionForm = this.fb.group({
      id: [{ value: '', disabled: true }],
      setupPaid: [false, Validators.required],
      activationDateTime: ['', Validators.required],
      endDate: [''],
      isBuy: [false, Validators.required],
      connectionType: ['', Validators.required],
      free: [false, Validators.required],
      buyer: ['', Validators.required],
      seller: ['', Validators.required],
      bookStatus: [''],
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  validDropdown(dropdownType: string) {
    if (!this.connectionForm.get(dropdownType).value.code) {
      this.connectionForm.get(dropdownType).setErrors({ 'Required': true });
    } else {
      this.connectionForm.get(dropdownType).setErrors(null);
    }
  }

  buildConnectionTypesSelect() {
    Object.keys(ConnectionType).forEach((value) => {
      this.connectionTypes.push({ code: value, label: value });
    });
  }

  closeModal() {
    this.connection = clone(this.connectionAux);
    this.activeModal.close(true);
  }

  onSave() {
    let endDate: any;

    let date = new Date(this.connectionForm.get('activationDateTime').value);
    let dateStr = date.getFullYear().toString();
    let month = date.getMonth() + 1;
    if (month < 10) {
      dateStr += '-0' + month.toString();
    } else {
      dateStr += '-' + month.toString();
    }
    let day = date.getDate();
    if (day < 10) {
      dateStr += '-0' + day.toString();
    } else {
      dateStr += '-' + day.toString();
    }
    dateStr += 'T00:00:00.000Z';
    const activationDateTime: any = dateStr;

    if (this.connectionForm.get('endDate').value !== '') {
      date = new Date(this.connectionForm.get('endDate').value);
      dateStr = date.getFullYear().toString();
      month = date.getMonth() + 1;
      if (month < 10) {
        dateStr += '-0' + month.toString();
      } else {
        dateStr += '-' + month.toString();
      }
      day = date.getDate();
      if (day < 10) {
        dateStr += '-0' + day.toString();
      } else {
        dateStr += '-' + day.toString();
      }
      dateStr += 'T23:59:59.000Z';
      endDate = dateStr;
    }

    if (this.isCreating) {
      //Mutation create
      const buyerCode = this.connectionForm.get('buyer').value.value;
      const sellerCode = this.connectionForm.get('seller').value.value;

      let index = this.connections.findIndex((x) => x.buyerCode === buyerCode && x.sellerCode === sellerCode);

      if (index === -1) {
        this.notificationService.warning(
          'This connection (buyer and seller pair) doesnt exists, you must create it in Connections > Main section first.',
        );
        return;
      }

      const mainConnection = this.connections[index];

      index = this.contractConnections.findIndex((x) => x.connection === mainConnection.id && !x.endDate);

      if (index > -1) {
        this.notificationService.warning(
          'This connection already exists without endDate, you must finalize first or you can clone it to change its rateType.',
        );
        return;
      }

      const createData: ContractConnectionCreateInput = {
        free: this.connectionForm.get('free').value,
        isBuy: this.connectionForm.get('isBuy').value,
        setupPaid: this.connectionForm.get('setupPaid').value,
        activationDateTime: activationDateTime,
        connectionType: this.connectionForm.get('connectionType').value.code,
        Contract: { connect: { id: this.contractId } },
        Connection: { connect: { id: mainConnection.id } },
      };

      if (endDate) {
        createData.endDate = endDate;
        if (new Date(activationDateTime) > new Date(endDate)) {
          this.notificationService.warning('Invalid dates, endDate must be greater than activationDate');
          return;
        }
      }

      if (this.connectionForm.get('bookStatus').value?.length > 0) {
        createData.bookStatus = {
          set: this.connectionForm.get('bookStatus').value.map((x) => x.code),
        };
      }

      this.contractConnectionService
        .createConnection(createData)
        .then((rs) => {
          this.notificationService.success('Contract connection succesfully created', 'Success');
          this.activeModal.close(rs);
        })
        .catch((err) => {
          this.activeModal.close(false);
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    } else {
      ///Mutation update

      const updateData: ContractConnectionUpdateInput = {
        free: { set: this.connectionForm.get('free').value },
        setupPaid: { set: this.connectionForm.get('setupPaid').value },
        isBuy: { set: this.connectionForm.get('isBuy').value },
        connectionType: { set: this.connectionForm.get('connectionType').value.code },
      };

      if (this.isBillingAdmin) {
        updateData.activationDateTime = { set: activationDateTime };
      }

      if (endDate) {
        updateData.endDate = { set: endDate };

        if (new Date(activationDateTime) > new Date(endDate)) {
          this.notificationService.warning('Invalid dates, endDate must be greater than activationDate');
          return;
        }
      }

      if (this.connectionForm.get('bookStatus').value?.length > 0) {
        updateData.bookStatus = { set: this.connectionForm.get('bookStatus').value.map((x) => x.code) };
      } else {
        updateData.bookStatus = { set: [] };
      }

      const where: ObjectWhereUniqueInputById = { id: this.connection.id };

      this.contractConnectionService
        .updateConnection(updateData, where)
        .then((rs) => {
          this.notificationService.success('Contract connection succesfully updated', 'Success');
          this.activeModal.close(rs);
        })
        .catch((err) => {
          this.activeModal.close(false);
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    }
  }

  validForm(): boolean {
    return (
      this.connectionForm?.get('connectionType').value?.code &&
      this.connectionForm.get('buyer').value?.value &&
      this.connectionForm.get('seller').value?.value &&
      this.connectionForm.valid
    );
  }
}
