import { gql } from 'apollo-angular';

export const updateSupplier = gql`
  mutation ($where: SupplierWhereUniqueInput!, $data: SupplierUpdateInput!) {
    admin {
      updateSupplier(where: $where, data: $data) {
        code
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
