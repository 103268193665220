<nb-card>
  <nb-card-header> Mapping Tool </nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-12">
        <div class="search-wrap mb-4">
          <div class="row" [formGroup]="mappingForm">
            <div class="col-lg-3 col-md-12 d-flex flex-column">
              <label class="form-label">Country</label>
              <p-dropdown
                styleClass="xen-filter-dropdown"
                [options]="countriesList"
                optionLabel="name"
                optionValue="isoCode2"
                placeholder="Select a Country"
                [filter]="true"
                formControlName="countryMapping"
                [(ngModel)]="selectedCountry"
                (onChange)="onCountryChange($event.value)"
                [showClear]="true"
              >
              </p-dropdown>
            </div>
            <div class="col-lg-6 col-md-12">
              <xen-input
                [label]="'Hotel'"
                [id]="'default'"
                [formControlName]="'hotelMapping'"
                [type]="'text'"
                [placeholder]="'Hotel name...'"
                [icon]="true"
                [readOnly]="false"
                [icon_snippet]="'fa-regular fa-search'"
                [icon_placement]="'right'"
                [size]="'medium'"
                [disabled]="isDisabled"
              ></xen-input>
            </div>
            <div class="col-lg-3 col-md-12 d-flex align-items-end">
              <xen-btn
                [type]="'primary'"
                [size]="'medium'"
                [label]="'Search masters'"
                [id]="'button id'"
                [full_width]="false"
                [icon]="true"
                [icon_placement]="'left'"
                [icon_snippet]="'far fa-search'"
                [disabled]="isDisabled"
                [loading]="false"
                [skin]="'blue'"
                (onClick)="onSubmit()"
              ></xen-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-4" *ngIf="formParams && !isLoading; else formParams && loadingTemplate">
        <xen-tabs [items]="tabs" id="mappingtabs" (tabChangeEvent)="selectTab($event)"> </xen-tabs>
        <div class="tab-content mt-3" id="mappingtabs">
          <div
            class="tab-pane fade"
            [ngClass]="tab.class ? 'show active' : ''"
            id="{{ tab.target }}"
            role="tabpanel"
            aria-labelledby="{{ tab.target }}"
            tabindex="0"
            *ngFor="let tab of tabs"
          >
            <div *ngIf="activeTab === '1'">
              <tgx-non-validate-table
                *ngIf="nonValidateMasters?.length > 0"
                [formParams]="formParams"
                (loadDataEvent)="onLoadDataEvent($event)"
              ></tgx-non-validate-table>
              <p *ngIf="errorNonValidate; else nonValidateMasters?.length === 0 && loadingTemplate">
                {{ errorNonValidate }}
              </p>
            </div>
            <div *ngIf="activeTab === '2'">
              <tgx-validate-table
                *ngIf="validateMasters?.length > 0"
                [formParams]="formParams"
                (loadDataEvent)="onLoadDataEvent()"
              ></tgx-validate-table>
              <p *ngIf="errorValidate; else validateMasters?.length === 0 && loadingTemplate">
                {{ errorValidate }}
              </p>
            </div>
            <div></div>
            <div *ngIf="activeTab === '3'">
              <tgx-unmaped-table
                *ngIf="unmappedList?.length > 0"
                [formParams]="formParams"
                (loadDataEvent)="onLoadDataEvent($event)"
              ></tgx-unmaped-table>
              <p *ngIf="errorUnmapped; else unmappedList?.length === 0 && loadingTemplate">
                {{ errorUnmapped }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>
<ng-template #loadingTemplate>
  <table class="xen-table is-full">
    <thead>
      <tr>
        <th *ngFor="let head of tHead">{{ head.label }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]">
        <td *ngFor="let item of [1, 2, 3, 4, 5]">
          <p-skeleton width="10rem"></p-skeleton>
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>

<ng-template #loadingError>
  <p-skeleton width="10rem"></p-skeleton>
</ng-template>
