import { Component, Input } from '@angular/core';

@Component({
  selector: 'xen-icon-app',
  templateUrl: './icon-app.component.html',
  styleUrls: ['./icon-app.component.scss'],
})
export class IconAppComponent {
  @Input() width: string;
  @Input() href: string;
  @Input() disabled: boolean;
  @Input() icon: string;
  @Input() font_size: string;
  @Input() type:
    | 'stats'
    | 'logging'
    | 'alerts'
    | 'speed'
    | 'traffic'
    | 'insights'
    | 'inventory'
    | 'distribution'
    | 'billing'
    | 'portfolio'
    | 'err-overview'
    | 'api-settings'
    | 'connections'
    | 'content'
    | 'plugins'
    | 'roadmap'
    | 'api-keys'
    | 'tickets'
    | 'b2b' = 'stats';

  public get classes(): Array<string> {
    return [`xen-icon-app--${this.type}`, this.disabled ? `disabled` : ''];
  }

  ngOnChanges(): void {
    if (this.type === 'stats') {
      this.icon = 'fa-chart-simple';
    }
    if (this.type === 'logging') {
      this.icon = 'fa-file-binary';
    }
    if (this.type === 'alerts') {
      this.icon = 'fa-bells';
    }
    if (this.type === 'speed') {
      this.icon = 'fa-gauge-high';
    }
    if (this.type === 'traffic') {
      this.icon = 'fa-wrench';
    }
    if (this.type === 'portfolio') {
      this.icon = 'fa-hotel';
    }
    if (this.type === 'err-overview') {
      this.icon = 'fa-square-exclamation';
    }
    if (this.type === 'inventory') {
      this.icon = 'fa-bed';
    }
    if (this.type === 'distribution') {
      this.icon = 'fa-circle-nodes';
    }
    if (this.type === 'billing') {
      this.icon = 'fa-money-bill';
    }
    if (this.type === 'b2b') {
      this.icon = 'fa-globe';
    }
    if (this.type === 'api-settings') {
      this.icon = 'fa-cogs';
    }
    if (this.type === 'connections') {
      this.icon = 'fa-plug';
    }
    if (this.type === 'plugins') {
      this.icon = 'fa-puzzle-piece';
    }
    if (this.type === 'content') {
      this.icon = 'fa-square-h';
    }
    if (this.type === 'roadmap') {
      this.icon = 'fa-calendar-days';
    }
    if (this.type === 'api-keys') {
      this.icon = 'fa-key';
    }
    if (this.type === 'tickets') {
      this.icon = 'fa-ticket';
    }
  }

  ngOnInit(): void {}
}
