import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
import SeoJson from './../../../../../seo-pages.json';

@Injectable({
  providedIn: 'root',
})
export class PlatformHeadService {
  private seoPagesData: any[];
  private urlServerWeb: string;

  constructor(
    @Inject('urlServerWeb') urlServerWeb: string,
    private http: HttpClient,
    private title: Title,
    private meta: Meta,
  ) {
    this.urlServerWeb = urlServerWeb;
  }

  updateTag(routeUrl: string) {
    this.meta.updateTag({
      property: 'og:url',
      content: this.urlServerWeb + routeUrl,
    });
  }

  getSeoPagesData(route: any) {
    const splitRoute = route.urlAfterRedirects.split('?')[0].split('/');

    const routesInit = ['signup', 'dashboard', 'network', 'network-v2', 'connections', 'content', '404', 'tickets'];
    const indexInit = splitRoute.findIndex((x: any) => routesInit.includes(x));
    let splitRouteRemoveOrg = splitRoute;
    if (indexInit !== -1) {
      splitRouteRemoveOrg = splitRoute.slice(indexInit - 1);
    }
    splitRouteRemoveOrg.shift();

    const routeUrl = '/' + splitRouteRemoveOrg.join('/');

    if (this.seoPagesData) {
      this.setSeoPage(routeUrl);
    }

    this.seoPagesData = JSON.parse(JSON.stringify(SeoJson));
    this.setSeoPage(routeUrl);
  }

  setSeoPage(routeUrl: string) {
    if (this.seoPagesData && this.seoPagesData.length > 0) {
      const seoPageSort = this.seoPagesData.sort((a, b) => b.url.length - a.url.length);
      const seoPage = seoPageSort.find((x) => (x.url === '/' ? routeUrl === '/' : routeUrl.startsWith(x.url)));
      if (seoPage) {
        if (seoPage['title']) {
          if (!(seoPage['url'] === '/network' && routeUrl.length > 9)) {
            this.title.setTitle(seoPage.title);
          }
        }
        if (seoPage['metaItems']) {
          const metaItemsJson = JSON.parse(seoPage.metaItems);
          metaItemsJson.forEach((iMeta: any) => {
            this.meta.updateTag(iMeta);
          });
        }
      }
    }
  }
}
