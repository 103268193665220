import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'custom-date-renderer',
  templateUrl: './custom-date-renderer.html',
  styleUrls: ['./custom-date-renderer.scss'],
})
export class CustomDateRendererComponent implements OnInit {
  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  ngOnInit() {
    this.renderValue = <string>this.value;
  }
}
