import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { cifValidator } from './cif-validator';
import { generateSlug } from './generate-slug';
import { capitalize } from './capitalize';

export function warning(validator: ValidatorFn): ValidatorFn {
  return (control: AbstractControl | any): ValidationErrors | null => {
    control.warnings = validator(control);
    return null;
  };
}

export function addProtocol(url: string): string {
  if (!/^(?:f|ht)tps?\:\/\/(\/*)/.test(url)) {
    url = 'http://' + url;
  }
  return url;
}

export function hostname(url: string): string {
  try {
    const parsedUrl = addProtocol(url.toLowerCase());
    const domain = new URL(parsedUrl);
    const hostmane = domain.hostname.replace('www.', '');
    return hostmane;
  } catch {
    return url;
  }
}

export function rangeValidator(min: number, max: number) {
  return (control) => {
    const value = control.value;
    if (value < min || value > max) {
      return { range: true };
    }
    return null;
  };
}

// Custom validator for password strength
export function passwordStrengthValidator(control: AbstractControl): ValidationErrors | null {
  const password = control.value;
  const minLength = 12;
  const minSpecialChar = 1;
  const minNum = 1;
  const minUpperCase = 1;

  const specialCharSet = '!@#&';
  const numberSet = '0123456789';
  const upperCharSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  let specialCharCount = 0;
  let numCount = 0;
  let upperCaseCount = 0;

  for (const char of password) {
    if (specialCharSet.includes(char)) {
      specialCharCount++;
    } else if (numberSet.includes(char)) {
      numCount++;
    } else if (upperCharSet.includes(char)) {
      upperCaseCount++;
    }
  }

  const errors: ValidationErrors = {};
  if (password.length < minLength) {
    errors.minLength = `The password must be at least ${minLength} characters long.`;
  }
  if (specialCharCount < minSpecialChar) {
    errors.minSpecialChar = `The password must contain at least ${minSpecialChar} special character${minSpecialChar > 1 ? 's' : ''}. (E.g. ${specialCharSet})`;
  }
  if (numCount < minNum) {
    errors.minNum = `The password must contain at least ${minNum} number(s).`;
  }
  if (upperCaseCount < minUpperCase) {
    errors.minUpperCase = `The password must contain at least ${minUpperCase} uppercase letter(s).`;
  }

  return Object.keys(errors).length ? errors : null;
}

export class FormValidator {
  static emailRegex(control: AbstractControl): ValidationErrors | null {
    const email = control.value;

    if (!email) {
      return null;
    }

    const EMAIL_REGEXP =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (email.length <= 5 || !EMAIL_REGEXP.test(email)) {
      return { emailRegex: true, errorMsg: 'Please provide a valid business email.' };
    }

    const BUSINESS_REGEXP =
      /@gmail\.|@outlook\.|@hotmail\.|@yahoo\.|@aol\.|@proton\.me|@zoho\.|@icloud\.|@gmx\.|@ymail\.|@live\.|@rocketmail\./;

    if (email.match(BUSINESS_REGEXP)) {
      return {
        emailRegex: true,
        errorMsg: 'Please indicate your business email address in order to continue.',
      };
    }

    return null;
  }

  static validateUrl(field: string) {
    return (control: AbstractControl): ValidationErrors | null => {
      const url = control.value;
      if (!url) {
        return null;
      }

      const urlRegex = /(http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/;
      if (!url.match(urlRegex)) {
        return { validUrl: false, errorMsg: 'Please provide a valid ' + field };
      }

      return null;
    };
  }

  static domainValidator(list: string[], included: boolean = true, archived: boolean = false): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value || list.length === 0) {
        return null;
      }

      const found = list.find((l) => {
        if (typeof l === 'string') {
          return hostname(l) === hostname(control.value);
        }
        return false;
      });

      if (list && !!found !== included) {
        if (included) {
          return { notIncluded: true };
        }
        if (archived) {
          return { archivedIncluded: true };
        }
        return { included: true };
      }
      return null;
    };
  }

  static validateCif = (control: AbstractControl): ValidationErrors | null => {
    const document = control.value || '';
    const str = document.toUpperCase().replace(/\s/g, '');

    return cifValidator(str)
      ? {
          validateCIF: true,
          errorMsg: 'The VAT/ID number must start with 1 character and must be followed by 8 digits',
        }
      : null;
  };

  static validateUser(signupService /* : SignupService */) {
    return (control: AbstractControl): ValidationErrors | null => {
      const email = control.value;

      if (!email) {
        return null;
      }

      return signupService
        .validateUser({ userEmail: email })
        .then((_) => {
          return null;
        })
        .catch((err) => {
          const message = capitalize(err.error?.errors[0]?.description ?? err.statusText);
          return { errorMsg: message };
        });
    };
  }

  static requiredTrue(control: AbstractControl): ValidationErrors | null {
    if (!control.value || control.value === 'false') {
      return { requiredTrue: true, errorMsg: 'You must agree before submitting' };
    }

    return null;
  }

  static required(field: string) {
    return (control: AbstractControl): ValidationErrors | null => {
      const nonWhitespace = /[\S]/;
      if (!control.value || !nonWhitespace.test(control.value)) {
        if (field === 'Partner Type' || field === 'integrationType') {
          return { required: true, errorMsg: 'Please click on one of these options' };
        }
        return { required: true, errorMsg: 'Please provide your ' + field };
      }

      return null;
    };
  }

  static checkInputValue(field: string) {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.hasValidator(Validators.required)) {
        return null;
      }
      const nonWhitespace = /[\S]/;
      if (!control.value || !nonWhitespace.test(control.value)) {
        return { errorMsg: 'Please provide your ' + field };
      }

      return null;
    };
  }

  static textLength(field: string, maxLength: number) {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const words = control.value.trim().split(' ');

      if (words.length > maxLength) {
        return { textLength: true, errorMsg: field + " can't have more than " + maxLength + ' words' };
      }

      return null;
    };
  }

  static textNumbers(field: string) {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      if (/\d/.test(control.value)) {
        return { textNumbers: true, errorMsg: field + " can't contain numbers" };
      }

      return null;
    };
  }

  static textOnlyNumbers(field: string) {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      if (!/^\d+$/.test(control.value)) {
        return { textNumbers: true, errorMsg: `${field} can only contain numbers` };
      }

      return null;
    };
  }

  static minLengthValidator(field: string, minLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      const value = control.value;
      if (value && value.length >= minLength) {
        return null;
      } else {
        return { required: true, errorMsg: field + " can't have less than " + minLength + ' characters' };
      }
    };
  }

  static onlyAlphanumeric(field: string) {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value || /^[a-zA-Z0-9-\s]*$/.test(control.value)) {
        return null;
      }

      return { textNumbers: true, errorMsg: field + ' must be alphanumeric' };
    };
  }

  static maxLength(field: string, maxLength: number) {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value || control.value?.length <= maxLength) {
        return null;
      }

      return { maxLength: true, errorMsg: field + " can't be longer than " + maxLength + ' characters' };
    };
  }

  static textInvalidChar(field: string) {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const invalidCharacters = /[\/@!#$%^&*()+={}\[\]|\\'";:.,~№¿?<>]+/i;

      if (invalidCharacters.test(control.value)) {
        return {
          textInvalidChar: true,
          errorMsg: `${field} can't contain invalid characters: @!#$%^&*()+={}[]|\/'";:.,~№¿?<>`,
        };
      }

      return null;
    };
  }

  static invalidStreetAddress(field: string) {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const invalidCharacters = /[\/@!#$%^&*()+={}\[\]|\\'";:.~№¿?<>]+/i;

      if (invalidCharacters.test(control.value)) {
        return {
          textInvalidChar: true,
          errorMsg: `${field} can't contain invalid characters: @!#$%^&*()+={}[]|\/'";:.~№¿?<>`,
        };
      }

      return null;
    };
  }

  static validatorAgainstList(list: any[], included: boolean = true): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const found = list.find((l) => {
        if (typeof l === 'string') {
          return l.toLowerCase() === ('' + control.value).toLowerCase();
        } else if (typeof l === 'number') {
          l === +control.value;
        } else if (typeof l === 'boolean' || l === null || l === undefined) {
          l === control.value;
        }
        return false;
      });

      if (list && !!found !== included) {
        if (included) {
          return { notIncluded: true };
        }
        return { included: true };
      }

      return null;
    };
  }

  static validatorAgainstSlugList(list: any[], included: boolean = true, archived: boolean = false): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = generateSlug(control.value);

      const found = list.find((l) => {
        if (typeof l === 'string') {
          return l === value;
        } else if (typeof l === 'number') {
          l === +value;
        } else if (typeof l === 'boolean' || l === null || l === undefined) {
          l === value;
        }
        return false;
      });

      if (list && !!found !== included) {
        if (included) {
          return { notIncluded: true };
        }
        if (archived) {
          return { archivedIncluded: true };
        }
        return { included: true };
      }

      return null;
    };
  }
}
