import { SharedModule } from './../../../../@core/shared/shared.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NbCardModule } from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReportsRoutingModule } from './reports.routing-module';
import { ReportsBillingComponent } from './components/reports.component';
import { BillingReportAllContractConnectionsComponent } from './components/report-all-contract-connections/report-all-contract-connections.component';
import { BillingReportAllContractsComponent } from './components/report-all-contracts/report-all-contracts.component';
import { BillingReportAllInvoicedDataByMonthComponent } from './components/report-invoiced-data-by-month/report-invoiced-data-by-month.component';
import { CalendarModule } from 'primeng/calendar';
import { BillingReportAllConnectionsComponent } from './components/report-all-connections/report-all-connections.component';
import { BillingReportAllContractsCostsComponent } from './components/report-all-contracts-costs/report-all-contracts-costs.component';
import { BillingReportMissingConnectionsComponent } from './components/report-missing-connections/report-missing-connections.component';

@NgModule({
  imports: [
    ReportsRoutingModule,
    SharedModule,
    FormsModule,
    NbCardModule,
    CommonModule,
    CalendarModule,
    ReactiveFormsModule,
  ],
  declarations: [
    ReportsBillingComponent,
    BillingReportAllContractConnectionsComponent,
    BillingReportAllContractsComponent,
    BillingReportAllInvoicedDataByMonthComponent,
    BillingReportAllConnectionsComponent,
    BillingReportAllContractsCostsComponent,
    BillingReportMissingConnectionsComponent,
  ],
})
export class ReportsBillingModule {}
