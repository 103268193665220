import { Table } from 'primeng/table';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import {
  Contract,
  Partner,
  BookingL2BCost,
  PartnerModelType,
} from '../../../../../interfaces/_index.billing.interfaces';
import { PartnerService, ContractService, CostsL2BService } from '../../../../../services/_index-billing.services';
import { NotificationService, WebAdminService } from './../../../../../../../@core/services/_index-core.services';
import { OrganizationSelector } from './../../../../../../entities/interfaces/_index-entities.interfaces';
import { Options } from './../../../../../../../@core/interfaces/_index.interfaces';
import { ModalDeleteComponent } from './../../../../../../../@core/shared/modal-delete/modal-delete.component';
import { BillingL2BCostModalComponent } from './../l2b-cost-modal/l2b-cost-modal.component';

@Component({
  selector: 'tgx-admin-billing-costs-table',
  templateUrl: './l2b-costs-table.component.html',
  styleUrls: ['./l2b-costs-table.component.scss'],
})
export class BillingL2BCostsTableComponent implements OnInit, OnDestroy {
  @ViewChild('dt', { static: false }) dataTable!: Table;
  $subs: Subscription[] = [];

  header = [
    { label: 'ID', field: 'id' },
    { label: 'MinL2B', field: 'minl2b' },
    { label: 'MaxL2B', field: 'maxl2b' },
    { label: 'MinBook', field: 'minBook' },
    { label: 'MaxBook', field: 'maxBook' },
    { label: 'Amount', field: 'amount' },
    { label: 'PaymentType', field: 'paymentType' },
    { label: 'Options', field: 'Options' },
  ];

  costsL2BLoadSource: any[];

  contracts: Contract[];

  costsL2b: BookingL2BCost[];

  isLoading: boolean;

  orgSelected: OrganizationSelector;
  partner: Partner;

  contractsSelector: Options[] = [{ code: null, label: 'Without contracts' }];
  selectedContract: Options;

  isEditor = false;

  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private webAdminService: WebAdminService,
    private partnerService: PartnerService,
    private contractService: ContractService,
    private costsL2BService: CostsL2BService,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;

    this.buildOptions();

    this.$subs.push(
      this.webAdminService.orgSelected.subscribe(async (orgSelected) => {
        if (orgSelected?.code) {
          this.orgSelected = orgSelected;
          await this.partnerService.getPartner(orgSelected.code).then((partner) => {
            if (partner) {
              this.partner = partner;
              this.getContracts(this.partner.orgCode);
            } else {
              this.partner = null;
              this.isLoading = false;
            }
          });
        }
      }),
    );
  }

  ngOnDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
  }

  buildOptions() {
    this.isEditor = this.webAdminService.isBillingEditorOrAdmin();
  }

  getContracts(orgCode: string) {
    if (orgCode) {
      this.contractService
        .getContracts(orgCode)
        .then((cnts) => {
          // Descartamos aquí en el selector los que son de newPricing, ya que no tienen costes personalizados,
          // tienen unos costes genéricos que se pintan en otra sección a modo solo de lectura.

          if (cnts?.length > 0) {
            this.contractsSelector = [];
            this.contracts = cnts.filter(
              (x) => x.modelType !== PartnerModelType.CM22 && x.modelType !== PartnerModelType.CM23,
            );
            if (this.contracts?.length === 0) {
              this.withoutContracts();
            }
            this.buildSelector();
            this.selectedContract = this.contractsSelector[0];
            this.getL2BCosts();
          } else {
            this.withoutContracts();
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
          this.isLoading = false;
        });
    }
  }

  withoutContracts() {
    this.contractsSelector = [];
    this.contractsSelector.push({ code: null, label: 'Without contracts' });
  }

  buildSelector() {
    if (this.contracts?.length > 0) {
      this.contracts.forEach((c) => {
        this.contractsSelector.push({
          code: c.id.toString(),
          label:
            '(' +
            c.id +
            ') ' +
            c.partnerType +
            (c.modelType ? ' - ' + c.modelType : '') +
            (c.ContractType ? ' - ' + c.ContractType.name : ''),
        });
      });
    }
  }

  async getL2BCosts() {
    this.isLoading = true;
    this.costsL2BLoadSource = [];
    if (this.dataTable) {
      this.dataTable.clearFilterValues();
    }
    if (this.selectedContract?.code) {
      const costs = await this.costsL2BService.getL2BCosts(Number(this.selectedContract?.code));

      if (costs.length > 0) {
        costs.forEach((c) => {
          const { id, minl2b, maxl2b, minbookings: minBook, maxbookings: maxBook, amount, paymentType } = c;

          this.costsL2BLoadSource.push({
            id,
            minl2b,
            maxl2b,
            minBook,
            maxBook,
            amount,
            paymentType,
          });
        });
        this.isLoading = false;
        this.costsL2BLoadSource = [...this.costsL2BLoadSource];
        this.costsL2BLoadSource.sort((a, b) => (a.id < b.id ? -1 : 1));
      } else {
        this.isLoading = false;
      }
    } else {
      this.isLoading = false;
    }
  }

  async onCreate(): Promise<void> {
    if (this.selectedContract.code === null) {
      this.notificationService.warning('You can not create a l2bCost, first you must to create a contract.');
      return;
    }

    const activeModal = this.modalService.open(BillingL2BCostModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = 'Create L2B Cost';
    activeModal.componentInstance.contractId = Number(this.selectedContract.code);
    activeModal.componentInstance.isEditor = this.isEditor;

    const self = this;
    activeModal.result.then((res) => {
      // OK
      if (typeof res === 'object') {
        self.getL2BCosts();
      }
    });
  }

  async onEdit(event): Promise<void> {
    const cost: BookingL2BCost = event;

    const activeModal = this.modalService.open(BillingL2BCostModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = (this.isEditor ? 'Edit ' : 'View ') + 'L2B Cost';
    activeModal.componentInstance.cost = cost;
    activeModal.componentInstance.contractId = Number(this.selectedContract.code);
    activeModal.componentInstance.isEditor = this.isEditor;

    const self = this;
    activeModal.result.then((res) => {
      // OK
      if (typeof res === 'object') {
        self.getL2BCosts();
      }
    });
  }

  onDelete(event): void {
    const modalRef = this.modalService.open(ModalDeleteComponent, {
      backdrop: 'static',
      container: 'nb-layout',
    });

    modalRef.componentInstance.message = 'Are you sure that you want to delete l2b cost ' + event.id.toString() + '?';
    modalRef.componentInstance.title = 'Delete L2B Cost';
    modalRef.componentInstance.buttonOkTitle = 'Delete';

    const self = this;

    modalRef.result
      .then((res) => {
        // on close
        if (res) {
          self.costsL2BService
            .deleteL2BCost({ id: event.id })
            .then((rs) => {
              this.notificationService.success('L2B Cost succesfully deleted', 'Success');
              modalRef.close(rs);
              self.getL2BCosts();
            })
            .catch((err) => {
              this.notificationService.handleGatewayAndGraphqlErrors(err);
            });
        }
      })
      .catch((error) => {
        self.notificationService.handleGatewayAndGraphqlErrors(error);
      });
  }

  filter(value: any, field: string) {
    if (value) {
      this.dataTable.filter(value, field, 'contains');
    }
  }
}
