import { gql } from 'apollo-angular';

export const updateOrganizationGiata = gql`
  mutation ($orgCode: ID!, $configuration: String, $isActive: Boolean) {
    admin {
      updateOrganizationGiata(
        where: { search: $orgCode, searchBy: CODE }
        data: { configuration: $configuration, isActive: $isActive }
      ) {
        code
        adviseMessage {
          code
          description
        }
      }
    }
  }
`;
