<div class="xen-step">
  <div class="item" [ngClass]="stepClasses">
    <div class="item__label">
      <p class="mb-0 lh-base" title="{{ label }}">{{ label }}</p>
      <i *ngIf="level === 'child' && status !== 'default'" class="item__label-icon" [ngClass]="this.icon"></i>
    </div>
    <div class="item__actions">
      <xen-link
        *ngIf="edit"
        label="Edit"
        href=""
        size="small"
        [icon]="true"
        icon_placement="left"
        icon_snippet="far fa-edit"
      ></xen-link>
      <xen-badge
        *ngIf="level === 'parent' && status !== 'default'"
        type="icon"
        [icon]="this.icon"
        status="{{ status }}"
      ></xen-badge>
    </div>
  </div>
</div>
