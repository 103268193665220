import { Options } from './../../../../../../../@core/interfaces/_index.interfaces';
import { NotificationService } from './../../../../../../../@core/services/notification.service';
import { ContractPluginService } from './../../../../../services/_index-billing.services';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { clone } from '../../../../../../../@core/utils/utils';
import {
  ObjectWhereUniqueInputById,
  ContractPlugin,
  pluginType,
  ContractPluginCreateInput,
  ContractPluginUpdateInput,
} from '../../../../../interfaces/_index.billing.interfaces';
import { WebAdminService } from '../../../../../../../@core/services/web-admin.service';

@Component({
  selector: 'tgx-admin-billing-plugins-modal',
  styleUrls: ['./plugins-modal.component.scss'],
  templateUrl: 'plugins-modal.component.html',
})
export class BillingPluginsModalComponent implements OnInit {
  @Input() plugin: any = null;
  @Input() header: string;
  @Input() contractId: number;
  @Input() isEditor: boolean;

  isCreating: boolean;
  pluginAux: ContractPlugin;
  ended = false;

  buyerOptions: any[];
  sellerOptions: any[];

  public pluginForm: UntypedFormGroup;

  pluginTypes: Options[] = [{ code: null, label: 'Select One' }];

  isLoading = false;
  isBillingAdmin = false;

  isCX = false;

  constructor(
    private notificationService: NotificationService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private contractPluginService: ContractPluginService,
    private webAdminService: WebAdminService,
  ) {}

  async ngOnInit() {
    this.isLoading = true;
    this.isCreating = true;

    this.isBillingAdmin = this.webAdminService.isBillingEditorOrAdmin(true);
    if (this.plugin) {
      this.isCreating = false;
    }

    this.buildForm();
    await this.buildPluginTypesSelect();

    if (!this.isCreating) {
      this.pluginForm.get('activationDateTime').setValue(new Date(this.plugin.activationDateTime));
      if (this.plugin.endDate) {
        this.pluginForm.get('endDate').setValue(new Date(this.plugin.endDate));
      }

      this.pluginForm.get('code').setValue(this.plugin.code);
      this.pluginForm
        .get('pluginType')
        .setValue(this.pluginTypes[this.pluginTypes.findIndex((x) => x.label === this.plugin.pluginType)]);

      if (this.pluginForm?.get('pluginType').value?.label === 'CHANNELX') {
        this.isCX = true;
      }

      let canBeModified = true;

      if (this.plugin?.endDate) {
        const endDate = new Date(this.plugin.endDate);
        if (endDate < new Date() && !this.isBillingAdmin) {
          canBeModified = false;
          if (this.isEditor) {
            this.notificationService.warning('Plugin ended, edit is disabled', 'Disabled');
            this.ended = true;
          }
        }
      }
      if (!this.isBillingAdmin) {
        this.pluginForm.get('activationDateTime').disable();
      }

      this.pluginForm.get('pluginType').disable();

      if (!canBeModified || !this.isEditor) {
        this.pluginForm.get('activationDateTime').disable();
        this.pluginForm.get('endDate').disable();
        this.pluginForm.get('pluginType').disable();
        this.pluginForm.get('code').disable();
      }
    } else {
      if (!this.isBillingAdmin) {
        this.pluginForm.get('endDate').disable();
      }

      this.pluginForm.get('pluginType').setValue(this.pluginTypes[0]);
    }

    this.isLoading = false;
  }

  buildForm() {
    this.pluginForm = this.fb.group({
      activationDateTime: ['', Validators.required],
      endDate: [''],
      pluginType: ['', Validators.required],
      code: [''],
    });
  }

  validDropdown(dropdownType: string) {
    if (!this.pluginForm.get(dropdownType).value.code) {
      this.pluginForm.get(dropdownType).setErrors({ 'Required': true });
    } else {
      this.pluginForm.get(dropdownType).setErrors(null);
    }
    if (this.pluginForm?.get(dropdownType).value?.label !== 'CHANNELX') {
      this.isCX = false;
      this.pluginForm.get('code').setErrors(null);
    } else {
      this.isCX = true;
      this.pluginForm.get('code').setErrors({ 'Required': true });
    }
    this.pluginForm.get('code').setValue('');
  }

  async buildPluginTypesSelect() {
    await this.contractPluginService
      .getPluginTypes()
      .then((rs) => {
        rs?.forEach((p) => {
          if (p.pluginType !== pluginType.DEFAULT) {
            this.pluginTypes.push({ code: p.id.toString(), label: p.pluginType });
          }
        });
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      });
  }

  closeModal() {
    this.plugin = clone(this.pluginAux);
    this.activeModal.close(true);
  }

  onSave() {
    let endDate: any;

    let date = new Date(this.pluginForm.get('activationDateTime').value);
    let dateStr = date.getFullYear().toString();
    let month = date.getMonth() + 1;
    if (month < 10) {
      dateStr += '-0' + month.toString();
    } else {
      dateStr += '-' + month.toString();
    }
    let day = date.getDate();
    if (day < 10) {
      dateStr += '-0' + day.toString();
    } else {
      dateStr += '-' + day.toString();
    }
    dateStr += 'T00:00:00.000Z';
    const activationDateTime: any = dateStr;

    if (this.pluginForm.get('endDate').value !== '') {
      date = new Date(this.pluginForm.get('endDate').value);
      dateStr = date.getFullYear().toString();
      month = date.getMonth() + 1;
      if (month < 10) {
        dateStr += '-0' + month.toString();
      } else {
        dateStr += '-' + month.toString();
      }
      day = date.getDate();
      if (day < 10) {
        dateStr += '-0' + day.toString();
      } else {
        dateStr += '-' + day.toString();
      }
      dateStr += 'T23:59:59.000Z';
      endDate = dateStr;
    }

    if (this.isCreating) {
      //Mutation create

      const createData: ContractPluginCreateInput = {
        code: this.pluginForm.get('code').value,
        activationDateTime: activationDateTime,
        Contract: { connect: { id: this.contractId } },
        Plugin: { connect: { id: Number(this.pluginForm.get('pluginType').value.code) } },
      };

      if (endDate) {
        createData.endDate = endDate;
        if (new Date(activationDateTime) > new Date(endDate)) {
          this.notificationService.warning('Invalid dates, endDate must be greater than activationDate');
          return;
        }
      }

      this.contractPluginService
        .createContractPlugin(createData)
        .then((rs) => {
          this.notificationService.success('Contract plugin succesfully created', 'Success');
          this.activeModal.close(rs);
        })
        .catch((err) => {
          this.activeModal.close(false);
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    } else {
      ///Mutation update

      const updateData: ContractPluginUpdateInput = {
        code: { set: this.pluginForm.get('code').value },
      };

      if (this.isBillingAdmin) {
        updateData.activationDateTime = { set: activationDateTime };
      }

      if (endDate) {
        updateData.endDate = { set: endDate };

        if (new Date(activationDateTime) > new Date(endDate)) {
          this.notificationService.warning('Invalid dates, endDate must be greater than activationDate');
          return;
        }
      }

      const where: ObjectWhereUniqueInputById = { id: this.plugin.id };

      this.contractPluginService
        .updateContractPlugin(updateData, where)
        .then((rs) => {
          this.notificationService.success('Contract plugin succesfully updated', 'Success');
          this.activeModal.close(rs);
        })
        .catch((err) => {
          this.activeModal.close(false);
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    }
  }

  validForm(): boolean {
    return (
      this.pluginForm?.get('pluginType').value?.code &&
      (this.pluginForm?.get('pluginType').value?.label !== 'CHANNELX' ||
        (this.pluginForm?.get('pluginType').value?.label === 'CHANNELX' &&
          this.pluginForm?.get('code').value !== '')) &&
      this.pluginForm.valid
    );
  }
}
