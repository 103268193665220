import { getBillingAccounts } from './getBillingAccounts';
import { getContractDiscounts } from './getContractDiscounts';
import { getContracts } from './getContracts';
import { getInvoiceBreakdowns } from './getInvoiceBreakdowns';
import { getInvoices } from './getInvoices';
import { getPartner } from './getPartner';
import { getPartners } from './getPartners';
import { getCosts } from './getCosts';
import { getL2BCosts } from './getL2BCosts';
import { getNewPricingCosts } from './getNewPricingCosts';
import { getContractsCountByType } from './getContractsCountByType';
import { getContractsGroupedByType } from './getContractsGroupedByType';
import { getPartnersWithSomeContract } from './getPartnersWithSomeContract';
import { getAllCurrentConnections } from './getAllCurrentConnections';
import { getInvoicedDataByMonth } from './getInvoicedDataByMonth';
import { getAllCurrentContracts } from './getAllCurrentContracts';
import { getBestFivePartners } from './getBestFivePartners';
import { getBestFiveConnections } from './getBestFiveConnections';
import { getContractConnections } from './getContractConnections';
import { getContractConnectionsByBuyerAndSeller } from './getContractConnectionsByBuyerAndSeller';
import { getDefaultCosts } from './getDefaultCosts';
import { getConnectionsFullInfo } from './getConnectionsFullInfo';
import { getChangePaymentRequests } from './getChangePaymentRequests';
import { getContractTypes } from './getContractTypes';
import { getAllCM23ContractCosts } from './getAllCM23ContractCosts';
import { getGlobalChangePaymentRequests } from './getGlobalChangePaymentRequests';
import { getMissingConnections } from './getMissingConnections';
import { getInconclusiveConnections } from './getInconclusiveConnections';
import { getAllBillScheduled } from './getAllBillScheduled';
import { getLastBillScheduled } from './getLastBillScheduled';
import { getConnectionsFullInfoById } from './getConnectionsFullInfoById';
import { getContractConnectionByIds } from './getContractConnectionByIds';
import { getBillingAccountContacts } from './getBillingAccountContacts';
import { getContractPlugins } from './getContractPlugins';
import { getPlugins } from './getPlugins';
import { getGatewayClientSuppliers } from './getGatewayClientSuppliers';
import { getContractConnectionDiscounts } from './getContractConnectionDiscounts';
import { getPartnersById } from './getPartnersById';

export const GraphqlApiBillingQueries = {
  // Gateway
  getGatewayClientSuppliers: getGatewayClientSuppliers,
  // Billing
  getPartners: getPartners,
  getPartner: getPartner,
  getPartnersById: getPartnersById,
  getBillingAccounts: getBillingAccounts,
  getContracts: getContracts,
  getContractDiscounts: getContractDiscounts,
  getInvoices: getInvoices,
  getInvoiceBreakdowns: getInvoiceBreakdowns,
  getCosts: getCosts,
  getL2BCosts: getL2BCosts,
  getNewPricingCosts: getNewPricingCosts,
  getContractsCountByType: getContractsCountByType,
  getContractsGroupedByType: getContractsGroupedByType,
  getPartnersWithSomeContract: getPartnersWithSomeContract,
  getAllCurrentConnections: getAllCurrentConnections,
  getAllCurrentContracts: getAllCurrentContracts,
  getInvoicedDataByMonth: getInvoicedDataByMonth,
  getBestFivePartners: getBestFivePartners,
  getBestFiveConnections: getBestFiveConnections,
  getContractConnections: getContractConnections,
  getContractConnectionsByBuyerAndSeller: getContractConnectionsByBuyerAndSeller,
  getContractConnectionByIds: getContractConnectionByIds,
  getDefaultCosts: getDefaultCosts,
  getConnectionsFullInfo: getConnectionsFullInfo,
  getConnectionsFullInfoById: getConnectionsFullInfoById,
  getChangePaymentRequests: getChangePaymentRequests,
  getContractTypes: getContractTypes,
  getAllCM23ContractCosts: getAllCM23ContractCosts,
  getMissingConnections: getMissingConnections,
  getInconclusiveConnections: getInconclusiveConnections,
  getGlobalChangePaymentRequests: getGlobalChangePaymentRequests,
  getAllBillScheduled: getAllBillScheduled,
  getLastBillScheduled: getLastBillScheduled,
  getBillingAccountContacts: getBillingAccountContacts,
  getContractPlugins: getContractPlugins,
  getPlugins: getPlugins,
  getContractConnectionDiscounts: getContractConnectionDiscounts,
};
