export interface Agreement {
  ID?: number;
  Type?: string;
  Name?: string;
  IdBlob?: string;
  Approved?: boolean;
  TemplateId?: number;
  ContractId?: number;
  CreatedAt?: Date;
  UpdatedAt?: Date;
}

export interface AgreementsResponse {
  success?: boolean;
  response?: Agreement[];
}
