import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { WebAdminService } from './../../../../../../@core/services/web-admin.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDeleteComponent } from './../../../../../../@core/shared/modal-delete/modal-delete.component';
import { NbStepperComponent } from '@nebular/theme';
import { BillingProcessPreBillModalComponent } from './invoicing-process-pre-bill-modal/invoicing-process-pre-bill-modal.component';
import { BillScheduledService, PreAndPostCheckService } from '../../../../services/_index-billing.services';
import { NotificationService } from './../../../../../../@core/services/notification.service';
import { BillScheduled } from '../../../../interfaces/_index.billing.interfaces';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'tgx-invoicing-process-pre-step',
  templateUrl: './invoicing-process-pre-step.component.html',
  styleUrls: ['./invoicing-process-pre-step.component.scss'],
})
export class InvoicingProcessPreStepComponent implements OnInit, OnDestroy {
  @ViewChild('stepper') stepper: NbStepperComponent;

  constructor(
    private webAdminService: WebAdminService,
    private preAndPostCheckService: PreAndPostCheckService,
    private billScheduledService: BillScheduledService,
    private notificationService: NotificationService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  isEditor = false;

  validSteps: Map<number, boolean>;

  hasFutureSchedule = false;

  message: string;

  async ngOnInit(): Promise<void> {
    this.buildOptions();
    this.buildStepMap();
    await this.checkUndoneSchedules();
  }

  ngOnDestroy(): void {
    this.preAndPostCheckService.resetSteps();
  }

  buildStepMap() {
    this.validSteps = new Map<number, boolean>();
    this.validSteps.set(1, false);
    this.validSteps.set(2, false);
    this.validSteps.set(3, true);
    this.validSteps.set(4, false);
    this.validSteps.set(5, false);
    this.validSteps.set(6, false);
    this.validSteps.set(7, false);
  }

  async checkUndoneSchedules() {
    let schedule: BillScheduled;
    await this.billScheduledService
      .getLastBillScheduled()
      .then((rs) => {
        schedule = rs;
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      });

    if (new Date() < new Date(schedule?.executionDate)) {
      this.hasFutureSchedule = true;
      this.message = 'There is a billing execution scheduled to run, delete it to restart a new config.';
    } else if (schedule?.startingDate && !schedule.finishDate) {
      this.hasFutureSchedule = true;
      this.message = 'There is a billing execution scheduled running, wait to finish current process.';
    }
  }

  buildOptions() {
    this.isEditor = this.webAdminService.isBillingEditorOrAdmin();
  }

  validStep(valid: boolean, step: number) {
    this.validSteps.set(step, valid);
  }

  refreshStep(stepNumber: number) {
    this.preAndPostCheckService.refreshPreStep(stepNumber);
  }

  changeCurrentStep(stepNumber: number) {
    this.preAndPostCheckService.changeCurrentPreStep(stepNumber);
  }

  bill() {
    const modalRef = this.modalService.open(ModalDeleteComponent, {
      backdrop: 'static',
      container: 'nb-layout',
    });

    modalRef.componentInstance.message =
      'Are you sure that you want to set start and end dates and one day to bill automatically?';
    modalRef.componentInstance.title = 'Bill Automatically';
    modalRef.componentInstance.buttonOkTitle = 'Yes';

    const self = this;

    modalRef.result.then(async (res) => {
      // on close
      if (res) {
        const modalRef2 = this.modalService.open(BillingProcessPreBillModalComponent, {
          backdrop: 'static',
          container: 'nb-layout',
        });

        modalRef2.componentInstance.header = 'Set automatic billing dates';

        modalRef2.result.then(async (res) => {
          // on close
          if (res) {
            self.stepper.next();
          }
        });
      }
    });
  }

  finishStepper() {
    this.stepper.reset();
    this.navigateToScheduled();
  }

  navigateToScheduled() {
    this.router.navigate(['../scheduled'], { relativeTo: this.route });
  }
}
