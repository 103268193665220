import { Injectable } from '@angular/core';
import * as fileStack from 'filestack-js';
import { ProfileCreateOrUpdateInput } from '../interfaces/_index-network.interfaces';
import { ToastsService } from '@tgx/shared/toasts';
import { BehaviorSubject } from 'rxjs';
import { AssetType } from '@tgx/shared/enums';

@Injectable({
  providedIn: 'root',
})
export class FilestackService {
  newFileUpload = new BehaviorSubject<{ url: string }>(null);
  updatedLogo = new BehaviorSubject<boolean>(false);
  formatData = new BehaviorSubject<ProfileCreateOrUpdateInput>({});

  private fileStackClient: any;

  constructor(private toastsService: ToastsService) {
    this.fileStackClient = fileStack.init('ASyLaaaYgS5iWKOwRjLsmz');
  }

  uploadLogo(): void {
    const pickOptions = {
      accept: ['image/jpeg', '.jpg', '.jpeg', '.png', '.svg', '.gif'],
      maxFiles: 1,
      imageMax: [1280, 720],
      fromSources: ['local_file_system'],
      onUploadDone: (res) => {
        try {
          // check for uploaded files
          if (res.filesUploaded.length > 0) {
            // check  file type
            const uploadedFile = res.filesUploaded[0];
            const fileType = uploadedFile.mimetype;

            if (fileType && this.isValidImageType(fileType)) {
              this.formatData.next(this.setAssetData(res.filesUploaded));
            } else {
              this.toastsService.addToast('Error', 'bg-danger', 'Invalid file type uploaded', 3);
            }
          } else if (res.filesFailed.length > 0) {
            this.toastsService.addToast('Error', 'bg-danger', 'Image could not be processed', 3);
          }
        } catch (error) {
          console.error('Error uploading logo:', error);
          this.toastsService.addToast('Error', 'bg-danger', 'An error occurred while uploading the image', 3);
        }
      },
    };
    this.fileStackClient.picker(pickOptions).open();
  }

  isValidImageType(fileType: string): boolean {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml', 'image/gif'];
    return validTypes.includes(fileType);
  }

  private setAssetData(filesUploaded: any): ProfileCreateOrUpdateInput {
    // In case two logos are uploaded at the same time, delete the first one.

    if (this.formatData.value.Asset) {
      this.formatData.value.Asset = {};
    }

    if (filesUploaded[filesUploaded.length - 1].url && filesUploaded[filesUploaded.length - 1].handle) {
      this.formatData.value.Asset = {
        create: [
          {
            ...this.formatData?.value.Asset?.create,
            name: filesUploaded[filesUploaded.length - 1].filename,
            handle: filesUploaded[filesUploaded.length - 1].handle,
            mimeType: filesUploaded[filesUploaded.length - 1].mimetype,
            size: filesUploaded[filesUploaded.length - 1].size,
            type: AssetType.LOGO,
          },
        ],
      };
    }
    this.updatedLogo.next(true);
    // To see it in the modal before upload it.
    this.newFileUpload.next({ url: filesUploaded[filesUploaded.length - 1].url });
    return this.formatData.value;
  }
}
