import { SharedModule } from '../../../../@core/shared/shared.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { WebBannerRoutingModule } from './web-banner.routing-module';
import { NgModule } from '@angular/core';
import { WebBannerComponent } from './components/web-banner.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { NbActionsModule, NbCardModule, NbCheckboxModule, NbIconModule, NbToggleModule } from '@nebular/theme';
import { SharedUiStorybookModule } from '@tgx/shared/ui/storybook';

@NgModule({
  imports: [
    SharedUiStorybookModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NbCardModule,
    NbCheckboxModule,
    NbActionsModule,
    NbIconModule,
    AutoCompleteModule,
    MultiSelectModule,
    CommonModule,
    DropdownModule,
    NgbModule,
    NbToggleModule,
    WebBannerRoutingModule,
  ],
  declarations: [WebBannerComponent],
  providers: [NgbTooltipConfig],
})
export class WebBannerModule {}
