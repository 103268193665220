import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UnmapModalComponent } from '../unmap-modal/unmap-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  HotelList,
  MasterList,
  MasterSons,
  GenericMappingResponse,
  Master,
  Hotel,
} from '../../interfaces/models/mapping.interfaces';
import { hsCountryListIsoCodes } from '@tgx/shared/utils';
import { MappingService } from '../../../../services/mapping.service';
import { ValidateModalComponent } from '../validate-modal/validate-modal.component';
import { deepEqual } from '../utils/checks-equal-objects';
import { Subscription } from 'rxjs';
import { MasterType } from '../../interfaces/models/enums.interfaces';
import { lastValueFrom } from 'rxjs';
@Component({
  selector: 'tgx-non-validate-table',
  templateUrl: './non-validate-table.component.html',
  styleUrls: ['./non-validate-table.component.scss'],
})
export class NonValidateTableComponent {
  @Input() formParams: { countryMapping: string; hotelMapping: string };
  @Output() loadDataEvent = new EventEmitter<{ options: string }>();

  nonValidateHotelsCP(): HotelList[] {
    const initialPosition = this.pageSizeHotels * this.pageHotels - this.pageSizeHotels;
    const finalPosition = this.pageSizeHotels * this.pageHotels;
    return this.nonValidateHotels.slice(initialPosition, finalPosition);
  }

  nonValidateCount: number;
  validateCount: number;
  unmappedCount: number;

  nonValidateMasters: MasterList[] = [];
  validateMasters: MasterList[] = [];
  unmappedList: HotelList[] = [];

  errorNonValidate: string;
  errorValidate: string;
  errorUnmapped: string;

  // Hotels
  nonValidateHotels: HotelList[] = [];
  selectedHotels: Set<MasterSons> = new Set<MasterSons>();
  idMasterSelected: string;

  page = 1;
  pageSize = 10;
  pageHotels = 1;
  pageSizeHotels = 10;

  tHead = [
    { label: 'Hotel master', field: 'name' },
    { label: 'Id', field: 'id' },
    { label: 'Geocode', field: 'location' },
    { label: 'Country', field: 'country' },
    { label: 'Address', field: 'address' },
  ];
  isLoading: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private modalService: NgbModal,
    private mappingService: MappingService,
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.mappingService.nonValidateMasters.subscribe(async (nonValidateMasters) => {
        this.nonValidateMasters = nonValidateMasters;
      }),
    );
    this.subscriptions.push(
      this.mappingService.nonValidateCount.subscribe(async (nonValidateCount) => {
        this.nonValidateCount = nonValidateCount;
      }),
    );
    this.subscriptions.push(
      this.mappingService.validateMasters.subscribe(async (validateMasters) => {
        this.validateMasters = validateMasters;
      }),
    );
    this.subscriptions.push(
      this.mappingService.validateCount.subscribe(async (validateCount) => {
        this.validateCount = validateCount;
      }),
    );
    this.subscriptions.push(
      this.mappingService.unmappedList.subscribe(async (unmappedList) => {
        this.unmappedList = unmappedList;
      }),
    );
    this.subscriptions.push(
      this.mappingService.unmappedCount.subscribe(async (unmappedCount) => {
        this.unmappedCount = unmappedCount;
      }),
    );
    this.subscriptions.push(
      this.mappingService.errorNonValidate.subscribe(async (errorNonValidate) => {
        this.errorNonValidate = errorNonValidate;
      }),
    );
    this.subscriptions.push(
      this.mappingService.errorValidate.subscribe(async (errorValidate) => {
        this.errorValidate = errorValidate;
      }),
    );
    this.subscriptions.push(
      this.mappingService.errorUnmapped.subscribe(async (errorUnmapped) => {
        this.errorUnmapped = errorUnmapped;
      }),
    );
    this.subscriptions.push(
      this.mappingService.nonValidateHotels.subscribe(async (hotels) => {
        this.nonValidateHotels = hotels;
      }),
    );
  }

  reLoadData(options?: string) {
    this.loadDataEvent.emit({
      options: options,
    });
  }

  findNameCountry(isoCode2: string) {
    const country = hsCountryListIsoCodes.find((x) => x.isoCode2 === isoCode2);
    if (country) {
      return country.name;
    }
    return '';
  }

  async retrieveMasterSons(nonValidate: MasterList) {
    this.selectedHotels = new Set();
    this.pageHotels = 1;
    const res = await this.mappingService.getNonValidatedMasterSons(nonValidate.id);
    this.idMasterSelected = res.id;
    if (this.idMasterSelected === nonValidate.id) {
      this.mappingService.updateHotels(res.hotels, MasterType.NON_VALIDATE);
      nonValidate.show_child = !nonValidate.show_child;
    }
  }

  async onPageChange(page: any) {
    let nonValidate: Master;
    let results: PromiseSettledResult<any>[];
    this.page = page;
    let offset = (page - 1) * 10;
    this.isLoading = true;
    nonValidate = (await lastValueFrom(
      this.mappingService.buildAndLaunchRequestWithParams(
        this.mappingService.getNonValidatedMasterEndPoint(),
        this.formParams.countryMapping,
        offset,
        10,
        this.formParams.hotelMapping,
      ),
    )) as Master;
    results = await Promise.allSettled([nonValidate]);

    if (results[0].status === 'fulfilled') {
      if (results[0].value.master_list.length === 0) {
        this.mappingService.errorNonValidate.next('No Non-Validated masters found');
      } else {
        this.mappingService.nonValidateMasters.next(results[0].value.master_list);
      }
    }
    this.isLoading = false;
  }

  onPageChangeHotels(pageHotels: any) {
    this.pageHotels = pageHotels;
  }

  openModalUnmap(nonValidate: MasterList) {
    const selectedHotelsArray = Array.from(this.selectedHotels);
    const hotelsToRemoveIndexes = this.findMatchingObjectIndexes(this.nonValidateHotels, selectedHotelsArray);
    const modalRef = this.modalService.open(UnmapModalComponent, { size: 'xl', windowClass: 'xen-modal' });
    modalRef.componentInstance.selectedHotels = selectedHotelsArray;
    modalRef.componentInstance.nonValidate = nonValidate;
    modalRef.componentInstance.hotelsToRemoveIndexes = hotelsToRemoveIndexes;
    modalRef.result
      .then(async (result: GenericMappingResponse) => {
        if (result) {
          let auxHotels = [];
          this.nonValidateHotels.forEach((hotel, index) => {
            if (!hotelsToRemoveIndexes.includes(index)) {
              auxHotels.push(hotel);
            }
          });
          this.nonValidateHotels = auxHotels;
          this.mappingService.updateHotels(this.nonValidateHotels, MasterType.NON_VALIDATE);
          this.reLoadData('unmap');
          this.selectedHotels = new Set();
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return modalRef;
  }

  onCheckboxChange(hotelSelected: MasterSons, event: any) {
    if (event.target.checked) {
      this.selectedHotels.add(hotelSelected);
    } else {
      this.selectedHotels.delete(hotelSelected);
    }
  }

  private findMatchingObjectIndexes(masterArray: any[], searchArray: any[]): number[] {
    const hotelsToRemoveIndexes: number[] = [];

    masterArray.forEach((obj1, index1) => {
      const match = searchArray.find((obj2) => deepEqual(obj1, obj2));
      if (match) {
        hotelsToRemoveIndexes.push(index1);
      }
    });

    return hotelsToRemoveIndexes;
  }

  openModalValidate(nonValidate: MasterList) {
    const modalRef = this.modalService.open(ValidateModalComponent, { size: 'xl', windowClass: 'xen-modal' });
    modalRef.componentInstance.nonValidate = nonValidate;
    modalRef.result
      .then((result: GenericMappingResponse) => {
        if (result) {
          this.reLoadData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
