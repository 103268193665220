import { Injectable } from '@angular/core';
import { GraphqlApiBillingQueries } from './queries/_index-billing-queries';
import { Apollo } from 'apollo-angular';
import { Connection, ConnectionCreateInput, ConnectionFullInfo } from './../interfaces/_index.billing.interfaces';
import { GraphqlApiBillingMutations } from './mutations/_index-billing-mutations';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  constructor(private apollo: Apollo) {}

  async getConnectionsFullInfo(): Promise<ConnectionFullInfo[]> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .watchQuery<any>({
          query: GraphqlApiBillingQueries.getConnectionsFullInfo,
          fetchPolicy: 'network-only',
        })
        .valueChanges.subscribe((res: any) => {
          if (res && res.data) {
            resolve(((res || {}).data || {}).connectionsFullInfo);
          } else {
            reject(res);
          }
        });
    });
  }

  async getConnectionsFullInfoById(ids: number[]): Promise<ConnectionFullInfo[]> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .watchQuery<any>({
          query: GraphqlApiBillingQueries.getConnectionsFullInfoById,
          fetchPolicy: 'network-only',
          variables: { id: { in: ids } },
        })
        .valueChanges.subscribe((res: any) => {
          if (res && res.data) {
            resolve(((res || {}).data || {}).connectionsFullInfo);
          } else {
            reject(res);
          }
        });
    });
  }

  async createConnection(create: ConnectionCreateInput): Promise<Connection> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.createConnection,
          variables: { data: create },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).createConnection);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }
}
