import { Options } from './../../../../../../../@core/interfaces/_index.interfaces';
import { NotificationService } from './../../../../../../../@core/services/notification.service';
import {
  ConnectionService,
  ContractConnectionDiscountService,
  ContractConnectionService,
} from './../../../../../services/_index-billing.services';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { clone } from '../../../../../../../@core/utils/utils';
import {
  ObjectWhereUniqueInputById,
  ConnectionFullInfo,
  ContractConnection,
  ContractConnectionDiscountCreateInput,
  ContractConnectionDiscountUpdateInput,
  ContractConnectionDiscount,
  PaymentType,
} from '../../../../../interfaces/_index.billing.interfaces';

@Component({
  selector: 'tgx-admin-billing-connection-discount-modal',
  styleUrls: ['./connection-discount-modal.component.scss'],
  templateUrl: 'connection-discount-modal.component.html',
})
export class BillingConnectionDiscountModalComponent implements OnInit {
  @Input() discount: any = null;
  @Input() header: string;
  @Input() contractId: number;
  @Input() isEditor: boolean;

  isCreating: boolean;
  discountAux: ContractConnectionDiscount;
  ended = false;

  connectionSuggestion: any[] | undefined;

  public discountForm: UntypedFormGroup;
  paymentTypes: Options[] = [{ code: null, label: 'Select One' }];

  constructor(
    private notificationService: NotificationService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private contractConnectionDiscountService: ContractConnectionDiscountService,
    private contractConnectionService: ContractConnectionService,
    private connectionService: ConnectionService,
  ) {}

  async ngOnInit() {
    this.isCreating = this.discount ? false : true;
    this.buildForm();

    await this.getConnections();

    if (!this.isCreating) {
      this.discountForm.get('startDate').setValue(new Date(this.discount.startDate));
      this.discountForm.get('endDate').setValue(new Date(this.discount.endDate));
      this.discountForm.get('startDate').disable();
      this.discountForm.get('endDate').disable();

      this.discountForm.get('enabled').setValue(this.discount.enabled);
      this.discountForm.get('amount').setValue(this.discount.amount);

      this.discountForm
        .get('paymentType')
        .setValue(this.paymentTypes[this.paymentTypes.findIndex((x) => x.code === this.discount.paymentType)]);

      const index = this.connectionSuggestion.findIndex((x) => x.value === this.discount.contractConnection.toString());

      this.discountForm.get('ccId').setValue(this.connectionSuggestion[index]);
      this.discountForm.get('ccId').disable();

      if (!this.isEditor) {
        this.discountForm.get('enabled').disable();
        this.discountForm.get('amount').disable();
        this.discountForm.get('ccId').disable();
      }
    } else {
      //Aqui agafar es primer payment només
      this.discountForm.get('paymentType').setValue(this.paymentTypes[0]);
    }
  }

  async getConnections() {
    if (this.contractId) {
      const contractConnections = await this.contractConnectionService.getContractConnections(this.contractId);

      const connectionCodes: number[] = [];

      if (contractConnections?.length > 0) {
        contractConnections.forEach((con) => {
          if (!con.endDate) {
            const index = connectionCodes.findIndex((x) => x === con.connection);

            if (index === -1) {
              connectionCodes.push(con.connection);
            }
          }
        });
      }

      let connections: ConnectionFullInfo[] = [];

      if (connectionCodes.length > 0) {
        await this.connectionService
          .getConnectionsFullInfoById(connectionCodes)
          .then((rs) => {
            connections = rs;
          })
          .catch((err) => {
            this.notificationService.handleGatewayAndGraphqlErrors(err);
          });
      }

      if (contractConnections?.length > 0) {
        this.buildConnectionsSelector(contractConnections, connections);
      } else {
        this.withoutConnections();
      }
    } else {
      this.withoutConnections();
    }
  }

  withoutConnections() {
    this.connectionSuggestion = [];
  }

  async buildConnectionsSelector(contractConnections: ContractConnection[], connections: ConnectionFullInfo[]) {
    const conIds: string[] = [];

    this.connectionSuggestion = [];

    contractConnections.forEach((con) => {
      const index = conIds.findIndex((x) => x === con.id.toString());

      const indexSup = connections.findIndex((x) => x.id === con.connection);

      if (index === -1 && indexSup > -1 && !con.endDate) {
        this.connectionSuggestion.push({
          value: con.id.toString(),
          buyer: connections[indexSup].buyerCode.toLowerCase(),
          seller: connections[indexSup].sellerCode.toLowerCase(),
          title: `ContractConnectionId: ${con.id} Buyer: ${connections[indexSup].buyerName} (${connections[indexSup].buyerCode}) Seller: ${connections[indexSup].sellerName} (${connections[indexSup].sellerCode})`,
        });
        conIds.push(con.id.toString());
      }
    });
  }

  buildForm() {
    Object.keys(PaymentType).forEach((value) => {
      if (value === PaymentType.STANDARD || value === PaymentType.SUPPLEMENT) {
        this.paymentTypes.push({ code: value, label: value });
      }
    });

    this.discountForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      enabled: [true, Validators.required],
      amount: ['', Validators.required],
      paymentType: ['', Validators.required],
      ccId: ['', Validators.required],
    });
  }

  closeModal() {
    this.discount = clone(this.discountAux);
    this.activeModal.close(true);
  }

  onSave() {
    if (this.isCreating) {
      //Mutation create
      let date = new Date(this.discountForm.get('startDate').value);
      let dateStr = date.getFullYear().toString();
      let month = date.getMonth() + 1;
      if (month < 10) {
        dateStr += '-0' + month.toString();
      } else {
        dateStr += '-' + month.toString();
      }
      let day = date.getDate();
      if (day < 10) {
        dateStr += '-0' + day.toString();
      } else {
        dateStr += '-' + day.toString();
      }
      dateStr += 'T00:00:00.000Z';

      const startDate: any = dateStr;

      date = new Date(this.discountForm.get('endDate').value);
      dateStr = date.getFullYear().toString();
      month = date.getMonth() + 1;
      if (month < 10) {
        dateStr += '-0' + month.toString();
      } else {
        dateStr += '-' + month.toString();
      }
      day = date.getDate();
      if (day < 10) {
        dateStr += '-0' + day.toString();
      } else {
        dateStr += '-' + day.toString();
      }
      dateStr += 'T23:59:59.000Z';

      const endDate: any = dateStr;

      const createData: ContractConnectionDiscountCreateInput = {
        startDate: startDate,
        endDate: endDate,
        enabled: this.discountForm.get('enabled').value,
        paymentType: this.discountForm.get('paymentType').value?.code,
        ContractConnection: {
          connect: {
            id: Number(this.discountForm.get('ccId').value?.value),
          },
        },
        amount: this.discountForm.get('amount').value.toString(),
      };

      this.contractConnectionDiscountService
        .createContractConnectionDiscount(createData)
        .then((rs) => {
          this.notificationService.success('Contract connection discount succesfully created', 'Success');
          this.activeModal.close(rs);
        })
        .catch((err) => {
          this.activeModal.close(false);
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    } else {
      ///Mutation update

      const updateData: ContractConnectionDiscountUpdateInput = {
        enabled: { set: this.discountForm.get('enabled').value },
        paymentType: { set: this.discountForm.get('paymentType').value.code },
        amount: { set: this.discountForm.get('amount').value.toString() },
      };

      const where: ObjectWhereUniqueInputById = { id: this.discount.id };

      this.contractConnectionDiscountService
        .updateContractConnectionDiscount(updateData, where)
        .then((rs) => {
          this.notificationService.success('Contract connection discount succesfully updated', 'Success');
          this.activeModal.close(rs);
        })
        .catch((err) => {
          this.activeModal.close(false);
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    }
  }

  validForm(): boolean {
    return this.discountForm.get('paymentType').value?.code && this.discountForm.valid;
  }
}
