import { SharedModule } from './../../../../@core/shared/shared.module';
import { NgModule } from '@angular/core';
import { NbCardModule, NbCheckboxModule, NbTabsetModule } from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BillingAgreementsComponent } from './agreements.component';
import { AgreementsRoutingModule } from './agreements-routing.module';
import { BillingAgreementCommercialTemplateModalComponent } from './commercial-templates/commercial-templates-modal/commercial-templates-modal.component';
import { BillingAgreementsCommercialTemplateTableComponent } from './commercial-templates/commercial-templates-table/commercial-templates-table.component';
import { BillingAgreementsCommercialByPartnerTableComponent } from './commercial-bypartner/commercial-bypartner-table/commercial-bypartner-table.component';
import { BillingAgreementsCommercialPartnerModalComponent } from './commercial-bypartner/commercial-bypartner-modal/commercial-bypartner-modal.component';
import { DropdownModule } from 'primeng/dropdown';
import { NgbModule, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';

@NgModule({
  imports: [
    TableModule,
    CheckboxModule,
    ButtonModule,
    SharedModule,
    AgreementsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NbCardModule,
    NbCheckboxModule,
    DropdownModule,
    NbTabsetModule,
    CommonModule,
    NgbModule,
  ],
  declarations: [
    BillingAgreementsComponent,
    BillingAgreementsCommercialPartnerModalComponent,
    BillingAgreementsCommercialByPartnerTableComponent,
    BillingAgreementCommercialTemplateModalComponent,
    BillingAgreementsCommercialTemplateTableComponent,
  ],
  providers: [NgbTooltipConfig],
})
export class AgreementsModule {}
