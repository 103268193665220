import { gql } from 'apollo-angular';

export const getGroupsBySupplierCode = gql`
  query ($where: SupplierWhereInput) {
    admin {
      allSuppliers(where: $where) {
        totalCount
        edges {
          node {
            supplierData {
              groups {
                edges {
                  node {
                    code
                  }
                }
              }
            }
          }
        }
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
