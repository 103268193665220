export const getBestFiveConnections = `
{
  bestFiveConnections{
    partnerName
    client
    supplier
    amount
  }
}
`;
