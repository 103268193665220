import { DropdownModule } from 'primeng/dropdown';
import { ContractsRoutingModule } from './contracts-routing.module';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../../../../@core/shared/shared.module';
import { NgModule } from '@angular/core';
import { NbCardModule, NbCheckboxModule } from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BillingContractsTableComponent } from './contracts/components/contracts-table/contracts-table.component';
import { BillingContractModalComponent } from './contracts/components/contract-modal/contract-modal.component';
import { BillingContractsComponent } from './contracts.component';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { BillingContractsSubtypeTableComponent } from './subTypes/components/subtypes-table/subtypes-table.component';
import { BillingContractSubTypeModalComponent } from './subTypes/components/subtype-modal/subtype-modal.component';
import { BillingPluginsTableComponent } from './plugins/components/plugins-table/plugins-table.component';
import { BillingPluginsModalComponent } from './plugins/components/plugins-modal/plugins-modal.component';
import { BillingDiscountsModalComponent } from './discounts/components/discounts-modal/discounts-modal.component';
import { BillingDiscountsTableComponent } from './discounts/components/discounts-table/discounts-table.component';

@NgModule({
  imports: [
    TableModule,
    CheckboxModule,
    SharedModule,
    ContractsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NbCardModule,
    NbCheckboxModule,
    DropdownModule,
    CalendarModule,
    CommonModule,
  ],
  declarations: [
    BillingContractsComponent,
    BillingContractsTableComponent,
    BillingContractModalComponent,
    BillingContractsSubtypeTableComponent,
    BillingContractSubTypeModalComponent,
    BillingPluginsTableComponent,
    BillingPluginsModalComponent,
    BillingDiscountsTableComponent,
    BillingDiscountsModalComponent,
  ],
})
export class ContractsModule {}
