import { NotificationService } from './../../../../../../@core/services/_index-core.services';
import { PartnerService } from './../../../../services/_index-billing.services';
import {
  Partner,
  PartnerCreateInput,
  PartnerWhereUniqueInput,
  PartnerUpdateInput,
} from './../../../../interfaces/_index.billing.interfaces';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { clone } from '../../../../../../@core/utils/utils';

@Component({
  selector: 'tgx-partners-modal',
  styleUrls: ['./partners-modal.component.scss'],
  templateUrl: 'partners-modal.component.html',
})
export class PartnersModalComponent implements OnInit {
  @Input() partner: Partner = null;
  @Input() header: string;
  @Input() isEditor: boolean;

  isLoading: boolean;
  isCreating: boolean;
  partnerAux: Partner;

  odooCodeEditable = false;

  public partnerForm: UntypedFormGroup;

  constructor(
    private notificationService: NotificationService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,

    private partnerService: PartnerService,
  ) {}

  ngOnInit() {
    this.isCreating = true;

    if (this.partner) {
      this.isCreating = false;
      this.odooCodeEditable = this.partner?.odooCode > 32559;
    }

    this.partnerForm = this.fb.group({
      name: [this.partner ? this.partner.name : '', Validators.required],
      orgCode: [this.partner ? this.partner.orgCode : '', Validators.required],
      odooCode: [this.partner ? this.partner.odooCode : 0],
    });

    this.partnerAux = clone(this.partner);

    if (!this.isCreating) {
      this.partnerForm.get('orgCode').disable();
    }

    if (!this.isEditor) {
      this.partnerForm.get('name').disable();
    }
  }

  closeModal() {
    this.partner = clone(this.partnerAux);
    this.activeModal.close(true);
  }

  onSave() {
    if (this.isCreating) {
      //Mutation create
      const createData: PartnerCreateInput = {
        name: this.partnerForm.get('name').value,
        orgCode: this.partnerForm.get('orgCode').value.toUpperCase(),
      };

      this.partnerService
        .createPartner(createData)
        .then((rs) => {
          this.notificationService.success('Partner succesfully created', 'Success');
          this.activeModal.close(rs);
        })
        .catch((err) => {
          this.activeModal.close(false);
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    } else {
      //Mutation update
      const updateData: PartnerUpdateInput = {
        name: { set: this.partnerForm.get('name').value },
      };

      if (this.odooCodeEditable) {
        updateData.odooCode = {
          set: this.partnerForm.get('odooCode').value,
        };
      }

      const where: PartnerWhereUniqueInput = { id: this.partner.id };

      this.partnerService
        .updatePartner(updateData, where)
        .then((rs) => {
          this.notificationService.success('Partner succesfully updated', 'Success');
          this.activeModal.close(rs);
        })
        .catch((err) => {
          this.activeModal.close(false);
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    }
  }
}
