<nav class="xen-tabs" [ngClass]="classes">
  <div class="nav nav-tabs xen-tabs__list" [ngClass]="navClasses" id="nav-tab" role="tablist">
    <button
      (click)="tabChange($event)"
      [disabled]="item.disabled"
      *ngFor="let item of items"
      [attr.data-bs-target]="'#' + item.target"
      class="{{ item.class }} nav-link"
      data-bs-toggle="tab"
      role="tab"
      id="{{ item.id }}"
    >
      {{ item.label }}
    </button>
  </div>
</nav>
