import { Table } from 'primeng/table';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActiveConnection, ActiveConnectionData } from '../../../../interfaces/_index.billing.interfaces';
import { ReportService } from '../../../../services/_index-billing.services';
import { NotificationService, WebAdminService } from './../../../../../../@core/services/_index-core.services';

@Component({
  selector: 'tgx-admin-billing-invoices-table',
  templateUrl: './connections-inconclusive-table.component.html',
  styleUrls: ['./connections-inconclusive-table.component.scss'],
})
export class BillingInconclusiveTableComponent implements OnInit {
  @ViewChild('dt', { static: false }) dataTable!: Table;

  header = [
    { label: 'Buyer', field: 'buyer' },
    { label: 'BuyerType', field: 'buyerContractType' },
    { label: 'BuyerSubType', field: 'buyerContractSubType' },
    { label: 'BuyerRate', field: 'buyerConnectionType' },
    { label: 'SellerRate', field: 'sellerConnectionType' },
    { label: 'SellerSubType', field: 'sellerContractSubType' },
    { label: 'SellerType', field: 'sellerContractType' },
    { label: 'Seller', field: 'seller' },
  ];

  inconclusiveSource: any[];
  activeConnections: ActiveConnection[];

  isLoading: boolean;

  isEditor = false;

  selectedPair: ActiveConnection;

  buyerTypes = [{ value: '', label: '' }];
  buyerSubTypes = [{ value: '', label: '' }];
  buyerRates = [{ value: '', label: '' }];
  sellerTypes = [{ value: '', label: '' }];
  sellerSubTypes = [{ value: '', label: '' }];
  sellerRates = [{ value: '', label: '' }];

  constructor(
    private notificationService: NotificationService,
    private webAdminService: WebAdminService,
    private reportService: ReportService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.selectedPair = null;

    this.buyerTypes = [{ value: '-', label: 'Empty' }];
    this.buyerSubTypes = [{ value: '-', label: 'Empty' }];
    this.buyerRates = [{ value: '-', label: 'Empty' }];
    this.sellerTypes = [{ value: '-', label: 'Empty' }];
    this.sellerSubTypes = [{ value: '-', label: 'Empty' }];
    this.sellerRates = [{ value: '-', label: 'Empty' }];

    this.buildOptions();
    await this.reportService
      .getInconclusiveConnections()
      .then((cns) => {
        this.activeConnections = cns;
        this.getConnections();
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  buildOptions() {
    this.isEditor = this.webAdminService.isBillingEditorOrAdmin();
  }

  async getConnections() {
    this.isLoading = true;
    this.inconclusiveSource = [];
    this.selectedPair = null;

    if (this.dataTable) {
      this.dataTable.clearFilterValues();
    }

    if (this.activeConnections?.length > 0) {
      this.activeConnections.forEach((cn) => {
        let buyer: ActiveConnectionData;
        let seller: ActiveConnectionData;

        let index = cn.connections.findIndex((x) => x.partnerOrgCode === cn.buyerCode);

        if (index > -1) {
          buyer = cn.connections[index];
        }

        if (!buyer) {
          index = cn.connections.findIndex((x) => x.partnerType === 'BUYER');
          if (index > -1) {
            buyer = cn.connections[index];
          }
        }

        index = cn.connections.findIndex((x) => x.partnerOrgCode === cn.sellerCode);
        if (index > -1) {
          seller = cn.connections[index];
        }

        if (!seller) {
          index = cn.connections.findIndex((x) => x.partnerType === 'SELLER');
          if (index > -1) {
            seller = cn.connections[index];
          }
        }

        if (buyer) {
          if (buyer.modelType) {
            const index = this.buyerTypes.findIndex((x) => x.value === buyer.modelType);
            if (index === -1) {
              this.buyerTypes.push({ value: buyer.modelType, label: buyer.modelType });
            }
          }

          if (buyer.partnerSubType) {
            const index = this.buyerSubTypes.findIndex((x) => x.value === buyer.partnerSubType);
            if (index === -1) {
              this.buyerSubTypes.push({ value: buyer.partnerSubType, label: buyer.partnerSubType });
            }
          }

          const rate = this.getRateType(buyer.connectionType);
          const index = this.buyerRates.findIndex((x) => x.value === rate);
          if (index === -1) {
            this.buyerRates.push({ value: rate, label: rate });
          }
        }

        if (seller) {
          if (seller.modelType) {
            const index = this.sellerTypes.findIndex((x) => x.value === seller.modelType);
            if (index === -1) {
              this.sellerTypes.push({ value: seller.modelType, label: seller.modelType });
            }
          }

          if (seller.partnerSubType) {
            const index = this.sellerSubTypes.findIndex((x) => x.value === seller.partnerSubType);
            if (index === -1) {
              this.sellerSubTypes.push({ value: seller.partnerSubType, label: seller.partnerSubType });
            }
          }

          const rate = this.getRateType(seller.connectionType);
          const index = this.sellerRates.findIndex((x) => x.value === rate);
          if (index === -1) {
            this.sellerRates.push({ value: rate, label: rate });
          }
        }

        const incCon = {
          id: cn.buyerCode + '|' + cn.sellerCode,
          buyer: `${cn.buyerName} (${cn.buyerCode})`,
          buyerContractType: buyer?.modelType ? buyer.modelType : '-',
          buyerContractSubType: buyer?.partnerSubType ? buyer.partnerSubType : '-',
          buyerConnectionType: buyer ? this.getRateType(buyer.connectionType) : '-',
          seller: `${cn.sellerName} (${cn.sellerCode})`,
          sellerContractType: seller ? seller.modelType : '-',
          sellerContractSubType: seller?.partnerSubType ? seller.partnerSubType : '-',
          sellerConnectionType: seller ? this.getRateType(seller.connectionType) : '-',
        };

        this.inconclusiveSource.push(incCon);
      });
      this.inconclusiveSource = [...this.inconclusiveSource];
      this.inconclusiveSource.sort((a, b) => (a.contracttype2 < b.contracttype2 ? 1 : -1));
    }
  }

  filter(value: any, field: string) {
    if (value) {
      this.dataTable.filter(value, field, 'contains');
    }
  }

  getRateType(rateType: string): string {
    switch (rateType) {
      case 'STANDARD+SUPPLEMENT':
        return 'SUP';
      case 'STANDARD':
        return 'STD';
      default:
        return rateType;
    }
  }

  isTextFilter(head: any) {
    return head.field === 'buyer' || head.field === 'seller';
  }

  filterDropdown(value: any, head: any) {
    this.dataTable.filter(value, head.field, 'equals');
  }
}
