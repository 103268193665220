import { Injectable } from '@angular/core';
import {
  ObjectWhereUniqueInputById,
  ContractDiscount,
  ContractDiscountCreateInput,
  ContractDiscountUpdateInput,
} from './../interfaces/_index.billing.interfaces';
import { GraphqlApiBillingMutations } from './mutations/_index-billing-mutations';
import { GraphqlApiBillingQueries } from './queries/_index-billing-queries';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class ContractDiscountService {
  constructor(private apollo: Apollo) {}

  async getContractDiscounts(contractId: number): Promise<ContractDiscount[]> {
    const where = { contract: { equals: contractId } };

    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .watchQuery<any>({
          query: GraphqlApiBillingQueries.getContractDiscounts,
          variables: { where: where },
          fetchPolicy: 'network-only',
        })
        .valueChanges.subscribe((res: any) => {
          if (res?.data) {
            resolve(((res || {}).data || {}).contractDiscounts);
          } else {
            reject(res);
          }
        });
    });
  }

  async createContractDiscount(create: ContractDiscountCreateInput): Promise<ContractDiscount> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.createContractDiscount,
          variables: { data: create },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).createContractDiscount);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }

  async updateContractDiscount(
    update: ContractDiscountUpdateInput,
    where: ObjectWhereUniqueInputById,
  ): Promise<ContractDiscount> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.updateContractDiscount,
          variables: { data: update, where: where },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).updateContractDiscount);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }

  async deleteContractDiscount(where: ObjectWhereUniqueInputById): Promise<ContractDiscount> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.deleteContractDiscount,
          variables: { where: where },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).deleteContractDiscount);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }
}
