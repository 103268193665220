import { Component, Input } from '@angular/core';

@Component({
  selector: 'xen-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent {
  @Input() size: string;
  @Input() label: string;
  @Input() icon: boolean;
  @Input() icon_snippet: string;
  @Input() icon_placement: string;
  @Input() type: string;
  @Input() tooltip: boolean;
  @Input() tooltip_text: string;
}
