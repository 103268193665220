import { updateContract } from './udpateContract';
import { createContract } from './createContract';
import { createBillingAccount } from './createBillingAccount';
import { createPartner } from './createPartner';
import { updateBillingAccount } from './updateBillingAccount';
import { updatePartner } from './updatePartner';
import { createCost } from './createCost';
import { updateCost } from './updateCost';
import { deleteCost } from './deleteCost';
import { createL2BCost } from './createL2BCost';
import { updateL2BCost } from './updateL2BCost';
import { deleteL2BCost } from './deleteL2BCost';
import { deleteInvoice } from './deleteInvoice';
import { updateInvoice } from './updateInvoice';
import { updateInvoiceBreakdown } from './updateInvoiceBreakdown';
import { deleteInvoiceBreakdown } from './deleteInvoiceBreakdown';
import { createNewPricingCost } from './createNewPricingCost';
import { updateNewPricingCost } from './updateNewPricingCost';
import { deleteNewPricingCost } from './deleteNewPricingCost';
import { createContractConnection } from './createContractConnection';
import { updateContractConnection } from './updateContractConnection';
import { updateDefaultCost } from './updateDefaultCost';
import { cloneContractConnection } from './cloneContractConnection';
import { createContractType } from './createContractType';
import { updateContractType } from './updateContractType';
import { deleteContractConnection } from './deleteContractConnection';
import { createBillScheduled } from './createBillScheduled';
import { deleteBillScheduled } from './deleteBillScheduled';
import { createConnection } from './createConnection';
import { createContact } from './createBillingAccountContact';
import { updateContact } from './updateBillingAccountContact';
import { createContractPlugin } from './createContractPlugin';
import { updateContractPlugin } from './updateContractPlugin';
import { deleteContact } from './deleteBillingAccountContact';
import { updateInvoiceVisible } from './updateInvoiceVisible';
import { createContractDiscount } from './createContractDiscount';
import { updateContractDiscount } from './updateContractDiscount';
import { deleteContractDiscount } from './deleteContractDiscount';
import { createContractConnectionDiscount } from './createContractConnectionDiscount';
import { updateContractConnectionDiscount } from './updateContractConnectionDiscount';

export const GraphqlApiBillingMutations = {
  createBillingAccount: createBillingAccount,
  updateBillingAccount: updateBillingAccount,
  createPartner: createPartner,
  updatePartner: updatePartner,
  createContract: createContract,
  updateContract: updateContract,
  createCost: createCost,
  updateCost: updateCost,
  deleteCost: deleteCost,
  createL2BCost: createL2BCost,
  updateL2BCost: updateL2BCost,
  deleteL2BCost: deleteL2BCost,
  updateInvoice: updateInvoice,
  setInvoicesVisible: updateInvoiceVisible,
  deleteInvoice: deleteInvoice,
  updateInvoiceBreadown: updateInvoiceBreakdown,
  deleteInvoiceBreadown: deleteInvoiceBreakdown,
  createNewPricingCost: createNewPricingCost,
  updateNewPricingCost: updateNewPricingCost,
  deleteNewPricingCost: deleteNewPricingCost,
  createContractConnection: createContractConnection,
  updateContractConnection: updateContractConnection,
  deleteContractConnection: deleteContractConnection,
  cloneContractConnection: cloneContractConnection,
  updateDefaultCost: updateDefaultCost,
  createConnection: createConnection,
  createContractType: createContractType,
  updateContractType: updateContractType,
  createBillScheduled: createBillScheduled,
  deleteBillScheduled: deleteBillScheduled,
  createContact: createContact,
  updateContact: updateContact,
  deleteContact: deleteContact,
  createContractPlugin: createContractPlugin,
  updateContractPlugin: updateContractPlugin,
  createContractDiscount: createContractDiscount,
  updateContractDiscount: updateContractDiscount,
  deleteContractDiscount: deleteContractDiscount,
  createContractConnectionDiscount: createContractConnectionDiscount,
  updateContractConnectionDiscount: updateContractConnectionDiscount,
};
