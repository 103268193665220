import { Component, OnInit } from '@angular/core';
import { WebAdminService } from './../../../../../@core/services/_index-core.services';
@Component({
  selector: 'tgx-admin-billing-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  isEditor = false;

  constructor(private webAdminService: WebAdminService) {}

  ngOnInit(): void {
    this.isEditor = this.webAdminService.isBillingEditorOrAdmin();
  }
}
