import { gql } from 'apollo-angular';

export const getContractTypes = gql`
  {
    contractTypes {
      id
      name
      isCalculable
      odooDepartment
      createdAt
      updatedAt
    }
  }
`;
