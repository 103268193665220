import { gql } from 'apollo-angular';

export const removeOrganizationOwner = gql`
  mutation removeOrganizationOwner($where: OrganizationWhereUniqueInput!) {
    admin {
      removeOrganizationOwner(where: $where) {
        code
        createdAt
        updatedAt
        adviseMessage {
          code
          description
        }
      }
    }
  }
`;
