import { gql } from 'apollo-angular';

export const getMinInfoOrganizations = gql`
  query {
    admin {
      allOrganizations(where: { includeArchived: true }) {
        totalCount
        edges {
          node {
            adviseMessage {
              code
              description
              level
            }
            organizationsData {
              id
              code
              label
              owner {
                code
              }
            }
          }
        }
      }
    }
  }
`;
