import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { WebComponent, WebFeature } from '@tgx/shared/enums';
import { ManagePermissionsService } from '@tgx/shared/services';

@Injectable({
  providedIn: 'root',
})
export class PluginsGuard implements CanActivate {
  constructor(
    private router: Router,
    private managePermissionsService: ManagePermissionsService,
  ) {}

  canActivate(): boolean {
    let permissionsVisibility;
    permissionsVisibility = this.managePermissionsService.checkVisibility(
      WebComponent.MY_CONNECTIONS,
      WebFeature.PLUGINS,
    );

    if (!permissionsVisibility.isVisible) this.router.navigateByUrl('connections');

    return permissionsVisibility.isVisible;
  }
}
