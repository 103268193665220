import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModuleType } from '../../../../../../../../..//web-admin/src/app/@core/interfaces/user-permissions';
import { NotificationService } from '../../../../../../../../..//web-admin/src/app/@core/services/notification.service';
import { WebAdminService } from '../../../../../../../../..//web-admin/src/app/@core/services/web-admin.service';
import { Subscription } from 'rxjs';
import { OrganizationSelector } from '../../../../interfaces/_index-entities.interfaces';
import { LegacyConnectionEntitiesService } from '../../../../services/clients-legacy-connections-entities.service';
import { LegacyConnectionsModalComponent } from '../legacy-connections-modal/legacy-connections-modal.component';
import { ClientLegacyConnection } from '../../../../interfaces/models/client.legacy-connection.interface';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';

@Component({
  selector: 'tgx-admin-legacy-connections-table',
  templateUrl: './legacy-connections-table.component.html',
  styleUrls: ['./legacy-connections-table.component.scss'],
})
export class LegacyConnectionsTableComponent implements OnInit, OnDestroy {
  @ViewChild('dataTable') public dataTable: Table;
  selectedLegacyConnections: any[];
  selectedLegacyConnectionsAux: any[];
  clientName: string;
  providerCode: string;

  isLoading: boolean;

  legacyConnections: ClientLegacyConnection[];
  legacyConnectionsLoadSource: any[];

  $subs: Subscription[] = [];

  orgSelected: OrganizationSelector;

  constructor(
    private modalService: NgbModal,
    private webAdminService: WebAdminService,
    private notificationService: NotificationService,
    private legacyConnectionsEntititesService: LegacyConnectionEntitiesService,
    private router: Router,
  ) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as {
      clientName: string;
      providerCode: string;
    };

    this.clientName = state && state.clientName ? state.clientName : '';
    this.providerCode = state && state.providerCode ? state.providerCode : '';
  }

  ngOnInit(): void {
    setTimeout(() => {
      //set filter value of table
      if (this.dataTable !== undefined) {
        if (this.clientName) this.dataTable.filter(this.clientName, 'hubUser', 'contains');
        if (this.providerCode) this.dataTable.filter(this.providerCode, 'hubProvider', 'contains');
      }
    }, 2000);
    this.isLoading = true;
    this.buildOptions();
    this.retrieveClientLegacyConnections();

    //toggle activate/deactivate legacy connection.
    this.$subs.push(
      this.legacyConnectionsEntititesService.onToggleTable$.subscribe((legacyConnection) => {
        if (legacyConnection) {
          this.legacyConnectionsEntititesService
            .changeClientLegacyConnectionStatus(legacyConnection)
            .then(() => {
              this.notificationService.info('The legacy connection was sucessfully updated');
            })
            .catch((er) => {
              this.notificationService.error('There was a problem updating the legacy connection.');
            });
        }
      }),
    );
  }

  async retrieveClientLegacyConnections() {
    const clientLegacyConnections = await this.legacyConnectionsEntititesService.getLegacyConnectionByClient({});
    this.selectedLegacyConnections = [];
    this.selectedLegacyConnectionsAux = [];
    if (clientLegacyConnections?.length > 0) {
      this.legacyConnections = clientLegacyConnections;
      this.buildLegacyConnections();
    } else {
      this.isLoading = false;
    }
  }

  buildOptions() {
    this.webAdminService.emitUserTypevalue(ModuleType.ENTITIES);
  }

  buildLegacyConnections() {
    this.legacyConnectionsLoadSource = [];

    this.legacyConnections.forEach((mb) => {
      this.legacyConnectionsLoadSource.push({
        hubUser: mb.node.legacyAccessData.hubUser,
        isActive: mb.node.legacyAccessData.isActive,
        hubProvider: mb.node.legacyAccessData.hubProvider,
      });
      this.isLoading = false;
    });
    this.addFilterToTable();
  }

  addFilterToTable(): void {
    if ('undefined' !== this.clientName && this.clientName !== '') {
      //this.source.setFilter([{ field: 'hubUser', search: this.clientName }]);
    }
    if ('undefined' !== this.providerCode && this.providerCode !== '') {
      //this.source.setFilter([{ field: 'hubProvider', search: this.providerCode }]);
    }
  }

  ngOnDestroy() {
    this.legacyConnectionsEntititesService.onToggleTable$.next(null);
    this.$subs.forEach((s) => s.unsubscribe());
  }

  onCreate(): void {
    const activeModal = this.modalService.open(LegacyConnectionsModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });
    activeModal.componentInstance.header = 'Add new Legacy connection';
    activeModal.componentInstance.isCreating = true;

    const self = this;

    activeModal.result
      .then(async (res) => {
        // on close
        if (typeof res === 'boolean' && res) {
          //todo whatever
        } else if (typeof res === 'boolean' && !res) {
          //todo whatever
        }
        // OK
        else if (typeof res === 'object') {
          if (res.admin.createLegacyAccess.error && res.admin.createLegacyAccess.error.length > 0) {
            throw new Error(res.admin.createLegacyAccess.error[0].description);
          }

          this.notificationService.success('Legacy connection succesfully created.', 'Success');
          await self.retrieveClientLegacyConnections();
        }
      })
      .catch((error) => {
        this.notificationService.error(error);
      });
  }

  onToggleActivateDeactiveClient(legacyClients) {
    this.legacyConnectionsEntititesService.onToggleTable$.next(legacyClients);
  }
}
