import { gql } from 'apollo-angular';

export const getLabelForGroups = gql`
  query ($codes: [ID!]) {
    admin {
      allGroups(codes: $codes) {
        edges {
          node {
            groupData {
              code
              label
            }
          }
        }
      }
    }
  }
`;
