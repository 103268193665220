import { NotFoundComponent } from './../../../../@core/shared/not-found/not-found.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BillingContractsComponent } from './contracts.component';
import { BillingContractsTableComponent } from './contracts/components/contracts-table/contracts-table.component';
import { BillingContractsSubtypeTableComponent } from './subTypes/components/subtypes-table/subtypes-table.component';
import { BillingPluginsTableComponent } from './plugins/components/plugins-table/plugins-table.component';
import { BillingDiscountsTableComponent } from './discounts/components/discounts-table/discounts-table.component';

const routes: Routes = [
  {
    path: '',
    component: BillingContractsComponent,
    children: [
      {
        path: 'subtypes',
        component: BillingContractsSubtypeTableComponent,
      },
      {
        path: 'contracts',
        component: BillingContractsTableComponent,
      },
      {
        path: 'plugins',
        component: BillingPluginsTableComponent,
      },
      {
        path: 'discounts',
        component: BillingDiscountsTableComponent,
      },
      {
        path: '',
        redirectTo: 'contracts',
        pathMatch: 'full',
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContractsRoutingModule {}
