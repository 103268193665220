import { gql } from 'apollo-angular';

export const updateContractConnectionDiscount = gql`
  mutation UpdateContractConnectionDiscount(
    $data: ContractConnectionDiscountUpdateInput!
    $where: ContractConnectionDiscountWhereUniqueInput!
  ) {
    updateContractConnectionDiscount(data: $data, where: $where) {
      id
    }
  }
`;
