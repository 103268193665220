import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationService } from '../../services/notification.service';
import { ModuleType } from '../../../../@core/interfaces/_index.interfaces';
import { WebAdminService } from '../../../../@core/services/_index-core.services';
import { ProfileNetworkService } from '../../services/profile-network.service';

@Component({
  selector: 'tgx-dashboard-partners-with-crmid',
  templateUrl: './dashboard-partners-with-crmid.component.html',
  styleUrls: ['./dashboard-partners-with-crmid.component.scss'],
})
export class DashboardPartnersWithCrmIdComponent implements OnInit, OnDestroy {
  numOrgs = 0;
  totalOrgs = 0;
  $subs: Subscription[] = [];

  constructor(
    private router: Router,
    private webAdminService: WebAdminService,
    private notificationService: NotificationService,
    private ProfileNetworkService: ProfileNetworkService,
  ) {}

  ngOnInit(): void {
    this.webAdminService.emitUserTypevalue(ModuleType.DASHBOARD);

    this.$subs.push(
      this.webAdminService.allOrgs.subscribe((orgs) => {
        if (orgs?.length > 0) {
          this.totalOrgs = orgs.length;
        }
      }),
    );

    this.ProfileNetworkService.getPartnersWithoutCrmID()
      .then((numOrgs) => {
        this.numOrgs = numOrgs;
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      });
  }

  ngOnDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
  }

  getValue() {
    if (this.numOrgs > 0) {
      return Math.round(((this.totalOrgs - this.numOrgs) * 100) / this.totalOrgs);
    } else if (this.numOrgs === 0) {
      return 100;
    }
    return 0;
  }

  goToProfileSection() {
    this.router.navigate(['/network/profiles'], { queryParams: { 'order-type': 'asc', 'order-column': 'crmId' } });
  }
}
