import { gql } from 'apollo-angular';

export const getContracts = gql`
  query ContractsByOrg($where: ContractWhereInput!) {
    contracts(where: $where) {
      id
      reference
      enabled
      minimumFee
      lookToBook
      lookToBookCheckinNorDate
      freeTrialDays
      invoicePerApi
      currency
      excess
      minimumUnit
      lookToBookTotalNorSearches
      excessBookingsTotalNorEffectives
      manualInvoice
      partiallyInvoiced
      bookCheckinNorDate
      activationDateTime
      endDate
      createdAt
      updatedAt
      ttvOnNrf
      partnerType
      modelType
      reviewFeeDate
      expirationDays
      remarks
      rateType
      ContractType {
        id
        name
      }
      defaultPaymentType
      BillingAccount {
        id
      }
      suppliersExcluded
    }
  }
`;
