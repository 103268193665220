import { Options } from './../../../../../../../@core/interfaces/_index.interfaces';
import { NotificationService } from './../../../../../../../@core/services/notification.service';
import {
  StaticBillingService,
  ContractService,
  ContractTypeService,
} from './../../../../../services/_index-billing.services';
import { Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { clone } from '../../../../../../../@core/utils/utils';
import {
  Contract,
  BillingAccount,
  ContractUpdateInput,
  ObjectWhereUniqueInputById,
  ContractCreateInput,
  PartnerType,
  PartnerModelType,
  PaymentType,
  ContractType,
  ContractRateType,
} from '../../../../../interfaces/_index.billing.interfaces';
import { WebAdminService } from '../../../../../../../@core/services/web-admin.service';

@Component({
  selector: 'tgx-admin-billing-contract-modal',
  styleUrls: ['./contract-modal.component.scss'],
  templateUrl: 'contract-modal.component.html',
})
export class BillingContractModalComponent implements OnInit, OnDestroy {
  @Input() contract: any = null;
  @Input() header: string;
  @Input() partnerId: number;
  @Input() billingAccounts: BillingAccount[];
  @Input() isEditor: boolean;

  isCreating: boolean;
  contractAux: Contract;
  ended = false;

  public contractForm: UntypedFormGroup;

  subscriptions: Subscription[] = [];

  currencies: Options[];
  currentCurrency: Options;

  contractTypesAux: Options[] = [{ code: null, label: 'Select One' }];

  partnerTypes: Options[] = [{ code: null, label: 'Select One' }];

  rateTypes: Options[] = [{ code: null, label: 'Select One' }];
  rateTypesAll: Options[] = [{ code: null, label: 'Select One' }];

  modelTypes: Options[] = [{ code: null, label: 'Select One' }];
  modelTypesBuyer: Options[] = [{ code: null, label: 'Select One' }];
  modelTypesSeller: Options[] = [{ code: null, label: 'Select One' }];

  paymentTypes: Options[] = [{ code: null, label: 'None' }];

  partnerSubTypes: Options[] = [{ code: null, label: 'None' }];
  partnerSubTypesCommon: Options[] = [{ code: null, label: 'None' }];
  partnerSubTypesOther: Options[] = [{ code: null, label: 'None' }];

  billingAccountOptions: Options[] = [{ code: null, label: 'Select One' }];

  contractTypes: ContractType[];

  isBillingAdmin = false;

  constructor(
    private notificationService: NotificationService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private staticService: StaticBillingService,
    private contractService: ContractService,
    private webAdminService: WebAdminService,
    private readonly contractTypeService: ContractTypeService,
  ) {}

  async ngOnInit() {
    this.isBillingAdmin = this.webAdminService.isBillingEditorOrAdmin(true);

    this.buildCurrencies();

    this.currentCurrency = { label: 'EUR', code: 'EUR' };

    if (this.contract) {
      this.currentCurrency = { label: this.contract.currency, code: this.contract.currency };
    }

    this.contractForm = this.fb.group({
      id: [this.contract ? this.contract.id : ''],
      enabled: [this.contract ? this.contract.enabled : true],
      excess: [this.contract ? this.contract.excess : false],
      defaultpdf: [this.contract ? false : true],
      activationDateTime: [this.contract ? new Date(this.contract.activationDateTime) : '', Validators.required],
      endDate: [this.contract?.endDate ? new Date(this.contract.endDate) : null],
      reference: [this.contract ? this.contract.reference : '', Validators.required],
      currency: [this.currentCurrency, Validators.required],
      lookToBook: [this.contract ? this.contract.lookToBook : '0', Validators.required],
      minimumFee: [this.contract ? this.contract.minimumFee : '0', Validators.required],
      minimumUnit: [this.contract ? this.contract.minimumUnit : '0', Validators.required],
      manualInvoice: [this.contract ? this.contract.manualInvoice : false],
      partiallyInvoiced: [this.contract ? this.contract.partiallyInvoiced : false],
      bookCheckinNorDate: [this.contract ? this.contract.bookCheckinNorDate : true],
      lookToBookCheckinNorDate: [this.contract ? this.contract.lookToBookCheckinNorDate : false],
      partnerType: ['', Validators.required],
      rateType: [''],
      partnerSubType: [''],
      modelType: [''],
      billingAccount: ['', Validators.required],
      reviewFeeDate: [this.contract?.reviewFeeDate ? new Date(this.contract.reviewFeeDate) : null],
      expirationDays: [this.contract?.expirationDays ? this.contract?.expirationDays : null],
      remarks: [this.contract ? this.contract.remarks : ''],
      supexc: [this.contract?.supexc?.length > 0 ? this.contract.supexc.join(',') : ''],
      defaultPaymentType: [''],
      hasRappel: [this.contract ? this.contract.hasRappel : false],
      rappelDiscount: [this.contract ? this.contract.rappelDiscount : null],
      rappelMinimum: [this.contract ? this.contract.rappelMinimum : null],
    });

    this.buildSelects();
    await this.getContractTypes();

    this.isCreating = true;

    if (this.contract) {
      this.isCreating = false;
      this.currentCurrency = this.currencies.filter((x) => x.code === this.contract.currency)[0];
      this.contractForm
        .get('partnerType')
        .setValue(this.partnerTypes[this.partnerTypes.findIndex((x) => x.code === this.contract.partnerType)]);
      this.contractForm
        .get('modelType')
        .setValue(this.modelTypes[this.modelTypes.findIndex((x) => x.code === this.contract.modelType)]);

      if (this.contract?.rateType) {
        this.contractForm
          .get('rateType')
          .setValue(this.rateTypes[this.rateTypes.findIndex((x) => x.code === this.contract.rateType)]);
      } else {
        this.contractForm.get('rateType').setValue(this.rateTypes[0]);
      }

      this.contractForm
        .get('partnerSubType')
        .setValue(
          this.partnerSubTypes[this.partnerSubTypes.findIndex((x) => x.label === this.contract.partnerSubType)],
        );

      if (this.contract.defaultPaymentType) {
        this.contractForm
          .get('defaultPaymentType')
          .setValue(this.paymentTypes[this.paymentTypes.findIndex((x) => x.code === this.contract.defaultPaymentType)]);
      } else {
        this.contractForm.get('defaultPaymentType').setValue(this.paymentTypes[0]);
      }

      this.contractForm.get('partnerType').disable();
      this.contractForm.get('partnerSubType').disable();
      this.contractForm.get('modelType').disable();
      this.contractForm.get('rateType').disable();
    }

    if (!this.isCreating) {
      if (!this.isBillingAdmin) {
        this.contractForm.get('activationDateTime').disable();
      }

      if (this.contract?.endDate || !this.isEditor) {
        if (!this.isBillingAdmin) {
          this.contractForm.get('endDate').disable();
        }

        this.contractForm.get('enabled').disable();
        this.contractForm.get('excess').disable();

        if (!this.isBillingAdmin) {
          this.contractForm.get('activationDateTime').disable();
        }

        this.contractForm.get('reference').disable();
        this.contractForm.get('currency').disable();
        this.contractForm.get('lookToBook').disable();
        this.contractForm.get('minimumFee').disable();
        this.contractForm.get('minimumUnit').disable();
        this.contractForm.get('manualInvoice').disable();
        this.contractForm.get('partiallyInvoiced').disable();
        this.contractForm.get('bookCheckinNorDate').disable();
        this.contractForm.get('lookToBookCheckinNorDate').disable();
        this.contractForm.get('partnerType').disable();
        this.contractForm.get('rateType').disable();
        this.contractForm.get('partnerSubType').disable();
        this.contractForm.get('modelType').disable();
        this.contractForm.get('billingAccount').disable();
        this.contractForm.get('reviewFeeDate').disable();
        this.contractForm.get('expirationDays').disable();
        this.contractForm.get('remarks').disable();
        this.contractForm.get('defaultPaymentType').disable();
        this.contractForm.get('supexc').disable();

        this.contractForm.get('hasRappel').disable();
        this.contractForm.get('rappelDiscount').disable();
        this.contractForm.get('rappelMinimum').disable();

        if (this.contract?.endDate && !this.isBillingAdmin) {
          this.ended = true;
          this.notificationService.warning('Contract ended, edit is disabled', 'Disabled');
        }
      }

      if (this.contract.billingAccountId) {
        const index = this.billingAccountOptions.findIndex((x) => x.code === this.contract.billingAccountId.toString());
        if (index > -1) {
          this.contractForm.get('billingAccount').setValue(this.billingAccountOptions[index]);
        }
      }
    } else {
      this.contractForm.get('endDate').disable();
      this.contractForm.get('partnerType').setValue(this.partnerTypes[0]);
      this.contractForm.get('partnerSubType').setValue(this.partnerSubTypes[0]);
      this.contractForm.get('rateType').setValue(this.rateTypes[0]);
      this.contractForm.get('modelType').setValue(this.modelTypes[0]);
      this.contractForm.get('billingAccount').setValue(this.billingAccountOptions[0]);
      this.contractForm.get('defaultPaymentType').setValue(this.paymentTypes[0]);
      this.contractForm.get('partnerSubType').disable();
      this.contractForm.get('modelType').disable();
      this.contractForm.get('rateType').disable();
    }

    this.contractForm.get('id').disable();
    this.contractAux = clone(this.contract);
  }

  buildCurrencies() {
    const currencies = this.staticService.getCurrencies();
    this.currencies = [];

    currencies.forEach((code: string, name: string) => {
      this.currencies.push({ label: name, code: code });
    });
  }

  buildSelects() {
    Object.keys(ContractRateType).forEach((value) => {
      this.rateTypesAll.push({ code: value, label: value });
    });

    this.rateTypes = this.rateTypesAll;

    Object.keys(PartnerType).forEach((value) => {
      this.partnerTypes.push({ code: value, label: value });
    });

    Object.keys(PartnerModelType).forEach((value) => {
      if (value === PartnerModelType.POWER) {
        this.modelTypesBuyer.push({ code: value, label: value });
      } else if (value === PartnerModelType.CM22) {
        this.modelTypesBuyer.push({ code: value, label: value });
        this.modelTypesSeller.push({ code: value, label: value });
      } else if (value === PartnerModelType.CM23) {
        this.modelTypesBuyer.push({ code: value, label: value });
        this.modelTypesSeller.push({ code: value, label: value });
      } else if (value === PartnerModelType.SELECTIVE_PREMIUM) {
        this.modelTypesSeller.push({ code: value, label: value });
      } else if (value === PartnerModelType.FULL_PREMIUM) {
        this.modelTypesSeller.push({ code: value, label: value });
      }
      this.modelTypes.push({ code: value, label: value });
    });

    Object.keys(PaymentType).forEach((value) => {
      if (
        value !== PaymentType.SUPPLEMENT_CM &&
        value !== PaymentType.STANDARD &&
        value !== PaymentType.SUPPLEMENT_FS
      ) {
        this.paymentTypes.push({ code: value, label: value });
      }
    });

    this.billingAccounts.forEach((ba) => {
      this.billingAccountOptions.push({
        code: ba.id.toString(),
        label: ba.name + ' (cif: ' + ba.cif + ', odooCode: ' + ba.odooCode + ')',
      });
    });
  }

  async getContractTypes() {
    await this.contractTypeService
      .getContractTypes()
      .then((rs) => {
        this.contractTypes = rs;
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      })
      .finally(() => {
        this.partnerSubTypesCommon = [{ code: null, label: 'None' }];
        this.partnerSubTypesOther = [{ code: null, label: 'None' }];
        this.contractTypes.forEach((ct) => {
          if (ct.id === 1 || ct.id === 2 || ct.id === 3 || ct.id === 5) {
            this.partnerSubTypesCommon.push({ code: ct.id.toString(), label: ct.name });
          } else {
            this.partnerSubTypesOther.push({ code: ct.id.toString(), label: ct.name });
          }
          this.partnerSubTypes.push({ code: ct.id.toString(), label: ct.name });
        });
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  closeModal() {
    this.contract = clone(this.contractAux);
    this.activeModal.close(true);
  }

  onSave() {
    let activationDate;
    let endDate;
    let reviewDate;

    if (this.contractForm.get('activationDateTime').value) {
      const date = new Date(this.contractForm.get('activationDateTime').value);
      let dateStr = date.getFullYear().toString();
      const month = date.getMonth() + 1;
      if (month < 10) {
        dateStr += '-0' + month.toString();
      } else {
        dateStr += '-' + month.toString();
      }
      const day = date.getDate();
      if (day < 10) {
        dateStr += '-0' + day.toString();
      } else {
        dateStr += '-' + day.toString();
      }
      dateStr += 'T00:00:00.000Z';
      activationDate = dateStr;
    }

    if (this.contractForm.get('endDate').value) {
      const date = new Date(this.contractForm.get('endDate').value);
      let dateStr = date.getFullYear().toString();
      const month = date.getMonth() + 1;
      if (month < 10) {
        dateStr += '-0' + month.toString();
      } else {
        dateStr += '-' + month.toString();
      }
      const day = date.getDate();
      if (day < 10) {
        dateStr += '-0' + day.toString();
      } else {
        dateStr += '-' + day.toString();
      }
      dateStr += 'T23:59:59.000Z';
      endDate = dateStr;
    }

    if (this.contractForm.get('reviewFeeDate').value) {
      const date = new Date(this.contractForm.get('reviewFeeDate').value);
      let dateStr = date.getFullYear().toString();
      const month = date.getMonth() + 1;
      if (month < 10) {
        dateStr += '-0' + month.toString();
      } else {
        dateStr += '-' + month.toString();
      }
      const day = date.getDate();
      if (day < 10) {
        dateStr += '-0' + day.toString();
      } else {
        dateStr += '-' + day.toString();
      }
      dateStr += 'T00:00:00.000Z';
      reviewDate = dateStr;
    }

    if (this.isCreating) {
      //Mutation create
      const createData: ContractCreateInput = {
        enabled: this.contractForm.get('enabled').value,
        excess: this.contractForm.get('excess').value,
        manualInvoice: this.contractForm.get('manualInvoice').value,
        partiallyInvoiced: this.contractForm.get('partiallyInvoiced').value,
        bookCheckinNorDate: this.contractForm.get('bookCheckinNorDate').value,
        lookToBookCheckinNorDate: this.contractForm.get('lookToBookCheckinNorDate').value,
        reference: this.contractForm.get('reference').value,
        currency: this.contractForm.get('currency').value.code,
        lookToBook: this.contractForm.get('lookToBook').value.toString(),
        minimumFee: this.contractForm.get('minimumFee').value.toString(),
        minimumUnit: this.contractForm.get('minimumUnit').value.toString(),
        invoicePerApi: false,
        activationDateTime: activationDate,
        Partner: { connect: { id: this.partnerId } },
        BillingAccount: { connect: { id: Number(this.contractForm.get('billingAccount').value.code) } },
        partnerType: this.contractForm.get('partnerType').value.code,
        hasRappel: this.contractForm.get('hasRappel').value,
        rappelDiscount: this.contractForm.get('rappelDiscount').value
          ? this.contractForm.get('rappelDiscount').value.toString()
          : null,
        rappelMinimum: this.contractForm.get('rappelMinimum').value
          ? this.contractForm.get('rappelMinimum').value.toString()
          : null,
      };

      if (this.contractForm.get('remarks').value) {
        createData.remarks = this.contractForm.get('remarks').value;
      }

      if (this.contractForm.get('defaultPaymentType').value.code) {
        createData.defaultPaymentType = this.contractForm.get('defaultPaymentType').value.code;
      }

      if (this.contractForm.get('modelType').value.code) {
        createData.modelType = this.contractForm.get('modelType').value.code;
      }

      if (this.contractForm.get('partnerSubType').value.code) {
        createData.ContractType = { connect: { id: Number(this.contractForm.get('partnerSubType').value.code) } };
      }

      if (this.contractForm.get('supexc').value) {
        const supCodes: string[] = this.contractForm.get('supexc').value.split(',');
        createData.suppliersExcluded = { set: supCodes.map((x) => x.toUpperCase().trim()) };
      }

      if (
        (this.contractForm.get('partnerType').value.code === 'OTHER' &&
          this.contractForm.get('partnerSubType').value?.label === 'INSIGHTS') ||
        this.contractForm.get('partnerType').value.code !== 'OTHER'
      ) {
        createData.rateType = this.contractForm.get('rateType').value.code;
      }

      if (reviewDate) {
        createData.reviewFeeDate = reviewDate;
      }

      if (this.contractForm.get('expirationDays').value) {
        createData.expirationDays = this.contractForm.get('expirationDays').value;
      }

      let loadPDF = true;
      if (this.contractForm.get('defaultpdf').value === false) {
        loadPDF = false;
      }

      this.contractService
        .createContract(createData, loadPDF)
        .then((rs) => {
          this.notificationService.success('Contract succesfully created', 'Success');
          this.activeModal.close(rs);
        })
        .catch((err) => {
          this.activeModal.close(false);
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    } else {
      ///Mutation update
      const updateData: ContractUpdateInput = {
        reference: { set: this.contractForm.get('reference').value },
        enabled: { set: this.contractForm.get('enabled').value },
        manualInvoice: { set: this.contractForm.get('manualInvoice').value },
        currency: { set: this.contractForm.get('currency').value.code },
        minimumFee: { set: this.contractForm.get('minimumFee').value.toString() },
        lookToBook: { set: this.contractForm.get('lookToBook').value.toString() },
        excess: { set: this.contractForm.get('excess').value },
        minimumUnit: { set: this.contractForm.get('minimumUnit').value.toString() },
        activationDateTime: { set: activationDate },
        BillingAccount: { connect: { id: Number(this.contractForm.get('billingAccount').value.code) } },
        partnerType: { set: this.contractForm.get('partnerType').value.code },
        modelType: { set: this.contractForm.get('modelType').value.code },
        hasRappel: { set: this.contractForm.get('hasRappel').value },
        rappelDiscount: this.contractForm.get('rappelDiscount').value
          ? { set: this.contractForm.get('rappelDiscount').value.toString() }
          : { set: null },
        rappelMinimum: this.contractForm.get('rappelMinimum').value
          ? { set: this.contractForm.get('rappelMinimum').value.toString() }
          : { set: null },
      };

      if (endDate) {
        updateData.endDate = { set: endDate };

        if (new Date(activationDate) > new Date(endDate)) {
          this.notificationService.warning('Invalid dates, endDate must be greater than activationDate');
          return;
        }
      }

      if (reviewDate) {
        updateData.reviewFeeDate = { set: reviewDate };
      } else {
        updateData.reviewFeeDate = { set: null };
      }

      if (this.contractForm.get('defaultPaymentType').value.code) {
        updateData.defaultPaymentType = { set: this.contractForm.get('defaultPaymentType').value.code };
      } else {
        updateData.defaultPaymentType = { set: null };
      }

      if (this.contractForm.get('remarks').value) {
        updateData.remarks = { set: this.contractForm.get('remarks').value };
      } else {
        updateData.remarks = { set: null };
      }

      if (this.contractForm.get('expirationDays').value) {
        updateData.expirationDays = { set: this.contractForm.get('expirationDays').value };
      } else {
        updateData.expirationDays = { set: null };
      }

      updateData.lookToBookCheckinNorDate = { set: this.contractForm.get('lookToBookCheckinNorDate').value };
      updateData.bookCheckinNorDate = { set: this.contractForm.get('bookCheckinNorDate').value };
      updateData.partiallyInvoiced = { set: this.contractForm.get('partiallyInvoiced').value };

      if (this.contractForm.get('supexc').value) {
        const supCodes: string[] = this.contractForm.get('supexc').value.split(',');
        updateData.suppliersExcluded = { set: supCodes.map((x) => x.toUpperCase().trim()) };
      } else {
        updateData.suppliersExcluded = { set: [] };
      }

      const where: ObjectWhereUniqueInputById = { id: this.contract.id };

      this.contractService
        .updateContract(updateData, where)
        .then((rs) => {
          this.notificationService.success('Contract succesfully updated', 'Success');
          this.activeModal.close(rs);
        })
        .catch((err) => {
          this.activeModal.close(false);
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    }
  }

  validDropdown(dropdownType: string) {
    if (!this.contractForm.get(dropdownType).value.code) {
      this.contractForm.get(dropdownType).setErrors({ 'Required': true });
    } else {
      this.contractForm.get(dropdownType).setErrors(null);
    }
  }

  checkSelectsContractType() {
    if (this.contractForm.get('partnerType').value.code) {
      if (this.contractForm.get('partnerType').value.code === 'OTHER') {
        this.partnerSubTypes = this.partnerSubTypesOther;
        this.contractForm.get('partnerSubType').enable();
        this.contractForm.get('modelType').setValue(this.modelTypes[0]);
        this.contractForm.get('modelType').disable();
      } else {
        this.partnerSubTypes = this.partnerSubTypesCommon;
        this.contractForm.get('modelType').enable();
        if (this.contractForm.get('modelType').value.code && this.contractForm.get('modelType').value.code === 'CM23') {
          this.contractForm.get('partnerSubType').enable();
        } else {
          this.contractForm.get('partnerSubType').disable();
          this.contractForm.get('partnerSubType').setValue(this.partnerSubTypes[0]);
        }

        if (this.contractForm.get('partnerType').value.code === 'BUYER') {
          this.modelTypes = this.modelTypesBuyer;
        } else if (this.contractForm.get('partnerType').value.code === 'SELLER') {
          this.modelTypes = this.modelTypesSeller;
        }
      }
    } else {
      this.contractForm.get('partnerSubType').disable();
      this.contractForm.get('partnerSubType').setValue(this.partnerSubTypes[0]);
      this.contractForm.get('modelType').disable();
      this.contractForm.get('modelType').setValue(this.modelTypes[0]);
    }
  }

  validateRateTypes() {
    //revisar tota aquesta casuisita i sino, la llevam sencera i per avall.
    if (
      (this.contractForm.get('partnerType').value.code && this.contractForm.get('modelType').value.code) ||
      (this.contractForm.get('partnerType').value.code === 'OTHER' &&
        this.contractForm.get('partnerSubType').value?.label === 'INSIGHTS')
    ) {
      this.contractForm.get('rateType').enable();
    } else {
      this.rateTypes = [...this.rateTypesAll];
      this.contractForm.get('rateType').setValue(null);
      this.contractForm.get('rateType').setValue(this.rateTypes[0]);
      this.contractForm.get('rateType').disable();
    }

    if (
      this.contractForm.get('modelType').value?.code === 'CM23' ||
      this.contractForm.get('partnerType').value?.code === 'OTHER'
    ) {
      this.contractForm.get('partnerSubType').enable();
    }

    if (this.contractForm.get('modelType').value?.code === 'CM23') {
      this.rateTypes = this.rateTypesAll.filter((x) => x.code === ContractRateType.GMV);
      this.contractForm.get('rateType').disable();
    } else if (this.contractForm.get('modelType').value?.code === 'CM22') {
      this.rateTypes = this.rateTypesAll.filter(
        (x) => x.code === ContractRateType.NEW_PRICING_GMV || x.code === ContractRateType.NEW_PRICING_BOOKING,
      );
    } else if (
      !this.contractForm.get('modelType').value?.code &&
      this.contractForm.get('partnerType').value.code === 'OTHER' &&
      this.contractForm.get('partnerSubType').value?.label === 'INSIGHTS'
    ) {
      this.rateTypes = this.rateTypesAll.filter((x) => x.code === ContractRateType.PLUGIN);
      this.contractForm.get('rateType').disable();
    } else if (this.contractForm.get('modelType').value?.code) {
      this.rateTypes = this.rateTypesAll.filter(
        (x) =>
          x.code !== ContractRateType.NEW_PRICING_GMV &&
          x.code !== ContractRateType.NEW_PRICING_BOOKING &&
          x.code !== ContractRateType.PLUGIN,
      );
    }
  }

  validForm(): boolean {
    return (
      this.contractForm.get('activationDateTime').value &&
      this.contractForm.get('reference').value &&
      this.contractForm.get('partnerType').value.code &&
      this.validOtherType() &&
      this.contractForm.get('billingAccount').value.code &&
      this.contractForm.valid
    );
  }

  isNp23(): boolean {
    return this.contractForm.get('modelType').value.code === PartnerModelType.CM23;
  }

  validOtherType(): boolean {
    if (
      this.contractForm.get('partnerType').value.code === 'OTHER' &&
      this.contractForm.get('partnerSubType').value.code &&
      !this.contractForm.get('modelType').value.code
    ) {
      return true;
    }
    if (this.contractForm.get('partnerType').value.code !== 'OTHER' && this.contractForm.get('modelType').value.code) {
      return true;
    }
    return false;
  }

  showExtraProperties(): boolean {
    return this.contractForm.get('partnerType').value?.code !== 'OTHER';
  }

  showLoadDefaultPDF(): boolean {
    return (
      (this.contractForm.get('partnerType').value?.code === 'OTHER' &&
        this.contractForm.get('partnerSubType').value?.code) ||
      this.contractForm.get('modelType').value?.code === 'CM23'
    );
  }
}
