import { gql } from 'apollo-angular';

export const archiveOrganization = gql`
  mutation ArchiveOrganization($where: OrganizationWhereUniqueInput!) {
    admin {
      archiveOrganization(where: $where) {
        code
        adviseMessage {
          code
          description
        }
      }
    }
  }
`;
