import { Injectable } from '@angular/core';

@Injectable()
export class StaticBillingService {
  getCountries(): Map<string, string> {
    const countries = new Map<string, string>();

    countries.set('Afghanistan', 'AF');
    countries.set('Albania', 'AL');
    countries.set('Algeria', 'DZ');
    countries.set('American Samoa', 'AS');
    countries.set('Andorra', 'AD');
    countries.set('Angola', 'AO');
    countries.set('Anguilla', 'AI');
    countries.set('Antarctica', 'AQ');
    countries.set('Antigua and Barbuda', 'AG');
    countries.set('Argentina', 'AR');
    countries.set('Armenia', 'AM');
    countries.set('Aruba', 'AW');
    countries.set('Australia', 'AU');
    countries.set('Austria', 'AT');
    countries.set('Azerbaijan', 'AZ');
    countries.set('Bahamas (the)', 'BS');
    countries.set('Bahrain', 'BH');
    countries.set('Bangladesh', 'BD');
    countries.set('Barbados', 'BB');
    countries.set('Belarus', 'BY');
    countries.set('Belgium', 'BE');
    countries.set('Belize', 'BZ');
    countries.set('Benin', 'BJ');
    countries.set('Bermuda', 'BM');
    countries.set('Bhutan', 'BT');
    countries.set('Bolivia (Plurinational State of)', 'BO');
    countries.set('Bonaire, Sint Eustatius and Saba', 'BQ');
    countries.set('Bosnia and Herzegovina', 'BA');
    countries.set('Botswana', 'BW');
    countries.set('Bouvet Island', 'BV');
    countries.set('Brazil', 'BR');
    countries.set('British Indian Ocean Territory (the)', 'IO');
    countries.set('Brunei Darussalam', 'BN');
    countries.set('Bulgaria', 'BG');
    countries.set('Burkina Faso', 'BF');
    countries.set('Burundi', 'BI');
    countries.set('Cabo Verde', 'CV');
    countries.set('Cambodia', 'KH');
    countries.set('Cameroon', 'CM');
    countries.set('Canada', 'CA');
    countries.set('Cayman Islands (the)', 'KY');
    countries.set('Central African Republic (the)', 'CF');
    countries.set('Chad', 'TD');
    countries.set('Chile', 'CL');
    countries.set('China', 'CN');
    countries.set('Christmas Island', 'CX');
    countries.set('Cocos (Keeling) Islands (the)', 'CC');
    countries.set('Colombia', 'CO');
    countries.set('Comoros (the)', 'KM');
    countries.set('Congo (the Democratic Republic of the)', 'CD');
    countries.set('Congo (the)', 'CG');
    countries.set('Cook Islands (the)', 'CK');
    countries.set('Costa Rica', 'CR');
    countries.set('Croatia', 'HR');
    countries.set('Cuba', 'CU');
    countries.set('Curaçao', 'CW');
    countries.set('Cyprus', 'CY');
    countries.set('Czechia', 'CZ');
    countries.set("Côte d'Ivoire", 'CI');
    countries.set('Denmark', 'DK');
    countries.set('Djibouti', 'DJ');
    countries.set('Dominica', 'DM');
    countries.set('Dominican Republic (the)', 'DO');
    countries.set('Ecuador', 'EC');
    countries.set('Egypt', 'EG');
    countries.set('El Salvador', 'SV');
    countries.set('Equatorial Guinea', 'GQ');
    countries.set('Eritrea', 'ER');
    countries.set('Estonia', 'EE');
    countries.set('Eswatini', 'SZ');
    countries.set('Ethiopia', 'ET');
    countries.set('Falkland Islands (the) [Malvinas]', 'FK');
    countries.set('Faroe Islands (the)', 'FO');
    countries.set('Fiji', 'FJ');
    countries.set('Finland', 'FI');
    countries.set('France', 'FR');
    countries.set('French Guiana', 'GF');
    countries.set('French Polynesia', 'PF');
    countries.set('French Southern Territories (the)', 'TF');
    countries.set('Gabon', 'GA');
    countries.set('Gambia (the)', 'GM');
    countries.set('Georgia', 'GE');
    countries.set('Germany', 'DE');
    countries.set('Ghana', 'GH');
    countries.set('Gibraltar', 'GI');
    countries.set('Greece', 'GR');
    countries.set('Greenland', 'GL');
    countries.set('Grenada', 'GD');
    countries.set('Guadeloupe', 'GP');
    countries.set('Guam', 'GU');
    countries.set('Guatemala', 'GT');
    countries.set('Guernsey', 'GG');
    countries.set('Guinea', 'GN');
    countries.set('Guinea-Bissau', 'GW');
    countries.set('Guyana', 'GY');
    countries.set('Haiti', 'HT');
    countries.set('Heard Island and McDonald Islands', 'HM');
    countries.set('Holy See (the)', 'VA');
    countries.set('Honduras', 'HN');
    countries.set('Hong Kong', 'HK');
    countries.set('Hungary', 'HU');
    countries.set('Iceland', 'IS');
    countries.set('India', 'IN');
    countries.set('Indonesia', 'ID');
    countries.set('Iran (Islamic Republic of)', 'IR');
    countries.set('Iraq', 'IQ');
    countries.set('Ireland', 'IE');
    countries.set('Isle of Man', 'IM');
    countries.set('Israel', 'IL');
    countries.set('Italy', 'IT');
    countries.set('Jamaica', 'JM');
    countries.set('Japan', 'JP');
    countries.set('Jersey', 'JE');
    countries.set('Jordan', 'JO');
    countries.set('Kazakhstan', 'KZ');
    countries.set('Kenya', 'KE');
    countries.set('Kiribati', 'KI');
    countries.set("Korea (the Democratic People's Republic of)", 'KP');
    countries.set('Korea (the Republic of)', 'KR');
    countries.set('Kuwait', 'KW');
    countries.set('Kyrgyzstan', 'KG');
    countries.set("Lao People's Democratic Republic (the)", 'LA');
    countries.set('Latvia', 'LV');
    countries.set('Lebanon', 'LB');
    countries.set('Lesotho', 'LS');
    countries.set('Liberia', 'LR');
    countries.set('Libya', 'LY');
    countries.set('Liechtenstein', 'LI');
    countries.set('Lithuania', 'LT');
    countries.set('Luxembourg', 'LU');
    countries.set('Macao', 'MO');
    countries.set('Madagascar', 'MG');
    countries.set('Malawi', 'MW');
    countries.set('Malaysia', 'MY');
    countries.set('Maldives', 'MV');
    countries.set('Mali', 'ML');
    countries.set('Malta', 'MT');
    countries.set('Marshall Islands (the)', 'MH');
    countries.set('Martinique', 'MQ');
    countries.set('Mauritania', 'MR');
    countries.set('Mauritius', 'MU');
    countries.set('Mayotte', 'YT');
    countries.set('Mexico', 'MX');
    countries.set('Micronesia (Federated States of)', 'FM');
    countries.set('Moldova (the Republic of)', 'MD');
    countries.set('Monaco', 'MC');
    countries.set('Mongolia', 'MN');
    countries.set('Montenegro', 'ME');
    countries.set('Montserrat', 'MS');
    countries.set('Morocco', 'MA');
    countries.set('Mozambique', 'MZ');
    countries.set('Myanmar', 'MM');
    countries.set('Namibia', 'NA');
    countries.set('Nauru', 'NR');
    countries.set('Nepal', 'NP');
    countries.set('Netherlands (the)', 'NL');
    countries.set('New Caledonia', 'NC');
    countries.set('New Zealand', 'NZ');
    countries.set('Nicaragua', 'NI');
    countries.set('Niger (the)', 'NE');
    countries.set('Nigeria', 'NG');
    countries.set('Niue', 'NU');
    countries.set('Norfolk Island', 'NF');
    countries.set('Northern Mariana Islands (the)', 'MP');
    countries.set('Norway', 'NO');
    countries.set('Oman', 'OM');
    countries.set('Pakistan', 'PK');
    countries.set('Palau', 'PW');
    countries.set('Palestine, State of', 'PS');
    countries.set('Panama', 'PA');
    countries.set('Papua New Guinea', 'PG');
    countries.set('Paraguay', 'PY');
    countries.set('Peru', 'PE');
    countries.set('Philippines (the)', 'PH');
    countries.set('Pitcairn', 'PN');
    countries.set('Poland', 'PL');
    countries.set('Portugal', 'PT');
    countries.set('Puerto Rico', 'PR');
    countries.set('Qatar', 'QA');
    countries.set('Republic of North Macedonia', 'MK');
    countries.set('Romania', 'RO');
    countries.set('Russian Federation (the)', 'RU');
    countries.set('Rwanda', 'RW');
    countries.set('Réunion', 'RE');
    countries.set('Saint Barthélemy', 'BL');
    countries.set('Saint Helena, Ascension and Tristan da Cunha', 'SH');
    countries.set('Saint Kitts and Nevis', 'KN');
    countries.set('Saint Lucia', 'LC');
    countries.set('Saint Martin (French part)', 'MF');
    countries.set('Saint Pierre and Miquelon', 'PM');
    countries.set('Saint Vincent and the Grenadines', 'VC');
    countries.set('Samoa', 'WS');
    countries.set('San Marino', 'SM');
    countries.set('Sao Tome and Principe', 'ST');
    countries.set('Saudi Arabia', 'SA');
    countries.set('Senegal', 'SN');
    countries.set('Serbia', 'RS');
    countries.set('Seychelles', 'SC');
    countries.set('Sierra Leone', 'SL');
    countries.set('Singapore', 'SG');
    countries.set('Sint Maarten (Dutch part)', 'SX');
    countries.set('Slovakia', 'SK');
    countries.set('Slovenia', 'SI');
    countries.set('Solomon Islands', 'SB');
    countries.set('Somalia', 'SO');
    countries.set('South Africa', 'ZA');
    countries.set('South Georgia and the South Sandwich Islands', 'GS');
    countries.set('South Sudan', 'SS');
    countries.set('Spain', 'ES');
    countries.set('Sri Lanka', 'LK');
    countries.set('Sudan (the)', 'SD');
    countries.set('Suriname', 'SR');
    countries.set('Svalbard and Jan Mayen', 'SJ');
    countries.set('Sweden', 'SE');
    countries.set('Switzerland', 'CH');
    countries.set('Syrian Arab Republic', 'SY');
    countries.set('Taiwan (Province of China)', 'TW');
    countries.set('Tajikistan', 'TJ');
    countries.set('Tanzania, United Republic of', 'TZ');
    countries.set('Thailand', 'TH');
    countries.set('Timor-Leste', 'TL');
    countries.set('Togo', 'TG');
    countries.set('Tokelau', 'TK');
    countries.set('Tonga', 'TO');
    countries.set('Trinidad and Tobago', 'TT');
    countries.set('Tunisia', 'TN');
    countries.set('Turkey', 'TR');
    countries.set('Turkmenistan', 'TM');
    countries.set('Turks and Caicos Islands (the)', 'TC');
    countries.set('Tuvalu', 'TV');
    countries.set('Uganda', 'UG');
    countries.set('Ukraine', 'UA');
    countries.set('United Arab Emirates (the)', 'AE');
    countries.set('United Kingdom of Great Britain and Northern Ireland (the)', 'GB');
    countries.set('United States Minor Outlying Islands (the)', 'UM');
    countries.set('United States of America (the)', 'US');
    countries.set('Uruguay', 'UY');
    countries.set('Uzbekistan', 'UZ');
    countries.set('Vanuatu', 'VU');
    countries.set('Venezuela (Bolivarian Republic of)', 'VE');
    countries.set('Viet Nam', 'VN');
    countries.set('Virgin Islands (British)', 'VG');
    countries.set('Virgin Islands (U.S.)', 'VI');
    countries.set('Wallis and Futuna', 'WF');
    countries.set('Western Sahara', 'EH');
    countries.set('Yemen', 'YE');
    countries.set('Zambia', 'ZM');
    countries.set('Zimbabwe', 'ZW');
    countries.set('Åland Islands', 'AX');

    return countries;
  }

  getCurrencies(): Map<string, string> {
    const currencies = new Map<string, string>();

    currencies.set('AED', 'AED');
    currencies.set('AFN', 'AFN');
    currencies.set('ALL', 'ALL');
    currencies.set('AMD', 'AMD');
    currencies.set('ANG', 'ANG');
    currencies.set('AOA', 'AOA');
    currencies.set('ARS', 'ARS');
    currencies.set('AUD', 'AUD');
    currencies.set('AWG', 'AWG');
    currencies.set('AZN', 'AZN');
    currencies.set('BAM', 'BAM');
    currencies.set('BBD', 'BBD');
    currencies.set('BDT', 'BDT');
    currencies.set('BGN', 'BGN');
    currencies.set('BHD', 'BHD');
    currencies.set('BIF', 'BIF');
    currencies.set('BMD', 'BMD');
    currencies.set('BND', 'BND');
    currencies.set('BOB', 'BOB');
    currencies.set('BOV', 'BOV');
    currencies.set('BRL', 'BRL');
    currencies.set('BSD', 'BSD');
    currencies.set('BTN', 'BTN');
    currencies.set('BWP', 'BWP');
    currencies.set('BYN', 'BYN');
    currencies.set('BZD', 'BZD');
    currencies.set('CAD', 'CAD');
    currencies.set('CDF', 'CDF');
    currencies.set('CHE', 'CHE');
    currencies.set('CHF', 'CHF');
    currencies.set('CHW', 'CHW');
    currencies.set('CLF', 'CLF');
    currencies.set('CLP', 'CLP');
    currencies.set('CNY', 'CNY');
    currencies.set('COP', 'COP');
    currencies.set('COU', 'COU');
    currencies.set('CRC', 'CRC');
    currencies.set('CUC', 'CUC');
    currencies.set('CUP', 'CUP');
    currencies.set('CVE', 'CVE');
    currencies.set('CZK', 'CZK');
    currencies.set('DJF', 'DJF');
    currencies.set('DKK', 'DKK');
    currencies.set('DOP', 'DOP');
    currencies.set('DZD', 'DZD');
    currencies.set('EGP', 'EGP');
    currencies.set('ERN', 'ERN');
    currencies.set('ETB', 'ETB');
    currencies.set('EUR', 'EUR');
    currencies.set('FJD', 'FJD');
    currencies.set('FKP', 'FKP');
    currencies.set('GBP', 'GBP');
    currencies.set('GEL', 'GEL');
    currencies.set('GHS', 'GHS');
    currencies.set('GIP', 'GIP');
    currencies.set('GMD', 'GMD');
    currencies.set('GNF', 'GNF');
    currencies.set('GTQ', 'GTQ');
    currencies.set('GYD', 'GYD');
    currencies.set('HKD', 'HKD');
    currencies.set('HNL', 'HNL');
    currencies.set('HRK', 'HRK');
    currencies.set('HTG', 'HTG');
    currencies.set('HUF', 'HUF');
    currencies.set('IDR', 'IDR');
    currencies.set('ILS', 'ILS');
    currencies.set('INR', 'INR');
    currencies.set('IQD', 'IQD');
    currencies.set('IRR', 'IRR');
    currencies.set('ISK', 'ISK');
    currencies.set('JMD', 'JMD');
    currencies.set('JOD', 'JOD');
    currencies.set('JPY', 'JPY');
    currencies.set('KES', 'KES');
    currencies.set('KGS', 'KGS');
    currencies.set('KHR', 'KHR');
    currencies.set('KMF', 'KMF');
    currencies.set('KPW', 'KPW');
    currencies.set('KRW', 'KRW');
    currencies.set('KWD', 'KWD');
    currencies.set('KYD', 'KYD');
    currencies.set('KZT', 'KZT');
    currencies.set('LAK', 'LAK');
    currencies.set('LBP', 'LBP');
    currencies.set('LKR', 'LKR');
    currencies.set('LRD', 'LRD');
    currencies.set('LSL', 'LSL');
    currencies.set('LYD', 'LYD');
    currencies.set('MAD', 'MAD');
    currencies.set('MDL', 'MDL');
    currencies.set('MGA', 'MGA');
    currencies.set('MKD', 'MKD');
    currencies.set('MMK', 'MMK');
    currencies.set('MNT', 'MNT');
    currencies.set('MOP', 'MOP');
    currencies.set('MRU', 'MRU');
    currencies.set('MUR', 'MUR');
    currencies.set('MVR', 'MVR');
    currencies.set('MWK', 'MWK');
    currencies.set('MXN', 'MXN');
    currencies.set('MXV', 'MXV');
    currencies.set('MYR', 'MYR');
    currencies.set('MZN', 'MZN');
    currencies.set('NAD', 'NAD');
    currencies.set('NGN', 'NGN');
    currencies.set('NIO', 'NIO');
    currencies.set('NOK', 'NOK');
    currencies.set('NPR', 'NPR');
    currencies.set('NZD', 'NZD');
    currencies.set('OMR', 'OMR');
    currencies.set('PAB', 'PAB');
    currencies.set('PEN', 'PEN');
    currencies.set('PGK', 'PGK');
    currencies.set('PHP', 'PHP');
    currencies.set('PKR', 'PKR');
    currencies.set('PLN', 'PLN');
    currencies.set('PYG', 'PYG');
    currencies.set('QAR', 'QAR');
    currencies.set('RON', 'RON');
    currencies.set('RSD', 'RSD');
    currencies.set('RUB', 'RUB');
    currencies.set('RWF', 'RWF');
    currencies.set('SAR', 'SAR');
    currencies.set('SBD', 'SBD');
    currencies.set('SCR', 'SCR');
    currencies.set('SDG', 'SDG');
    currencies.set('SEK', 'SEK');
    currencies.set('SGD', 'SGD');
    currencies.set('SHP', 'SHP');
    currencies.set('SLL', 'SLL');
    currencies.set('SOS', 'SOS');
    currencies.set('SRD', 'SRD');
    currencies.set('SSP', 'SSP');
    currencies.set('STN', 'STN');
    currencies.set('SVC', 'SVC');
    currencies.set('SYP', 'SYP');
    currencies.set('SZL', 'SZL');
    currencies.set('THB', 'THB');
    currencies.set('TJS', 'TJS');
    currencies.set('TMT', 'TMT');
    currencies.set('TND', 'TND');
    currencies.set('TOP', 'TOP');
    currencies.set('TRY', 'TRY');
    currencies.set('TTD', 'TTD');
    currencies.set('TWD', 'TWD');
    currencies.set('TZS', 'TZS');
    currencies.set('UAH', 'UAH');
    currencies.set('UGX', 'UGX');
    currencies.set('USD', 'USD');
    currencies.set('USN', 'USN');
    currencies.set('UYI', 'UYI');
    currencies.set('UYU', 'UYU');
    currencies.set('UYW', 'UYW');
    currencies.set('UZS', 'UZS');
    currencies.set('VES', 'VES');
    currencies.set('VND', 'VND');
    currencies.set('VUV', 'VUV');
    currencies.set('WST', 'WST');
    currencies.set('XAF', 'XAF');
    currencies.set('XAG', 'XAG');
    currencies.set('XAU', 'XAU');
    currencies.set('XBA', 'XBA');
    currencies.set('XBB', 'XBB');
    currencies.set('XBC', 'XBC');
    currencies.set('XBD', 'XBD');
    currencies.set('XCD', 'XCD');
    currencies.set('XDR', 'XDR');
    currencies.set('XOF', 'XOF');
    currencies.set('XPD', 'XPD');
    currencies.set('XPF', 'XPF');
    currencies.set('XPT', 'XPT');
    currencies.set('XSU', 'XSU');
    currencies.set('XTS', 'XTS');
    currencies.set('XUA', 'XUA');
    currencies.set('XXX', 'XXX');
    currencies.set('YER', 'YER');
    currencies.set('ZAR', 'ZAR');
    currencies.set('ZMW', 'ZMW');
    currencies.set('ZWL', 'ZWL');

    return currencies;
  }
}
