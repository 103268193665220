import { Component, OnInit } from '@angular/core';
import { NotificationService } from './../../../../../../@core/services/_index-core.services';
import { Options } from './../../../../../../@core/interfaces/_index.interfaces';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  SuppliersService,
  ClientsEntitiesService,
} from 'apps/web-admin/src/app/features/entities/services/_index-entities.services';
import { TaskManagerService } from '../../../../services/task-manager.service';
import { Masters, SchedulerFilter, Task } from '../../../../interfaces/_index-content.interfaces';
import { formatDate } from '@angular/common';

@Component({
  selector: 'tgx-billing-scheduler-table',
  templateUrl: './scheduler-table.component.html',
  styleUrls: ['./scheduler-table.component.scss'],
})
export class SchedulerTableComponent implements OnInit {
  public searchForm: UntypedFormGroup;

  isLoading = false;
  isFiltering = false;
  suppliersSelector: Options[] = [];
  filteredSuppliersSelector: Options[] = [];
  clientsSelector: Options[] = [];
  operationsSelector: Options[] = [];
  statusesSelector: Options[] = [];
  masterData: Masters;
  maxDate: Date;
  tasks: Task[] = [];
  shedulerClassifications: string[] = ['Complete', 'Executing', 'Enqueued'];
  scheduleByStatus: any[] = [];
  scheduleComplete: any;
  scheduleExecuting: any;
  scheduleEnqueued: any;
  activeLetter = 'A';

  constructor(
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private supplierService: SuppliersService,
    private clientsEntitiesService: ClientsEntitiesService,
    private taskManagerService: TaskManagerService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.buildForm();
    await this.loadInitialData();
    this.isLoading = false;
  }

  buildForm() {
    const todayDate = new Date();
    const tomorrowDate = new Date(todayDate);
    tomorrowDate.setDate(todayDate.getDate() + 1);

    this.searchForm = this.fb.group({
      suppliers: [''],
      clients: [''],
      operations: [''],
      status: [''],
      startDate: [todayDate, Validators.required],
      endDate: [{ value: tomorrowDate, disabled: false }, Validators.required],
    });

    this.resetSchedules();

    this.searchForm.get('suppliers').valueChanges.subscribe(() => {
      this.sortSuppliers();
      this.checkAndReapplyLetterFilter();
    });
  }

  resetSchedules() {
    this.scheduleComplete = null;
    this.scheduleExecuting = null;
    this.scheduleEnqueued = null;
  }

  async loadInitialData() {
    await Promise.all([this.getSuppliers(), this.getClients(), this.getMasterData()]);
    this.filterSuppliersByLetter(this.activeLetter);
  }

  async getSuppliers() {
    try {
      const supplierEdges = await this.supplierService.getAllSuppliersWithoutFilter();
      this.suppliersSelector = supplierEdges.map((sup) => ({
        code: sup.node?.supplierData?.code,
        label: `${sup.node?.supplierData?.name} (${sup.node?.supplierData?.code})`,
      }));
      this.filteredSuppliersSelector = [...this.suppliersSelector];
      this.sortSuppliers();
    } catch (err) {
      this.notificationService.handleGatewayAndGraphqlErrors(err);
    }
  }

  async getClients() {
    try {
      const clients = await this.clientsEntitiesService.getAllClients();
      this.clientsSelector = clients.map((cli) => ({
        code: cli.node?.clientData?.code,
        label: `${cli.node?.clientData?.name} (${cli.node?.clientData?.code})`,
      }));
    } catch (err) {
      this.notificationService.handleGatewayAndGraphqlErrors(err);
    }
  }

  async getMasterData() {
    try {
      this.masterData = await this.taskManagerService.getMasterData();
      this.buildSelects();
    } catch (err) {
      this.notificationService.handleGatewayAndGraphqlErrors(err);
    }
  }

  buildSelects() {
    if (this.masterData) {
      this.operationsSelector = this.masterData.ServiceOperations.map((so) => ({ code: so, label: so }));
      this.statusesSelector = this.masterData.Statuses.map((st) => ({ code: st, label: st }));
    }
  }

  filterSuppliersByLetter(letter: string) {
    this.activeLetter = letter;
    const selectedSuppliers = this.searchForm.get('suppliers').value || [];
    this.filteredSuppliersSelector = this.suppliersSelector.filter(
      (sup) =>
        sup.label.charAt(sup.label.lastIndexOf('(') + 1) === letter ||
        selectedSuppliers.some((selectedSup) => selectedSup.code === sup.code),
    );
    this.sortSuppliers();
  }

  handleFilter(event: any) {
    const filterValue = event.filter.trim().toUpperCase();
    const selectedSuppliers = this.searchForm.get('suppliers').value || [];
    if (filterValue.length > 1) {
      this.filteredSuppliersSelector = this.suppliersSelector.filter(
        (sup) =>
          sup.label.toUpperCase().includes(filterValue) ||
          selectedSuppliers.some((selectedSup) => selectedSup.code === sup.code),
      );
    } else if (filterValue.length === 0) {
      this.filterSuppliersByLetter(this.activeLetter);
    } else {
      this.filteredSuppliersSelector = this.suppliersSelector.filter(
        (sup) =>
          (sup.label.toUpperCase().includes(filterValue) &&
            sup.label.charAt(sup.label.lastIndexOf('(') + 1) === this.activeLetter) ||
          selectedSuppliers.some((selectedSup) => selectedSup.code === sup.code),
      );
    }
    this.sortSuppliers();
  }

  sortSuppliers() {
    const selectedSuppliers = this.searchForm.get('suppliers').value || [];
    this.filteredSuppliersSelector.sort((a, b) => {
      const aSelected = selectedSuppliers.some((sup) => sup.code === a.code);
      const bSelected = selectedSuppliers.some((sup) => sup.code === b.code);
      if (aSelected && !bSelected) {
        return -1;
      } else if (!aSelected && bSelected) {
        return 1;
      } else {
        return a.label.localeCompare(b.label);
      }
    });
  }

  checkAndReapplyLetterFilter() {
    const selectedSuppliers = this.searchForm.get('suppliers').value || [];
    const filterApplies = selectedSuppliers.every(
      (sup) => sup.label.charAt(sup.label.lastIndexOf('(') + 1) === this.activeLetter,
    );

    if (!filterApplies || selectedSuppliers.length === 0) {
      this.filterSuppliersByLetter(this.activeLetter);
    }
  }

  async filter() {
    this.scheduleByStatus = [];
    this.isFiltering = true;

    const filter: SchedulerFilter = {
      fromDate: formatDate(this.searchForm.get('startDate').value, 'yyyy-MM-dd', 'en-US'),
      toDate: formatDate(this.searchForm.get('endDate').value, 'yyyy-MM-dd', 'en-US'),
      limit: 10000,
      filters: this.getFilters(),
    };

    try {
      const rs = await this.taskManagerService.getListQueue(filter);
      this.tasks = rs;
      this.processTasks();
      if (this.tasks.length === 0) {
        this.notificationService.info('No tasks found with this criteria.', 'Info');
      }
    } catch (err) {
      if (err.status === 204) {
        this.notificationService.info('No tasks found with this criteria.', 'Info');
      } else {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      }
    } finally {
      this.isFiltering = false;
    }
  }

  getFilters() {
    const filters: any = {};
    this.addFilter(filters, 'suppliers', 'Suppliers');
    this.addFilter(filters, 'operations', 'ServiceOperations');
    this.addFilter(filters, 'status', 'Statuses');
    return filters;
  }

  addFilter(filters: any, formControlName: string, filterKey: string) {
    const value = this.searchForm.get(formControlName).value;
    if (value) {
      filters[filterKey] = value.map((item: Options) => item.code);
    }
  }

  checkEndDate() {
    const startDate = this.searchForm.get('startDate').value;
    if (startDate) {
      this.searchForm.get('endDate').enable();
      this.maxDate = this.taskManagerService.addMonths(startDate, 1);
      if (
        !this.searchForm.get('endDate').value ||
        new Date(this.searchForm.get('endDate').value) < new Date(startDate)
      ) {
        this.searchForm.get('endDate').setValue(startDate);
      }
    } else {
      this.searchForm.get('endDate').disable();
    }
  }

  processTasks() {
    const schedule = this.initializeSchedule();

    if (!this.tasks?.length) {
      this.notificationService.warning('No tasks found with this criteria.', 'Warning');
      this.resetSchedules();
      return;
    }

    this.tasks.forEach((task) => {
      this.processTaskStats(task);
      if (task.classification === 'Complete') {
        this.processTaskExecutionTime(task);
      }
      if (this.shedulerClassifications.includes(task.classification)) {
        schedule[task.classification].push(task);
      }
    });

    this.populateScheduleByStatus(schedule);
    this.groupTasksByStatus();
  }

  initializeSchedule() {
    const schedule = {};
    this.shedulerClassifications.forEach((classification) => (schedule[classification] = []));
    return schedule;
  }

  processTaskStats(task) {
    if (!task || !task.stats) {
      task.noStats = true;
      return;
    }

    const parsedStats = JSON.parse(task.stats);
    if (parsedStats && typeof parsedStats === 'object' && parsedStats.StatsLang) {
      const statsLang = parsedStats.StatsLang;
      parsedStats.statsLangArray = [];

      const languages = Object.keys(statsLang);
      if (languages.length) {
        parsedStats.languageColumns = Object.keys(statsLang[languages[0]]);
        languages.forEach((language) => {
          parsedStats.statsLangArray.push({ language, stats: statsLang[language] });
        });
      }
      task.parsedStats = parsedStats;
    } else {
      task.noStats = true;
    }
  }

  processTaskExecutionTime(task) {
    const executedDate = new Date(task.recievedTime);
    const completedDate = new Date(task.completeTime);
    const totalSeconds = Math.floor((completedDate.valueOf() - executedDate.valueOf()) / 1000);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);

    task.executionTime = `${totalHours}:${String(totalMinutes % 60).padStart(2, '0')}:${String(totalSeconds % 60).padStart(2, '0')}`;
  }

  populateScheduleByStatus(schedule) {
    for (const key in schedule) {
      if (schedule[key].length) {
        const indicators = this.getIndicators(schedule[key]);
        this.scheduleByStatus.push({ title: key, rows: schedule[key], indicators });
      }
    }
  }

  getIndicators(tasks) {
    const indicators = { ok: 0, minorWarning: 0, warning: 0, ko: 0 };
    tasks.forEach((task) => {
      switch (task.schedulerStatus) {
        case 'Ok':
        case 'Success':
        case 'Completed':
          indicators.ok++;
          break;
        case 'Minorwarning':
          indicators.minorWarning++;
          break;
        case 'Warning':
          indicators.warning++;
          break;
        default:
          indicators.ko++;
      }
    });
    return indicators;
  }

  groupTasksByStatus() {
    this.scheduleComplete = this.scheduleByStatus.find((x) => x.title === 'Complete') || null;
    this.scheduleExecuting = this.scheduleByStatus.find((x) => x.title === 'Executing') || null;
    this.scheduleEnqueued = this.scheduleByStatus.find((x) => x.title === 'Enqueued') || null;
  }
}
