import { gql } from 'apollo-angular';

export const updateInvoice = gql`
  mutation EditInvoice($data: InvoiceUpdateInput!, $where: InvoiceWhereUniqueInput!) {
    updateInvoice(data: $data, where: $where) {
      id
      amount
      createdAt
      startDate
      endDate
    }
  }
`;
