export enum GqlContextName {
  GATEWAY = 'gateway',
  GATEWAY_PUBLIC = 'gateway_public',
  BILLING = 'billing_api',
  IAM = 'iam',
  SOCIAL = 'social',
  DATA = 'data',
  INSIGHTS = 'insights_api',
  DEV_SOCIAL = 'dev_social',
}
