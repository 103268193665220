import { gql } from 'apollo-angular';

export const getAllMembersByOrgV2 = gql`
  query getMembersFromOrg($where: OrganizationWhereUniqueInput!) {
    admin {
      organization(where: $where) {
        deletedAt
        organizationsData {
          code
          label
          members {
            role
            member {
              code
              label
              type
              isActive
            }
          }
        }
        adviseMessage {
          code
          level
          description
        }
      }
    }
  }
`;
