import { NotificationService } from './../../../../../../../@core/services/notification.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { BillScheduledService } from '../../../../../services/_index-billing.services';
import { BillScheduledCreateInput } from '../../../../../interfaces/_index.billing.interfaces';

@Component({
  selector: 'tgx-admin-billing-invoicing-process-pre-bill-modal',
  styleUrls: ['./invoicing-process-pre-bill-modal.component.scss'],
  templateUrl: 'invoicing-process-pre-bill-modal.component.html',
})
export class BillingProcessPreBillModalComponent implements OnInit {
  @Input() header: string;

  public datesForm: UntypedFormGroup;

  minDateVal: Date;

  constructor(
    private notificationService: NotificationService,
    private activeModal: NgbActiveModal,
    private billScheduledService: BillScheduledService,
    private fb: UntypedFormBuilder,
  ) {}

  async ngOnInit() {
    this.buildMinExecutionDate();
    this.buildForm();
  }

  buildForm() {
    this.datesForm = this.fb.group({
      month: ['', Validators.required],
      executionDate: ['', Validators.required],
    });
  }

  buildMinExecutionDate() {
    this.minDateVal = new Date();
    this.minDateVal.setDate(this.minDateVal.getDate() + 1);
  }

  closeModal() {
    this.activeModal.close(false);
  }

  async onSave() {
    const createData: BillScheduledCreateInput = {
      startDate: this.getDateStartString('month'),
      endDate: this.getDateEndString(),
      executionDate: this.getDateStartString('executionDate'),
    };

    await this.billScheduledService
      .createBillScheduled(createData)
      .then((rs) => {
        this.notificationService.success('Dates successfully set', 'Success');
        this.activeModal.close(true);
      })
      .catch((err) => {
        this.activeModal.close(false);
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      });
  }

  getDateStartString(field: string): string {
    let date = new Date(this.datesForm.get(field).value);
    let dateStr = date.getFullYear().toString();
    let month = date.getMonth() + 1;

    if (month < 10) {
      dateStr += '-0' + month.toString();
    } else {
      dateStr += '-' + month.toString();
    }

    let day = date.getDate();

    if (day < 10) {
      dateStr += '-0' + day.toString();
    } else {
      dateStr += '-' + day.toString();
    }

    dateStr += 'T00:00:00.000Z';

    return dateStr;
  }

  getDateEndString(): string {
    let date = new Date(this.datesForm.get('month').value);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    let dateStr = lastDay.getFullYear().toString();
    let month = lastDay.getMonth() + 1;
    if (month < 10) {
      dateStr += '-0' + month.toString();
    } else {
      dateStr += '-' + month.toString();
    }
    let day = lastDay.getDate();
    if (day < 10) {
      dateStr += '-0' + day.toString();
    } else {
      dateStr += '-' + day.toString();
    }

    dateStr += 'T23:59:59.000Z';

    return dateStr;
  }
}
