<nb-card>
  <nb-card-body>
    <div class="row mb-3">
      <div class="col-1">
        <label class="mt-2">Contract: </label>
      </div>
      <div class="col-6">
        <p-dropdown
          (onHide)="getDiscounts()"
          [options]="contractsSelector"
          [appendTo]="'body'"
          baseZIndex="999"
          [disabled]="contractsSelector[0].code === null"
          [(ngModel)]="selectedContract"
          optionLabel="label"
          filterBy="label"
        >
          <ng-template pTemplate="selectedItem">
            <div *ngIf="selectedContract">
              <div>{{ selectedContract?.label }}</div>
            </div>
          </ng-template>
          <ng-template let-ci pTemplate="ci">
            <div>
              <div>{{ ci.label }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
  </nb-card-body>
</nb-card>

<nb-card>
  <nb-card-header> Discounts </nb-card-header>

  <nb-card-body>
    <div *ngIf="isEditor" class="mb-3">
      <p-button label="Create" icon="pi pi-plus" (onClick)="onCreate()"></p-button>
    </div>
    <p-table
      #dt
      [value]="discountsLoadSource"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      styleClass="p-datatable-gridlines p-datatable-striped"
      [tableStyle]="{ 'min-width': '50rem' }"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
      [loading]="isLoading"
      loadingIcon="pi pi-spin pi-spinner"
      [resizableColumns]="true"
      columnResizeMode="expand"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let head of header">
            {{ head.label }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-discount>
        <tr>
          <td>{{ discount.id }}</td>
          <td>{{ discount.startDate | date: 'dd/MM/yyyy' }}</td>
          <td>{{ discount.endDate | date: 'dd/MM/yyyy' }}</td>
          <td>{{ discount.concept }}</td>
          <td>{{ discount.amount }}</td>
          <td>{{ discount.account }}</td>
          <td>{{ discount.department }}</td>
          <td>
            <div class="flex align-items-center justify-content-center gap-2">
              <button
                pButton
                pRipple
                type="button"
                icon="pi {{ isEditor ? 'pi-pencil' : 'pi-search' }}"
                (click)="onEdit(discount)"
                class="p-button-rounded p-button-text"
              ></button>
              <button
                [disabled]="!isEditor"
                pButton
                pRipple
                type="button"
                icon="pi pi-times"
                (click)="onDelete(discount)"
                class="p-button-rounded p-button-text"
              ></button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="15" class="text-center">No Discounts found.</td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>
