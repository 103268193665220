import { NotFoundComponent } from './../../../../@core/shared/not-found/not-found.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BillingAgreementsComponent } from './agreements.component';
import { BillingAgreementsCommercialTemplateTableComponent } from './commercial-templates/commercial-templates-table/commercial-templates-table.component';
import { BillingAgreementsCommercialByPartnerTableComponent } from './commercial-bypartner/commercial-bypartner-table/commercial-bypartner-table.component';

const routes: Routes = [
  {
    path: '',
    component: BillingAgreementsComponent,
    children: [
      {
        path: 'templates',
        component: BillingAgreementsCommercialTemplateTableComponent,
      },
      {
        path: 'partners',
        component: BillingAgreementsCommercialByPartnerTableComponent,
      },
      {
        path: '',
        redirectTo: 'templates',
        pathMatch: 'full',
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AgreementsRoutingModule {}
