import { gql } from 'apollo-angular';

export const updateHubUser = gql`
  mutation ($input: UpdateHubUserInput!) {
    admin {
      updateHubUser(input: $input) {
        code
        adviseMessage {
          code
          description
          level
        }
        code
        hubUserData {
          code
          name
          password
          isActive
          giataActive
          giataConfiguration
          owner {
            code
          }
        }
      }
    }
  }
`;
