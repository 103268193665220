import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ModuleType } from '../../../../@core/interfaces/_index.interfaces';
import { ProfileNetworkService } from '../../services/profile-network.service';
import { WebAdminService } from '../../../../@core/services/_index-core.services';
import { OrganizationNetworkService } from '../../services/organization-network.service';

@Component({
  selector: 'tgx-dashboard-profiles-with-org',
  templateUrl: './dashboard-profiles-with-org.component.html',
  styleUrls: ['./dashboard-profiles-with-org.component.scss'],
})
export class DashboardProfilesWithOrgComponent implements OnInit {
  totalPrfs = 0;
  totalPrfWithOrgs = 0;
  filteredPrfList: any[] = [];
  totalPrfsWithOrgsNotInList = 0;

  mapOrgs: any[] = [];
  mapPrfsWithOrgs: any[] = [];

  totalPrfsWrong = 0;

  constructor(
    private router: Router,
    private webAdminService: WebAdminService,
    private profileNetworkService: ProfileNetworkService,
    private organizationNetworkService: OrganizationNetworkService,
  ) {}

  ngOnInit(): void {
    this.webAdminService.emitUserTypevalue(ModuleType.DASHBOARD);

    this.getProfilesWithoutOrg();
  }

  async getProfilesWithoutOrg() {
    const promises = [];
    promises.push(this.organizationNetworkService.getOrganizationsWidget());
    promises.push(this.profileNetworkService.getProfilesWidget());
    const results = await Promise.all(promises);

    if (!results[0].error && !results[1]?.error) {
      this.mapOrgs = results[0].data;
      this.mapPrfsWithOrgs = results[1].data;
      this.totalPrfs = results[1].total;
      this.totalPrfWithOrgs = results[1].total - results[1].mapPrfsNoOrgs.size;

      this.filteredPrfList = this.organizationNetworkService.filteredOrgs(this.mapOrgs, this.mapPrfsWithOrgs, 'prfs');
      this.totalPrfsWithOrgsNotInList = this.filteredPrfList.length;

      this.totalPrfsWrong = this.totalPrfs - (this.totalPrfsWithOrgsNotInList + results[1].mapPrfsNoOrgs.size);
    } else {
      // TODO: Error!
      if (results[0].error) console.log();
      if (results[1].error) console.log();
    }
  }

  getValueProfiles() {
    if (this.totalPrfsWrong > 0) {
      return Math.floor((this.totalPrfsWrong * 100) / this.totalPrfs);
    }
    return 0;
  }

  goToPrfsTable() {
    this.profileNetworkService.setProfilesCodes(this.filteredPrfList);
    this.router.navigate(['/network/profiles']);
  }
}
