import { Injectable } from '@angular/core';
import {
  ContractConnection,
  ContractConnectionWhereInput,
  ContractConnectionCreateInput,
  ObjectWhereUniqueInputById,
  ContractConnectionUpdateInput,
  ContractConnectionCloneInput,
} from './../interfaces/_index.billing.interfaces';
import { GraphqlApiBillingMutations } from './mutations/_index-billing-mutations';
import { GraphqlApiBillingQueries } from './queries/_index-billing-queries';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class ContractConnectionService {
  constructor(private apollo: Apollo) {}

  async getContractConnections(contractId: number): Promise<ContractConnection[]> {
    const where: ObjectWhereUniqueInputById = { id: contractId };

    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .watchQuery<any>({
          query: GraphqlApiBillingQueries.getContractConnections,
          variables: { where: where },
          fetchPolicy: 'network-only',
        })
        .valueChanges.subscribe((res: any) => {
          if (res?.data) {
            resolve(((res || {}).data || {}).contractconnectionsExtended);
          } else {
            reject(res);
          }
        });
    });
  }

  async getContractConnectionsByBuyerAndSeller(buyer: number, seller: number): Promise<ContractConnection[]> {
    const where: ContractConnectionWhereInput = {
      Connection: {
        is: {
          buyer: { equals: buyer },
          seller: { equals: seller },
        },
      },
    };

    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .watchQuery<any>({
          query: GraphqlApiBillingQueries.getContractConnectionsByBuyerAndSeller,
          variables: { where: where },
          fetchPolicy: 'network-only',
        })
        .valueChanges.subscribe((res: any) => {
          if (res?.data) {
            resolve(((res || {}).data || {}).contractconnections);
          } else {
            reject(res);
          }
        });
    });
  }

  async getContractConnectionsByIds(ids: number[]): Promise<ContractConnection[]> {
    const where: ContractConnectionWhereInput = { id: { in: ids } };

    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .watchQuery<any>({
          query: GraphqlApiBillingQueries.getContractConnectionByIds,
          variables: { where: where },
          fetchPolicy: 'network-only',
        })
        .valueChanges.subscribe((res: any) => {
          if (res?.data) {
            resolve(((res || {}).data || {}).contractconnections);
          } else {
            reject(res);
          }
        });
    });
  }

  async createConnection(create: ContractConnectionCreateInput): Promise<ContractConnection> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.createContractConnection,
          variables: { data: create },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).createContractConnection);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }

  async updateConnection(
    update: ContractConnectionUpdateInput,
    where: ObjectWhereUniqueInputById,
  ): Promise<ContractConnection> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.updateContractConnection,
          variables: { data: update, where: where },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).updateContractConnection);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }

  async deleteConnection(where: ObjectWhereUniqueInputById): Promise<ContractConnection> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.deleteContractConnection,
          variables: { where: where },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).deleteContractConnection);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }

  async cloneConnection(
    clone: ContractConnectionCloneInput,
    where: ObjectWhereUniqueInputById,
  ): Promise<ContractConnection> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.cloneContractConnection,
          variables: { data: clone, where: where },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).cloneContractConnection);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }
}
