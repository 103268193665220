import { NotFoundComponent } from './../../@core/shared/not-found/not-found.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { ContentComponent } from './content.component';

const routes: Routes = [
  {
    path: '',
    component: ContentComponent,
    children: [
      {
        path: 'scheduler',
        loadChildren: () => import('./modules/scheduler/scheduler.module').then((m) => m.SchedulerModule),
      },
      {
        path: '',
        redirectTo: 'scheduler',
        pathMatch: 'full',
      },
      {
        path: 'enqueuer',
        loadChildren: () => import('./modules/enqueuer/enqueuer.module').then((m) => m.EnqueuerModule),
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContentRoutingModule {}
