import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../../../@core/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GroupContentContextTableComponent } from './components/group-content-context-table/group-content-context-table.component';
import { GroupContentContextComponent } from './components/group-content-context.component';
import { GroupContentContextRoutingModule } from './group-content-context-routing.module';
import { GroupContentContextModalComponent } from './components/group-content-context-modal/group-content-context-modal.component';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';

import { NbActionsModule, NbCardModule, NbCheckboxModule, NbIconModule, NbToggleModule } from '@nebular/theme';

@NgModule({
  declarations: [GroupContentContextTableComponent, GroupContentContextComponent, GroupContentContextModalComponent],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NbActionsModule,
    NbCardModule,
    NbCheckboxModule,
    NbIconModule,
    GroupContentContextRoutingModule,
    NbToggleModule,
    TableModule,
    ButtonModule,
  ],
})
export class GroupContentContextModule {}
