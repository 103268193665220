import { Inject, Injectable } from '@angular/core';
import {
  Invoice,
  InvoiceWhereInput,
  ObjectWhereUniqueInputById,
  InvoiceUpdateInput,
  ConciliateData,
  NewPricingConciliateData,
} from './../interfaces/_index.billing.interfaces';
import { GraphqlApiBillingMutations } from './mutations/_index-billing-mutations';
import { GraphqlApiBillingQueries } from './queries/_index-billing-queries';
import { Apollo } from 'apollo-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  urlBillingCalculation: string;

  constructor(
    @Inject('urlBillingCalculation') urlBillingCalculation: string,
    private apollo: Apollo,
    private http: HttpClient,
  ) {
    this.urlBillingCalculation = urlBillingCalculation;
  }

  async getInvoices(contractId: number): Promise<Invoice[]> {
    const where: InvoiceWhereInput = { contract: { equals: contractId } };

    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .watchQuery<any>({
          query: GraphqlApiBillingQueries.getInvoices,
          variables: { where: where },
          fetchPolicy: 'network-only',
        })
        .valueChanges.subscribe((res: any) => {
          if (res?.data) {
            resolve(((res || {}).data || {}).invoices);
          } else {
            reject(res);
          }
        });
    });
  }

  async updateInvoice(data: InvoiceUpdateInput, where: ObjectWhereUniqueInputById): Promise<Invoice> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.updateInvoice,
          variables: { data: data, where: where },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).updateInvoice);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }

  async setVisibleInvoices(date: String): Promise<boolean> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.setInvoicesVisible,
          variables: { startDate: date },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).setVisibleInvoices);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }

  async deleteInvoice(where: ObjectWhereUniqueInputById): Promise<Invoice> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.deleteInvoice,
          variables: { where: where },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).deleteInvoice);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }

  async createInvoiceManually(
    partner: number,
    contractId: number,
    billingType: string,
    billingCharge: string,
    startDate: string,
    endDate: string,
    concept: string,
    amount: number,
    contractConnectionIds: string,
  ): Promise<any> {
    let url =
      this.urlBillingCalculation +
      `invoices/create?partnerId=${partner.toString()}&contractId=${contractId.toString()}&billingType=${billingType}&startBillDate=${startDate}&endBillDate=${endDate}&csvType=Odoo`;

    switch (billingType) {
      case 'billing_process':
        url += `&billingCharge=${billingCharge}`;
        break;
      case 'free':
        url += `&concept=${concept}&amount=${amount.toFixed(2)}`;
        break;
    }

    if (contractConnectionIds) {
      url += `&contractConnectionIds=${contractConnectionIds}`;
    }

    const headers = new HttpHeaders({ Accept: 'text/csv' });
    const options = { headers, responseType: 'text' as any };

    return await new Promise((resolve, reject) => {
      this.http.post(url, null, options).subscribe(
        (res: any) => {
          resolve(new Blob([res], { type: 'text/csv' }));
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  async legacyConciliateInvoice(
    partner: number,
    contractId: number,
    startDate: string,
    endDate: string,
    rq: ConciliateData,
  ): Promise<any> {
    const url =
      this.urlBillingCalculation +
      `invoices/legacyConciliate?partnerId=${partner.toString()}&contractId=${contractId.toString()}&startBillDate=${startDate}&endBillDate=${endDate}&csvType=Odoo`;

    const headers = new HttpHeaders({ Accept: 'text/csv' });
    const options = { headers, responseType: 'text' as any };

    return await new Promise((resolve, reject) => {
      this.http.post(url, rq, options).subscribe(
        (res: any) => {
          resolve(new Blob([res], { type: 'text/csv' }));
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  async newPricingConciliateInvoice(
    partner: number,
    contractId: number,
    startDate: string,
    endDate: string,
    rq: NewPricingConciliateData,
  ): Promise<any> {
    const url =
      this.urlBillingCalculation +
      `invoices/newPricingConciliate?partnerId=${partner.toString()}&contractId=${contractId.toString()}&startBillDate=${startDate}&endBillDate=${endDate}&csvType=Odoo`;

    const headers = new HttpHeaders({ Accept: 'text/csv' });
    const options = { headers, responseType: 'text' as any };

    return await new Promise((resolve, reject) => {
      this.http.post(url, rq, options).subscribe(
        (res: any) => {
          resolve(new Blob([res], { type: 'text/csv' }));
        },
        (err) => {
          reject(err);
        },
      );
    });
  }
}
