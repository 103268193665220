import { gql } from 'apollo-angular';

export const removeMemberFromOrganization = gql`
  mutation RemoveMemberFromOrganization($where: MemberToOrganizationInput!) {
    admin {
      removeMemberFromOrganization(where: $where) {
        code
        adviseMessage {
          code
          description
        }
      }
    }
  }
`;
