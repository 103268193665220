import { Table } from 'primeng/table';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  ChangePaymentRequest,
  Contract,
  Partner,
  PartnerModelType,
  ContractConnection,
  ConnectionType,
  ConnectionFullInfo,
} from '../../../../interfaces/_index.billing.interfaces';
import {
  PartnerService,
  ContractService,
  ChangePaymentRequestService,
  ContractConnectionService,
  ConnectionService,
} from '../../../../services/_index-billing.services';
import { NotificationService, WebAdminService } from './../../../../../../@core/services/_index-core.services';
import { OrganizationSelector } from './../../../../../entities/interfaces/_index-entities.interfaces';
import { Options } from './../../../../../../@core/interfaces/_index.interfaces';

@Component({
  selector: 'tgx-admin-billing-payment-requests-contract',
  templateUrl: './payment-requests-contract.component.html',
  styleUrls: ['./payment-requests-contract.component.scss'],
})
export class BillingPaymentRequestsContractComponent implements OnInit, OnDestroy {
  @ViewChild('dt1', { static: false }) dataTable1!: Table;
  @ViewChild('dt2', { static: false }) dataTable2!: Table;

  $subs: Subscription[] = [];

  header = [
    { label: 'ID', field: 'id' },
    { label: 'ccId', field: 'contractConnectionId' },
    { label: 'Buyer', field: 'buyer' },
    { label: 'Seller', field: 'seller' },
    { label: 'RateType', field: 'currentConnectionType' },
    { label: 'NextRateType', field: 'requestedConnectionType' },
    { label: 'Status', field: 'status' },
    { label: 'RequestUser', field: 'requestUser' },
    { label: 'ResponseUser', field: 'responseUser' },
    { label: 'CreatedAt', field: 'createdAt' },
    { label: 'UpdatedAt', field: 'updatedAt' },
  ];

  requestSendLoadSource: any[];

  requestRecievedLoadSource: any[];

  contracts: Contract[];

  isLoading: boolean;

  orgSelected: OrganizationSelector;
  partner: Partner;

  contractsSelector: Options[] = [{ code: null, label: 'Without CM23 contracts' }];
  selectedContract: Options;

  isEditor = false;

  constructor(
    private notificationService: NotificationService,
    private webAdminService: WebAdminService,
    private partnerService: PartnerService,
    private contractService: ContractService,
    private changePaymentRequestService: ChangePaymentRequestService,
    private contractConnectionService: ContractConnectionService,
    private connectionService: ConnectionService,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;

    this.$subs.push(
      this.webAdminService.orgSelected.subscribe(async (orgSelected) => {
        if (orgSelected?.code) {
          this.orgSelected = orgSelected;
          await this.partnerService.getPartner(orgSelected.code).then((partner) => {
            if (partner) {
              this.partner = partner;
              this.getContracts(this.partner.orgCode);
            } else {
              this.partner = null;
              this.isLoading = false;
            }
          });
        }
      }),
    );
  }

  ngOnDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
  }

  getContracts(orgCode: string) {
    if (orgCode) {
      this.contractService
        .getContracts(orgCode)
        .then((cnts) => {
          this.contracts = cnts;
          if (this.contracts?.length > 0) {
            this.contractsSelector = [];
            this.contracts = cnts.filter((x) => x.modelType === PartnerModelType.CM23);
            this.buildSelector();
            if (this.contracts?.length > 0) {
              this.selectedContract = this.contractsSelector[0];
              this.getRequests();
            } else {
              this.withoutContracts();
            }
          } else {
            this.withoutContracts();
          }
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  buildSelector() {
    if (this.contracts?.length > 0) {
      this.contracts.forEach((c) => {
        this.contractsSelector.push({
          code: c.id.toString(),
          label:
            '(' +
            c.id +
            ') ' +
            c.partnerType +
            (c.modelType ? ' - ' + c.modelType : '') +
            (c.ContractType ? ' - ' + c.ContractType.name : ''),
        });
      });
    }
  }

  withoutContracts() {
    this.contractsSelector = [];
    this.contractsSelector.push({ code: null, label: 'Without CM23 contracts' });
  }

  async getRequests() {
    this.isLoading = true;
    this.requestSendLoadSource = [];
    this.requestRecievedLoadSource = [];

    if (this.dataTable1) {
      this.dataTable1.clearFilterValues();
    }

    if (this.dataTable2) {
      this.dataTable2.clearFilterValues();
    }

    const ccIds: number[] = [];
    let recieved: ChangePaymentRequest[] = [];
    let send: ChangePaymentRequest[] = [];

    if (this.selectedContract?.code) {
      await this.changePaymentRequestService
        .getRequests(Number(this.selectedContract?.code), true)
        .then((rs) => {
          send = rs;
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
          this.isLoading = false;
          return;
        });

      await this.changePaymentRequestService
        .getRequests(Number(this.selectedContract?.code), false)
        .then((rs) => {
          recieved = rs;
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
          this.isLoading = false;
          return;
        });

      send.forEach((con) => {
        const index = ccIds.findIndex((x) => x === con.contractConnectionIdFrom);
        if (index === -1) {
          ccIds.push(con.contractConnectionIdFrom);
        }
      });

      recieved.forEach((con) => {
        const index = ccIds.findIndex((x) => x === con.contractConnectionIdTo);
        if (index === -1) {
          ccIds.push(con.contractConnectionIdTo);
        }
      });

      const mapCC: Map<number, ContractConnection> = new Map<number, ContractConnection>();

      const connectionIds: number[] = [];

      if (ccIds.length > 0) {
        await this.contractConnectionService
          .getContractConnectionsByIds(ccIds)
          .then((rs) => {
            if (rs.length > 0) {
              rs.forEach((cc) => {
                mapCC.set(cc.id, cc);

                const index = connectionIds.findIndex((x) => x === cc.connection);
                if (index === -1) {
                  connectionIds.push(cc.connection);
                }
              });
            }
          })
          .catch((err) => {
            this.notificationService.handleGatewayAndGraphqlErrors(err);
            this.isLoading = false;
            return;
          });
      }

      const mapConnections: Map<number, ConnectionFullInfo> = new Map<number, ConnectionFullInfo>();

      if (connectionIds.length > 0) {
        await this.connectionService
          .getConnectionsFullInfoById(connectionIds)
          .then((rs) => {
            rs?.forEach((cn) => {
              if (!mapConnections.has(cn.id)) {
                mapConnections.set(cn.id, cn);
              }
            });
          })
          .catch((err) => {
            this.notificationService.handleGatewayAndGraphqlErrors(err);
            this.isLoading = false;
          });
      }

      send.forEach((con) => {
        if (mapCC.has(con.contractConnectionIdFrom)) {
          let buyer = '';
          let seller = '';

          if (mapConnections.has(mapCC.get(con.contractConnectionIdFrom).connection)) {
            const connection = mapConnections.get(mapCC.get(con.contractConnectionIdFrom).connection);
            buyer = `${connection.buyerName} (${connection.buyerCode})`;
            seller = `${connection.sellerName} (${connection.sellerCode})`;
          }

          this.requestSendLoadSource.push({
            'id': con.id,
            'contractConnectionId': con.contractConnectionIdFrom,
            'buyer': buyer,
            'seller': seller,
            'currentConnectionType': con.currentConnectionType,
            'requestedConnectionType': con.requestedConnectionType,
            'status': con.status,
            'requestUser': con.requestUser,
            'responseUser': con.responseUser,
            'createdAt': con.createdAt,
            'updatedAt': con.updatedAt,
          });
        }
      });
      this.requestSendLoadSource = [...this.requestSendLoadSource];
      this.requestSendLoadSource.sort((a, b) => (a.id < b.id ? 1 : -1));

      recieved.forEach((con) => {
        if (mapCC.has(con.contractConnectionIdTo)) {
          let buyer = '';
          let seller = '';

          if (mapConnections.has(mapCC.get(con.contractConnectionIdTo).connection)) {
            const connection = mapConnections.get(mapCC.get(con.contractConnectionIdTo).connection);
            buyer = `${connection.buyerName} (${connection.buyerCode})`;
            seller = `${connection.sellerName} (${connection.sellerCode})`;
          }

          this.requestRecievedLoadSource.push({
            'id': con.id,
            'contractConnectionId': con.contractConnectionIdTo,
            'buyer': buyer,
            'seller': seller,
            'currentConnectionType': con.currentConnectionType,
            'requestedConnectionType': this.getReverseConnectionType(con.requestedConnectionType),
            'status': con.status,
            'requestUser': con.requestUser,
            'responseUser': con.responseUser,
            'createdAt': con.createdAt,
            'updatedAt': con.updatedAt,
          });
        }
      });
      this.requestRecievedLoadSource = [...this.requestRecievedLoadSource];
      this.requestRecievedLoadSource.sort((a, b) => (a.id < b.id ? 1 : -1));
      this.isLoading = false;
    } else {
      this.isLoading = false;
    }
  }

  private getReverseConnectionType(type: ConnectionType): ConnectionType {
    switch (type) {
      case ConnectionType.FREE:
        return ConnectionType.STANDARD_SUPPLEMENT;
      case ConnectionType.STANDARD:
        return ConnectionType.STANDARD;
      case ConnectionType.STANDARD_SUPPLEMENT:
        return ConnectionType.FREE;
    }
  }

  filterDT1(value: any, field: string) {
    if (value) {
      this.dataTable1.filter(value, field, 'contains');
    }
  }

  filterDT2(value: any, field: string) {
    if (value) {
      this.dataTable2.filter(value, field, 'contains');
    }
  }
}
