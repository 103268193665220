import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxEchartsModule } from 'ngx-echarts';
import { SharedModule } from '../../@core/shared/shared.module';
import { DashboardComponent } from './components/dashboard.component';
import { NbCardModule, NbProgressBarModule } from '@nebular/theme';
import { DashboardPartnersWithCrmIdComponent } from './components/dashboard-partners-with-crmid/dashboard-partners-with-crmid.component';
import { DashboardOrgsWithProfileComponent } from './components/dashboard-orgs-with-profile/dashboard-orgs-with-profile.component';
import { DashboardProfilesWithOrgComponent } from './components/dashboard-profiles-with-org/dashboard-profiles-with-org.component';
import { NotificationService } from './services/notification.service';
import { ProfileNetworkService } from './services/profile-network.service';
import { OrganizationNetworkService } from './services/organization-network.service';
import { DashboardRoutingModule } from '../dashboard/dashboard-routing.module';

@NgModule({
  declarations: [
    DashboardComponent,
    DashboardPartnersWithCrmIdComponent,
    DashboardOrgsWithProfileComponent,
    DashboardProfilesWithOrgComponent,
  ],
  exports: [DashboardComponent],
  imports: [
    FormsModule,
    SharedModule,
    NbCardModule,
    CommonModule,
    NbProgressBarModule,
    DashboardRoutingModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ],
  providers: [NotificationService, ProfileNetworkService, OrganizationNetworkService],
})
export class DashboardModule {}
