import { GraphqlApiBillingMutations } from './mutations/_index-billing-mutations';
import {
  Partner,
  PartnerCreateInput,
  PartnerWhereUniqueInput,
  PartnerUpdateInput,
} from './../interfaces/_index.billing.interfaces';
import { Injectable } from '@angular/core';

import { GraphqlApiBillingQueries } from './queries/_index-billing-queries';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class PartnerService {
  constructor(private apollo: Apollo) {}

  async getPartner(orgCode: string): Promise<Partner> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .watchQuery<any>({
          query: GraphqlApiBillingQueries.getPartner,
          variables: { where: { orgCode: orgCode.toUpperCase() } },
          fetchPolicy: 'network-only',
        })
        .valueChanges.subscribe((res: any) => {
          if (res && res.data) {
            resolve(((res || {}).data || {}).partner);
          } else {
            reject(res);
          }
        });
    });
  }

  async getAllPartnersWithSomeContract(): Promise<Partner[]> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .watchQuery<any>({
          query: GraphqlApiBillingQueries.getPartnersWithSomeContract,
          fetchPolicy: 'network-only',
        })
        .valueChanges.subscribe((res: any) => {
          if (res && res.data) {
            resolve(((res || {}).data || {}).partnersWithSomeContract);
          } else {
            reject(res);
          }
        });
    });
  }

  async getAllPartners(): Promise<Partner[]> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .watchQuery<any>({
          query: GraphqlApiBillingQueries.getPartners,
          fetchPolicy: 'network-only',
        })
        .valueChanges.subscribe((res: any) => {
          if (res && res.data) {
            resolve(((res || {}).data || {}).partners);
          } else {
            reject(res);
          }
        });
    });
  }

  async getPartnersByIds(ids: number[]): Promise<Partner[]> {
    const where = { id: { in: ids } };

    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .watchQuery<any>({
          query: GraphqlApiBillingQueries.getPartners,
          variables: { where: where },
          fetchPolicy: 'network-only',
        })
        .valueChanges.subscribe((res: any) => {
          if (res && res.data) {
            resolve(((res || {}).data || {}).partners);
          } else {
            reject(res);
          }
        });
    });
  }

  async createPartner(input: PartnerCreateInput): Promise<Partner> {
    return await new Promise((resolve, reject) => {
      return this.apollo
        .use('billing_api')
        .mutate<any>({
          mutation: GraphqlApiBillingMutations.createPartner,
          variables: { data: input },
        })
        .subscribe(
          (res: any) => {
            if (res?.data) {
              resolve(((res || {}).data || {}).createPartner);
            }
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }

  async updatePartner(data: PartnerUpdateInput, where: PartnerWhereUniqueInput): Promise<Partner> {
    return await new Promise((resolve, reject) => {
      return this.apollo
        .use('billing_api')
        .mutate<any>({
          mutation: GraphqlApiBillingMutations.updatePartner,
          variables: { data: data, where: where },
        })
        .subscribe(
          (res: any) => {
            if (res?.data) {
              resolve(((res || {}).data || {}).updatePartner);
            }
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }
}
