<nb-card>
  <nb-card-header>
    <div class="row">
      <div class="org-container">
        <h3>Clients</h3>
        <h1 class="small-text">({{ totalClients }})</h1>
      </div>
      <div class="d-flex justify-content-end">
        <form [formGroup]="queryForm">
          <div class="d-flex align-items-center">
            <span class="p-2">Show archived</span>
            <nb-toggle [checked]="false" formControlName="includeArchived" (change)="retrieveClients()"></nb-toggle>
          </div>
        </form>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <p-table
      #dt1
      *ngIf="!isLoading"
      [value]="clientsLoadSource"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      [tableStyle]="{ 'min-width': '50rem' }"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
      styleClass="p-datatable-gridlines p-datatable-striped"
    >
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="code">
            Name(Code)
            <p-sortIcon field="code"></p-sortIcon>
          </th>
          <th pSortableColumn="ownerCode">
            Owner Code
            <p-sortIcon field="ownerCode"></p-sortIcon>
          </th>
          <th pSortableColumn="groupCode">
            Group Code
            <p-sortIcon field="groupCode"></p-sortIcon>
          </th>
          <th pSortableColumn="isActive">
            Active
            <p-sortIcon field="isActive"></p-sortIcon>
          </th>
          <th pSortableColumn="deletedAt">
            Archived
            <p-sortIcon field="deletedAt"></p-sortIcon>
          </th>
          <th>Options</th>
        </tr>
        <tr>
          <th>
            <p-columnFilter
              [showClearButton]="false"
              placeholder="Name(Code)"
              type="text"
              field="code"
              matchMode="contains"
              (input)="dt1.filter($event.target.value, 'code', 'contains')"
            ></p-columnFilter>
          </th>
          <th>
            <p-columnFilter
              [showClearButton]="false"
              placeholder="Owner Code"
              type="text"
              field="ownerCode"
              matchMode="contains"
              (input)="dt1.filter($event.target.value, 'ownerCode', 'contains')"
            ></p-columnFilter>
          </th>
          <th>
            <p-columnFilter
              [showClearButton]="false"
              placeholder="Group Code"
              type="text"
              field="groupCode"
              matchMode="contains"
              (input)="dt1.filter($event.target.value, 'groupCode', 'contains')"
            ></p-columnFilter>
          </th>
          <th>
            <p-columnFilter [showClearButton]="false" type="boolean" field="isActive"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter [showClearButton]="false" type="boolean" field="deletedAt"></p-columnFilter>
          </th>
          <th width="3rem"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-clients>
        <tr>
          <td>{{ clients.code }}</td>
          <td>{{ clients.ownerCode }}</td>
          <td>{{ clients.groupCode }}</td>
          <td>
            <p-inputSwitch
              [disabled]="clients.deletedAt"
              (onChange)="onToggleActivateDeactiveClient(clients)"
              [(ngModel)]="clients.isActiveModel"
            ></p-inputSwitch>
          </td>
          <td>
            <p-inputSwitch
              (onChange)="onToggleArchiveDearchiveClient(clients)"
              [(ngModel)]="clients.isArchived"
            ></p-inputSwitch>
          </td>
          <td>
            <i class="nb-collapse icon mx-2" title="Legacy Connections" (click)="onLegacyConnections(clients)"></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
  <div *ngIf="isLoading">
    <tgx-spinner [class]="'routingLoading'" [isLoading]="isLoading" [top]="'100px'"></tgx-spinner>
  </div>
</nb-card>
