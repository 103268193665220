export const CompleteSettings = {
  rowClassFunction: (row) => {
    if (
      row.data.schedulerStatus === 'Ok' ||
      row.data.schedulerStatus === 'Success' ||
      row.data.schedulerStatus === 'OperationNotImplemented' ||
      row.data.schedulerStatus === 'Completed'
    ) {
      return 'ok-row';
    } else if (row.data.schedulerStatus === 'Warning') {
      return 'warning-row';
    } else if (row.data.schedulerStatus === 'Minorwarning' || row.data.schedulerStatus === 'Minorwarning') {
      return 'minor-warning-row';
    } else {
      return 'ko-row';
    }
  },
  edit: {
    editButtonContent: '<i title="Kibana Link" class="nb-arrow-retweet"></i>',
  },
  delete: {
    deleteButtonContent: '<i title="View Details" class="nb-search"></i>',
  },
  mode: 'external',
  actions: {
    columnTitle: 'Options',
    position: 'right',
    edit: true,
    add: false,
    delete: true,
  },
  columns: {
    serviceOperation: {
      title: 'Service Operation',
      sort: false,
      filter: {
        type: 'list',
        config: {
          selectText: 'All',
          list: [],
        },
      },
      filterFunction(cell: any, search?: string): boolean {
        return cell === search || search === 'All';
      },
    },
    accessCode: {
      title: 'Access',
      type: 'string',
    },
    supplier: {
      title: 'Supplier',
      type: 'string',
    },
    client: {
      title: 'Client',
      type: 'string',
    },
    schedulerStatus: {
      title: 'Status',
      sort: false,
      filter: {
        type: 'list',
        config: {
          selectText: 'All',
          list: [],
        },
      },
      filterFunction(cell: any, search?: string): boolean {
        return cell === search || search === 'All';
      },
    },
    adviseMessages: {
      title: 'Integration Error',
      type: 'string',
      valuePrepareFunction: (value, _row, _cell) => {
        let description = 'None';

        if (value?.length > 0) {
          description = value[0].description;
          if (description.includes(', check external for more details')) {
            const externalMessage = value[0].external?.message;
            description = description.replace(', check external for more details', ' : ' + externalMessage);
          }
        }
        return description;
      },
    },
    executedTime: {
      title: 'Executed Time',
      type: 'string',
    },
    executionTime: {
      title: 'Execution Time',
      type: 'string',
    },
    completeTime: {
      title: 'Finish Time',
      type: 'string',
    },
    enqueuedTime: {
      title: 'Time Added',
      type: 'string',
    },
    priority: {
      title: 'Priority',
      sort: false,
      filter: {
        type: 'list',
        config: {
          selectText: 'All',
          list: [],
        },
      },
      filterFunction(cell: any, search?: string): boolean {
        return cell === search || search === 'All';
      },
    },
    user: {
      title: 'User',
      type: 'string',
    },
  },
};

export const ExecutingSettings = {
  edit: {
    editButtonContent: '<i title="Kibana Link" class="nb-arrow-retweet"></i>',
  },
  delete: {
    deleteButtonContent: '<i title="Abort Task" class="nb-close"></i>',
  },
  mode: 'external',
  actions: {
    columnTitle: 'Options',
    position: 'right',
    edit: true,
    add: false,
    delete: true,
  },
  columns: {
    serviceOperation: {
      title: 'Service Operation',
      type: 'string',
    },
    accessCode: {
      title: 'Access',
      type: 'string',
    },
    supplier: {
      title: 'Supplier',
      type: 'string',
    },
    client: {
      title: 'Client',
      type: 'string',
    },
    enqueuedTime: {
      title: 'Time Added',
      type: 'string',
    },
    executedTime: {
      title: 'Executed Time',
      type: 'string',
    },
    priority: {
      title: 'Priority',
      type: 'string',
    },
    user: {
      title: 'User',
      type: 'string',
    },
  },
};

export const EnqueuedSettings = {
  delete: {
    deleteButtonContent: '<i title="Cancel Task" class="nb-close"></i>',
  },
  mode: 'external',
  actions: {
    columnTitle: 'Cancel Task',
    position: 'right',
    edit: false,
    add: false,
    delete: true,
  },
  columns: {
    serviceOperation: {
      title: 'Service Operation',
      type: 'string',
    },
    accessCode: {
      title: 'Access',
      type: 'string',
    },
    supplier: {
      title: 'Supplier',
      type: 'string',
    },
    client: {
      title: 'Client',
      type: 'string',
    },
    enqueuedTime: {
      title: 'Time Added',
      type: 'string',
    },
    priority: {
      title: 'Priority',
      type: 'string',
    },
    user: {
      title: 'User',
      type: 'string',
    },
  },
};

export const DataBaseSettings = {
  hideSubHeader: true,
  actions: false,
  columns: {
    status: {
      title: 'Status',
      type: 'string',
    },
    updated: {
      title: 'Updated',
      type: 'string',
    },
    notModified: {
      title: 'Not Modified',
      type: 'string',
    },
    new: {
      title: 'New',
      type: 'string',
    },
    deleted: {
      title: 'Deleted',
      type: 'string',
    },
    dbErrors: {
      title: 'DB Errors',
      type: 'string',
    },
  },
};

export const LanguageSettings = {
  hideSubHeader: true,
  actions: false,
  sort: false,
  columns: {},
};
