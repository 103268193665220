import { gql } from 'apollo-angular';

export const createMember = gql`
  mutation createMember($data: CreateMemberInput!) {
    admin {
      createMember(member: $data) {
        code
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
