import { CommonModule } from '@angular/common';
import { SharedModule } from './../../../../@core/shared/shared.module';
import { NgModule } from '@angular/core';
import { NbCardModule, NbCheckboxModule } from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BillingCostsComponent } from './costs.component';
import { BillingCostsTableComponent } from './cost/components/costs-table/costs-table.component';
import { BillingCostModalComponent } from './cost/components/cost-modal/cost-modal.component';
import { BillingL2BCostModalComponent } from './l2b-cost/components/l2b-cost-modal/l2b-cost-modal.component';
import { BillingCostsRoutingModule } from './costs-routing.module';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { BillingL2BCostsTableComponent } from './l2b-cost/components/l2b-costs-table/l2b-costs-table.component';
import { BillingL2BNewPricingCostsTableComponent } from './new-pricing-costs/components/new-pricing-costs-table/new-pricing-costs-table.component';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { BillingNewPricingCostModalComponent } from './new-pricing-costs/components/new-pricing-costs-modal/new-pricing-costs-modal.component';
import { BillingDefaultCostTableComponent } from './default-cost/components/default-cost-table/default-cost-table.component';
import { BillingDefaultCostModalComponent } from './default-cost/components/default-cost-modal/default-cost-modal.component';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';

@NgModule({
  imports: [
    TableModule,
    ButtonModule,
    CheckboxModule,
    SharedModule,
    BillingCostsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NbCardModule,
    CommonModule,
    NbCheckboxModule,
    DropdownModule,
    CalendarModule,
    TriStateCheckboxModule,
  ],
  declarations: [
    BillingCostsComponent,
    BillingCostsTableComponent,
    BillingCostModalComponent,
    BillingL2BCostsTableComponent,
    BillingL2BCostModalComponent,
    BillingL2BNewPricingCostsTableComponent,
    BillingNewPricingCostModalComponent,
    BillingDefaultCostTableComponent,
    BillingDefaultCostModalComponent,
  ],
})
export class CostModule {}
