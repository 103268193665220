import { gql } from 'apollo-angular';

export const updateBillingAccount = gql`
  mutation updateBillingAccount($data: BillingAccountUpdateInput!, $where: BillingAccountWhereUniqueInput!) {
    updateBillingAccount(data: $data, where: $where) {
      id
      name
      cif
      countryCode
      fiscalAddress
      createdAt
      updatedAt
      odooCode
      singleInvoice
    }
  }
`;
