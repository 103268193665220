import { Table } from 'primeng/table';
import { Component, OnInit, Output, ViewChild, EventEmitter, OnDestroy } from '@angular/core';
import { PreAndPostCheckService } from '../../../../../services/_index-billing.services';
import { NotificationService } from './../../../../../../../@core/services/_index-core.services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'tgx-admin-billing-invoicing-process-pre-first',
  templateUrl: './invoicing-process-pre-first.component.html',
  styleUrls: ['./invoicing-process-pre-first.component.scss'],
})
export class BillingInvoicingProcessPreFirstComponent implements OnInit, OnDestroy {
  @ViewChild('dt', { static: false }) dataTable!: Table;
  @Output() isValidStep: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  header = [
    { label: 'PartnerId', field: 'PartnerId' },
    { label: 'PartnerName', field: 'PartnerName' },
    { label: 'PartnerOrgCode', field: 'PartnerOrgCode' },
    { label: 'ContractId', field: 'ContractId' },
    { label: 'PartnerType', field: 'PartnerType' },
    { label: 'ModelType', field: 'ModelType' },
    { label: 'Buyer', field: 'Buyer' },
    { label: 'Seller', field: 'Seller' },
    { label: 'NumRepeated', field: 'NumRepeated' },
  ];

  source: any[];

  isLoading: boolean;

  $subs: Subscription[] = [];

  step = 1;
  constructor(
    private notificationService: NotificationService,
    private preAndPostCheckService: PreAndPostCheckService,
  ) {
    this.$subs.push(
      this.preAndPostCheckService.refreshPreStepPage.subscribe(async (refreshStep) => {
        if (refreshStep && refreshStep === this.step) {
          await this.getConnections();
        }
      }),
    );
  }

  async ngOnInit(): Promise<void> {
    this.$subs.push(
      this.preAndPostCheckService.currentPreStepPage.subscribe(async (currentStep) => {
        if (currentStep && currentStep === this.step) {
          await this.getConnections();
        }
      }),
    );
  }

  ngOnDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
  }

  async getConnections() {
    this.isLoading = true;
    this.source = [];
    const requests: any[] = [];

    await this.preAndPostCheckService
      .getDuplicatedConnectionsContract()
      .then((rs) => {
        if (rs.success) {
          rs.response?.forEach((cn) => {
            requests.push({
              'PartnerId': cn.PartnerId,
              'PartnerName': cn.PartnerName,
              'PartnerOrgCode': cn.PartnerOrgCode,
              'ContractId': cn.ContractId,
              'PartnerType': cn.PartnerType,
              'ModelType': cn.ModelType,
              'Buyer': `${cn.BuyerName} (${cn.BuyerCode})`,
              'Seller': `${cn.SellerName} (${cn.SellerCode})`,
              'NumRepeated': cn.NumRepeated,
            });
          });
        }
        this.source = [...requests];
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      })
      .finally(() => {
        this.isLoading = false;
        this.isValidStep.emit(this.source?.length === 0);
      });
  }
}
