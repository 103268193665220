import { CommonModule, DatePipe } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { InMemoryCache } from '@apollo/client/core';
import { StoreModule } from '@ngrx/store';
import {
  AuthService,
  PlatformAuthService,
  PlatformBrowserService,
  PlatformInfoService,
  PlatformSelectorOrganizationService,
} from '@tgx/shared/services';
import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { environment } from './../../../../../environments/environment';
import { BillingGuard, EntitiesGuard, NetworkGuard, BannerGuard } from './guards/_index.guards';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { CapitalizePipe, NumberWithCommasPipe, RoundPipe, TimingPipe } from './pipes/index_pipes';
import {
  JwtPermissionsService,
  MenuService,
  NotificationService,
  WebAdminService,
} from './services/_index-core.services';
import { GqlContextName } from '@tgx/shared/data-access/graphql';

const PIPES = [CapitalizePipe, RoundPipe, TimingPipe, NumberWithCommasPipe];

const GUARDS = [BillingGuard, EntitiesGuard, NetworkGuard, BannerGuard];

const SERVICES = [
  NotificationService,
  PlatformBrowserService,
  PlatformInfoService,
  PlatformAuthService,
  WebAdminService,
  MenuService,
  JwtPermissionsService,
  AuthService,
  DatePipe,
];

export const NB_CORE_PROVIDERS = [
  ...SERVICES,
  ...GUARDS,
  {
    provide: 'urlBillingApi',
    useValue: environment.apis.billing_api,
  },
  {
    provide: 'urlBillingCalculation',
    useValue: environment.apis.billing_calculation,
  },
  {
    provide: 'urlGateway',
    useValue: environment.apis.gateway,
  },
  {
    provide: 'urlContentApi',
    useValue: environment.apis.content,
  },
  {
    provide: 'urlNotificationsService',
    useValue: environment.apis.notifications_service,
  },
  {
    provide: 'urlTaskManager',
    useValue: environment.apis.task_manager,
  },
  PlatformSelectorOrganizationService,
];

@NgModule({
  imports: [CommonModule, StoreModule, ApolloModule],
  exports: [...PIPES],
  declarations: [...PIPES],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule,
    apollo: Apollo,
    httpLink: HttpLink,
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');

    const cache = new InMemoryCache({
      addTypename: false,
    });

    apollo.create({
      link: httpLink.create({ uri: environment.apis.gateway }),
      cache: cache,
    });

    apollo.createNamed(GqlContextName.GATEWAY_PUBLIC, {
      link: httpLink.create({ uri: environment.apis.gateway_public }),
      cache: cache,
    });

    apollo.createNamed(GqlContextName.GATEWAY, {
      link: httpLink.create({ uri: environment.apis.gateway }),
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              admin: {
                merge: true,
              },
            },
          },
        },
      }),
    });

    apollo.createNamed('data', {
      link: httpLink.create({ uri: environment.apis.data }),
      // link: httpLink.create({ uri:'http://localhost:8080/query'}),
      cache: cache,
    });

    apollo.createNamed(GqlContextName.BILLING, {
      link: httpLink.create({ uri: environment.apis.billing_api }),
      cache: cache,
    });

    apollo.createNamed(GqlContextName.SOCIAL, {
      link: httpLink.create({ uri: environment.apis.social }),
      cache: cache,
    });

    apollo.createNamed(GqlContextName.INSIGHTS, {
      link: httpLink.create({ uri: environment.apis.insights_api }),
      cache: cache,
    });
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [...NB_CORE_PROVIDERS],
    };
  }
}
