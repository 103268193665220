import { Injectable } from '@angular/core';
import {
  NewPricingCost,
  NewPricingCostWhereInput,
  NewPricingCostCreateInput,
  NewPricingCostUpdateInput,
  ObjectWhereUniqueInputById,
} from './../interfaces/_index.billing.interfaces';
import { GraphqlApiBillingQueries } from './queries/_index-billing-queries';
import { Apollo } from 'apollo-angular';
import { GraphqlApiBillingMutations } from './mutations/_index-billing-mutations';

@Injectable({
  providedIn: 'root',
})
export class NewPricingCostsService {
  constructor(private apollo: Apollo) {}

  async getNewPricingCosts(contractId: number): Promise<NewPricingCost[]> {
    const where: NewPricingCostWhereInput = { contract: { equals: contractId } };

    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .watchQuery<any>({
          query: GraphqlApiBillingQueries.getNewPricingCosts,
          variables: { where: where },
          fetchPolicy: 'network-only',
        })
        .valueChanges.subscribe((res: any) => {
          if (res?.data) {
            resolve(((res || {}).data || {}).newPricingCosts);
          } else {
            reject(res);
          }
        });
    });
  }

  async createNewPricingCost(create: NewPricingCostCreateInput): Promise<NewPricingCost> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.createNewPricingCost,
          variables: { data: create },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).createNewPricingCost);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }

  async updateNewPricingCost(
    update: NewPricingCostUpdateInput,
    where: ObjectWhereUniqueInputById,
  ): Promise<NewPricingCost> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.updateNewPricingCost,
          variables: { data: update, where: where },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).updateNewPricingCost);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }

  async deleteNewPricingCost(where: ObjectWhereUniqueInputById): Promise<NewPricingCost> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.deleteNewPricingCost,
          variables: { where: where },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).deleteNewPricingCost);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }
}
