import {
  BillingAccount,
  ObjectWhereUniqueInputById,
  BillingAccountCreateInput,
  BillingAccountUpdateInput,
} from './../../../../interfaces/_index.billing.interfaces';
import { NotificationService } from './../../../../../../@core/services/_index-core.services';
import { BillingAccountService, StaticBillingService } from './../../../../services/_index-billing.services';

import { Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { clone } from '../../../../../../@core/utils/utils';

@Component({
  selector: 'tgx-billing-account-modal',
  styleUrls: ['./billing-accounts-modal.component.scss'],
  templateUrl: 'billing-accounts-modal.component.html',
})
export class BillingAccountModalComponent implements OnInit, OnDestroy {
  @Input() billingAccount: BillingAccount = null;
  @Input() header: string;
  @Input() partnerId: number;
  @Input() isEditor: boolean;

  isLoading: boolean;
  isCreating: boolean;
  billingAccountAux: BillingAccount;

  public billingAccountForm: UntypedFormGroup;

  subscriptions: Subscription[] = [];

  countries: SelectItem[];
  countriesAutocomplete: SelectItem[];
  currentCountry: SelectItem;

  constructor(
    private notificationService: NotificationService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private staticService: StaticBillingService,
    private billingAccountService: BillingAccountService,
  ) {}

  ngOnInit() {
    this.buildCountries();

    this.currentCountry = { label: '', value: '' };

    this.isCreating = true;

    if (this.billingAccount) {
      this.isCreating = false;
      this.currentCountry = this.countries.filter((x) => x.value === this.billingAccount.countryCode)[0];
    }

    this.billingAccountForm = this.fb.group({
      id: [this.billingAccount ? this.billingAccount.id : ''],
      name: [this.billingAccount ? this.billingAccount.name : '', Validators.required],
      cif: [this.billingAccount ? this.billingAccount.cif : '', Validators.required],
      countryCode: [this.currentCountry, Validators.required],
      city: [this.billingAccount ? this.billingAccount.city : '', Validators.required],
      zipCode: [this.billingAccount ? this.billingAccount.zipCode : '', Validators.required],
      excludeVAT: [this.billingAccount ? this.billingAccount.excludeVAT : false, Validators.required],
      fiscalAddress: [this.billingAccount ? this.billingAccount.fiscalAddress : '', Validators.required],
    });

    this.billingAccountForm.get('id').disable();
    this.billingAccountAux = clone(this.billingAccount);

    if (!this.isEditor) {
      this.billingAccountForm.get('id').disable();
      this.billingAccountForm.get('name').disable();
      this.billingAccountForm.get('cif').disable();
      this.billingAccountForm.get('countryCode').disable();
      this.billingAccountForm.get('fiscalAddress').disable();
      this.billingAccountForm.get('city').disable();
      this.billingAccountForm.get('zipCode').disable();
      this.billingAccountForm.get('excludeVAT').disable();
    }
  }

  buildCountries() {
    const countries = this.staticService.getCountries();
    this.countries = [];
    countries.forEach((isoCode: string, name: string) => {
      this.countries.push({ label: name + ' (' + isoCode + ')', value: isoCode });
    });
  }

  autoCompleteCountries(event: any) {
    this.countriesAutocomplete = [];
    const query = event.query.toLowerCase();
    this.countriesAutocomplete = this.countries.filter((x) => x.label.toLowerCase().includes(query));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  closeModal() {
    this.billingAccount = clone(this.billingAccountAux);
    this.activeModal.close(true);
  }

  onSave() {
    if (this.isCreating) {
      //Mutation create
      const createData: BillingAccountCreateInput = {
        name: this.billingAccountForm.get('name').value,
        cif: this.billingAccountForm.get('cif').value,
        countryCode: this.billingAccountForm.get('countryCode').value.value,
        fiscalAddress: this.billingAccountForm.get('fiscalAddress').value,
        singleInvoice: this.isCreating ? false : this.billingAccount.singleInvoice,
        Partner: { connect: { id: this.partnerId } },
        city: this.billingAccountForm.get('city').value,
        zipCode: this.billingAccountForm.get('zipCode').value,
        excludeVAT: this.billingAccountForm.get('excludeVAT').value,
      };

      this.billingAccountService
        .createBillingAccount(createData)
        .then((rs) => {
          this.notificationService.success('Billingaccount succesfully created', 'Success');
          this.activeModal.close(rs);
        })
        .catch((err) => {
          this.activeModal.close(false);
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    } else {
      ///Mutation update
      const updateData: BillingAccountUpdateInput = {
        name: { set: this.billingAccountForm.get('name').value },
        cif: { set: this.billingAccountForm.get('cif').value },
        countryCode: { set: this.billingAccountForm.get('countryCode').value.value },
        fiscalAddress: { set: this.billingAccountForm.get('fiscalAddress').value },
        city: { set: this.billingAccountForm.get('city').value },
        zipCode: { set: this.billingAccountForm.get('zipCode').value },
        excludeVAT: { set: this.billingAccountForm.get('excludeVAT').value },
      };

      const where: ObjectWhereUniqueInputById = { id: this.billingAccount.id };

      this.billingAccountService
        .updateBillingAccount(updateData, where)
        .then((rs) => {
          this.notificationService.success('Billingaccount succesfully updated', 'Success');
          this.activeModal.close(rs);
        })
        .catch((err) => {
          this.activeModal.close(false);
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    }
  }
}
