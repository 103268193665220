<table class="xen-table is-full table-mapping" *ngIf="!isLoading; else loadingTemplate">
  <thead>
    <tr>
      <th *ngFor="let head of tHead">{{ head.label }}</th>
      <th></th>
    </tr>
  </thead>
  <tbody *ngFor="let validate of validateMasters">
    <tr
      class="first-tbody"
      [ngClass]="{ '--is-child': idMasterSelected === validate.id && validate.show_child }"
      (click)="retrieveMasterSons(validate)"
    >
      <td>
        <i class="fa-solid fa-circle-check me-1 pt-1" [ngClass]="{ 'text-success': validate }"></i>
        {{ validate.name }}
      </td>
      <td>{{ validate.id }}</td>
      <td>{{ validate.location }}</td>
      <td>{{ findNameCountry(validate.country) }}</td>
      <td>{{ validate.address }}</td>
      <td class="text-end">
        <i
          class="fa-regular fa-chevron-{{ validate.show_child && idMasterSelected === validate.id ? 'up' : 'down' }}"
        ></i>
      </td>
    </tr>
    <!-- HOTELS -->
    <ng-container *ngIf="validate.show_child && idMasterSelected === validate.id">
      <tr class="child">
        <td colspan="10" class="pt-0">
          <table class="xen-table is-full mt-3">
            <tbody>
              <tr *ngFor="let hotel of validateHotelsCP()" class="bg-td">
                <td>{{ hotel.name }}</td>
                <td>{{ hotel.hotel_code }}</td>
                <td>{{ hotel.location }}</td>
                <td>{{ findNameCountry(hotel.country) }}</td>
                <td>{{ hotel.address }}</td>
              </tr>
              <tr class="border-0">
                <td colspan="10" *ngIf="validateHotels?.length > pageSizeHotels">
                  <xen-pagination
                    class="d-flex justify-content-start"
                    [page]="pageHotels"
                    [pageSize]="pageSizeHotels"
                    [maxSize]="3"
                    [collectionSize]="validateHotels?.length"
                    (pageChange)="onPageChangeHotels($event)"
                  ></xen-pagination>
                </td>
              </tr>
              <tr class="tr-btn">
                <td class="ps-0 py-3 px-0" colspan="10">
                  <div class="d-flex flex-row-reverse gap-2">
                    <xen-btn
                      [type]="'primary'"
                      [size]="'small'"
                      [label]="'Invalidate this master'"
                      [id]="'invalidate-master'"
                      [full_width]="false"
                      [icon]="true"
                      [icon_placement]="'left'"
                      [icon_snippet]="'fa-light fa-circle-xmark'"
                      [disabled]="false"
                      [loading]="false"
                      [skin]="'red'"
                      (click)="openModalInvalidate(validate)"
                    ></xen-btn>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>

<ng-template #loadingTemplate>
  <table class="xen-table is-full">
    <thead>
      <tr>
        <th *ngFor="let head of tHead">{{ head.label }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]">
        <td *ngFor="let item of [1, 2, 3, 4, 5]">
          <p-skeleton width="10rem"></p-skeleton>
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>

<xen-pagination
  *ngIf="validateCount > 10"
  [(page)]="page"
  [pageSize]="pageSize"
  [collectionSize]="validateCount"
  [isDisabled]="isLoading"
  [maxSize]="10"
  (pageChange)="onPageChange($event)"
></xen-pagination>
