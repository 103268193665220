export const hsCountryListIsoCodes = [
  { name: 'Afghanistan', isoCode2: 'AF' },
  { name: 'Åland Islands', isoCode2: 'AX' },
  { name: 'Albania', isoCode2: 'AL' },
  { name: 'Algeria', isoCode2: 'DZ' },
  { name: 'American Samoa', isoCode2: 'AS' },
  { name: 'Andorra', isoCode2: 'AD' },
  { name: 'Angola', isoCode2: 'AO' },
  { name: 'Anguilla', isoCode2: 'AI' },
  { name: 'Antigua and Barbuda', isoCode2: 'AG' },
  { name: 'Argentina', isoCode2: 'AR' },
  { name: 'Armenia', isoCode2: 'AM' },
  { name: 'Aruba', isoCode2: 'AW' },
  { name: 'Australia', isoCode2: 'AU' },
  { name: 'Austria', isoCode2: 'AT' },
  { name: 'Azerbaijan', isoCode2: 'AZ' },
  { name: 'Bahamas', isoCode2: 'BS' },
  { name: 'Bahrain', isoCode2: 'BH' },
  { name: 'Bangladesh', isoCode2: 'BD' },
  { name: 'Barbados', isoCode2: 'BB' },
  { name: 'Belarus', isoCode2: 'BY' },
  { name: 'Belgium', isoCode2: 'BE' },
  { name: 'Belize', isoCode2: 'BZ' },
  { name: 'Benin', isoCode2: 'BJ' },
  { name: 'Bermuda', isoCode2: 'BM' },
  { name: 'Bhutan', isoCode2: 'BT' },
  { name: 'Bolivia (Plurinational State of)', isoCode2: 'BO' },
  { name: 'Bonaire, Sint Eustatius and Saba', isoCode2: 'BQ' },
  { name: 'Bosnia and Herzegovina', isoCode2: 'BA' },
  { name: 'Botswana', isoCode2: 'BW' },
  { name: 'Bouvet Island', isoCode2: 'BV' },
  { name: 'Brazil', isoCode2: 'BR' },
  { name: 'British Indian Ocean Territory', isoCode2: 'IO' },
  { name: 'Brunei Darussalam', isoCode2: 'BN' },
  { name: 'Bulgaria', isoCode2: 'BG' },
  { name: 'Burkina Faso', isoCode2: 'BF' },
  { name: 'Burundi', isoCode2: 'BI' },
  { name: 'Cabo Verde', isoCode2: 'CV' },
  { name: 'Cambodia', isoCode2: 'KH' },
  { name: 'Cameroon', isoCode2: 'CM' },
  { name: 'Canada', isoCode2: 'CA' },
  { name: 'Cayman Islands', isoCode2: 'KY' },
  { name: 'Central African Republic', isoCode2: 'CF' },
  { name: 'Chad', isoCode2: 'TD' },
  { name: 'Chile', isoCode2: 'CL' },
  { name: 'China', isoCode2: 'CN' },
  { name: 'Colombia', isoCode2: 'CO' },
  { name: 'Comoros', isoCode2: 'KM' },
  { name: 'Congo', isoCode2: 'CG' },
  { name: 'Cook Islands', isoCode2: 'CK' },
  { name: 'Costa Rica', isoCode2: 'CR' },
  { name: "Côte d'Ivoire", isoCode2: 'CI' },
  { name: 'Croatia', isoCode2: 'HR' },
  { name: 'Cuba', isoCode2: 'CU' },
  { name: 'Curaçao', isoCode2: 'CW' },
  { name: 'Cyprus', isoCode2: 'CY' },
  { name: 'Czechia', isoCode2: 'CZ' },
  { name: "Democratic People's Republic of Korea", isoCode2: 'KP' },
  { name: 'Democratic Republic of the Congo', isoCode2: 'CD' },
  { name: 'Denmark', isoCode2: 'DK' },
  { name: 'Djibouti', isoCode2: 'DJ' },
  { name: 'Dominica', isoCode2: 'DM' },
  { name: 'Dominican Republic', isoCode2: 'DO' },
  { name: 'Ecuador', isoCode2: 'EC' },
  { name: 'Egypt', isoCode2: 'EG' },
  { name: 'El Salvador', isoCode2: 'SV' },
  { name: 'Equatorial Guinea', isoCode2: 'GQ' },
  { name: 'Eritrea', isoCode2: 'ER' },
  { name: 'Estonia', isoCode2: 'EE' },
  { name: 'Eswatini', isoCode2: 'SZ' },
  { name: 'Ethiopia', isoCode2: 'ET' },
  { name: 'Falkland Islands (Malvinas)', isoCode2: 'FK' },
  { name: 'Faroe Islands', isoCode2: 'FO' },
  { name: 'Fiji', isoCode2: 'FJ' },
  { name: 'Finland', isoCode2: 'FI' },
  { name: 'France', isoCode2: 'FR' },
  { name: 'French Guiana', isoCode2: 'GF' },
  { name: 'French Polynesia', isoCode2: 'PF' },
  { name: 'French Southern Territories', isoCode2: 'TF' },
  { name: 'Gabon', isoCode2: 'GA' },
  { name: 'Gambia', isoCode2: 'GM' },
  { name: 'Georgia', isoCode2: 'GE' },
  { name: 'Germany', isoCode2: 'DE' },
  { name: 'Ghana', isoCode2: 'GH' },
  { name: 'Gibraltar', isoCode2: 'GI' },
  { name: 'Greece', isoCode2: 'GR' },
  { name: 'Greenland', isoCode2: 'GL' },
  { name: 'Grenada', isoCode2: 'GD' },
  { name: 'Guadeloupe', isoCode2: 'GP' },
  { name: 'Guam', isoCode2: 'GU' },
  { name: 'Guatemala', isoCode2: 'GT' },
  { name: 'Guernsey', isoCode2: 'GG' },
  { name: 'Guinea', isoCode2: 'GN' },
  { name: 'Guinea-Bissau', isoCode2: 'GW' },
  { name: 'Guyana', isoCode2: 'GY' },
  { name: 'Haiti', isoCode2: 'HT' },
  { name: 'Holy See', isoCode2: 'VA' },
  { name: 'Honduras', isoCode2: 'HN' },
  { name: 'Hong Kong', isoCode2: 'HK' },
  { name: 'Hungary', isoCode2: 'HU' },
  { name: 'Iceland', isoCode2: 'IS' },
  { name: 'India', isoCode2: 'IN' },
  { name: 'Indonesia', isoCode2: 'ID' },
  { name: 'Iran', isoCode2: 'IR' },
  { name: 'Iraq', isoCode2: 'IQ' },
  { name: 'Ireland', isoCode2: 'IE' },
  { name: 'Isle of Man', isoCode2: 'IM' },
  { name: 'Israel', isoCode2: 'IL' },
  { name: 'Italy', isoCode2: 'IT' },
  { name: 'Jamaica', isoCode2: 'JM' },
  { name: 'Japan', isoCode2: 'JP' },
  { name: 'Jersey', isoCode2: 'JE' },
  { name: 'Jordan', isoCode2: 'JO' },
  { name: 'Kazakhstan', isoCode2: 'KZ' },
  { name: 'Kenya', isoCode2: 'KE' },
  { name: 'Kiribati', isoCode2: 'KI' },
  { name: 'Kuwait', isoCode2: 'KW' },
  { name: 'Kyrgyzstan', isoCode2: 'KG' },
  { name: "Lao People's Democratic Republic", isoCode2: 'LA' },
  { name: 'Latvia', isoCode2: 'LV' },
  { name: 'Lebanon', isoCode2: 'LB' },
  { name: 'Lesotho', isoCode2: 'LS' },
  { name: 'Liberia', isoCode2: 'LR' },
  { name: 'Libya', isoCode2: 'LY' },
  { name: 'Liechtenstein', isoCode2: 'LI' },
  { name: 'Lithuania', isoCode2: 'LT' },
  { name: 'Luxembourg', isoCode2: 'LU' },
  { name: 'Macao', isoCode2: 'MO' },
  { name: 'Madagascar', isoCode2: 'MG' },
  { name: 'Malawi', isoCode2: 'MW' },
  { name: 'Malaysia', isoCode2: 'MY' },
  { name: 'Maldives', isoCode2: 'MV' },
  { name: 'Mali', isoCode2: 'ML' },
  { name: 'Malta', isoCode2: 'MT' },
  { name: 'Marshall Islands', isoCode2: 'MH' },
  { name: 'Martinique', isoCode2: 'MQ' },
  { name: 'Mauritania', isoCode2: 'MR' },
  { name: 'Mauritius', isoCode2: 'MU' },
  { name: 'Mayotte', isoCode2: 'YT' },
  { name: 'Mexico', isoCode2: 'MX' },
  { name: 'Micronesia', isoCode2: 'FM' },
  { name: 'Moldova', isoCode2: 'MD' },
  { name: 'Monaco', isoCode2: 'MC' },
  { name: 'Mongolia', isoCode2: 'MN' },
  { name: 'Montenegro', isoCode2: 'ME' },
  { name: 'Montserrat', isoCode2: 'MS' },
  { name: 'Morocco', isoCode2: 'MA' },
  { name: 'Mozambique', isoCode2: 'MZ' },
  { name: 'Myanmar', isoCode2: 'MM' },
  { name: 'Namibia', isoCode2: 'NA' },
  { name: 'Nauru', isoCode2: 'NR' },
  { name: 'Nepal', isoCode2: 'NP' },
  { name: 'Netherlands', isoCode2: 'NL' },
  { name: 'New Caledonia', isoCode2: 'NC' },
  { name: 'New Zealand', isoCode2: 'NZ' },
  { name: 'Nicaragua', isoCode2: 'NI' },
  { name: 'Niger', isoCode2: 'NE' },
  { name: 'Nigeria', isoCode2: 'NG' },
  { name: 'Niue', isoCode2: 'NU' },
  { name: 'Norfolk Island', isoCode2: 'NF' },
  { name: 'Northern Mariana Islands', isoCode2: 'MP' },
  { name: 'North Macedonia', isoCode2: 'MK' },
  { name: 'Norway', isoCode2: 'NO' },
  { name: 'Oman', isoCode2: 'OM' },
  { name: 'Pakistan', isoCode2: 'PK' },
  { name: 'Palau', isoCode2: 'PW' },
  { name: 'Palestine', isoCode2: 'PS' },
  { name: 'Panama', isoCode2: 'PA' },
  { name: 'Papua New Guinea', isoCode2: 'PG' },
  { name: 'Paraguay', isoCode2: 'PY' },
  { name: 'Peru', isoCode2: 'PE' },
  { name: 'Philippines', isoCode2: 'PH' },
  { name: 'Pitcairn', isoCode2: 'PN' },
  { name: 'Poland', isoCode2: 'PL' },
  { name: 'Portugal', isoCode2: 'PT' },
  { name: 'Puerto Rico', isoCode2: 'PR' },
  { name: 'Qatar', isoCode2: 'QA' },
  { name: 'Republic of Korea', isoCode2: 'KR' },
  { name: 'Republic of Kosovo', isoCode2: 'XK' },
  { name: 'Réunion', isoCode2: 'RE' },
  { name: 'Romania', isoCode2: 'RO' },
  { name: 'Russian Federation', isoCode2: 'RU' },
  { name: 'Rwanda', isoCode2: 'RW' },
  { name: 'Saint Barthélemy', isoCode2: 'BL' },
  { name: 'Saint Helena', isoCode2: 'SH' },
  { name: 'Saint Kitts and Nevis', isoCode2: 'KN' },
  { name: 'Saint Lucia', isoCode2: 'LC' },
  { name: 'Saint Martin (French part)', isoCode2: 'MF' },
  { name: 'Saint Pierre and Miquelon', isoCode2: 'PM' },
  { name: 'Saint Vincent and the Grenadines', isoCode2: 'VC' },
  { name: 'Samoa', isoCode2: 'WS' },
  { name: 'San Marino', isoCode2: 'SM' },
  { name: 'Sao Tome and Principe', isoCode2: 'ST' },
  { name: 'Saudi Arabia', isoCode2: 'SA' },
  { name: 'Senegal', isoCode2: 'SN' },
  { name: 'Serbia', isoCode2: 'RS' },
  { name: 'Seychelles', isoCode2: 'SC' },
  { name: 'Sierra Leone', isoCode2: 'SL' },
  { name: 'Singapore', isoCode2: 'SG' },
  { name: 'Sint Maarten (Dutch part)', isoCode2: 'SX' },
  { name: 'Slovakia', isoCode2: 'SK' },
  { name: 'Slovenia', isoCode2: 'SI' },
  { name: 'Solomon Islands', isoCode2: 'SB' },
  { name: 'Somalia', isoCode2: 'SO' },
  { name: 'South Africa', isoCode2: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', isoCode2: 'GS' },
  { name: 'South Sudan', isoCode2: 'SS' },
  { name: 'Spain', isoCode2: 'ES' },
  { name: 'Sri Lanka', isoCode2: 'LK' },
  { name: 'Sudan', isoCode2: 'SD' },
  { name: 'Suriname', isoCode2: 'SR' },
  { name: 'Svalbard and Jan Mayen Islands', isoCode2: 'SJ' },
  { name: 'Sweden', isoCode2: 'SE' },
  { name: 'Switzerland', isoCode2: 'CH' },
  { name: 'Syrian Arab Republic', isoCode2: 'SY' },
  { name: 'Taiwan', isoCode2: 'TW' },
  { name: 'Tajikistan', isoCode2: 'TJ' },
  { name: 'Tanzania', isoCode2: 'TZ' },
  { name: 'Thailand', isoCode2: 'TH' },
  { name: 'Timor-Leste', isoCode2: 'TL' },
  { name: 'Togo', isoCode2: 'TG' },
  { name: 'Tokelau', isoCode2: 'TK' },
  { name: 'Tonga', isoCode2: 'TO' },
  { name: 'Trinidad and Tobago', isoCode2: 'TT' },
  { name: 'Tunisia', isoCode2: 'TN' },
  { name: 'Türkiye', isoCode2: 'TR' },
  { name: 'Turkmenistan', isoCode2: 'TM' },
  { name: 'Turks and Caicos Islands', isoCode2: 'TC' },
  { name: 'Tuvalu', isoCode2: 'TV' },
  { name: 'Uganda', isoCode2: 'UG' },
  { name: 'Ukraine', isoCode2: 'UA' },
  { name: 'United Arab Emirates', isoCode2: 'AE' },
  { name: 'United Kingdom of Great Britain and Northern Ireland', isoCode2: 'GB' },
  { name: 'United States Minor Outlying Islands', isoCode2: 'UM' },
  { name: 'United States of America', isoCode2: 'US' },
  { name: 'Uruguay', isoCode2: 'UY' },
  { name: 'Uzbekistan', isoCode2: 'UZ' },
  { name: 'Vanuatu', isoCode2: 'VU' },
  { name: 'Venezuela', isoCode2: 'VE' },
  { name: 'Viet Nam', isoCode2: 'VN' },
  { name: 'Virgin Islands (British)', isoCode2: 'VG' },
  { name: 'Virgin Islands (U.S.)', isoCode2: 'VI' },
  { name: 'Wallis and Futuna Islands', isoCode2: 'WF' },
  { name: 'Western Sahara', isoCode2: 'EH' },
  { name: 'Yemen', isoCode2: 'YE' },
  { name: 'Zambia', isoCode2: 'ZM' },
  { name: 'Zimbabwe', isoCode2: 'ZW' },
];
