<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a (click)="navigateHome()" href="#">
      <img _ngcontent-ipc-c115="" src="../../../../assets/logo_travelgate_blue.svg" alt="Logo TravelgateX" />
    </a>
  </div>
</div>

<div class="header-container">
  <span *ngIf="!hideSelector" style="margin-right: 10px">Organization: </span>

  <p-dropdown
    *ngIf="!hideSelector"
    [options]="orgsDropdown"
    (onHide)="changeOrganization()"
    [(ngModel)]="orgDropdownSelected"
    optionLabel="label"
    [filter]="true"
    filterBy="label"
  >
    <ng-template pTemplate="selectedItem">
      <div *ngIf="orgDropdownSelected">
        <div>{{ orgDropdownSelected.label }}</div>
      </div>
    </ng-template>
    <ng-template let-org pTemplate="org">
      <div>
        <div>{{ org.label }}</div>
      </div>
    </ng-template>
  </p-dropdown>

  <nb-actions size="small">
    <nb-action class="user-action">
      <nb-user
        shape="semi-round"
        [nbContextMenu]="userMenu"
        nbContextMenuTrigger="click"
        [name]="userData?.firstName"
        [picture]="userData?.picture"
        [title]="userRole"
      >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
