import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decode',
})
export class DecodePipe implements PipeTransform {
  traductions = {
    rq: 'Request',
    rs: 'Response',
  };

  transform(code: string): string {
    let stringUncoded = '';
    if (this.traductions[code]) {
      return (stringUncoded = this.traductions[code]);
    } else {
      stringUncoded = code.charAt(0).toUpperCase() + code.slice(1);
      return stringUncoded.replace(/_/g, ' ');
    }
  }
}
