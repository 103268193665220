import { gql } from 'apollo-angular';

export const getPartners = gql`
  query {
    partners {
      id
      name
      orgCode
      odooCode
      createdAt
      updatedAt
    }
  }
`;
