<nb-card>
  <nb-card-header> Main Connections </nb-card-header>

  <nb-card-body>
    <div *ngIf="isEditor" class="mb-3">
      <p-button label="Create" icon="pi pi-plus" (onClick)="onCreate()"></p-button>
    </div>
    <p-table
      #dt
      [value]="connectionsLoadSource"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      styleClass="p-datatable-gridlines p-datatable-striped"
      [tableStyle]="{ 'min-width': '50rem' }"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
      [loading]="isLoading"
      loadingIcon="pi pi-spin pi-spinner"
      [resizableColumns]="true"
      columnResizeMode="expand"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let head of header" [pSortableColumn]="head.field">
            {{ head.label }}
            <p-sortIcon *ngIf="head.field !== 'Options'" [field]="head.field"></p-sortIcon>
          </th>
        </tr>
        <tr>
          <th *ngFor="let head of header" [style]="head.field === 'UpdatedAt' ? 'width: 3rem' : ''">
            <p-columnFilter
              *ngIf="head.field !== 'createdAt' && head.field !== 'updatedAt'"
              [type]="getFilterType(head.field)"
              [field]="head.field"
              [showMenu]="false"
              [showClearButton]="false"
              (input)="filter($event.target.value, head.field)"
            ></p-columnFilter>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-connection>
        <tr>
          <td>{{ connection.id }}</td>
          <td>{{ connection.buyer }}</td>
          <td>{{ connection.seller }}</td>
          <td>
            <p-checkbox [ngModel]="connection.isPush" [binary]="true" inputId="binary" disabled="true"></p-checkbox>
          </td>
          <td>{{ connection.api }}</td>
          <td>{{ connection.createdAt | date: 'dd/MM/yyyy' }}</td>
          <td>{{ connection.updatedAt | date: 'dd/MM/yyyy' }}</td>
          <td></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="15" class="text-center">No main connections found.</td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>
