import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class WebService {
  message: string;
  isLoading = true;
  isVisible: boolean;

  constructor(
    @Inject('urlNotificationsService') private urlNotificationsService: string,
    private http: HttpClient,
  ) {}

  getMessage() {
    return this.http.get<any>(`${this.urlNotificationsService}admin/get-banner-message`);
  }

  setMessage(visibleInput, messageInput, alertTypeInput, dismissInput) {
    const body = {
      visible: visibleInput,
      message: messageInput,
      type: alertTypeInput,
      dismiss: dismissInput,
    };

    // To test notifications locally, change the notifications_service to 'http://localhost:3000/api/' in environments/environment.ts
    return this.http.put<any>(`${this.urlNotificationsService}admin/set-banner-message`, body);
  }
}
