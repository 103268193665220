import { Injectable } from '@angular/core';
import {
  ObjectWhereUniqueInputById,
  BillingAccountContact,
  BillingAccountContactCreateInput,
  BillingAccountContactUpdateInput,
} from './../interfaces/_index.billing.interfaces';
import { GraphqlApiBillingMutations } from './mutations/_index-billing-mutations';
import { GraphqlApiBillingQueries } from './queries/_index-billing-queries';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class BillingAccountContactService {
  constructor(private apollo: Apollo) {}

  async getBillingAccountContacts(baId: number): Promise<BillingAccountContact[]> {
    const where = { billingAccount: { equals: baId } };

    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .watchQuery<any>({
          query: GraphqlApiBillingQueries.getBillingAccountContacts,
          variables: { where: where },
          fetchPolicy: 'network-only',
        })
        .valueChanges.subscribe((res: any) => {
          if (res && res.data) {
            resolve(((res || {}).data || {}).billingAccountContacts);
          } else {
            reject(res);
          }
        });
    });
  }

  async createBillingAccountContact(create: BillingAccountContactCreateInput): Promise<BillingAccountContact> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.createContact,
          variables: { data: create },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).createBillingAccountContact);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }

  async updateBillingAccountContact(
    update: BillingAccountContactUpdateInput,
    where: ObjectWhereUniqueInputById,
  ): Promise<BillingAccountContact> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.updateContact,
          variables: { data: update, where: where },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).updateBillingAccountContact);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }

  async deleteBillingAccountContact(where: ObjectWhereUniqueInputById): Promise<BillingAccountContact> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.deleteContact,
          variables: { where: where },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).deleteBillingAccountContact);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }
}
