import { gql } from 'apollo-angular';

export const getNewPricingCosts = gql`
  query GetNewPricingCosts($where: NewPricingCostWhereInput!) {
    newPricingCosts(where: $where) {
      id
      min
      max
      bookingFee
      cancelFee
      l2b
      excess
      chargeType
      paymentType
    }
  }
`;
