import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SubCategoryCreateInput } from '../../../../interfaces/inputs/sub-category-create-input';
import { ProfileNetworkService } from '../../../../services/profile-network.service';

@Component({
  selector: 'tgx-new-subcategory-form',
  templateUrl: './new-subcategory-form.component.html',
  styleUrls: ['./new-subcategory-form.component.scss'],
})
export class NewSubcategoryFormComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private profileService: ProfileNetworkService,
    private activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    // TODO: add validations to form fields: max length, allowed chars, ...
    this.form = this.fb.group({
      name: ['', Validators.required],
      description: [''],
      isBuyer: [false, Validators.required],
      isSeller: [false, Validators.required],
      isDemandBridge: [false, Validators.required],
      isSupplyBridge: [false, Validators.required],
    });
  }

  sendCreateSubCategory() {
    const response: SubCategoryCreateInput = {
      code: this.form.get('name').value,
      description: this.form.get('description').value,
      defaultIsBuyer: this.form.get('isBuyer').value,
      defaultIsSeller: this.form.get('isSeller').value,
      defaultDemandBridge: this.form.get('isDemandBridge').value,
      defaultSupplyBridge: this.form.get('isSupplyBridge').value,
    };

    this.activeModal.close(response);
  }

  closeModal() {
    this.activeModal.close();
  }
}
