import {
  ObjectWhereUniqueInputById,
  BillingAccountContact,
  BillingAccountContactUpdateInput,
} from './../../../../interfaces/_index.billing.interfaces';
import { NotificationService } from './../../../../../../@core/services/_index-core.services';
import { BillingAccountContactService } from './../../../../services/_index-billing.services';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { clone } from '../../../../../../@core/utils/utils';

@Component({
  selector: 'tgx-billing-account-modal',
  styleUrls: ['./billing-accounts-contact-modal.component.scss'],
  templateUrl: 'billing-accounts-contact-modal.component.html',
})
export class BillingAccountContactModalComponent implements OnInit {
  @Input() billingAccountContact: BillingAccountContact = null;
  @Input() header: string;
  @Input() baId: number;
  @Input() isEditor: boolean;

  isLoading: boolean;
  billingAccountContactAux: BillingAccountContact;

  public billingAccountContactForm: UntypedFormGroup;

  constructor(
    private notificationService: NotificationService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private billingAccountContactService: BillingAccountContactService,
  ) {}

  ngOnInit() {
    this.billingAccountContactForm = this.fb.group({
      id: [this.billingAccountContact ? this.billingAccountContact.id : ''],
      email: [this.billingAccountContact ? this.billingAccountContact.email : '', Validators.required],
      receiveInvoices: [
        this.billingAccountContact ? this.billingAccountContact.receiveInvoices : true,
        Validators.required,
      ],
      odooCode: [this.billingAccountContact ? this.billingAccountContact.odooCode : 0],
    });

    this.billingAccountContactForm.get('id').disable();
    this.billingAccountContactAux = clone(this.billingAccountContact);
    this.billingAccountContactForm.get('email').disable();
    this.billingAccountContactForm.get('receiveInvoices').disable();

    if (!this.isEditor) {
      this.billingAccountContactForm.get('id').disable();
    }
  }

  closeModal() {
    this.billingAccountContact = clone(this.billingAccountContactAux);
    this.activeModal.close(true);
  }

  onSave() {
    if (!this.billingAccountContact?.odooCode && this.billingAccountContactForm.get('odooCode').value) {
      const updateData: BillingAccountContactUpdateInput = {
        odooCode: { set: this.billingAccountContactForm.get('odooCode').value },
      };

      const where: ObjectWhereUniqueInputById = { id: this.billingAccountContact.id };

      this.billingAccountContactService
        .updateBillingAccountContact(updateData, where)
        .then((rs) => {
          this.notificationService.success('Contact succesfully updated', 'Success');
          this.activeModal.close(rs);
        })
        .catch((err) => {
          this.activeModal.close(false);
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    }
  }
}
