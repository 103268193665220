<div class="xen-textarea" [attr.formGroupTag]>
  <label class="form-label flex" *ngIf="label" [for]="id">
    {{ label }}
    <span class="is-required" *ngIf="required">*</span>
    <span class="xen-textarea__tooltip-icon" *ngIf="tooltip" ngbTooltip="{{ tooltip_text }}" tooltipClass="xen-tooltip">
      <i class="fas fa-info-square ms-1"></i>
    </span>
  </label>
  <div class="input-group xen-input-group" [formGroup]="form">
    <textarea
      name="{{ name }}"
      [id]="id"
      rows="{{ rows }}"
      cols="{{ cols }}"
      formControlName="{{ formControlName }}"
      [ngClass]="inputClasses"
      placeholder="{{ placeholder }}"
      [required]="required"
      [disabled]="disabled"
      >{{ content }}</textarea
    >
  </div>
  <div class="xen-input__feedback" [ngClass]="feedbackClasses" *ngIf="validation">{{ feedback }}</div>
</div>
