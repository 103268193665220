import {
  ObjectWhereUniqueInputById,
  BillingAccountContact,
  BillingAccountContactCreateInput,
  BillingAccountContactUpdateInput,
} from './../../../../interfaces/_index.billing.interfaces';
import { NotificationService } from './../../../../../../@core/services/_index-core.services';
import { BillingAccountContactService } from './../../../../services/_index-billing.services';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { clone } from '../../../../../../@core/utils/utils';

@Component({
  selector: 'tgx-billing-account-modal',
  styleUrls: ['./billing-accounts-contact-modal.component.scss'],
  templateUrl: 'billing-accounts-contact-modal.component.html',
})
export class BillingAccountContactModalComponent implements OnInit {
  @Input() billingAccountContact: BillingAccountContact = null;
  @Input() header: string;
  @Input() baId: number;
  @Input() isEditor: boolean;

  isLoading: boolean;
  isCreating: boolean;
  billingAccountContactAux: BillingAccountContact;

  public billingAccountContactForm: UntypedFormGroup;

  constructor(
    private notificationService: NotificationService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private billingAccountContactService: BillingAccountContactService,
  ) {}

  ngOnInit() {
    this.isCreating = true;

    if (this.billingAccountContact) {
      this.isCreating = false;
    }

    this.billingAccountContactForm = this.fb.group({
      id: [this.billingAccountContact ? this.billingAccountContact.id : ''],
      email: [this.billingAccountContact ? this.billingAccountContact.email : '', Validators.required],
      receiveInvoices: [
        this.billingAccountContact ? this.billingAccountContact.receiveInvoices : true,
        Validators.required,
      ],
      odooCode: [this.billingAccountContact ? this.billingAccountContact.odooCode : 0],
    });

    this.billingAccountContactForm.get('id').disable();
    this.billingAccountContactAux = clone(this.billingAccountContact);

    if (!this.isEditor) {
      this.billingAccountContactForm.get('id').disable();
      this.billingAccountContactForm.get('email').disable();
      this.billingAccountContactForm.get('receiveInvoices').disable();
    }
  }

  closeModal() {
    this.billingAccountContact = clone(this.billingAccountContactAux);
    this.activeModal.close(true);
  }

  onSave() {
    if (this.isCreating) {
      //Mutation create
      const createData: BillingAccountContactCreateInput = {
        email: this.billingAccountContactForm.get('email').value,
        receiveInvoices: this.billingAccountContactForm.get('receiveInvoices').value,
        BillingAccount: { connect: { id: this.baId } },
      };

      this.billingAccountContactService
        .createBillingAccountContact(createData)
        .then((rs) => {
          this.notificationService.success('Contact succesfully created', 'Success');
          this.activeModal.close(rs);
        })
        .catch((err) => {
          this.activeModal.close(false);
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    } else {
      ///Mutation update
      const updateData: BillingAccountContactUpdateInput = {
        email: { set: this.billingAccountContactForm.get('email').value },
        receiveInvoices: { set: this.billingAccountContactForm.get('receiveInvoices').value },
      };

      if (!this.billingAccountContact?.odooCode && this.billingAccountContactForm.get('odooCode').value) {
        updateData.odooCode = { set: this.billingAccountContactForm.get('odooCode').value };
      }

      const where: ObjectWhereUniqueInputById = { id: this.billingAccountContact.id };

      this.billingAccountContactService
        .updateBillingAccountContact(updateData, where)
        .then((rs) => {
          this.notificationService.success('Contact succesfully updated', 'Success');
          this.activeModal.close(rs);
        })
        .catch((err) => {
          this.activeModal.close(false);
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    }
  }
}
