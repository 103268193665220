export const configLocalStorage = {
  idTokenImpersonation: 'id_token_impersonation',
  idToken: 'id_token',
  emailImpersonation: 'email_impersonation',
  expiresAt: 'expires_at',
  userProfile: 'userProfile',
  accessToken: 'access_token',
  cookieImpersonation: 'impersonation',
  cookieLoggedin: 'loggedin',
  userID: 'userID',
  adminPermissions: 'adminPermissions',
  ticketsFilter: 'ticketsFilter',
};
