<table class="xen-table is-full table-mapping" *ngIf="!isLoading; else loadingTemplate">
  <thead>
    <tr>
      <th *ngFor="let head of tHead">{{ head.label }}</th>
      <th></th>
    </tr>
  </thead>
  <tbody *ngFor="let unmaped of unmappedList">
    <tr class="first-tbody" (click)="openRemapHotelModal(unmaped)">
      <td class="pe-auto">
        <i class="fa-light fa-link"></i>
        {{ unmaped.name }}
      </td>
      <td>{{ unmaped.hotel_code }}</td>
      <td>{{ unmaped.location }}</td>
      <td>{{ findNameCountry(unmaped.country) }}</td>
      <td>{{ unmaped.address }}</td>
      <td class="text-end"></td>
    </tr>
  </tbody>
</table>
<ng-template #loadingTemplate>
  <table class="xen-table is-full">
    <thead>
      <tr>
        <th *ngFor="let head of tHead">{{ head.label }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]">
        <td *ngFor="let item of [1, 2, 3, 4, 5]">
          <p-skeleton width="10rem"></p-skeleton>
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>

<xen-pagination
  *ngIf="unmappedCount > 10"
  [(page)]="page"
  [pageSize]="pageSize"
  [collectionSize]="unmappedCount"
  [isDisabled]="isLoading"
  [maxSize]="10"
  (pageChange)="onPageChange($event)"
></xen-pagination>
