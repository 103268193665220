import { Injectable } from '@angular/core';
import { GqlService } from '@tgx/shared/data-access/graphql';
import { Subject } from 'rxjs';
import { Organizations } from '../../../@core/shared/interfaces/models/organization.interface';
import { CreateMemberInput } from '../interfaces/inputs/create-member-input';
import { UpdateMemberRoleDataInput, UpdateMemberRoleWhereInput } from '../interfaces/inputs/update-member-role-input';

import {
  MemberToOrganizationInput,
  MemberDataInOrganization,
  Apikeys,
  ApikeysConnection,
  ApikeysData,
  Members,
  MembersConnection,
  UpdateMemberInput,
} from './../interfaces/_index-entities.interfaces';
import { GraphqlGatewayMutations } from './mutations/_index-entities.mutations';
import { GraphqlGatewayQueries } from './queries/_index-entities.queries';

@Injectable({
  providedIn: 'root',
})
export class MemberNetworkService {
  onToggleTable$: Subject<any> = new Subject();
  onToggleArchive$: Subject<any> = new Subject();
  filteredMember$: Subject<any> = new Subject();

  constructor(private gqlService: GqlService) {}

  async getAllMembersV2(type, contains, include_archived): Promise<MembersConnection> {
    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getAllMembersV2, {
          type: type,
          contains: contains,
          include_archived: include_archived,
        })
        .then((response) => {
          if (response['admin']['allMembers']) {
            resolve(response['admin']['allMembers']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          console.log(error);
          resolve({ edges: [], totalCount: 0 });
        });
    });
  }

  async getAllMembersByOrgV2(code_in, include_archived): Promise<Organizations[]> {
    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getAllMembersByOrgV2, {
          code_in: code_in,
          include_archived: include_archived,
        })
        .then((response) => {
          if (response['admin']['allOrganizations']['edges'].length > 0) {
            resolve(response['admin']['allOrganizations']['edges']);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          console.log(error);
          resolve([]);
        });
    });
  }

  async addMemberToOrg(where: MemberToOrganizationInput, data: MemberDataInOrganization): Promise<string> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.addMemberToOrganization, { where: where, data: data })
        .then((response) => {
          if (response['admin']['addMemberToOrganization']['adviseMessage']?.length > 0) {
            reject(response['admin']['addMemberToOrganization']['adviseMessage'][0]);
          } else {
            resolve(response['admin']['safeAddMemberToOrganization']['code']);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  async addMemberToOrgSafe(where: MemberToOrganizationInput, data: MemberDataInOrganization): Promise<string> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.addMemberToOrganizationSafe, { where: where, data: data })
        .then((response) => {
          if (response['admin']['safeAddMemberToOrganization']['adviseMessage']?.length > 0) {
            reject(response['admin']['safeAddMemberToOrganization']['adviseMessage'][0]);
          } else {
            resolve(response['admin']['safeAddMemberToOrganization']['code']);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }
  async updateMemberRole(input: UpdateMemberRoleDataInput, where: UpdateMemberRoleWhereInput): Promise<string> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.updateMemberRole, { data: input, where: where })
        .then((response) => {
          if (response['admin']['updateMemberDataInOrganization']['adviseMessage']?.length > 0) {
            reject(response['admin']['updateMemberDataInOrganization']['adviseMessage'][0]);
          } else {
            resolve(response['admin']['updateMemberDataInOrganization']['code']);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  async createMember(input: CreateMemberInput) {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.createMember, { data: input })
        .then((response) => {
          if (response['admin']['createMember']['adviseMessage']?.length > 0) {
            reject(response['admin']['createMember']['adviseMessage'][0]);
          } else {
            const member = {
              code: response['admin']['createMember']['code'],
            };
            resolve(member);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async activateMember(code: any) {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.activateMember, { memberCode: code })
        .then((response) => {
          if (response['admin']['activateMember']['adviseMessage']?.length > 0) {
            reject(response['admin']['activateMember']['adviseMessage'][0]);
          } else {
            resolve(true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async deactivateMember(code: any) {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.deactivateMember, { memberCode: code })
        .then((response) => {
          if (response['admin']['deactivateMember']['adviseMessage']?.length > 0) {
            reject(response['admin']['deactivateMember']['adviseMessage'][0]);
          } else {
            resolve(true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async archiveMember(code: any) {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.archiveMember, { memberCode: code })
        .then((response) => {
          if (response['admin']['archiveMember']['adviseMessage']?.length > 0) {
            reject(response['admin']['archiveMember']['adviseMessage'][0]);
          } else {
            resolve(true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async unarchiveMember(code: any) {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.unarchiveMember, { memberCode: code })
        .then((response) => {
          if (response['admin']['unArchiveMember']['adviseMessage']?.length > 0) {
            reject(response['admin']['unArchiveMember']['adviseMessage'][0]);
          } else {
            resolve(true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async removeMemberFromOrganization(where: MemberToOrganizationInput): Promise<Members> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.removeMemberFromOrganization, { where: where })
        .then((response) => {
          if (response['admin']['removeMemberFromOrganization']['adviseMessage']?.length > 0) {
            reject(response['admin']['removeMemberFromOrganization']['adviseMessage'][0]);
          } else {
            const member: Members = {
              code: response['admin']['removeMemberFromOrganization']['code'],
              createdAt: response['admin']['removeMemberFromOrganization']['createdAt'],
              updatedAt: response['admin']['removeMemberFromOrganization']['updatedAt'],
              membersData: response['admin']['removeMemberFromOrganization']['memberData'],
            };
            resolve(member);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  async updateMember(input: UpdateMemberInput): Promise<Members> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.updateMember, { data: input })
        .then((response) => {
          if (response['admin']['updateMember']['adviseMessage']?.length > 0) {
            reject(response['admin']['updateMember']['adviseMessage'][0]);
          } else {
            const member: Members = {
              code: response['admin']['updateMember']['code'],
              createdAt: response['admin']['updateMember']['createdAt'],
              updatedAt: response['admin']['updateMember']['updatedAt'],
              membersData: response['admin']['updateMember']['memberData'],
            };
            resolve(member);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  async getAllApikeys(include_archived): Promise<{ edges: Members[] }> {
    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getAllApikeys, { include_archived: include_archived })
        .then((response) => {
          if (response['admin']['allAPIKeys']['edges'].length > 0) {
            const edges = response['admin']['allAPIKeys']['edges'];
            resolve({ edges: edges });
          }
        })
        .catch((error) => {
          console.log(error);
          resolve({ edges: [] });
        });
    });
  }

  async activateApikey(apikeyCode: string, organization: string): Promise<Apikeys> {
    return await new Promise((resolve, reject) => {
      let where = { apikey: apikeyCode, organization: organization };
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.activateApikey, { where })
        .then((response) => {
          if (response['admin']['activateApikey']['adviseMessage']?.length > 0) {
            reject(response['admin']['activateApikey']['adviseMessage'][0]);
          } else {
            resolve(response['admin']['activateApikey']);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async deactivateApikey(apikeyCode: string, organization: string): Promise<Apikeys> {
    return await new Promise((resolve, reject) => {
      let where = { apikey: apikeyCode, organization: organization };
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.deActivateApikey, { where })
        .then((response) => {
          if (response['admin']['deactivateApikey']['adviseMessage']?.length > 0) {
            reject(response['admin']['deactivateApikey']['adviseMessage'][0]);
          } else {
            resolve(response['admin']['deactivateApikey']);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async safeDeactivateApikey(apikeyCode: string, organization: string): Promise<Apikeys> {
    return await new Promise((resolve, reject) => {
      let where = { apikey: apikeyCode, organization: organization };
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.safeDeactivateApikey, { where })
        .then((response) => {
          if (response['admin']['safeDeactivateApikey']['adviseMessage']?.length > 0) {
            reject(response['admin']['safeDeactivateApikey']['adviseMessage'][0]);
          } else {
            resolve(response['admin']['safeDeactivateApikey']);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async archiveApikey(apikeyCode: string, organization: string): Promise<Apikeys> {
    return await new Promise((resolve, reject) => {
      let where = { apikey: apikeyCode, organization: organization };
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.archiveApikey, { where })
        .then((response) => {
          if (response['admin']['archiveApikey']['adviseMessage']?.length > 0) {
            reject(response['admin']['archiveApikey']['adviseMessage'][0]);
          } else {
            resolve(response['admin']['archiveApikey']);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async unArchiveApikey(apikeyCode: string, organization: string): Promise<Apikeys> {
    return await new Promise((resolve, reject) => {
      let where = { apikey: apikeyCode, organization: organization };
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.unArchiveApikey, { where })
        .then((response) => {
          if (response['admin']['unArchiveApikey']['adviseMessage']?.length > 0) {
            reject(response['admin']['unArchiveApikey']['adviseMessage'][0]);
          } else {
            resolve(response['admin']['unArchiveApikey']);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
