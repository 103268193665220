import { gql } from 'apollo-angular';

export const getAllGroupContentContext = gql`
  query {
    admin {
      allGroupContents {
        groupContentData {
          code
          context {
            contextData {
              code
            }
          }
        }
      }
    }
  }
`;
