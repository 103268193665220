<div class="spinner" *ngIf="isLoading">
  <i class="fad fa-spinner-third fa-spin"></i>
</div>
<div>
  <nb-card id="table" *ngIf="!isLoading">
    <nb-card-header>
      <div class="d-flex align-items-center justify-content-between">
        <p class="mb-0">List of profiles</p>
        <xen-btn
          [type]="'secondary'"
          [size]="'medium'"
          [label]="'Download CSV'"
          [id]="'download-profile-csv'"
          [icon]="true"
          [icon_placement]="'left'"
          [icon_snippet]="'far fa-download'"
          [disabled]="isDowloading"
          [loading]="isDowloading"
          [skin]="'blue'"
          (onClick)="downloadCsv()"
        ></xen-btn>
      </div>
      <p class="csv-info mb-0 mt-2 d-flex align-items-center justify-content-end">
        <i class="fa-solid fa-circle-info tooltip-icon mb-0 me-2"></i>
        This CSV contains 'Partner Type, Name, OrgCode, Country, Status, Category/Subcategory, Integration Type, and
        Tech Bridge' properties.
      </p>
    </nb-card-header>
    <nb-card-body>
      <p-table
        #dt
        dataKey="ownerCode"
        [loading]="isLoading"
        [value]="profilesSource"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [paginator]="true"
        [rowsPerPageOptions]="[10, 25, 50]"
        [rows]="10"
        [rowHover]="true"
        [globalFilterFields]="['ownerCode', 'crmId', 'name', 'createdAt', 'updatedAt']"
        styleClass="p-datatable-striped p-datatable-sm"
        sortField="name"
        sortOrder="1"
      >
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="ownerCode" style="min-width: 8rem">
              <div class="flex justify-content-between align-items-center">
                Code
                <p-sortIcon field="ownerCode"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="crmId" style="min-width: 10rem">
              <div class="flex justify-content-between align-items-center">
                CRM ID
                <p-sortIcon field="crmId"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="name" style="min-width: 14rem">
              <div class="flex justify-content-between align-items-center">
                Name
                <p-sortIcon field="name"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="createdAt" style="min-width: 8rem">
              <div class="flex justify-content-between align-items-center">
                Created at
                <p-sortIcon field="createdAt"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="updatedAt" style="min-width: 8rem">
              <div class="flex justify-content-between align-items-center">
                Updated at
                <p-sortIcon field="updatedAt"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="published" style="min-width: 8rem">
              <div class="flex justify-content-between align-items-center">
                Published
                <p-sortIcon field="published"></p-sortIcon>
              </div>
            </th>
            <th></th>
          </tr>
          <tr>
            <th>
              <input
                pInputText
                type="text"
                (input)="dt.filter($event.target.value, 'ownerCode', 'contains')"
                placeholder="Search by code"
                class="p-inputtext p-column-filter"
              />
            </th>
            <th>
              <input
                pInputText
                type="text"
                (input)="dt.filter($event.target.value, 'crmId', 'contains')"
                placeholder="Search by crmID"
                class="p-inputtext p-column-filter"
              />
            </th>
            <th>
              <input
                pInputText
                type="text"
                (input)="dt.filter($event.target.value, 'name', 'contains')"
                placeholder="Search by name"
                class="p-inputtext p-column-filter"
              />
            </th>
            <th>
              <p-calendar
                (onSelect)="onDateSelect($event, 'createdAt')"
                (onClearClick)="dt.filter('', 'createdAt', 'equals')"
                [showButtonBar]="true"
                styleClass="p-column-filter"
                placeholder="Creation date"
                [readonlyInput]="true"
                dateFormat="dd/MM/yy"
                [appendTo]="'body'"
              ></p-calendar>
            </th>
            <th>
              <p-calendar
                (onSelect)="onDateSelect($event, 'updatedAt')"
                (onClearClick)="dt.filter('', 'updatedAt', 'equals')"
                [showButtonBar]="true"
                styleClass="p-column-filter"
                placeholder="Last update date"
                [readonlyInput]="true"
                dateFormat="dd/MM/yy"
                [appendTo]="'body'"
              ></p-calendar>
            </th>
            <th></th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-profile>
          <tr
            [ngClass]="{
              'noData': profile.crmId === null,
            }"
          >
            <td>{{ profile.ownerCode }}</td>
            <td>
              <a
                href="https://app.hubspot.com/contacts/2825176/record/0-2/{{ profile.crmId }}"
                external
                target="_blank"
                >{{ profile.crmId }}</a
              >
            </td>
            <td>{{ profile.name }}</td>
            <td>{{ profile.createdAt | date: 'dd/MM/yyyy' }}</td>
            <td>{{ profile.updatedAt | date: 'dd/MM/yyyy' }}</td>
            <td>
              <p-inputSwitch
                (onChange)="onTogglePublishedProfile(profile)"
                [(ngModel)]="profile.published"
              ></p-inputSwitch>
            </td>
            <td>
              <button
                pButton
                type="button"
                class="p-button-secondary"
                icon="pi pi-cog"
                (click)="onEdit(profile)"
              ></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="8">No customers found.</td>
          </tr>
        </ng-template>
      </p-table>
    </nb-card-body>
  </nb-card>
</div>
