import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, lastValueFrom, map } from 'rxjs';
import { SignupData } from './interfaces/signup-data.interface';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SignupStatusService {
  constructor(private http: HttpClient) {}

  async checkSignupStatus(email: string): Promise<SignupData> {
    const source$ = this.http
      .get(`${environment.apis.network_service}signup/check-signup-status?email=${email}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(
        map((res: SignupData) => {
          return res;
        }),
        catchError((error) => {
          throw error;
        }),
      );

    return lastValueFrom(source$);
  }
}
