import gql from 'graphql-tag';

export const getWidgetOrgs = gql`
  query getOrganization {
    admin {
      allOrganizations {
        edges {
          node {
            organizationsData {
              code
              label
            }
          }
        }
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
