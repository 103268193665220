import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignupStatusComponent } from './signup-status/signup-status.component';
import { ManageRegionsTableComponent } from './manage-regions/manage-regions-table/manage-regions-table.component';

const routes: Routes = [
  {
    path: 'signup-status',
    component: SignupStatusComponent,
  },
  {
    path: 'manage-regions',
    component: ManageRegionsTableComponent,
  },
  {
    path: '',
    redirectTo: 'signup-status',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HelpToolsRoutingModule {}
