import { Options } from './../../../../../../@core/interfaces/_index.interfaces';
import { NotificationService } from './../../../../../../@core/services/notification.service';
import { ConnectionService, PartnerService } from './../../../../services/_index-billing.services';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';
import {
  ConnectionFullInfo,
  Partner,
  connectionApi,
  ConnectionCreateInput,
} from '../../../../interfaces/_index.billing.interfaces';

@Component({
  selector: 'tgx-admin-billing-connections-main-modal',
  styleUrls: ['./connections-main-modal.component.scss'],
  templateUrl: 'connections-main-modal.component.html',
})
export class BillingConnectionsMainModalComponent implements OnInit {
  @Input() connections: ConnectionFullInfo[];
  partners: Partner[];

  buyerOptions: any[];
  sellerOptions: any[];

  public connectionForm: UntypedFormGroup;

  connectionApis: Options[] = [{ code: null, label: 'Select One' }];

  isLoading = false;

  constructor(
    private notificationService: NotificationService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private connectionService: ConnectionService,
    private partnerService: PartnerService,
  ) {}

  async ngOnInit() {
    this.isLoading = true;

    await this.partnerService
      .getAllPartners()
      .then((partns) => {
        this.partners = partns;
        this.buildBuyerSellerDropdown();
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      });

    this.buildForm();
    this.buildaConnectionApisSelect();
    this.connectionForm.get('connectionApi').setValue(this.connectionApis[0]);
    this.connectionForm.get('buyer').setValue(this.buyerOptions[0]);
    this.connectionForm.get('seller').setValue(this.sellerOptions[0]);
    this.isLoading = false;
  }

  buildBuyerSellerDropdown() {
    this.buyerOptions = [{ value: null, title: 'Select One' }];
    this.sellerOptions = [{ value: null, title: 'Select One' }];

    this.partners.forEach((p) => {
      this.buyerOptions.push({
        value: p.orgCode,
        title: `${p.name} (${p.orgCode})`,
      });
      this.sellerOptions.push({
        value: p.orgCode,
        title: `${p.name} (${p.orgCode})`,
      });
    });
  }

  buildForm() {
    this.connectionForm = this.fb.group({
      isPush: [false, Validators.required],
      connectionApi: ['', Validators.required],
      buyer: ['', Validators.required],
      seller: ['', Validators.required],
    });
  }

  validDropdown(dropdownType: string) {
    if (!this.connectionForm.get(dropdownType).value.code) {
      this.connectionForm.get(dropdownType).setErrors({ 'Required': true });
    } else {
      this.connectionForm.get(dropdownType).setErrors(null);
    }
  }

  buildaConnectionApisSelect() {
    Object.keys(connectionApi).forEach((value) => {
      this.connectionApis.push({ code: value, label: value });
    });
  }

  closeModal() {
    this.activeModal.close(true);
  }

  onSave() {
    //Mutation create
    const buyerCode = this.connectionForm.get('buyer').value.value;
    const sellerCode = this.connectionForm.get('seller').value.value;

    let index = this.connections.findIndex((x) => x.buyerCode === buyerCode && x.sellerCode === sellerCode);

    if (index > -1) {
      this.notificationService.warning('This connection (buyer and seller pair) already exists.');
      return;
    }

    index = this.partners.findIndex((x) => x.orgCode === buyerCode);

    if (index === -1) {
      this.notificationService.warning('Buyer not found as partner.');
      return;
    }

    index = this.partners.findIndex((x) => x.orgCode === sellerCode);

    if (index === -1) {
      this.notificationService.warning('Seller not found as partner.');
      return;
    }

    const createData: ConnectionCreateInput = {
      isPush: this.connectionForm.get('isPush').value,
      api: this.connectionForm.get('connectionApi').value.code,
      buyer: this.partners.filter((x) => x.orgCode === buyerCode)[0].id,
      seller: this.partners.filter((x) => x.orgCode === sellerCode)[0].id,
    };

    this.connectionService
      .createConnection(createData)
      .then((rs) => {
        this.notificationService.success('Main connection succesfully created', 'Success');
        this.activeModal.close(rs);
      })
      .catch((err) => {
        this.activeModal.close(false);
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      });
  }

  validForm(): boolean {
    return (
      this.connectionForm?.get('connectionApi').value?.code &&
      this.connectionForm.get('buyer').value?.value &&
      this.connectionForm.get('seller').value?.value &&
      this.connectionForm.valid
    );
  }
}
