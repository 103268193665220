import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Options } from './../../../@core/interfaces/_index.interfaces';

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
const MAX_PAGES = 3;

@Component({
  selector: 'app-letter-carousel',
  templateUrl: './letter-carousel.component.html',
  styleUrls: ['./letter-carousel.component.scss'],
})
export class LetterCarouselComponent implements OnInit {
  @Input() selectedSupplier: Options | null = null;
  @Output() letterSelected = new EventEmitter<string>();

  startIndex = 0;
  visibleLetters: string[] = [];
  selectedLetter = ALPHABET[0];
  lettersPerPage = Math.ceil(ALPHABET.length / MAX_PAGES);
  alphabetLength = ALPHABET.length;

  ngOnInit() {
    this.updateVisibleLetters();
    this.letterSelected.emit(this.selectedLetter);
  }

  updateVisibleLetters() {
    this.visibleLetters = ALPHABET.slice(this.startIndex, this.startIndex + this.lettersPerPage);
  }

  nextPage() {
    if (this.startIndex + this.lettersPerPage < this.alphabetLength) {
      this.startIndex += this.lettersPerPage;
      this.updateVisibleLetters();
    }
  }

  prevPage() {
    if (this.startIndex > 0) {
      this.startIndex -= this.lettersPerPage;
      this.updateVisibleLetters();
    }
  }

  selectLetter(letter: string) {
    this.selectedLetter = letter;
    this.letterSelected.emit(letter);
  }

  onScroll(event: WheelEvent) {
    event.deltaY > 0 ? this.nextPage() : this.prevPage();
  }
}
