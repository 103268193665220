export interface CommercialTemplate {
  ID?: number;
  Type?: string;
  Name?: string;
  ContractTypeName?: string;
  IdBlob?: string;
  Version?: number;
  CreatedAt?: Date;
  UpdatedAt?: Date;
}

export interface CommercialTemplateResponse {
  success?: boolean;
  response?: CommercialTemplate[];
}

export enum CommercialTypeTemplate {
  BUYER = 'BUYER',
  SELLER = 'SELLER',
  OTHER = 'OTHER',
}
