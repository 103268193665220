import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../../../../@core/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClientsRoutingModule } from './clients-routing.module';
import { ClientsTableComponent } from './components/clients-table/clients-table.component';

import { ClientsComponent } from './components/clients.component';

import { NbActionsModule, NbCardModule, NbCheckboxModule, NbIconModule, NbToggleModule } from '@nebular/theme';
import { ClientsLegacyConnectionModalComponent } from './components/clients-legacy-connection-modal/clients-legacy-connection-modal.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';

import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';

@NgModule({
  declarations: [ClientsTableComponent, ClientsComponent, ClientsLegacyConnectionModalComponent],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ClientsRoutingModule,
    NbActionsModule,
    NbCardModule,
    NbCheckboxModule,
    NbIconModule,
    MultiSelectModule,
    DropdownModule,
    NbToggleModule,
    TableModule,
    InputSwitchModule,
  ],
})
export class ClientsModule {}
