<div class="toast-container position-fixed top-0 end-0 p-3"></div>

<ngb-toast
  [ngClass]="classes"
  header="{{ header }}"
  (hide)="showToast = false"
  [delay]="delay || 5000"
  [autohide]="autohide"
  show="show"
  [autohide]="autohide"
>
  {{ content }}
</ngb-toast>
