import { NotFoundComponent } from './../../../../@core/shared/not-found/not-found.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BillingInvoicingProcessComponent } from './invoicing-process.component';
import { InvoicingProcessPreStepComponent } from './components/invoicing-process-pre-step/invoicing-process-pre-step.component';
import { InvoicingProcessPostStepComponent } from './components/invoicing-process-post-step/invoicing-process-post-step.component';
import { InvoicingSchedulesTableComponent } from './components/invoicing-schedules/invoicing-schedules.component';

const routes: Routes = [
  {
    path: '',
    component: BillingInvoicingProcessComponent,
    children: [
      {
        path: 'pre-step',
        component: InvoicingProcessPreStepComponent,
      },
      {
        path: 'scheduled',
        component: InvoicingSchedulesTableComponent,
      },
      {
        path: 'post-step',
        component: InvoicingProcessPostStepComponent,
      },
      {
        path: '',
        redirectTo: 'pre-step',
        pathMatch: 'full',
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InvoicingProcessRoutingModule {}
