import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ProfileNetworkService } from '../../services/profile-network.service';
import { WebAdminService } from '../../../../@core/services/_index-core.services';
import { OrganizationNetworkService } from '../../services/organization-network.service';
import { ModuleType } from 'apps/web-admin/src/app/@core/interfaces/user-permissions';

@Component({
  selector: 'tgx-dashboard-orgs-with-profile',
  templateUrl: './dashboard-orgs-with-profile.component.html',
  styleUrls: ['./dashboard-orgs-with-profile.component.scss'],
})
export class DashboardOrgsWithProfileComponent implements OnInit {
  totalOrgs = 0;
  orgsWithoutPrf = 0;
  orgsWithPrf = 0;
  prfWithoutOrgs = 0;
  $subs: Subscription[] = [];
  filteredOrgList: any[] = [];
  localMapOrg: any[] = [];
  localMapPrf: any[] = [];

  mapPageCursor: Map<number, string> = new Map<number, string>();
  page = 1;

  constructor(
    private router: Router,
    private webAdminService: WebAdminService,
    private profileNetworkService: ProfileNetworkService,
    private organizationNetworkService: OrganizationNetworkService,
  ) {}

  ngOnInit(): void {
    this.webAdminService.emitUserTypevalue(ModuleType.DASHBOARD);

    this.$subs.push(
      this.webAdminService.allOrgs.subscribe((orgs) => {
        if (orgs?.length > 0) {
          this.totalOrgs = orgs.length;
        }
      }),
    );

    this.getOrgsWithoutProfile();
  }

  async getOrgsWithoutProfile() {
    // Executes asynchronously both 'getOrganizationsWidget'
    // and 'getProfilesWidget' requests
    const promises = [];
    promises.push(this.organizationNetworkService.getOrganizationsWidget());
    promises.push(this.profileNetworkService.getProfilesWidget());
    const results = await Promise.all(promises); // Waits both requests to be finished

    if (!results[0].error && !results[1]?.error) {
      this.localMapOrg = results[0].data;
      this.localMapPrf = results[1].data;

      this.filteredOrgList = this.organizationNetworkService.filteredOrgs(this.localMapOrg, this.localMapPrf, 'orgs');
      this.orgsWithoutPrf = this.filteredOrgList.length;

      const filteredPrfList = this.organizationNetworkService.filteredOrgs(this.localMapOrg, this.localMapPrf, 'prfs');
      this.prfWithoutOrgs = filteredPrfList.length;
    } else {
      // TODO: Error!
      if (results[0].error) console.log();
      if (results[1].error) console.log();
    }
  }

  ngDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
  }

  getValueOrgs() {
    this.orgsWithPrf = this.totalOrgs - this.orgsWithoutPrf;
    if (this.orgsWithPrf > 0) {
      return Math.round((this.orgsWithPrf * 100) / this.totalOrgs);
    }
    return 0;
  }

  goToOrgsTable() {
    this.organizationNetworkService.setOrganizationsCodes(this.filteredOrgList);
    this.router.navigate(['/entities/organizations']);
  }
}
