export interface MembersConnection {
  edges: MembersEdge[];
  totalCount: number;
}

export interface MembersEdge {
  node: Members[];
}

export interface Members {
  code: string;
  membersData: MembersData;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
}

export interface MembersData {
  id: string;
  code: string;
  label?: string;
  isActive: boolean;
  organizations?: any;
  type: MembersType;
}

export enum MembersType {
  USER = 'USER',
  SERVICE_ACCOUNT = 'SERVICE_ACCOUNT',
}

export enum ContactImportance {
  ExecutionLevel = 'EXECUTION_LEVEL',
  DecisionMaker = 'DECISION_MAKER',
  Primary = 'PRIMARY',
  Minor = 'MINOR',
}

export enum CompanyRole {
  AccountManagerBuyer = 'ACCOUNT_MANAGER_BUYER',
  AccountManagerSeller = 'ACCOUNT_MANAGER_SELLER',
  BillingBuyer = 'BILLING_BUYER',
  BillingSeller = 'BILLING_SELLER',
  Contracting = 'CONTRACTING',
  GenericAccount = 'GENERIC_ACCOUNT',
  Legal = 'LEGAL',
  PlatforPrimaryAccount = 'PLATFOR_PRIMARY_ACCOUNT',
  Press = 'PRESS',
  SalesBuyer = 'SALES_BUYER',
  SalesSeller = 'SALES_SELLER',
  ServiceProvider = 'SERVICE_PROVIDER',
  TechBuyer = 'TECH_BUYER',
  TechSeller = 'TECH_SELLER',
  Test = 'TEST',
  TgxHelpdeskUser = 'TGX_HELPDESK_USER',
  Unknown = 'UNKNOWN',
}
