<nb-card>
  <nb-card-header>
    <div class="row">
      <div class="org-container">
        <h3>Suppliers</h3>
        <h1 class="small-text">({{ totalSuppliers }})</h1>
      </div>
      <div class="d-flex justify-content-end">
        <form [formGroup]="queryFormOrganizations">
          <div class="d-flex align-items-center">
            <span class="p-2">Show archived</span>
            <nb-toggle
              [checked]="false"
              formControlName="includeArchived"
              (change)="retrieveAllSuppliers()"
            ></nb-toggle>
          </div>
        </form>
        <!-- <button class="btn btn-md btn-danger" (click)="resetTable()">Reset</button> -->
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <p-table
      *ngIf="!isLoading"
      [value]="suppliersLoadSource"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      [tableStyle]="{ 'min-width': '50rem' }"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
      styleClass="p-datatable-gridlines p-datatable-striped"
      #dataTable
    >
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="fullName">
            Name(Code)
            <p-sortIcon field="fullName"></p-sortIcon>
          </th>
          <th pSortableColumn="providerCode">
            Provider Code
            <p-sortIcon field="providerCode"></p-sortIcon>
          </th>
          <th pSortableColumn="owner">
            Owner Code
            <p-sortIcon field="owner"></p-sortIcon>
          </th>
          <th pSortableColumn="groupContentCode_context">
            Group Content: Context
            <p-sortIcon field="groupContentCode_context"></p-sortIcon>
          </th>
          <th pSortableColumn="api">
            Service Api
            <p-sortIcon field="api"></p-sortIcon>
          </th>
          <th pSortableColumn="isPublic">
            Is Public
            <p-sortIcon field="isPublic"></p-sortIcon>
          </th>
          <th pSortableColumn="isActive">
            Active
            <p-sortIcon field="isActive"></p-sortIcon>
          </th>
          <th pSortableColumn="deletedAt">
            Archived
            <p-sortIcon field="deletedAt"></p-sortIcon>
          </th>
          <th>Options</th>
        </tr>
        <tr>
          <th>
            <p-columnFilter
              [showClearButton]="false"
              placeholder="Name(Code)"
              type="text"
              field="fullName"
              matchMode="contains"
              (input)="dt1.filter($event.target.value, 'fullName', 'contains')"
            ></p-columnFilter>
          </th>
          <th>
            <p-columnFilter
              [showClearButton]="false"
              placeholder="Provider Code"
              type="text"
              field="providerCode"
              matchMode="contains"
              (input)="dt1.filter($event.target.value, 'providerCode', 'contains')"
            ></p-columnFilter>
          </th>
          <th>
            <p-columnFilter
              [showClearButton]="false"
              placeholder="Owner Code"
              type="text"
              field="owner"
              matchMode="contains"
              (input)="dt1.filter($event.target.value, 'owner', 'contains')"
            ></p-columnFilter>
          </th>
          <th>
            <p-columnFilter
              [showClearButton]="false"
              placeholder="Group Content: Context"
              type="text"
              field="groupContentCode_context"
              matchMode="contains"
              (input)="dt1.filter($event.target.value, 'groupContentCode_context', 'contains')"
            ></p-columnFilter>
          </th>
          <th>
            <p-columnFilter
              [showClearButton]="false"
              placeholder="Service Api"
              type="text"
              field="api"
              matchMode="contains"
              (input)="dt1.filter($event.target.value, 'api', 'contains')"
            ></p-columnFilter>
          </th>
          <th>
            <p-columnFilter [showClearButton]="false" type="boolean" field="isPublic"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter [showClearButton]="false" type="boolean" field="isActive"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter [showClearButton]="false" type="boolean" field="deletedAt"></p-columnFilter>
          </th>
          <th>
            <p-button class="p-button" icon="pi pi-plus" (onClick)="onCreate()"></p-button>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-supplier>
        <tr>
          <td>{{ supplier.fullName }}</td>
          <td [ngClass]="!supplier.providerIsActive ? 'disabledProvider' : ''">
            <span [title]="!supplier.providerIsActive ? 'NO ACTIVE' : ''">{{ supplier.providerCode }}</span>
          </td>
          <td>{{ supplier.owner }}</td>
          <td>{{ supplier.groupContentCode_context }}</td>
          <td>{{ supplier.api }}</td>
          <td>
            <p-inputSwitch (onChange)="onToggleIsPublic(supplier)" [(ngModel)]="supplier.isPublicModel"></p-inputSwitch>
          </td>
          <td>
            <p-inputSwitch
              [disabled]="supplier.deletedAt || !supplier.providerIsActive"
              (onChange)="onToggleActivateDeactiveSupplier(supplier)"
              [(ngModel)]="supplier.isActiveModel"
            ></p-inputSwitch>
          </td>
          <td>
            <p-inputSwitch
              [disabled]="!supplier.providerIsActive"
              (onChange)="onToggleArchiveDearchiveSupplier(supplier)"
              [(ngModel)]="supplier.isArchived"
            ></p-inputSwitch>
          </td>
          <td width="1rem">
            <i class="nb-edit icon" title="Edit supplier" (click)="onEdit(supplier)"></i>
            <i class="nb-collapse icon" title="Legacy Connections" (click)="onLegacyConnections(supplier)"></i>
            <i class="nb-compose icon" title="Supplier GIATA" (click)="onGiataInfo(supplier)"></i>
            <i class="nb-bar-chart icon" title="Accesses" (click)="onAccess(supplier)"></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
  <div *ngIf="isLoading">
    <tgx-spinner [class]="'routingLoading'" [isLoading]="isLoading" [top]="'100px'"></tgx-spinner>
  </div>
</nb-card>
