import { Injectable } from '@angular/core';
import {
  ContractType,
  ContractTypeCreateInput,
  ContractTypeUpdateInput,
  ObjectWhereUniqueInputById,
} from './../interfaces/_index.billing.interfaces';
import { GraphqlApiBillingQueries } from './queries/_index-billing-queries';
import { GqlService } from '@tgx/shared/data-access/graphql';
import { GraphqlApiBillingMutations } from './mutations/_index-billing-mutations';

@Injectable({
  providedIn: 'root',
})
export class ContractTypeService {
  constructor(private gqlService: GqlService) {}

  async getContractTypes(): Promise<ContractType[]> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .queryBilling(GraphqlApiBillingQueries.getContractTypes, {})
        .then((res: any) => {
          if (res?.contractTypes) {
            resolve(res.contractTypes);
          } else {
            reject(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async createContractType(data: ContractTypeCreateInput): Promise<ContractType> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationBilling(GraphqlApiBillingMutations.createContractType, { data: data })
        .then((res: any) => {
          if (res?.createContractType) {
            resolve(res.createContractType);
          } else {
            reject(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async updateContractType(data: ContractTypeUpdateInput, where: ObjectWhereUniqueInputById): Promise<ContractType> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationBilling(GraphqlApiBillingMutations.updateContractType, { data: data, where: where })
        .then((res: any) => {
          if (res?.updateContractType) {
            resolve(res.updateContractType);
          } else {
            reject(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
