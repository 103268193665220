import { SharedModule } from './../../../../@core/shared/shared.module';
import { CommonModule } from '@angular/common';
import { PartnerRoutingModule } from './partner.routing-module';

import { PartnerComponent } from './components/partner.component';
import { PartnerTableComponent } from './components/partners-table/partners-table.component';
import { PartnersModalComponent } from './components/partners-modal/partners-modal.component';

import { NgModule } from '@angular/core';
import { NbCardModule, NbCheckboxModule } from '@nebular/theme';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';

@NgModule({
  imports: [
    TableModule,
    InputTextModule,
    ButtonModule,
    SharedModule,
    PartnerRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NbCheckboxModule,
    NbCardModule,
    CommonModule,
  ],
  declarations: [PartnerComponent, PartnerTableComponent, PartnersModalComponent],
})
export class PartnerModule {}
