export interface ConciliateBreakdown {
  quantity: number;
  amount: number;
}

export interface NewPricingBreakdownConciliateItem {
  ok?: ConciliateBreakdown;
  cancel?: ConciliateBreakdown;
}

export interface NewPricingItem {
  clientName?: string;
  supplierName?: string;
  supplierCode?: string;
  gmv?: NewPricingBreakdownConciliateItem;
  bookings?: NewPricingBreakdownConciliateItem;
}

export interface NewPricingConciliateConnection {
  contractConnectionId: number;
  conciliateNewPricingConnectionItems: NewPricingItem[];
}

export interface NewPricingConciliateData {
  conciliateConnections: NewPricingConciliateConnection[];
}

export interface ConciliateConnectionItem {
  clientName?: string;
  supplierName?: string;
  supplierCode?: string;
  setup?: ConciliateBreakdown;
  maintenance?: ConciliateBreakdown;
  traffic?: ConciliateBreakdown;
  gmv?: ConciliateBreakdown;
  excess?: ConciliateBreakdown;
  bookings?: ConciliateBreakdown;
}

export interface ConciliateConnection {
  contractConnectionId: number;
  conciliateConnectionItems: ConciliateConnectionItem[];
}

export interface ConciliateData {
  conciliateConnections: ConciliateConnection[];
}
