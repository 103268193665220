import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'xen-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  @Input() items = [];
  @Input() id: string;
  @Input() full_width: boolean;
  @Input() type: 'default' | 'pills' = 'default';

  @Output() tabChangeEvent = new EventEmitter<string>();

  constructor() {}

  public get classes(): Array<string> {
    return [this.full_width ? 'is-full' : ''];
  }

  public get navClasses(): Array<string> {
    return [`nav-${this.type}`];
  }

  ngOnInit(): void {}

  tabChange(value: any) {
    this.tabChangeEvent.emit(value.target.id);
  }
}
