import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NbMenuModule, NbSidebarModule } from '@nebular/theme';
import { NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import { LoginGuard } from '@tgx/shared/guards';
import { ToastsModule } from '@tgx/shared/toasts';
import { ToastrModule } from 'ngx-toastr';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { environment } from './../../../../environments/environment';
import { CoreModule } from './@core/core.module';
import { AccessTokenInterceptor } from './@core/interceptors/_index.interceptors';
import { SharedModule } from './@core/shared/shared.module';
import { ThemeModule } from './@theme/theme.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BillingModule } from './features/billing/billing.module';
import { EntitiesModule } from './features/entities/entities.module';
import { NetworkModule } from './features/network/network.module';
import { ContentModule } from './features/content/content.module';
import { WebModule } from './features/web/web.module';
import { DashboardModule } from './features/dashboard/dashboard.module';
import { AppsModule } from './features/apps/apps.module';
import { MappingModule } from './features/mapping/mapping.module';
import { SharedUiStorybookModule } from '@tgx/shared/ui/storybook';
import { SharedUiStylesModule } from '@tgx/shared/ui/styles';
import { HttpHeadersInterceptor } from '@tgx/shared/data-access/interceptors';

@NgModule({
  declarations: [AppComponent],
  imports: [
    ToastsModule,
    RouterModule,
    CommonModule,
    DropdownModule,
    AutoCompleteModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedUiStorybookModule,
    SharedUiStylesModule,
    NgbModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production ? [] : [],
      },
    ),
    CoreModule,
    DashboardModule,
    BillingModule,
    EntitiesModule,
    AppsModule,
    NetworkModule,
    MappingModule,
    ThemeModule.forRoot(),
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 4000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    NgbPopoverModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    CoreModule.forRoot().providers,
    BillingModule.forRoot().providers,
    EntitiesModule.forRoot().providers,
    AppsModule.forRoot().providers,
    NetworkModule.forRoot().providers,
    ContentModule.forRoot().providers,
    WebModule.forRoot().providers,
    //AdminModule.forRoot().providers,
    {
      provide: 'domain',
      useValue: environment.auth0.domain,
    },
    {
      provide: 'clientId',
      useValue: environment.auth0.clientId,
    },
    {
      provide: 'application',
      useValue: 'admin',
    },
    { provide: HTTP_INTERCEPTORS, useClass: AccessTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpHeadersInterceptor, multi: true },
    LoginGuard,
  ],
  exports: [HttpClientModule],
})
export class AppModule {}
