import { gql } from 'apollo-angular';

export const getOrganizationGiata = gql`
  query Organizations($code: ID!) {
    admin {
      organization(where: { search: $code, searchBy: CODE }) {
        organizationsData {
          code
          giata {
            configuration
            isActive
          }
          adviseMessage {
            code
            description
            level
          }
        }
      }
    }
  }
`;
