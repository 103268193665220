import { gql } from 'apollo-angular';

export const setSupplierDefaultSharedAccess = gql`
  mutation setSupplierDefaultSharedAccess($where: SupplierWhereUniqueInput!, $sharedCode: ID!) {
    admin {
      setSupplierDefaultSharedAccess(where: $where, sharedDefaultCode: $sharedCode) {
        code
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
