import { Options } from './../interfaces/option';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  multiSelectOrgs = new BehaviorSubject<Options[]>(null);
}
