<div class="xen-meta-info mb-1">
  <div class="xen-meta-info__wrapper">
    <div class="xen-meta-info__header">
      <div class="mb-3">
        <p class="xen-meta-info__title">{{ title }}</p>
        <p class="mb-0 xen-meta-info__name">
          {{ name }}<span class="xen-meta-info__name-caption">{{ caption }}</span>
        </p>
      </div>
      <div class="d-flex align-items-center justify-content-between">
        <p class="mb-0">
          <span class="xen-meta-info__subtitle">Type: </span>
          <xen-badge type="label" label="{{ type | titlecase }}" status="{{ type }}" theme="light"></xen-badge>
        </p>
        <p class="mb-0">
          <span class="xen-meta-info__subtitle state" [ngClass]="stateClass">State: </span>
        </p>
      </div>
    </div>
    <div class="mb-3 xen-meta-info__body" *ngIf="accessInfo">
      <div *ngIf="accessName">
        <p class="xen-meta-info__title">Access name:</p>
        <p class="xen-meta-info__name xen-meta-info__name--small">{{ accessName }}</p>
      </div>
      <div *ngIf="accessInfo">
        <div class="d-flex justify-content-between flex-wrap my-2">
          <div class="d-flex flex-column" *ngIf="accessStatus" [ngSwitch]="accessStatus">
            <p class="mb-0 xen-meta-info__subtitle">Status:</p>
            <xen-badge *ngSwitchCase="'working'" type="label" label="Working" status="success"></xen-badge>
            <xen-badge *ngSwitchCase="'pending'" type="label" label="Pending" status="warning"></xen-badge>
            <xen-badge *ngSwitchCase="'notWorking'" type="label" label="Not working" status="error"></xen-badge>
          </div>
          <div *ngIf="accommodations" class="d-flex flex-column">
            <p class="mb-0 xen-meta-info__subtitle">Accommodations:</p>
            <xen-link label="{{ accommodations.value }}" href="{{ accommodations.href }}"></xen-link>
          </div>
        </div>
        <div class="d-flex flex-column my-3" *ngIf="relatedTickets?.length > 0">
          <p class="mb-0 xen-meta-info__subtitle">Related tickets:</p>
          <xen-link
            *ngFor="let ticket of relatedTickets"
            label="{{ ticket.label }}"
            href="{{ ticket.href }}"
          ></xen-link>
        </div>
        <div class="d-flex flex-column my-3" [ngSwitch]="accessStatus">
          <div *ngSwitchCase="'working'">
            <p class="mb-0 xen-meta-info__subtitle">Active:</p>
            <xen-switch [checked]="true"></xen-switch>
          </div>
          <p *ngSwitchCase="'pending'" class="xen-meta-info__subtitle is-inactive">Inactive</p>
          <p *ngSwitchCase="'notWorking'" class="xen-meta-info__subtitle is-inactive">Inactive</p>
        </div>
      </div>
    </div>

    <div class="xen-meta-info__footer" *ngIf="footer">
      <p class="mb-0" *ngFor="let item of footerInfo">{{ item }}</p>
    </div>
  </div>
</div>
