import { gql } from 'apollo-angular';

export const getContractPlugins = gql`
  query contractPlugins($where: ContractPluginWhereInput!) {
    contractPlugins(where: $where) {
      id
      activationDateTime
      endDate
      code
      Plugin {
        pluginType
      }
      createdAt
      updatedAt
    }
  }
`;
