import { firstValueFrom, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ToastsService } from '@tgx/shared/toasts';
import { LoginResponse, Message, Room } from '@tgx/shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  matrixSdk = window['matrixcs' as any];

  userId = '';
  accessToken = '';
  rooms: Room[] = [];
  messages: Message[] = [];

  rooms$ = new Subject<Room[]>();
  messages$ = new Subject<Message[]>();
  client: any;
  isLoged = false;
  constructor(
    private http: HttpClient,
    private toastsService: ToastsService,
    @Inject('urlNotificationsService') private urlNotificationsService: string,
  ) {}

  getUser() {
    this.http.post<LoginResponse>(`${this.urlNotificationsService}admin/login-synapse`, null).subscribe({
      next: (response: LoginResponse) => {
        this.userId = response['user_id'];
        this.accessToken = response['access_token'];
        this.createClient();
      },
      error: (err) => {
        // this.toastsService.addToast(`Notifications error: ${err}`, 'bg-danger');
        // console.log(err);
      },
    });
  }

  async createClient() {
    //@ts-ignore
    this.client = this.matrixSdk.createClient({
      baseUrl: 'https://matrix.travelgatex.com/',
      accessToken: this.accessToken,
      userId: this.userId,
    });
    this.client.startClient(50); // This is the number of events in the timeline to retrieve
    this.client.once('sync', (res: any) => {
      if (res.error) {
        // this.toastsService.addToast(`Notifications error: ${res.error.message}`, 'bg-danger');
        this.client.stopClient();
      }
      if (!res.error) {
        this.loadInitialData();
        this.listenEvents();
      }
    });
  }

  async loadInitialData() {
    const rooms = await this.client.getRooms();
    this.rooms = [];
    this.messages = [];
    const readMarkerTimestamp = this.getReadMarkerTimestamp();

    for (let roomIdx = 0; roomIdx < rooms.length; roomIdx++) {
      const roomData = rooms[roomIdx];
      const room: Room = {
        id: roomData.roomId,
        name: roomData.name.split(':')[0].trim(),
      };
      const roomMessages = (await this.getMessagesFromRoom(room.id))?.chunk.sort(
        (x, y) => x.origin_server_ts - y.origin_server_ts,
      );
      room.messages = [];
      for (let msgIdx = 0; msgIdx < roomMessages.length; msgIdx++) {
        const message = roomMessages[msgIdx];

        if (message.content.body && !message.content['m.new_content']) {
          let isRead = false;
          if (message.content.read === undefined) {
            isRead = message.origin_server_ts <= readMarkerTimestamp;
          } else {
            isRead = message.content.read;
          }
          const formattedMessage = {
            eventId: message.event_id,
            roomName: room.name,
            roomId: message.room_id,
            sender: message.sender.split(':')[0].slice(1),
            content: message.content,
            isRead: isRead,
            timeStamp: message.origin_server_ts,
          };
          room.messages.push(formattedMessage);
          this.addMessage(formattedMessage);
        }
      }
      this.rooms.push(room);
    }

    this.rooms$.next(this.rooms);
    this.messages = this.messages.sort((msgA, msgB) => msgB.timeStamp - msgA.timeStamp);
    this.messages$.next(this.messages);
  }

  private getReadMarkerTimestamp() {
    // INFO: Hardcoded to 04/03/2023 due to the implementation of the new read marker system
    // could be deleted once the expiration policy for notifications has been set
    return 1677884400000;
  }

  private async getMessagesFromRoom(roomId: string): Promise<any> {
    const messages = await firstValueFrom(
      this.http.get<any>(`${this.urlNotificationsService}get-messages`, {
        params: {
          'room-id': roomId,
        },
      }),
    );
    return messages;
  }

  private addMessage(message: Message) {
    const messageExists = this.messages.find((m) => m.eventId === message.eventId);
    if (!messageExists) {
      this.messages.unshift(message);
    }
  }

  async markAllAsRead(roomId: string, eventId: string) {
    await this.client.setRoomReadMarkers(roomId, eventId);
  }

  async markAsRead(notificationData) {
    this.http.post<any>(`${this.urlNotificationsService}mark-as-read`, { notification: notificationData }).subscribe({
      next: (_response: any) => {
        // Ok
      },
      error: (err) => {
        // this.toastsService.addToast(`Notifications error: ${err}`, 'bg-danger');
        // console.log(err);
      },
    });
  }

  listenEvents() {
    this.client.on('event', async (evt: any) => {
      if (evt.event.type === 'm.fully_read') {
        const readMarkerTimestamp = this.getReadMarkerTimestamp();
        for (let i = 0; i < this.messages.length; i++) {
          const msg = this.messages[i];
          if (msg.timeStamp <= readMarkerTimestamp) msg.isRead = true;
        }
        this.messages$.next(this.messages);
      }
    });
    this.client.on('Room.timeline', (evt: any) => {
      if (evt.event.type === 'm.room.message') {
        const room = this.rooms.find((room: Room) => room.id === evt.event.room_id);
        if (evt.event.content.body && !evt.event.content['m.new_content']) {
          const formattedMessage = {
            eventId: evt.getId(),
            roomName: room?.name || 'Unknown room',
            roomId: evt.event.room_id,
            sender: evt.event.sender.split(':')[0].slice(1),
            content: evt.event.content,
            isRead: false,
            timeStamp: evt.event.origin_server_ts,
          };
          room.messages.push(formattedMessage);
          this.addMessage(formattedMessage);
        }
      }
      this.messages$.next(this.messages);
    });
  }
}
