import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Hotel, HotelList } from '../../interfaces/models/mapping.interfaces';
import { hsCountryListIsoCodes } from '@tgx/shared/utils';
import { MappingService } from '../../../../services/mapping.service';
import { Subscription, lastValueFrom } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RemapModalComponent } from '../remap-modal/remap-modal.component';

@Component({
  selector: 'tgx-unmaped-table',
  templateUrl: './unmaped-table.component.html',
  styleUrls: ['./unmaped-table.component.scss'],
})
export class UnmapedTableComponent {
  @Input() formParams: { countryMapping: string; hotelMapping: string };
  @Output() loadDataEvent = new EventEmitter<{ options: string }>();

  unmappedList: HotelList[];
  unmappedCount: number;
  errorUnmapped: string;

  page = 1;
  pageSize = 10;

  tHead = [
    { label: 'Hotel master', field: 'name' },
    { label: 'Hotel Code', field: 'hotel_code' },
    { label: 'Geocode', field: 'location' },
    { label: 'Country', field: 'country' },
    { label: 'Address', field: 'address' },
  ];
  isLoading: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private mappingService: MappingService,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.mappingService.unmappedList.subscribe(async (unmappedList) => {
        this.unmappedList = unmappedList;
      }),
    );
    this.subscriptions.push(
      this.mappingService.unmappedCount.subscribe(async (unmappedCount) => {
        this.unmappedCount = unmappedCount;
      }),
    );
    this.subscriptions.push(
      this.mappingService.errorUnmapped.subscribe(async (errorUnmapped) => {
        this.errorUnmapped = errorUnmapped;
      }),
    );
  }

  findNameCountry(isoCode2: string) {
    const country = hsCountryListIsoCodes.find((x) => x.isoCode2 === isoCode2);
    if (country) {
      return country.name;
    }
    return '';
  }

  async onPageChange(page: any) {
    let unmaped: Hotel;
    let results: PromiseSettledResult<any>[];
    this.page = page;
    let offset = (page - 1) * 10;
    this.isLoading = true;
    unmaped = (await lastValueFrom(
      this.mappingService.buildAndLaunchRequestWithParams(
        this.mappingService.getUnmappedHotelsEndPoint(),
        this.formParams.countryMapping,
        offset,
        10,
        this.formParams.hotelMapping,
      ),
    )) as Hotel;
    results = await Promise.allSettled([unmaped]);

    if (results[0].status === 'fulfilled') {
      if (results[0].value.hotel_list.length === 0) {
        this.mappingService.errorUnmapped.next('No unmapped hotels found');
      } else {
        this.mappingService.unmappedList.next(results[0].value.hotel_list);
      }
    }
    this.isLoading = false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  reLoadData(options?: string) {
    this.loadDataEvent.emit({
      options: options,
    });
  }

  async openRemapHotelModal(unmapedHotel: HotelList) {
    const modalRef = this.modalService.open(RemapModalComponent, { size: 'xl', windowClass: 'xen-modal' });
    modalRef.componentInstance.selectedHotel = unmapedHotel;
    modalRef.componentInstance.formParams = this.formParams;
    modalRef.result
      .then(async (result: any) => {
        if (result) {
          this.reLoadData('unmap');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
