import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { hsCountryListIsoCodes } from '@tgx/shared/utils';
import { MappingService } from '../../../services/mapping.service';
import { Country, HotelList, MasterList } from '../interfaces/models/mapping.interfaces';
import { Subscription } from 'rxjs';
import { OptionMapping } from '../interfaces/models/enums.interfaces';

@Component({
  selector: 'tgx-mapping-tool',
  templateUrl: './mapping-tool.component.html',
  styleUrls: ['./mapping-tool.component.scss'],
})
export class MappingToolComponent {
  countriesList: { name: string; isoCode2: string }[] = hsCountryListIsoCodes;

  selectedCountry: Country;
  mappingForm: FormGroup;
  isDisabled: boolean = true;
  formParams: { countryMapping: string; hotelMapping: string };

  nonValidateCount: number;
  validateCount: number;
  unmappedCount: number;

  nonValidateMasters: MasterList[] = [];
  validateMasters: MasterList[] = [];
  unmappedList: HotelList[] = [];

  errorNonValidate: string;
  errorValidate: string;
  errorUnmapped: string;

  tabs = [
    { label: `Non validated Masters (${0})`, target: 'non', class: 'active', id: '1' },
    { label: `Validated Masters (${0})`, target: 'validated', class: '', id: '2' },
    { label: `Unmapped properties (${0})`, target: 'unmapped', class: '', id: '3' },
  ];

  activeTab = '1';
  isValidateShow: boolean = false;
  isUnmapedShow: boolean = false;
  isLoading: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private mappingService: MappingService,
  ) {}

  async ngOnInit() {
    this.subscriptions.push(
      this.mappingService.nonValidateMasters.subscribe(async (nonValidateMasters) => {
        this.nonValidateMasters = nonValidateMasters;
      }),
    );
    this.subscriptions.push(
      this.mappingService.nonValidateCount.subscribe(async (nonValidateCount) => {
        this.nonValidateCount = nonValidateCount;
      }),
    );
    this.subscriptions.push(
      this.mappingService.validateMasters.subscribe(async (validateMasters) => {
        this.validateMasters = validateMasters;
      }),
    );
    this.subscriptions.push(
      this.mappingService.validateCount.subscribe(async (validateCount) => {
        this.validateCount = validateCount;
      }),
    );
    this.subscriptions.push(
      this.mappingService.unmappedList.subscribe(async (unmappedList) => {
        this.unmappedList = unmappedList;
      }),
    );
    this.subscriptions.push(
      this.mappingService.unmappedCount.subscribe(async (unmappedCount) => {
        this.unmappedCount = unmappedCount;
      }),
    );
    this.subscriptions.push(
      this.mappingService.errorNonValidate.subscribe(async (errorNonValidate) => {
        this.errorNonValidate = errorNonValidate;
      }),
    );
    this.subscriptions.push(
      this.mappingService.errorValidate.subscribe(async (errorValidate) => {
        this.errorValidate = errorValidate;
      }),
    );
    this.subscriptions.push(
      this.mappingService.errorUnmapped.subscribe(async (errorUnmapped) => {
        this.errorUnmapped = errorUnmapped;
      }),
    );
    this.createMappingForm();
  }

  async onLoadDataEvent(data?: { options: string }) {
    if (data?.options === OptionMapping.UNMAP) {
      await this.mappingService.loadData(this.formParams, OptionMapping.UNMAP);
    } else {
      await this.mappingService.loadData(this.formParams);
    }
    await this.setTotals();
  }

  private createMappingForm() {
    this.mappingForm = this.fb.group({
      countryMapping: this.fb.control('', [Validators.required]),
      hotelMapping: this.fb.control({ value: '', disabled: this.isDisabled }),
    });
  }

  selectTab(tab_id: string) {
    this.activeTab = tab_id;
    this.tabs.forEach((tab) => {
      tab.class = tab.id === tab_id ? 'active show' : '';
    });
  }

  onCountryChange(event) {
    if (!event) this.formParams = null;
    return !event ? (this.isDisabled = true) : (this.isDisabled = false);
  }

  private async setTotals() {
    this.nonValidateCount
      ? (this.tabs[0].label = `Non validated Masters (${this.nonValidateCount})`)
      : (this.tabs[0].label = `Non validated Masters (0)`);
    this.validateCount
      ? (this.tabs[1].label = `Validated Masters (${this.validateCount})`)
      : (this.tabs[1].label = `Validated Masters (0)`);
    this.unmappedCount
      ? (this.tabs[2].label = `Unmapped properties (${this.unmappedCount})`)
      : (this.tabs[2].label = `Unmapped properties (0)`);
  }

  async onSubmit() {
    this.formParams = this.mappingForm.getRawValue();
    if (this.formParams) {
      this.isLoading = true;
      await this.mappingService.loadData(this.formParams, OptionMapping.ALL);
      await this.setTotals();
      this.isLoading = false;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
