import { NotificationService } from './../../../../../../../@core/services/notification.service';
import { ContractTypeService } from './../../../../../services/_index-billing.services';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { ContractTypeCreateInput, ContractTypeUpdateInput } from '../../../../../interfaces/_index.billing.interfaces';

@Component({
  selector: 'tgx-admin-billing-subtype-modal',
  styleUrls: ['./subtype-modal.component.scss'],
  templateUrl: 'subtype-modal.component.html',
})
export class BillingContractSubTypeModalComponent implements OnInit {
  @Input() contracType: any = null;
  @Input() header: string;

  public subTypeForm: UntypedFormGroup;
  isCreating = false;

  constructor(
    private notificationService: NotificationService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private readonly contractTypeService: ContractTypeService,
  ) {}

  async ngOnInit() {
    this.subTypeForm = this.fb.group({
      name: [this.contracType ? this.contracType.name : '', [Validators.required, Validators.minLength(3)]],
      odooDepartment: [this.contracType ? this.contracType.odooDepartment : ''],
    });

    this.isCreating = this.contracType === null;
    if (!this.isCreating) {
      this.subTypeForm.get('name').disable();
    }
  }

  closeModal() {
    this.activeModal.close(true);
  }

  onSave() {
    //const name = (<string>this.subTypeForm.get('name').value).toUpperCase().trim();
    const name = this.subTypeForm.get('name').value;
    const odooDepartment = this.subTypeForm.get('odooDepartment').value
      ? this.subTypeForm.get('odooDepartment').value
      : null;

    if (this.isCreating) {
      const createData: ContractTypeCreateInput = {
        name: name,
        odooDepartment: odooDepartment,
      };

      this.contractTypeService
        .createContractType(createData)
        .then((rs) => {
          this.notificationService.success('Contract type succesfully created', 'Success');
          this.activeModal.close(rs);
        })
        .catch((err) => {
          this.activeModal.close(false);
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    } else {
      const updateData: ContractTypeUpdateInput = {
        //name: { set: name },
        odooDepartment: { set: odooDepartment },
      };

      this.contractTypeService
        .updateContractType(updateData, { id: this.contracType.id })
        .then((rs) => {
          this.notificationService.success('Contract type succesfully updated', 'Success');
          this.activeModal.close(rs);
        })
        .catch((err) => {
          this.activeModal.close(false);
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    }
  }

  checkSubTypeCode(event: any) {
    const newValue = event.target.value.replaceAll(' ', '_').toUpperCase().trim();
    this.subTypeForm.get('name').setValue(newValue);
  }
}
