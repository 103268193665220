import { decodeJWT } from '@tgx/shared/utils';

export enum MemberRole {
  NONE = 'NONE',
  VIEWER = 'VIEWER',
  EDITOR = 'EDITOR',
  ADMIN = 'ADMIN',
  OWNER = 'OWNER',
}

export enum ServiceRole {
  BILLING = 'BILLING',
  HOTELX = 'HOTELX',
  ENTITIES = 'ENTITIES',
  BANNER = 'BANNER',
}

export class PermissionTable {
  // Member code (email)
  memberId: string;
  // TGX general Role
  orgRole: MemberRole;
  // Service to upgrade TGX general Role
  serviceRoles: ServiceRoleConfig[];
  errorMessage: string;
}

export interface OrgStructure {
  // Org Code
  o: string;
  // Role in this Org
  r: MemberRole;
  // Service Roles (Array)
  s: [
    {
      c: ServiceRole;
      r: MemberRole;
    },
  ];
}

export class UserConfig {
  claimUrl: string;
  claimMemberId: string;
  claimOrgRole: string;
}

export class ServiceRoleConfig {
  s: ServiceRole;
  r: MemberRole;
}

export function JwtTools(pBearer: string, pConfig: UserConfig): PermissionTable {
  let permissionTable: PermissionTable = {
    memberId: '',
    orgRole: MemberRole.NONE,
    serviceRoles: [],
    errorMessage: null,
  };
  _NewPermissionTable(permissionTable, pBearer, pConfig);
  return permissionTable;
}

function _NewPermissionTable(permissionTable: PermissionTable, pBearer: string, pConfig: UserConfig) {
  try {
    if (pBearer !== '') {
      const tokenParts = _GetOrgStructureAndMember(pBearer, pConfig);
      if (tokenParts[0]) {
        permissionTable.memberId = tokenParts[1];

        const orgRoles: OrgStructure = tokenParts[0];

        permissionTable.orgRole = orgRoles?.r;

        if (orgRoles?.s?.length > 0) {
          orgRoles.s.forEach((service) => {
            permissionTable.serviceRoles.push({ s: service.c, r: service.r });
          });
        }
      } else {
        permissionTable.errorMessage = 'Invalid new organzation role structure.';
      }
    }
  } catch (err) {
    permissionTable.errorMessage = err.message;
    permissionTable.orgRole = MemberRole.NONE;
    permissionTable.serviceRoles = null;
    return;
  }
}

//Return the IAM and MemberID claims value
function _GetOrgStructureAndMember(pJwt: string, pConfig: UserConfig) {
  const decoded = decodeJWT(pJwt);
  if (decoded) {
    let orgField = null;
    let memberId = null;
    for (var claimKey of Object.keys(decoded)) {
      if (claimKey === pConfig.claimUrl + pConfig.claimOrgRole) {
        const tgxOrg = decoded[claimKey].filter((x) => x.o === 'tgx');
        if (tgxOrg?.length > 0) {
          orgField = tgxOrg[0];
        }
      } else if (claimKey === pConfig.claimUrl + pConfig.claimMemberId) {
        memberId = decoded[claimKey];
      }
      if (orgField && memberId) {
        break;
      }
    }
    return Object.freeze([orgField, memberId]);
  }
}
