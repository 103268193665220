import { gql } from 'apollo-angular';

export const getPartnersWithSomeContract = gql`
  {
    partnersWithSomeContract {
      id
      name
      orgCode
    }
  }
`;
