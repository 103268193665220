<nb-card class="col-md-12 col-lg-12 col-xxxl-12">
  <nb-card-header> Pre steps before bill </nb-card-header>
  <nb-card-body *ngIf="!isEditor && !hasFutureSchedule">
    You are in the billing group, but you must be an admin to view and execute this operation.
  </nb-card-body>
  <nb-card-body *ngIf="isEditor && hasFutureSchedule">
    {{ message }}
    <button class="btn btn-md btn-warning" (click)="navigateToScheduled()">View</button>
  </nb-card-body>
  <nb-card-body *ngIf="isEditor && !hasFutureSchedule">
    <nb-stepper orientation="horizontal" #stepper>
      <nb-step label="First">
        <!-- Duplicate Connections (same contract) -->
        <tgx-admin-billing-invoicing-process-pre-first
          (isValidStep)="validStep($event, 1)"
        ></tgx-admin-billing-invoicing-process-pre-first>
        <button class="btn btn-md btn-warning" [disabled]="validSteps?.get(1)" (click)="refreshStep(1)">Refresh</button>
        <button
          class="btn btn-md btn-primary"
          [disabled]="!validSteps?.get(1)"
          (click)="changeCurrentStep(2)"
          nbStepperNext
        >
          Next
        </button>
      </nb-step>
      <nb-step label="Second">
        <!-- Duplicate Connections (same partner) -->
        <tgx-admin-billing-invoicing-process-pre-second
          (isValidStep)="validStep($event, 2)"
        ></tgx-admin-billing-invoicing-process-pre-second>
        <button class="btn btn-md btn-danger" [disabled]="validSteps?.get(2)" nbStepperPrevious>Prev</button>
        <button class="btn btn-md btn-warning" [disabled]="validSteps?.get(2)" (click)="refreshStep(2)">Refresh</button>
        <button
          class="btn btn-md btn-primary"
          [disabled]="!validSteps?.get(2)"
          (click)="changeCurrentStep(3)"
          nbStepperNext
        >
          Next
        </button>
      </nb-step>
      <nb-step label="Third">
        <!-- Check suppliers (applying DP bit) -->
        <tgx-admin-billing-invoicing-process-pre-third
          (isValidStep)="validStep($event, 3)"
        ></tgx-admin-billing-invoicing-process-pre-third>
        <button class="btn btn-md btn-danger" [disabled]="validSteps?.get(3)" nbStepperPrevious>Prev</button>
        <!-- <button class="btn btn-md btn-warning" [disabled]="validSteps?.get(3)" (click)="refreshStep(3)">Refresh</button> -->
        <button
          class="btn btn-md btn-primary"
          [disabled]="!validSteps?.get(3)"
          (click)="changeCurrentStep(4)"
          nbStepperNext
        >
          Next
        </button>
      </nb-step>
      <nb-step label="Fourth">
        <!-- Check Connection costs -->
        <tgx-admin-billing-invoicing-process-pre-fourth
          (isValidStep)="validStep($event, 4)"
        ></tgx-admin-billing-invoicing-process-pre-fourth>
        <button class="btn btn-md btn-danger" [disabled]="validSteps?.get(4)" nbStepperPrevious>Prev</button>
        <button class="btn btn-md btn-warning" [disabled]="validSteps?.get(4)" (click)="refreshStep(4)">Refresh</button>
        <button
          class="btn btn-md btn-primary"
          [disabled]="!validSteps?.get(4)"
          (click)="changeCurrentStep(5)"
          nbStepperNext
        >
          Next
        </button>
      </nb-step>
      <nb-step label="Fifth">
        <!-- SUP Connections in CM22 agreements -->
        <tgx-admin-billing-invoicing-process-pre-fifth
          (isValidStep)="validStep($event, 5)"
        ></tgx-admin-billing-invoicing-process-pre-fifth>
        <button class="btn btn-md btn-danger" [disabled]="validSteps?.get(5)" nbStepperPrevious>Prev</button>
        <button class="btn btn-md btn-warning" [disabled]="validSteps?.get(5)" (click)="refreshStep(5)">Refresh</button>
        <button
          class="btn btn-md btn-primary"
          [disabled]="!validSteps?.get(5)"
          (click)="changeCurrentStep(6)"
          nbStepperNext
        >
          Next
        </button>
      </nb-step>
      <nb-step label="Sixth">
        <!-- CM23 agreement without STD costs (ignoring TP & DP) -->
        <tgx-admin-billing-invoicing-process-pre-sixth
          (isValidStep)="validStep($event, 6)"
        ></tgx-admin-billing-invoicing-process-pre-sixth>
        <button class="btn btn-md btn-danger" [disabled]="validSteps?.get(6)" nbStepperPrevious>Prev</button>
        <button class="btn btn-md btn-warning" [disabled]="validSteps?.get(6)" (click)="refreshStep(6)">Refresh</button>
        <button
          class="btn btn-md btn-primary"
          [disabled]="!validSteps?.get(6)"
          (click)="changeCurrentStep(7)"
          nbStepperNext
        >
          Next
        </button>
      </nb-step>
      <nb-step label="Seventh">
        <!-- DP Connections which are not SUP (Ignoring CM22 agreements) -->
        <tgx-admin-billing-invoicing-process-pre-seventh
          (isValidStep)="validStep($event, 7)"
        ></tgx-admin-billing-invoicing-process-pre-seventh>
        <button class="btn btn-md btn-danger" [disabled]="validSteps?.get(7)" nbStepperPrevious>Prev</button>
        <button class="btn btn-md btn-warning" [disabled]="validSteps?.get(7)" (click)="refreshStep(7)">Refresh</button>
        <button class="btn btn-md btn-primary" [disabled]="!validSteps?.get(7)" (click)="bill()">Bill</button>
      </nb-step>
      <nb-step label="Eighth">
        <div class="step-container">
          <h3>Billing scheduled!</h3>
          <button class="btn btn-md btn-primary" (click)="finishStepper()">Finish</button>
        </div>
      </nb-step>
    </nb-stepper>
  </nb-card-body>
</nb-card>
