import { ToastsComponent } from './toasts.component';
import { ToastsService } from './toasts.service';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedUiStorybookModule } from '@tgx/shared/ui/storybook';

@NgModule({
  declarations: [ToastsComponent],
  providers: [ToastsService, HttpClient],
  imports: [CommonModule, SharedUiStorybookModule],
  exports: [ToastsComponent],
})
export class ToastsModule {}
