import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GraphqlGatewayQueries } from './queries/_index-entities.queries';
import { GqlService } from '@tgx/shared/data-access/graphql';
import { ProviderNode } from '../interfaces/models/providers.interface';
import { ProviderWhereUniqueInput } from './../../entities/interfaces/inputs/provider-where-unique-input.interface';
import { GraphqlGatewayMutations } from './mutations/_index-entities.mutations';
import { ProviderCreateInput } from '../interfaces/inputs/create-provider-input.interface';
import { ProviderInput } from '../interfaces/inputs/update-provider.input.interface';

@Injectable({
  providedIn: 'root',
})
export class ProvidersService {
  onToggleTable$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private gqlService: GqlService) {}

  async getAllProviders(): Promise<ProviderNode[]> {
    return await new Promise((resolve, _reject) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getAllProviders)
        .then((response) => {
          if (response['admin']['allProviders'].length > 0) {
            resolve(response['admin']['allProviders']);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async activateProvider(input: ProviderWhereUniqueInput): Promise<ProviderNode> {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.activateProvider, { where: input })
        .then((response) => {
          if (response['admin']['activateProvider']['code']) {
            resolve(response['admin']['activateProvider']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async deactivateProvider(input: ProviderWhereUniqueInput): Promise<ProviderNode> {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.deactivateProvider, { where: input })
        .then((response) => {
          if (response['admin']['deactivateProvider']['code']) {
            resolve(response['admin']['deactivateProvider']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async createProvider(input: ProviderCreateInput): Promise<ProviderNode> {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.createProvider, { data: input })
        .then((response) => {
          if (response['admin']['createProvider']) {
            resolve(response['admin']['createProvider']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async updateProvider(input: ProviderInput): Promise<ProviderNode> {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.updateProvider, { input: input })
        .then((response) => {
          if (response['admin']['updateProvider']['code']) {
            resolve(response['admin']['updateProvider']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }
}
