import { gql } from 'apollo-angular';

export const getSupplier = gql`
  query ($supplierCode: ID!) {
    admin {
      supplier(where: { search: $supplierCode, searchBy: CODE }) {
        supplierData {
          code
          giata {
            giataCode
            giataType
          }
        }
      }
    }
  }
`;
