import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './../../@core/shared/not-found/not-found.component';
import { EntitiesComponent } from './entities.component';

const routes: Routes = [
  {
    path: '',
    component: EntitiesComponent,
    children: [
      {
        path: 'clients',
        loadChildren: () => import('./modules/clients/clients.module').then((m) => m.ClientsModule),
      },
      {
        path: 'legacy-connections',
        loadChildren: () =>
          import('./modules/legacy-connections/legacy-connections.module').then((m) => m.LegacyConnectionsModule),
      },
      {
        path: 'connections',
        loadChildren: () => import('./modules/connections/connections.module').then((m) => m.ConnectionsModule),
      },
      {
        path: 'suppliers',
        loadChildren: () => import('./modules/suppliers/suppliers.module').then((m) => m.SuppliersModule),
      },
      {
        path: 'providers',
        loadChildren: () => import('./modules/providers/providers.module').then((m) => m.ProvidersModule),
      },
      {
        path: 'group-content-context',
        loadChildren: () =>
          import('./modules/group-content-context/group-content-context.module').then(
            (m) => m.GroupContentContextModule,
          ),
      },
      {
        path: 'accesses',
        loadChildren: () => import('./modules/accesses/accesses.module').then((m) => m.AccessesModule),
      },
      {
        path: 'members',
        loadChildren: () => import('./modules/members/members.module').then((m) => m.MembersModule),
      },
      {
        path: 'apikeys',
        loadChildren: () => import('./modules/apikeys/apikeys.module').then((m) => m.ApikeysModule),
      },
      {
        path: 'organizations',
        loadChildren: () => import('./modules/organizations/organizations.module').then((m) => m.OrganizationsModule),
      },

      {
        path: '',
        redirectTo: 'clients',
        pathMatch: 'full',
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EntitiesRoutingModule {}
