<div class="modal-header">
  <span>{{ header }}</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="costForm">
    <nb-card>
      <nb-card-header> Data </nb-card-header>
      <nb-card-body>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="id">ID</label>
              <input type="text" class="form-control" name="id" formControlName="id" />
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="isPush"
                >Cost for <b>{{ getIsPushValue() }}</b> connection types</label
              >
              <p-triStateCheckbox class="form-control" name="isPush" formControlName="isPush"></p-triStateCheckbox>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="startDate">Start Date</label>
              <p-calendar
                [appendTo]="'body'"
                baseZIndex="999"
                class="form-control p-0 border-0 w-100"
                formControlName="startDate"
                dateFormat="dd/mm/yy"
                [showIcon]="true"
                [maxDate]="costForm.get('endDate').value"
                inputId="icon"
                [readonlyInput]="true"
                placeholder="Enter a date.."
                (onSelect)="checkEndDate()"
              ></p-calendar>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="endDate">End Date</label>
              <p-calendar
                [appendTo]="'body'"
                baseZIndex="999"
                class="form-control p-0 border-0 w-100"
                [minDate]="costForm.get('startDate').value"
                formControlName="endDate"
                dateFormat="dd/mm/yy"
                [showIcon]="true"
                inputId="icon"
                [readonlyInput]="true"
                placeholder="Enter a date.."
              ></p-calendar>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-4">
            <div
              class="form-group"
              [ngClass]="{ 'form-control-danger': costForm.get('min').errors && costForm.get('min').touched }"
            >
              <label class="mb-1" for="min"
                >Minimum
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error [element]="costForm.get('min')" [errors]="costForm.get('min').errors"></tooltip-error>
              <input
                type="number"
                class="form-control"
                formControlName="min"
                [ngClass]="{ 'form-control-danger': costForm.get('min').errors && costForm.get('min').touched }"
                name="min"
              />
            </div>
          </div>
          <div class="col-4">
            <div
              class="form-group"
              [ngClass]="{ 'form-control-danger': costForm.get('max').errors && costForm.get('max').touched }"
            >
              <label class="mb-1" for="max">Maximum</label>
              <input
                type="number"
                class="form-control"
                formControlName="max"
                [ngClass]="{ 'form-control-danger': costForm.get('max').errors && costForm.get('max').touched }"
                name="max"
              />
            </div>
          </div>
          <div class="col-4">
            <div
              class="form-group"
              [ngClass]="{ 'form-control-danger': costForm.get('amount').errors && costForm.get('amount').touched }"
            >
              <label class="mb-1" for="amount"
                >Amount
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="costForm.get('amount')"
                [errors]="costForm.get('amount').errors"
              ></tooltip-error>
              <input
                type="number"
                class="form-control"
                formControlName="amount"
                [ngClass]="{ 'form-control-danger': costForm.get('amount').errors && costForm.get('amount').touched }"
                name="amount"
              />
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="chargeType"
                >Charge Type
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="costForm.get('chargeType')"
                [errors]="costForm.get('chargeType').errors"
              ></tooltip-error>
              <div class="form-group">
                <p-dropdown
                  (onHide)="validType('chargeType')"
                  [options]="chargeTypes"
                  [appendTo]="'body'"
                  baseZIndex="999"
                  formControlName="chargeType"
                  optionLabel="label"
                  filterBy="label"
                  (onChange)="loadPaymentTypeEvent($event.value)"
                >
                  <ng-template pTemplate="selectedItem">
                    <div *ngIf="costForm.get('chargeType').value">
                      <div>{{ costForm.get('chargeType').value.label }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-ci pTemplate="ci">
                    <div>
                      <div>{{ ci.label }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="paymentType"
                >Payment Type
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="costForm.get('paymentType')"
                [errors]="costForm.get('paymentType').errors"
              ></tooltip-error>
              <div class="form-group">
                <p-dropdown
                  (onHide)="validType('paymentType')"
                  [options]="paymentTypes"
                  [appendTo]="'body'"
                  baseZIndex="999"
                  formControlName="paymentType"
                  optionLabel="label"
                  filterBy="label"
                >
                  <ng-template pTemplate="selectedItem">
                    <div *ngIf="costForm.get('paymentType').value">
                      <div>{{ costForm.get('paymentType').value.label }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-ci pTemplate="ci">
                    <div>
                      <div>{{ ci.label }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()">{{ isEditor ? 'Cancel' : 'Close' }}</button>
  <button *ngIf="isEditor" class="btn btn-md btn-primary" [disabled]="!validForm()" (click)="onSave()">
    {{ isCreating ? 'Create' : 'Update' }}
  </button>
</div>
