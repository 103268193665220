<div class="row">
  <div class="col-6">
    <tgx-spinner [class]="'routingLoading'" [isLoading]="isLoading" [top]="'200px'"></tgx-spinner>
    <nb-card size="tiny">
      <nb-card-header>New Connections</nb-card-header>
      <nb-card-body *ngIf="!isLoading && isEditor">
        <input type="file" class="file-input" (change)="onFileSelected($event, 'new')" #fileUploadNewConnections />
        <div class="file-upload">
          {{ newConnectionFile?.name || 'No csv selected.' }}
          <button class="btn btn-md btn-primary" (click)="fileUploadNewConnections.click()">Attach</button>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-md btn-danger"
            (click)="loadConnections('new')"
            [disabled]="isLoading || !newConnectionFile"
          >
            Load
          </button>
        </div>
      </nb-card-body>
      <nb-card-body *ngIf="isLoading && isEditor">
        Your connections are being loaded, please be patient..
      </nb-card-body>
      <nb-card-body *ngIf="!isEditor">
        You are in the billing group, but you must be an admin to view and execute this operation.
      </nb-card-body>
    </nb-card>
  </div>
  <div class="col-6">
    <tgx-spinner [class]="'routingLoading'" [isLoading]="isLoadingClone" [top]="'200px'"></tgx-spinner>
    <nb-card size="tiny">
      <nb-card-header>Clone Connections</nb-card-header>
      <nb-card-body *ngIf="!isLoadingClone && isEditor">
        <input type="file" class="file-input" (change)="onFileSelected($event, 'clone')" #fileUploadCloneConnections />
        <div class="file-upload">
          {{ cloneConnectionFile?.name || 'No csv selected.' }}
          <button class="btn btn-md btn-primary" (click)="fileUploadCloneConnections.click()">Attach</button>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-md btn-danger"
            (click)="loadConnections('clone')"
            [disabled]="isLoadingClone || !cloneConnectionFile"
          >
            Load
          </button>
        </div>
      </nb-card-body>
      <nb-card-body *ngIf="isLoadingClone && isEditor">
        Your connections are being loaded, please be patient..
      </nb-card-body>
      <nb-card-body *ngIf="!isEditor">
        You are in the billing group, but you must be an admin to view and execute this operation.
      </nb-card-body>
    </nb-card>
  </div>
</div>
<div class="row">
  <div class="col-6">
    <tgx-spinner [class]="'routingLoading'" [isLoading]="isLoadingMigrate" [top]="'200px'"></tgx-spinner>
    <nb-card size="tiny">
      <nb-card-header>Migrate Connections</nb-card-header>
      <nb-card-body *ngIf="!isLoadingMigrate && isEditor">
        <input type="file" class="file-input" (change)="onFileSelected($event, 'migrate')" #fileMigrateConnections />
        <div class="file-upload">
          {{ migrateConnectionFile?.name || 'No csv selected.' }}
          <button class="btn btn-md btn-primary" (click)="fileMigrateConnections.click()">Attach</button>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-md btn-danger"
            (click)="loadConnections('migrate')"
            [disabled]="isLoadingMigrate || !migrateConnectionFile"
          >
            Load
          </button>
        </div>
      </nb-card-body>
      <nb-card-body *ngIf="isLoadingMigrate && isEditor">
        Your connections are being migrated, please be patient..
      </nb-card-body>
      <nb-card-body *ngIf="!isEditor">
        You are in the billing group, but you must be an admin to view and execute this operation.
      </nb-card-body>
    </nb-card>
  </div>
</div>
