import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ActiveConnection,
  CM23ContractReport,
  CurrentConnection,
  CurrentContract,
  InvoicedDataByMonth,
  MissingConnection,
} from '../interfaces/_index.billing.interfaces';
import { GraphqlApiBillingQueries } from './queries/_index-billing-queries';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  urlBillingApi: string;

  constructor(
    @Inject('urlBillingApi') urlBillingApi: string,
    private http: HttpClient,
  ) {
    this.urlBillingApi = urlBillingApi;
  }

  async getAllConnections(): Promise<CurrentConnection[]> {
    return await new Promise((resolve, reject) => {
      this.http
        .post(this.urlBillingApi, {
          query: GraphqlApiBillingQueries.getAllCurrentConnections,
        })
        .subscribe(
          (res: any) => {
            if (res?.data?.getAllCurrentConnections?.length > 0) {
              resolve(res.data.getAllCurrentConnections);
            } else {
              reject(res);
            }
          },
          (err) => {
            reject(err);
          },
        );
    });
  }

  async getAllContracts(): Promise<CurrentContract[]> {
    return await new Promise((resolve, reject) => {
      this.http
        .post(this.urlBillingApi, {
          query: GraphqlApiBillingQueries.getAllCurrentContracts,
        })
        .subscribe(
          (res: any) => {
            if (res?.data?.getAllCurrentContracts?.length > 0) {
              resolve(res.data.getAllCurrentContracts);
            } else {
              reject(res);
            }
          },
          (err) => {
            reject(err);
          },
        );
    });
  }

  async getAllCM23ContractCosts(): Promise<CM23ContractReport[]> {
    return await new Promise((resolve, reject) => {
      this.http
        .post(this.urlBillingApi, {
          query: GraphqlApiBillingQueries.getAllCM23ContractCosts,
        })
        .subscribe(
          (res: any) => {
            if (res?.data?.getAllCM23ContractCosts?.length > 0) {
              resolve(res.data.getAllCM23ContractCosts);
            } else {
              reject(res);
            }
          },
          (err) => {
            reject(err);
          },
        );
    });
  }

  async getMissingConnections(): Promise<MissingConnection[]> {
    return await new Promise((resolve, reject) => {
      this.http
        .post(this.urlBillingApi, {
          query: GraphqlApiBillingQueries.getMissingConnections,
        })
        .subscribe(
          (res: any) => {
            if (res?.data?.getMissingConnections?.length > 0) {
              resolve(res.data.getMissingConnections);
            } else {
              reject(res);
            }
          },
          (err) => {
            reject(err);
          },
        );
    });
  }

  async getInconclusiveConnections(): Promise<ActiveConnection[]> {
    return await new Promise((resolve, reject) => {
      this.http
        .post(this.urlBillingApi, {
          query: GraphqlApiBillingQueries.getInconclusiveConnections,
        })
        .subscribe(
          (res: any) => {
            if (res?.data?.getInconclusiveConnections?.length > 0) {
              resolve(res.data.getInconclusiveConnections);
            } else {
              reject(res);
            }
          },
          (err) => {
            reject(err);
          },
        );
    });
  }

  async getAllInvoicedDataByMonth(startDate: string): Promise<InvoicedDataByMonth[]> {
    return await new Promise((resolve, reject) => {
      this.http
        .post(this.urlBillingApi, {
          query: GraphqlApiBillingQueries.getInvoicedDataByMonth,
          variables: { date: startDate },
        })
        .subscribe(
          (res: any) => {
            if (res?.data?.getInvoicedDataByMonth?.length > 0) {
              resolve(res.data.getInvoicedDataByMonth);
            } else {
              reject(res);
            }
          },
          (err) => {
            reject(err);
          },
        );
    });
  }
}
