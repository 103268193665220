<tgx-spinner [class]="'routingLoading'" [isLoading]="isLoading" [top]="'180px'"></tgx-spinner>
<nb-card *ngIf="!isLoading">
  <nb-card-header> SubCategories </nb-card-header>
  <nb-card-body>
    <form>
      <p-dropdown
        [options]="categoriesListData"
        [appendTo]="'body'"
        baseZIndex="999"
        [placeholder]="'Select category...'"
        optionLabel="code"
        filterBy="code"
        (onChange)="selectCategory($event)"
      >
      </p-dropdown>
    </form>
  </nb-card-body>

  <nb-card-body>
    <div class="mb-3">
      <p-button label="Create" icon="pi pi-plus" [disabled]="!this.selectedCategory" (onClick)="onCreate()"></p-button>
    </div>
    <p-table
      [value]="subcategoriesSource"
      [tableStyle]="{ 'min-width': '50rem' }"
      styleClass="p-datatable-gridlines p-datatable-striped"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>Name</th>
          <th>Description</th>
          <th>Buyer</th>
          <th>Seller</th>
          <th>Demand Bridge</th>
          <th>Supply Bridge</th>
          <th>Created at</th>
          <th>Updated at</th>
          <th>Options</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-subcategory>
        <tr>
          <td>{{ subcategory.name }}</td>
          <td>{{ subcategory.description }}</td>
          <td><p-checkbox [(ngModel)]="subcategory.isBuyer" disabled="true" [binary]="true"></p-checkbox></td>
          <td><p-checkbox [(ngModel)]="subcategory.isSeller" disabled="true" [binary]="true"></p-checkbox></td>
          <td><p-checkbox [(ngModel)]="subcategory.isDemandBridge" disabled="true" [binary]="true"></p-checkbox></td>
          <td><p-checkbox [(ngModel)]="subcategory.isSupplyBridge" disabled="true" [binary]="true"></p-checkbox></td>
          <td>{{ subcategory.createdAt | date: 'dd-MM-yyyy' }}</td>
          <td>{{ subcategory.updatedAt | date: 'dd-MM-yyyy' }}</td>
          <td>
            <i title="Edit Subcategory" class="nb-edit icon" (click)="onEdit(subcategory)"></i>
            <i title="Remove Subcategory" class="nb-trash icon" (click)="onDelete(subcategory.name)"></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>
