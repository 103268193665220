import { Subscription } from 'rxjs';
import { NbThemeService } from '@nebular/theme';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { buildNeededColorsEchart } from '../../../../../../@core/utils/utils';
import { UtilsService } from '../../../../services/_index-billing.services';
import { NotificationService } from '../../../../../../@core/services/notification.service';

@Component({
  selector: 'tgx-dashboard-best-partners',
  templateUrl: './dashboard-best-partners.component.html',
  styleUrls: ['./dashboard-best-partners.component.scss'],
})
export class DashboardBestPartnersComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  private config: any;

  isLoading: boolean;

  partners: any[];

  showLegend = true;
  showXAxis = true;
  showYAxis = true;
  xAxisLabel = 'Partner';
  yAxisLabel = 'Invoiced Amount';
  colorScheme: any;

  constructor(
    private theme: NbThemeService,
    private utilsService: UtilsService,
    private notificationService: NotificationService,
  ) {
    this.subscriptions.push(
      this.theme.getJsTheme().subscribe((config) => {
        this.config = config;
        const colors: any = this.config.variables;
        this.colorScheme = {
          domain: [colors.primaryLight, colors.infoLight, colors.successLight, colors.warningLight, colors.dangerLight],
        };
      }),
    );
  }

  ngOnInit() {
    this.isLoading = true;
    this.utilsService
      .getBestPartners()
      .then((cts) => {
        this.partners = [];
        if (cts?.length > 0) {
          cts.forEach((ct) => {
            this.partners.push({ name: ct.name, value: ct.amount });
          });
          this.buildOptions();
        }
        this.isLoading = false;
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
        this.isLoading = false;
      });
  }

  buildOptions() {
    const colors: any = this.config.variables;

    const neededColors: any[] = buildNeededColorsEchart(colors, this.partners.length);

    this.colorScheme = { domain: [] };

    neededColors.forEach((color) => {
      this.colorScheme.domain.push(color);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }
}
