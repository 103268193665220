import { gql } from 'apollo-angular';

export const getContractConnections = gql`
  query getContractConnections($where: ContractWhereUniqueInput!) {
    contractconnectionsExtended(where: $where) {
      id
      setupPaid
      free
      isBuy
      activationDateTime
      endDate
      connectionType
      connection
      isDeletable
      bookStatus
    }
  }
`;
