export const getAllCurrentContracts = `
query {
  getAllCurrentContracts {
    partnerId
    partnerName
    orgCode
    contractId
    activationDateTime
    contractEndDate
    partnerType
    partnerSubType
    modelType
    rateType
    name
    reviewFeeDate
    billingExpirationDays
    remarks
    defaultPaymentType
    hasRappel
    rappelMinimum
    rappelDiscount
  }
}
`;
