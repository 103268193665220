import { gql } from 'apollo-angular';

export const safeDeactivateAccess = gql`
  mutation ($where: AccessWhereUniqueInput!) {
    admin {
      safeDeactivateAccess(where: $where) {
        code
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;

export const safeDeactivateClient = gql`
  mutation ($where: ClientWhereUniqueInput!) {
    admin {
      safeDeactivateClient(where: $where) {
        code
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;

export const safeDeactivateSupplier = gql`
  mutation ($where: SupplierWhereUniqueInput!) {
    admin {
      safeDeactivateSupplier(where: $where) {
        code
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;

export const safeDeactivateProvider = gql`
  mutation ($where: ProviderWhereUniqueInput!) {
    admin {
      safeDeactivateProvider(where: $where) {
        code
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
