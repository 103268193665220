export const getAllCurrentConnections = `
{
  getAllCurrentConnections {
    partnerId
    orgCode
    partnerName
    contractId
    contractStartDate
    contractEndDate
    contractPartnerType
    partnerSubType
    modelType
    contractConnectionId
    isBuy
    startConnectionDate
    endConnectionDate
    connectionType
    rateType
    buyerCode
    buyerName
    sellerCode
    sellerName
    connectionApi
  }
}

`;
