import { gql } from 'apollo-angular';

export const getImpersonation = gql`
  query getImpersonation($memberCode: String!) {
    admin {
      getImpersonationJWT(memberCode: $memberCode) {
        bearer
        adviseMessage {
          code
          description
        }
      }
    }
  }
`;
