import { Injectable } from '@angular/core';
import { billingQueries, GqlService } from '@tgx/shared/data-access/graphql';
import {
  BillingAccountContactCreateInput,
  GeneralWhereIdUniqueInput,
  BillingAccountContact,
} from '@tgx/shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  constructor(private gqlService: GqlService) {}

  async createBillingAccountContact(data: BillingAccountContactCreateInput): Promise<BillingAccountContact> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationBilling(billingQueries.createBillingAccountContact, { data: data })
        .then((response: any) => {
          if (response['createBillingAccountContact']) {
            resolve(response['createBillingAccountContact']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async deleteBillingAccountContact(where: GeneralWhereIdUniqueInput): Promise<BillingAccountContact> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationBilling(billingQueries.deleteBillingAccountContact, { where: where })
        .then((response: any) => {
          if (response['deleteBillingAccountContact']) {
            resolve(response['deleteBillingAccountContact']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
