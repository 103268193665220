import { AdviseMessage } from 'libs/web/feature-connections/src/lib/shared/operations';

export interface ApikeysConnection {
  edges: ApikeysEdge[];
  totalCount: number;
}

export interface ApikeysEdge {
  node: Apikeys[];
}

export interface Apikeys {
  code: string;
  apikeysData: ApikeysData;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  adviseMessage: AdviseMessage[];
}

export interface ApikeysData {
  id: string;
  code: string;
  label?: string;
  isActive: boolean;
  role: string;
  organization?: any;
}
