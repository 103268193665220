import { gql } from 'apollo-angular';

export const changeOrganizationMemberOwner = gql`
  mutation changeOrganizationMemberOwner($member: String!, $organization: String!) {
    admin {
      changeOrganizationMemberOwner(member: $member, organization: $organization) {
        code
        createdAt
        updatedAt
        adviseMessage {
          code
          description
        }
      }
    }
  }
`;
