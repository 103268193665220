<p-slider
  *ngIf="!range; else rangeSlider"
  [(ngModel)]="value"
  [min]="min"
  [max]="max"
  [disabled]="disabled"
  (onChange)="onUserChange($event)"
  styleClass="xen-range"
></p-slider>
<ng-template #rangeSlider>
  <p-slider
    [(ngModel)]="rangeValues"
    [range]="true"
    [min]="min"
    [max]="max"
    [disabled]="disabled"
    styleClass="xen-range"
    (onChange)="onUserChange($event)"
  ></p-slider>
</ng-template>
