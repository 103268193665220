<div class="modal-header">
  <span>{{ header }}</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <nb-card>
    <nb-card-header> Data </nb-card-header>
    <nb-card-body>
      <form [formGroup]="cloneForm">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="startDate"
                >Activation Date (New connection)
                <span class="mandatory ml-1">*</span>
              </label>
              <p-calendar
                [appendTo]="'body'"
                baseZIndex="9999"
                class="form-control p-0 border-0 w-100"
                formControlName="startDate"
                dateFormat="dd/mm/yy"
                [showIcon]="true"
                inputId="icon"
                [readonlyInput]="true"
                placeholder="Enter a date.."
              ></p-calendar>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="endDate"
                >End Date (Current connection)<span class="mandatory ml-1">*</span></label
              >
              <p-calendar
                [appendTo]="'body'"
                baseZIndex="9999"
                class="form-control p-0 border-0 w-100"
                formControlName="endDate"
                dateFormat="dd/mm/yy"
                [showIcon]="true"
                inputId="icon"
                [readonlyInput]="true"
                placeholder="Enter a date.."
              ></p-calendar>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="connectionType"
                >Connection Type (New connection)
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="cloneForm.get('connectionType')"
                [errors]="cloneForm.get('connectionType').errors"
              ></tooltip-error>
              <div class="form-group">
                <p-dropdown
                  [options]="connectionTypes"
                  [appendTo]="'body'"
                  baseZIndex="9999"
                  formControlName="connectionType"
                  optionLabel="label"
                  filterBy="label"
                >
                  <ng-template pTemplate="selectedItem">
                    <div *ngIf="cloneForm.get('connectionType').value">
                      <div>{{ cloneForm.get('connectionType').value.label }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-ci pTemplate="ci">
                    <div>
                      <div>{{ ci.label }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>
        </div>
      </form>
    </nb-card-body>
  </nb-card>
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()">Cancel</button>
  <button class="btn btn-md btn-primary" [disabled]="!validForm()" (click)="onSave()">Clone</button>
</div>
