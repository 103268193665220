import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'xen-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
})
export class EmptyStateComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() imgPath: string;
  @Input() imgAlt: string;
  @Input() icon = false;
  @Input() iconSnippet: string;
  @Input() buttonLabel: string;
  @Input() footer: string;

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onClickEvent(event) {
    this.onClick.emit(event);
  }
}
