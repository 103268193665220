import { NotFoundComponent } from './../../../../@core/shared/not-found/not-found.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BillingCostsComponent } from './costs.component';
import { BillingCostsTableComponent } from './cost/components/costs-table/costs-table.component';
import { BillingL2BCostsTableComponent } from './l2b-cost/components/l2b-costs-table/l2b-costs-table.component';
import { BillingL2BNewPricingCostsTableComponent } from './new-pricing-costs/components/new-pricing-costs-table/new-pricing-costs-table.component';
import { BillingDefaultCostTableComponent } from './default-cost/components/default-cost-table/default-cost-table.component';

const routes: Routes = [
  {
    path: '',
    component: BillingCostsComponent,
    children: [
      {
        path: 'costs',
        component: BillingCostsTableComponent,
      },
      {
        path: 'l2b-costs',
        component: BillingL2BCostsTableComponent,
      },
      {
        path: 'new-pricing-costs',
        component: BillingL2BNewPricingCostsTableComponent,
      },
      {
        path: 'default-costs',
        component: BillingDefaultCostTableComponent,
      },
      {
        path: '',
        redirectTo: 'costs',
        pathMatch: 'full',
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BillingCostsRoutingModule {}
