import { gql } from 'apollo-angular';

export const getMinInfoSupplier = gql`
  query ($where: SupplierWhereInput) {
    admin {
      allSuppliers(where: $where) {
        edges {
          node {
            supplierData {
              code
              name
            }
          }
        }
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
