import { Component, OnInit } from '@angular/core';
import { WebService } from './../../../services/web.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { WebAdminService } from './../../../../../@core/services/web-admin.service';
import { ModuleType } from './../../../../../@core/interfaces/_index.interfaces';
@Component({
  selector: 'tgx-web-banner',
  templateUrl: './web-banner.component.html',
  styleUrls: ['./web-banner.component.scss'],
})
export class WebBannerComponent implements OnInit {
  form: UntypedFormGroup;
  isVisible: boolean;
  message: string;
  isLoading = true;
  show = true;
  alertType = 'warning';
  disabledType = false;
  textInputValue = '';
  disabledSendButton = true;
  disableHtmlButtons = false;

  constructor(
    public webService: WebService,
    private fb: UntypedFormBuilder,
    private webAdminService: WebAdminService,
  ) {}

  messageRadio = [
    {
      id: 'showMessageCheckbox',
      name: 'Show message',
      value: true,
      tooltip:
        'Add a new banner alert with this message in our website to keep our customers up to date with any issue.',
      checked: true,
    },
    {
      id: 'hideMessageCheckbox',
      name: 'Hide message',
      value: false,
      tooltip: 'Select and send this option to remove the message from the website. No message or type message needed.',
      checked: false,
    },
  ];
  alertTypeRadio = [
    {
      id: 'warning',
      name: 'Warning Alert',
      value: 'warning',
      checked: true,
    },
    {
      id: 'info',
      name: 'Info Alert',
      value: 'info',
      checked: false,
    },
    {
      id: 'error',
      name: 'Error Alert',
      value: 'danger',
      checked: false,
    },
  ];

  ngOnInit(): void {
    this.buildOptions();
    this.webService.getMessage().subscribe({
      next: (res) => {
        this.isVisible = res?.visible;
        this.message = res?.message;
        if (res) this.isLoading = false;
        this.form = this.fb.group({
          bannerMessage: '',
        });
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  buildOptions() {
    this.webAdminService.emitUserTypevalue(ModuleType.BANNER);
  }

  addLink() {
    let actualValue;
    if (this.form.get('bannerMessage').value !== '') {
      actualValue = this.form.get('bannerMessage').value;
    }
    this.form
      .get('bannerMessage')
      .setValue(
        actualValue
          ? `${actualValue} <a target="_blank" href="URL">Set here your text</a>`
          : '<a target="_blank" href="URL">Set here your text</a>',
      );
  }

  addBold() {
    let actualValue;
    if (this.form.get('bannerMessage').value !== '') {
      actualValue = this.form.get('bannerMessage').value;
    }
    this.form
      .get('bannerMessage')
      .setValue(actualValue ? `${actualValue} <b>Set here your text</b>` : '<b>Set here your text</b>');
  }

  clearText() {
    this.form.get('bannerMessage').setValue('');
    this.disabledSendButton = true;
  }

  getSelected(value) {
    if (value === false) {
      this.form.get('bannerMessage').disable();
      this.form.get('bannerMessage').setValue('');
      this.disabledType = true;
      this.disableHtmlButtons = true;
      this.disabledSendButton = false;
    } else {
      this.form.get('bannerMessage').enable();
      this.disableHtmlButtons = false;
      this.disabledType = false;
    }
    this.show = value;
  }

  getAlertType(input) {
    this.alertType = input;
  }

  getInput() {
    this.textInputValue = this.form.get('bannerMessage').value;
    if (this.textInputValue !== '' || this.show === false) {
      this.disabledSendButton = false;
    } else {
      this.disabledSendButton = true;
    }
  }

  sendMessage() {
    if ((this.textInputValue !== '' && this.show === true) || this.show === false) {
      this.isLoading = true;
      let messageInput = this.form.get('bannerMessage')?.value;
      if (!this.show) messageInput = 'Send your message';
      this.webService.setMessage(this.show, messageInput, this.alertType).subscribe({
        next: (res) => {
          this.isVisible = res.visible;
          this.message = res.message;
          if (res) this.isLoading = false;
          this.form.get('bannerMessage').setValue('');
          this.form.get('bannerMessage').enable();
          this.disabledType = false;
          this.disableHtmlButtons = false;
          this.alertType = 'warning';
          this.show = true;
          this.getInput();
        },
        error: (err) => {
          console.log(err);
          return err;
        },
      });
    }
  }
}
