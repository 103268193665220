import { Injectable } from '@angular/core';
import { CacheService } from './cache.service';
import { AccessStatus } from '@tgx/shared/enums';
import { ToastsService } from '@tgx/shared/toasts';
import { ActiveCardOrg } from '@tgx/shared/interfaces';
import { GqlService, sharedAdminQueries, sharedActivationsQueries } from '@tgx/shared/data-access/graphql';

@Injectable({
  providedIn: 'root',
})
export class SharedConnectionsService {
  constructor(
    private toastService: ToastsService,
    private gqlService: GqlService,
    private cacheService: CacheService,
  ) {}

  async getMyConnections(org: any, getActivations: boolean = false): Promise<ActiveCardOrg[]> {
    const isSeller = org.typeSelected === 'seller';
    const connections = [];

    let accesses;
    if (isSeller) {
      accesses = await this.getAccessesSeller(org.code);
    } else {
      accesses = await this.getAccessesBuyer(org.code);
    }

    let activations = [];
    if (getActivations) activations = await this.getActivations(org.code, isSeller);

    accesses.forEach((access) => {
      const activation = activations.find((activation) => activation.accessCode === access.accessCode);

      let status = activation?.status;
      if (status == 'MANUAL') {
        status = AccessStatus.IN_PROGRESS;
      } else if (status == 'AVAILABLE' || !status) {
        status = AccessStatus.WORKING;
      }
      access.status = status;
      access.certificationStatus = activation?.certificationStatus || '';

      const connection = connections.find((conn) => conn.orgCode === access.orgCode);
      if (connection) {
        connection.accesses.push(access);
        if (access.createdAt < connection.createdAt) {
          connection.createdAt = access.createdAt;
        }
        if (access.updatedAt > connection.updatedAt) {
          connection.updatedAt = access.updatedAt;
        }
      } else {
        const newConnection = {
          orgCode: access.orgCode,
          orgLabel: access.orgLabel,
          accesses: [],
          createdAt: access.createdAt,
          updatedAt: access.updatedAt,
          isHotelX: false,
        };
        newConnection.accesses.push(access);
        connections.push(newConnection);
      }
    });

    await this.getOrgsProfileInfo(connections);

    return connections;
  }

  private async getOrgsProfileInfo(connections: any[]) {
    const orgCodes = connections.map((conn) => conn.orgCode);
    await this.getProfilesByCode(orgCodes).then((res) => {
      connections.forEach((conn) => {
        const obj = res.get(conn.orgCode);
        if (obj) {
          conn.orgLabel = obj.name ? obj.name : conn.orgCode;
          conn.isHotelX = obj.isHotelX;
        }
      });
    });
    connections.sort((a, b) => a.orgLabel?.toLowerCase().localeCompare(b.orgLabel?.toLowerCase()));
  }

  getProfilesByCode(codes: string[]): Promise<Map<string, any>> {
    return new Promise((resolve) => {
      this.cacheService.retrieveProfilesByCodes(codes, true).then((res) => {
        resolve(this.buildProfilesResponse(res));
      });
    });
  }

  private buildProfilesResponse(res: any): Map<string, any> {
    return new Map(
      res?.map((profile) => [
        profile.code,
        {
          name: profile.name,
          isHotelX: profile.buyerIntegrationType?.includes('HOTELX'),
        },
      ]),
    );
  }

  private async getAccessesBuyer(owner: string): Promise<any> {
    try {
      const responseBody = (await this.gqlService.queryGateway(sharedAdminQueries.getAccesses, {
        owner: owner,
      })) as any;

      return this.processGetAccessesBuyerResponse(responseBody);
    } catch (err: any) {
      this.toastService.addToast('Error', 'bg-danger', 'Error getting accesses:', err);
      return [];
    }
  }

  private processGetAccessesBuyerResponse(responseBody) {
    const accesses = [];

    const accessesEdges = responseBody.admin?.allAccesses?.edges;
    accessesEdges.forEach((accessEdge) => {
      const accessData = accessEdge.node?.accessData;
      let access = {
        orgCode: accessData.supplier.supplierData.owner.code,
        createdAt: new Date(accessEdge.node?.createdAt),
        updatedAt: new Date(accessEdge.node?.updatedAt),
        isTest: accessData.isTest,
        isActive: accessData.isActive,
        accessCode: accessData.code,
        accessName: accessData.name,
        lastModification: new Date(accessEdge.node?.updatedAt) || new Date(accessEdge.node?.createdAt),
        supplierCode: accessData.supplier?.code,
        supplierName: accessData.supplier?.supplierData?.name,
        context: accessData.supplier?.supplierData?.context || '',
        status: null,
      };
      accesses.push(access);
    });

    return accesses;
  }

  private async getAccessesSeller(owner: string): Promise<any> {
    try {
      const responseBody = (await this.gqlService.queryGateway(sharedAdminQueries.getAccessesBySupplierCode, {
        where: { owner_in: owner },
      })) as any;

      return this.processGetAccessesSellerResponse(responseBody);
    } catch (err: any) {
      this.toastService.addToast('Error', 'bg-danger', 'Error getting accesses:', err);
      return [];
    }
  }

  private processGetAccessesSellerResponse(responseBody) {
    const accesses = [];

    const suppliersEdges = responseBody.admin?.allSuppliers?.edges;
    suppliersEdges.forEach((supplierEdge) => {
      const supplierData = supplierEdge.node?.supplierData;
      if (supplierData?.accesses?.edges?.length) {
        supplierData.accesses.edges.forEach((accessEdge) => {
          const accessData = accessEdge.node?.accessData;
          let access = {
            orgCode: accessData.owner.code,
            createdAt: new Date(accessEdge.node?.createdAt),
            updatedAt: new Date(accessEdge.node?.updatedAt),
            isTest: accessData.isTest,
            isActive: accessData.isActive,
            accessCode: accessData.code,
            accessName: accessData.name,
            lastModification: new Date(accessEdge.node?.updatedAt) || new Date(accessEdge.node?.createdAt),
            supplierCode: supplierData?.code,
            supplierName: supplierData?.name,
            context: supplierData?.context || '',
            status: null,
          };
          accesses.push(access);
        });
      }
    });

    return accesses;
  }

  private async getActivations(orgCode: string, isSeller: boolean): Promise<any[]> {
    try {
      const activationReadInput = { [isSeller ? 'organizationSellerCode' : 'organizationBuyerCode']: orgCode };
      const responseBody = (await this.gqlService.queryGateway(sharedActivationsQueries.getActivationsByOrg, {
        input: activationReadInput,
      })) as any;

      return this.processGetActivationsResponse(responseBody);
    } catch (err: any) {
      this.toastService.addToast('Error', 'bg-danger', 'Error getting activations:', err);
      return [];
    }
  }

  private processGetActivationsResponse(responseBody) {
    const adviseMessage = responseBody?.data?.activation?.activations?.adviseMessage;
    if (adviseMessage && adviseMessage.length > 0) {
      adviseMessage.forEach((adv: any) => {
        this.toastService.addToastAdviseMessage(adv);
      });
    }
    const activations = [];

    const activationsEdges = responseBody.activation?.activations?.edges;
    activationsEdges.forEach((edge) => {
      const activationData = edge.node?.activationData;
      let activation = {
        accessCode: activationData.access?.code,
        supplierCode: activationData.access.supplier.code,
        status: activationData.status,

        certificationStatus: activationData.certificationStatus || '',
      };
      activations.push(activation);
    });
    return activations;
  }
}
