import { gql } from 'apollo-angular';

export const createProvider = gql`
  mutation ($data: ProviderCreateInput!) {
    admin {
      createProvider(data: $data) {
        code
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
