import { Table } from 'primeng/table';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { PreAndPostCheckService } from '../../../../../services/_index-billing.services';
import { NotificationService } from './../../../../../../../@core/services/_index-core.services';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'tgx-admin-billing-invoicing-process-post-second',
  templateUrl: './invoicing-process-post-second.component.html',
  styleUrls: ['./invoicing-process-post-second.component.scss'],
})
export class BillingInvoicingProcessPostSecondComponent implements OnInit, OnDestroy {
  @ViewChild('dt', { static: false }) dataTable!: Table;

  header = [
    { label: 'PartnerId', field: 'PartnerId' },
    { label: 'OrgCode', field: 'OrgCode' },
    { label: 'PartnerName', field: 'PartnerName' },
    { label: 'ContractId', field: 'ContractId' },
    { label: 'PartnerType', field: 'PartnerType' },
    { label: 'ModelType', field: 'ModelType' },
    { label: 'SubType', field: 'SubType' },
    { label: 'StartDate', field: 'StartDate' },
    { label: 'TotalAmount', field: 'TotalAmount' },
    { label: 'TotalGMV', field: 'TotalGMV' },
  ];

  requestsSource: any[];

  isLoading: boolean;

  date1Str = '';
  date2Str = '';

  $subs: Subscription[] = [];
  step = 2;

  constructor(
    private notificationService: NotificationService,
    private preAndPostCheckService: PreAndPostCheckService,
    private datePipe: DatePipe,
  ) {}

  async ngOnInit(): Promise<void> {
    this.$subs.push(
      this.preAndPostCheckService.currentPostConfig.subscribe(async (config) => {
        if (config?.step === this.step && config?.date_from && config?.date_to) {
          this.date1Str = this.datePipe.transform(config.date_from, 'yyyy-MM-dd');
          this.date2Str = this.datePipe.transform(config.date_to, 'yyyy-MM-dd');
          await this.getAmounts();
        }
      }),
    );
  }

  ngOnDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
  }

  async getAmounts() {
    this.isLoading = true;
    this.requestsSource = [];
    const requests: any[] = [];

    await this.preAndPostCheckService
      .getAmountsByContract(this.date1Str, this.date2Str)
      .then((rs) => {
        if (rs.success) {
          rs.response?.forEach((cn) => {
            requests.push({
              'PartnerId': cn.PartnerId,
              'OrgCode': cn.PartnerOrgCode,
              'PartnerName': cn.PartnerName,
              'ContractId': cn.ContractId,
              'PartnerType': cn.PartnerType,
              'ModelType': cn.ModelType.Valid ? cn.ModelType.String : '',
              'SubType': cn.PartnerSubType,
              'StartDate': this.datePipe.transform(cn.StartDate, 'dd-MM-yyyy'),
              'TotalAmount': this.getFormattedNumber(cn.TotalAmount),
              'TotalGMV': this.getFormattedNumber(cn.TotalGMV),
            });
          });
        }
        this.requestsSource = [...requests];
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  filter(value: any, field: string) {
    if (value) {
      this.dataTable.filter(value, field, 'contains');
    }
  }

  getFormattedNumber(quantity: number): string {
    const m = Number((Math.abs(quantity) * 100).toPrecision(15));
    const tmp = (Math.round(m) / 100) * Math.sign(quantity);

    if (tmp % 1 === 0) {
      return tmp.toLocaleString('en');
    } else {
      return parseFloat(tmp.toFixed(2)).toLocaleString('en');
    }
  }
}
