import { AccessStatus } from '@tgx/shared/enums';

export interface ActiveCardOrg {
  orgCode: string;
  orgLabel: string;
  createdAt?: string;
  updatedAt?: string;
  accesses: AccessActiveCardOrg[];
  isHotelX?: boolean;
}

export class AccessActiveCardOrg {
  accessCode: string;
  accessName: string;
  isActive: boolean;
  isTest: boolean;
  accommodations: number;
  lastModification: string; // "2021-04-20 08:46:17",
  createAt: string; // "2021-04-20 08:46:17",
  status: AccessStatus;
  supplierCode: string;
  context?: string;
  activeApps?: ActiveApp[];
}

export interface ActiveApp {
  icon: string;
  tip: string;
  route: string;
}
