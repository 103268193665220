<div class="modal-header">
  <span>{{ header }}</span>
  <button (click)="closeModal()" aria-label="Close" class="close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form [formGroup]="organizationForm">
    <nb-card>
      <nb-card-header> Data </nb-card-header>
      <nb-card-body>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="code">Organization ID</label>
              <tooltip-error [element]="organizationForm.get('code')" [errors]="organizationForm.get('code').errors">
              </tooltip-error>
              <input
                [ngClass]="{
                  'form-control-danger': organizationForm.get('code').errors && organizationForm.get('code').touched,
                }"
                class="form-control"
                formControlName="code"
                name="code"
                type="text"
              />
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <div
              [ngClass]="{
                'form-control-danger': organizationForm.get('label').errors && organizationForm.get('label').touched,
              }"
              class="form-group"
            >
              <label class="mb-1" for="label">Label</label>
              <tooltip-error [element]="organizationForm.get('label')" [errors]="organizationForm.get('label').errors">
              </tooltip-error>
              <input
                [ngClass]="{
                  'form-control-danger': organizationForm.get('label').errors && organizationForm.get('label').touched,
                }"
                class="form-control"
                formControlName="label"
                name="label"
                type="text"
              />
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="owner">Owner</label>
              <p-dropdown
                [appendTo]="'body'"
                [options]="partners"
                baseZIndex="999"
                filter="true"
                filterBy="label"
                formControlName="owner"
                optionValue="code"
                placeholder="Please select an owner"
              >
              </p-dropdown>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="member">Owner member</label>
              <p-dropdown
                [appendTo]="'body'"
                [options]="organization.members"
                baseZIndex="999"
                display="chip"
                filter="true"
                filterBy="label"
                formControlName="ownerMember"
                optionValue="code"
                placeholder="Please select a member"
              >
              </p-dropdown>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="form-group">
            <label class="mb-1" for="isGiataActive"
              >Is Giata Active
              <span class="mandatory ml-1">*</span>
            </label>
            <tooltip-error
              [element]="organizationForm.get('isGiataActive')"
              [errors]="organizationForm.get('isGiataActive').errors"
            ></tooltip-error>
            <nb-checkbox
              class="form-control"
              [ngClass]="{
                'form-control-danger':
                  organizationForm.get('isGiataActive').errors && organizationForm.get('isGiataActive').touched,
              }"
              name="isGiataActive"
              formControlName="isGiataActive"
            ></nb-checkbox>
          </div>
        </div>
        <div class="col-12">
          <div
            [ngClass]="{
              'form-control-danger':
                organizationForm.get('giataConfiguration').errors && organizationForm.get('giataConfiguration').touched,
            }"
            class="form-group"
          >
            <label class="mb-1" for="label" style="font-size: 18px">Giata Configuration</label>
            <tooltip-error
              [element]="organizationForm.get('giataConfiguration')"
              [errors]="organizationForm.get('giataConfiguration').errors"
            >
            </tooltip-error>
            <textarea
              [ngClass]="{
                'form-control-danger':
                  organizationForm.get('giataConfiguration').errors &&
                  organizationForm.get('giataConfiguration').touched,
              }"
              class="form-control"
              formControlName="giataConfiguration"
              name="giataConfiguration"
            ></textarea>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </form>
</div>

<div class="modal-footer">
  <button (click)="closeModal()" class="btn btn-md btn-danger">Close</button>
  <button (click)="onSave()" [disabled]="organizationForm.invalid" class="btn btn-md btn-primary">Update</button>
</div>
