import { SharedModule } from './../../../../@core/shared/shared.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BillingAccountModalComponent } from './components/billing-accounts-modal/billing-accounts-modal.component';
import { BillingAccountTableComponent } from './components/billing-accounts-table/billing-accounts-table.component';
import { BillingAccountContactModalComponent } from './components/billing-accounts-contact-modal/billing-accounts-contact-modal.component';
import { BillingAccountContactTableComponent } from './components/billing-accounts-contact-table/billing-accounts-contact-table.component';
import { BillingAccountComponent } from './components/billing-account.component';
import { BillingAccountRoutingModule } from './billing-account.routing-module';
import { NgModule } from '@angular/core';
import { NbCardModule, NbCheckboxModule } from '@nebular/theme';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';

@NgModule({
  imports: [
    TableModule,
    ButtonModule,
    SharedModule,
    BillingAccountRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NbCardModule,
    NbCheckboxModule,
    CommonModule,
    AutoCompleteModule,
    CheckboxModule,
  ],
  declarations: [
    BillingAccountComponent,
    BillingAccountTableComponent,
    BillingAccountModalComponent,
    BillingAccountContactTableComponent,
    BillingAccountContactModalComponent,
  ],
})
export class BillingAccountModule {}
