import { gql } from 'apollo-angular';

export const getPartner = gql`
  query GetPartner($where: PartnerWhereUniqueInput!) {
    partner(where: $where) {
      id
      name
      orgCode
    }
  }
`;
