// apps\web-admin\src\app\features\content\modules\scheduler\components\scheduler-details\scheduler-details.component.ts
import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDeleteComponent } from 'apps/web-admin/src/app/@core/shared/modal-delete/modal-delete.component';
import { NotificationService } from 'apps/web-admin/src/app/@core/services/notification.service';
import { copyObject } from '@tgx/shared/utils';
import { SchedulerInfoComponent } from '../scheduler-info/scheduler-info.component';
import { TaskManagerService } from '../../../../services/task-manager.service';

const headers = new Map([
  [
    'Complete',
    [
      { field: 'serviceOperation', label: 'Service' },
      { field: 'accessCode', label: 'Access' },
      { field: 'supplier', label: 'Supplier' },
      { field: 'status', label: 'Status' },
      { field: 'schedulerStatus', label: 'IntegrationStatus' },
      { field: 'adviseMessage', label: 'Int. error' },
      { field: 'executionTime', label: 'Ex. time' },
      { field: 'completeTime', label: 'Finish time' },
      { field: 'enqueuedTime', label: 'Time added' },
      { field: 'priority', label: 'Priority' },
      { field: 'user', label: 'User' },
      { field: 'Options', label: 'Options' },
    ],
  ],
  [
    'Executing',
    [
      { field: 'serviceOperation', label: 'Service' },
      { field: 'accessCode', label: 'Access' },
      { field: 'supplier', label: 'Supplier' },
      { field: 'status', label: 'Status' },
      { field: 'enqueuedTime', label: 'Time added' },
      { field: 'executedTime', label: 'Started time' },
      { field: 'priority', label: 'Priority' },
      { field: 'user', label: 'User' },
      { field: 'Options', label: 'Options' },
    ],
  ],
  [
    'Enqueued',
    [
      { field: 'serviceOperation', label: 'Service' },
      { field: 'accessCode', label: 'Access' },
      { field: 'supplier', label: 'Supplier' },
      { field: 'status', label: 'Status' },
      { field: 'enqueuedTime', label: 'Time added' },
      { field: 'priority', label: 'Priority' },
      { field: 'user', label: 'User' },
      { field: 'Options', label: 'Options' },
    ],
  ],
]);

@Component({
  selector: 'tgx-billing-scheduler-details',
  templateUrl: './scheduler-details.component.html',
  styleUrls: ['./scheduler-details.component.scss'],
})
export class SchedulerDetailsComponent implements OnInit {
  @Input() source: any;
  @Input() title: string;

  sourceAux: any;

  header: any[];

  showTable = false;
  ok = 0;
  minorWarn = 0;
  warn = 0;
  ko = 0;

  filteredBy = 'All';

  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private taskManagerService: TaskManagerService,
  ) {}

  ngOnInit() {
    this.header = headers.get(this.title);

    if (this.title === 'Complete') {
      const operations: string[] = [];
      const statuses: string[] = [];
      const priorities: string[] = [];

      this.source.rows.forEach((row) => {
        let index = operations.findIndex((x) => x === row.serviceOperation);
        if (index === -1) {
          operations.push(row.serviceOperation);
        }

        index = statuses.findIndex((x) => x === row.schedulerStatus);
        if (index === -1) {
          statuses.push(row.schedulerStatus);
        }

        index = priorities.findIndex((x) => x === row.priority);
        if (index === -1) {
          priorities.push(row.priority);
        }
      });

      this.sourceAux = copyObject(this.source);
    }

    if (this.source.indicators?.ok) {
      this.ok = this.source.indicators?.ok;
    }
    if (this.source.indicators?.minorWarning) {
      this.minorWarn = this.source.indicators?.minorWarning;
    }
    if (this.source.indicators?.warning) {
      this.warn = this.source.indicators?.warning;
    }
    if (this.source.indicators?.ko) {
      this.ko = this.source.indicators?.ko;
    }
  }

  onEdit(event: any) {
    window.open(event.kibanaLink, '_blank');
  }

  onCancel(event: any) {
    if (this.title === 'Complete') {
      if (event.noStats || !event.parsedStats?.statsLangArray) {
        this.notificationService.warning('No details available.', 'Warning');
        return;
      }

      const modalRef = this.modalService.open(SchedulerInfoComponent, {
        size: 'xl',
        backdrop: 'static',
        container: 'nb-layout',
      });

      modalRef.componentInstance.stats = event.parsedStats;
      modalRef.componentInstance.title = event.serviceOperation;
      return;
    }

    if (this.title === 'Executing' && event.serviceOperation !== 'DescriptiveInfo') {
      this.notificationService.warning('Only DescriptiveInfo service operation can be aborted.', 'Warning');
      return;
    }

    const modalRef = this.modalService.open(ModalDeleteComponent, {
      backdrop: 'static',
      container: 'nb-layout',
    });

    let action = 'cancel';
    let result = 'cancelled';
    if (this.title === 'Executing') {
      action = 'abort';
      result = 'aborted';
    }

    modalRef.componentInstance.message =
      'Are you sure that you want to ' + action + ' the current task ' + event.taskID.toString() + '?';

    action = action.charAt(0).toUpperCase() + action.slice(1);

    modalRef.componentInstance.title = action + ' Task';
    modalRef.componentInstance.buttonOkTitle = action + ' Task';

    const self = this;

    modalRef.result
      .then((res) => {
        // on close
        if (res) {
          self.taskManagerService
            .abortTask(event.taskID)
            .then((rs) => {
              this.notificationService.success('Task successfully ' + result + '.', 'Success');
              modalRef.close(rs);
            })
            .catch((err) => {
              this.notificationService.handleGatewayAndGraphqlErrors(err);
            });
        }
      })
      .catch((error) => {
        self.notificationService.handleGatewayAndGraphqlErrors(error);
      });
  }

  formatDate(date: string): string {
    const d = new Date(date);
    const day = ('0' + d.getDate()).slice(-2);
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const year = d.getFullYear();
    const hours = ('0' + d.getHours()).slice(-2);
    const minutes = ('0' + d.getMinutes()).slice(-2);
    const seconds = ('0' + d.getSeconds()).slice(-2);
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }

  formatData(data: any, head: string, row: any) {
    if (head === 'adviseMessages' && data?.length > 0) {
      let message = data[0].description;
      if (message.includes(', check external for more details')) {
        const externalMessage = data[0].external?.message;
        message = message.replace(', check external for more details', ' : ' + externalMessage);
      }
      data = message;
    }

    if (head === 'enqueuedTime' || head === 'executedTime' || head === 'completeTime') {
      data = this.formatDate(data);
    }

    return data;
  }

  filter(status: string) {
    let rows: any[] = [];

    switch (status) {
      case 'all':
        this.source.rows = this.sourceAux?.rows;
        this.filteredBy = 'All';
        break;
      case 'ok':
        rows = this.sourceAux?.rows?.filter(
          (x) =>
            x.schedulerStatus === 'Ok' ||
            x.schedulerStatus === 'Success' ||
            x.schedulerStatus === 'OperationNotImplemented' ||
            x.schedulerStatus === 'Completed',
        );
        this.source.rows = rows;
        this.filteredBy = 'Ok';
        break;
      case 'minorWarn':
        rows = this.sourceAux?.rows?.filter((x) => x.schedulerStatus === 'Minorwarning');
        this.source.rows = rows;
        this.filteredBy = 'Minor Warning';
        break;
      case 'warn':
        rows = this.sourceAux?.rows?.filter((x) => x.schedulerStatus === 'Warning');
        this.source.rows = rows;
        this.filteredBy = 'Warning';
        break;
      case 'ko':
        rows = this.sourceAux?.rows?.filter(
          (x) =>
            x.schedulerStatus !== 'Ok' &&
            x.schedulerStatus !== 'Success' &&
            x.schedulerStatus !== 'OperationNotImplemented' &&
            x.schedulerStatus !== 'Minorwarning' &&
            x.schedulerStatus !== 'Warning' &&
            x.schedulerStatus !== 'Completed',
        );
        this.source.rows = rows;
        this.filteredBy = 'Ko';
        break;
    }
  }

  isValidFilterColumn(theadName: string) {
    if (this.title === 'Complete') {
      if (
        theadName !== 'Options' &&
        theadName !== 'executionTime' &&
        theadName !== 'completeTime' &&
        theadName !== 'enqueuedTime'
      ) {
        return true;
      }
    } else if (this.title === 'Executing') {
      if (theadName !== 'Options' && theadName !== 'enqueuedTime') {
        return true;
      }
    } else if (this.title === 'Enqueued') {
      if (theadName !== 'Options' && theadName !== 'enqueuedTime') {
        return true;
      }
    }
    return false;
  }

  getTrClass(x: any): string {
    if (this.title !== 'Complete') {
      return '';
    }

    if (
      x.schedulerStatus === 'Ok' ||
      x.schedulerStatus === 'Success' ||
      x.schedulerStatus === 'OperationNotImplemented' ||
      x.schedulerStatus === 'Completed'
    ) {
      return 'ok-row';
    }

    if (x.schedulerStatus === 'MinorWarning' || x.schedulerStatus === 'Minorwarning') {
      return 'minor-warning-row';
    }

    if (x.schedulerStatus === 'Warning') {
      return 'warning-row';
    }

    if (
      x.schedulerStatus !== 'Ok' &&
      x.schedulerStatus !== 'Success' &&
      x.schedulerStatus !== 'OperationNotImplemented' &&
      x.schedulerStatus !== 'Minorwarning' &&
      x.schedulerStatus !== 'Warning' &&
      x.schedulerStatus !== 'Completed'
    ) {
      return 'ko-row';
    }
  }
}
