import { gql } from 'apollo-angular';

export const unArchiveOrganization = gql`
  mutation unArchiveOrganization($where: OrganizationWhereUniqueInput!) {
    admin {
      unArchiveOrganization(where: $where) {
        code
        adviseMessage {
          code
          description
        }
      }
    }
  }
`;
