<table class="xen-table is-full table-mapping" *ngIf="!isLoading; else loadingTemplate">
  <thead>
    <tr>
      <th *ngFor="let head of tHead">{{ head.label }}</th>
      <th></th>
    </tr>
  </thead>
  <tbody *ngFor="let nonValidate of nonValidateMasters">
    <tr
      class="first-tbody"
      [ngClass]="{ '--is-child': idMasterSelected === nonValidate.id && nonValidate.show_child }"
      (click)="retrieveMasterSons(nonValidate)"
    >
      <td>
        <i class="fa-solid fa-circle-check me-1 pt-1" [ngClass]="{ 'text-success': validate }"></i>
        {{ nonValidate.name }}
      </td>
      <td>{{ nonValidate.id }}</td>
      <td>{{ nonValidate.location }}</td>
      <td>{{ findNameCountry(nonValidate.country) }}</td>
      <td>{{ nonValidate.address }}</td>
      <td class="text-end">
        <i
          class="fa-regular fa-chevron-{{
            nonValidate.show_child && idMasterSelected === nonValidate.id ? 'up' : 'down'
          }}"
        ></i>
      </td>
    </tr>
    <!-- HOTELS -->
    <ng-container *ngIf="nonValidate.show_child && idMasterSelected === nonValidate.id">
      <tr class="child">
        <td colspan="10" class="pt-0">
          <table class="xen-table is-full mt-3">
            <tbody>
              <tr>
                <td colspan="10" class="xen-table__unmap d-flex">
                  <label>
                    <div
                      (click)="selectedHotels.size > 0 ? openModalUnmap(nonValidate) : null"
                      [ngClass]="{ 'isDisabled': selectedHotels.size === 0 }"
                    >
                      <p class="mb-0 ms-2 xen-table__unmap__txt">
                        <i class="fa-regular fa-link-slash"></i> Unmap selected properties
                      </p>
                    </div>
                  </label>
                </td>
              </tr>
              <tr *ngFor="let hotel of nonValidateHotelsCP()" class="bg-td">
                <td>
                  <label>
                    <input
                      [(ngModel)]="hotel.selected"
                      (change)="onCheckboxChange(hotel, $event)"
                      type="checkbox"
                      class="form-check-input me-2"
                      id=""
                    />{{ hotel.name }}
                  </label>
                </td>
                <td>{{ hotel.hotel_code }}</td>
                <td>{{ hotel.location }}</td>
                <td>{{ findNameCountry(hotel.country) }}</td>
                <td>{{ hotel.address }}</td>
              </tr>
              <tr>
                <td colspan="10" *ngIf="nonValidateHotels?.length > pageSizeHotels">
                  <xen-pagination
                    class="d-flex justify-content-start"
                    [(page)]="pageHotels"
                    [pageSize]="pageSizeHotels"
                    [maxSize]="3"
                    [collectionSize]="nonValidateHotels?.length"
                    (pageChange)="onPageChangeHotels($event)"
                  ></xen-pagination>
                </td>
              </tr>
              <tr class="tr-btn">
                <td class="ps-0 py-3 px-0" colspan="10">
                  <div class="d-flex flex-row-reverse gap-2">
                    <xen-btn
                      [type]="'primary'"
                      [size]="'small'"
                      [label]="'Validate this master'"
                      [id]="'validate-master'"
                      [full_width]="false"
                      [icon]="true"
                      [icon_placement]="'left'"
                      [icon_snippet]="'fa-light fa-circle-check'"
                      [disabled]="false"
                      [loading]="false"
                      [skin]="'green'"
                      (click)="openModalValidate(nonValidate)"
                    ></xen-btn>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>

<ng-template #loadingTemplate>
  <table class="xen-table is-full">
    <thead>
      <tr>
        <th *ngFor="let head of tHead">{{ head.label }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]">
        <td *ngFor="let item of [1, 2, 3, 4, 5]">
          <p-skeleton width="10rem"></p-skeleton>
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>

<xen-pagination
  *ngIf="nonValidateCount > 10"
  [(page)]="page"
  [pageSize]="pageSize"
  [collectionSize]="nonValidateCount"
  [isDisabled]="isLoading"
  [maxSize]="10"
  (pageChange)="onPageChange($event)"
></xen-pagination>
