<nb-card>
  <nb-card-header>
    <span [ngClass]="{ 'mandatory': totalInvoice.toString() !== totalSum }"
      >Breakdowns (Total Sum: {{ totalSum }})</span
    >
  </nb-card-header>

  <nb-card-body>
    <p-table
      #dt2
      [value]="breakdownsLoadSource"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      styleClass="p-datatable-gridlines p-datatable-striped"
      [tableStyle]="{ 'min-width': '50rem' }"
      editMode="row"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
      selectionMode="single"
      [(selection)]="selectedInvoice"
      dataKey="id"
      [resizableColumns]="true"
      columnResizeMode="expand"
      [loading]="isLoading"
      loadingIcon="pi pi-spin pi-spinner"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let head of header" [pSortableColumn]="head.field">
            {{ head.label }}
            <p-sortIcon *ngIf="head.field !== 'Options'" [field]="head.field"></p-sortIcon>
          </th>
        </tr>
        <tr>
          <th *ngFor="let head of header" [style]="head.field === 'Options' ? 'width: 3rem' : ''">
            <p-columnFilter
              type="text"
              *ngIf="head.field !== 'Options'"
              [field]="head.field"
              [showMenu]="false"
              [showClearButton]="false"
              (input)="filterDT2($event.target.value, head.field)"
            ></p-columnFilter>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-breakdown let-editing="editing">
        <tr [pSelectableRow]="breakdown" [pEditableRow]="breakdown">
          <td>{{ breakdown.chargeType }}</td>
          <td>{{ breakdown.paymentType }}</td>
          <td>{{ breakdown.dataType }}</td>
          <td>{{ breakdown.costType }}</td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="breakdown.amount" required />
              </ng-template>
              <ng-template pTemplate="output"> {{ breakdown.amount }} </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="breakdown.quantity" required />
              </ng-template>
              <ng-template pTemplate="output"> {{ breakdown.quantity }} </ng-template>
            </p-cellEditor>
          </td>
          <td>{{ breakdown.buyer }}</td>
          <td>{{ breakdown.seller }}</td>
          <td>{{ breakdown.client }}</td>
          <td>{{ breakdown.supplier }}</td>
          <td *ngIf="!isEditor"></td>
          <td *ngIf="isEditor">
            <div class="flex align-items-center justify-content-center gap-2">
              <button
                *ngIf="!editing"
                [disabled]="!isEditor"
                pButton
                pRipple
                type="button"
                pInitEditableRow
                icon="pi pi-pencil"
                class="p-button-rounded p-button-text"
              ></button>
              <button
                *ngIf="editing"
                pButton
                pRipple
                type="button"
                pSaveEditableRow
                icon="pi pi-check"
                (click)="onEdit(breakdown)"
                class="p-button-rounded p-button-text p-button-success mr-2"
              ></button>
              <button
                *ngIf="editing"
                pButton
                pRipple
                type="button"
                pCancelEditableRow
                icon="pi pi-times"
                class="p-button-rounded p-button-text p-button-danger"
              ></button>
              <button
                [disabled]="!isEditor"
                pButton
                pRipple
                type="button"
                icon="pi pi-times"
                (click)="onDelete(breakdown)"
                class="p-button-rounded p-button-text"
              ></button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="10" class="text-center">No breakdowns found.</td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>
