import { Component, OnInit } from '@angular/core';
import { WebAdminService } from './../../../../../@core/services/_index-core.services';

@Component({
  selector: 'tgx-admin-billing-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsBillingComponent implements OnInit {
  isEditor = false;

  constructor(private webAdminService: WebAdminService) {}

  ngOnInit(): void {
    this.isEditor = this.webAdminService.isBillingEditorOrAdmin();
  }
}
