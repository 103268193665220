<div class="modal-header">
  <span>{{ header }}</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="subTypeForm">
    <nb-card>
      <nb-card-header> Data </nb-card-header>
      <nb-card-body>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="id">Name (Min 3)</label>
              <input
                type="text"
                class="form-control"
                name="name"
                formControlName="name"
                (keyup)="checkSubTypeCode($event)"
              />
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <div class="form-group">
              <label class="mb-1" for="id">Odoo Department (Optional)</label>
              <input type="text" class="form-control" name="odooDepartment" formControlName="odooDepartment" />
            </div>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()">Cancel</button>
  <button class="btn btn-md btn-primary" [disabled]="subTypeForm.invalid" (click)="onSave()">
    {{ isCreating ? 'Create' : 'Update' }}
  </button>
</div>
