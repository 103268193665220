import { Component, Input } from '@angular/core';

@Component({
  selector: 'xen-meta-help',
  templateUrl: './meta-help.component.html',
  styleUrls: ['./meta-help.component.scss'],
})
export class MetaHelpComponent {
  @Input() cardsHelp = [];
  @Input() title: string;
  @Input() card_title: string;
  @Input() card_subtitle: string;
  @Input() card_link: string;
}
