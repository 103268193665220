import { gql } from 'apollo-angular';

export const getCategories = gql`
  query getCategories {
    social {
      allCategories {
        edges {
          node {
            categoryData {
              code
              subCategories {
                edges {
                  node {
                    code
                  }
                }
              }
            }
          }
        }
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
