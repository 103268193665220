import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { ModuleType, Options } from '../../../../../../@core/interfaces/_index.interfaces';
import { MembersConnection, OrganizationSelector } from '../../../../../entities/interfaces/_index-entities.interfaces';
import { AddMemberToOrganizationModalComponent } from '../add-members-modal/add-member-to-org-modal.component';
import { MembersToggleActiveModalComponent } from '../members-ToggleActive-modal/members-ToggleActive-modal.component';
import { MembersModalComponent } from '../members-modal/members-modal.component';
import { UpdateRoleMemberComponent } from '../members-update-role-modal/update-member-role-modal.component';
import { NotificationService, WebAdminService } from './../../../../../../@core/services/_index-core.services';
import { ModalDeleteComponent } from './../../../../../../@core/shared/modal-delete/modal-delete.component';
import { Members, MemberToOrganizationInput } from './../../../../interfaces/_index-entities.interfaces';
import { MemberNetworkService } from './../../../../services/_index-entities.services';
import {
  RemoveMemberServiceRoleDataInput,
  UpdateMemberRoleDataInput,
  UpdateMemberRoleWhereInput,
  UpdateMemberServiceRoleDataInput,
} from '../../../../interfaces/inputs/update-member-role-input';
import { Table } from 'primeng/table';
import { MembersLogsModalComponent } from '../members-logs-modal/members-logs-modal.component';

@Component({
  selector: 'tgx-members-table',
  templateUrl: './members-table.component.html',
  styleUrls: ['./members-table.component.scss'],
})
export class MembersTableComponent implements OnInit, OnDestroy {
  @ViewChild('td1', { static: false }) table: Table;
  selectedMembers: any[] = [];
  selectedMembersAux: any[];

  $subs: Subscription[] = [];

  public queryForm: UntypedFormGroup;
  organizations: Options[];
  orgSelectors: OrganizationSelector[];

  headers1 = [
    { label: 'Code', field: 'code', placeholder: 'Code' },
    { label: 'Status', field: 'status', placeholder: 'Status' },
    { label: 'Options', field: 'Options', placeholder: 'Options' },
  ];

  headers2 = [
    { label: 'Organization', field: 'organization', placeholder: 'Organization' },
    { label: 'Role', field: 'role', placeholder: 'Role' },
    { label: 'Options', field: 'Options', placeholder: 'Options' },
  ];

  userProfilesEdges: any[];

  members: Members[];
  membersLoadSource: any[];

  isLoading = true;

  mapPageCursor: Map<number, string> = new Map<number, string>();
  page = 1;
  organizationsCode: string[] = [];

  totalMembers = 0;
  filteredMember: string;

  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private webAdminService: WebAdminService,
    private memberNetworkService: MemberNetworkService,
    private cookieService: CookieService,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;

    this.buildOptions();
    this.buildForm();
    this.organizations = [];
    this.orgSelectors = [];

    this.initTable();
    this.$subs.push(
      this.memberNetworkService.onToggleTable$.subscribe((mActivate) => {
        if (mActivate) {
          this.onToggleActiveMember(mActivate);
        }
      }),
      this.memberNetworkService.onToggleArchive$.subscribe((mArchive) => {
        if (mArchive) {
          this.onToggleArchiveMember(mArchive);
        }
      }),
      this.memberNetworkService.filteredMember$.subscribe((member) => {
        this.filteredMember = member;
      }),
    );
  }

  async initTable() {
    const resultsMembers = await this.memberNetworkService.getAllMembersV2(false);
    this.totalMembers = (resultsMembers as MembersConnection).totalCount;
    const memberEdges = (resultsMembers as MembersConnection).edges;
    this.buildMembers(memberEdges);
  }

  ngOnDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
  }

  getStatusSeverity(status: string) {
    switch (status) {
      case 'Active':
        return 'p-button-success';
      case 'Inactive':
        return 'p-button-secondary';
      case 'Archived':
        return 'p-button-danger';
    }
  }

  buildOptions() {
    this.webAdminService.emitUserTypevalue(ModuleType.NETWORK);
  }

  async retrieveMembersByOrg(orgCodes: any, include_archived: any) {
    if (!orgCodes) {
      const data = await this.memberNetworkService.getAllMembersByOrgV2(orgCodes, include_archived);
      this.getMembersByOrg(data);
    }
  }

  getMembersByOrg(data) {
    this.membersLoadSource = [];

    data.forEach((data) => {
      const { organizationsData } = data['node'];
      const { code, label } = organizationsData;
      const orgCode = code;
      const orgLabel = label;

      if (organizationsData.members && organizationsData.members.length) {
        organizationsData.members.forEach((memberData) => {
          const { code, isActive } = memberData['member'];

          const memberRole = memberData['role'];

          this.membersLoadSource.push({
            code,
            organization: orgLabel + ' (' + orgCode + ')',
            role: memberRole,
            isActive,
          });
        });
      }

      this.isLoading = false;
    });
  }

  buildMembers(memberEdges) {
    this.membersLoadSource = [];
    const members = new Map<string, any>();
    memberEdges.forEach((data) => {
      const { deletedAt, membersData } = data['node'];

      const { code, isActive } = membersData;

      // const userProfile = userProfilesMap.get(code);
      const isArchived = !deletedAt ? false : true;
      const status = isActive ? 'Active' : isArchived ? 'Archived' : 'Inactive';
      if (!membersData.organizations || !membersData.organizations.length) {
        if (!members.has(code)) {
          members.set(code, {
            code,
            isActive,
            isArchived: isArchived,
            organizations: [],
            status: status,
            statusList: [],
          });
        }
      } else {
        membersData['organizations'].forEach((orgMember) => {
          const orgCode = orgMember['organization']['code'];
          const orgLabel = orgMember['organization']['label'];
          const orgRole = orgMember['role'];

          const orgServices = this.createOrgServices(orgMember['services']);

          if (!members.has(code)) {
            members.set(code, {
              code,
              isActive,
              isArchived: isArchived,
              status: status,
              statusList: [],
              organizations: [
                {
                  orgCode: orgCode,
                  organization: orgLabel + ' (' + orgCode + ')',
                  role: orgRole,
                  roleList: [],
                  services: orgServices,
                },
              ],
            });
          } else {
            members.get(code).organizations.push({
              organization: orgLabel + ' (' + orgCode + ')',
              role: orgRole,
              orgCode: orgCode,
              roleList: [],
              services: orgServices,
            });
          }
        });
      }
    });
    this.membersLoadSource = Array.from(members.values());
    this.isLoading = false;
    this.setItemsForButtons(this.membersLoadSource);
    if (this.membersLoadSource.length && this.filteredMember !== 'undefined') {
      this.addFilterToTable(this.filteredMember);
    }
  }
  createOrgServices(services) {
    let orgServices = [];

    const EXTRA_SERVICES = ['FASTX', 'BANNER'];

    // Extract existing service names for quick lookup
    const existingServices = new Set(services.map((s) => s.service));

    // Add existing services
    services.forEach((serviceObj) => {
      const updatedOrgServices = {
        service: serviceObj,
        serviceRoleList: [],
      };
      orgServices.push(updatedOrgServices);
    });

    // Add missing EXTRA_SERVICES with role "NONE"
    EXTRA_SERVICES.forEach((extraService) => {
      if (!existingServices.has(extraService)) {
        orgServices.push({
          service: {
            service: extraService,
            role: 'NONE',
          },
          serviceRoleList: [],
        });
      }
    });

    return orgServices;
  }
  setItemsForButtons(members: any[]) {
    this.membersLoadSource = members.map((member) => ({
      ...member,
      statusList: this.setStatus(member),
      organizations: this.processOrganizations(member),
    }));
  }

  private processOrganizations(member: any) {
    return (
      member.organizations?.map((organization) => ({
        ...organization,
        roleList: this.setRole(member, organization.orgCode || '', organization.role),
        services: this.processServices(member, organization.orgCode, organization.services),
      })) || []
    );
  }

  private processServices(member: any, organization: any, services: any[]) {
    return (
      services?.map((serviceObj) => ({
        ...serviceObj,
        serviceRoleList: this.setRoleService(member, serviceObj.service.service, organization, serviceObj.service.role),
      })) || []
    );
  }

  isTG(member: string): boolean {
    return member.endsWith('travelgate.com');
  }

  setRoleService(member, service, organization, role) {
    const roleMap = {
      'VIEWER': ['EDITOR', 'ADMIN', 'NONE'],
      'EDITOR': ['VIEWER', 'ADMIN', 'NONE'],
      'ADMIN': ['VIEWER', 'EDITOR', 'NONE'],
      'NONE': ['VIEWER', 'EDITOR', 'ADMIN'],
    };
    if (role in roleMap) {
      return roleMap[role].map((newRole) => ({
        label: newRole,
        code: newRole,
        command: () => {
          this.onUpdateServiceRole(member, organization, service, newRole);
        },
      }));
    }
  }

  setRole(member, orgCode, role) {
    const roleMap = {
      'VIEWER': ['EDITOR', 'ADMIN'],
      'EDITOR': ['VIEWER', 'ADMIN'],
      'ADMIN': ['VIEWER', 'EDITOR'],
    };
    if (role in roleMap) {
      return roleMap[role].map((newRole) => ({
        label: newRole,
        orgCode: orgCode,
        command: () => {
          this.onUpdateRole(member, orgCode, newRole);
        },
      }));
    }
  }

  setStatus(member) {
    const statusMap = {
      'Active': [
        {
          label: 'Deactivate',
          icon: 'pi pi-power-off',
        },
      ],
      'Inactive': [
        {
          label: 'Activate',
          icon: 'pi pi-play',
        },
        {
          label: 'Archive',
          icon: 'pi pi-folder',
        },
      ],
      'Archived': [
        {
          label: 'Unarchive',
          icon: 'pi pi-folder-open',
        },
      ],
    };

    return statusMap[member.status].map((item) => ({
      ...item,
      command: () => {
        if (item.label === 'Activate' || item.label === 'Deactivate') {
          this.onToggleActiveMember(member);
        } else {
          this.onToggleArchiveMember(member);
        }
      },
    }));
  }

  onCustom($event): void {
    switch ($event.action) {
      case 'impersonate':
        this.onImpersonate($event);
        break;
      case 'edit':
        this.onEdit($event);
        break;
      case 'delete':
        this.onCancel($event);
        break;
      case 'addMemberToOrg':
        this.onAddMemberToOrg($event);
        break;
    }
  }

  async onAddMemberToOrg(member): Promise<void> {
    const activeModal = this.modalService.open(AddMemberToOrganizationModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });
    activeModal.componentInstance.member = member;
    const self = this;
    activeModal.result
      .then((res) => {
        // on close

        if (typeof res === 'boolean' && res) {
          //do nothing
        } else if (typeof res === 'boolean' && !res) {
          //do nothing
        }
        // OK
        else if (typeof res === 'object') {
          self.ngOnInit();
        }
      })
      .catch((err) => {
        this.notificationService.error(err);
      });
  }

  async updateRole(member, orgCode, role) {
    const where: UpdateMemberRoleWhereInput = {
      member: member.code,
      organization: orgCode,
    };
    const data: UpdateMemberRoleDataInput = {
      role: role,
    };
    await this.memberNetworkService
      .updateMemberRole(data, where)
      .then((rs) => {
        const updatedMember = this.membersLoadSource.find((memberCode) => memberCode.code === member.code);
        const updatedRoleMember = updatedMember.organizations.find((code) => code.orgCode === orgCode);
        if (updatedRoleMember) {
          updatedRoleMember['role'] = role;
          updatedRoleMember['roleList'] = this.setRole(updatedMember, orgCode, role);
        }
        this.notificationService.success('Role successfully updated', 'Success');
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      });
  }
  async updateServiceRole(member, orgCode, service, role) {
    const where: UpdateMemberRoleWhereInput = {
      member: member.code,
      organization: orgCode,
    };
    const data: UpdateMemberServiceRoleDataInput = {
      role: role,
      service: service,
    };
    await this.memberNetworkService
      .updateMemberServiceRole(data, where)
      .then((rs) => {
        const updatedMember = this.membersLoadSource.find((memberCode) => memberCode.code === member.code);
        const updatedRoleMember = updatedMember.organizations.find((code) => code.orgCode === orgCode);
        const updatedServiceRoleMember = updatedRoleMember.services.find(
          (serviceUpd) => serviceUpd.service.service === service,
        );
        if (updatedServiceRoleMember) {
          updatedServiceRoleMember.service = Object.assign({}, updatedServiceRoleMember.service, { role });
          updatedServiceRoleMember.serviceRoleList = this.setRoleService(updatedMember, service, orgCode, role);
        }
        this.notificationService.success('Role successfully updated', 'Success');
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      });
  }

  async removeServiceRole(member, orgCode, service) {
    const where: UpdateMemberRoleWhereInput = {
      member: member.code,
      organization: orgCode,
    };
    const data: RemoveMemberServiceRoleDataInput = {
      service: service,
    };
    await this.memberNetworkService
      .removeMemberServiceRole(data, where)
      .then((rs) => {
        const updatedMember = this.membersLoadSource.find((memberCode) => memberCode.code === member.code);
        const updatedRoleMember = updatedMember.organizations.find((code) => code.orgCode === orgCode);
        const updatedServiceRoleMember = updatedRoleMember.services.find(
          (serviceUpd) => serviceUpd.service.service === service,
        );
        if (updatedServiceRoleMember) {
          const role = 'NONE';
          updatedServiceRoleMember.service = Object.assign({}, updatedServiceRoleMember.service, { role });
          updatedServiceRoleMember.serviceRoleList = this.setRoleService(updatedMember, service, orgCode, role);
        }
        this.notificationService.success('Role successfully removed', 'Success');
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      });
  }

  onUpdateRole(member, orgCode, role) {
    const activeModal = this.modalService.open(UpdateRoleMemberComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });
    activeModal.componentInstance.member = member.code;
    activeModal.componentInstance.org = member.organizationCode;
    activeModal.componentInstance.message = 'Do you really want to set as ' + role + ' this member?';
    activeModal.dismissed.subscribe(async (res) => {
      if (res === true) {
        await this.updateRole(member, orgCode, role);
      }
    });
  }

  onUpdateServiceRole(member, orgCode, service, role) {
    const activeModal = this.modalService.open(UpdateRoleMemberComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    Object.assign(activeModal.componentInstance, {
      member: member.code,
      org: member.organizationCode,
      message:
        role === 'NONE'
          ? `Do you really want to remove service ${service} from this member?`
          : `Do you really want to set ${role} to service ${service} for this member?`,
    });

    activeModal.dismissed.subscribe(async (res) => {
      if (res === true) {
        const action = role === 'NONE' ? this.removeServiceRole : this.updateServiceRole;
        await action.call(this, member, orgCode, service, ...(role !== 'NONE' ? [role] : []));
      }
    });
  }

  async onToggleActiveMember(member): Promise<void> {
    const activeModal = this.modalService.open(MembersToggleActiveModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    const operation = member.isActive ? 'Deactivate' : 'Activate';

    activeModal.componentInstance.header = operation + ' Member';
    activeModal.componentInstance.message = 'Do you really want to ' + operation + ' this member?';
    activeModal.componentInstance.btnLabel = operation;
    activeModal.dismissed.subscribe(async (res) => {
      if (typeof res === 'boolean' && res) {
        await this.activateDeactivateMember(member);
      }
    });
  }

  async onToggleArchiveMember(member): Promise<void> {
    const activeModal = this.modalService.open(MembersToggleActiveModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    const operation = !member.isArchived || member.status === 'Inactive' ? 'Archive' : 'UnArchive';

    activeModal.componentInstance.header = operation + ' Member';
    activeModal.componentInstance.message = 'Do you really want to ' + operation + ' this member?';
    activeModal.componentInstance.btnLabel = operation;
    activeModal.dismissed.subscribe(async (res) => {
      if (typeof res === 'boolean' && res) {
        await this.archiveUnarchiveMember(member);
      }
    });
  }

  async archiveUnarchiveMember(member) {
    const operationMsg = member.isArchived ? 'UnArchived' : 'Archived';

    if (member.isArchived) {
      this.memberNetworkService
        .unarchiveMember(member.code)
        .then((_rs) => {
          this.notificationService.success('Member succesfully ' + operationMsg + '.', 'Success');
          const updatedMember = this.membersLoadSource.find((memberCode) => memberCode.code === member.code);
          if (updatedMember) {
            updatedMember['status'] = 'Active';
            updatedMember['isArchived'] = !member.isArchived;
            updatedMember['statusList'] = this.setStatus(updatedMember);
            updatedMember['isActive'] = !member.isActive;
          }
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    } else {
      this.memberNetworkService
        .archiveMember(member.code)
        .then((_rs) => {
          this.notificationService.success('Member succesfully ' + operationMsg + '.', 'Success');
          const updatedMember = this.membersLoadSource.find((memberCode) => memberCode.code === member.code);
          if (updatedMember) {
            updatedMember['status'] = 'Archived';
            updatedMember['isArchived'] = !member.isArchived;
            updatedMember['statusList'] = this.setStatus(updatedMember);
          }
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    }
  }

  async activateDeactivateMember(member) {
    const operationMsg = member.isActive ? 'Deactivated' : 'Activated';

    if (member.isActive) {
      this.memberNetworkService
        .deactivateMember(member.code)
        .then((_rs) => {
          this.notificationService.success('Member succesfully ' + operationMsg + '.', 'Deactivated');
          const updatedMember = this.membersLoadSource.find((memberCode) => memberCode.code === member.code);
          if (updatedMember) {
            updatedMember['status'] = 'Inactive';
            updatedMember['statusList'] = this.setStatus(updatedMember);
            updatedMember['isActive'] = !member.isActive;
          }
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    } else {
      this.memberNetworkService
        .activateMember(member.code)
        .then((_rs) => {
          this.notificationService.success('Member succesfully ' + operationMsg + '.', 'Activated');
          const updatedMember = this.membersLoadSource.find((memberCode) => memberCode.code === member.code);
          if (updatedMember) {
            if (updatedMember) {
              updatedMember['status'] = 'Active';
              updatedMember['statusList'] = this.setStatus(updatedMember);
              updatedMember['isActive'] = !member.isActive;
            }
          }
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    }
  }

  async onEdit(event): Promise<void> {
    const member: Members = event.data;

    const activeModal = this.modalService.open(MembersModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = 'Edit Member';
    activeModal.componentInstance.member = member;

    const self = this;
    activeModal.result.then((res) => {
      // on close

      if (typeof res === 'boolean' && res) {
        //do nothing
      } else if (typeof res === 'boolean' && !res) {
        //do nothing
      }
      // OK
      else if (typeof res === 'object') {
        self.ngOnInit();
      }
    });
  }

  onImpersonate(member): void {
    const modalRef = this.modalService.open(ModalDeleteComponent, {
      backdrop: 'static',
      container: 'nb-layout',
    });

    modalRef.componentInstance.message = 'Do you want to impersonate the member ' + member.code + '?';
    modalRef.componentInstance.title = 'Impersonate';
    modalRef.componentInstance.buttonOkTitle = 'Impersonate';

    const self = this;

    modalRef.result
      .then((res) => {
        // on close
        if (res) {
          const currentDomain = window.location.hostname;
          let baseDomain = 'travelgate.com';

          if (currentDomain.includes('x')) {
            baseDomain = 'travelgatex.com';
          }

          this.cookieService.set('impersonation', member.code, 0, '/', baseDomain);
          window.open(`https://app.${baseDomain}/dashboard`);
        }
      })
      .catch((error) => {
        self.notificationService.error(error);
      });
  }

  onCancel(member): void {
    if (member.organizationCode === undefined) {
      this.notificationService.warning(
        'This user has no organization, please contact to CORE team to solve this issue',
      );
      return;
    }

    const modalRef = this.modalService.open(ModalDeleteComponent, {
      backdrop: 'static',
      container: 'nb-layout',
    });

    modalRef.componentInstance.message =
      'Are you sure that you want to remove from this organization this member ' + member.code + '?';
    modalRef.componentInstance.title = 'Remove member from this organization';
    modalRef.componentInstance.buttonOkTitle = 'Ok';

    const self = this;

    const where: MemberToOrganizationInput = { member: member.code, organization: member.organizationCode };

    modalRef.result
      .then((res) => {
        // on close
        if (res) {
          this.memberNetworkService
            .removeMemberFromOrganization(where)
            .then((rs) => {
              this.notificationService.success('Member succesfully removed from this organization.', 'Success');
              modalRef.close(rs);
              self.ngOnInit();
            })
            .catch((err) => {
              if (err.code === '199') {
                this.notificationService.success('Member succesfully removed from this organization.', 'Success');
                modalRef.close();
                self.ngOnInit();
              } else {
                this.notificationService.handleGatewayAndGraphqlErrors(err);
              }
            });
        }
      })
      .catch((error) => {
        self.notificationService.error(error);
      });
  }

  buildForm() {
    this.queryForm = this.fb.group({
      codes: [''],
      includeArchived: false,
    });
  }

  buildOrganizationsDropdown(groupsToAdd: any) {
    if (this.organizations.length === 0) {
      this.organizations = [];
      this.organizationsCode = [];
    }

    groupsToAdd.forEach((group) => {
      const label = group.label + ' (' + group.code + ')';
      this.organizations.push({ code: group.code, label: label, isTeam: group.isTeam });
      this.organizationsCode.push(group.code);
    });
  }

  resetTable() {
    this.isLoading = true;
    this.buildForm();
    this.page = 1;
    this.mapPageCursor = new Map<number, string>();
    this.initTable();
  }

  async runQuery() {
    this.totalMembers = 0;

    const orgCodes =
      this.queryForm.get('codes').value !== '' ? this.queryForm.get('codes').value.map((value) => value.code) : null;
    const includeArchived = this.queryForm.get('includeArchived').value;
    if (orgCodes !== null && orgCodes.length > 0) {
      this.retrieveMembersByOrg(orgCodes, includeArchived);
    } else {
      this.isLoading = true;
      const result = await this.memberNetworkService.getAllMembersV2(includeArchived);
      const edges = result.edges;
      this.totalMembers = result.totalCount;

      this.buildMembers(edges);
    }
    this.isLoading = false;
    this.page = 1;
    this.mapPageCursor = new Map<number, string>();
  }

  addFilterToTable(value) {
    if (value) {
      setTimeout(() => {
        this.table?.filter(value, 'code', 'contains');
      }, 1000);
    }
  }

  filterTable(member: string, field: string) {
    this.table.filter(member, field, 'contains');
    if (member) {
      this.memberNetworkService.filteredMember$.next(member);
    } else {
      this.memberNetworkService.filteredMember$.next(null);
    }
  }

  async onLogs(member: any) {
    const activeModal = this.modalService.open(MembersLogsModalComponent, {
      size: 'xl',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = 'Member logs: ' + member.code;
    activeModal.componentInstance.memberCode = member.code;
  }
}
