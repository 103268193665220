import { Table } from 'primeng/table';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Partner } from '../../../../interfaces/_index.billing.interfaces';
import { ContractConnectionService, PartnerService } from '../../../../services/_index-billing.services';
import { NotificationService } from './../../../../../../@core/services/_index-core.services';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'tgx-admin-billing-connections-relations',
  templateUrl: './connections-relations.component.html',
  styleUrls: ['./connections-relations.component.scss'],
})
export class BillingConnectionsRelationsComponent implements OnInit {
  @ViewChild('dt', { static: false }) dataTable!: Table;
  public relationForm: UntypedFormGroup;
  $subs: Subscription[] = [];

  header = [
    { label: 'ID', field: 'id' },
    { label: 'ActivationDate', field: 'activationDateTime' },
    { label: 'EndDate', field: 'endDate' },
    { label: 'IsBuy', field: 'isBuy' },
    { label: 'ConType', field: 'connectionType' },
    { label: 'PartnerId', field: 'partnerId' },
    { label: 'PartnerName', field: 'partnerName' },
    { label: 'OrgCode', field: 'partnerOrgCode' },
    { label: 'ContractID', field: 'contractId' },
    { label: 'PartnerType', field: 'contractPartnerType' },
    { label: 'PartnerSubType', field: 'contractPartnerSubType' },
    { label: 'modelType', field: 'contractModelType' },
  ];

  isLoading = false;

  connectionsLoadSource: any[];

  buyersAutocomplete: SelectItem[];
  sellersAutocomplete: SelectItem[];

  partners: Partner[];
  allPartnerItems: SelectItem[];

  constructor(
    private notificationService: NotificationService,
    private contractConnectionService: ContractConnectionService,
    private partnerService: PartnerService,
    private fb: UntypedFormBuilder,
    private datePipe: DatePipe,
  ) {}

  async ngOnInit(): Promise<void> {
    this.buildForm();
    await this.getBillingPartners();
  }

  buildForm() {
    this.relationForm = this.fb.group({
      buyer: ['', Validators.required],
      seller: ['', Validators.required],
    });
  }

  async getBillingPartners() {
    this.partners = [];
    this.buyersAutocomplete = [];
    this.sellersAutocomplete = [];
    this.allPartnerItems = [];

    await this.partnerService
      .getAllPartners()
      .then((prtns) => {
        if (prtns?.length > 0) {
          this.partners = prtns;
          this.partners.forEach((p) => {
            this.buyersAutocomplete.push({ value: p.id, label: `${p.name} (${p.orgCode})` });
            this.sellersAutocomplete.push({ value: p.id, label: `${p.name} (${p.orgCode})` });
            this.allPartnerItems.push({ value: p.id, label: `${p.name} (${p.orgCode})` });
          });
        }
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  async getContractConnections() {
    this.isLoading = true;
    this.connectionsLoadSource = [];

    if (this.dataTable) {
      this.dataTable.clearFilterValues();
    }

    const buyer = this.relationForm.get('buyer').value.value;
    const seller = this.relationForm.get('seller').value.value;

    await this.contractConnectionService
      .getContractConnectionsByBuyerAndSeller(buyer, seller)
      .then((connections) => {
        if (connections?.length > 0) {
          connections.forEach((c) => {
            const { id, activationDateTime, endDate, isBuy, connectionType } = c;

            const start = this.datePipe.transform(activationDateTime, 'dd-MM-yyyy');
            const end = this.datePipe.transform(endDate, 'dd-MM-yyyy');

            this.connectionsLoadSource.push({
              id,
              'activationDateTime': start,
              'endDate': end,
              isBuy,
              connectionType,
              'partnerId': c.Contract?.Partner?.id,
              'partnerName': c.Contract?.Partner?.name,
              'partnerOrgCode': c.Contract?.Partner?.orgCode,
              'contractId': c.Contract?.id,
              'partnerType': c.Contract?.partnerType,
              'partnerSubType': c.Contract?.ContractType ? c.Contract?.ContractType.name : '',
              'modelType': c.Contract?.modelType ? c.Contract?.modelType : '',
            });
          });
          this.isLoading = false;
          this.connectionsLoadSource = [...this.connectionsLoadSource];
          this.connectionsLoadSource.sort((a, b) => (a.id < b.id ? 1 : -1));
        } else {
          this.isLoading = false;
        }
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
        this.isLoading = false;
      });
  }

  autoCompleteBuyers(event: any) {
    this.buyersAutocomplete = [];
    const query = event.query.toLowerCase();
    this.buyersAutocomplete = this.allPartnerItems.filter((x) => x.label.toLowerCase().includes(query));
  }

  autoCompleteSellers(event: any) {
    this.sellersAutocomplete = [];
    const query = event.query.toLowerCase();
    this.sellersAutocomplete = this.allPartnerItems.filter((x) => x.label.toLowerCase().includes(query));
  }

  validForm(): boolean {
    return (
      this.relationForm.get('buyer').value.value &&
      this.relationForm.get('seller').value.value &&
      this.relationForm.valid
    );
  }

  getFilterType(field: string) {
    switch (field) {
      case 'isBuy':
        return 'boolean';
      default:
        return 'text';
    }
  }

  filter(value: any, field: string) {
    if (value) {
      this.dataTable.filter(value, field, 'contains');
    }
  }
}
