import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ClientLegacyConnection } from '../interfaces/models/client.legacy-connection.interface';
import { GraphqlGatewayMutations } from './mutations/_index-entities.mutations';
import { GraphqlGatewayQueries } from './queries/_index-entities.queries';
import { RetrieveAllClientLegacyConnectionInput } from '../interfaces/inputs/retrieve-all-client-legacy-connection-input';
import { ClientLegacyConnectionInput } from '../interfaces/inputs/client-legacy-connection-update-status-input.interface';
import { GqlService } from '@tgx/shared/data-access/graphql';
import { NotificationService } from '../../../@core/services/notification.service';

@Injectable({
  providedIn: 'root',
})
export class LegacyConnectionEntitiesService {
  onToggleTable$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private gqlService: GqlService,
    private notificationService: NotificationService,
  ) {}

  async createLegacyConnection(input: ClientLegacyConnectionInput): Promise<ClientLegacyConnection[]> {
    return await new Promise((resolve, _reject) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.createLegacyConnection, { input: input })
        .then((response: any) => {
          if (!response.admin.createLegacyAccess.error) {
            resolve(response);
          } else {
            throw new Error(response.admin.createLegacyAccess.error[0].description);
          }
        })
        .catch((error) => {
          this.notificationService.error(error);
        });
    });
  }

  async getLegacyConnectionByClient(input: RetrieveAllClientLegacyConnectionInput): Promise<ClientLegacyConnection[]> {
    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getLegacyConnectionByClient, { filter: input })
        .then((response) => {
          if (response['admin']['legacyAccesses']['edges'].length > 0) {
            resolve(response['admin']['legacyAccesses']['edges']);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async changeClientLegacyConnectionStatus(input: ClientLegacyConnectionInput): Promise<ClientLegacyConnection[]> {
    return await new Promise((resolve, _reject) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.changeStatusClientLegacyConnection, { input: input })
        .then((response: any) => {
          if (response['admin']['updateLegacyAccess']) {
            resolve(response['admin']['updateLegacyAccess']);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }
}
