import { gql } from 'apollo-angular';

export const getContractsGroupedByType = gql`
  {
    contractsGroupedByType {
      name
      numCount
    }
  }
`;
