export interface CheckResponse {
  success?: boolean;
}

export interface DuplicatedConnectionContractRS extends CheckResponse {
  response?: DuplicatedConnectionContract[];
}

export interface DuplicatedConnectionContract {
  PartnerId: number;
  PartnerName: string;
  PartnerOrgCode: string;
  ContractId: number;
  PartnerType: string;
  ModelType: string;
  BuyerCode: string;
  BuyerName: string;
  SellerCode: string;
  SellerName: string;
  NumRepeated: number;
}

export interface DuplicatedConnectionPartnerRS extends CheckResponse {
  response?: DuplicatedConnectionPartner[];
}

export interface DuplicatedConnectionPartner {
  PartnerId: number;
  PartnerOrgCode: string;
  ContractId: number;
  PartnerType: string;
  ModelType: string;
  ContractConnectionId: number;
  CreatedAt: string;
  ActivationDateTime: string;
  ConnectionType: string;
  BuyerCode: string;
  BuyerName: string;
  SellerCode: string;
  SellerName: string;
}

export interface CheckSuppliersRS extends CheckResponse {
  response?: CheckSupplier[];
}

export interface CheckSupplier {
  ID: number;
  Code: string;
  IsPush: boolean;
  Type: string;
  SubType: string;
  IsChannelManager: boolean;
  CreatedAt: string;
  UpdatedAt: string;
}

export interface CheckConnectionCostsRS extends CheckResponse {
  response?: CheckConnectionCosts[];
}

export interface CheckConnectionCosts {
  orgCode: string;
  contractId: number;
  partnerType: string;
  partnerSubType: string;
  modelType: string;
  message: string;
}

export interface CheckCM22SupRS extends CheckResponse {
  response?: CheckCM22Sup[];
}

export interface CheckCM22Sup {
  ID: number;
  ActivationDateTime: OptionalDateTime;
  EndDate: OptionalDateTime;
  IsBuy: boolean;
  ConnectionType: string;
  RateType: string;
  OrgCode: string;
  ContractID: number;
  SupplierID: number;
}

export interface OptionalDateTime {
  String: string;
  Valid: boolean;
}

export interface CheckAgreementCM23StdRS extends CheckResponse {
  response?: CheckAgreementCM23Std[];
}

export interface CheckAgreementCM23Std {
  ID: number;
  PartnerID: number;
  PartnerType: string;
  ModelType: string;
  SubType: string;
}

export interface CheckDPConnectionNotSupRS extends CheckResponse {
  response?: CheckDPConnectionNotSup[];
}

export interface CheckDPConnectionNotSup {
  PartnerId: number;
  PartnerOrgCode: string;
  ContractId: number;
  PartnerType: string;
  PartnerSubType: string;
  ModelType: string;
  ContractConnectionId: number;
  ActivationDateTime: Date;
  ConnectionType: string;
  ConnectionOrgCode: string;
  SupplierCode: string;
}

export interface GetAmountsByCurrencyRS extends CheckResponse {
  response?: GetAmountsByCurrency[];
}

export interface GetAmountsByCurrency {
  Currency: string;
  StartDate: string;
  TotalAmount: number;
}

export interface GetAmountsByContractRS extends CheckResponse {
  response?: GetAmountsByContract[];
}

export interface GetAmountsByContract {
  PartnerId: number;
  PartnerOrgCode: string;
  PartnerName: string;
  ContractId: number;
  PartnerType: string;
  ModelType?: OptionalDateTime;
  PartnerSubType: string;
  StartDate: Date;
  TotalAmount: number;
  TotalGMV: number;
}
