import { gql } from 'apollo-angular';

export const getPlugins = gql`
  {
    plugins {
      id
      pluginType
    }
  }
`;
