import { gql } from 'apollo-angular';

export const appendAccessRateRules = gql`
  mutation appendAccessRateRules($where: AccessWhereUniqueInput!, $data: [RateRulesType!]!) {
    admin {
      appendAccessRateRules(where: $where, data: $data) {
        code
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
