import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'tooltip-error',
  templateUrl: './tooltip-error.component.html',
  styleUrls: ['./tooltip-error.component.scss'],
})
export class TooltipErrorComponent implements OnChanges {
  @Input() element: any;
  @Input() field?: any;
  @Input() message?: any;
  @Input() errors: any; // We need to obtain the errors separately from the element,
  // cause if the object is updated ngOnChanges don't trigger if ins't in this mode
  arrayErrors: string[];

  ngOnChanges() {
    this.arrayErrors = [];
    if (this.errors) {
      for (const error in this.errors) {
        if (this.errors.hasOwnProperty(error)) {
          switch (error) {
            case 'minlength':
              this.arrayErrors.push(error + ' = ' + this.errors[error].requiredLength);
              break;
            case 'maxlength':
              this.arrayErrors.push(error + ' = ' + this.errors[error].requiredLength);
              break;
            case 'maxTransaction':
              this.arrayErrors.push(this.message);
              break;
            default:
              this.arrayErrors.push(error);
              break;
          }
        }
      }
    }
  }
}
