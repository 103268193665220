import { NotFoundComponent } from './../../@core/shared/not-found/not-found.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { BillingComponent } from './billing.component';

const routes: Routes = [
  {
    path: '',
    component: BillingComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardBillingModule),
      },
      {
        path: 'reports',
        loadChildren: () => import('./modules/reports/reports.module').then((m) => m.ReportsBillingModule),
      },
      {
        path: 'billing-accounts',
        loadChildren: () =>
          import('./modules/billing-accounts/billing-account.module').then((m) => m.BillingAccountModule),
      },
      {
        path: 'connections',
        loadChildren: () => import('./modules/connections/connection.module').then((m) => m.ConnectionModule),
      },
      {
        path: 'contract',
        loadChildren: () => import('./modules/contracts/contracts.module').then((m) => m.ContractsModule),
      },
      {
        path: 'partners',
        loadChildren: () => import('./modules/partners/partner.module').then((m) => m.PartnerModule),
      },
      {
        path: 'payment-requests',
        loadChildren: () =>
          import('./modules/payment-requests/payment-requests.module').then((m) => m.PaymentRequestModule),
      },

      {
        path: 'costs',
        loadChildren: () => import('./modules/costs/cost.module').then((m) => m.CostModule),
      },
      {
        path: 'invoices',
        loadChildren: () => import('./modules/invoices/invoices.module').then((m) => m.InvoicesModule),
      },
      {
        path: 'agreements',
        loadChildren: () => import('./modules/agreements/agreements.module').then((m) => m.AgreementsModule),
      },
      {
        path: 'invoicing-process',
        loadChildren: () =>
          import('./modules/invoicing-process/invoicing-process.module').then((m) => m.InvoicingProcessModule),
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BillingRoutingModule {}
