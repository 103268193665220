import { gql } from 'apollo-angular';

export const getAllUserProfiles = gql`
  query getAllUserProfiles($where: UserProfileWhereInput) {
    social {
      allUserProfiles(where: $where) {
        edges {
          node {
            code
            userProfileData {
              email
              firstName
              lastName
              partnersIncludedIn {
                department
                jobTitle
                organization {
                  code
                }
              }
              __typename
            }
            adviseMessage {
              code
              description
              level
              external {
                code
                message
              }
            }
          }
        }
        adviseMessage {
          code
          description
        }
      }
    }
  }
`;
