import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RegionNetworkService } from '../../../../services/region-network.services';
import { ToastsService } from '@tgx/shared/toasts';

@Component({
  selector: 'tgx-new-manage-regions-form',
  templateUrl: './new-manage-regions-form.component.html',
  styleUrls: ['./new-manage-regions-form.component.scss'],
})
export class NewManageRegionsFormComponent implements OnInit {
  form: UntypedFormGroup;
  hasValue: boolean = true;

  constructor(
    private fb: UntypedFormBuilder,
    private activeModal: NgbActiveModal,
    private regionNetworkService: RegionNetworkService,
    private toastsService: ToastsService,
  ) {}

  ngOnInit(): void {
    this.buildRegionForm();
  }

  buildRegionForm() {
    this.form = this.fb.group({
      name: [],
    });
  }

  closeModal() {
    this.activeModal.close();
  }

  sendCreateRegion() {
    const formName = this.form.get('name').value;

    if (formName) {
      this.regionNetworkService.createRegion(formName).subscribe({
        next: (resp) => {
          this.activeModal.close(resp);
        },
        error: (er) => {
          this.toastsService.addToast('Error', 'bg-danger', er.error.message);
        },
      });
    } else {
      this.hasValue = false;
    }
  }

  onChangeForm() {
    const value = this.form.get('name').value;
    this.hasValue = !!value;
  }
}
