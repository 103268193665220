import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'tgx-supplier-ToggleActive-modal',
  styleUrls: ['./supplier-ToggleActive-modal.component.scss'],
  templateUrl: './supplier-ToggleActive-modal.component.html',
})
export class SupplierToggleActiveModalComponent {
  @Input() header: string;
  @Input() message: string;
  @Input() btnLabel: string;

  constructor(private activeModal: NgbActiveModal) {}

  onSave() {
    this.activeModal.close(true);
  }
  onCancel() {
    this.activeModal.dismiss();
  }
}
