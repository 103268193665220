<nb-card>
  <nb-card-header> Contracts </nb-card-header>

  <nb-card-body>
    <div *ngIf="isEditor" class="mb-3">
      <p-button label="Create" icon="pi pi-plus" (onClick)="onCreate()"></p-button>
    </div>
    <p-table
      #dt
      [value]="contractsLoadSource"
      [paginator]="false"
      [rows]="contractsLoadSource?.length"
      [showCurrentPageReport]="false"
      styleClass="p-datatable-gridlines p-datatable-striped"
      [tableStyle]="{ 'min-width': '50rem' }"
      [resizableColumns]="true"
      columnResizeMode="expand"
      [loading]="isLoading"
      loadingIcon="pi pi-spin pi-spinner"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let head of header" [pSortableColumn]="head.field">
            {{ head.label }}
            <p-sortIcon *ngIf="head.field !== 'Options'" [field]="head.field"></p-sortIcon>
          </th>
        </tr>
        <tr *ngIf="contractsLoadSource?.length > 1">
          <th *ngFor="let head of header" [style]="head.field === 'Options' ? 'width: 3rem' : ''">
            <p-columnFilter
              [type]="getFilterType(head.field)"
              *ngIf="head.field !== 'Options'"
              [field]="head.field"
              [showMenu]="false"
              [showClearButton]="false"
              (input)="filter($event.target.value, head.field)"
            ></p-columnFilter>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-contract>
        <tr>
          <td>{{ contract.id }}</td>
          <td>{{ contract.currency }}</td>
          <td>
            <p-checkbox
              [ngModel]="contract.bookCheckinNorDate"
              [binary]="true"
              inputId="binary"
              disabled="true"
            ></p-checkbox>
          </td>
          <td>{{ contract.activationDateTime | date: 'dd/MM/yyyy' }}</td>
          <td>{{ contract.endDate | date: 'dd/MM/yyyy' }}</td>
          <td>{{ contract.partnerType }}</td>
          <td>{{ contract.partnerSubType }}</td>
          <td>{{ contract.modelType }}</td>
          <td>{{ contract.rateType }}</td>
          <td>
            <i
              title="View Details"
              class="icon"
              [ngClass]="{ 'nb-search': !isEditor, 'nb-edit': isEditor }"
              (click)="onEdit(contract)"
            ></i>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="10" class="text-center">No contract found.</td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>
