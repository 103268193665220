import { gql } from 'apollo-angular';

export const getContractConnectionDiscounts = gql`
  query GetContractConnectionDiscounts($where: ContractConnectionDiscountWhereInput!) {
    contractConnectionDiscounts(where: $where) {
      id
      startDate
      endDate
      enabled
      amount
      paymentType
      createdAt
      updatedAt
      contractConnection
      ContractConnection {
        Connection {
          buyer
          seller
        }
      }
    }
  }
`;
