<tgx-spinner [class]="'routingLoading'" [isLoading]="isLoading" [top]="'180px'"></tgx-spinner>
<nb-card *ngIf="!isLoading">
  <nb-card-header>Regions</nb-card-header>

  <nb-card-body>
    <div class="mb-3">
      <p-button label="Create" icon="pi pi-plus" (onClick)="onCreate()"></p-button>
    </div>
    <p-table
      [value]="regionsList"
      [tableStyle]="{ 'min-width': '50rem' }"
      styleClass="p-datatable-gridlines p-datatable-striped"
    >
      <ng-template pTemplate="header">
        <tr>
          <th class="col-10">Name</th>
          <th class="col-2">Options</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-region>
        <tr>
          <td class="col-10">{{ region }}</td>
          <td class="col-2">
            <i title="Remove region" class="nb-trash icon" (click)="onDelete(region)"></i>
          </td>
        </tr>
      </ng-template>
    </p-table> </nb-card-body
></nb-card>
