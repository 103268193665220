<div class="modal-header">
  <span>Create Region</span>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="row col-6">
      <label class="mb-1">Name</label>
      <xen-input
        [type]="'string'"
        [validation]="true"
        [disabled]="false"
        [validation_status]="hasValue ? '' : 'is-invalid'"
        [id]="'name'"
        [formControlName]="'name'"
        (keyup)="onChangeForm()"
      />
    </div>
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()">Close</button>
  <button class="btn btn-md btn-primary" (click)="sendCreateRegion()">Create Region</button>
</div>
