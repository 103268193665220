import { Injectable } from '@angular/core';
import {
  BillScheduled,
  BillScheduledCreateInput,
  ObjectWhereUniqueInputById,
} from './../interfaces/_index.billing.interfaces';
import { GraphqlApiBillingQueries } from './queries/_index-billing-queries';
import { GqlService } from '@tgx/shared/data-access/graphql';
import { GraphqlApiBillingMutations } from './mutations/_index-billing-mutations';

@Injectable({
  providedIn: 'root',
})
export class BillScheduledService {
  constructor(private gqlService: GqlService) {}

  async getAllBillScheduled(): Promise<BillScheduled[]> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .queryBilling(GraphqlApiBillingQueries.getAllBillScheduled, {})
        .then((res: any) => {
          if (res?.getAllBillScheduled) {
            resolve(res.getAllBillScheduled);
          } else {
            reject(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getLastBillScheduled(): Promise<BillScheduled> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .queryBilling(GraphqlApiBillingQueries.getLastBillScheduled, {})
        .then((res: any) => {
          if (res?.getAllBillScheduled?.length > 0) {
            resolve(res.getAllBillScheduled[0]);
          } else {
            reject(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async createBillScheduled(data: BillScheduledCreateInput): Promise<BillScheduled> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationBilling(GraphqlApiBillingMutations.createBillScheduled, { data: data })
        .then((res: any) => {
          if (res?.createBillScheduled) {
            resolve(res.createBillScheduled);
          } else {
            reject(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async deleteBillScheduled(where: ObjectWhereUniqueInputById): Promise<BillScheduled> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationBilling(GraphqlApiBillingMutations.deleteBillScheduled, { where: where })
        .then((res: any) => {
          if (res?.deleteBillScheduled) {
            resolve(res.deleteBillScheduled);
          } else {
            reject(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
