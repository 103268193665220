<div class="d-flex align-items-center justify-content-center pagination-wrap mt-2" [ngClass]="{ 'd-none': hasHidden }">
  <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" (pageChange)="onPageChange($event)">
    <ng-template ngbPaginationPages let-page let-pages="pages">
      <li class="ngb-custom-pages-item" *ngIf="pages.length > 0">
        <div class="mb-3 d-flex flex-nowrap px-2">
          <label id="paginationInputLabel" for="paginationInput" class="col-form-label me-2 ms-1">Page</label>
          <div class="form-control" style="cursor: not-allowed !important">{{ page }}</div>
          <span id="paginationDescription" class="col-form-label text-nowrap px-2">of {{ pages.length }}</span>
        </div>
      </li>
    </ng-template>
  </ngb-pagination>
</div>
