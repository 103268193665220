import { HttpBackend, HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PlatformBrowserService } from '@tgx/shared/services';

@Injectable({
  providedIn: 'root',
})
export class LoadCSVService {
  urlBillingCalculation: string;

  private http: HttpClient;

  constructor(
    @Inject('urlBillingCalculation') urlBillingCalculation: string,
    handler: HttpBackend,
    private platformBrowserService: PlatformBrowserService,
  ) {
    this.http = new HttpClient(handler);
    this.urlBillingCalculation = urlBillingCalculation;
  }

  async loadConnections(operationType: string, csv: any): Promise<any> {
    const formData = new FormData();
    formData.append('CSV', csv);

    let url = this.urlBillingCalculation + 'load/';

    if (operationType === 'new') {
      url += 'newConnections';
    } else if (operationType === 'clone') {
      url += 'cloneConnections';
    } else if (operationType === 'migrate') {
      url += 'migrateConnections';
    }

    return await new Promise((resolve, reject) => {
      this.http
        .post(url, formData, {
          headers: {
            Authorization: 'Bearer ' + this.platformBrowserService.getToken(),
          },
        })
        .subscribe(
          (res: any) => {
            if (res?.success) {
              resolve(res.successMessage);
            }
          },
          (err) => {
            reject(err?.error?.errors[0]?.error);
          },
        );
    });
  }
}
