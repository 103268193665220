<div class="xen-summary">
  <xen-tag
    size="{{ size }}"
    label="{{ label }}"
    [icon]="icon"
    type="{{ type }}"
    icon_snippet="{{ icon_snippet }}"
    icon_placement="{{ icon_placement }}"
  ></xen-tag>
  <div class="xen-summary__wrap">
    <ng-content #content></ng-content>
  </div>
</div>
