import { Table } from 'primeng/table';
import { Component, OnInit, Output, ViewChild, EventEmitter, OnDestroy } from '@angular/core';

@Component({
  selector: 'tgx-admin-billing-invoicing-process-pre-third',
  templateUrl: './invoicing-process-pre-third.component.html',
  styleUrls: ['./invoicing-process-pre-third.component.scss'],
})
export class BillingInvoicingProcessPreThirdComponent implements OnInit, OnDestroy {
  @ViewChild('dt', { static: false }) dataTable!: Table;
  @Output() isValidStep: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  source: any[];

  step = 3;

  constructor() {}

  async ngOnInit(): Promise<void> {}

  ngOnDestroy() {}
}
