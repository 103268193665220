export interface Master {
  master_count: number;
  master_list: MasterList[];
}

export interface MasterList {
  name: string;
  address: string;
  country: string;
  location: string;
  id: string;
  show_child?: boolean;
}

export interface Hotel {
  hotel_count: number;
  hotel_list: HotelList[];
}

export interface HotelList {
  supplier_code: string;
  hotel_code: string;
  name: string;
  address: string;
  location: string;
  tg_property_type: string;
  country: string;
  hotel_id?: string;
}

export interface MasterSons extends MasterList {
  hotels: HotelList[];
}

export interface GenericMappingResponse {
  id: number;
  created_at: string;
  action_type: string;
  user_id: string;
  hotel_id: string;
}

export interface Country {
  name: string;
  isoCode2: string;
}

export interface HotelId {
  supplier_code: string;
  hotel_code: string;
}
