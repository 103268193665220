<div class="modal-header">
  <span>{{ header }}</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="accessesGroupsForm">
    <nb-card>
      <nb-card-header> Add new group to access {{ access.name }} </nb-card-header>
      <nb-card-body>
        <div class="row"></div>
        <div class="row mt-2">
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger':
                  accessesGroupsForm.get('orgCode')?.errors && accessesGroupsForm.get('orgCode').touched,
              }"
            >
              <label class="mb-1" for="label">Organizations</label>
              <tooltip-error
                [element]="accessesGroupsForm.get('orgCode')"
                [errors]="accessesGroupsForm.get('orgCode').errors"
              >
              </tooltip-error>
              <p-dropdown
                [options]="organizations"
                [(ngModel)]="selectedOrg"
                [virtualScroll]="true"
                itemSize="30"
                [showClear]="true"
                [filter]="true"
                optionsValue="orgCode.code"
                filterby="orgCode.label"
                appendTo="body"
                baseZIndex="999"
                placeholder="Select an Organization"
                (onChange)="loadOrganizationsGroupsEvent($event)"
                formControlName="orgCode"
              >
              </p-dropdown>
            </div>
          </div>
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger':
                  accessesGroupsForm.get('groupCode')?.errors && accessesGroupsForm.get('groupCode').touched,
              }"
            >
              <label class="mb-1" for="label">Groups</label>
              <tooltip-error
                [element]="accessesGroupsForm.get('groupCode')"
                [errors]="accessesGroupsForm.get('groupCode').errors"
              >
              </tooltip-error>

              <p-dropdown
                #groups_dd
                [options]="groups"
                [virtualScroll]="true"
                itemSize="30"
                [showClear]="true"
                [filter]="true"
                optionsValue="groups.value"
                filterby="groups.label"
                appendTo="body"
                baseZIndex="99999"
                placeholder="Empty groups"
                formControlName="groupCode"
                (onChange)="enableAddButtonChanged()"
              >
              </p-dropdown>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-12">
            <div class="form-group">
              <button class="btn btn-md btn-primary" (click)="onSave()" [disabled]="!this.enableAddButton">Add</button>
            </div>
          </div>
        </div>
      </nb-card-body>
    </nb-card>

    <nb-card>
      <nb-card-header> Assigned groups to access: {{ access.name }} </nb-card-header>
      <nb-card-body>
        <div class="row mt-2">
          <div class="col-12">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger':
                  accessesGroupsForm.get('assignedGroup')?.errors && accessesGroupsForm.get('assignedGroup').touched,
              }"
            >
              <div class="d-inline-block w-100">
                <label class="mb-1" for="assignedGroupsControl">Groups assigned</label>
                <p-multiSelect
                  [options]="currentAssignedGroups"
                  formControlName="assignedGroupsControl"
                  display="chip"
                  optionLabel="label"
                  [appendTo]="'body'"
                  baseZIndex="999"
                  selectedItemsLabel="{0} Group/s selected"
                  (onChange)="assignedGroupSelectionChanged()"
                >
                </p-multiSelect>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <div class="form-group">
              <button
                class="btn btn-md btn-danger"
                (click)="checkRevokeOrganizations()"
                [disabled]="!this.enableRevokeButton"
              >
                Revoke
              </button>
            </div>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()">Close</button>
</div>
