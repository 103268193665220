import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { GraphqlQueries } from './queries/_index.queries';
import { GqlService } from '@tgx/shared/data-access/graphql';
import { OrganizationSelector } from '../../../@core/shared/interfaces/_index-shared-interfaces';

@Injectable({
  providedIn: 'root',
})
export class OrganizationNetworkService {
  onToggleArchived$: Subject<any> = new Subject();

  filteredOrgList: OrganizationSelector[];
  filteredPrfList: OrganizationSelector[];
  orgCodes = new BehaviorSubject<string[]>(null);

  constructor(private gqlService: GqlService) {}

  getOrganizationsWidget(): Promise<{ data: Map<string, string>; error?: any }> {
    const mapOrgs = new Map<string, string>();

    return this.gqlService
      .queryGateway(GraphqlQueries.getWidgetOrgs)
      .then((res: any) => {
        const edges = res?.admin?.allOrganizations?.edges;
        if (edges?.length > 0) {
          edges.forEach((elem: any) => {
            const orgData = elem?.node?.organizationsData;
            if (orgData) mapOrgs.set(orgData.code, orgData.label);
          });
          return { data: mapOrgs };
        } else {
          return { data: null, error: 'The admin response is empty' };
        }
      })
      .catch((err) => {
        return { data: null, error: err };
      });
  }

  filteredOrgs(localMapOrg, localMapPrf, widget) {
    const arrayKeyOrgs: any = Array.from(localMapOrg.keys());
    const arrayKeyProfiles: any = Array.from(localMapPrf.values());

    if (widget === 'orgs') {
      this.filteredOrgList = arrayKeyOrgs.filter(function (n) {
        return arrayKeyProfiles.indexOf(n) === -1;
      });
      return this.filteredOrgList;
    }
    if (widget === 'prfs') {
      this.filteredPrfList = arrayKeyProfiles.filter(function (n) {
        return arrayKeyOrgs.indexOf(n) === -1;
      });
      return this.filteredPrfList;
    }
  }

  setOrganizationsCodes(orgCodes) {
    this.orgCodes.next(orgCodes);
  }
}
