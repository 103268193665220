import { gql } from 'apollo-angular';

export const getGatewayClientSuppliers = gql`
  query {
    admin {
      allClients(where: { includeArchived: true }) {
        edges {
          node {
            clientData {
              code
              name
              owner {
                code
              }
            }
          }
        }
      }
      allSuppliers(where: { includeArchived: true }) {
        edges {
          node {
            supplierData {
              code
              name
              owner {
                code
              }
            }
          }
        }
      }
    }
  }
`;
