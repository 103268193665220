<div class="xen-pagination" [ngClass]="{ 'd-none': hasHidden }">
  <ngb-pagination
    [pageSize]="pageSize"
    [collectionSize]="collectionSize"
    [page]="page"
    [maxSize]="maxSize"
    [rotate]="true"
    (pageChange)="onPageChange($event)"
    aria-label="Default pagination"
    [disabled]="isDisabled"
  >
  </ngb-pagination>
</div>
