import { CommonModule } from '@angular/common';
import { SharedModule } from './../../../../@core/shared/shared.module';
import { NgModule } from '@angular/core';
import { NbCardModule, NbCheckboxModule } from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InvoicesRoutingModule } from './invoices.routing-module';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { InvoicesComponent } from './invoices.component';
import { BillingInvoicesTableComponent } from './list/components/invoices-table/invoices-table.component';
import { BillingInvoiceBreakdownsTableComponent } from './list/components/invoice-breakdown-table/invoice-breakdowns-table.component';
import { BillingInvoiceCreateComponent } from './create/components/invoice-create/invoice-create.component';
import { BillingInvoiceLegacyConciliateComponent } from './conciliate/components/invoice-legacy-conciliate/invoice-legacy-conciliate.component';
import { BillingInvoiceNewPricingConciliateComponent } from './new-pricing-conciliate/components/invoice-newpricing-conciliate/invoice-newpricing-conciliate.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';

@NgModule({
  imports: [
    TableModule,
    ButtonModule,
    InputTextModule,
    AutoCompleteModule,
    SharedModule,
    InvoicesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    NbCardModule,
    CommonModule,
    NbCheckboxModule,
    DropdownModule,
    CalendarModule,
    MultiSelectModule,
    CheckboxModule,
  ],
  declarations: [
    InvoicesComponent,
    BillingInvoicesTableComponent,
    BillingInvoiceBreakdownsTableComponent,
    BillingInvoiceCreateComponent,
    BillingInvoiceLegacyConciliateComponent,
    BillingInvoiceNewPricingConciliateComponent,
  ],
})
export class InvoicesModule {}
