<div class="modal-header">
  <span>{{ header }}</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <!-- Modal content -->
  <div class="modal-safeDeactivate">
    <p>The access have traffic, are you sure?</p>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-primary" (click)="onSave()">Confirm deactivate</button>
  <button class="btn btn-md btn-danger" (click)="closeModal()">Close</button>
</div>
