<nb-card>
  <nb-card-header> Inconclusive connections (Select one row to view its configuration) </nb-card-header>

  <nb-card-body>
    <p-table
      #dt
      [value]="inconclusiveSource"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      styleClass="p-datatable-gridlines p-datatable-striped"
      [tableStyle]="{ 'min-width': '50rem' }"
      editMode="row"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
      selectionMode="single"
      [(selection)]="selectedPair"
      dataKey="id"
      [loading]="isLoading"
      loadingIcon="pi pi-spin pi-spinner"
      [resizableColumns]="true"
      columnResizeMode="expand"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let head of header" [pSortableColumn]="head.field">
            {{ head.label }}
            <p-sortIcon [field]="head.field"></p-sortIcon>
          </th>
        </tr>
        <tr>
          <th *ngFor="let head of header" [style]="'width: 3rem'">
            <p-columnFilter
              *ngIf="isTextFilter(head)"
              type="text"
              [field]="head.field"
              [showMenu]="false"
              [showClearButton]="false"
              (input)="filter($event.target.value, head.field)"
            ></p-columnFilter>

            <p-columnFilter
              [field]="head.field"
              matchMode="contains"
              [showMenu]="false"
              *ngIf="head.field == 'buyerContractType'"
            >
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-dropdown
                  [ngModel]="value"
                  [options]="buyerTypes"
                  (onChange)="filterDropdown($event.value, head)"
                  placeholder="Any"
                  [showMenu]="false"
                  [showClearButton]="false"
                  [appendTo]="'body'"
                  baseZIndex="999"
                  [filter]="true"
                >
                  <ng-template let-option pTemplate="item">
                    <span>{{ option.label }}</span>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>

            <p-columnFilter
              [field]="head.field"
              matchMode="contains"
              [showMenu]="false"
              *ngIf="head.field == 'buyerContractSubType'"
            >
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-dropdown
                  [ngModel]="value"
                  [options]="buyerSubTypes"
                  (onChange)="filterDropdown($event.value, head)"
                  placeholder="Any"
                  [showMenu]="false"
                  [showClearButton]="false"
                  [appendTo]="'body'"
                  baseZIndex="999"
                  [filter]="true"
                >
                  <ng-template let-option pTemplate="item">
                    <span>{{ option.label }}</span>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>

            <p-columnFilter
              [field]="head.field"
              matchMode="contains"
              [showMenu]="false"
              *ngIf="head.field == 'buyerConnectionType'"
            >
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-dropdown
                  [ngModel]="value"
                  [options]="buyerRates"
                  (onChange)="filterDropdown($event.value, head)"
                  placeholder="Any"
                  [showMenu]="false"
                  [showClearButton]="false"
                  [appendTo]="'body'"
                  baseZIndex="999"
                  [filter]="true"
                >
                  <ng-template let-option pTemplate="item">
                    <span>{{ option.label }}</span>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>

            <p-columnFilter
              [field]="head.field"
              matchMode="contains"
              [showMenu]="false"
              *ngIf="head.field == 'sellerConnectionType'"
            >
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-dropdown
                  [ngModel]="value"
                  [options]="sellerRates"
                  (onChange)="filterDropdown($event.value, head)"
                  placeholder="Any"
                  [showMenu]="false"
                  [showClearButton]="false"
                  [appendTo]="'body'"
                  baseZIndex="999"
                  [filter]="true"
                >
                  <ng-template let-option pTemplate="item">
                    <span>{{ option.label }}</span>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>

            <p-columnFilter
              [field]="head.field"
              matchMode="contains"
              [showMenu]="false"
              *ngIf="head.field == 'sellerContractSubType'"
            >
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-dropdown
                  [ngModel]="value"
                  [options]="sellerSubTypes"
                  (onChange)="filterDropdown($event.value, head)"
                  placeholder="Any"
                  [showMenu]="false"
                  [showClearButton]="false"
                  [appendTo]="'body'"
                  baseZIndex="999"
                  [filter]="true"
                >
                  <ng-template let-option pTemplate="item">
                    <span>{{ option.label }}</span>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>

            <p-columnFilter
              [field]="head.field"
              matchMode="contains"
              [showMenu]="false"
              *ngIf="head.field == 'sellerContractType'"
            >
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-dropdown
                  [ngModel]="value"
                  [options]="sellerTypes"
                  (onChange)="filterDropdown($event.value, head)"
                  placeholder="Any"
                  [showMenu]="false"
                  [showClearButton]="false"
                  [appendTo]="'body'"
                  baseZIndex="999"
                  [filter]="true"
                >
                  <ng-template let-option pTemplate="item">
                    <span>{{ option.label }}</span>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-connection let-editing="editing">
        <tr [pSelectableRow]="connection" [pEditableRow]="connection">
          <td>{{ connection.buyer }}</td>
          <td>{{ connection.buyerContractType }}</td>
          <td>{{ connection.buyerContractSubType }}</td>
          <td>{{ connection.buyerConnectionType }}</td>
          <td>{{ connection.sellerConnectionType }}</td>
          <td>{{ connection.sellerContractSubType }}</td>
          <td>{{ connection.sellerContractType }}</td>
          <td>{{ connection.seller }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="10" class="text-center">No connections found.</td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>

<tgx-admin-billing-inconclusive-breakdowns-table
  [isEditor]="isEditor"
  *ngIf="selectedPair"
  [selectedPair]="selectedPair"
  [allConnections]="activeConnections"
  (newAction)="ngOnInit()"
></tgx-admin-billing-inconclusive-breakdowns-table>
