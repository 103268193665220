import { Component, forwardRef, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'xen-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectComponent),
    },
  ],
})
export class SelectComponent implements OnInit, OnChanges {
  form: FormGroup;

  @Input() label: string;
  @Input() size: 'small' | 'medium' = 'medium';
  @Input() id: string;
  @Input() formControlName: string;
  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() selected: boolean;
  @Input() tooltip: boolean;
  @Input() tooltip_text: string;
  @Input() tooltip_placement: string;
  @Input() validation: boolean;
  @Input() validation_status: string;
  @Input() feedback: string;
  @Input() disabled: boolean;
  @Input() options = [];
  @Input() selectedObject: string;

  //NG-VALUE-ACCESSOR variables
  value: string;
  isDisabled = false;
  tooltipText: string;
  onChange: () => {};
  onTouched: () => {};

  constructor(private rootFormGroup: FormGroupDirective) {}

  public get labelClasses(): Array<string> {
    return [this.required ? 'is-required' : ''];
  }
  public get selectClasses(): Array<string> {
    return [
      this.validation && this.validation_status ? `${this.validation_status}` : '',
      `xen-select__select--${this.size}`,
    ];
  }
  public get feedbackClasses(): Array<string> {
    return [
      this.validation && this.validation_status === 'is-valid' ? 'valid-feedback' : '',
      this.validation && this.validation_status === 'is-invalid' ? 'invalid-feedback' : '',
    ];
  }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control;

    if (this.disabled) {
      this.form.get(this.formControlName).disable();
    }
  }

  ngOnChanges() {
    this.form = this.rootFormGroup.control;
    if (this.disabled && this.form) {
      this.form.get(this.formControlName).disable();
    } else if (this.form) {
      this.form.get(this.formControlName).enable();
    }
  }

  writeValue(value) {
    this.value = value;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled) {
    this.isDisabled = isDisabled;
  }
}
