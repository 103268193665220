import { gql } from 'apollo-angular';

export const getGroupsByAccessCode = gql`
  query ($where: AccessWhereInput) {
    admin {
      allAccesses(where: $where) {
        totalCount
        edges {
          node {
            accessData {
              groups {
                edges {
                  node {
                    code
                  }
                }
              }
            }
          }
        }
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
