<nb-card>
  <nb-card-header> Commercial Templates </nb-card-header>

  <nb-card-body>
    <div *ngIf="isEditor" class="mb-3">
      <p-button label="Create" icon="pi pi-plus" (onClick)="onCreate()"></p-button>
    </div>
    <p-table
      #dt
      [value]="templatesLoadSource"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      styleClass="p-datatable-gridlines p-datatable-striped"
      [tableStyle]="{ 'min-width': '50rem' }"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
      [loading]="isLoading"
      loadingIcon="pi pi-spin pi-spinner"
      [resizableColumns]="true"
      columnResizeMode="expand"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let head of header" [pSortableColumn]="head.field">
            {{ head.label }}
            <p-sortIcon
              *ngIf="head.field !== 'Options' && head.field !== 'CreatedAt'"
              [field]="head.field"
            ></p-sortIcon>
          </th>
        </tr>
        <tr>
          <th *ngFor="let head of header" [style]="head.field === 'Options' ? 'width: 3rem' : ''">
            <p-columnFilter
              type="text"
              *ngIf="
                head.field !== 'Options' &&
                head.field !== 'CreatedAt' &&
                head.field !== 'Type' &&
                head.field !== 'ContractTypeName'
              "
              [field]="head.field"
              [showClearButton]="false"
              [showMenu]="false"
              (input)="filter($event.target.value, head.field)"
            ></p-columnFilter>
            <p-columnFilter [field]="head.field" matchMode="equals" [showMenu]="false" *ngIf="head.field === 'Type'">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-dropdown
                  [ngModel]="value"
                  [options]="modelTypes"
                  (onChange)="filter($event.value)"
                  placeholder="Any"
                  [showMenu]="false"
                  [showClearButton]="false"
                  [appendTo]="'body'"
                  baseZIndex="999"
                >
                  <ng-template let-option pTemplate="item">
                    <span>{{ option.label }}</span>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>
            <p-columnFilter
              [field]="head.field"
              matchMode="equals"
              [showMenu]="false"
              *ngIf="head.field === 'ContractTypeName'"
            >
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-dropdown
                  [ngModel]="value"
                  [options]="subTypes"
                  (onChange)="filter($event.value)"
                  placeholder="Any"
                  [showMenu]="false"
                  [showClearButton]="false"
                  [appendTo]="'body'"
                  baseZIndex="999"
                >
                  <ng-template let-option pTemplate="item">
                    <span>{{ option.label }}</span>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-agreement>
        <tr>
          <td>{{ agreement.ID }}</td>
          <td>{{ agreement.Type }}</td>
          <td>{{ agreement.ContractTypeName }}</td>
          <td>{{ agreement.Name }}</td>
          <td>{{ agreement.Version }}</td>
          <td>{{ agreement.CreatedAt | date: 'dd-MM-yyyy' }}</td>
          <td>
            <div class="d-flex align-items-center justify-content-center">
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-download"
                (click)="onDownload(agreement)"
                class="p-button-rounded p-button-text"
              ></button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="10" class="text-center">No agreement found.</td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>
