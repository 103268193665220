import { gql } from 'apollo-angular';

export const getOperationsAndErrors = gql`
  query getOperationsAndErrorsByApiServices($codes: [ID!]) {
    admin {
      apis: allAPIs(where: { code_in: $codes }) {
        edges {
          node {
            apiData {
              adviseMessageCatalog: errorCatalog {
                code
                description
                type
              }
              operations {
                edges {
                  node {
                    operationData {
                      code
                      label
                      types
                    }
                  }
                }
              }
            }
            adviseMessage {
              code
              description
              level
            }
          }
        }
      }
    }
  }
`;
