import { gql } from 'apollo-angular';

export const getInvoices = gql`
  query GetInvoices($where: InvoiceWhereInput!) {
    invoices(where: $where) {
      id
      amount
      currency
      createdAt
      startDate
      endDate
      visible
    }
  }
`;
