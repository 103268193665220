import { NotificationService, WebAdminService } from './../../../../../../@core/services/_index-core.services';
import { Partner } from './../../../../interfaces/_index.billing.interfaces';
import { PartnerService } from './../../../../services/_index-billing.services';
import { Table } from 'primeng/table';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnersModalComponent } from '../partners-modal/partners-modal.component';

@Component({
  selector: 'tgx-partners-table',
  templateUrl: './partners-table.component.html',
  styleUrls: ['./partners-table.component.scss'],
})
export class PartnerTableComponent implements OnInit, OnDestroy {
  @ViewChild('dt', { static: false }) dataTable!: Table;

  $subs: Subscription[] = [];

  partners: Partner[];
  partnersLoadSource: any[];
  isLoading: boolean;
  isEditor = false;

  constructor(
    private notificationService: NotificationService,
    private partnerService: PartnerService,
    private webAdminService: WebAdminService,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.isLoading = false;
    this.buildOptions();
    this.getPartners();
  }

  ngOnDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
  }

  buildOptions() {
    this.isEditor = this.webAdminService.isBillingEditorOrAdmin();
  }

  getPartners() {
    this.isLoading = true;

    this.partnerService
      .getAllPartners()
      .then((prts) => {
        this.partners = prts;
        this.buildPartners();
        this.isLoading = false;
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
        this.isLoading = false;
      });
  }

  buildPartners() {
    this.partnersLoadSource = [];

    if (this.partners?.length > 0) {
      this.partners.forEach((p) => {
        this.partnersLoadSource.push({
          id: p.id,
          name: p.name,
          orgCode: p.orgCode,
          odooCode: p.odooCode,
          createdAt: p.createdAt,
          updatedAt: p.updatedAt,
        });
      });
      this.partnersLoadSource = [...this.partnersLoadSource];

      this.partnersLoadSource.sort((a, b) => (a.id < b.id ? 1 : -1));
    }
  }

  async onCreate(): Promise<void> {
    const activeModal = this.modalService.open(PartnersModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = 'Create Partner';
    activeModal.componentInstance.isEditor = this.isEditor;

    const self = this;
    activeModal.result.then((res) => {
      // OK
      if (typeof res === 'object') {
        self.ngOnInit();
      }
    });
  }

  onEdit(partner): void {
    const activeModal = this.modalService.open(PartnersModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = (this.isEditor ? 'Edit ' : 'View ') + 'Partner';
    activeModal.componentInstance.partner = partner;
    activeModal.componentInstance.isEditor = this.isEditor;

    const self = this;
    activeModal.result.then((res) => {
      // OK
      if (typeof res === 'object') {
        self.ngOnInit();
      }
    });
  }

  filter(value: any, field: string) {
    if (value) {
      this.dataTable.filter(value, field, 'contains');
    }
  }
}
