import { gql } from 'apollo-angular';

export const allProfilesBasic = gql`
  query allProfilesCacheBasic($where: ProfileWhereInput) {
    social {
      allProfilesV2(where: $where) {
        adviseMessage {
          code
          description
          level
        }
        edges {
          node {
            code
            createdAt
            updatedAt
            profileData {
              name
              owner {
                code
              }
              slug
              crmId
              buyerCategory
              sellerCategory
              buyerIntegrationType
              sellerIntegrationType
            }
          }
        }
      }
    }
  }
`;

//Add in case to be used

// assets {
//   name
//   createdAt
//   updatedAt
// }

//classifications

// isDemandBridge
// isSupplyBridge

// group {
//   code
// }
