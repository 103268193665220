import { Injectable } from '@angular/core';
import * as introJs from 'intro.js/intro.js';

@Injectable({
  providedIn: 'root',
})
export class IntrojsService {
  introJS = introJs();

  featureOne() {
    this.introJS.start();

    this.introJS
      .setOptions({
        steps: [
          {
            element: '#navbar-dashboard',
            intro: 'Your connections performance stats at a glance',
          },
          {
            element: '#navbar-network',
            intro: 'Search for next Partner you want to connect to',
          },
          {
            element: '#navbar-connections',
            intro: 'View, activate and monitor your connections',
          },
          {
            element: '#navbar-apps',
            intro: "Log manager, stats dashboard, smart traffic apps, you'll find them all here!",
          },
          {
            element: '#navbar-tickets',
            intro: 'Questions? Bug? Error? Reach out to our Customer Care team here',
          },
          {
            element: '#step4',
            intro: "You'll find Billing, Member Management and other settings right here.'",
          },
          {
            element: '#step5',
            intro: 'Discover a world of resources at your fingertips',
          },
        ],
      })
      .start();
  }

  featureLogging() {
    this.introJS
      .setOptions({
        disableInteraction: true,
        steps: [
          {
            intro:
              'Welcome to Logging! 👋⏬ </br></br> Retrieve and download logs from your transactions in our marketplace.',
          },
          {
            element: '#step-filters',
            intro:
              'First of all, select the connection/s, time range and other filters you want to use to retrieve logs.',
          },
          {
            element: '#step-session',
            intro: 'You can also search logs using a specific booking locator or reference.',
            position: 'left',
          },
          {
            element: '#step-audit',
            intro: 'If you want to download search logs you will need to audit them first.',
            position: 'left',
          },
        ],
      })
      .start();
  }

  featureLoggingTable() {
    this.introJS
      .setOptions({
        disableInteraction: true,
        steps: [
          {
            element: '#step-table',
            intro:
              'Yay! 🎊 You have found your first logs! </br></br> In this table you can check all its detailed information.',
            position: 'top',
          },
          {
            element: '#step-filter-table',
            intro: 'You can also filter your results to find specific logs.',
          },
          {
            element: '#step-download',
            intro: 'Click here to download a specific transaction log.',
            position: 'left',
          },
          {
            element: '#step-download-all',
            intro: 'Or use this option if you want to download a batch of logs.',
            position: 'left',
          },
        ],
      })
      .start();
  }

  featureStats() {
    this.introJS
      .setOptions({
        steps: [
          {
            intro:
              'Welcome to Stats! 👋📊 </br></br> Monitor and evaluate the performance of each of your connections on a minute-by-minute basis.',
            disableInteraction: true,
          },
          {
            element: '#step-filters',
            intro: 'First of all, select the time range and traffic type you want to monitor.',
            disableInteraction: true,
          },
          {
            element: '#step-tabs',
            intro: 'Select a specific shopping flow step if you want to know more details.',
            tooltipPosition: 'bottom',
            disableInteraction: true,
          },
          {
            element: '#step-overview',
            intro: 'You can check your organization’s overview stats.',
            disableInteraction: true,
          },
          {
            element: '#step-table',
            scrollToElement: true,
            scrollTo: 'tooltip',
            intro: 'Or get specific information for each of your connections.',
            disableInteraction: true,
          },
          {
            element: document.querySelector('#step-view'),
            intro: 'Additionally, you can check your stats by a specific access (credential).',
            position: 'left',
            disableInteraction: true,
          },
        ],
      })
      .start();
  }

  featureAlerts() {
    this.introJS
      .setOptions({
        disableInteraction: true,
        steps: [
          {
            intro:
              'Welcome to Alerts! 👋🚨 </br></br> Detect potential anomalies in real-time to react on time and avoid impact on your business.',
          },
          {
            element: '#step-create',
            intro:
              'Click on this button to create your first alert. Here you will be able to configurate the alert type and the notification set up.',
            position: 'left',
          },
          {
            element: '#step-table',
            intro: 'Once you have alerts created, you will be able to manage them from this same dashboard.',
          },
        ],
      })
      .start();
  }

  featureAlertsConnection() {
    this.introJS
      .setOptions({
        disableInteraction: true,
        steps: [
          {
            element: '#step-connections',
            intro: 'First, select one or more connections that you want to monitor with Alerts.',
          },
        ],
      })
      .start();
  }

  featureAlertsConfiguration() {
    this.introJS
      .setOptions({
        disableInteraction: true,
        steps: [
          {
            element: '#step-type',
            intro: 'Great!🎊 </br></br>Now select which type of alert do you want to configure.🔧',
          },
          {
            element: '#step-configuration',
            intro:
              'Then select the alert configuration that suits better for you and fill the parameters which your own values.',
          },
          {
            element: '#step-traffic',
            intro: 'Optionally, you can choose a specific kind of traffic for the alert to review.',
          },
        ],
      })
      .start();
  }

  featureAlertsNotification() {
    this.introJS
      .setOptions({
        disableInteraction: true,
        steps: [
          {
            element: '#step-name',
            intro:
              'You are almost finished! </br></br> Name the alert and add a description that will make it easy for you to identify it.',
          },
          {
            element: '#step-email',
            intro: 'Indicate one or more emails to notify if the alert is active.',
          },
          {
            element: '#step-notification',
            intro: 'Choose the frequency notification you prefer.',
          },
          {
            element: '#step-alert-configuration',
            intro:
              'Finally, in this section you can configure the alert to alert globally, a different alert for each connection or a different alert for each access.',
          },
        ],
      })
      .start();
  }

  featureTrafficOptimization() {
    this.introJS
      .setOptions({
        disableInteraction: true,
        steps: [
          {
            intro:
              'Welcome to Traffic Optimizer! 👋🚦 ' +
              '<br><br> Optimize your product and reduce unnecessary search traffic automatically. ' +
              'Our APP detects, based on different criteria, which hotels are not converting into a booking based on the data of your connections compiled in our marketplace.',
          },
          {
            element: '.step-activate',
            intro:
              'You can optimize a full connection or a particular access (credential)! Click on this button to activate Traffic Optimizer with the blocking criterias that you prefer.',
          },
          {
            element: '#step-table',
            intro:
              'Once you have connections or acceses using Traffic Optimizer, you will be able to check their performance and edit their configuration from this same dashboard.',
          },
        ],
      })
      .start();
  }

  featureSpeedTable() {
    this.introJS
      .setOptions({
        disableInteraction: true,
        steps: [
          {
            intro: 'Welcome to Speed! 👋🚀 </br></br> Improve your search response time and reduce system costs.',
          },
          {
            element: '#step-button',
            intro:
              'Click on this button to activate your first connection. Here you will be able to configurate the Speed cache mode and TTL.',
          },
          {
            element: '#step-table',
            intro:
              'Yay! 🎊 You have connections using Speed! </br></br> In this table you can quickly activate/deactivate cache and check its basic information.',
          },
          {
            position: 'left',
            element: '#step-detail',
            intro: 'Click here to get more details about the cache performance and edit its configuration.',
          },
        ],
      })
      .start();
  }

  featureTrafficOptimizationTable() {
    this.introJS
      .setOptions({
        disableInteraction: true,
        steps: [
          {
            intro:
              'Welcome to Traffic Optimizer! 👋🚦 ' +
              '<br><br> Optimize your product and reduce unnecessary search traffic automatically. ' +
              'Our APP detects, based on different criteria, which hotels are not converting into a booking based on the data of your connections compiled in our marketplace.',
          },
          {
            element: '.step-activate',
            intro:
              'You can optimize a full connection or a particular access (credential)! Click on this button to activate Traffic Optimizer with the blocking criterias that you prefer.',
          },
          {
            element: '#step-table',
            intro:
              'Yay! 🎊 You have connections using Traffic Optimizer!<br><br> In this table you can quickly activate/deactivate the APP and edit its configuration.',
            position: 'bottom',
          },
          {
            element: '.step-switch',
            intro: 'With this button you can activate and deactivate all blocking criterias at the same time.',
            position: 'left',
          },
          {
            element: '#step-actions',
            intro:
              'Click here to add or remove a specific criteria or to check which hotels are being automatically blocked with the APP.',
            position: 'left',
          },
        ],
      })
      .start();
  }

  featureTrafficOptimizationBlockedHotels() {
    this.introJS
      .setOptions({
        disableInteraction: true,
        steps: [
          {
            element: '#step-hotels-table',
            intro:
              'In this section you are able to check which hotels are being blocked in your connections and accesses and for which criteria. 100% transparent.',
          },
          {
            element: '#step-filter-hotels',
            intro: 'Search for a specific hotel using its code or name.',
          },
          {
            element: '#step-filter-criteria',
            intro: 'And also you can filter by a specific criteria',
          },
        ],
      })
      .start();
  }

  featureTrafficOptimizationCharts() {
    this.introJS
      .setOptions({
        disableInteraction: true,
        steps: [
          {
            intro:
              ' In this section you are able to check how the Traffic Optimizer APP is performing in the search traffic!',
          },
          {
            element: '#step-chart-pie',
            intro: 'This chart gives you a global view of how much search traffic has been saved in the last 24 hours.',
          },
          {
            element: '#step-chart-bar',
            intro:
              'And this gives you a detailed view of the search traffic optimized for each hour of the last 24 hours.',
          },
        ],
      })
      .start();
  }
}
