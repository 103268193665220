import { gql } from 'apollo-angular';

export const setIsPublicSupplier = gql`
  mutation ($where: SupplierWhereUniqueInput!, $isPublic: Boolean!) {
    admin {
      setIsPublicSupplier(where: $where, isPublic: $isPublic) {
        code
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
