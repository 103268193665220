import { Component, Input } from '@angular/core';

@Component({
  selector: 'xen-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  host: { class: 'toast-container position-relative', style: 'z-index: 1200' },
})
export class ToastComponent {
  @Input() type: 'default' | 'info' | 'success' | 'warning' | 'error' | 'dark' = 'default';
  @Input() header: string;
  as;
  @Input() content: string;
  @Input() delay: number;
  @Input() show: boolean;
  @Input() autohide: boolean;
  @Input() showToast: boolean;

  constructor() {}

  public get classes(): Array<string> {
    return ['xen-toast', `xen-toast--${this.type}`];
  }

  ngOnInit(): void {}
}
