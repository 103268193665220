<tgx-spinner [class]="'routingLoading'" [isLoading]="isLoading" [top]="'180px'"></tgx-spinner>
<div *ngIf="!isLoading">
  <form [formGroup]="form">
    <nb-card>
      <nb-card-header>
        Set your banner message
        <span class="text-secondary"
          ><i> (The message will take a minute to appear on the web)</i></span
        ></nb-card-header
      >
      <nb-card-body>
        <div class="row mb-12">
          <div class="col-12">
            <div class="form-group">
              <div class="d-inline-block w-100">
                <textarea
                  type="text"
                  class="form-control"
                  formControlName="bannerMessage"
                  placeholder="Write here your message"
                  (input)="getInput()"
                ></textarea>
                <div class="py-1 d-flex align-items-center">
                  <button class="btn btn-sm btn-secondary m-2" [disabled]="disableHtmlButtons" (click)="addLink()">
                    Link*
                  </button>
                  <button class="btn btn-sm btn-secondary m-2" [disabled]="disableHtmlButtons" (click)="addBold()">
                    <b>Bold*</b>
                  </button>
                  <button class="btn btn-sm btn-secondary m-2" [disabled]="disableHtmlButtons" (click)="clearText()">
                    <b>Clear</b>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex w-100 justify-content-between">
          <div class="row py-3 d-flex align-content-start">
            <label class="mb-1"><b>Select one option:</b></label>
            <div class="col-12" *ngFor="let message of messageRadio">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  [id]="message.id"
                  name="showMessage"
                  (click)="getSelected(message.value)"
                  [checked]="message.checked"
                />
                <label class="form-check-label" [ngbTooltip]="message.tooltip">
                  {{ message.name }}
                </label>
              </div>
            </div>
          </div>
          <div class="row py-3">
            <label class="mb-1"><b>Select type of message:</b></label>
            <div class="line-separator"></div>
            <div class="col-12" *ngFor="let alert of alertTypeRadio">
              <div class="form-check">
                <input
                  [disabled]="disabledType"
                  class="form-check-input"
                  type="radio"
                  [id]="alert.id"
                  name="typeAlert"
                  (click)="getAlertType(alert.value)"
                  [checked]="alert.checked"
                />
                <label class="form-check-label" [ngbTooltip]="alert.tooltip">
                  {{ alert.name }}
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-md btn-primary" [disabled]="disabledSendButton" (click)="sendMessage()">Send</button>
          </div>
        </div>
        <div class="col-12">
          <div class="text-secondary py-3">
            <b>* Link:</b>
            <span>
              The HTML tag &lt;a target="_blank" href="URL"&gt; text &lt;/a&gt; is used to create links to other web
              pages or online resources. <br />
              <i><b>Usage:</b></i> To create a link, use the &lt;a&gt; &lt;/a &gt; tag with the <b>href</b> attribute,
              which specifies the <b>URL</b> you want to link to. For example: &lt;a href="https://app.travelgate.com/"
              target="_blank"&gt;Link to the website&lt;/a&gt;.
              <br />
              <i><b>target="_blank"</b> is used to open the URL in a new tab.</i>
            </span>
          </div>
          <div class="text-secondary py-3">
            <b>* Bold:</b>
            <span>
              This HTML tag &lt;b&gt; text &lt;/b&gt; is used to highlight text and make it appear in <b>bold</b>.<br />
              <i><b>Usage:</b></i> To apply bold formatting to a specific part of the text, simply enclose the content
              you want to highlight with the &lt;b&gt; tag. For example: &lt;b&gt;This text will be bold&lt;/b&gt;.
            </span>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </form>
  <nb-card>
    <nb-card-header> Preview </nb-card-header>
    <nb-card-body>
      <div class="row mb-12">
        <div class="col-12">
          <xen-alert
            *ngIf="form.get('bannerMessage').value && alertType"
            [type]="alertType"
            [content]="form.get('bannerMessage').value"
            [icon]="true"
            [icon_snippet]="'far fa-circle-info'"
            [dismiss]="false"
          ></xen-alert>
          <span
            *ngIf="!alertType && form.get('bannerMessage').value"
            [innerHTML]="form.get('bannerMessage').value"
          ></span>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
  <nb-card>
    <nb-card-header> Current message </nb-card-header>
    <nb-card-body>
      <div class="row mb-12">
        <div class="col-12">
          <div>
            <div class="d-flex py-3 gap-3"><b>Are message currently visible?</b> {{ isVisible ? 'Yes' : 'No' }}</div>
            <div class="d-flex py-3 gap-3"><b>Message:</b> <span [innerHtml]="message"></span></div>
          </div>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</div>
