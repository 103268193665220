import { gql } from 'apollo-angular';

export const updateUserProfile = gql`
  mutation ($where: UserProfileWhereUniqueInput!, $data: UserProfileUpdateInput!) {
    social {
      updateUserProfile(where: $where, data: $data) {
        code
        adviseMessage {
          description
        }
        userProfileData {
          email
          firstName
          lastName
          partnersIncludedIn {
            department
            jobTitle
            organization {
              code
            }
          }
          onboardingProperties {
            startedAt
            userDetailsFinishedAt
            billingAccountCreated
            tcpAcceptedAt
            annexBuyerAcceptedAt
            annexSellerAcceptedAt
            annexPlatformBuyerAcceptedAt
            annexPlatformSellerAcceptedAt
            finishedAt
          }
        }
      }
    }
  }
`;
