import { Table } from 'primeng/table';
import { Component, OnInit, Output, ViewChild, EventEmitter, OnDestroy } from '@angular/core';
import { PreAndPostCheckService } from '../../../../../services/_index-billing.services';
import { NotificationService } from './../../../../../../../@core/services/_index-core.services';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'tgx-admin-billing-invoicing-process-pre-seventh',
  templateUrl: './invoicing-process-pre-seventh.component.html',
  styleUrls: ['./invoicing-process-pre-seventh.component.scss'],
})
export class BillingInvoicingProcessPreSeventhComponent implements OnInit, OnDestroy {
  @ViewChild('dt', { static: false }) dataTable!: Table;
  @Output() isValidStep: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  header = [
    { label: 'PartnerId', field: 'PartnerId' },
    { label: 'OrgCode', field: 'OrgCode' },
    { label: 'ContractId', field: 'ContractId' },
    { label: 'PartnerType', field: 'PartnerType' },
    { label: 'SubType', field: 'SubType' },
    { label: 'ModelType', field: 'ModelType' },
    { label: 'ConnectionId', field: 'ConnectionId' },
    { label: 'Activation', field: 'Activation' },
    { label: 'ConnectionType', field: 'ConnectionType' },
    { label: 'Buyer', field: 'Buyer' },
    { label: 'Supplier', field: 'Supplier' },
  ];

  source: any[];

  isLoading: boolean;

  $subs: Subscription[] = [];

  step = 7;
  constructor(
    private notificationService: NotificationService,
    private preAndPostCheckService: PreAndPostCheckService,
    private datePipe: DatePipe,
  ) {
    this.$subs.push(
      this.preAndPostCheckService.refreshPreStepPage.subscribe(async (refreshStep) => {
        if (refreshStep && refreshStep === this.step) {
          await this.getConnections();
        }
      }),
    );
  }

  async ngOnInit(): Promise<void> {
    this.$subs.push(
      this.preAndPostCheckService.currentPreStepPage.subscribe(async (currentStep) => {
        if (currentStep && currentStep === this.step) {
          await this.getConnections();
        }
      }),
    );
  }

  ngOnDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
  }

  async getConnections() {
    this.isLoading = true;
    this.source = [];
    const requests: any[] = [];

    await this.preAndPostCheckService
      .checkDPConnectionsNotSup()
      .then((rs) => {
        if (rs.success) {
          rs.response?.forEach((cn) => {
            requests.push({
              'PartnerId': cn.PartnerId,
              'OrgCode': cn.PartnerOrgCode,
              'ContractId': cn.ContractId,
              'PartnerType': cn.PartnerType,
              'SubType': cn.PartnerSubType,
              'ModelType': cn.ModelType,
              'ConnectionId': cn.ContractConnectionId,
              'Activation': this.datePipe.transform(cn.ActivationDateTime, 'dd-MM-yyyy'),
              'ConnectionType': this.getRateType(cn.ConnectionType),
              'Buyer': cn.ConnectionOrgCode,
              'Supplier': cn.SupplierCode,
            });
          });
        }
        this.source = [...requests];
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      })
      .finally(() => {
        this.isLoading = false;
        this.isValidStep.emit(this.source?.length === 0);
      });
  }

  getRateType(rateType: string) {
    switch (rateType) {
      case 'STANDARD':
        return 'STD';
      case 'STANDARD+SUPPLEMENT':
        return 'SUP';
      case 'FREE':
        return rateType;
    }
  }
}
