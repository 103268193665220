import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'xen-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class LinkComponent {
  @Input() size: 'caption' | 'small' | 'body' | 'medium' | 'large' | 'xlarge' = 'body';
  @Input() weight: 'regular' | 'medium' | 'semi-bold' | 'bold' = 'regular';
  @Input() label: string;
  @Input() href: string;
  @Input() routerLink: string;
  @Input() icon = false;
  @Input() icon_snippet: string;
  @Input() icon_placement: 'left' | 'right' = 'left';
  @Input() theme: 'blue' | 'dark' | 'gray' = 'blue';
  @Input() target: '_self' | '_blank' = '_self';
  @Input() underlined = true;
  @Input() disabled = false;
  @Input() queryParams: any = {};
  @Input() block = false;
  @Output() ClickEvent: EventEmitter<any> = new EventEmitter();

  public get classes(): Array<string> {
    return [
      this.icon ? `xen-link__icon xen-link__icon--${this.icon_placement}` : '',
      `xen-link--${this.theme}`,
      this.block ? 'xen-link__block' : '',
      this.disabled ? 'xen-link__disabled' : '',
    ];
  }
  public get aClasses(): Array<string> {
    return [
      this.disabled ? 'xen-link__disabled pe-none' : '',
      this.underlined ? '' : 'no-underlined',
      'xen-link__href',
      `xen-link__href--${this.size}`,
      this.weight,
    ];
  }

  ngOnInit(): void {}

  onClickEvent(event) {
    this.ClickEvent.emit(event);
  }
}
