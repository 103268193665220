import { gql } from 'apollo-angular';

export const getSuppliers = gql`
  query getSuppliersSimple {
    admin {
      suppliers {
        edges {
          node {
            supplierData {
              code
              name
              isActive
              owner {
                code
              }
              groups {
                edges {
                  node {
                    code
                  }
                }
              }
            }
            error {
              code
              type
              description
            }
          }
        }
      }
    }
  }
`;
