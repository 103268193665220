import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDeleteComponent } from './../../../../../../@core/shared/modal-delete/modal-delete.component';
import { NotificationService } from '../../../../../../@core/services/_index-core.services';
import { Dropdown } from 'primeng/dropdown';
import { ClientsEntitiesService } from '../../../../services/clients-entities.service';
import { AccessesService } from '../../../../services/accesses.service';
import { OrganizationEntitiesService } from '../../../../services/organization-entities.service';

@Component({
  selector: 'tgx-accesses-safeDeactivate-modal',
  templateUrl: './accesses-safeDeactivate-modal.component.html',
  styleUrls: ['./accesses-safeDeactivate-modal.component.scss'],
})
export class AccessesSafeDeactivateModalComponent implements OnInit {
  @Input() access: any;
  @Input() header: string;

  public accessesSafeDeactivateForm: UntypedFormGroup;

  constructor(
    private activeModal: NgbActiveModal,
    private notificationsService: NotificationService,
    private accessesService: AccessesService,
  ) {}

  ngOnInit(): void {
    /* TODO document why this method 'ngOnInit' is empty */
  }

  closeModal() {
    this.activeModal.close(true);
  }

  async onSave() {
    const input = { search: this.access.code, searchBy: 'ID' };
    const result = await this.accessesService.deactivateAccess(input);
    const hasEmptyAdviseMessage = result && result.adviseMessage == null;

    if (hasEmptyAdviseMessage) {
      this.notificationsService.success('Access was successfully deactivated');
    } else {
      this.notificationsService.error('There was an error deactivating the access, please contact your admin');
    }
    this.activeModal.close(true);
  }
}
