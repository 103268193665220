import { Injectable } from '@angular/core';
import { PlatformInfoService } from './platform-info.service';
import { VisibilityPermissions as Permissions } from '@tgx/shared/utils';
import { WebComponent, WebFeature, PartnerType, MembersRole } from '@tgx/shared/enums';
import { IAuth0Permissions, Permission, PermissionsVisibility } from '@tgx/shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ManagePermissionsService {
  private isStaff: boolean;
  private genericRole: MembersRole;
  private selectedOrg: string;
  private partnerType: PartnerType;
  private permissions: IAuth0Permissions;

  // Defines the hierarchy of roles from least to most privileged
  private readonly roleHierarchy = [MembersRole.VIEWER, MembersRole.EDITOR, MembersRole.ADMIN, MembersRole.OWNER];

  constructor(private platformInfoService: PlatformInfoService) {
    this.platformInfoService.userLogged$.subscribe((user) => {
      this.isStaff = user.isStaff;
      this.genericRole = user.role;
      this.permissions = user.permissions;
    });

    this.platformInfoService.organizationSelected.subscribe((org) => {
      this.partnerType = org.typeSelected as PartnerType;
      this.selectedOrg = org.code;
    });
  }

  /**
   * Checks the visibility of a component or section based on their role and partner type.
   * @param component - The web component to check visibility for.
   * @param section - The specific section within the component to check visibility for. Defaults to WebFeature.FULL_COMPONENT.
   * @returns An object containing the visibility status and the required role for the component or section.
   */
  checkVisibility(component: WebComponent, section: WebFeature = WebFeature.FULL_COMPONENT): PermissionsVisibility {
    // Find permissions for the specified component
    const permissionsByComponent = Permissions.get(component);

    // Find permissions for the specified section within the component
    const permissionsBySection = permissionsByComponent?.get(section);

    // If no specific permissions are defined for the component or section, allow visibility
    if (!permissionsBySection) return this.createPermissionResponse(true, permissionsBySection);

    // Check role permission
    const hasRolePermission = this.hasRolePermissions(permissionsBySection);

    // Check partner type permission
    const hasPartnerTypePermission =
      !permissionsBySection.partnerType || permissionsBySection.partnerType === this.partnerType;

    // Check if the selected organization is allowed
    const isSelectedOrgAllowed =
      !permissionsBySection.allowedOrgs || permissionsBySection.allowedOrgs.has(this.selectedOrg);

    const isVisible = hasRolePermission && hasPartnerTypePermission && isSelectedOrgAllowed;
    return this.createPermissionResponse(isVisible, permissionsBySection);
  }

  buildVisibilityMessage({ isVisible, requiredRole, requiredPartnerType }: PermissionsVisibility) {
    let message = '';

    if (!isVisible) {
      if (requiredRole) {
        message += `You need an ${requiredRole} role or higher to use this feature. Please contact an Admin from your organization to update your role.`;
      }

      if (requiredPartnerType) {
        message += `You need to be browsing as a ${requiredPartnerType} to access this feature.`;
      }
    }

    return message.trim();
  }

  private createPermissionResponse(isVisible: boolean, permission: Permission) {
    return {
      isVisible,
      requiredRole: permission.role,
      requiredPartnerType: permission.partnerType,
    };
  }

  // Normalizes the user's role to one of the predefined roles
  private normalizeRole(
    role: MembersRole,
  ): MembersRole.OWNER | MembersRole.ADMIN | MembersRole.EDITOR | MembersRole.VIEWER {
    switch (role) {
      case MembersRole.OWNER:
        return MembersRole.OWNER;
      case MembersRole.ADMIN:
        return MembersRole.ADMIN;
      case MembersRole.EDITOR:
        return MembersRole.EDITOR;
      default:
        return MembersRole.VIEWER;
    }
  }

  // Refactored role permission check into a separate method
  private hasRolePermissions(permissionsBySection): boolean {
    if (!permissionsBySection.role) return true;

    const normalizedGenericRole = this.normalizeRole(this.genericRole);
    if (this.checkRolePermission(permissionsBySection.role, normalizedGenericRole)) return true;

    if (this.permissions?.services.has(permissionsBySection.service)) {
      const serviceRole = this.permissions.services.get(permissionsBySection.service);
      const normalizedSpecificRole = this.normalizeRole(serviceRole);
      return this.checkRolePermission(permissionsBySection.role, normalizedSpecificRole);
    }

    return false;
  }

  // Checks if a user's role meets or exceeds the required role for an action, respecting the defined role hierarchy and staff status
  private checkRolePermission(
    requiredRole: MembersRole | null, // Role required to perform the action
    userRole: MembersRole, // User's current role
  ): boolean {
    // Allow action if no specific role is required or if the user is a staff member
    if (requiredRole === null || this.isStaff) return true;

    // Determine the hierarchy levels of the required and user's roles
    const requiredRoleIndex = this.roleHierarchy.indexOf(requiredRole);
    const userRoleIndex = this.roleHierarchy.indexOf(userRole);

    // User is permitted if their role is equal to or higher than the required role
    return userRoleIndex >= requiredRoleIndex;
  }
}
