<nb-card>
  <nb-card-header> Signup Status </nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-12 mb-4">
        <div [formGroup]="form">
          <xen-input
            [label]="'Enter the email you wish to consult'"
            [id]="'default'"
            [formControlName]="'email'"
            [required]="false"
            [tooltip]="false"
            [tooltip_text]="'This is a tooltip sample text'"
            [type]="'text'"
            [placeholder]="'email@travelgate.com'"
            [validation]="false"
            [validation_status]="'is-valid'"
            [feedback]="'Looks good!'"
            [disabled]="false"
            [icon]="true"
            [readOnly]="false"
            [icon_snippet]="'fa-regular fa-search'"
            [icon_placement]="'right'"
            [size]="'medium'"
            (keyup.enter)="retrieveSignupFeedback()"
            (paste)="handlePaste()"
            (input)="emptyData()"
          ></xen-input>
        </div>
      </div>
    </div>
    <div *ngIf="data && !loading" class="row">
      <div *ngFor="let section of objectKeys(toggleProperties)" class="col-lg-6 col-md-12 col-12 mb-4">
        <div class="mb-3">
          <xen-subtitle-page [back]="false" title="{{ addSpaceBeforeCapitals(section) }}"></xen-subtitle-page>
        </div>
        <div *ngFor="let property of objectKeys(data[section])" style="padding-bottom: 1%">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <ng-container
                *ngIf="data[section][property].status === 'CHECKED' && data[section][property].properties !== null"
              >
                <span
                  [ngbTooltip]="
                    status === 'success' ? 'This entity exists' : 'This entity exists but some info is missing'
                  "
                  tooltipClass="xen-tooltip"
                  placement="top"
                  container="body"
                  class="me-1"
                  *ngIf="checkSuccessStatus(section, property) as status"
                >
                  <i class="fa-regular fa-check {{ status }}"></i
                ></span>
              </ng-container>

              <span
                [ngbTooltip]="'This entity does NOT exists'"
                tooltipClass="xen-tooltip"
                placement="top"
                container="body"
                class="me-1"
                *ngIf="data[section][property].status === 'CHECKED' && data[section][property].properties === null"
              >
                <i class="fa-regular fa-xmark text-danger"></i
              ></span>

              <span
                [ngbTooltip]="'This entity has not been checked'"
                tooltipClass="xen-tooltip"
                placement="top"
                container="body"
                class="me-1"
                *ngIf="data[section][property].status === 'NO_CHECKED'"
              >
                <i class="fa-regular fa-eye-slash"></i
              ></span>
              <p class="mb-0">{{ addSpaceBeforeCapitals(property) }}</p>
            </div>
            <xen-link
              *ngIf="data[section][property].status === 'CHECKED' && data[section][property].properties !== null"
              [size]="'body'"
              [underlined]="false"
              [weight]="'regular'"
              [label]="'Show properties'"
              [theme]="'blue'"
              (click)="showProperties(section, property)"
              [icon]="true"
              [icon_snippet]="'fa-solid fa-caret-down'"
              [icon_placement]="'right'"
            ></xen-link>
          </div>

          <div *ngIf="toggleProperties[section][property]">
            <ng-container
              *ngTemplateOutlet="hubspotDataTemplate; context: { properties: data[section][property].properties }"
            ></ng-container>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="loading" class="row">
      <div *ngFor="let section of [1, 2, 3, 4]" class="col-lg-6 col-md-12 col-12 mb-4">
        <p-skeleton styleClass="mb-2"></p-skeleton>
        <p-skeleton styleClass="mb-2"></p-skeleton>
        <p-skeleton styleClass="mb-2"></p-skeleton>
      </div>
    </div>
  </nb-card-body>
</nb-card>

<ng-template #hubspotDataTemplate let-properties="properties">
  <div>
    <div>
      <ul class="list-unstyled">
        <li *ngFor="let key of objectKeys(properties)">
          {{ key }}: <span *ngIf="properties[key]; else nullValue">{{ properties[key] }}</span>
        </li>
      </ul>
    </div>
  </div>
</ng-template>

<ng-template #nullValue>
  <span class="null-value">null</span>
</ng-template>
