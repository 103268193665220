import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Dropdown } from 'primeng/dropdown';
import { AccessesService } from '../../../../services/accesses.service';
import { ClientsEntitiesService } from '../../../../services/clients-entities.service';
import { Options } from '../../../../../../@core/interfaces/_index.interfaces';
import { NotificationService } from 'apps/web-admin/src/app/@core/services/notification.service';

@Component({
  selector: 'tgx-admin-accesses-modal',
  templateUrl: './accesses-modal.component.html',
  styleUrls: ['./accesses-modal.component.scss'],
})
export class AccessesModalComponent implements OnInit {
  @Input() header: string;
  @Input() access: any;
  @Input() isCreating: boolean;
  @Input() organizations: any;
  @Input() selectedOrg: any;
  @Input() groups: any;
  @Input() showOwner: boolean;

  @ViewChild('groups_dd') groupsDropdown: Dropdown;

  isLoading: boolean;

  public accessForm: UntypedFormGroup;
  currentGroups: any;
  rateRulesOptions: Options[];
  iRateRules: any[];
  updateRateRules: boolean;
  updateParameters: boolean;
  updateMarkets: boolean;
  originalRateRules: string[];
  originalParametersKeys: string[];
  originalMarkets: string[];

  constructor(
    private activeModal: NgbActiveModal,
    private ClientsEntitiesService: ClientsEntitiesService,
    private accessesService: AccessesService,
    private fb: UntypedFormBuilder,
    private notificationsService: NotificationService,
  ) {
    this.updateRateRules = false;
    this.updateParameters = false;
    this.updateMarkets = false;
    this.loadRateRulesOptions();
  }

  ngOnInit(): void {
    if (!this.isCreating) {
      this.iRateRules = this.initializeRateRules(this.access?.rateRules);
      this.originalRateRules = this.access?.rateRules;
      this.originalParametersKeys = this.getParameterKeys(this.access?.parameters);
      this.originalMarkets = this.access?.markets;
    }

    let supplier = '';
    if (this.access?.supplier) {
      supplier = typeof this.access.supplier !== 'string' ? this.access.supplier.code : this.access.supplier;
    }
    this.accessForm = this.fb.group({
      code: [this.access ? this.access.code : ''],
      name: [this.access ? this.access.name : '', Validators.required],
      ownerCode: this.fb.control(null),
      isActive: [this.access ? this.access.isActive : false],
      isShared: [this.access ? this.access.isShared : false],

      isTest: [this.access ? this.access.isTest : false],
      isSchedulerActive: [this.access ? this.access.isSchedulerActive : false],
      //supplierCode: [this.access ? this.access.supplier : ''],
      supplierCode: [supplier, Validators.required],
      user: [this.access ? this.access.user : ''],
      password: [this.access ? this.access.password : ''],
      master: this.fb.control(this.access?.master || ''),
      shared: this.fb.control(this.access?.shared || ''),

      rateRules: this.fb.control(this.access?.rateRules || null),
      descriptiveInfoLimit: this.fb.control(this.access?.descriptiveInfoLimit || 0),
      updateList: this.fb.control(this.access?.updateList || 0),
      updateDateRange: this.fb.control(this.access?.updateDateRange || '0'),
      updateDescriptiveInfo: this.fb.control(this.access?.updateDescriptiveInfo || 0),
      urls_search: this.fb.control(this.access?.urls?.search || ''),
      urls_book: this.fb.control(this.access?.urls?.book || ''),
      urls_generic: this.fb.control(this.access?.urls?.generic || ''),
      urls_quote: this.fb.control(this.access?.urls?.quote || ''),
      parameters: this.fb.array(this.initializeParameters(this.access?.parameters)),
      groupCode: this.fb.control(this.access?.groupCode || '0', Validators.required),
      markets: this.fb.control(this.access?.markets || ''),
    });

    if (this.showOwner) {
      this.accessForm.get('ownerCode').setValue(this.access?.ownerCode);
      this.accessForm.get('ownerCode').setValidators(Validators.required);
    }

    if (!this.isCreating) {
      this.accessForm.get('ownerCode').disable();
      this.accessForm.get('code').disable();
      this.accessForm.get('isActive').disable();
    }
  }

  initializeRateRules(rateRules: any) {
    const iRateRules = [];
    if (rateRules) {
      rateRules.forEach((element) => {
        iRateRules.push({ code: element, label: element });
      });
    }
    return iRateRules;
  }

  initializeParameters(parameters: any) {
    const iParameters = [];
    if (parameters) {
      parameters.forEach((element) => {
        const nParameter = this.createParameter(element.key, element.value);
        iParameters.push(nParameter);
      });
    }
    return iParameters;
  }

  createParameter(key: string, value: string): FormGroup {
    return this.fb.group({
      key: [key, Validators.required],
      value: [value],
    });
  }

  get parameters(): FormArray {
    return <FormArray>this.accessForm.get('parameters');
  }

  addParameter() {
    this.parameters.push(this.createParameter('', ''));
    this.setParametersForUpdate(); //force update parameters on edit
  }

  removeParameter(index: any) {
    this.parameters.removeAt(index);
    this.setParametersForUpdate(); //force update parameters on edit
  }

  closeModal() {
    this.activeModal.close(true);
  }

  composeUpdateRateRules() {
    return this.accessForm.get('rateRules').value.map(function (rate) {
      return rate.code;
    });
  }

  getParameterKeys(parameters: any) {
    return parameters.map(function (parameter) {
      return parameter.key;
    });
  }

  composeUpdateAccessInput() {
    const input = {
      code: this.accessForm.get('code').value,
      name: this.accessForm.get('name').value,
      isTest: this.accessForm.get('isTest').value,
      isShared: this.accessForm.get('isShared').value,
      isSchedulerActive: this.accessForm.get('isSchedulerActive').value,
      supplierCode: this.accessForm.get('supplierCode').value,

      user: this.accessForm.get('user').value,
      password: this.accessForm.get('password').value,
      descriptiveInfoLimit: this.accessForm.get('descriptiveInfoLimit').value,
      updateList: this.accessForm.get('updateList').value,
      updateDateRange: this.accessForm.get('updateDateRange').value,
      updateDescriptiveInfo: this.adaptValueToNumber(this.accessForm.get('updateDescriptiveInfo').value),
      urls: {
        search: this.accessForm.get('urls_search').value,
        quote: this.accessForm.get('urls_quote').value,
        book: this.accessForm.get('urls_book').value,
        generic: this.accessForm.get('urls_generic').value,
      },
    };

    /*     if (this.showOwner) {
      input['ownerCode'] = this.accessForm.get('ownerCode').value.code;
    } */

    return input;
  }

  adaptValueToNumber(val: any): number {
    let udi = val * 1;
    if (!Number.isInteger(udi)) {
      udi = 0;
    }
    return udi;
  }

  composeCreateAccessInput() {
    const parameters = this.parameters;
    let rateRulesMap;

    if (this.accessForm.get('rateRules')?.value) {
      rateRulesMap = this.accessForm.get('rateRules').value.map(function (rate) {
        return rate.code;
      });
    }

    const input = {
      name: this.accessForm.get('name').value,
      isActive: this.accessForm.get('isActive').value,
      isTest: this.accessForm.get('isTest').value,
      isSchedulerActive: this.accessForm.get('isSchedulerActive').value,
      supplierCode: this.accessForm.get('supplierCode').value,

      user: this.accessForm.get('user').value,
      password: this.accessForm.get('password').value,
      descriptiveInfoLimit: this.accessForm.get('descriptiveInfoLimit').value,
      updateList: this.accessForm.get('updateList').value,
      updateDateRange: this.accessForm.get('updateDateRange').value,
      updateDescriptiveInfo: this.accessForm.get('updateDescriptiveInfo').value,
      urls: {
        search: this.accessForm.get('urls_search').value,
        quote: this.accessForm.get('urls_quote').value,
        book: this.accessForm.get('urls_book').value,
        generic: this.accessForm.get('urls_generic').value,
      },
      parameters: parameters.value,

      markets: this.accessForm.get('markets').value.split(','),
      rateRules: rateRulesMap ?? [],
      groupCode: this.accessForm.get('groupCode').value.code,
    };

    return input;
  }

  isValidForm(): string[] {
    const failures = [];

    Object.keys(this.accessForm.controls).forEach((key) => {
      const controlErrors: ValidationErrors = this.accessForm.get(key).errors;
      if (controlErrors !== null) {
        Object.keys(controlErrors).forEach((keyError) => {
          const message = key.toUpperCase() + ', Error: ' + keyError;
          failures.push(message);
        });
      }
    });

    Object.keys(this.parameters.controls).forEach((key) => {
      const formGroup = <FormGroup>this.parameters.get(key);
      const controlErrors: ValidationErrors = formGroup.controls.key.errors;
      if (controlErrors !== null) {
        Object.keys(controlErrors).forEach((keyError) => {
          const message = 'PARAMETER_' + key + ', Error: ' + keyError;
          failures.push(message);
        });
      }
    });
    return failures;
  }

  async onSave() {
    let result = null;
    const failures = this.isValidForm();

    if (failures.length > 0) {
      this.notificationsService.error(failures.join('\n'));
    } else {
      if (this.isCreating) {
        const input = this.composeCreateAccessInput();
        result = await this.accessesService.createAccess(input);
      } else {
        const data = this.composeUpdateAccessInput();
        const where = { search: data.code, searchBy: 'ID' };
        delete data.code;

        //update access general info
        result = await this.accessesService.updateAccess(where, data);

        if (this.updateRateRules) {
          //update access rates info
          result = await this.accessesService.updateRatesRules(
            where,
            this.composeUpdateRateRules(),
            this.originalRateRules,
          );
        }

        if (this.updateParameters) {
          //update access parameters info
          result = await this.accessesService.updateParameters(
            where,
            this.parameters.value,
            this.originalParametersKeys,
          );
        }

        if (this.updateMarkets) {
          //update access parameters info
          let currentMarkets = this.accessForm.get('markets').value;
          currentMarkets = currentMarkets.split(',');
          result = await this.accessesService.updateMarkets(where, currentMarkets, this.originalMarkets);
        }

        //update shared
        if (this.access.shared !== this.accessForm.get('shared').value) {
          if (this.accessForm.get('shared').value !== '') {
            result = await this.accessesService.setAccessShared(where, this.accessForm.get('shared').value);
          } else {
            result = await this.accessesService.removeAccessShared(where);
          }
        }
        //update master
        if (this.access.master !== this.accessForm.get('master').value) {
          if (this.accessForm.get('master').value !== '') {
            result = await this.accessesService.setAccessMaster(where, this.accessForm.get('master').value);
          } else {
            result = await this.accessesService.removeAccessMaster(where);
          }
        }
      }
      this.activeModal.close(result);
    }
  }

  async loadOrganizationsGroupsEvent(event) {
    if (this.isCreating) {
      if (event.value && event.value.code) {
        await this.loadOrganizationsGroups(event.value.code);
      } else {
        this.groups = [];
        this.groupsDropdown.placeholder = 'Empty groups';
      }
    }
  }

  async loadOrganizationsGroups(code) {
    this.groups = await this.ClientsEntitiesService.getHotelXGroupsByOrgs(code);

    this.currentGroups = [];
    if (!this.isCreating) {
      this.currentGroups = await this.accessesService.getGroupsByAccessCode({ code_in: [this.access.code] });
    }

    this.groups.forEach((group_tmp) => {
      if (this.currentGroups.indexOf(group_tmp.code) !== -1) {
        this.accessForm.get('groupCode').setValue(group_tmp);
      }
    });

    if (this.isCreating) {
      this.groupsDropdown.show();
    }
  }

  loadRateRulesOptions() {
    const rateRulesRaw = [
      'NORMAL',
      'PACKAGE',
      'OLDER55',
      'OLDER60',
      'OLDER65',
      'CANARY_RESIDENT',
      'BALEARIC_RESIDENT',
      'LARGE_FAMILY',
      'HONEYMOON',
      'PUBLIC_SERVANT',
      'UNEMPLOYED',
      'NON_REFUNDABLE',
      'MOBILE',
      'WARRANTY',
      'ESSENTIAL_WORKER',
      'NEGOTIATED',
      'CUSTOM',
    ];

    this.rateRulesOptions = [];
    for (const rate of rateRulesRaw) {
      this.rateRulesOptions.push({ code: rate, label: rate });
    }
  }

  setRateRulesForUpdate() {
    this.updateRateRules = true;
  }

  setParametersForUpdate() {
    this.updateParameters = true;
  }
  setMarketsForUpdate() {
    this.updateMarkets = true;
  }
}
