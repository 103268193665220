<div class="xen-help-center">
  <p class="xen-help-center__title">{{ title }}</p>
  <div class="xen-help-center__list">
    <div
      class="xen-help-center__list__list-card"
      [ngClass]="{ 'border-0 pb-0 mb-0': isLast }"
      *ngFor="let card of cardsHelp; let isLast = last"
    >
      <p class="xen-help-center__list__list-card__title">{{ card.card_title }}</p>
      <p class="xen-help-center__list__list-card__text">
        {{ card.card_subtitle }}
      </p>
      <xen-link
        size="body"
        weight="regular"
        label="Read more"
        href="{{ card.card_link }}"
        [icon]="true"
        icon_snippet="fas fa-chevron-right"
        icon_placement="right"
        theme="blue"
        target="_blank"
        [underlined]="true"
      ></xen-link>
    </div>
  </div>
</div>
