import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDeleteComponent } from './../../../../../../@core/shared/modal-delete/modal-delete.component';
import { NotificationService } from '../../../../../../@core/services/_index-core.services';
import { Dropdown } from 'primeng/dropdown';
import { ClientsEntitiesService } from '../../../../services/clients-entities.service';
import { AccessesService } from '../../../../services/accesses.service';
import { OrganizationEntitiesService } from '../../../../services/organization-entities.service';

@Component({
  selector: 'tgx-accesses-groups-modal',
  templateUrl: './accesses-groups-modal.component.html',
  styleUrls: ['./accesses-groups-modal.component.scss'],
})
export class AccessesGroupsModalComponent implements OnInit {
  @Input() header: string;
  @Input() access: any;
  @Input() organizations: any;
  @Input() selectedOrg: any;
  @Input() selectedGroup: any;

  @ViewChild('groups_dd') groupsDropdown: Dropdown;

  public accessesGroupsForm: UntypedFormGroup;
  groups: any[];
  currentGroups: any[];
  enableRevokeButton: boolean;
  enableAddButton: any;
  assignedGroupsOptions: any[];
  currentAssignedGroups: any[];

  constructor(
    private activeModal: NgbActiveModal,
    private modal: NgbModal,
    private fb: UntypedFormBuilder,
    private notificationsService: NotificationService,
    private accessesService: AccessesService,
    private ClientsEntitiesService: ClientsEntitiesService,
    private OrganizationEntitiesService: OrganizationEntitiesService,
  ) {}

  ngOnInit(): void {
    this.loadAssignedGroupsToAccess();

    this.accessesGroupsForm = this.fb.group({
      name: [this.access ? this.access.name : '', Validators.required],
      orgCode: this.fb.control(this.access?.owner || '0', Validators.required),
      groupCode: this.fb.control(this.access?.groupCode || '0', Validators.required),
      assignedGroup: [''],
      assignedGroupsControl: [''],
    });

    this.enableRevokeButton = false;
    this.enableAddButton = false;
  }

  closeModal() {
    this.activeModal.close(true);
  }

  onSave() {
    const accessCode = this.access.code;
    const orgCode = this.accessesGroupsForm.get('orgCode').value?.code ?? null;
    const groupCode = this.accessesGroupsForm.get('groupCode').value?.code ?? null;

    if (accessCode && orgCode && groupCode) {
      const where = { search: accessCode, searchBy: 'ID' };
      const groupCodes = [groupCode, orgCode];
      this.accessesService
        .grantAccessToGroups(where, groupCodes)
        .then(() => {
          this.loadAssignedGroupsToAccess();
          this.notificationsService.info('Access was successfully added to Group');
        })
        .catch((err) => {
          this.notificationsService.info(err);
        });
    } else {
      this.notificationsService.error('Please, select an Organization and a Group');
    }
  }

  async loadOrganizationsGroupsEvent(event) {
    this.enableAddButton = false;
    if (event.value && event.value.code) {
      if (!event.value.isTeam) {
        await this.loadOrganizationsGroups(event.value.code);
      } else {
        //as team we set de "org group" as the only group available option.
        this.groups = [event.value];
        this.groupsDropdown.show();
      }
    } else {
      this.groups = [];
      this.groupsDropdown.placeholder = 'Empty groups';
    }
  }

  async loadAssignedGroupsToAccess() {
    const assignedGroupsSource = await this.accessesService.getAllAccessesGroups({ code_in: [this.access.code] });
    const groupsCode = await this.accessesService.getGroupsCodeFromAccessEdge(assignedGroupsSource);
    const labelsForGroups = await this.OrganizationEntitiesService.getLabelsForGroups(groupsCode);
    const typesForGroups = await this.OrganizationEntitiesService.getTypesForGroups(groupsCode);
    this.currentAssignedGroups = this.adaptAssignedGroupsToDropboxFormat(
      assignedGroupsSource,
      labelsForGroups,
      typesForGroups,
    );
  }

  adaptAssignedGroupsToDropboxFormat = (currentAssignedGroups, labelsForGroups, typesForGroups) => {
    const assignedGroupsOptions = [];
    currentAssignedGroups.forEach((accessGroups) => {
      accessGroups['node']['accessData']['groups']['edges'].forEach((group) => {
        const code = group['node']['groupData'].code;
        let label = group['node']['groupData'].code;
        if (labelsForGroups[code]) {
          label += ' | ' + labelsForGroups[code];
        }

        const type = typesForGroups[code];
        assignedGroupsOptions.push({ code, label, type });
      });
    });
    return assignedGroupsOptions;
  };

  executeModal() {
    return new Promise((resolve) => {
      const modalRef = this.modal.open(ModalDeleteComponent, {
        backdrop: 'static',
        container: 'nb-layout',
      });

      modalRef.componentInstance.message = `You have selected one or more organizations to revoke permissions.
       The groups descended from them will also be revoked.`;
      modalRef.componentInstance.title = 'Alert';
      modalRef.componentInstance.buttonOkTitle = 'Revoke';

      modalRef.result.then((res) => {
        if (res) {
          resolve(true);
        }
        resolve(false);
      });
    });
  }

  async checkRevokeOrganizations() {
    let accessGroup = this.accessesGroupsForm.get('assignedGroupsControl').value;
    const existsOrg = accessGroup.filter((org) => org.type === 'ORG').length;

    if (existsOrg !== 0) {
      await this.executeModal().then((res) => {
        if (!res) accessGroup = accessGroup.filter((org) => org.type !== 'ORG');
      });
    }

    this.revokeAccessFromGroups(accessGroup);
  }

  async revokeAccessFromGroups(accessGroup) {
    const limit = accessGroup.length;
    let index = 0;
    const selectedSubGroups = [];

    let groupToRevoke = [];
    accessGroup.forEach(async (group) => {
      const { code, type } = group;

      if (type !== 'ORG') {
        const response = await this.OrganizationEntitiesService.getOrgByGroupCode({ codes: code });

        if (response.length !== 0) {
          selectedSubGroups.push(code);
        } else {
          this.notificationsService.error('Organization not found');
        }
      } else {
        groupToRevoke = await this.getOrgDescendents(groupToRevoke, code);
      }

      index++;
      if (index === limit) {
        const selected = selectedSubGroups.concat(this.getSelectedGroup(groupToRevoke));

        if (selected.length > 0) {
          const where = { search: this.access.code, searchBy: 'ID' };
          await this.accessesService.revokeAccessFromGroups(where, selected);
          await this.loadAssignedGroupsToAccess();
          this.notificationsService.info('Revoke access from groups operation was successfull');
        }
      }
    });
  }

  async getOrgDescendents(groupToRevoke, code) {
    const response = await this.OrganizationEntitiesService.getOrgGroupsByOrgCode({ codes: code });

    if (response[0]['node']['adviseMessage']) {
      this.notificationsService.error(response[0]['node']['adviseMessage'][0].description);
    } else {
      const descendents = [];
      response[0]['node']['organizationsData']['groups']['edges'].forEach((edge) => {
        descendents.push({ code: edge['node']['groupData']['code'] });
      });

      groupToRevoke.push({
        org: code,
        descendents,
      });
    }

    return groupToRevoke;
  }

  getSelectedGroup(groupToRevoke) {
    const selected = [];

    groupToRevoke.forEach(({ org, descendents }) => {
      this.currentAssignedGroups.forEach((group) => {
        if (org === group.code) {
          selected.push(org);
        }

        descendents.forEach(({ code }) => {
          if (code === group.code) {
            selected.push(code);
          }
        });
      });
    });

    return selected;
  }

  async loadOrganizationsGroups(code) {
    this.groups = await this.ClientsEntitiesService.getAllGroupsByOrg(code);
    this.groupsDropdown.show();
  }

  async enableAddButtonChanged() {
    this.enableAddButton =
      this.accessesGroupsForm.get('orgCode').value?.code && this.accessesGroupsForm.get('groupCode').value?.code;
  }

  async assignedGroupSelectionChanged() {
    this.enableRevokeButton = this.accessesGroupsForm.get('assignedGroupsControl').value.length;
  }
}
