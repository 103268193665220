import { CookieService } from 'ngx-cookie-service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PaginationComponent } from './pagination/pagination.component';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { CustomDateRendererComponent } from './custom-date-renderer/custom-date-renderer';
import { DecodePipe } from './decode-pipe/decode-pipe';
import { CommonModule } from '@angular/common';
import { ModalDeleteComponent } from './modal-delete/modal-delete.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { TooltipErrorComponent } from './tooltip-error/tooltip-error.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { NgModule } from '@angular/core';
import { NbCardModule, NbMenuModule, NbButtonModule, NbCheckboxModule, NbToggleModule } from '@nebular/theme';
import { SharedService } from './shared.service';
import { CustomToggleRendererComponent } from './custom-toggle-renderer/custom-toggle-renderer.component';
import { CustomListRendererComponent } from './custom-list-renderer/custom-list-renderer';
import { CustomCompleterRendererComponent } from './custom-completer-renderer/custom-completer-renderer';
import { SharedUiStorybookModule } from '@tgx/shared/ui/storybook';
import { SharedUiStylesModule } from '@tgx/shared/ui/styles';
import { LetterCarouselComponent } from './letter-carousel/letter-carousel.component';

const components = [
  SpinnerComponent,
  TooltipErrorComponent,
  NotFoundComponent,
  ModalDeleteComponent,
  CustomDateRendererComponent,
  PaginationComponent,
  CustomToggleRendererComponent,
  CustomListRendererComponent,
  CustomCompleterRendererComponent,
  DecodePipe,
  LetterCarouselComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NbCardModule,
    NbMenuModule,
    NbButtonModule,
    NbCheckboxModule,
    NbToggleModule,
    MultiSelectModule,
    NgbModule,
    SharedUiStorybookModule,
    SharedUiStylesModule,
  ],
  declarations: [...components],
  exports: [...components],
  providers: [SharedService, CookieService],
})
export class SharedModule {}
