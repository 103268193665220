import { gql } from 'apollo-angular';

export const getAllBillScheduled = gql`
  query {
    getAllBillScheduled {
      id
      startDate
      endDate
      executionDate
      startingDate
      finishDate
      idBlob
      createdAt
      updatedAt
    }
  }
`;
