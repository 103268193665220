import { Table } from 'primeng/table';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ChangePaymentRequestService } from '../../../../services/_index-billing.services';
import { NotificationService } from './../../../../../../@core/services/_index-core.services';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'tgx-admin-billing-payment-requests-global',
  templateUrl: './payment-requests-global.component.html',
  styleUrls: ['./payment-requests-global.component.scss'],
})
export class BillingPaymentRequestsGlobalComponent implements OnInit {
  @ViewChild('dt', { static: false }) dataTable!: Table;
  header = [
    { label: 'ID', field: 'id' },
    { label: 'PartnerFrom', field: 'partnerFromName' },
    { label: 'PartnerTo', field: 'partnerToName' },
    { label: 'RateType', field: 'currentConnectionType' },
    { label: 'NextRateType', field: 'requestedConnectionType' },
    { label: 'Status', field: 'requestStatus' },
    { label: 'RequestUser', field: 'requestUser' },
    { label: 'ResponseUser', field: 'responseUser' },
    { label: 'CreatedAt', field: 'createdAt' },
    { label: 'UpdatedAt', field: 'updatedAt' },
  ];

  rateTypes = [
    { value: 'STD', label: 'STD' },
    { value: 'SUP', label: 'SUP' },
    { value: 'FREE', label: 'FREE' },
  ];

  statuses = [
    { value: 'ACCEPTED', label: 'ACCEPTED' },
    { value: 'PENDING', label: 'PENDING' },
    { value: 'REJECTED', label: 'REJECTED' },
  ];

  requestsSource: any[];

  isLoading: boolean;

  isEditor = false;

  constructor(
    private notificationService: NotificationService,
    private changePaymentRequestService: ChangePaymentRequestService,
    private datePipe: DatePipe,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.getRequests();
  }

  async getRequests() {
    this.isLoading = true;
    this.requestsSource = [];
    const requests: any[] = [];

    await this.changePaymentRequestService
      .getGlobalRequests()
      .then((rs) => {
        rs?.forEach((rq) => {
          requests.push({
            'id': rq.id,
            'partnerFromName': `${rq.partnerFromName} (${rq.partnerFromOrgCode})`,
            'partnerToName': `${rq.partnerToName} (${rq.partnerToOrgCode})`,
            'currentConnectionType': this.getRateType(rq.currentConnectionType),
            'requestedConnectionType': this.getRateType(rq.requestedConnectionType),
            'requestStatus': rq.requestStatus,
            'requestUser': rq.requestUser,
            'responseUser': rq.responseUser,
            'createdAt': this.datePipe.transform(rq.createdAt, 'dd-MM-yyyy'),
            'updatedAt': this.datePipe.transform(rq.updatedAt, 'dd-MM-yyyy'),
          });
        });
        this.requestsSource = [...requests];
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  getRateType(rateType: string) {
    switch (rateType) {
      case 'STANDARD':
        return 'STD';
      case 'STANDARD_SUPPLEMENT':
        return 'SUP';
      case 'FREE':
        return rateType;
    }
  }

  filterDT(value: any, field: string) {
    if (value) {
      this.dataTable.filter(value, field, 'contains');
    }
  }
}
