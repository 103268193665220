import { Component, OnInit } from '@angular/core';
import { LoadCSVService } from '../../../../services/_index-billing.services';
import { NotificationService } from '../../../../../../@core/services/notification.service';
import { WebAdminService } from '../../../../../../@core/services/web-admin.service';

@Component({
  selector: 'tgx-billing-load-connections',
  templateUrl: './connections-load.component.html',
  styleUrls: ['./connections-load.component.scss'],
})
export class BillingLoadConnectionsComponent implements OnInit {
  isLoading: boolean;
  isLoadingClone: boolean;
  isLoadingMigrate: boolean;

  newConnectionFile: any;
  cloneConnectionFile: any;
  migrateConnectionFile: any;

  isEditor: boolean;

  constructor(
    private notificationService: NotificationService,
    private loadCSVService: LoadCSVService,
    private webAdminService: WebAdminService,
  ) {}

  ngOnInit(): void {
    this.isEditor = this.webAdminService.isBillingEditorOrAdmin();
  }

  loadConnections(operationType: string) {
    let file: any;

    if (operationType === 'new') {
      file = this.newConnectionFile;
      this.isLoading = true;
    } else if (operationType === 'clone') {
      file = this.cloneConnectionFile;
      this.isLoadingClone = true;
    } else if (operationType === 'migrate') {
      file = this.migrateConnectionFile;
      this.isLoadingMigrate = true;
    }

    this.loadCSVService
      .loadConnections(operationType, file)
      .then((rs) => {
        this.notificationService.success(rs);
        this.isLoading = false;
        this.isLoadingClone = false;
        this.isLoadingMigrate = false;
        this.newConnectionFile = null;
        this.cloneConnectionFile = null;
        this.migrateConnectionFile = null;
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
        this.isLoading = false;
        this.isLoadingClone = false;
        this.isLoadingMigrate = false;
      });
  }

  onFileSelected(event, operationType: string) {
    const file: File = event.target.files[0];
    if (file && this.isValidCSVFile(file)) {
      if (operationType === 'new') {
        this.newConnectionFile = file;
      } else if (operationType === 'clone') {
        this.cloneConnectionFile = file;
      } else if (operationType === 'migrate') {
        this.migrateConnectionFile = file;
      }
    } else {
      this.notificationService.error('Invalid csv file.');
    }
  }

  isValidCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }
}
