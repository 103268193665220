import { NetworkComponent } from './network.component';
import { SharedModule } from './../../@core/shared/shared.module';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { NetworkRoutingModule } from './network-routing.module';
import { ProfileNetworkService } from './services/_index-network.services';
import { ProfilesModule } from './modules/_index-network-modules';
import { StaticEntitiesService } from './services/static-entities.service';
import { SubcategoriesModule } from './modules/subcategories/subcategories.module';
import { HelpToolsModule } from './modules/help-tools/help-tools.module';
import { FilestackService } from './services/filestack.service';
import { RegionNetworkService } from './services/region-network.services';

const SERVICES = [ProfileNetworkService, StaticEntitiesService, FilestackService, RegionNetworkService];

@NgModule({
  imports: [
    SharedModule,
    // Routing
    NetworkRoutingModule,
    //Custom modules
    ProfilesModule,
    HelpToolsModule,
    SubcategoriesModule,
  ],
  declarations: [NetworkComponent],
})
export class NetworkModule {
  static forRoot(): ModuleWithProviders<NetworkModule> {
    return {
      ngModule: NetworkModule,
      providers: [...SERVICES],
    };
  }
}
