<tgx-spinner [class]="'routingLoading'" [isLoading]="isLoading" [top]="'200px'"></tgx-spinner>
<nb-card size="tiny">
  <nb-card-header>Invoiced Data</nb-card-header>
  <nb-card-body *ngIf="!isLoading">
    <form [formGroup]="dateForm">
      <div class="form-group">
        <label class="mb-1" for="activationDateTime">Invoiced Month<span class="mandatory ml-1">*</span> </label>
        <p-calendar
          [appendTo]="'body'"
          baseZIndex="999"
          class="form-control p-0 border-0 w-100"
          formControlName="date"
          view="month"
          dateFormat="mm/yy"
          [showIcon]="true"
          inputId="icon"
          [readonlyInput]="true"
          placeholder="Select a month.."
        ></p-calendar>
      </div>
    </form>
    <div class="modal-footer mt-2">
      <button class="btn btn-md btn-danger" (click)="getCSV()" [disabled]="isLoading || dateForm.invalid">
        Download CSV
      </button>
    </div>
  </nb-card-body>
  <nb-card-body *ngIf="isLoading"> Your csv is being generated, please be patient.. </nb-card-body>
</nb-card>
