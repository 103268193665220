import { gql } from 'apollo-angular';

export const getAllMembersV2 = gql`
  query ($include_archived: Boolean) {
    admin {
      allMembers(where: { includeArchived: $include_archived }) {
        totalCount
        edges {
          node {
            deletedAt
            membersData {
              code
              isActive
              organizations {
                role
                organization {
                  code
                  label
                }
                services {
                  service
                  role
                }
              }
            }
          }
        }
      }
    }
  }
`;
