import { gql } from 'apollo-angular';

export const getSupplierOrgCode = gql`
  query ($supplierCode: ID!) {
    admin {
      supplier(where: { search: $supplierCode, searchBy: CODE }) {
        supplierData {
          owner {
            code
          }
        }
      }
    }
  }
`;
