import { gql } from 'apollo-angular';

export const archiveMember = gql`
  mutation archiveMember($memberCode: ID!) {
    admin {
      archiveMember(where: { search: $memberCode, searchBy: CODE }) {
        code
        adviseMessage {
          code
          description
        }
      }
    }
  }
`;
