import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModuleType } from '../../../../../../@core/interfaces/user-permissions';
import { NotificationService } from '../../../../../../@core/services/notification.service';
import { WebAdminService } from '../../../../../../@core/services/web-admin.service';
import { Subscription } from 'rxjs';
import { OrganizationSelector } from '../../../../interfaces/_index-entities.interfaces';
import { GroupContentContextService } from '../../../../services/group-content-context.service';
import { Router } from '@angular/router';
import { GroupContent } from '../../../../interfaces/models/group-content-context.interface';
import { GroupContentContextModalComponent } from '../group-content-context-modal/group-content-context-modal.component';

@Component({
  selector: 'tgx-admin-group-content-context-table',
  templateUrl: './group-content-context-table.component.html',
  styleUrls: ['./group-content-context-table.component.scss'],
})
export class GroupContentContextTableComponent implements OnInit, OnDestroy {
  selectedGroupContentContext: any[];
  selectedGroupContentContextAux: any[];
  clientName: string;

  isLoading: boolean;

  groupContentData: GroupContent[];
  groupContentDataLoadSource: any[];

  $subs: Subscription[] = [];

  orgSelected: OrganizationSelector;

  header = [
    { label: 'Group content', field: 'groupCode', placeholder: 'Group content' },
    { label: 'Context', field: 'contextCode', placeholder: 'Context' },
    { label: 'Options', field: 'Options', placeholder: 'Options' },
  ];

  constructor(
    private modalService: NgbModal,
    private webAdminService: WebAdminService,
    private notificationService: NotificationService,
    private GroupContentContextService: GroupContentContextService,
    private router: Router,
  ) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as {
      clientName: string;
    };

    this.clientName = state && state.clientName ? state.clientName : '';
  }

  /*   onCustom($event): void {
    switch ($event.action) {
      case 'show-suppliers':
        this.onShowSuppliers($event);
        break;
    }
  } */

  /*   async onShowSuppliers(event: any) {
    const provider: any = event.data;
    const navigationExtras: NavigationExtras = {
      state: {
        providerCode: provider.code,
      },
    };
    this.router.navigate(['/entities/suppliers'], navigationExtras);
  } */

  ngOnInit(): void {
    this.isLoading = true;
    this.buildOptions();
    this.retrieveAllGroupContentContext();

    //toggle activate/deactivate provider.
    /*     this.$subs.push(
      this.GroupContentContextService.onToggleTable$.subscribe((provider) => {
        if (provider) {
          const input = { search: provider.code, searchBy: 'CODE' };
          if (provider.isActive) {
            this.GroupContentContextService.activateProvider(input);
          } else {
            this.GroupContentContextService.deactivateProvider(input);
          }
        }
      })
    ); */
  }

  async retrieveAllGroupContentContext() {
    const allGroupContentContext = await this.GroupContentContextService.getAll();
    this.selectedGroupContentContext = [];
    this.selectedGroupContentContextAux = [];
    if (allGroupContentContext?.length > 0) {
      this.groupContentData = allGroupContentContext;
      this.buildGroupContentContexts();
    } else {
      this.isLoading = false;
    }
  }

  buildOptions() {
    this.webAdminService.emitUserTypevalue(ModuleType.ENTITIES);
  }

  buildGroupContentContexts() {
    this.groupContentDataLoadSource = [];

    this.groupContentData.forEach((mb) => {
      this.groupContentDataLoadSource.push({
        groupCode: mb.groupContentData.code,
        contextCode: mb.groupContentData.context.contextData.code,
      });
      this.isLoading = false;
    });
  }

  ngOnDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
  }

  onCreate(): void {
    const activeModal = this.modalService.open(GroupContentContextModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });
    activeModal.componentInstance.header = 'Add new Group Content | Context';
    activeModal.componentInstance.isCreating = true;

    const self = this;

    activeModal.result
      .then(async (res) => {
        if (typeof res === 'object') {
          if (res.adviseMessage && res.adviseMessage.length > 0) {
            throw new Error(res.adviseMessage[0].description);
          }

          this.notificationService.success('Group Content | Context was succesfully created.', 'Success');
          await self.retrieveAllGroupContentContext();
        }
      })
      .catch((error) => {
        this.notificationService.error(error);
      });
  }
}
