import { Injectable } from '@angular/core';
import { configLocalStorage } from '@tgx/shared/constants';
import { PlatformBrowserService } from '@tgx/shared/services';
import { BehaviorSubject } from 'rxjs';
import { JwtTools, PermissionTable, UserConfig } from '../utils/jwt-tools.utils';

const IamConfig: UserConfig = {
  claimOrgRole: 'org',
  claimMemberId: 'member_id',
  claimUrl: 'https://travelgatex.com/',
};

@Injectable({
  providedIn: 'root',
})
export class JwtPermissionsService {
  permissionTable = new BehaviorSubject<PermissionTable>(null);

  constructor(private platformBrowserService: PlatformBrowserService) {
    this.managePermissions();
  }

  async managePermissions() {
    const token = this.platformBrowserService.GetItemFromLocalStorage(configLocalStorage.idToken);
    if (token) {
      const permissionsTable = JwtTools(token, IamConfig);

      if (!permissionsTable.errorMessage) {
        this.platformBrowserService.SetItemInLocalStorage(
          configLocalStorage.adminPermissions,
          JSON.stringify(permissionsTable),
        );
        this.permissionTable.next(permissionsTable);
      }
    }
  }
}
