import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Dropdown } from 'primeng/dropdown';
import { SuppliersService } from '../../../../services/suppliers.service';
import { ClientsEntitiesService } from '../../../../services/clients-entities.service';
import { Options } from './../../../../../../@core/interfaces/_index.interfaces';
@Component({
  selector: 'tgx-admin-supplier-modal',
  templateUrl: './supplier-modal.component.html',
  styleUrls: ['./supplier-modal.component.scss'],
})
export class SupplierModalComponent implements OnInit {
  @Input() header: string;
  @Input() supplier: any;
  @Input() isCreating: boolean;
  @Input() organizations: any;
  @Input() providers: any;
  @Input() selectedOrg: any;
  @Input() selectedProvider: any;
  @Input() selectedGroupContentContext: any;
  @Input() groups: any;
  @Input() groupContent_context: any;
  @Input() serviceApi: Options;

  @ViewChild('groups_dd') groupsDropdown: Dropdown;

  isLoading: boolean;

  serviceApiEnum = [
    { label: 'HOTEL', code: '1' },
    { label: 'TRANSPORTATION', code: '2' },
    { label: 'CAR', code: '3' },
    { label: 'TRANSFERS', code: '4' },
    { label: 'ACTIVITIES', code: '5' },
    { label: 'VIRTUALACCOUNT', code: '6' },
    { label: 'PAYMENT', code: '7' },
    { label: 'PACKAGE', code: '8' },
  ];

  public supplierForm: UntypedFormGroup;
  currentGroups: any;

  constructor(
    private activeModal: NgbActiveModal,
    private ClientsEntitiesService: ClientsEntitiesService,
    private suppliersService: SuppliersService,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    if (this.selectedOrg) {
      this.loadOrganizationsGroups(this.selectedOrg.code);
    }

    this.supplierForm = this.fb.group({
      code: [this.supplier ? this.supplier.code : '', Validators.required],
      name: [this.supplier ? this.supplier.name : '', Validators.required],
      orgCode: this.fb.control(this.supplier?.owner || '0', Validators.required),
      provCode: this.fb.control(this.supplier?.providerCode || '0', Validators.required),
      isActive: [this.supplier ? this.supplier.isActive : false, Validators.required],
      groupContentCode: this.fb.control(this.supplier?.groupContentCode || '0', Validators.required),
      serviceApi: [this.supplier ? this.supplier.api : ''],
      sharedDefault: [this.supplier?.sharedDefault?.code || ''],
    });

    this.supplierForm.get('isActive').disable();

    if (!this.isCreating) {
      this.supplierForm.get('code').disable();
      this.supplierForm.get('orgCode').disable();
    }
  }

  closeModal() {
    this.activeModal.close(true);
  }

  async onSave() {
    let result = null;
    if (this.isCreating) {
      this.activeModal.close(
        await this.suppliersService.createSupplier({
          code: this.supplierForm.get('code').value,
          name: this.supplierForm.get('name').value,
          providerCode: this.supplierForm.get('provCode').value.code,
          organization: this.supplierForm.get('orgCode').value.code,
          isActive: this.supplierForm.get('isActive').value,
          groupContentCode: this.supplierForm.get('groupContentCode').value.code,
          serviceApi: this.supplierForm.get('serviceApi').value.code,
        }),
      );
    } else {
      const where = { search: this.supplierForm.get('code').value, searchBy: 'CODE' };
      const input = {
        name: this.supplierForm.get('name').value,
        providerCode: this.supplierForm.get('provCode').value.code,
        serviceApi: this.supplierForm.get('serviceApi').value.code,
        groupContentCode: this.supplierForm.get('groupContentCode').value.code,
      };

      //update sharedDefault
      if (this.supplier.sharedDefault !== this.supplierForm.get('sharedDefault').value) {
        if (this.supplierForm.get('sharedDefault').value !== '') {
          result = await this.suppliersService.setSupplierDefaultSharedAccess(
            where,
            this.supplierForm.get('sharedDefault').value,
          );
        } else {
          result = await this.suppliersService.removeSupplierDefaultSharedAccess(where);
        }
      }
      result = await this.suppliersService.updateSupplier(where, input);

      this.activeModal.close(result);
    }
  }

  async loadOrganizationsGroupsEvent(event) {
    if (this.isCreating) {
      if (event.value && event.value.code) {
        await this.loadOrganizationsGroups(event.value.code);
      } else {
        this.groups = [];
        this.groupsDropdown.placeholder = 'Empty groups';
      }
    }
  }

  async loadOrganizationsGroups(code) {
    this.groups = await this.ClientsEntitiesService.getSellerGroupsByOrgs(code);

    //retrieve current supplier's groups
    this.currentGroups = [];
    if (!this.isCreating) {
      this.currentGroups = await this.suppliersService.getGroupsBySupplierCode({ code_in: [this.supplier.code] });
    }

    this.groups.forEach((group_tmp) => {
      if (this.currentGroups.indexOf(group_tmp.code) !== -1) {
        this.supplierForm.get('groupCode').setValue(group_tmp);
      }
    });

    if (this.isCreating) {
      this.groupsDropdown.show();
    }
  }
}
