import { Apollo } from 'apollo-angular';
import { DocumentNode, print } from 'graphql';
import { first } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { GqlContextName } from './gql-context-name';
import { datadogLogs } from '@datadog/browser-logs';

@Injectable({
  providedIn: 'root',
})
export class GqlService {
  constructor(private apollo: Apollo) {}

  queryGateway<T>(query: DocumentNode, body?: any): Promise<T> {
    return this.query<T>(GqlContextName.GATEWAY, query, body);
  }

  queryData<T>(query: DocumentNode, body?: any): Promise<T> {
    return this.query<T>(GqlContextName.DATA, query, body);
  }

  queryPublicGateway<T>(query: DocumentNode, body?: any): Promise<T> {
    return this.query<T>(GqlContextName.GATEWAY_PUBLIC, query, body);
  }

  queryBilling<T>(query: DocumentNode, body?: any): Promise<T> {
    return this.query<T>(GqlContextName.BILLING, query, body);
  }

  queryIam<T>(query: DocumentNode, body?: any): Promise<T> {
    return this.query<T>(GqlContextName.IAM, query, body);
  }

  queryInsights<T>(query: DocumentNode, body?: any): Promise<T> {
    return this.query<T>(GqlContextName.INSIGHTS, query, body);
  }

  query<T>(gqlContext: GqlContextName, query: DocumentNode, body?: any): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.apollo
        .use(gqlContext)
        .watchQuery<T>({
          query: query,
          variables: body,
          fetchPolicy: 'network-only',
        })
        .valueChanges.pipe(first())
        .subscribe({
          next: (response) => {
            if (response && response.data) {
              resolve(response.data);
            } else {
              const loggedBody = {
                query: query?.loc?.source?.body,
                variables: body,
              };
              // console.error(response, loggedBody);
              reject({ ...((response || {}).error || response), errorSource: 'apollo', loggedBody: loggedBody });
            }
          },
          error: (error) => {
            const mutationDetails = {
              query: print(query), // Convierte el DocumentNode a una cadena
              variables: body,
            };

            datadogLogs.logger.error('Error sending GraphQL query', {
              details: {
                file: 'gql.service.ts',
                method: 'query',
                description: error.message,
                mutation: JSON.stringify(mutationDetails),
              },
            });

            const loggedBody = {
              query: query?.loc?.source?.body,
              variables: body,
            };
            reject({ ...((error || {}).error || error), errorSource: 'apollo', loggedBody: loggedBody });
          },
        });
    });
  }

  mutationGateway<T>(mutation: DocumentNode, body?: any): Promise<T> {
    return this.mutation<T>(GqlContextName.GATEWAY, mutation, body);
  }

  mutationBilling<T>(mutation: DocumentNode, body?: any): Promise<T> {
    return this.mutation<T>(GqlContextName.BILLING, mutation, body);
  }

  mutationIam<T>(mutation: DocumentNode, body?: any): Promise<T> {
    return this.mutation<T>(GqlContextName.IAM, mutation, body);
  }

  mutationData<T>(mutation: DocumentNode, body?: any): Promise<T> {
    return this.mutation<T>(GqlContextName.DATA, mutation, body);
  }

  mutation<T>(gqlContext: GqlContextName, mutation: DocumentNode, body?: any) {
    return new Promise<T>((resolve, reject) => {
      this.apollo
        .use(gqlContext)
        .mutate<T>({
          mutation: mutation,
          variables: body,
        })
        .subscribe({
          next: (response) => {
            // TODO: Handle response
            resolve(response.data as T);
          },
          error: (error) => {
            const mutationDetails = {
              query: print(mutation), // Convierte el DocumentNode a una cadena
              variables: body,
            };

            datadogLogs.logger.error('Error sending GraphQL mutation', {
              details: {
                file: 'gql.service.ts',
                method: 'mutation',
                description: error.message,
                mutation: JSON.stringify(mutationDetails),
              },
            });

            reject(error);
          },
        });
    });
  }
}
