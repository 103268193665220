import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'tgx-merge-modal',
  templateUrl: './merge-modal.component.html',
  styleUrls: ['./merge-modal.component.scss'],
})
export class MergeModalComponent {
  isOpen = false;

  tHead = ['Hotel master', 'Country', 'Geocode', 'CP', 'Address'];

  tBody = [
    {
      master: 'Pension manolete',
      country: 'Spain',
      geo: '42.23, -12.35',
      cp: '08080',
      address: 'Carrer Balmes, 32, 08015 Barcelona',
      child: true,
      show_child: false,
    },
    {
      master: 'Don Juan',
      country: 'Spain',
      geo: '42.23, -12.35',
      cp: '08080',
      address: 'Carrer Balmes, 32, 08015 Barcelona',
      child: true,
      show_child: false,
    },
    {
      master: 'Hotel Catalonia XII',
      country: 'Spain',
      geo: '42.23, -12.35',
      cp: '08080',
      address: 'Carrer Balmes, 32, 08015 Barcelona',
      child: true,
      show_child: false,
    },
  ];

  constructor(private modalService: NgbModal) {}

  close() {
    this.modalService.dismissAll();
    console.log(this.isOpen);
  }
}
