<div class="xen-link" [ngClass]="classes">
  <a
    *ngIf="!href"
    [routerLink]="routerLink"
    [queryParams]="queryParams"
    routerLinkActive="router-link-active"
    [ngClass]="aClasses"
    (click)="onClickEvent($event)"
    >{{ label }}</a
  >
  <a *ngIf="href" [href]="href" [target]="target" [ngClass]="aClasses" (click)="onClickEvent($event)">{{ label }}</a>
  <i class="{{ icon_snippet }} xen-link__icon" *ngIf="icon"></i>
</div>
