import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[focusInvalidInput]',
})
export class FormAutoFocusDirective {
  constructor(private el: ElementRef) {}

  @HostListener('ngSubmit')
  public onFormSubmit(): void {
    const invalidControl = this.el.nativeElement.querySelector('input.ng-invalid');
    if (invalidControl) {
      invalidControl.focus();
    }
  }
}
