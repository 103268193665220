import { Component, Input, OnInit } from '@angular/core';
import { DataBaseSettings, LanguageSettings } from '../../../../interfaces/_index-content.interfaces';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'tgx-billing-scheduler-info',
  templateUrl: './scheduler-info.component.html',
  styleUrls: ['./scheduler-info.component.scss'],
})
export class SchedulerInfoComponent implements OnInit {
  @Input() stats: any;
  @Input() title: string;

  mainSetting: any;
  langSetting: any;

  mainHeader = [
    { label: 'Status', field: 'status' },
    { label: 'Updated', field: 'updated' },
    { label: 'Not modified', field: 'notModified' },
    { label: 'New', field: 'new' },
    { label: 'Deleted', field: 'deleted' },
    { label: 'Errors', field: 'dbErrors' },
  ];

  langHeader: string[];

  mainSource: any[];
  langSource: any[];

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.mainSetting = DataBaseSettings;
    this.langSetting = LanguageSettings;

    this.mainSource = [
      {
        'status': this.stats.Status,
        'updated': this.stats.Updated,
        'notModified': this.stats.NotModified,
        'new': this.stats.New,
        'deleted': this.stats.Deleted,
        'dbErrors': this.stats.DatabaseErrors,
      },
    ];

    this.langSetting.columns = {};

    this.langSetting.columns['Language'] = {
      title: 'Language',
      type: 'string',
    };

    for (let i = 0, len = Object.keys(this.stats.languageColumns).length; i < len; i++) {
      this.langSetting.columns[this.stats.languageColumns[i]] = {
        title: this.stats.languageColumns[i],
        type: 'string',
      };
    }

    this.langSource = [];
    this.stats.statsLangArray.forEach((s) => {
      const row = {};
      row['Language'] = s.language;

      for (let i = 0, len = Object.keys(this.stats.languageColumns).length; i < len; i++) {
        if (this.stats.languageColumns[i] !== 'Language') {
          row[this.stats.languageColumns[i]] = s.stats[this.stats.languageColumns[i]];
        }
      }
      this.langSource.push(row);
    });

    this.langHeader = Object.keys(this.langSource[0]);
  }

  closeModal() {
    this.activeModal.close(true);
  }
}
