import { gql } from 'apollo-angular';

export const getOrgs = gql`
  query getOrganizationsSimple {
    admin {
      allOrganizations {
        edges {
          node {
            organizationsData {
              id
              code
              label
            }
          }
        }
      }
    }
  }
`;
