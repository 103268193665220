<div class="modal-header">
  <span>{{ header }}</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <nb-card>
    <nb-card-header> Data </nb-card-header>
    <nb-card-body>
      <form [formGroup]="discountForm">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="startDate"
                >StartDate
                <span class="mandatory ml-1">*</span>
              </label>
              <p-calendar
                [appendTo]="'body'"
                baseZIndex="999"
                class="form-control p-0 border-0 w-100"
                formControlName="startDate"
                dateFormat="dd/mm/yy"
                [showIcon]="true"
                inputId="icon"
                [readonlyInput]="true"
                placeholder="Enter a date.."
              ></p-calendar>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="endDate"
                >EndDate
                <span class="mandatory ml-1">*</span>
              </label>
              <p-calendar
                [appendTo]="'body'"
                baseZIndex="999"
                class="form-control p-0 border-0 w-100"
                formControlName="endDate"
                dateFormat="dd/mm/yy"
                [showIcon]="true"
                inputId="icon"
                [readonlyInput]="true"
                placeholder="Enter a date.."
              ></p-calendar>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-3">
            <div class="form-group">
              <label class="mb-1" for="enabled">Enabled</label>
              <nb-checkbox class="form-control" name="enabled" formControlName="enabled"></nb-checkbox>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label class="mb-1" for="id"
                >Discount (%)
                <span class="mandatory ml-1">*</span>
              </label>
              <input type="number" class="form-control" name="amount" formControlName="amount" />
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="id">
                Payment Type
                <span class="mandatory ml-1">*</span>
              </label>
              <p-dropdown
                [options]="paymentTypes"
                [appendTo]="'body'"
                baseZIndex="999"
                formControlName="paymentType"
                optionLabel="label"
                filterBy="label"
              >
                <ng-template pTemplate="selectedItem">
                  <div *ngIf="discountForm.get('paymentType').value">
                    <div>{{ discountForm.get('paymentType').value.label }}</div>
                  </div>
                </ng-template>
                <ng-template let-ci pTemplate="ci">
                  <div>
                    <div>{{ ci.label }}</div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <div class="form-group">
              <label class="mb-1" for="id">
                ContractConnection
                <span class="mandatory ml-1">*</span>
              </label>
              <p-dropdown
                formControlName="ccId"
                [options]="connectionSuggestion"
                placeholder="Select connection"
                optionLabel="title"
                appendTo="body"
                filter="true"
                baseZIndex="999"
              >
              </p-dropdown>
            </div>
          </div>
        </div>
      </form>
    </nb-card-body>
  </nb-card>
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()">
    {{ ended ? 'Close' : isEditor ? 'Cancel' : 'Close' }}
  </button>
  <button *ngIf="isEditor && !ended" class="btn btn-md btn-primary" [disabled]="!validForm()" (click)="onSave()">
    {{ isCreating ? 'Create' : 'Update' }}
  </button>
</div>
