import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'xen-btn',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() type: 'primary' | 'secondary' | 'flat' = 'primary';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() label: string;
  @Input() id: string;
  @Input() full_width: boolean;
  @Input() icon: boolean;
  @Input() icon_placement: 'left' | 'right' = 'left';
  @Input() icon_snippet: string;
  @Input() disabled: boolean;
  @Input() loading: boolean;
  @Input() skin: 'blue' | 'gray' | 'green' | 'red' = 'blue';
  @Input() tooltip_text: string;
  @Input() tooltip_placement: string = 'top';

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  constructor() {}

  public get classes(): Array<string> {
    return [
      'xen-btn',
      `xen-btn--${this.type}`,
      `xen-btn--${this.size}`,
      this.icon && this.icon_placement ? `xen-btn--icon-${this.icon_placement}` : '',
      this.full_width ? 'is-full' : '',
      this.loading ? 'is-loading' : '',
      this.label ? 'px-3' : '',
      `${this.skin}-skin`,
    ];
  }

  ngOnInit(): void {}

  onClickEvent(event) {
    this.onClick.emit(event);
  }
}
