import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GroupContentContextService } from '../../../../services/group-content-context.service';

@Component({
  selector: 'tgx-admin-group-content-context-modal',
  templateUrl: './group-content-context-modal.component.html',
  styleUrls: ['./group-content-context-modal.component.scss'],
})
export class GroupContentContextModalComponent implements OnInit {
  @Input() header: string;
  @Input() provider: any;
  @Input() isCreating: boolean;

  isLoading: boolean;

  public groupContentContextForm: UntypedFormGroup;

  constructor(
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private GroupContentContextService: GroupContentContextService,
  ) {}

  ngOnInit(): void {
    this.groupContentContextForm = this.fb.group({
      groupCode: [''],
      contextCode: [''],
    });
  }

  closeModal() {
    this.activeModal.close(true);
  }

  async onSave() {
    if (this.isCreating) {
      this.activeModal.close(
        await this.GroupContentContextService.createGroupContent({
          code: this.groupContentContextForm.get('groupCode').value,
          contextCode: this.groupContentContextForm.get('contextCode').value,
        }),
      );
    } else {
      this.activeModal
        .close
        //console.log("Update Group Content | Context")
        /* await this.GroupContentContextService.updateProvider({
            code: this.groupContentContextForm.get('code').value,
            name: this.groupContentContextForm.get('name').value,
            isActive: this.groupContentContextForm.get('isActive').value,
          }) */
        ();
    }
  }
}
