<nb-card>
  <nb-card-header> Billing Accounts </nb-card-header>

  <nb-card-body>
    <div *ngIf="isEditor" class="mb-3">
      <p-button label="Create" icon="pi pi-plus" (onClick)="onCreate()"></p-button>
    </div>
    <p-table
      #dt
      [value]="billingAccountsLoadSource"
      [paginator]="false"
      [rows]="billingAccountsLoadSource?.length"
      [showCurrentPageReport]="false"
      styleClass="p-datatable-gridlines p-datatable-striped"
      [tableStyle]="{ 'min-width': '50rem' }"
      [resizableColumns]="true"
      columnResizeMode="expand"
      [loading]="isLoading"
      loadingIcon="pi pi-spin pi-spinner"
      editMode="row"
      selectionMode="single"
      [(selection)]="selectedBA"
      dataKey="id"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let head of header">
            {{ head.label }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-account let-editing="editing">
        <tr [pSelectableRow]="account" [pEditableRow]="account">
          <td>{{ account.id }}</td>
          <td>{{ account.name }}</td>
          <td>{{ account.countryCode }}</td>
          <td>{{ account.fiscalAddress }}</td>
          <td>{{ account.odooCode }}</td>
          <td>
            <i
              title="View Details"
              class="icon"
              [ngClass]="{ 'nb-search': !isEditor, 'nb-edit': isEditor }"
              (click)="onEdit(account)"
            ></i>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="10" class="text-center">No billing accounts found.</td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>

<tgx-billing-account-contact-table
  [isEditor]="isEditor"
  [baId]="selectedBA?.id"
  *ngIf="selectedBA"
  [selectedBA]="selectedBA"
>
</tgx-billing-account-contact-table>
