import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Contract, Partner, ContractConnectionDiscount } from '../../../../../interfaces/_index.billing.interfaces';
import {
  PartnerService,
  ContractService,
  ContractConnectionDiscountService,
} from '../../../../../services/_index-billing.services';
import { NotificationService, WebAdminService } from './../../../../../../../@core/services/_index-core.services';
import { OrganizationSelector } from './../../../../../../entities/interfaces/_index-entities.interfaces';
import { Options } from './../../../../../../../@core/interfaces/_index.interfaces';
import { Table } from 'primeng/table';
import { BillingConnectionDiscountModalComponent } from '../connection-discount-modal/connection-discount-modal.component';

@Component({
  selector: 'tgx-admin-billing-connection-discount-table',
  templateUrl: './connection-discount-table.component.html',
  styleUrls: ['./connection-discount-table.component.scss'],
})
export class BillingConnectionDiscountTableComponent implements OnInit, OnDestroy {
  @ViewChild('dt', { static: false }) dataTable!: Table;
  $subs: Subscription[] = [];

  header = [
    { label: 'ID', field: 'id' },
    { label: 'StartDate', field: 'startDate' },
    { label: 'EndDate', field: 'endDate' },
    { label: 'Enabled', field: 'enabled' },
    { label: 'Amount', field: 'amount' },
    { label: 'PaymentType', field: 'paymentType' },
    { label: 'ContractConnectionId', field: 'contractConnectionId' },
    { label: 'Buyer', field: 'buyer' },
    { label: 'Seller', field: 'seller' },
    { label: 'Options', field: 'Options' },
  ];

  discountsLoadSource: any[];

  contracts: Contract[];

  discounts: ContractConnectionDiscount[];

  isLoading: boolean;

  orgSelected: OrganizationSelector;
  partner: Partner;

  contractsSelector: Options[] = [{ code: null, label: 'Without contracts' }];
  selectedContract: Options;

  isEditor = false;

  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private webAdminService: WebAdminService,
    private partnerService: PartnerService,
    private contractService: ContractService,
    private contractConnectionDiscountService: ContractConnectionDiscountService,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;

    this.buildOptions();

    this.$subs.push(
      this.webAdminService.orgSelected.subscribe(async (orgSelected) => {
        if (orgSelected?.code) {
          this.orgSelected = orgSelected;
          await this.partnerService.getPartner(orgSelected.code).then((partner) => {
            if (partner) {
              this.partner = partner;
              this.getContracts(this.partner.orgCode);
            } else {
              this.partner = null;
              this.isLoading = false;
            }
          });
        }
      }),
    );
  }

  ngOnDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
  }

  buildOptions() {
    this.isEditor = this.webAdminService.isBillingEditorOrAdmin();
  }

  getContracts(orgCode: string) {
    if (orgCode) {
      this.contractService
        .getContracts(orgCode)
        .then(async (cnts) => {
          this.contracts = cnts;
          if (this.contracts?.length > 0) {
            this.contractsSelector = [];
            this.buildSelector();
            this.selectedContract = this.contractsSelector[0];
            await this.getDiscounts();
          }
          if (!this.selectedContract) {
            this.withoutContracts();
          }
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  buildSelector() {
    if (this.contracts?.length > 0) {
      this.contracts = this.contracts.filter((x) => x.modelType === 'CM23');
      this.contracts.forEach((c) => {
        this.contractsSelector.push({
          code: c.id.toString(),
          label:
            '(' +
            c.id +
            ') ' +
            c.partnerType +
            (c.modelType ? ' - ' + c.modelType : '') +
            (c.ContractType ? ' - ' + c.ContractType.name : ''),
        });
      });
    }
  }

  withoutContracts() {
    this.contractsSelector = [];
    this.contractsSelector.push({ code: null, label: 'Without contracts' });
  }

  async getDiscounts() {
    this.isLoading = true;
    if (this.dataTable) {
      this.dataTable.clearFilterValues();
    }

    this.discountsLoadSource = [];

    if (this.selectedContract?.code) {
      await this.contractConnectionDiscountService
        .getContractConnectionDiscounts(Number(this.selectedContract?.code))
        .then((rs) => {
          this.discounts = rs;
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
          this.isLoading = false;
        });

      if (this.discounts?.length > 0) {
        let partnerIds: number[] = [];

        this.discounts?.forEach((p) => {
          let index = partnerIds.findIndex((x) => x === p.ContractConnection?.Connection?.buyer);
          if (index === -1) {
            partnerIds.push(p.ContractConnection?.Connection?.buyer);
          }
          index = partnerIds.findIndex((x) => x === p.ContractConnection?.Connection?.seller);
          if (index === -1) {
            partnerIds.push(p.ContractConnection?.Connection?.seller);
          }
        });

        let partners: Partner[] = [];
        await this.partnerService
          .getPartnersByIds(partnerIds)
          .then((rs) => {
            partners = rs;
          })
          .catch((err) => {
            this.notificationService.handleGatewayAndGraphqlErrors(err);
          });

        this.discounts?.forEach((p) => {
          const { id, startDate, endDate, enabled, amount, paymentType, contractConnection } = p;

          let buyer = '';
          let seller = '';
          let index = partners.findIndex((x) => x.id === p.ContractConnection?.Connection?.buyer);
          if (index > -1) {
            buyer = `${partners[index].name} (${partners[index].orgCode})`;
          }

          index = partners.findIndex((x) => x.id === p.ContractConnection?.Connection?.seller);
          if (index > -1) {
            seller = `${partners[index].name} (${partners[index].orgCode})`;
          }

          this.discountsLoadSource.push({
            id,
            startDate,
            endDate,
            enabled,
            amount,
            paymentType,
            contractConnection,
            buyer,
            seller,
          });
        });
        this.discountsLoadSource = [...this.discountsLoadSource];
        this.discountsLoadSource.sort((a, b) => (a.id < b.id ? 1 : -1));

        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    } else {
      this.isLoading = false;
    }
  }

  async onCreate(): Promise<void> {
    if (this.selectedContract.code === null) {
      this.notificationService.warning('You can not create a discount, first you must to create a contract.');
      return;
    }

    const activeModal = this.modalService.open(BillingConnectionDiscountModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = 'Create Contract Connection Discount';
    activeModal.componentInstance.contractId = Number(this.selectedContract.code);
    activeModal.componentInstance.isEditor = this.isEditor;

    const self = this;
    activeModal.result.then(async (res) => {
      // on close

      if (typeof res === 'boolean' && res) {
        //todo do nothing at the moment
      } else if (typeof res === 'boolean' && !res) {
        //todo do nothing at the moment
      }
      // OK
      else if (typeof res === 'object') {
        await self.getDiscounts();
      }
    });
  }

  async onEdit(event): Promise<void> {
    const discount: ContractConnectionDiscount = event;

    const activeModal = this.modalService.open(BillingConnectionDiscountModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = (this.isEditor ? 'Edit ' : 'View ') + 'Contract Connection Discount';
    activeModal.componentInstance.discount = discount;
    activeModal.componentInstance.contractId = Number(this.selectedContract.code);
    activeModal.componentInstance.isEditor = this.isEditor;

    const self = this;
    activeModal.result.then(async (res) => {
      // on close

      if (typeof res === 'boolean' && res) {
        //todo do nothing at the moment
      } else if (typeof res === 'boolean' && !res) {
        //todo do nothing at the moment
      }
      // OK
      else if (typeof res === 'object') {
        await self.getDiscounts();
      }
    });
  }
}
