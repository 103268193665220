<xen-modal subtitle="Please make sure you think about the action" modal_title="Validate Master">
  <div xenModalBody>
    <p class="mb-0 text-center">
      Are you sure to validate
      <span>{{ nonValidate.name }}, {{ findNameCountry(nonValidate.country) }}, {{ nonValidate.address }}</span>
      ?
    </p>
  </div>
  <div xenModalFooter>
    <div class="d-flex align-items-center gap-2">
      <xen-btn (click)="close()" label="Cancel" size="medium" skin="gray" type="flat"></xen-btn>
      <xen-btn
        (click)="validateMaster(nonValidate.id)"
        label="Validate this master"
        size="medium"
        skin="green"
        type="primary"
      ></xen-btn>
    </div>
  </div>
</xen-modal>
