import { gql } from 'apollo-angular';

export const createAccess = gql`
  mutation createAccess($data: AccessCreateInput!) {
    admin {
      createAccess(data: $data) {
        code
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
