import { gql } from 'apollo-angular';

export const getMemberRole2 = gql`
  query getMemberRole($where: MemberWhereUniqueInput!) {
    admin {
      member(where: $where) {
        deletedAt
        membersData {
          code
          label
          isActive
          organizations {
            role
            organization {
              code
              label
            }
            services {
              service
              role
            }
          }
        }
        adviseMessage {
          code
          level
          description
        }
      }
    }
  }
`;
