import { gql } from 'apollo-angular';

export const unArchiveClient = gql`
  mutation ($where: ClientWhereUniqueInput!) {
    admin {
      unArchiveClient(where: $where) {
        code
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
