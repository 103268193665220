import { Component } from '@angular/core';

@Component({
  selector: 'xen-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent {
  rangeDates: Date[];

  constructor() {}
}
