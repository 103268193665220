import { gql } from 'apollo-angular';

export function getOrganizationsSimple(codes = ''): any {
  return gql`
  query getOrganizationsSimple {
    admin {
      allOrganizations${codes ? '(codes: "' + codes + '")' : ''}{
        edges {
          node {
            code
            organizationsData {
              id
              code
              label
              blacklisted
							isBuyer
							isSeller
            }
          }
        }
      }
    }
  }`;
}
