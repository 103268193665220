import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  NgbActiveModal,
  NgbDatepickerModule,
  NgbModule,
  NgbPopoverModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { MarkdownModule } from 'ngx-markdown';
import { RouterModule } from '@angular/router';
import { AlertComponent } from './components/alert/alert.component';
import { BadgeComponent } from './components/badge/badge.component';
import { ButtonComponent } from './components/button/button.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { InputComponent } from './components/input/input.component';
import { LinkComponent } from './components/link/link.component';
import { ModalComponent } from './components/modal/modal.component';
import { MultiselectDropdownComponent } from './components/multiselect-dropdown/multiselect-dropdown.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { RadioComponent } from './components/radio/radio.component';
import { SwitchComponent } from './components/switch/switch.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TagComponent } from './components/tag/tag.component';
import { TitlePageComponent } from './components/title-page/title-page.component';
import { TitleSectionComponent } from './components/title-section/title-section.component';
import { ToastComponent } from './components/toast/toast.component';
import { SelectComponent } from './components/select/select.component';
import { IconAppComponent } from './components/icon-app/icon-app.component';
import { SubtitlePageComponent } from './components/subtitle-page/subtitle-page.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { TableComponent } from './components/table/table.component';
import { StepComponent } from './components/step/step.component';
import { MetaInfoComponent } from './components/meta-info/meta-info.component';
import { MetaAppComponent } from './components/meta-app/meta-app.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { MetaHelpComponent } from './components/meta-help/meta-help.component';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PopoverComponent } from './components/popover/popover.component';
import { SliderComponent } from './components/slider/slider.component';
import { SliderModule } from 'primeng/slider';
import { SummaryComponent } from './components/summary/summary.component';
import { ScoreCardComponent } from './components/score-card/score-card.component';
import { MetaLinksComponent } from './components/meta-links/meta-links.component';
import { SubtitleSectionComponent } from './components/subtitle-section/subtitle-section.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    NgbTooltipModule,
    NgbDatepickerModule,
    ReactiveFormsModule,
    RouterModule,
    MarkdownModule,
    MultiSelectModule,
    NgbPopoverModule,
    SliderModule,
    CalendarModule,
  ],
  declarations: [
    ButtonComponent,
    InputComponent,
    PaginationComponent,
    CheckboxComponent,
    RadioComponent,
    SwitchComponent,
    ModalComponent,
    AlertComponent,
    BadgeComponent,
    TitlePageComponent,
    TabsComponent,
    MultiselectDropdownComponent,
    LinkComponent,
    TagComponent,
    TitleSectionComponent,
    ToastComponent,
    SelectComponent,
    IconAppComponent,
    SubtitlePageComponent,
    TextareaComponent,
    TableComponent,
    StepComponent,
    MetaAppComponent,
    MetaInfoComponent,
    EmptyStateComponent,
    DatepickerComponent,
    MetaHelpComponent,
    PopoverComponent,
    SliderComponent,
    SummaryComponent,
    ScoreCardComponent,
    MetaLinksComponent,
    SubtitleSectionComponent,
  ],
  exports: [
    ButtonComponent,
    InputComponent,
    PaginationComponent,
    CheckboxComponent,
    RadioComponent,
    SwitchComponent,
    ModalComponent,
    AlertComponent,
    BadgeComponent,
    TitlePageComponent,
    TabsComponent,
    MultiselectDropdownComponent,
    LinkComponent,
    TagComponent,
    TitleSectionComponent,
    ToastComponent,
    SelectComponent,
    IconAppComponent,
    SubtitlePageComponent,
    TextareaComponent,
    TableComponent,
    StepComponent,
    MetaAppComponent,
    MetaInfoComponent,
    EmptyStateComponent,
    DatepickerComponent,
    MetaHelpComponent,
    PopoverComponent,
    SliderComponent,
    SummaryComponent,
    ScoreCardComponent,
    MetaLinksComponent,
    SubtitleSectionComponent,
  ],
  providers: [FormGroupDirective, NgbActiveModal],
})
export class SharedUiStorybookModule {}
