import { Component, Input, OnChanges, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'xen-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss'],
})
export class StepComponent implements OnChanges, OnInit {
  @Input() label: string;
  @Input() edit: boolean;
  @Input() current: boolean;
  @Input() disabled: boolean;
  @Input() status: 'success' | 'warning' | 'error' | 'default' = 'default';
  @Input() icon: string;
  @Input() level: 'parent' | 'child' = 'parent';

  ngOnInit(): void {
    this.iconStatus();
  }
  ngOnChanges() {
    this.iconStatus();
  }

  public get stepClasses(): Array<string> {
    return [
      `item--${this.status || 'default'}`,
      this.disabled ? 'is-disabled' : '',
      this.current && (!this.status || this.status === 'default') ? 'is-current' : '',
      `is-${this.level || 'parent'}`,
    ];
  }

  iconStatus() {
    if (this.status === 'success') {
      this.icon = 'fas fa-check';
    }
    if (this.status === 'warning') {
      if (this.level === 'child') {
        this.icon = 'fas fa-circle-exclamation';
      } else {
        this.icon = 'fas fa-exclamation';
      }
    }
    if (this.status === 'error') {
      this.icon = 'fas fa-xmark';
    }
  }
}
