import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ProfileUpdateInput } from '../../../../interfaces/_index-network.interfaces';
import { ProfileNetworkService } from '../../../../services/profile-network.service';
import { ModuleType } from './../../../../../../@core/interfaces/_index.interfaces';
import { NotificationService, WebAdminService } from './../../../../../../@core/services/_index-core.services';
import { OrganizationSelector } from './../../../../../../@core/shared/interfaces/_index-shared-interfaces';
import { ProfileModalComponent } from './../profiles-modal/profile-modal.component';
import { PlatformInfoService } from '@tgx/shared/services';
import { ActivatedRoute } from '@angular/router';
import { Table } from 'primeng/table';
import { ngxCsv } from 'ngx-csv';
import { ProfileCsvData } from '../../../../interfaces/models/profile-csv-data.interface';
import { hsCountryListIsoCodes } from '@tgx/shared/utils';
import { Profile } from '@tgx/shared/interfaces';
import { Status as ProfileStatus } from '@tgx/shared/enums';

@Component({
  selector: 'tgx-profiles-table',
  templateUrl: './profiles-table.component.html',
  styleUrls: ['./profiles-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfilesTableComponent implements OnInit, OnDestroy {
  categoriesEnum = [];
  subCategoriesEnum = {};

  profilesSource: any[];
  profilesCsvData: ProfileCsvData[];
  tHeadCsv: string[] = [
    'PARTNER TYPE',
    'CODE',
    'NAME',
    'COUNTRY',
    'STATUS',
    'BUYER PARTNER STATUS',
    'SELLER PARTNER STATUS',
    'BUYER CATEGORY',
    'SELLER CATEGORY',
    'BUYER SUB CATEGORY',
    'SELLER SUB CATEGORY',
    'BUYER INTEGRATION TYPE',
    'SELLER INTEGRATION TYPE',
    'IS DEMAND BRIDGE',
    'IS SUPPLY BRIDGE',
    'BUYER TECH BRIDGE',
    'SELLER TECH BRIDGE',
  ];
  isDowloading: boolean = false;
  isLoading: boolean;

  $subs: Subscription[] = [];
  organizations: OrganizationSelector[] = [];

  isFiltering = false;

  orderType: string;
  orderColumn: string;

  @ViewChild('dt') table: Table;

  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private webAdminService: WebAdminService,
    private profileService: ProfileNetworkService,
    public platformInfoService: PlatformInfoService,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.webAdminService.emitUserTypevalue(ModuleType.NETWORK);
    this.initialization();
    this.buildCategoriesEnum();

    this.route.queryParamMap.subscribe((params) => {
      if (params.has('order-type')) {
        this.orderType = params.get('order-type');
      }
      if (params.has('order-column')) {
        this.orderColumn = params.get('order-column');
      }
    });
  }

  initialization() {
    this.organizations = [];

    this.$subs.push(
      this.webAdminService.allOrgs.subscribe((orgs) => {
        if (orgs?.length > 0) {
          this.organizations = orgs.map((o) => {
            return { ...o, label: `${o.label} (${o.code})` };
          });
        }
        this.retrieveTotalProfiles();
      }),
    );
  }

  retrieveTotalProfiles() {
    this.profileService
      .getTotalProfiles()
      .then((prf) => {
        if (prf) {
          this.getProfilesSource(prf);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  ngOnDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
    this.profileService.setProfilesCodes(null);
  }

  getProfilesSource(profiles: Profile[]) {
    this.profilesSource = [];

    profiles.forEach((prf) => {
      if (prf) {
        this.profilesSource.push({
          'ownerCode': prf.profileData.owner?.code,
          'crmId': prf.profileData.crmId,
          'name': prf.profileData.name,
          'createdAt': this.formatDate(new Date(prf.createdAt)),
          'updatedAt': this.formatDate(new Date(prf.updatedAt)),
          'published':
            prf.profileData?.status === ProfileStatus.DRAFT || prf.profileData?.status === ProfileStatus.ARCHIVED
              ? false
              : true,
        });
      }
    });
    this.isLoading = false;
  }

  async onEdit(event) {
    const profile = event;

    const profilesArray: Profile[] = await this.profileService.getProfiles({
      ownerCode: { equals: profile.ownerCode },
    });

    const activeModal = this.modalService.open(ProfileModalComponent, {
      size: 'xl',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = 'Edit Profile';
    activeModal.componentInstance.profile = profilesArray[0].profileData;
    activeModal.componentInstance.orgCode = profile.ownerCode;
    activeModal.componentInstance.prfCode = profilesArray[0].code;
    activeModal.componentInstance.categoriesEnum = this.categoriesEnum;
    activeModal.componentInstance.subCategoriesEnum = this.subCategoriesEnum;

    const self = this;
    activeModal.result.then((res) => {
      if (typeof res === 'object') {
        self.ngOnInit();
      }
    });
  }

  buildCategoriesEnum() {
    this.categoriesEnum = [];
    this.profileService
      .getCategories()
      .then((response: any) => {
        const categories = response.map((cat) => cat.categoryData);
        categories.forEach((cat) => {
          this.buildSubCategoriesEnum(cat);
          this.categoriesEnum.push({
            label: cat.code.split(/(?=[A-Z])/).join(' '),
            value: cat.code,
          });
        });
        this.categoriesEnum.unshift({ label: '', value: '' });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  buildSubCategoriesEnum(category: any) {
    const subcategories = category.subCategories.edges.map((subCat) => subCat.node.code);

    this.subCategoriesEnum[category.code] = [];
    subcategories.forEach((subCat) => {
      this.subCategoriesEnum[category.code].push({ label: subCat, value: subCat });
    });
  }

  onDateSelect(value, dateName) {
    this.table.filter(this.formatDate(value), dateName, 'equals');
  }

  formatDate(date) {
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = '0' + month;
    }

    if (day < 10) {
      day = '0' + day;
    }

    return date.getFullYear() + '-' + month + '-' + day;
  }

  onTogglePublishedProfile(profile) {
    const profileUpdateInput: ProfileUpdateInput = {};
    profileUpdateInput['status'] = profile?.published ? ProfileStatus.PUBLISHED : ProfileStatus.DRAFT;

    this.profileService
      .updateProfile(profileUpdateInput, { ownerCode: profile.ownerCode })
      .then((rs) => {
        this.notificationService.success('Profile successfully updated', 'Success');
      })
      .catch((err) => {
        console.log(err);
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      });
  }

  async downloadCsv() {
    this.isDowloading = true;
    await this.getProfilesCsvData();

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'LIST OF PROFILES',
      useBom: true,
      noDownload: false,
      headers: this.tHeadCsv,
    };
    const currentDate = new Date();
    const fileName = `profiles_list_${currentDate.toISOString().slice(0, 10).replace(/-/g, '_')}`;
    this.isDowloading = false;
    return new ngxCsv(this.profilesCsvData, fileName, options);
  }

  async getProfilesCsvData() {
    this.profilesCsvData = [];
    let profiles: Profile[] = [];
    profiles = await this.profileService.getProfilesDataCsv();
    profiles.forEach((prf) => {
      if (prf) {
        this.profilesCsvData.push({
          partnerType: this.getPartnerType(prf.profileData.buyerCategory, prf.profileData.sellerCategory),
          code: prf.profileData.owner?.code,
          name: prf.profileData.name,
          country: this.getCountry(prf.profileData.country) ?? 'N/A',
          status: prf.profileData.status,
          buyerPartnerStatus: prf.profileData.buyerPartnerStatus ?? 'N/A',
          sellerPartnerStatus: prf.profileData.sellerPartnerStatus ?? 'N/A',
          buyerCategory: prf.profileData.buyerCategory ?? 'N/A',
          sellerCategory: prf.profileData.sellerCategory ?? 'N/A',
          buyerSubCategory: prf.profileData.buyerSubCategory ?? 'N/A',
          sellerSubCategory: prf.profileData.sellerSubCategory ?? 'N/A',
          buyerIntegrationType: [
            prf.profileData?.buyerIntegrationType ? prf.profileData?.buyerIntegrationType[0] : 'N/A',
          ],
          sellerIntegrationType: [
            prf.profileData?.sellerIntegrationType ? prf.profileData?.sellerIntegrationType[0] : 'N/A',
          ],
          isDemandBridge: prf.profileData.isDemandBridge ?? false,
          isSupplyBridge: prf.profileData.isSupplyBridge ?? false,
          buyerTechBridge: prf.profileData.buyerTechBridge ?? 'N/A',
          sellerTechBridge: prf.profileData.sellerTechBridge ?? 'N/A',
        });
      }
    });
  }

  getPartnerType(buyerCategory: string, sellerCategory: string): string {
    if (buyerCategory === null && sellerCategory === null) {
      return 'N/A';
    }
    return buyerCategory && sellerCategory ? 'Buyer/Seller' : buyerCategory ? 'Buyer' : 'Seller';
  }

  getCountry(countryCode: string): string {
    return hsCountryListIsoCodes.find((country) => country.isoCode2 === countryCode)?.name;
  }
}
