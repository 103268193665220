import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbActionsModule, NbCardModule, NbCheckboxModule, NbIconModule, NbToggleModule } from '@nebular/theme';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { SharedModule } from './../../../../@core/shared/shared.module';
import { AddMemberToOrganizationModalComponent } from './components/add-members-modal/add-member-to-org-modal.component';
import { MembersModalComponent } from './components/members-modal/members-modal.component';
import { MembersTableComponent } from './components/members-table/members-table.component';
import { UpdateRoleMemberComponent } from './components/members-update-role-modal/update-member-role-modal.component';
import { MembersComponent } from './components/members.component';
import { MembersRoutingModule } from './members.routing-module';
import { SplitButtonModule } from 'primeng/splitbutton';

@NgModule({
  imports: [
    SharedModule,
    MembersRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NbCardModule,
    NbActionsModule,
    NbIconModule,
    NbCheckboxModule,
    AutoCompleteModule,
    MultiSelectModule,
    CommonModule,
    DropdownModule,
    NbToggleModule,
    TableModule,
    InputSwitchModule,
    ButtonModule,
    SplitButtonModule,
  ],
  declarations: [
    MembersComponent,
    MembersTableComponent,
    MembersModalComponent,
    AddMemberToOrganizationModalComponent,
    UpdateRoleMemberComponent,
  ],
  providers: [],
})
export class MembersModule {}
