<!-- apps\web-admin\src\app\features\content\modules\scheduler\components\scheduler-details\scheduler-details.component.html -->
<nb-card class="mt-2">
  <nb-card-header>
    <button
      class="btn btn-md btn-infoLight"
      (click)="showTable = !showTable"
      [ngStyle]="{ 'padding-bottom': title === 'Complete' ? '0px' : '12px' }"
    >
      <i class="nb-arrow-dropright" [ngClass]="{ 'nb-arrow-dropdown': showTable }" aria-hidden="true"></i>
      {{ title }}
    </button>

    <span *ngIf="title !== 'Complete'" class="badge-total mx-2" placement="top" ngbTooltip="All">{{
      source?.rows?.length
    }}</span>
    <span style="padding: 30px" *ngIf="title === 'Complete' && showTable"> Filtered by: {{ filteredBy }} </span>
    <button
      *ngIf="title === 'Complete'"
      placement="top"
      ngbTooltip="All"
      class="btn btn-md btn-default mx-1"
      style="border-color: #0d6efd !important"
      (click)="filter('all')"
      [disabled]="!showTable"
    >
      {{ sourceAux?.rows?.length }}
    </button>
    <button
      *ngIf="title === 'Complete'"
      placement="top"
      ngbTooltip="OK"
      class="btn btn-md btn-success mx-1"
      [disabled]="!showTable"
      (click)="filter('ok')"
    >
      {{ ok }}
    </button>
    <button
      *ngIf="title === 'Complete'"
      placement="top"
      ngbTooltip="MINOR WARNING"
      class="btn btn-md btn-info mx-1"
      (click)="filter('minorWarn')"
      [disabled]="!showTable"
    >
      {{ minorWarn }}
    </button>
    <button
      *ngIf="title === 'Complete'"
      placement="top"
      ngbTooltip="WARNING"
      class="btn btn-md btn-warning mx-1"
      (click)="filter('warn')"
      [disabled]="!showTable"
    >
      {{ warn }}
    </button>
    <button
      *ngIf="title === 'Complete'"
      placement="top"
      ngbTooltip="KO"
      class="btn btn-md btn-danger mx-1"
      (click)="filter('ko')"
      [disabled]="!showTable"
    >
      {{ ko }}
    </button>
  </nb-card-header>
  <nb-card-body *ngIf="showTable">
    <p-table
      [value]="source.rows"
      styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      [tableStyle]="{ 'min-width': '50rem' }"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
      [loading]="isLoading"
      loadingIcon="pi pi-spin pi-spinner"
      [resizableColumns]="true"
      columnResizeMode="expand"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let head of header" [pSortableColumn]="head.field">
            {{ head.label }}
            <p-sortIcon *ngIf="isValidFilterColumn(head.field)" [field]="head.field"></p-sortIcon>
          </th>
        </tr>
        <tr>
          <th *ngFor="let head of header" [style]="head.field === 'Options' ? 'width: 3rem' : ''">
            <p-columnFilter
              type="text"
              *ngIf="isValidFilterColumn(head.field)"
              [field]="head.field"
              matchMode="contains"
              [showMenu]="false"
              [showClearButton]="false"
            ></p-columnFilter>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-scheduler>
        <tr [ngClass]="getTrClass(scheduler)">
          <td *ngFor="let head of header">
            <div *ngIf="head.field !== 'Options'">
              {{ formatData(scheduler[head.field], head.field, scheduler) }}
            </div>
            <i
              *ngIf="head.field === 'Options' && title !== 'Enqueued'"
              title="Kibana Link"
              class="nb-arrow-retweet icon"
              (click)="onEdit(scheduler)"
            ></i>
            <i
              *ngIf="head.field === 'Options' && title === 'Complete'"
              title="View Details"
              class="nb-search icon"
              (click)="onCancel(scheduler)"
            ></i>
            <i
              *ngIf="head.field === 'Options' && title !== 'Complete'"
              title="Abort Task"
              class="nb-close icon"
              (click)="onCancel(scheduler)"
            ></i>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="10" class="text-center">No schedulers found.</td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>
