<div class="modal-header d-flex align-items-start">
  <div class="d-flex flex-column">
    <h4 class="modal-title">{{ modal_title }}</h4>
    <p class="mb-0 modal-subtitle" *ngIf="subtitle">{{ subtitle }}</p>
  </div>
  <button type="button" class="btn-close mt-0" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
  <ng-content select="[xenModalBody]"></ng-content>
</div>
<div class="modal-footer">
  <ng-content select="[xenModalFooter]"></ng-content>
</div>
