import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PlatformBrowserService } from '@tgx/shared/services';
import { Observable } from 'rxjs';

@Injectable()
export class HttpHeadersInterceptor implements HttpInterceptor {
  constructor(@Inject(PlatformBrowserService) private platformBrowserService: PlatformBrowserService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = null;

    const headerConfig: any = {
      Authorization: 'Bearer ' + this.platformBrowserService.getToken(),
      'Content-Type': 'application/json',
    };

    const operationName = req?.body?.operationName || '';

    if (!operationName.includes('NOAUDIT')) {
      headerConfig['TGX-Audit'] = '1';
    }

    headers = new HttpHeaders(headerConfig);

    const cloneReq = req.clone({ headers });
    return next.handle(cloneReq);
  }
}
