import { NotFoundComponent } from './../../../../@core/shared/not-found/not-found.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BillingPaymentRequestComponent } from './payment-requests.component';
import { BillingPaymentRequestsContractComponent } from './components/payment-requests-contract/payment-requests-contract.component';
import { BillingPaymentRequestsGlobalComponent } from './components/payment-requests-global/payment-requests-global.component';

const routes: Routes = [
  {
    path: '',
    component: BillingPaymentRequestComponent,
    children: [
      {
        path: 'contract',
        component: BillingPaymentRequestsContractComponent,
      },
      {
        path: 'global',
        component: BillingPaymentRequestsGlobalComponent,
      },
      {
        path: '',
        redirectTo: 'contract',
        pathMatch: 'full',
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PaymentRequestRoutingModule {}
