import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { DropdownModule } from 'primeng/dropdown';
import { WebAdminService } from '../@core/services/web-admin.service';
import { FooterComponent, HeaderComponent } from './components';
import { OneColumnLayoutComponent } from './layouts';
import { CORPORATE_THEME } from './styles/theme.corporate';
import { TooltipModule } from 'primeng/tooltip';

import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbIconModule,
  NbThemeModule,
} from '@nebular/theme';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbIconModule,
  NbEvaIconsModule,
  FormsModule,
  DropdownModule,
];
const COMPONENTS = [HeaderComponent, FooterComponent, OneColumnLayoutComponent];

@NgModule({
  imports: [CommonModule, TooltipModule, ...NB_MODULES],
  exports: [CommonModule, ...COMPONENTS],
  declarations: [...COMPONENTS],
  providers: [WebAdminService],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'corporate',
          },
          [CORPORATE_THEME],
        ).providers,
      ],
    };
  }
}
