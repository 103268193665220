<div class="row">
  <div class="col-md-12 col-lg-4 col-xxxl-4">
    <nb-card>
      <nb-card-header>Organizations with crmID</nb-card-header>
      <nb-card-body>
        <tgx-dashboard-partners-with-crmid></tgx-dashboard-partners-with-crmid>
      </nb-card-body>
    </nb-card>
  </div>

  <div class="col-md-12 col-lg-4 col-xxxl-4">
    <nb-card>
      <nb-card-header>Organizations with Profile</nb-card-header>
      <nb-card-body>
        <tgx-dashboard-orgs-with-profile></tgx-dashboard-orgs-with-profile>
      </nb-card-body>
    </nb-card>
  </div>

  <div class="col-md-12 col-lg-4 col-xxxl-4">
    <nb-card>
      <nb-card-header>Profiles with Organization</nb-card-header>
      <nb-card-body>
        <tgx-dashboard-profiles-with-org></tgx-dashboard-profiles-with-org>
      </nb-card-body>
    </nb-card>
  </div>
</div>
