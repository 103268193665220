import { gql } from 'apollo-angular';

export const getBillingAccounts = gql`
  query BillingAccounts($where: BillingAccountWhereInput!) {
    billingAccounts(where: $where) {
      id
      name
      cif
      countryCode
      city
      zipCode
      excludeVAT
      fiscalAddress
      createdAt
      updatedAt
      odooCode
      singleInvoice
    }
  }
`;
