<div class="alert" role="alert" [ngClass]="classes">
  <i class="{{ icon_snippet }} xen-alert__icon" *ngIf="icon"></i>
  <div
    [ngClass]="{ 'xen-alert-wrapper-button': hasbutton, 'xen-alert__wrapper': !hasbutton }"
    [innerHtml]="content"
  ></div>
  <ng-content #buttons></ng-content>
  <button
    type="button"
    class="btn-close xen-alert__dismiss"
    data-bs-dismiss="alert"
    aria-label="Close"
    *ngIf="dismiss"
  ></button>
</div>
