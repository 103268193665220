import { gql } from 'apollo-angular';

export const updateProfile = gql`
  mutation UpdateProfile($data: ProfileUpdateInput!, $where: ProfileWhereUniqueInput!) {
    social {
      updateProfile(data: $data, where: $where) {
        code
        createdAt
        updatedAt
        profileData {
          status
          id
          buyerOnboardingEnd
        }
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
