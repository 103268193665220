import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'custom-completer-renderer',
  templateUrl: './custom-completer-renderer.html',
  styleUrls: ['./custom-completer-renderer.scss'],
})
export class CustomCompleterRendererComponent implements OnInit {
  renderValue: string;

  @Input() value: number;
  @Input() rowData: any;

  ngOnInit() {
    this.renderValue = this.rowData.connection;
  }
}
