import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tgx-billing-modal-delete',
  templateUrl: './modal-delete.component.html',
  styleUrls: ['./modal-delete.component.scss'],
})
export class ModalDeleteComponent implements OnInit {
  @Input() message;
  @Input() title;
  @Input() buttonOkTitle;
  @Input() dataName: string;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    const defaultTitle = 'Delete entity';
    const defaultMessage = 'Are you sure that you want to delete it?';
    const defaultButtonOkTitle = 'Delete';

    this.title = !this.title ? defaultTitle : this.title;
    this.message = !this.message ? defaultMessage : this.message;
    this.buttonOkTitle = !this.buttonOkTitle ? defaultButtonOkTitle : this.buttonOkTitle;
  }

  /**
   * Returns the decision of the user
   * @param result decision result
   */
  onDelete(result) {
    if (result === 'delete') {
      this.activeModal.close(true);
    } else {
      this.activeModal.close(false);
    }
  }
}
