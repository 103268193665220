<div>
  <nb-card>
    <nb-card-header>
      <div class="row">
        <div class="org-container">
          <h3>Members</h3>
          <h1 class="small-text">({{ totalMembers }})</h1>
        </div>
        <div class="d-flex justify-content-end">
          <form [formGroup]="queryForm">
            <div class="d-flex align-items-center">
              <span class="p-2">Show archived</span>
              <nb-toggle [checked]="false" formControlName="includeArchived" (change)="runQuery()"></nb-toggle>
            </div>
          </form>
          <!-- <button class="btn btn-md btn-danger" (click)="resetTable()">Reset</button> -->
        </div>
      </div>
    </nb-card-header>
    <nb-card-body class="members-body">
      <p-table
        #td1
        [value]="membersLoadSource"
        [paginator]="true"
        [rows]="10"
        dataKey="code"
        [tableStyle]="{ 'min-width': '60rem' }"
        class="table-members"
        [loading]="isLoading"
        loadingIcon="pi pi-spin pi-spinner"
      >
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 3%"></th>
            <th *ngFor="let header of headers1" [pSortableColumn]="header.field">
              {{ header.label }}
              <p-sortIcon *ngIf="header.field !== 'Options'" [field]="header.field"></p-sortIcon>
            </th>
          </tr>
          <tr>
            <th style="width: 3%"></th>
            <ng-container *ngFor="let header of headers1">
              <th style="width: {{ header.field === 'code' ? '35%' : '15%' }}">
                <p-columnFilter
                  [showClearButton]="false"
                  [placeholder]="header.placeholder"
                  type="text"
                  *ngIf="header.field !== 'Options'"
                  [field]="header.field"
                  matchMode="contains"
                  (input)="filterTable($event.target.value, header.field)"
                >
                </p-columnFilter>
              </th>
            </ng-container>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-member let-expanded="expanded">
          <tr>
            <td>
              <button
                [disabled]="member.organizations.length === 0"
                type="button"
                pButton
                pRipple
                [pRowToggler]="member"
                class="p-button-text p-button-rounded p-button-plain"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
              ></button>
            </td>
            <td>
              <span *ngIf="member.organizations.length > 0" class="font-bold ms-2">{{ member.code }}</span>
              <span *ngIf="member.organizations.length === 0" class="ms-2">{{ member.code }}</span>
            </td>
            <td>
              <p-splitButton
                [label]="member.status"
                [model]="member.statusList"
                [styleClass]="getStatusSeverity(member.status)"
              ></p-splitButton>
            </td>
            <td>
              <i
                class="nb-shuffle icon"
                *ngIf="!isTG(member?.code)"
                title="Impersonate"
                (click)="onImpersonate(member)"
              ></i>
              <i class="nb-plus icon" title="Add member to organization" (click)="onAddMemberToOrg(member)"></i>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-member>
          <tr>
            <td colspan="7">
              <div class="p-3">
                <p-table
                  [value]="member.organizations"
                  dataKey="organization"
                  [loading]="isLoadingRoleTable && roleFilter"
                  loadingIcon="pi pi-spin pi-spinner"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th *ngFor="let header of headers2" [pSortableColumn]="header.field">
                        {{ header.label }}
                        <p-sortIcon *ngIf="header.field !== 'Options'" [field]="header.field"></p-sortIcon>
                      </th>
                    </tr>
                    <!-- <tr>
                      <ng-container *ngFor="let header of headers2">
                        <th style="width: 20%">
                          <p-columnFilter
                            [showClearButton]="false"
                            [placeholder]="header.placeholder"
                            [type]="selectType(header.field)"
                            *ngIf="header.field !== 'Options'"
                            [field]="header.field"
                            matchMode="contains"
                            (input)="dt1.filter($event.target.value, header.field, 'contains')"
                          >
                          </p-columnFilter>
                        </th>
                      </ng-container>
                    </tr> -->
                  </ng-template>
                  <ng-template pTemplate="body" let-member>
                    <tr>
                      <td>
                        <ng-container> {{ member.organization }} </ng-container>
                      </td>
                      <td>
                        <ng-container>
                          <p-splitButton
                            [disabled]="member.role === 'OWNER'"
                            [label]="member.role"
                            [model]="member.roleList"
                            styleClass="p-button-secondary"
                          ></p-splitButton>
                        </ng-container>
                      </td>
                      <td>
                        <i
                          class="nb-close icon"
                          title="Remove member from this organization"
                          (click)="onCancel(member)"
                        ></i>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td colspan="6">There are no order for this product yet.</td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </nb-card-body>
    <div *ngIf="isLoading">
      <tgx-spinner [class]="'routingLoading'" [isLoading]="isLoading" [top]="'100px'"></tgx-spinner>
    </div>
  </nb-card>
</div>
