<nb-card>
  <nb-card-header>Contract SubTypes</nb-card-header>
  <nb-card-body>
    <div *ngIf="isEditor" class="mb-3">
      <p-button label="Create" icon="pi pi-plus" (onClick)="onCreate()"></p-button>
    </div>
    <p-table
      #dt
      [value]="subtypesLoadSource"
      [paginator]="false"
      [rows]="subtypesLoadSource?.length"
      [showCurrentPageReport]="false"
      styleClass="p-datatable-gridlines p-datatable-striped"
      [tableStyle]="{ 'min-width': '50rem' }"
      [resizableColumns]="true"
      columnResizeMode="expand"
      [loading]="isLoading"
      loadingIcon="pi pi-spin pi-spinner"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let head of header" [pSortableColumn]="head.field">
            {{ head.label }}
            <p-sortIcon
              *ngIf="head.field !== 'createdAt' && head.field !== 'updatedAt' && head.field !== 'Options'"
              [field]="head.field"
            ></p-sortIcon>
          </th>
        </tr>
        <tr *ngIf="subtypesLoadSource?.length > 1">
          <th *ngFor="let head of header">
            <p-columnFilter
              [type]="getFilterType(head.field)"
              *ngIf="head.field !== 'createdAt' && head.field !== 'updatedAt' && head.field !== 'Options'"
              [field]="head.field"
              [showMenu]="false"
              [showClearButton]="false"
              (input)="filter($event.target.value, head.field)"
            ></p-columnFilter>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-type>
        <tr>
          <td>{{ type.id }}</td>
          <td>{{ type.name }}</td>
          <td>
            <p-checkbox [ngModel]="type.isCalculable" [binary]="true" inputId="binary" disabled="true"></p-checkbox>
          </td>
          <td>{{ type.odooDepartment }}</td>
          <td>{{ type.createdAt | date: 'dd-MM-yyyy' }}</td>
          <td>{{ type.updatedAt | date: 'dd-MM-yyyy' }}</td>
          <td *ngIf="isEditor">
            <i title="View Details" class="icon nb-edit" (click)="onEdit(type)"></i>
          </td>
          <td *ngIf="!isEditor"></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="10" class="text-center">No contract subtype found.</td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>
