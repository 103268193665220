import { Injectable } from '@angular/core';
import {
  ChangePaymentRequest,
  ChangePaymentRequestWhereInput,
  GlobalCustomChangePaymentRequest,
} from './../interfaces/_index.billing.interfaces';
import { GraphqlApiBillingQueries } from './queries/_index-billing-queries';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class ChangePaymentRequestService {
  constructor(private apollo: Apollo) {}

  async getRequests(contractId: number, send: boolean): Promise<ChangePaymentRequest[]> {
    let where: ChangePaymentRequestWhereInput;

    if (send) {
      where = { contractIdFrom: { equals: contractId } };
    } else {
      where = { contractIdTo: { equals: contractId } };
    }

    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .watchQuery<any>({
          query: GraphqlApiBillingQueries.getChangePaymentRequests,
          variables: { where: where },
        })
        .valueChanges.subscribe((res: any) => {
          if (res?.data) {
            resolve(((res || {}).data || {}).changePaymentRequests);
          } else {
            reject(res);
          }
        });
    });
  }

  async getGlobalRequests(): Promise<GlobalCustomChangePaymentRequest[]> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .watchQuery<any>({
          query: GraphqlApiBillingQueries.getGlobalChangePaymentRequests,
        })
        .valueChanges.subscribe((res: any) => {
          if (res?.data) {
            resolve(((res || {}).data || {}).globalChangePaymentRequestsHistorical);
          } else {
            reject(res);
          }
        });
    });
  }
}
