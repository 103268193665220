import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SuppliersService } from '../../../../services/suppliers.service';

@Component({
  selector: 'tgx-supplier-meta-info-modal',
  templateUrl: './supplier-meta-info-modal.component.html',
  styleUrls: ['./supplier-meta-info-modal.component.scss'],
})
export class SupplierMetaInfoModalComponent implements OnInit {
  @Input() supplierCode: string;
  @Input() header: string;

  public queryFormAuditory: UntypedFormGroup;
  protected isLoading = true;
  protected loadingIcon = 'pi pi-spin pi-spinner';

  tableHeader = [
    { label: 'Key', field: 'xKey' },
    { label: 'Value', field: 'xValue' },
  ];

  metaInfoSource: any[];

  constructor(
    private activeModal: NgbActiveModal,
    private suppliersService: SuppliersService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.runQuery();
  }

  async runQuery() {
    const source = await this.suppliersService.getSupplierMetaInfo(this.supplierCode);
    this.buildMetaInfo(source);
    this.isLoading = false;
  }

  buildMetaInfo(supplierMetaInfo: any[]) {
    const metaInfoRows = [];
    supplierMetaInfo.forEach((metaInfo) => {
      const data = this.formatSupplierMetaInfoSource(metaInfo.node);
      metaInfoRows.push(data);
    });
    this.metaInfoSource = metaInfoRows;
  }

  formatSupplierMetaInfoSource(metaInfo: any) {
    return {
      key: metaInfo.key,
      value: metaInfo.value,
    };
  }

  closeModal() {
    this.activeModal.close(true);
  }
}
