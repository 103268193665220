import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NotificationService } from '../../../../../../@core/services/notification.service';
//import { CustomRenderAuditDetailsComponent } from './custom-components/custom-renderer-audit-details';
import { Options } from '../../../../../../@core/interfaces/_index.interfaces';
import { AuditoryService } from '../../../../services/_index-entities.services';

interface GroupOptions {
  group_label: string;
  section_options: Options[];
}

@Component({
  selector: 'tgx-admin-auditory-table',
  templateUrl: './admin-auditory-table.component.html',
  styleUrls: ['./admin-auditory-table.component.scss'],
})
export class AdminAuditoryTableComponent implements OnInit {
  public queryFormAuditory: UntypedFormGroup;
  entities_types: GroupOptions[] = [];
  selectedEntityTypes: Options[] = [];
  from_date: Date;
  to_date: Date;

  header = [
    { label: 'Section', field: 'entityType' },
    { label: 'Code/ID', field: 'code' },
    { label: 'Action', field: 'actionType' },
    { label: 'Field / Previous / Current', field: 'auditDetails' },
    { label: 'Date', field: 'createdAt' },
    { label: 'Modified by', field: 'modifiedBy' },
  ];

  auditorySource: any[];

  constructor(
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private auditoryService: AuditoryService,
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.selectedEntityTypes = [];
    this.loadEntitiesTypes();
  }

  buildForm() {
    this.queryFormAuditory = this.fb.group({
      sections: [''],
      start_date: '',
      end_date: '',
    });
  }

  loadEntitiesTypes() {
    this.selectedEntityTypes = [];
    this.entities_types = [
      {
        group_label: 'Entities',
        section_options: [
          { code: 'ACCESS', label: 'ACCESS', isTeam: false },
          { code: 'CLIENT', label: 'CLIENT', isTeam: false },
          { code: 'PROVIDER', label: 'PROVIDER', isTeam: false },
          { code: 'SUPPLIER', label: 'SUPPLIER', isTeam: false },
          { code: 'MEMBER', label: 'MEMBER', isTeam: false },
        ],
      },
    ];
  }

  buildLogs(logs: any[]) {
    const source_logs = [];
    logs.forEach((log) => {
      const data = this.formatLogSource(log.node);
      source_logs.push(data);
    });
    this.auditorySource = source_logs;
  }

  formatLogSource(log: any) {
    return {
      entityType: log.entityAuditData.entityType,
      code: log.entityAuditData.code ?? log.entityAuditData.id,
      actionType: log.entityAuditData.actionType,
      auditDetails: log.entityAuditData.auditDetails,
      createdAt: new Date(log.createdAt).toLocaleString(['fr-FR'], {}),
      modifiedBy: log.entityAuditData.modifiedBy,
    };
  }

  async runQuery() {
    if (!this.to_date || !this.from_date) {
      this.notificationService.warning('Please set a data range!');
      return 1;
    }
    if (this.to_date) {
      this.to_date.setHours(23, 59, 59); // Set hours, minutes and seconds
    }

    if (this.from_date > this.to_date) {
      this.notificationService.warning('Date range is wrong, please set it correctly');
      return 1;
    }

    const default_order = 'created_at_DESC';
    const where = {
      date_range: {
        start_date: this.from_date,
        end_date: this.to_date,
      },
    };

    const sections = this.queryFormAuditory.get('sections').value;

    if (sections.length) {
      where['entity_type'] = sections;
    }

    const source = await this.auditoryService.getAuditory(where, default_order);
    this.buildLogs(source);
  }
}
