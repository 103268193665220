export enum AdviseMessageLevel {
  WARN = 'WARN',
  ERROR = 'ERROR',
  INFO = 'INFO',
}

export interface ExternalMessage {
  code?: string;
  message: string;
}

export interface AdviseMessage {
  code: string;
  description: string;
  level: AdviseMessageLevel;
  external: ExternalMessage;
  correlationID: string;
  type: string;
}
