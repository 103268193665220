export interface CalculationError {
  error?: string;
}

export interface PDF {
  fileName: string;
  invoice_id: number;
  mymetype: string;
  base64: string;
}

export interface PDFResponse {
  success: boolean;
  successMessage?: string;
  errors?: CalculationError[];
  response?: PDF;
}
