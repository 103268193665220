import { Component } from '@angular/core';
import { NotificationService } from '../../../../../../@core/services/notification.service';
import { ngxCsv } from 'ngx-csv';
import { ConnectionService } from '../../../../services/_index-billing.services';

@Component({
  selector: 'tgx-report-all-connections',
  templateUrl: './report-all-connections.component.html',
  styleUrls: ['./report-all-connections.component.scss'],
})
export class BillingReportAllConnectionsComponent {
  isLoading: boolean;

  constructor(
    private notificationService: NotificationService,
    private connectionService: ConnectionService,
  ) {}

  async getCSV() {
    this.isLoading = true;

    await this.connectionService
      .getConnectionsFullInfo()
      .then(async (rs) => {
        await this.downloadCsv(rs);
        this.notificationService.success('CSV successfully generated', 'Success');
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  async downloadCsv(rows: any[]) {
    const headers = Object.keys(rows[0]);

    const options = {
      fieldSeparator: ';',
      quoteStrings: '',
      decimalseparator: '.',
      showLabels: false,
      showTitle: false,
      title: '',
      useBom: true,
      noDownload: false,
      headers: headers,
      eol: '\n',
    };

    return new ngxCsv(
      this.buildCSVLines(headers, rows),
      'All_Connections_' + new Date().toISOString(),
      options,
    ).getCsv();
  }

  private buildCSVLines(headers: string[], rows: any[]): any {
    const data: any[] = [];

    rows.forEach((row) => {
      let lineData: { [key: string]: any } = {};

      lineData = {};

      headers.forEach((header) => {
        lineData[header] = row[header] !== null && row[header] !== 'null' ? row[header] : '';
      });
      data.push(lineData);
    });

    return data;
  }
}
