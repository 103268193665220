import { gql } from 'apollo-angular';

export const getActivationsByOrg = gql`
  query getActivationsByOrg($input: ActivationReadInput!) {
    activation {
      activations(data: $input) {
        adviseMessage {
          code
          description
          code
        }
        edges {
          node {
            adviseMessage {
              code
              description
              level
            }
            code
            activationData {
              code
              access {
                code
                supplier {
                  code
                }
              }
              buyer {
                code
              }
              certificationStatus
              issueID
              status
              clientNames
              editAccessAvailable
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;
