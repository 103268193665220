import { ProfileNetworkService } from './../../../../services/profile-network.service';
import { ProfileUpdateInput } from './../../../../interfaces/_index-network.interfaces';
import { Options } from './../../../../../../@core/interfaces/_index.interfaces';
import { NotificationService } from './../../../../../../@core/services/_index-core.services';
import { Subscription } from 'rxjs';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { clone } from '../../../../../../@core/utils/utils';
import { orderBy } from 'lodash';
import { hsCountryListIsoCodes } from '@tgx/shared/utils';
import { FilestackService } from '../../../../services/filestack.service';
import { ToastsService } from '@tgx/shared/toasts';
import { Profile, ProfileData } from '@tgx/shared/interfaces';

@Component({
  selector: 'tgx-profile-modal',
  styleUrls: ['./profile-modal.component.scss'],
  templateUrl: 'profile-modal.component.html',
})
export class ProfileModalComponent implements OnInit, OnDestroy {
  @Input() profile: ProfileData = null;
  @Input() header: string;
  @Input() orgCode: string;
  @Input() prfCode: string;
  @Input() categoriesEnum = [];
  @Input() subCategoriesEnum = {};
  @Input() buyerTechBridgeList = [];
  @Input() sellerTechBridgeList = [];

  isLoading: boolean;
  profileAux: Profile;
  mapBuyerTechPlatform: Map<string, string> = new Map<string, string>();
  mapSellerTechPlatform: Map<string, string> = new Map<string, string>();
  isBuyer: boolean;
  isSeller: boolean;

  // this object is used to know if some form input has changed
  settingsChanged: any;
  profileBackup: ProfileData;
  isFormUpdated = false;

  countries: { isoCode2: string; name: string }[] = [];
  selectedSellerIntegrationType: { label: string; value: string }[];
  selectedBuyerIntegrationType: { label: string; value: string }[];
  regions: { label: string; value: string }[] = [];

  integrationTypeEnum = {
    seller: [
      { label: 'Hotel Pull (TGX)', value: 'HOTEL_PULL_TGX' },
      { label: 'Hotel Pull (TGX v2)', value: 'HOTEL_PULL_TGX_V2' },
      { label: 'Hotel Pull (Generic v1)', value: 'HOTEL_PULL_GENERIC' },
      { label: 'Hotel Pull (Generic - Connector)', value: 'HOTEL_PULL_GENERIC_V2' },
      { label: 'Hotel Push (TGX)', value: 'HOTEL_PUSH_TGX' },
      { label: 'Hotel Push (Generic)', value: 'HOTEL_PUSH_GENERIC' },
      { label: 'Distribution', value: 'DISTRIBUTION' },
    ],
    buyer: [
      { label: 'Legacy', value: 'LEGACY' },
      { label: 'HotelX', value: 'HOTELX' },
      { label: 'ChannelX', value: 'CHANNELX' },
      { label: 'B2B (Virtual Agency Tool)', value: 'B2B_VIRTUAL_AGENCY_TOOL' },
      { label: 'EasyConnect', value: 'EASY_CONNECT' },
    ],
  };

  public profileForm: FormGroup;

  subscriptions: Subscription[] = [];

  templates: Options[];

  //Select
  allStatus: Options[];

  private defaultLogo = '/assets/no-photo.svg';

  constructor(
    private notificationService: NotificationService,
    private activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private profileService: ProfileNetworkService,
    private filestackService: FilestackService,
    private toast: ToastsService,
  ) {
    this.countries = this.getNationalities();
    this.getRegions();
  }

  async ngOnInit() {
    this.profileBackup = this.profile;

    this.profileForm = this.fb.group({
      name: [this.profile ? this.profile.name : '', Validators.required],
      ownerCode: [this.profile ? this.profile.owner.code : '', Validators.required],
      crmId: [this.profile ? this.profile.crmId : '', Validators.required],
      slug: [this.profile ? this.profile.slug : ''],
      websiteUrl: [this.profile ? this.profile.websiteUrl : ''],
      description: [this.profile ? this.profile.description : ''],
      Asset: [this.getlastLogoUrl(this.profile)],
      buyerLifecycleStage: [this.profile ? this.profile.buyerLifecycleStage : ''],
      sellerLifecycleStage: [this.profile ? this.profile.sellerLifecycleStage : ''],
      buyerPartnerStatus: [this.profile ? this.profile.buyerPartnerStatus : ''],
      sellerPartnerStatus: [this.profile ? this.profile.sellerPartnerStatus : ''],
      buyerOnboardingStart: [this.profile?.buyerOnboardingStart ? new Date(this.profile.buyerOnboardingStart) : null],
      buyerOnboardingEnd: [this.profile?.buyerOnboardingEnd ? new Date(this.profile.buyerOnboardingEnd) : null],
      sellerOnboardingStart: [
        this.profile?.sellerOnboardingStart ? new Date(this.profile.sellerOnboardingStart) : null,
      ],
      sellerOnboardingEnd: [this.profile?.sellerOnboardingEnd ? new Date(this.profile.sellerOnboardingEnd) : null],
      buyerFirstBooking: [this.profile?.buyerFirstBooking ? new Date(this.profile.buyerFirstBooking) : null],
      buyerLastBooking: [this.profile?.buyerLastBooking ? new Date(this.profile.buyerLastBooking) : null],
      sellerFirstBooking: [this.profile?.sellerFirstBooking ? new Date(this.profile.sellerFirstBooking) : null],
      sellerLastBooking: [this.profile?.sellerLastBooking ? new Date(this.profile.sellerLastBooking) : null],
      country: [
        this.profile?.country
          ? hsCountryListIsoCodes.filter((country) => country.isoCode2 === this.profile.country)[0].isoCode2
          : '',
      ],
      region: [this.profile ? this.profile.internalExtendedInfo?.region : ''],
      sellerIntegrationType: [
        this.profile?.sellerIntegrationType
          ? this.profile.sellerIntegrationType.map((integrationType) =>
              this.filterEnums(integrationType, this.integrationTypeEnum.seller),
            )
          : null,
      ],
      buyerIntegrationType: [
        this.profile?.buyerIntegrationType
          ? this.profile.buyerIntegrationType.map(
              (integrationType) => this.filterEnums(integrationType, this.integrationTypeEnum.buyer).value,
            )
          : null,
      ],
      buyerCategory: [
        this.profile?.buyerCategory ? this.filterEnums(this.profile.buyerCategory, this.categoriesEnum).value : '',
      ],
      sellerCategory: [
        this.profile?.sellerCategory ? this.filterEnums(this.profile.sellerCategory, this.categoriesEnum).value : '',
      ],
      buyerSubCategory: [
        this.profile?.buyerSubCategory
          ? this.filterEnums(this.profile.buyerSubCategory, this.subCategoriesEnum[this.profile.buyerCategory]).value
          : '',
        this.requiredIfCategory('buyerCategory'),
      ],
      sellerSubCategory: [
        this.profile?.sellerSubCategory
          ? this.filterEnums(this.profile.sellerSubCategory, this.subCategoriesEnum[this.profile.sellerCategory]).value
          : '',
        this.requiredIfCategory('sellerCategory'),
      ],
      isDemandBridge: [this.profile?.isDemandBridge ?? false],
      isSupplyBridge: [this.profile?.isSupplyBridge ?? false],
      buyerTechBridge: [''],
      sellerTechBridge: [''],
      customerEngineer: [this.profile ? this.profile?.internalExtendedInfo?.customerEngineer : ''],
      marketplaceManager: [this.profile ? this.profile?.internalExtendedInfo?.marketplaceManager : ''],
    });

    this.settingsChanged = {
      name: false,
      code: false,
      ownerCode: false,
      crmId: false,
      slug: false,
      websiteUrl: false,
      description: false,
      Asset: false,
      buyerLifecycleStage: false,
      sellerLifecycleStage: false,
      buyerPartnerStatus: false,
      sellerPartnerStatus: false,
      buyerOnboardingStart: false,
      buyerOnboardingEnd: false,
      sellerOnboardingStart: false,
      sellerOnboardingEnd: false,
      buyerFirstBooking: false,
      buyerLastBooking: false,
      sellerFirstBooking: false,
      sellerLastBooking: false,
      country: false,
      region: false,
      sellerIntegrationType: false,
      buyerIntegrationType: false,
      buyerCategory: false,
      sellerCategory: false,
      buyerSubCategory: false,
      sellerSubCategory: false,
      isDemandBridge: false,
      isSupplyBridge: false,
      buyerTechBridge: false,
      sellerTechBridge: false,
      customerEngineer: false,
      marketplaceManager: false,
    };

    this.retrieveTechPlatform('buyer').then((_) => {
      if (this.profile?.buyerTechBridge) {
        this.profileForm
          .get('buyerTechBridge')
          .patchValue(this.filterList(this.profile.buyerTechBridge, this.buyerTechBridgeList)?.value);
      }
    });

    this.retrieveTechPlatform('seller').then((_) => {
      if (this.profile?.sellerTechBridge) {
        this.profileForm
          .get('sellerTechBridge')
          .patchValue(this.filterList(this.profile.sellerTechBridge, this.sellerTechBridgeList)?.value);
      }
    });

    this.profileForm.get('slug').disable();
    this.profileForm.get('buyerLifecycleStage').disable();
    this.profileForm.get('sellerLifecycleStage').disable();
    this.profileForm.get('buyerIntegrationType').disable();
    this.profileForm.get('sellerIntegrationType').disable();
    this.profileForm.get('buyerFirstBooking').disable();
    this.profileForm.get('buyerLastBooking').disable();
    this.profileForm.get('sellerFirstBooking').disable();
    this.profileForm.get('sellerLastBooking').disable();
    this.profileForm.get('buyerPartnerStatus').disable();
    this.profileForm.get('sellerPartnerStatus').disable();
    this.profileForm.get('customerEngineer').disable();

    this.profileAux = clone(this.profile);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  closeModal() {
    this.profile = clone(this.profileAux);
    this.activeModal.close(true);
  }

  onChange(funcName: string) {
    let previousValue: string;
    switch (funcName) {
      case 'marketplaceManager':
        previousValue = this.profileBackup?.internalExtendedInfo.marketplaceManager;
        break;
      case 'ownerCode':
        previousValue = this.profileBackup?.owner.code;
        break;
      case 'Asset':
        previousValue = this.getlastLogoUrl(this.profileBackup);
      case 'region':
        previousValue = this.profileBackup?.internalExtendedInfo.region;
        break;
      default:
        previousValue = this.profileBackup?.[funcName] ?? '';
        break;
    }

    let currentValue = this.profileForm.get(funcName).value ?? '';
    if (currentValue instanceof Date) {
      currentValue = currentValue.toISOString();
    }

    if (previousValue === currentValue) {
      // the value is the same
      this.settingsChanged[funcName] = false;
    } else {
      // the value has changed
      this.settingsChanged[funcName] = true;
    }

    this.checkChanges();
  }

  onCategoryChanges(funcName) {
    // When the category is modifies the subcategory must be reset
    const subCategory = funcName === 'buyerCategory' ? 'buyerSubCategory' : 'sellerSubCategory';
    this.profileForm?.get(subCategory).setValue('');
    this.onChange(subCategory);

    // Idk the difference, but when the setValue is not set
    // the '' option is selected instead of the empty state
    if (this.profileForm?.get(funcName).value === '') {
      this.profileForm?.get(funcName).setValue('');
    }

    this.onChange(funcName);
  }

  uploadLogo() {
    this.filestackService.uploadLogo();
  }

  getlastLogoUrl(profile: ProfileData): string {
    if (!profile) {
      return this.defaultLogo;
    }
    if (this.filestackService.updatedLogo.value === true) {
      let url: string;
      this.filestackService.newFileUpload.subscribe((fileUpload) => {
        url = fileUpload?.url;
      });
      return url;
    }
    if (profile.assets?.length > 0) {
      const logoList = profile.assets.filter((asset) => asset.type === 'LOGO').map((asset) => asset.url);
      return logoList[logoList.length - 1];
    } else return this.defaultLogo;
  }

  // function to check if some form input has changed
  checkChanges() {
    this.isFormUpdated = false;
    for (const property in this.settingsChanged) {
      if (this.settingsChanged[property]) this.isFormUpdated = true;
    }
  }

  addLogoUrl(profileupdateInput: ProfileUpdateInput) {
    this.filestackService.newFileUpload.subscribe((fileUpload) => {
      this.profileForm['Asset'] = fileUpload.url;
    });
    profileupdateInput['Asset'] = this.filestackService.formatData.value.Asset;
  }

  async submit() {
    if (!this.profileForm.valid) {
      this.profileForm.markAllAsTouched();
      return;
    }

    const profileupdateInput: ProfileUpdateInput = {};

    for (const settingName in this.settingsChanged) {
      if (this.settingsChanged[settingName]) {
        const newValue = this.profileForm.get(settingName).value;

        if (newValue instanceof Date) {
          profileupdateInput[settingName] = this.profileForm.get(settingName).value.toISOString();
        } else if (settingName.includes('Category')) {
          this.addCategory(settingName, profileupdateInput);
        } else if (settingName === 'marketplaceManager' || settingName === 'region') {
          this.addInternalExtendedInfo(settingName, profileupdateInput);
        } else if (settingName === 'Asset') {
          this.addLogoUrl(profileupdateInput);
        } else profileupdateInput[settingName] = this.emptyToNull(this.profileForm.get(settingName).value);
      }
    }

    this.profileService
      .updateProfile(profileupdateInput, { code: this.prfCode })
      .then((rs) => {
        this.notificationService.success('Profile succesfully updated', 'Success');
        this.activeModal.close(rs);
      })
      .catch((err) => {
        console.log(err);

        this.notificationService.handleGatewayAndGraphqlErrors(err);
      });
  }

  private emptyToNull(value: string): string {
    return value !== '' ? value : null;
  }

  addCategory(settingName: string, profileUpdateInput: ProfileUpdateInput) {
    const categoryType = settingName.includes('buyer') ? 'buyer' : 'seller';

    const propertyName = `${categoryType}CategoryPropertiesInput`;

    if (settingName.includes('SubCategory') && this.profileForm.get(settingName).value !== '') {
      // In this if statement, the subCategory and the category
      // are set, even though the category has not been changed.
      profileUpdateInput[propertyName] = {
        [settingName]: this.profileForm.get(settingName).value, // subCategory
        [`${categoryType}Category`]: this.profileForm.get(`${categoryType}Category`).value, // category
      };
    } else if (!settingName.includes('SubCategory')) {
      // settingName === 'buyerCategory' || settingName === 'sellerCategory'
      if (this.profileForm.get(settingName).value === '') {
        profileUpdateInput[propertyName] = null;
      }
    }
  }

  addInternalExtendedInfo(settingName: string, profileupdateInput: ProfileUpdateInput) {
    profileupdateInput['internalExtendedInfoInput'] = profileupdateInput['internalExtendedInfoInput'] || {};

    profileupdateInput['internalExtendedInfoInput'][settingName] = this.emptyToNull(
      this.profileForm.get(settingName).value,
    );
  }

  filterEnums(code: string, enumerado: { label: string; value: string }[]) {
    const target = enumerado.filter((object) => object.value === code);
    const result = target.length !== 0 ? target[0] : { label: null, value: null };

    return result;
  }

  filterList(code: string, list: any[]) {
    return code ? list.filter((element) => element.value === code)[0] : '';
  }

  async retrieveTechPlatform(partnerType: string) {
    return await this.profileService
      .retrieveTechPlatforms(partnerType)
      .then((result: any[]) => {
        const filteredList = result.filter((elem) => elem.profileData.owner.organizationsData);
        filteredList.unshift({ profileData: { name: '', owner: { organizationsData: { code: '' } } } });
        let techBridgeList: any[] = [];
        techBridgeList = orderBy(
          filteredList.map((techplat) => {
            const name =
              techplat.profileData.name.charAt(0).toUpperCase() + techplat.profileData.name.toLowerCase().substring(1);

            this.mapBuyerTechPlatform.set(name, techplat.profileData.owner.organizationsData.code);

            return {
              label: name,
              value: techplat.profileData.owner?.organizationsData?.code,
            };
          }),
          'name',
        );

        if (partnerType === 'buyer') {
          this.buyerTechBridgeList = techBridgeList;
        } else if (partnerType === 'seller') {
          this.sellerTechBridgeList = techBridgeList;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  private getNationalities() {
    return hsCountryListIsoCodes.map((country) => ({
      isoCode2: country.isoCode2,
      label: country.name,
      name: country.name,
      value: country.isoCode2,
    }));
  }

  private async getRegions() {
    const regions = await this.profileService.allRegions();
    if (regions.length === 0) {
      this.regions = [];
      this.toast.addToast('ERROR', 'bg-danger', 'There are no regions available');
    } else {
      this.regions = regions.map((item) => ({ label: item, value: item }));
    }
  }

  private requiredIfCategory(category: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (this.profileForm?.get(category).value !== '' && value === '') {
        return {
          requiredIfCategory: true,
        };
      }

      return null;
    };
  }
}
