import { Table } from 'primeng/table';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DefaultCost } from '../../../../../interfaces/_index.billing.interfaces';
import { DefaultCostsService } from '../../../../../services/_index-billing.services';
import { WebAdminService } from './../../../../../../../@core/services/_index-core.services';
import { BillingDefaultCostModalComponent } from '../default-cost-modal/default-cost-modal.component';

@Component({
  selector: 'tgx-admin-billing-default-cost-table',
  templateUrl: './default-cost-table.component.html',
  styleUrls: ['./default-cost-table.component.scss'],
})
export class BillingDefaultCostTableComponent implements OnInit {
  @ViewChild('dt', { static: false }) dataTable!: Table;

  header = [
    { label: 'ID', field: 'id' },
    { label: 'Amount', field: 'amount' },
    { label: 'PartnerType', field: 'partnerType' },
    { label: 'PartnerSubType', field: 'partnerSubType' },
    { label: 'PaymentType', field: 'paymentType' },
    { label: 'CreatedAt', field: 'createdAt' },
  ];

  costsLoadSource: any[];

  costs: DefaultCost[];

  isLoading: boolean;

  isEditor = false;

  constructor(
    private modalService: NgbModal,
    private webAdminService: WebAdminService,
    private defaultCostService: DefaultCostsService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.buildOptions();
    await this.getDefaultCosts();
  }

  buildOptions() {
    this.isEditor = this.webAdminService.isBillingEditorOrAdmin();
  }

  async getDefaultCosts() {
    this.isLoading = true;
    this.costsLoadSource = [];

    const costs = await this.defaultCostService.getDefaultCosts();

    if (costs.length > 0) {
      costs.forEach((c) => {
        const { id, amount, partnerType, paymentType, createdAt, updatedAt, ContractType } = c;

        this.costsLoadSource.push({
          id,
          amount,
          partnerType,
          partnerSubType: ContractType ? ContractType.name : '',
          paymentType,
          createdAt,
          updatedAt,
        });
        this.costsLoadSource = [...this.costsLoadSource];
        this.costsLoadSource.sort((a, b) => (a.id < b.id ? -1 : 1));
      });
      this.isLoading = false;
    } else {
      this.isLoading = false;
    }
  }

  async onEdit(event): Promise<void> {
    const defaultCost: DefaultCost = event;

    const activeModal = this.modalService.open(BillingDefaultCostModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = (this.isEditor ? 'Edit ' : 'View ') + 'Cost';
    activeModal.componentInstance.defaultCost = defaultCost;
    activeModal.componentInstance.isEditor = this.isEditor;

    const self = this;
    activeModal.result.then(async (res) => {
      // OK
      if (typeof res === 'object') {
        await self.getDefaultCosts();
      }
    });
  }

  filter(value: any, field: string) {
    if (value) {
      this.dataTable.filter(value, field, 'contains');
    }
  }
}
