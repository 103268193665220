export const getAllCM23ContractCosts = `
query {
	getAllCM23ContractCosts {
	  partnerId
	  partnerName
	  orgCode
	  contractId
	  partnerType
	  partnerSubType
	  modelType
	  activationDateTime
	  STD
	  SUP
	  SUP_DP
	  FS
	  reviewFeeDate
	  remarks
	}
  }
`;
