<div class="modal-header">
  <span>{{ header }}</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" *ngIf="isLoading">
  <tgx-spinner [isLoading]="isLoading" [top]="'0px'"></tgx-spinner>
</div>
<div class="modal-body" *ngIf="!isLoading">
  <form [formGroup]="templateForm">
    <nb-card>
      <nb-card-header> Data </nb-card-header>
      <nb-card-body>
        <div class="row mt-2">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="typeTemplate"
                >Type
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="templateForm.get('typeTemplate')"
                [errors]="templateForm.get('typeTemplate').errors"
              ></tooltip-error>
              <div class="form-group">
                <p-dropdown
                  (onHide)="validDropdown()"
                  [options]="typeOptions"
                  optionLabel="label"
                  filterBy="label"
                  class="form-control p-0 border-0 w-100"
                  formControlName="typeTemplate"
                  [appendTo]="'body'"
                  baseZIndex="999"
                  [ngClass]="{
                    'form-control-danger':
                      templateForm.get('typeTemplate').errors && templateForm.get('typeTemplate').touched,
                  }"
                  placeholder="Select a type..."
                  name="typeTemplate"
                  >>
                  <ng-template pTemplate="selectedItem">
                    <div *ngIf="templateForm.get('typeTemplate')?.value">
                      <div>{{ templateForm.get('typeTemplate').value.label }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-cur pTemplate="cur">
                    <div>
                      <div>{{ cur.label }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="subTypeTemplate"
                >SubType
                <span *ngIf="templateForm.get('typeTemplate').value.label === 'OTHER'" class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="templateForm.get('subTypeTemplate')"
                [errors]="templateForm.get('subTypeTemplate').errors"
              ></tooltip-error>
              <div class="form-group">
                <p-dropdown
                  [options]="subTypeOptions"
                  optionLabel="label"
                  filterBy="label"
                  class="form-control p-0 border-0 w-100"
                  formControlName="subTypeTemplate"
                  [appendTo]="'body'"
                  baseZIndex="999"
                  [ngClass]="{
                    'form-control-danger':
                      templateForm.get('subTypeTemplate').errors && templateForm.get('subTypeTemplate').touched,
                  }"
                  placeholder="Select a subtype..."
                  name="subTypeTemplate"
                  >>
                  <ng-template pTemplate="selectedItem">
                    <div *ngIf="templateForm.get('subTypeTemplate')?.value">
                      <div>{{ templateForm.get('subTypeTemplate').value.label }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-cur pTemplate="cur">
                    <div>
                      <div>{{ cur.label }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="version"
                >Version
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="templateForm.get('version')"
                [errors]="templateForm.get('version').errors"
              ></tooltip-error>
              <input
                type="number"
                class="form-control"
                formControlName="version"
                [ngClass]="{
                  'form-control-danger': templateForm.get('version').errors && templateForm.get('version').touched,
                }"
                placeholder="Enter a version..."
                name="version"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="container"
                >Pdf File
                <span class="mandatory ml-1">*</span>
              </label>
              <input type="file" class="file-input" (change)="onFileSelected($event)" #filePdfUpload />
              <div class="file-upload">
                <button class="btn btn-md btn-primary" (click)="filePdfUpload.click()">
                  {{ container?.name || 'Attach file' }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()">Cancel</button>
  <button class="btn btn-md btn-primary" [disabled]="!validForm()" (click)="onSave()">Create</button>
</div>
