import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'xen-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
})
export class RadioComponent implements OnInit {
  @Input() checked: boolean;
  @Input() disabled: boolean;
  @Input() reverse: boolean;
  @Input() label: string;
  @Input() name: string;
  @Input() id: string;
  @Input() validation: boolean;
  @Input() validation_status: string;
  @Input() feedback: string;
  @Input() tooltip: boolean;
  @Input() tooltip_text: string;
  @Input() tooltip_placement: string;

  tooltipText: string;

  constructor() {}

  public get classes(): Array<string> {
    return [this.reverse ? 'form-check-reverse' : ''];
  }
  public get inputClasses(): Array<string> {
    return [this.validation && this.validation_status ? `${this.validation_status}` : ''];
  }
  public get feedbackClasses(): Array<string> {
    return [
      this.validation && this.validation_status === 'is-valid' ? 'valid-feedback' : '',
      this.validation && this.validation_status === 'is-invalid' ? 'invalid-feedback' : '',
    ];
  }

  ngOnInit(): void {}
}
