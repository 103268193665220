export enum WebFeature {
  FULL_COMPONENT,
  CREATE_CONNECTION,
  CREATE_ACCESS,
  EDIT_ACCESS,
  COPY_ACCESS,
  CREATE_APIKEY,
  PLUGINS,
  MAPPING,
  COMPANY_CONTACTS,
}
