import { SharedModule } from './../../@core/shared/shared.module';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { ContentComponent } from './content.component';
import { ContentRoutingModule } from './content-routing.module';
import { SchedulerModule } from './modules/_index-content-modules';
import { ConApiService } from './services/con-api.service';
import { TaskManagerService } from './services/task-manager.service';

const SERVICES = [ConApiService, TaskManagerService];

@NgModule({
  imports: [
    SharedModule,
    // Routing
    ContentRoutingModule,
    //Custom modules
    SchedulerModule,
  ],
  declarations: [ContentComponent],
  providers: [],
})
export class ContentModule {
  static forRoot(): ModuleWithProviders<ContentModule> {
    return {
      ngModule: ContentModule,
      providers: [...SERVICES],
    };
  }
}
