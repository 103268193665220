import { gql } from 'apollo-angular';

export const allProfiles = gql`
  query allProfilesCache($where: ProfileWhereInput) {
    social {
      allProfilesV2(where: $where) {
        adviseMessage {
          code
          description
          level
        }
        edges {
          node {
            code
            createdAt
            updatedAt
            profileData {
              country
              assets {
                url
                updatedAt
                type
                id
              }
              owner {
                code
                deletedAt
                organizationsData {
                  code
                  isTest
                  isBuyer
                  isSeller
                  blacklisted
                }
              }
              description
              status
              mainMarkets
              websiteUrl
              buyerInsightsCountries {
                countryType
                countriesSortedByBookings
              }
              sellerInsightsCountries {
                countryType
                countriesSortedByBookings
              }
              buyerSubCategory
              sellerSubCategory
              buyerOnboardingStart
              buyerOnboardingEnd
              sellerOnboardingStart
              sellerOnboardingEnd
              buyerPartnerStatus
              sellerPartnerStatus
              buyerTechBridge
              sellerTechBridge
              isDemandBridge
              isSupplyBridge
            }
          }
        }
      }
    }
  }
`;

//Add in case to be used

// assets {
//   name
//   createdAt
// }

//classifications

// isDemandBridge
// isSupplyBridge

// group {
//   code
// }
