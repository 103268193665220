import { gql } from 'apollo-angular';

export const getHubUserInformationByName = gql`
  query ($name: [String!]) {
    admin {
      allClients(where: { name_in: $name }) {
        edges {
          node {
            clientData {
              isActive
              name
              hubUser {
                giataActive
                giataConfiguration
                password
              }
            }
          }
        }
      }
    }
  }
`;
