import { Component, Input } from '@angular/core';

@Component({
  selector: 'xen-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent {
  @Input() size: 'small' | 'medium' | 'large' = 'small';
  @Input() label: string;
  @Input() icon: boolean;
  @Input() icon_snippet: string;
  @Input() icon_placement: 'right' | 'left' = 'right';
  @Input() type:
    | 'default'
    | 'gray-light'
    | 'info'
    | 'primary-darken'
    | 'success'
    | 'warning'
    | 'error'
    | 'criteria-1'
    | 'criteria-2'
    | 'criteria-3' = 'default';
  @Input() tooltip: boolean;
  @Input() tooltip_text: string;

  public get classes(): Array<string> {
    return [
      this.icon ? `xen-tag__icon xen-tag__icon--${this.icon_placement}` : '',
      `xen-tag--${this.type}`,
      `xen-tag--${this.size}`,
    ];
  }

  ngOnInit(): void {}
}
