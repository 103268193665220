import { gql } from 'apollo-angular';

export const createBillScheduled = gql`
  mutation CreateScheduled($data: BillScheduledCreateInput!) {
    createBillScheduled(data: $data) {
      id
      startDate
      endDate
    }
  }
`;
