import { gql } from 'apollo-angular';

export const addMemberToOrganizationSafe = gql`
  mutation ($where: MemberToOrganizationInput!, $data: MemberDataInOrganization!) {
    admin {
      safeAddMemberToOrganization(where: $where, data: $data) {
        code
        adviseMessage {
          code
          description
        }
      }
    }
  }
`;
