<div class="modal-header">
  <span>{{ header }}</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="costForm">
    <nb-card>
      <nb-card-header> Data </nb-card-header>
      <nb-card-body>
        <div *ngIf="!isCreating" class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="id">ID</label>
              <input type="text" class="form-control" name="id" formControlName="id" />
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{ 'form-control-danger': costForm.get('min').errors && costForm.get('min').touched }"
            >
              <label class="mb-1" for="min"
                >Minimum
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error [element]="costForm.get('min')" [errors]="costForm.get('min').errors"></tooltip-error>
              <input
                type="number"
                class="form-control"
                formControlName="min"
                [ngClass]="{ 'form-control-danger': costForm.get('min').errors && costForm.get('min').touched }"
                name="min"
              />
            </div>
          </div>
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{ 'form-control-danger': costForm.get('max').errors && costForm.get('max').touched }"
            >
              <label class="mb-1" for="max">Maximum</label>
              <input
                type="number"
                class="form-control"
                formControlName="max"
                [ngClass]="{ 'form-control-danger': costForm.get('max').errors && costForm.get('max').touched }"
                name="max"
              />
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger': costForm.get('bookingFee').errors && costForm.get('bookingFee').touched,
              }"
            >
              <label class="mb-1" for="bookingFee"
                >Ok fee
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="costForm.get('bookingFee')"
                [errors]="costForm.get('bookingFee').errors"
              ></tooltip-error>
              <input
                type="number"
                class="form-control"
                formControlName="bookingFee"
                [ngClass]="{
                  'form-control-danger': costForm.get('bookingFee').errors && costForm.get('bookingFee').touched,
                }"
                name="bookingFee"
              />
            </div>
          </div>
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger': costForm.get('cancelFee').errors && costForm.get('cancelFee').touched,
              }"
            >
              <label class="mb-1" for="cancelFee"
                >Cancel fee
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="costForm.get('cancelFee')"
                [errors]="costForm.get('cancelFee').errors"
              ></tooltip-error>
              <input
                type="number"
                class="form-control"
                formControlName="cancelFee"
                [ngClass]="{
                  'form-control-danger': costForm.get('cancelFee').errors && costForm.get('cancelFee').touched,
                }"
                name="cancelFee"
              />
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{ 'form-control-danger': costForm.get('l2b').errors && costForm.get('l2b').touched }"
            >
              <label class="mb-1" for="l2b">L2B </label>
              <tooltip-error [element]="costForm.get('l2b')" [errors]="costForm.get('l2b').errors"></tooltip-error>
              <input
                type="number"
                class="form-control"
                formControlName="l2b"
                [ngClass]="{ 'form-control-danger': costForm.get('l2b').errors && costForm.get('l2b').touched }"
                name="l2b"
              />
            </div>
          </div>
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{ 'form-control-danger': costForm.get('excess').errors && costForm.get('excess').touched }"
            >
              <label class="mb-1" for="excess">Excess </label>
              <tooltip-error
                [element]="costForm.get('excess')"
                [errors]="costForm.get('excess').errors"
              ></tooltip-error>
              <input
                type="number"
                class="form-control"
                formControlName="excess"
                [ngClass]="{ 'form-control-danger': costForm.get('excess').errors && costForm.get('excess').touched }"
                name="excess"
              />
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="chargeType"
                >Charge Type
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="costForm.get('chargeType')"
                [errors]="costForm.get('chargeType').errors"
              ></tooltip-error>
              <div class="form-group">
                <p-dropdown
                  (onHide)="validType('chargeType')"
                  [options]="chargeTypes"
                  [appendTo]="'body'"
                  baseZIndex="999"
                  formControlName="chargeType"
                  optionLabel="label"
                  filterBy="label"
                >
                  <ng-template pTemplate="selectedItem">
                    <div *ngIf="costForm.get('chargeType').value">
                      <div>{{ costForm.get('chargeType').value.label }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-ci pTemplate="ci">
                    <div>
                      <div>{{ ci.label }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="paymentType"
                >Payment Type
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="costForm.get('paymentType')"
                [errors]="costForm.get('paymentType').errors"
              ></tooltip-error>
              <div class="form-group">
                <p-dropdown
                  (onHide)="validType('paymentType')"
                  [options]="paymentTypes"
                  [appendTo]="'body'"
                  baseZIndex="999"
                  formControlName="paymentType"
                  optionLabel="label"
                  filterBy="label"
                >
                  <ng-template pTemplate="selectedItem">
                    <div *ngIf="costForm.get('paymentType').value">
                      <div>{{ costForm.get('paymentType').value.label }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-ci pTemplate="ci">
                    <div>
                      <div>{{ ci.label }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()">{{ isEditor ? 'Cancel' : 'Close' }}</button>
  <button *ngIf="isEditor" class="btn btn-md btn-primary" [disabled]="!validForm()" (click)="onSave()">
    {{ isCreating ? 'Create' : 'Update' }}
  </button>
</div>
