import { gql } from 'apollo-angular';

export const getGlobalChangePaymentRequests = gql`
  query {
    globalChangePaymentRequestsHistorical {
      id
      partnerFromName
      partnerFromOrgCode
      partnerToName
      partnerToOrgCode
      currentConnectionType
      requestedConnectionType
      requestStatus
      requestUser
      responseUser
      createdAt
      updatedAt
    }
  }
`;
