import { gql } from 'apollo-angular';

export const updateContact = gql`
  mutation updateContact($data: BillingAccountContactUpdateInput!, $where: BillingAccountContactWhereUniqueInput!) {
    updateBillingAccountContact(data: $data, where: $where) {
      id
      email
      receiveInvoices
      createdAt
      updatedAt
    }
  }
`;
