<div class="modal-header">
  <span>Create Main Connection</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <nb-card>
    <nb-card-header> Data </nb-card-header>
    <nb-card-body *ngIf="!isLoading">
      <tgx-spinner [class]="'routingLoading'" [isLoading]="isLoading" [top]="'0px'"></tgx-spinner>
      <form [formGroup]="connectionForm">
        <div class="row">
          <div class="col-3">
            <div class="form-group">
              <label class="mb-1" for="isPush">IsPush</label>
              <nb-checkbox class="form-control" name="isPush" formControlName="isPush"></nb-checkbox>
            </div>
          </div>
          <div class="col-9">
            <div class="form-group">
              <label class="mb-1" for="connectionApi"
                >Connection Api
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="connectionForm.get('connectionApi')"
                [errors]="connectionForm.get('connectionApi').errors"
              ></tooltip-error>
              <div class="form-group">
                <p-dropdown
                  (onHide)="validDropdown('connectionApi')"
                  [options]="connectionApis"
                  [appendTo]="'body'"
                  baseZIndex="999"
                  formControlName="connectionApi"
                  optionLabel="label"
                  filterBy="label"
                >
                  <ng-template pTemplate="selectedItem">
                    <div *ngIf="connectionForm.get('connectionApi').value">
                      <div>{{ connectionForm.get('connectionApi').value.label }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-ci pTemplate="ci">
                    <div>
                      <div>{{ ci.label }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="buyer">Buyer<span class="mandatory ml-1">*</span> </label>
              <tooltip-error
                [element]="connectionForm.get('buyer')"
                [errors]="connectionForm.get('buyer').errors"
              ></tooltip-error>
              <div class="form-group">
                <p-dropdown
                  [options]="buyerOptions"
                  placeholder="Select a Buyer"
                  optionLabel="title"
                  appendTo="body"
                  filter="true"
                  baseZIndex="999"
                  formControlName="buyer"
                >
                </p-dropdown>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="seller">Seller<span class="mandatory ml-1">*</span> </label>
              <tooltip-error
                [element]="connectionForm.get('seller')"
                [errors]="connectionForm.get('seller').errors"
              ></tooltip-error>
              <div class="form-group">
                <p-dropdown
                  [options]="sellerOptions"
                  placeholder="Select a Seller"
                  optionLabel="title"
                  appendTo="body"
                  filter="true"
                  baseZIndex="999"
                  formControlName="seller"
                >
                </p-dropdown>
              </div>
            </div>
          </div>
        </div>
      </form>
    </nb-card-body>
    <nb-card-body *ngIf="isLoading">
      <span>Data is being loaded, please wait..</span>
    </nb-card-body>
  </nb-card>
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()">Cancel</button>
  <button class="btn btn-md btn-primary" [disabled]="!validForm()" (click)="onSave()">Create</button>
</div>
