import { Component, Input, OnChanges, Output, ViewChild, EventEmitter } from '@angular/core';
import { Table } from 'primeng/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActiveConnection, ActiveConnectionData } from '../../../../interfaces/_index.billing.interfaces';
import { NotificationService } from './../../../../../../@core/services/_index-core.services';
import { ModalDeleteComponent } from './../../../../../../@core/shared/modal-delete/modal-delete.component';
import { BillingConnectionCloneModalComponent } from '../connections-clone-modal/connections-clone-modal.component';
import { ContractConnectionService } from '../../../../services/contract-connection.service';
import { BillingConnectionsinconclusiveModalComponent } from '../connections-inconclusive-modal/connections-inconclusive-modal.component';

@Component({
  selector: 'tgx-admin-billing-inconclusive-breakdowns-table',
  templateUrl: './connections-inconclusive-breakdown-table.component.html',
  styleUrls: ['./connections-inconclusive-breakdown-table.component.scss'],
})
export class BillingInconclusiveBreakdownsTableComponent implements OnChanges {
  @Input() isEditor: boolean;
  @Input() selectedPair: any;
  @Input() allConnections: ActiveConnection[];
  @Output() newAction: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  @ViewChild('dt2', { static: false }) dataTable2!: Table;

  header = [
    { label: 'PartnerId', field: 'partnerId' },
    { label: 'PartnerName', field: 'partnerName' },
    { label: 'PartnerOrgCode', field: 'partnerOrgCode' },
    { label: 'ContractId', field: 'contractId' },
    { label: 'PartnerType', field: 'partnerType' },
    { label: 'PartnerSubType', field: 'partnerSubType' },
    { label: 'ModelType', field: 'modelType' },
    { label: 'ConnectionId', field: 'connectionId' },
    { label: 'ConnectionType', field: 'connectionType' },
    { label: 'Options', field: 'Options' },
  ];

  connectionsLoadSource: any[];

  connections: ActiveConnectionData[];

  isLoading: boolean;

  constructor(
    private notificationService: NotificationService,
    private modalService: NgbModal,
    private contractConnectionService: ContractConnectionService,
  ) {}

  ngOnChanges() {
    this.isLoading = true;
    this.getSubConnections();
    this.isLoading = false;
  }

  getSubConnections() {
    this.isLoading = true;
    this.connectionsLoadSource = [];

    if (this.dataTable2) {
      this.dataTable2.clearFilterValues();
    }

    let current = this.allConnections.filter((x) => x.buyerCode + '|' + x.sellerCode === this.selectedPair.id);
    if (current?.length > 0) {
      if (current[0].connections?.length > 0) {
        current[0].connections.forEach((cn) => {
          this.connectionsLoadSource.push({
            'partnerId': cn.partnerId,
            'partnerName': cn.partnerName,
            'partnerOrgCode': cn.partnerOrgCode,
            'contractId': cn.contractId,
            'partnerType': cn.partnerType,
            'partnerSubType': cn.partnerSubType,
            'modelType': cn.modelType,
            'connectionId': cn.connectionId,
            'connectionType': this.getRateType(cn.connectionType),
            'conBreakdownId': cn.conBreakdownId,
          });
        });
      }
    }

    this.connectionsLoadSource = [...this.connectionsLoadSource];
    this.connectionsLoadSource.sort((a, b) => (a.id < b.id ? 1 : -1));
  }

  async onEdit(subcon: ActiveConnectionData): Promise<void> {
    const activeModal = this.modalService.open(BillingConnectionsinconclusiveModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = 'Edit Connection Type';
    activeModal.componentInstance.connection = subcon;

    const self = this;
    activeModal.result.then((res) => {
      // on close

      if (typeof res === 'boolean' && res) {
        //todo do nothing at the moment
      } else if (typeof res === 'boolean' && !res) {
        //todo do nothing at the moment
      }
      // OK
      else if (typeof res === 'object') {
        self.newAction.emit(true);
      }
    });
  }

  async onClone(subcon: ActiveConnectionData): Promise<void> {
    const activeModal = this.modalService.open(BillingConnectionCloneModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = 'Clone Contract Connection';
    activeModal.componentInstance.connection = { id: subcon.connectionId };

    const self = this;
    activeModal.result.then((res) => {
      // on close

      if (typeof res === 'boolean' && res) {
        //todo do nothing at the moment
      } else if (typeof res === 'boolean' && !res) {
        //todo do nothing at the moment
      }
      // OK
      else if (typeof res === 'object') {
        self.newAction.emit(true);
      }
    });
  }

  onDelete(subcon: ActiveConnectionData): void {
    const modalRef = this.modalService.open(ModalDeleteComponent, {
      backdrop: 'static',
      container: 'nb-layout',
    });

    modalRef.componentInstance.message =
      'Are you sure that you want to delete connection with id ' + subcon.connectionId + '?';
    modalRef.componentInstance.title = 'Delete Connection';
    modalRef.componentInstance.buttonOkTitle = 'Delete';

    const self = this;

    modalRef.result
      .then(async (res) => {
        // on close
        if (res) {
          await self.contractConnectionService
            .deleteConnection({ id: subcon.connectionId })
            .then((rs) => {
              this.notificationService.success('Contract connection succesfully deleted', 'Success');
              self.newAction.emit(true);
            })
            .catch((err) => {
              this.notificationService.handleGatewayAndGraphqlErrors(err);
            });
        }
      })
      .catch((error) => {
        self.notificationService.handleGatewayAndGraphqlErrors(error);
      });
  }

  getRateType(rateType: string): string {
    switch (rateType) {
      case 'STANDARD+SUPPLEMENT':
        return 'SUP';
      case 'STANDARD':
        return 'STD';
      default:
        return rateType;
    }
  }
}
