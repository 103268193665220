import { gql } from 'apollo-angular';

export const getPartnersById = gql`
  query GetPartnersById($where: PartnerWhereInput) {
    partners(where: $where) {
      id
      name
      orgCode
      odooCode
      createdAt
      updatedAt
    }
  }
`;
