import { gql } from 'apollo-angular';

export const grantClientToGroup = gql`
  mutation ($where: ClientWhereUniqueInput!, $group: ID!) {
    admin {
      grantClientToGroup(where: $where, group: $group) {
        clientData {
          code
          name
          isActive
          group {
            code
          }
        }
      }
    }
  }
`;
