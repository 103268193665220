import { ActivatedRoute } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { PlatformBrowserService } from './platform-browser.service';
import { IAuth0User, SelectorOrganization, UserData } from '@tgx/shared/interfaces';

@Injectable()
export class PlatformInfoService {
  searchBar = new BehaviorSubject<string>('');
  isSearchBarFocused = new BehaviorSubject<boolean>(false);

  public selectedOrg: SelectorOrganization;
  public isPublicApp: boolean;

  // Subject to know if the user is in the login process
  public isLogging$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  // Subject for Auth0 member Data
  public userAuthenticated$ = new BehaviorSubject<IAuth0User>(null);
  public userImpersonated$ = new BehaviorSubject<IAuth0User>(null);
  // Subject for General member Data
  public userLogged$ = new ReplaySubject<UserData>(1);
  // Subject for Selected organization
  public organizationSelected = new ReplaySubject<SelectorOrganization | null>(1);
  // Subject to know if the 'Help Dropdown' must be opened
  public helpDropdownVisibility$ = new BehaviorSubject<boolean>(false);

  // Last checked app path
  cachedAppPath: string;

  // List of available app paths
  sectionsTeam1 = ['content', 'myconnections'];
  sectionsTeam3 = ['stats', 'logging', 'speed', 'alerts', 'traffic-optimizer'];
  sectionsTeam7 = [
    'network',
    'roadmap',
    'network-v2',
    'company-profile',
    'members',
    'account',
    'onboarding',
    'user-onboarding',
    'signup',
  ];
  sectionsTeam8 = ['tickets', 'billing', 'dashboard'];

  constructor(
    @Inject('application') application: string,
    private route: ActivatedRoute,
    private platformBrowserService: PlatformBrowserService,
  ) {
    this.isPublicApp = application === 'public';

    this.route.queryParams.subscribe((params) => {
      if (params['search']) {
        this.searchBar.next(params['search']);
      }
    });

    this.organizationSelected.subscribe((response) => {
      this.selectedOrg = response;
    });
  }

  // UTILS
  isBrowser() {
    return this.platformBrowserService.isBrowser();
  }

  getCrmId() {
    return this.selectedOrg?.crmID;
  }

  orgCode() {
    return this.selectedOrg?.code;
  }

  orgCodeSuscription() {
    return this.organizationSelected;
  }

  getOrgLabel() {
    return this.selectedOrg?.label;
  }

  getTypeSelected() {
    return this.selectedOrg?.typeSelected;
  }

  isSeller(): boolean {
    return this.selectedOrg?.typeSelected === 'seller';
  }

  getEmail(): string {
    return this.userAuthenticated$.value?.email || '';
  }

  getImpersonatedEmail(): string {
    return this.userAuthenticated$.value?.impersonation || '';
  }

  getAppPath(): string {
    // List of available app paths
    const appPaths = [...this.sectionsTeam1, ...this.sectionsTeam3, ...this.sectionsTeam7, ...this.sectionsTeam8];
    const url = window.location.href.split('//')[1];
    let foundPath;
    // Checks the previously cached app path in case the url haven't changed since the last
    // execution of this function
    if (this.cachedAppPath && url.includes(this.cachedAppPath)) {
      foundPath = this.cachedAppPath;
    }
    // If not cached matches the current url with the list of appPaths
    for (let i = 0; i < appPaths.length && !foundPath; i++) {
      const path = appPaths[i];
      if (url.includes(path)) {
        foundPath = path;
        this.cachedAppPath = path;
      }
    }
    const splittedUrl = url.split('/');
    const lastSegment = splittedUrl[splittedUrl.length - 1];
    if (foundPath) return foundPath;
    if (lastSegment && lastSegment.length) return lastSegment;
    return 'dashboard';
  }
}
