import { Component, OnInit, ViewChild } from '@angular/core';
import { WebAdminService } from './../../../../../../@core/services/web-admin.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NbStepperComponent } from '@nebular/theme';
import { ActivatedRoute, Router } from '@angular/router';
import { PreAndPostCheckService } from '../../../../services/pre-and-post-check.service';
import { ModalDeleteComponent } from './../../../../../../@core/shared/modal-delete/modal-delete.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { InvoiceService } from '../../../../services/_index-billing.services';
import { NotificationService } from './../../../../../../@core/services/notification.service';

@Component({
  selector: 'tgx-invoicing-process-post-step',
  templateUrl: './invoicing-process-post-step.component.html',
  styleUrls: ['./invoicing-process-post-step.component.scss'],
})
export class InvoicingProcessPostStepComponent implements OnInit {
  @ViewChild('stepper') stepper: NbStepperComponent;

  constructor(
    private webAdminService: WebAdminService,
    private preAndPostCheckService: PreAndPostCheckService,
    private invoiceService: InvoiceService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
    private datePipe: DatePipe,
    private notificationService: NotificationService,
  ) {}

  isEditor = false;
  public dateForm: UntypedFormGroup;

  currentStep: number;

  ngOnInit(): void {
    this.dateForm = this.fb.group({
      date1: ['', Validators.required],
      date2: ['', Validators.required],
    });

    this.buildOptions();
    this.currentStep = 1;
  }

  buildOptions() {
    this.isEditor = this.webAdminService.isBillingEditorOrAdmin();
  }

  finishStepper() {
    this.stepper.reset();
    this.navigateToScheduled();
  }

  onConfigChange() {
    const startDate = this.dateForm.get('date1').value;
    const endDate = this.dateForm.get('date2').value;

    if (startDate && endDate) {
      this.preAndPostCheckService.changePostConfig({ step: this.currentStep, date_from: startDate, date_to: endDate });
    }
  }

  changeCurrentStep(step: number) {
    this.currentStep = step;
    this.onConfigChange();
  }

  navigateToScheduled() {
    this.router.navigate(['../scheduled'], { relativeTo: this.route });
  }

  setInvoices() {
    const modalRef = this.modalService.open(ModalDeleteComponent, {
      backdrop: 'static',
      container: 'nb-layout',
    });

    modalRef.componentInstance.message = `Are you sure that you want to set all invoices from ${this.datePipe.transform(this.dateForm?.get('date2').value, 'MM-yyyy')} visibles automatically?`;
    modalRef.componentInstance.title = 'Make visible invoices';
    modalRef.componentInstance.buttonOkTitle = 'Yes';

    const self = this;

    modalRef.result.then(async (res) => {
      // on close
      if (res) {
        await self.invoiceService
          .setVisibleInvoices(this.datePipe.transform(this.dateForm?.get('date2').value, 'yyyy-MM-dd HH:mm:ss'))
          .then(() => {
            self.stepper.next();
          })
          .catch((err) => {
            this.notificationService.handleGatewayAndGraphqlErrors(err);
          });
      }
    });
  }
}
