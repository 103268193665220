export const getInvoicedDataByMonth = `
query GetInvoicedDataByMonth($date: Date!){
  getInvoicedDataByMonth(startDate: $date) {
    partnerId
    partnerName
    orgCode
    partnerType
    partnerSubType
    contractId
    modelType
    name
    invoiceId
    invoiceAmount
    currency
    concept
    contractConnectionId
    contractPluginId
    buyerCode
    buyerName
    sellerCode
    sellerName
    client
    supplier
    breakdownAmount
    quantity
    invoiceChargeTypeName
    checkinDate
  }
}
`;
