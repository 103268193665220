import { gql } from 'apollo-angular';

export const getChangePaymentRequests = gql`
  query getChangePaymentRequests($where: ChangePaymentRequestWhereInput!) {
    changePaymentRequests(where: $where) {
      id
      contractIdFrom
      contractIdTo
      contractConnectionIdFrom
      contractConnectionIdTo
      currentConnectionType
      requestedConnectionType
      status
      requestUser
      responseUser
      createdAt
      updatedAt
    }
  }
`;
