<div [formGroup]="form" class="xen-select">
  <label class="form-label flex" *ngIf="label" [for]="id">
    {{ label }}
    <span class="is-required" *ngIf="required">*</span>
    <span class="xen-select__tooltip-icon" *ngIf="tooltip" ngbTooltip="{{ tooltip_text }}" tooltipClass="xen-tooltip">
      <i class="fas fa-info-square ms-1"></i>
    </span>
  </label>
  <select
    class="form-select"
    [ngClass]="selectClasses"
    [name]="formControlName"
    [formControlName]="formControlName"
    id=" {{ id }}"
    aria-label="Default select example"
    [disabled]="disabled"
  >
    <option *ngIf="placeholder" value="" disabled>{{ placeholder }}</option>
    <option
      *ngFor="let option of options"
      class="xen-select__option"
      [selected]="option.selected"
      [hidden]="option.hidden"
      [value]="option.value"
      [disabled]="option.disabled"
    >
      {{ option.label }}
    </option>
  </select>
  <div class="xen-select__feedback" [ngClass]="feedbackClasses" *ngIf="validation">{{ feedback }}</div>
</div>
