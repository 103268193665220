<div class="row">
  <div class="col-6">
    <tgx-report-all-connections></tgx-report-all-connections>
  </div>
  <div class="col-6">
    <tgx-report-all-contract-connections></tgx-report-all-contract-connections>
  </div>
</div>
<div class="row">
  <div class="col-6">
    <tgx-report-all-contracts></tgx-report-all-contracts>
  </div>
  <div class="col-6">
    <tgx-report-all-contracts-costs></tgx-report-all-contracts-costs>
  </div>
</div>
<div class="row">
  <div class="col-6">
    <tgx-report-missing-connections></tgx-report-missing-connections>
  </div>
  <div class="col-6">
    <tgx-report-invoiced-data-by-month></tgx-report-invoiced-data-by-month>
  </div>
</div>
