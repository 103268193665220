import {
  ClientsModule,
  GroupContentContextModule,
  LegacyConnectionsModule,
  MembersModule,
  ProvidersModule,
  SuppliersModule,
} from './modules/_index-entities-modules';
import { SharedModule } from './../../@core/shared/shared.module';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { EntitiesComponent } from './entities.component';
import { OrganizationEntitiesService } from './services/organization-entities.service';
import { EntitiesRoutingModule } from './entities-routing.module';
import { AccessesModule } from './modules/accesses/accesses.module';
import { MemberNetworkService, OrganizationNetworkService, AuditoryService } from './services/_index-entities.services';
import { OrganizationsModule } from './modules/organizations/organizations.module';
import { TableModule } from 'primeng/table';

const SERVICES = [OrganizationEntitiesService, MemberNetworkService, OrganizationNetworkService, AuditoryService];

@NgModule({
  imports: [
    SharedModule,
    // Routing
    EntitiesRoutingModule,
    //Custom modules
    ClientsModule,
    LegacyConnectionsModule,
    ProvidersModule,
    SuppliersModule,
    GroupContentContextModule,
    AccessesModule,
    MembersModule,
    OrganizationsModule,
    TableModule,
  ],
  declarations: [EntitiesComponent],
})
export class EntitiesModule {
  static forRoot(): ModuleWithProviders<EntitiesModule> {
    return {
      ngModule: EntitiesModule,
      providers: [...SERVICES],
    };
  }
}
