import { gql } from 'apollo-angular';

export const deleteAccessParameters = gql`
  mutation deleteAccessParameters($where: AccessWhereUniqueInput!, $data: [ID!]!) {
    admin {
      deleteAccessParameters(where: $where, keys: $data) {
        code
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
