<nb-card>
  <nb-card-header> CM23 Agreements without STD costs </nb-card-header>
  <nb-card-body>
    <p-table
      #dt
      [value]="source"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      styleClass="p-datatable-gridlines p-datatable-striped"
      [tableStyle]="{ 'min-width': '50rem' }"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
      [resizableColumns]="true"
      columnResizeMode="expand"
      [loading]="isLoading"
      loadingIcon="pi pi-spin pi-spinner"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let head of header">
            {{ head.label }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-request>
        <tr>
          <td>{{ request.ID }}</td>
          <td>{{ request.PartnerID }}</td>
          <td>{{ request.PartnerType }}</td>
          <td>{{ request.ModelType }}</td>
          <td>{{ request.SubType }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="15" class="text-center">No agreements found.</td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>
