import { gql } from 'apollo-angular';

export const getHotelXGroupsByOrg = gql`
  query ($orgCode: ID!) {
    admin {
      allGroups(orgCode: $orgCode, codeStartsWith: "HotelX") {
        edges {
          node {
            groupData {
              label
              code
            }
          }
        }
        adviseMessage {
          code
          description
        }
      }
    }
  }
`;
