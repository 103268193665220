import { gql } from 'apollo-angular';

export const updateSubCategory = gql`
  mutation ($data: SubCategoryUpdateInput!, $where: SubCategoryWhereUniqueInput!) {
    social {
      updateSubCategory(where: $where, data: $data) {
        code
        createdAt
        updatedAt
        subCategoryData {
          description
          defaultIsBuyer
          defaultIsSeller
          defaultDemandBridge
          defaultSupplyBridge
        }
        adviseMessage {
          code
          description
          level
          external {
            code
            message
          }
        }
      }
    }
  }
`;
