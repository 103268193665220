export const getInconclusiveConnections = `
{
	getInconclusiveConnections {
		buyerCode
		buyerName
		sellerCode
		sellerName
	  connections {
		partnerId
		partnerName
		partnerOrgCode
		contractId
		partnerType
		partnerSubType
		modelType
		connectionType
		connectionId
		conBreakdownId
	  }
	}
  }
`;
