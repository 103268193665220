<xen-modal subtitle="These are the hotels you are going to unmap" modal_title="Unmap these properties">
  <div xenModalBody>
    <xen-summary [size]="'small'" [label]="'Summary'" [icon]="false" [type]="'success'">
      <div #content>
        <p class="mb-0 txt mb-1"><i class="fa-regular fa-link-slash"></i> You are unmapping these properties:</p>
        <ul class="mb-1" *ngFor="let selected of selectedHotels">
          <li>{{ selected.name }}, {{ findNameCountry(selected.country) }}, {{ selected.address }}</li>
        </ul>
        <p class="mb-1 txt">From this master:</p>
        <ul>
          <li>{{ nonValidate.name }}, {{ findNameCountry(nonValidate.country) }}, {{ nonValidate.address }}</li>
        </ul>
      </div>
    </xen-summary>
  </div>
  <div xenModalFooter>
    <div class="d-flex align-items-center gap-2">
      <xen-btn (click)="close()" label="Cancel" size="medium" skin="gray" type="flat"></xen-btn>
      <xen-btn
        label="Unmap these properties"
        size="medium"
        skin="red"
        type="primary"
        [loading]="false"
        (click)="unmapHotelFromMaster()"
      ></xen-btn>
    </div>
  </div>
</xen-modal>
