import { MappingComponent } from './mapping.component';
import { SharedModule } from './../../@core/shared/shared.module';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { MappingRoutingModule } from './mapping-routing.module';
import { MappingToolModule } from './modules/_index-mapping-modules';
import { SharedUiStorybookModule } from '@tgx/shared/ui/storybook';
import { SharedUiStylesModule } from '@tgx/shared/ui/styles';
import { MappingService } from './services/mapping.service';
import { SkeletonModule } from 'primeng/skeleton';

const SERVICES = [MappingService];

@NgModule({
  imports: [
    SharedModule,
    MappingRoutingModule,
    MappingToolModule,
    SharedUiStorybookModule,
    SharedUiStylesModule,
    SkeletonModule,
  ],
  declarations: [MappingComponent],
})
export class MappingModule {
  static forRoot(): ModuleWithProviders<MappingModule> {
    return {
      ngModule: MappingModule,
      providers: [...SERVICES],
    };
  }
}
