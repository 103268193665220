<div>
  <nb-card>
    <nb-card-header>
      <div class="row justify-content-between align-items-center">
        <div class="org-container d-flex align-items-center">
          <h3>Members</h3>
          <h1 class="small-text ms-2">({{ totalMembers }})</h1>
        </div>
        <form [formGroup]="queryForm" class="d-flex align-items-center">
          <span class="p-2">Show archived</span>
          <nb-toggle formControlName="includeArchived" (change)="runQuery()"></nb-toggle>
        </form>
      </div>
    </nb-card-header>

    <nb-card-body class="members-body">
      <p-table
        #td1
        [value]="membersLoadSource"
        [paginator]="true"
        [rows]="10"
        dataKey="code"
        [tableStyle]="{ 'min-width': '60rem' }"
        class="table-members"
        [loading]="isLoading"
        loadingIcon="pi pi-spin pi-spinner"
      >
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 3%"></th>
            <th *ngFor="let header of headers1" [pSortableColumn]="header.field">
              {{ header.label }}
              <p-sortIcon *ngIf="header.field !== 'Options'" [field]="header.field"></p-sortIcon>
            </th>
          </tr>
          <tr>
            <th></th>
            <ng-container *ngFor="let header of headers1">
              <th [style.width]="header.field === 'code' ? '35%' : '15%'">
                <p-columnFilter
                  *ngIf="header.field !== 'Options'"
                  [placeholder]="header.placeholder"
                  [field]="header.field"
                  matchMode="contains"
                  (input)="filterTable($event.target.value, header.field)"
                ></p-columnFilter>
              </th>
            </ng-container>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-member let-expanded="expanded">
          <tr>
            <td>
              <button
                type="button"
                pButton
                pRipple
                [disabled]="!member.organizations.length"
                [pRowToggler]="member"
                class="p-button-text p-button-rounded p-button-plain"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
              ></button>
            </td>
            <td>
              <span [ngClass]="{ 'font-bold': member.organizations.length }" class="ms-2">
                {{ member.code }}
              </span>
            </td>
            <td>
              <p-splitButton
                [label]="member.status"
                [model]="member.statusList"
                [styleClass]="getStatusSeverity(member.status)"
              ></p-splitButton>
            </td>
            <td>
              <i
                *ngIf="!isTG(member?.code)"
                class="nb-shuffle icon"
                title="Impersonate"
                (click)="onImpersonate(member)"
              ></i>
              <i class="nb-plus icon" title="Add to organization" (click)="onAddMemberToOrg(member)"></i>
              <i class="nb-compose icon mx-2" title="Logs" (click)="onLogs(member)"></i>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="rowexpansion" let-member>
          <tr>
            <td colspan="7">
              <div class="p-3">
                <p-table
                  [value]="member.organizations"
                  dataKey="organization"
                  [loading]="isLoadingRoleTable && roleFilter"
                  loadingIcon="pi pi-spin pi-spinner"
                  expandableRows="true"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th style="width: 3%"></th>
                      <th *ngFor="let header of headers2" [pSortableColumn]="header.field">
                        {{ header.label }}
                        <p-sortIcon *ngIf="header.field !== 'Options'" [field]="header.field"></p-sortIcon>
                      </th>
                    </tr>
                  </ng-template>

                  <ng-template pTemplate="body" let-org let-orgExpanded="expanded">
                    <tr>
                      <td>
                        <button
                          type="button"
                          pButton
                          pRipple
                          [disabled]="!org.services?.length"
                          [pRowToggler]="org"
                          class="p-button-text p-button-rounded p-button-plain"
                          [icon]="orgExpanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                        ></button>
                      </td>
                      <td>{{ org.organization }}</td>
                      <td>
                        <p-splitButton
                          [disabled]="org.role === 'OWNER'"
                          [label]="org.role"
                          [model]="org.roleList"
                          styleClass="p-button-secondary"
                        ></p-splitButton>
                      </td>
                      <td>
                        <i class="nb-close icon" title="Remove from organization" (click)="onCancel(org)"></i>
                      </td>
                    </tr>
                  </ng-template>
                  <!-- NEW EXPANSION -->
                  <ng-template pTemplate="rowexpansion" let-org>
                    <tr>
                      <td colspan="4">
                        <div class="p-3">
                          <p-table [value]="org.services" [tableStyle]="{ 'min-width': '50rem' }">
                            <ng-template pTemplate="header">
                              <tr>
                                <th>Service</th>
                                <th>Role</th>
                              </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-service>
                              <tr>
                                <td>{{ service.service.service }}</td>
                                <td>
                                  <p-splitButton
                                    [disabled]="service.role === 'OWNER'"
                                    [label]="service.service.role"
                                    [model]="service.serviceRoleList"
                                    styleClass="p-button-secondary"
                                  ></p-splitButton>
                                </td>
                              </tr>
                            </ng-template>

                            <ng-template pTemplate="emptymessage">
                              <tr>
                                <td colspan="2">No services available.</td>
                              </tr>
                            </ng-template>
                          </p-table>
                        </div>
                      </td>
                    </tr>
                  </ng-template>

                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td colspan="4">No organizations available.</td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </nb-card-body>

    <tgx-spinner *ngIf="isLoading" class="routingLoading" [isLoading]="isLoading" [top]="'100px'"></tgx-spinner>
  </nb-card>
</div>
