import { gql } from 'apollo-angular';

export const getContractConnectionByIds = gql`
  query getContractConnectionByIds($where: ContractConnectionWhereInput!) {
    contractconnections(where: $where) {
      id
      setupPaid
      free
      isBuy
      activationDateTime
      endDate
      connectionType
      connection
    }
  }
`;
