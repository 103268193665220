import { Toast } from './interfaces/toast.interface';
import { ToastsService } from './toasts.service';

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tgx-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss'],
})
export class ToastsComponent implements OnInit {
  toasts: Toast[];

  constructor(private toastsService: ToastsService) {}

  ngOnInit(): void {
    this.toasts = this.toastsService.toasts;
  }

  onClose(toast) {
    const index = this.toasts.findIndex((x) => x === toast);
    if (index !== -1) {
      this.toasts.splice(index, 1);
    }
  }
}
