import { gql } from 'apollo-angular';

export const getAllAccesses = gql`
  query ($where: AccessWhereInput!) {
    admin {
      allAccesses(where: $where) {
        edges {
          node {
            accessData {
              code
              name
              isShared
              owner {
                code
              }
              isTest
              isActive
              isSchedulerActive
              supplier {
                code
                supplierData {
                  isActive
                }
              }
              user
              password
              urls {
                search
                quote
                book
                generic
              }
              parameters {
                key
                value
              }
              markets
              master {
                code
              }
              shared {
                code
              }
              rateRules
              descriptiveInfoLimit
              updateList
              updateDateRange
              updateDescriptiveInfo
            }
            adviseMessage {
              code
              description
            }
            deletedAt
          }
        }
      }
    }
  }
`;
