import { gql } from 'apollo-angular';

export const updateAccess = gql`
  mutation updateAccess($where: AccessWhereUniqueInput!, $data: AccessUpdateInput!) {
    admin {
      updateAccess(where: $where, data: $data) {
        code
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
