import { gql } from 'apollo-angular';

export const getAllMembersV2 = gql`
  query ($type: MembersType!, $contains: String!, $include_archived: Boolean) {
    admin {
      allMembers(where: { membersType: $type, includeArchived: $include_archived, label: $contains }) {
        totalCount
        edges {
          node {
            deletedAt
            membersData {
              code
              label
              isActive
              organizations {
                role
                organization {
                  code
                  label
                }
              }
            }
          }
        }
      }
    }
  }
`;
