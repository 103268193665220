import { gql } from 'apollo-angular';

export const getInvoiceBreakdowns = gql`
  query GetInvoiceBreakdowns($where: InvoiceBreakdownWhereInput!) {
    invoiceBreakdowns(where: $where) {
      id
      concept
      amount
      quantity
      costType
      chargeType
      paymentType
      subDataType
      client
      supplier
      supplierCode
      contractConnection
    }
  }
`;
