import { Injectable } from '@angular/core';
import { CanLoad, Route } from '@angular/router';
import { AdminUserPermissions } from '../interfaces/_index.interfaces';
import { WebAdminService } from '../services/_index-core.services';

@Injectable()
export class BannerGuard implements CanLoad {
  userPermissions: AdminUserPermissions;

  constructor(private webAdminService: WebAdminService) {
    this.webAdminService.userPermissions.subscribe((up) => {
      this.userPermissions = up;
    });
  }

  /**
   * Only lets user to enter when it's logged in.
   * canLoad avoid to load routes or modules if is not logged in for this guard.
   * canActivate load all routes but only can access if the function returns true.
   */
  canLoad(_route: Route): boolean {
    return this.userPermissions?.banner !== undefined;
  }
}
