import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../../../../@core/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuditoryRoutingModule } from './auditory-routing.module';

import { AdminAuditoryTableComponent } from './components/admin-auditory-table/admin-auditory-table.component';

import { AuditoryComponent } from './components/auditory.component';

import {
  NbActionsModule,
  NbCardModule,
  NbCheckboxModule,
  NbIconModule,
  NbRouteTabsetModule,
  NbToggleModule,
} from '@nebular/theme';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';

import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';

@NgModule({
  declarations: [AuditoryComponent, AdminAuditoryTableComponent],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AuditoryRoutingModule,
    NbActionsModule,
    NbCardModule,
    NbCheckboxModule,
    NbIconModule,
    MultiSelectModule,
    DropdownModule,
    NbToggleModule,
    TableModule,
    InputSwitchModule,

    TableModule,
    CheckboxModule,
    ButtonModule,
    SharedModule,
    // Routing
    NbRouteTabsetModule,
    NbCardModule,
    NbCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MultiSelectModule,
    CalendarModule,
    DropdownModule,
  ],
})
export class AuditoryModule {}
