import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'xen-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderComponent {
  @Input() value: number;
  @Input() range: boolean;
  @Input() min: number;
  @Input() max: number;
  @Input() disabled: boolean;

  @Input() rangeValues: number[];
  @Output() rangeValuesChange = new EventEmitter<number[]>();

  @Output() userChangeEnd = new EventEmitter<any>();

  onUserChange(event) {
    this.userChangeEnd.emit(event);
  }
}
