<!-- Normal usage -->
<xen-badge
  [type]="'label'"
  [status]="'info'"
  [theme]="'dark'"
  [label]="'Hover me'"
  [placement]="placement"
  popoverClass="xen-popover"
  triggers="mouseenter:mouseleave"
  [ngbPopover]="label"
  [popoverTitle]="title"
  *ngIf="!template"
></xen-badge>

<!-- Template usage -->
<xen-badge
  [type]="'label'"
  [status]="'info'"
  [theme]="'dark'"
  [label]="'Click me'"
  [placement]="placement"
  popoverClass="xen-popover"
  [ngbPopover]="popContent"
  [popoverTitle]="popTitle"
  *ngIf="template"
></xen-badge>

<ng-template #popTitle>{{ title }}</ng-template>
<ng-template #popContent>
  <table class="xen-table">
    <thead>
      <tr>
        <th><a class="sortable sortable--up">Monday</a></th>
        <th><a class="sortable sortable--down">Tuesday</a></th>
        <th>Wednesday</th>
        <th>Thursday</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Bread</td>
        <td>Bread</td>
        <td>Cookies</td>
        <td>Juice</td>
      </tr>
    </tbody>
  </table>
</ng-template>
