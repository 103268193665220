import { gql } from 'apollo-angular';

export const getLastBillScheduled = gql`
  {
    getAllBillScheduled(take: 1, orderBy: { id: desc }) {
      id
      startDate
      endDate
      executionDate
      startingDate
      finishDate
      idBlob
      createdAt
      updatedAt
    }
  }
`;
