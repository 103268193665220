import { gql } from 'apollo-angular';

export const grantAccessToGroups = gql`
  mutation ($where: AccessWhereUniqueInput!, $groupCodes: [ID!]) {
    admin {
      grantAccessToGroups(where: $where, groupCodes: $groupCodes) {
        accessData {
          code
        }
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
