import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../../../@core/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SuppliersTableComponent } from './components/suppliers-table/suppliers-table.component';
import { SuppliersComponent } from './components/suppliers.component';
import { SuppliersRoutingModule } from './suppliers-routing.module';

import { NbActionsModule, NbCardModule, NbCheckboxModule, NbIconModule, NbToggleModule } from '@nebular/theme';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { SupplierModalComponent } from './components/supplier-modal/supplier-modal.component';
import { GiataModalComponent } from './components/giata-modal/giata-modal.component';
import { SupplierToggleActiveModalComponent } from './components/supplier-ToggleActive-modal/supplier-ToggleActive-modal.component';
import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [
    GiataModalComponent,
    SuppliersComponent,
    SuppliersTableComponent,
    SupplierModalComponent,
    SupplierToggleActiveModalComponent,
  ],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NbActionsModule,
    NbCardModule,
    NbCheckboxModule,
    NbIconModule,
    SuppliersRoutingModule,
    MultiSelectModule,
    DropdownModule,
    NbToggleModule,
    TableModule,
    InputSwitchModule,
    ButtonModule,
  ],
})
export class SuppliersModule {}
