<div class="letter-carousel-container" (wheel)="onScroll($event)">
  <button class="nav-button" (click)="prevPage()" [disabled]="startIndex === 0">❮</button>
  <div class="letters">
    <button
      *ngFor="let letter of visibleLetters"
      (click)="selectLetter(letter)"
      class="letter-button"
      [class.selected]="letter === selectedLetter"
    >
      {{ letter }}
    </button>
  </div>
  <button class="nav-button" (click)="nextPage()" [disabled]="startIndex + lettersPerPage >= alphabetLength">❯</button>
</div>
