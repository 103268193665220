import { gql } from 'apollo-angular';

export const getAllClientsByOwner = gql`
  query ($where: ClientWhereInput) {
    admin {
      allClients(where: $where) {
        totalCount
        edges {
          node {
            clientData {
              code
              name
              isActive
              owner {
                code
                organizationData {
                  label
                }
              }
              group {
                code
              }
            }
            deletedAt
          }
        }
      }
    }
  }
`;
