import { Member } from './member.interface';

export interface PageInfo {
  endCursor: string;
  hasNextPage: boolean;
}

export interface DescendentNode {
  code?: string;
}
export interface DescendentsEdges {
  node?: DescendentNode;
}

export interface Descendents {
  edges?: DescendentsEdges[];
}

export interface GroupData {
  id: string;
  code: string;
  label?: string;
  isBuyer: boolean;
  isSeller: boolean;
  isPublic?: boolean;
  owner: Member;
  descendents?: Descendents;
}
export interface GroupNode {
  groupData: GroupData;
}
export interface Group {
  code: string;
  node: GroupNode;
  createdAt: Date;
  updatedAt: Date;
}
export interface OrganizationResponse {
  totalCount: number;
  pageInfo: PageInfo;
  edges: Group[];
}

export interface GroupEdges {
  totalCount: number;
  pageInfo: PageInfo;
  edges: Group[];
}
