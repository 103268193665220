import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'tgx-member-modal',
  templateUrl: './update-member-role-modal.component.html',
  styleUrls: ['./update-member-role-modal.component.scss'],
})
export class UpdateRoleMemberComponent {
  @Input() member: string;
  @Input() message: string;

  constructor(private activeModal: NgbActiveModal) {}

  onSave() {
    this.activeModal.dismiss(true);
  }
  onCancel() {
    this.activeModal.dismiss();
  }
}
