import { gql } from 'apollo-angular';

export const createBillingAccount = gql`
  mutation createBillingAccount($data: BillingAccountCreateInput!) {
    createBillingAccount(data: $data) {
      id
      name
      cif
      countryCode
      fiscalAddress
      createdAt
      updatedAt
      odooCode
      singleInvoice
    }
  }
`;
