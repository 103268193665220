// ----------------- UTILS -----------------
export function isObject(obj: any): boolean {
  return obj !== null && typeof obj === 'object';
}

export function deepEqual(objA: any, objB: any): boolean {
  if (objA === objB) {
    return true;
  }

  if (!isObject(objA) || !isObject(objB)) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  for (const key of keysA) {
    if (!keysB.includes(key) || !deepEqual(objA[key], objB[key])) {
      return false;
    }
  }

  return true;
}
