import { Component, Input, OnChanges, OnInit, forwardRef } from '@angular/core';
import { FormGroup, FormGroupDirective, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'xen-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TextareaComponent),
    },
  ],
})
export class TextareaComponent implements OnInit, OnChanges {
  @Input() label: string;
  @Input() id: string;
  @Input() name: string;
  @Input() rows = 4;
  @Input() cols = 50;
  @Input() content: string;
  @Input() formControlName: string;
  @Input() formGroup: any;
  @Input() required: boolean;
  @Input() tooltip: boolean;
  @Input() tooltip_text: string;
  @Input() tooltip_placement: string;
  @Input() placeholder: string;
  @Input() validation: boolean;
  @Input() validation_status: string;
  @Input() feedback: string;
  @Input() disabled: boolean;
  @Input() skin: 'default' | 'gray' = 'default';

  tooltipText: string;
  form: FormGroup;

  value: string;
  onChange() {}
  onTouched() {}

  constructor(private rootFormGroup: FormGroupDirective) {}

  ngOnInit(): void {
    this.form = this.rootFormGroup.control;

    if (this.disabled) {
      this.form.controls[this.formControlName].disable();
    }
  }
  ngOnChanges(): void {
    this.form = this.rootFormGroup.control;
    if (this.disabled && this.form && !this.form.get(this.formControlName).disabled) {
      this.form.get(this.formControlName).disable();
    } else if (this.form && !this.form.get(this.formControlName).enabled) {
      this.form.get(this.formControlName).enable();
    }
  }

  public get labelClasses(): Array<string> {
    return [this.required ? 'is-required' : ''];
  }
  public get inputClasses(): Array<string> {
    return [
      'form-control',
      this.validation && this.validation_status ? `${this.validation_status}` : '',
      `${this.skin}-skin`,
    ];
  }
  public get feedbackClasses(): Array<string> {
    return [
      this.validation && this.validation_status === 'is-valid' ? 'valid-feedback' : '',
      this.validation && this.validation_status === 'is-invalid' ? 'invalid-feedback' : '',
    ];
  }

  public get formGroupTag(): Array<string> {
    return [this.formGroup ? this.formGroup : ''];
  }

  writeValue(value) {
    this.value = value;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }
}
