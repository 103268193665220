import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Options } from './../../../../../../../@core/interfaces/_index.interfaces';
import { NotificationService } from './../../../../../../../@core/services/notification.service';
import { WebAdminService } from './../../../../../../../@core/services/web-admin.service';
import { Subscription } from 'rxjs';
import { OrganizationSelector } from './../../../../../../../features/entities/interfaces/_index-entities.interfaces';
import {
  ContractService,
  PartnerService,
  InvoiceService,
  ContractConnectionService,
  ConnectionService,
} from '../../../../../services/_index-billing.services';
import {
  Contract,
  Partner,
  ContractConnection,
  ConnectionFullInfo,
} from '../../../../../interfaces/_index.billing.interfaces';
import { formatDate } from '@angular/common';
import { saveAs } from 'file-saver';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDeleteComponent } from './../../../../../../../@core/shared/modal-delete/modal-delete.component';

@Component({
  selector: 'tgx-admin-billing-invoice-create',
  templateUrl: './invoice-create.component.html',
  styleUrls: ['./invoice-create.component.scss'],
})
export class BillingInvoiceCreateComponent implements OnInit, OnDestroy {
  public createForm: UntypedFormGroup;
  contractsSelector: Options[] = [{ code: null, label: 'Without contracts' }];
  selectedContract: Options;

  billingTypeSelector: Options[] = [];

  billingChargeSelector: Options[] = [
    { code: 'All', label: 'All' },
    { code: 'Setup', label: 'Setup' },
    { code: 'Maintenance', label: 'Maintenance' },
    { code: 'Traffic', label: 'Traffic' },
    { code: 'Gmv', label: 'Gmv' },
    { code: 'Excess', label: 'Excess' },
    { code: 'Bookings', label: 'Bookings' },
  ];

  $subs: Subscription[] = [];
  orgSelected: OrganizationSelector;

  contracts: Contract[];
  partner: Partner;

  isFree = false;
  isLoading: boolean;
  isEditor: boolean;

  isRappel = false;

  connections: any[];
  connectionsSelector: any[];

  constructor(
    private notificationService: NotificationService,
    private webAdminService: WebAdminService,
    private fb: UntypedFormBuilder,
    private partnerService: PartnerService,
    private contractService: ContractService,
    private invoiceService: InvoiceService,
    private contractConnectionService: ContractConnectionService,
    private connectionService: ConnectionService,
    private modalService: NgbModal,
  ) {}

  async ngOnInit() {
    this.buildBillingTypeSelector();
    this.buildForm();

    this.buildOptions();

    if (this.isEditor) {
      this.$subs.push(
        this.webAdminService.orgSelected.subscribe(async (orgSelected) => {
          if (orgSelected?.code) {
            this.orgSelected = orgSelected;
            await this.partnerService.getPartner(orgSelected.code).then(async (partner) => {
              if (partner) {
                this.partner = partner;
                this.createForm.get('partner').setValue(this.partner.id);
                await this.getContracts(this.partner.orgCode);
              } else {
                this.partner = null;
                this.withoutContracts();
                this.notificationService.warning('This partner doesnt exists in billing section.');
              }
            });
          }
        }),
      );

      this.createForm.get('billingType').setValue(this.billingTypeSelector[0]);
      this.createForm.get('billingCharge').setValue(this.billingChargeSelector[0]);
    }
  }

  ngOnDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
  }

  buildBillingTypeSelector() {
    this.billingTypeSelector = [
      { code: 'billing_process', label: 'Billing Process' },
      { code: 'free', label: 'Free' },
    ];
    if (this.selectedContract?.label?.endsWith('CM23')) {
      this.billingTypeSelector.push({ code: 'rappel_discounts', label: 'Rappel discounts' });
    }
  }

  buildForm() {
    this.createForm = this.fb.group({
      partner: ['', Validators.required],
      contract: ['', Validators.required],
      billingType: ['', Validators.required],
      billingCharge: [''],
      contractConnectionId: [''],
      start: ['', Validators.required],
      end: ['', Validators.required],
      concept: [''],
      amount: [''],
    });
  }

  autoCompleteConnections(event: any) {
    this.connectionsSelector = [];
    const query = event.query.toLowerCase();
    this.connectionsSelector = this.connections.filter((x) => x.name.toLowerCase().includes(query));
  }

  resetForm() {
    this.createForm.get('partner').setValue(this.partner.id);
    this.createForm.get('contract').setValue(Number(this.selectedContract.code));
    this.createForm.get('billingType').setValue(this.billingTypeSelector[0]);
    this.createForm.get('billingCharge').setValue(this.billingChargeSelector[0]);
    this.createForm.get('contractConnectionId').setValue(null);
    this.createForm.get('start').setValue('');
    this.createForm.get('start').setErrors(null);
    this.createForm.get('end').setValue('');
    this.createForm.get('end').setErrors(null);
    this.createForm.get('concept').setValue('');
    this.createForm.get('amount').setValue('');
    this.createForm.get('concept').setErrors(null);
    this.createForm.get('amount').setErrors(null);
    this.createForm.get('billingCharge').enable();
    this.createForm.get('concept').clearValidators();
    this.createForm.get('amount').clearValidators();
    this.isFree = false;
  }

  async getContracts(orgCode: string) {
    if (orgCode) {
      await this.contractService
        .getContracts(orgCode)
        .then(async (cnts) => {
          if (cnts?.length > 0) {
            this.contractsSelector = [];
            this.contracts = cnts;

            if (this.contracts?.length === 0) {
              this.withoutContracts();
            } else {
              this.buildContractSelector();
              this.selectedContract = this.contractsSelector[0];
              this.createForm.get('contract').setValue(Number(this.selectedContract.code));
              await this.getConnections();
            }
          } else {
            this.withoutContracts();
          }
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    }
  }

  buildOptions() {
    this.isEditor = this.webAdminService.isBillingEditorOrAdmin();
  }

  buildContractSelector() {
    if (this.contracts?.length > 0) {
      this.contracts.forEach((c) => {
        this.contractsSelector.push({
          code: c.id.toString(),
          label:
            '(' +
            c.id +
            ') ' +
            c.partnerType +
            (c.modelType ? ' - ' + c.modelType : '') +
            (c.ContractType ? ' - ' + c.ContractType.name : ''),
        });
      });
    }
  }

  async getConnections() {
    if (this.selectedContract?.code) {
      const contractConnections = await this.contractConnectionService.getContractConnections(
        Number(this.selectedContract?.code),
      );

      const connectionCodes: number[] = [];

      if (contractConnections?.length > 0) {
        contractConnections.forEach((con) => {
          const index = connectionCodes.findIndex((x) => x === con.connection);

          if (index === -1) {
            connectionCodes.push(con.connection);
          }
        });
      }

      let connections: ConnectionFullInfo[] = [];

      if (connectionCodes.length > 0) {
        await this.connectionService
          .getConnectionsFullInfoById(connectionCodes)
          .then((rs) => {
            connections = rs;
          })
          .catch((err) => {
            this.notificationService.handleGatewayAndGraphqlErrors(err);
            this.isLoading = false;
          });
      }

      if (contractConnections?.length > 0) {
        await this.buildConnectionsSelector(contractConnections, connections);
      } else {
        this.withoutConnections();
      }
    } else {
      this.withoutConnections();
    }
  }

  withoutConnections() {
    this.connectionsSelector = [];
    this.connectionsSelector.push({ code: null, name: 'Without connections' });
  }

  async buildConnectionsSelector(contractConnections: ContractConnection[], connections: ConnectionFullInfo[]) {
    this.connectionsSelector = [];
    contractConnections.forEach((con) => {
      const index = connections.findIndex((x) => x.id === con.connection);

      if (index > -1) {
        this.connectionsSelector.push({
          code: con.id.toString(),
          name: `ContractConnectionId: ${con.id} Buyer: ${connections[index].buyerName} (${connections[index].buyerCode}) Seller: ${connections[index].sellerName} (${connections[index].sellerCode})`,
        });
      }
    });
  }

  withoutContracts() {
    this.contractsSelector = [];
    this.contractsSelector.push({ code: null, label: 'Without contracts' });
  }

  changeBillingType() {
    if (this.createForm.get('billingType').value.code === 'billing_process') {
      this.createForm.get('billingCharge').enable();
      this.createForm.get('contractConnectionId').enable();
      this.createForm.get('concept').clearValidators();
      this.createForm.get('amount').clearValidators();
      this.createForm.get('concept').setErrors(null);
      this.createForm.get('amount').setErrors(null);
      this.createForm.get('concept').updateValueAndValidity();
      this.createForm.get('amount').updateValueAndValidity();
      this.isFree = false;
      this.isRappel = false;
    } else if (this.createForm.get('billingType').value.code === 'free') {
      this.createForm.get('billingCharge').disable();
      this.createForm.get('contractConnectionId').disable();
      this.createForm.get('contractConnectionId').setValue(null);
      this.createForm.get('concept').addValidators(Validators.required);
      this.createForm.get('amount').addValidators(Validators.required);
      this.createForm.get('concept').setErrors(null);
      this.createForm.get('amount').setErrors(null);
      this.createForm.get('concept').updateValueAndValidity();
      this.createForm.get('amount').updateValueAndValidity();
      this.createForm.get('concept').markAsUntouched();
      this.createForm.get('amount').markAsUntouched();
      this.isFree = true;
      this.isRappel = false;
    } else if (this.createForm.get('billingType').value.code === 'rappel_discounts') {
      this.createForm.get('billingCharge').disable();
      this.createForm.get('contractConnectionId').disable();
      this.createForm.get('contractConnectionId').setValue(null);
      this.createForm.get('concept').addValidators(Validators.required);
      this.createForm.get('amount').addValidators(Validators.required);
      this.createForm.get('concept').setErrors(null);
      this.createForm.get('amount').setErrors(null);
      this.createForm.get('concept').updateValueAndValidity();
      this.createForm.get('amount').updateValueAndValidity();
      this.createForm.get('concept').markAsUntouched();
      this.createForm.get('amount').markAsUntouched();
      this.isFree = false;
      this.isRappel = true;
    }
  }

  validForm(): boolean {
    if (this.createForm.get('billingType').value.code === 'free') {
      return (
        this.createForm.get('start').value &&
        this.createForm.get('end').value &&
        this.createForm.get('concept').value &&
        this.createForm.get('amount').value &&
        Number(this.createForm.get('amount').value) > 0
      );
    } else {
      return this.createForm.get('start').value && this.createForm.get('end').value;
    }
  }

  async runCreate() {
    const billingType = this.createForm.get('billingType').value.code;
    const billingCharge = this.createForm.get('billingCharge').value.code;
    const startDate = formatDate(this.createForm.get('start').value, 'yyyy-MM-dd', 'en-US');
    const endDate = formatDate(this.createForm.get('end').value, 'yyyy-MM-dd', 'en-US');
    const concept = this.createForm.get('concept').value
      ? this.createForm.get('concept').value.replaceAll('-', '')
      : '';
    const amount = Number(this.createForm.get('amount').value);

    const modalRef = this.modalService.open(ModalDeleteComponent, {
      backdrop: 'static',
      container: 'nb-layout',
    });

    modalRef.componentInstance.message = 'Are you sure that you want to create a new invoice with these parameters?';
    modalRef.componentInstance.title = 'Create Invoice';
    modalRef.componentInstance.buttonOkTitle = 'Bill';

    const self = this;

    let contractConnectionIds = '';

    const contractConnectionIdsInt: number[] = [];

    if (this.createForm.get('contractConnectionId').value) {
      const connections = Object.values(self.createForm.get('contractConnectionId').value);
      if (connections.length > 0) {
        connections.forEach((item) => {
          contractConnectionIdsInt.push(Number(item['code']));
        });
      }
    }

    if (contractConnectionIdsInt.length > 0) {
      contractConnectionIds = contractConnectionIdsInt.join(',');
    }

    modalRef.result
      .then(async (res) => {
        // on close
        if (res) {
          this.isLoading = true;

          if (billingType !== 'rappel_discounts') {
            await this.invoiceService
              .createInvoiceManually(
                this.partner.id,
                Number(this.selectedContract.code),
                billingType,
                billingCharge,
                startDate,
                endDate,
                concept,
                amount,
                contractConnectionIds,
                '',
              )
              .then((file) => {
                const fileName = 'billing_' + endDate + '.json';
                saveAs(file, fileName);
                this.notificationService.success(
                  'Invoice succesfully generated and ' + fileName + ' downloaded!',
                  'Success',
                );
              })
              .catch((err) => {
                const parsedErr = JSON.parse(err.error);
                this.notificationService.handleGatewayAndGraphqlErrors(parsedErr.errors[0]);
              })
              .finally(() => {
                this.isLoading = false;
              });
          } else {
            await this.invoiceService
              .createRappelInvoiceDiscount(this.partner.id, Number(this.selectedContract.code), startDate, endDate)
              .then((file) => {
                const fileName = 'billing_' + endDate + '.json';
                saveAs(file, fileName);
                this.notificationService.success(
                  'Invoice succesfully generated and ' + fileName + ' downloaded!',
                  'Success',
                );
              })
              .catch((err) => {
                const parsedErr = JSON.parse(err.error);
                this.notificationService.handleGatewayAndGraphqlErrors(parsedErr.errors[0]);
              })
              .finally(() => {
                this.isLoading = false;
              });
          }
        }
      })
      .catch((error) => {
        self.notificationService.handleGatewayAndGraphqlErrors(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  checkAmountType() {
    const billingType = this.createForm.get('billingType').value.code;

    if (this.createForm.get('amount').value) {
      const amount = Number(this.createForm.get('amount').value);

      if (amount < 0 && billingType === 'free') {
        this.notificationService.warning(
          'If you create a negative invoice you have to use the other option (Credit Note).',
        );
      }
    }
  }
}
