<div class="modal-header">
  <span>{{ header }}</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="providerForm">
    <nb-card>
      <nb-card-header> Provider </nb-card-header>
      <nb-card-body>
        <div class="row"></div>
        <div class="row mt-2">
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger': providerForm.get('code')?.errors && providerForm.get('code').touched,
              }"
            >
              <label class="mb-1" for="label">Code</label>
              <tooltip-error [element]="providerForm.get('code')" [errors]="providerForm.get('code').errors">
              </tooltip-error>
              <input
                type="text"
                class="form-control"
                formControlName="code"
                [ngClass]="{
                  'form-control-danger': providerForm.get('code')?.errors && providerForm.get('code').touched,
                }"
                placeholder="Enter a valid code"
                name="code"
              />
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger': providerForm.get('name')?.errors && providerForm.get('name').touched,
              }"
            >
              <label class="mb-1" for="label">Name</label>
              <tooltip-error [element]="providerForm.get('name')" [errors]="providerForm.get('name').errors">
              </tooltip-error>
              <input
                type="text"
                class="form-control"
                formControlName="name"
                [ngClass]="{
                  'form-control-danger': providerForm.get('name')?.errors && providerForm.get('name').touched,
                }"
                placeholder="Enter a valid name"
                name="name"
              />
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="form-group">
            <label class="mb-1" for="isActive">Is Active</label>
            <tooltip-error [element]="providerForm.get('isActive')" [errors]="providerForm.get('isActive').errors">
            </tooltip-error>
            <nb-toggle
              class="form-control"
              [ngClass]="{
                'form-control-danger': providerForm.get('isActive').errors && providerForm.get('isActive').touched,
              }"
              name="isActive"
              formControlName="isActive"
            ></nb-toggle>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()">Close</button>
  <button class="btn btn-md btn-primary" [disabled]="providerForm.invalid" (click)="onSave()">
    {{ isCreating ? 'Create' : 'Update' }}
  </button>
</div>
