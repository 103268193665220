<nb-card size="large">
  <nb-card-header>Best 5 Partners</nb-card-header>
  <nb-card-body>
    <tgx-spinner [class]="'routingLoading'" [isLoading]="isLoading" [top]="'150px'"></tgx-spinner>
    <ngx-charts-bar-vertical
      [scheme]="colorScheme"
      [results]="partners"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [legend]="showLegend"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel"
    >
    </ngx-charts-bar-vertical>
  </nb-card-body>
</nb-card>
