import { gql } from 'apollo-angular';

export const createContact = gql`
  mutation createContact($data: BillingAccountContactCreateInput!) {
    createBillingAccountContact(data: $data) {
      id
      email
      receiveInvoices
      createdAt
      updatedAt
    }
  }
`;
