import { gql } from 'apollo-angular';

export const setAccessShared = gql`
  mutation setAccessShared($where: AccessWhereUniqueInput!, $sharedCode: ID!) {
    admin {
      setAccessShared(where: $where, sharedCode: $sharedCode) {
        code
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
