import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'xen-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopoverComponent {
  @Input() placement: 'top' | 'end' | 'bottom' | 'start' = 'top';
  @Input() title: string;
  @Input() label: string;
  @Input() template: boolean;
}
