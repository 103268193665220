<div class="modal-header">
  <span>{{ header }}</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="billingAccountForm">
    <nb-card>
      <nb-card-header> Data </nb-card-header>
      <nb-card-body>
        <div class="row" *ngIf="!isCreating">
          <div class="col-2">
            <div class="form-group">
              <label for="id"
                >ID
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="billingAccountForm.get('id')"
                [errors]="billingAccountForm.get('id').errors"
              ></tooltip-error>
              <input
                type="text"
                class="form-control input-disabled"
                [ngClass]="{
                  'form-control-danger': billingAccountForm.get('id').errors && billingAccountForm.get('id').touched,
                }"
                name="id"
                formControlName="id"
              />
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger': billingAccountForm.get('name').errors && billingAccountForm.get('name').touched,
              }"
            >
              <label for="name"
                >Name
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="billingAccountForm.get('name')"
                [errors]="billingAccountForm.get('name').errors"
              ></tooltip-error>
              <input
                type="text"
                class="form-control"
                formControlName="name"
                [ngClass]="{
                  'form-control-danger':
                    billingAccountForm.get('name').errors && billingAccountForm.get('name').touched,
                }"
                placeholder="Enter a name..."
                name="name"
              />
            </div>
          </div>
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger': billingAccountForm.get('cif').errors && billingAccountForm.get('cif').touched,
              }"
            >
              <label for="cif"
                >Cif
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="billingAccountForm.get('cif')"
                [errors]="billingAccountForm.get('cif').errors"
              ></tooltip-error>
              <input
                type="text"
                class="form-control"
                formControlName="cif"
                [ngClass]="{
                  'form-control-danger': billingAccountForm.get('cif').errors && billingAccountForm.get('cif').touched,
                }"
                placeholder="Enter a cif code.."
                name="cif"
              />
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <div class="form-group">
              <label for="countryCode"
                >CountryCode
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="billingAccountForm.get('countryCode')"
                [errors]="billingAccountForm.get('countryCode').errors"
              ></tooltip-error>
              <p-autoComplete
                [inputStyle]="{ 'width': '332px' }"
                [formControl]="billingAccountForm.get('countryCode')"
                [suggestions]="countriesAutocomplete"
                field="label"
                class="form-control"
                [ngClass]="{
                  'form-control-danger':
                    billingAccountForm.get('countryCode').errors && billingAccountForm.get('countryCode').touched,
                }"
                (completeMethod)="autoCompleteCountries($event)"
              ></p-autoComplete>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="excludeVAT">Exclude VAT</label>
              <nb-checkbox class="form-control" name="excludeVAT" formControlName="excludeVAT"></nb-checkbox>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <div class="form-group">
              <label for="fiscalAddress"
                >Fiscal Address
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="billingAccountForm.get('fiscalAddress')"
                [errors]="billingAccountForm.get('fiscalAddress').errors"
              ></tooltip-error>
              <input
                type="text"
                class="form-control"
                formControlName="fiscalAddress"
                [ngClass]="{
                  'form-control-danger':
                    billingAccountForm.get('fiscalAddress').errors && billingAccountForm.get('fiscalAddress').touched,
                }"
                placeholder="Enter a fiscal address..."
                name="fiscalAddress"
              />
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <div class="form-group">
              <label for="city"
                >City
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="billingAccountForm.get('city')"
                [errors]="billingAccountForm.get('city').errors"
              ></tooltip-error>
              <input
                type="text"
                class="form-control"
                formControlName="city"
                [ngClass]="{
                  'form-control-danger':
                    billingAccountForm.get('city').errors && billingAccountForm.get('city').touched,
                }"
                placeholder="Enter a city name..."
                name="city"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="zipCode"
                >Zip Code
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="billingAccountForm.get('zipCode')"
                [errors]="billingAccountForm.get('zipCode').errors"
              ></tooltip-error>
              <input
                type="text"
                class="form-control"
                formControlName="zipCode"
                [ngClass]="{
                  'form-control-danger':
                    billingAccountForm.get('zipCode').errors && billingAccountForm.get('zipCode').touched,
                }"
                placeholder="Enter a zip code..."
                name="zipCode"
              />
            </div>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()">{{ isEditor ? 'Cancel' : 'Close' }}</button>
  <button
    *ngIf="isEditor"
    class="btn btn-md btn-primary"
    [disabled]="billingAccountForm.invalid || !this.isEditor"
    (click)="onSave()"
  >
    {{ isCreating ? 'Create' : 'Update' }}
  </button>
</div>
