import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Contract, Partner, ContractPlugin } from '../../../../../interfaces/_index.billing.interfaces';
import {
  PartnerService,
  ContractService,
  ContractPluginService,
} from '../../../../../services/_index-billing.services';
import { NotificationService, WebAdminService } from './../../../../../../../@core/services/_index-core.services';
import { OrganizationSelector } from './../../../../../../entities/interfaces/_index-entities.interfaces';
import { Options } from './../../../../../../../@core/interfaces/_index.interfaces';
import { Table } from 'primeng/table';
import { BillingPluginsModalComponent } from '../plugins-modal/plugins-modal.component';

@Component({
  selector: 'tgx-admin-billing-plugins-table',
  templateUrl: './plugins-table.component.html',
  styleUrls: ['./plugins-table.component.scss'],
})
export class BillingPluginsTableComponent implements OnInit, OnDestroy {
  @ViewChild('dt', { static: false }) dataTable!: Table;
  $subs: Subscription[] = [];

  header = [
    { label: 'ID', field: 'id' },
    { label: 'ActivationDate', field: 'activationDateTime' },
    { label: 'EndDate', field: 'endDate' },
    { label: 'PluginType', field: 'pluginType' },
    { label: 'Code', field: 'code' },
    { label: 'CreatedAt', field: 'createdAt' },
    { label: 'UpdatedAt', field: 'updatedAt' },
    { label: 'Options', field: 'Options' },
  ];

  pluginsLoadSource: any[];

  contracts: Contract[];

  plugins: ContractPlugin[];

  isLoading: boolean;

  orgSelected: OrganizationSelector;
  partner: Partner;

  contractsSelector: Options[] = [{ code: null, label: 'Without contracts' }];
  selectedContract: Options;

  isEditor = false;

  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private webAdminService: WebAdminService,
    private partnerService: PartnerService,
    private contractService: ContractService,
    private contractPluginService: ContractPluginService,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;

    this.buildOptions();

    this.$subs.push(
      this.webAdminService.orgSelected.subscribe(async (orgSelected) => {
        if (orgSelected?.code) {
          this.orgSelected = orgSelected;
          await this.partnerService.getPartner(orgSelected.code).then((partner) => {
            if (partner) {
              this.partner = partner;
              this.getContracts(this.partner.orgCode);
            } else {
              this.partner = null;
              this.isLoading = false;
            }
          });
        }
      }),
    );
  }

  ngOnDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
  }

  buildOptions() {
    this.isEditor = this.webAdminService.isBillingEditorOrAdmin();
  }

  getContracts(orgCode: string) {
    if (orgCode) {
      this.contractService
        .getContracts(orgCode)
        .then(async (cnts) => {
          this.contracts = cnts;
          if (this.contracts?.length > 0) {
            this.contractsSelector = [];
            this.buildSelector();
            this.selectedContract = this.contractsSelector[0];
            await this.getPlugins();
          }
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  buildSelector() {
    if (this.contracts?.length > 0) {
      this.contracts.forEach((c) => {
        this.contractsSelector.push({
          code: c.id.toString(),
          label:
            '(' +
            c.id +
            ') ' +
            c.partnerType +
            (c.modelType ? ' - ' + c.modelType : '') +
            (c.ContractType ? ' - ' + c.ContractType.name : ''),
        });
      });
    }
  }

  async getPlugins() {
    this.isLoading = true;
    if (this.dataTable) {
      this.dataTable.clearFilterValues();
    }

    this.pluginsLoadSource = [];

    if (this.selectedContract?.code) {
      await this.contractPluginService
        .getContractPlugins(Number(this.selectedContract?.code))
        .then((rs) => {
          this.plugins = rs;
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
          this.isLoading = false;
        });

      if (this.plugins?.length > 0) {
        this.plugins?.forEach((p) => {
          const { id, activationDateTime, endDate, code, createdAt, updatedAt } = p;
          const pluginType = p.Plugin?.pluginType;

          this.pluginsLoadSource.push({
            id,
            activationDateTime,
            endDate,
            code,
            pluginType,
            createdAt,
            updatedAt,
          });
        });
        this.pluginsLoadSource = [...this.pluginsLoadSource];
        this.pluginsLoadSource.sort((a, b) => (a.id < b.id ? 1 : -1));

        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    } else {
      this.isLoading = false;
    }
  }

  async onCreate(): Promise<void> {
    if (this.selectedContract.code === null) {
      this.notificationService.warning('You can not create a plugin, first you must to create a contract.');
      return;
    }

    const activeModal = this.modalService.open(BillingPluginsModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = 'Create Contract Plugin';
    activeModal.componentInstance.contractId = Number(this.selectedContract.code);
    activeModal.componentInstance.isEditor = this.isEditor;

    const self = this;
    activeModal.result.then(async (res) => {
      // on close

      if (typeof res === 'boolean' && res) {
        //todo do nothing at the moment
      } else if (typeof res === 'boolean' && !res) {
        //todo do nothing at the moment
      }
      // OK
      else if (typeof res === 'object') {
        await self.getPlugins();
      }
    });
  }

  async onEdit(event): Promise<void> {
    const plugin: ContractPlugin = event;

    const activeModal = this.modalService.open(BillingPluginsModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = (this.isEditor ? 'Edit ' : 'View ') + 'Contract Plugin';
    activeModal.componentInstance.plugin = plugin;
    activeModal.componentInstance.contractId = Number(this.selectedContract.code);
    activeModal.componentInstance.isEditor = this.isEditor;

    const self = this;
    activeModal.result.then(async (res) => {
      // on close

      if (typeof res === 'boolean' && res) {
        //todo do nothing at the moment
      } else if (typeof res === 'boolean' && !res) {
        //todo do nothing at the moment
      }
      // OK
      else if (typeof res === 'object') {
        await self.getPlugins();
      }
    });
  }
}
