import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'xen-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent implements OnInit {
  @Input() type: 'dot' | 'counter' | 'icon' | 'label' = 'dot';
  @Input() status:
    | 'default'
    | 'info'
    | 'warning'
    | 'success'
    | 'seller'
    | 'buyer'
    | 'tech'
    | 'error'
    | 'role-you'
    | 'role-owner'
    | 'role-admin'
    | 'role-editor'
    | 'role-viewer' = 'default';
  @Input() theme: 'dark' | 'light' = 'dark';
  @Input() label: string;
  @Input() amount: number;
  @Input() icon: string;
  @Input() label_count: boolean;

  constructor() {}

  public get classes(): Array<string> {
    return ['xen-badge', `xen-badge--${this.type}`, `xen-badge--${this.status}-${this.theme}`];
  }

  ngOnInit(): void {}
}
