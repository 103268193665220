import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InvalidateModalComponent } from '../invalidate-modal/invalidate-modal.component';
import {
  GenericMappingResponse,
  Hotel,
  HotelList,
  Master,
  MasterList,
} from '../../interfaces/models/mapping.interfaces';
import { hsCountryListIsoCodes } from '@tgx/shared/utils';
import { MappingService } from '../../../../services/mapping.service';
import { Subscription, lastValueFrom } from 'rxjs';
import { MasterType } from '../../interfaces/models/enums.interfaces';

@Component({
  selector: 'tgx-validate-table',
  templateUrl: './validate-table.component.html',
  styleUrls: ['./validate-table.component.scss'],
})
export class ValidateTableComponent {
  @Input() formParams: { countryMapping: string; hotelMapping: string };
  @Output() loadDataEvent = new EventEmitter<{ options: string }>();

  validateHotelsCP(): HotelList[] {
    const initialPosition = this.pageSizeHotels * this.pageHotels - this.pageSizeHotels;
    const finalPosition = this.pageSizeHotels * this.pageHotels;
    return this.validateHotels.slice(initialPosition, finalPosition);
  }

  nonValidateCount: number;
  validateCount: number;
  unmappedCount: number;

  nonValidateMasters: MasterList[] = [];
  validateMasters: MasterList[] = [];
  unmappedList: HotelList[] = [];

  errorNonValidate: string;
  errorValidate: string;
  errorUnmapped: string;

  // Hotels
  validateHotels: HotelList[] = [];
  idMasterSelected: string;
  show_child: boolean = false;
  checked: boolean;

  page = 1;
  pageSize = 10;
  pageHotels = 1;
  pageSizeHotels = 10;

  tHead = [
    { label: 'Hotel master', field: 'name' },
    { label: 'Id', field: 'id' },
    { label: 'Geocode', field: 'location' },
    { label: 'Country', field: 'country' },
    { label: 'Address', field: 'address' },
  ];
  isLoading: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private modalService: NgbModal,
    private mappingService: MappingService,
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.mappingService.nonValidateMasters.subscribe(async (nonValidateMasters) => {
        this.nonValidateMasters = nonValidateMasters;
      }),
    );
    this.subscriptions.push(
      this.mappingService.nonValidateCount.subscribe(async (nonValidateCount) => {
        this.nonValidateCount = nonValidateCount;
      }),
    );
    this.subscriptions.push(
      this.mappingService.validateMasters.subscribe(async (validateMasters) => {
        this.validateMasters = validateMasters;
      }),
    );
    this.subscriptions.push(
      this.mappingService.validateCount.subscribe(async (validateCount) => {
        this.validateCount = validateCount;
      }),
    );
    this.subscriptions.push(
      this.mappingService.unmappedList.subscribe(async (unmappedList) => {
        this.unmappedList = unmappedList;
      }),
    );
    this.subscriptions.push(
      this.mappingService.unmappedCount.subscribe(async (unmappedCount) => {
        this.unmappedCount = unmappedCount;
      }),
    );
    this.subscriptions.push(
      this.mappingService.errorNonValidate.subscribe(async (errorNonValidate) => {
        this.errorNonValidate = errorNonValidate;
      }),
    );
    this.subscriptions.push(
      this.mappingService.errorValidate.subscribe(async (errorValidate) => {
        this.errorValidate = errorValidate;
      }),
    );
    this.subscriptions.push(
      this.mappingService.errorUnmapped.subscribe(async (errorUnmapped) => {
        this.errorUnmapped = errorUnmapped;
      }),
    );
    this.subscriptions.push(
      this.mappingService.validateHotels.subscribe(async (validateHotels) => {
        this.validateHotels = validateHotels;
      }),
    );
  }

  reLoadData() {
    this.loadDataEvent.emit();
  }

  findNameCountry(isoCode2: string) {
    const country = hsCountryListIsoCodes.find((x) => x.isoCode2 === isoCode2);
    if (country) {
      return country.name;
    }
    return '';
  }

  async retrieveMasterSons(validate: MasterList) {
    const res = await this.mappingService.getValidatedMasterSons(validate.id);
    this.idMasterSelected = res.id;
    if (this.idMasterSelected === validate.id) {
      this.mappingService.updateHotels(res.hotels, MasterType.VALIDATE);
      validate.show_child = !validate.show_child;
    }
  }

  async onPageChange(page: any) {
    let validate: Master;
    let results: PromiseSettledResult<any>[];
    this.page = page;
    let offset = (page - 1) * 10;
    this.isLoading = true;
    validate = (await lastValueFrom(
      this.mappingService.buildAndLaunchRequestWithParams(
        this.mappingService.getValidatedMasterEndPoint(),
        this.formParams.countryMapping,
        offset,
        10,
        this.formParams.hotelMapping,
      ),
    )) as Master;
    results = await Promise.allSettled([validate]);

    if (results[0].status === 'fulfilled') {
      if (results[0].value.master_list.length === 0) {
        this.mappingService.errorValidate.next('No Validated masters found');
      } else {
        this.mappingService.validateMasters.next(results[0].value.master_list);
      }
    }
    this.isLoading = false;
  }

  onPageChangeHotels(pageHotels: any) {
    this.pageHotels = pageHotels;
  }

  openModalInvalidate(validate: MasterList) {
    const modalRef = this.modalService.open(InvalidateModalComponent, { size: 'xl', windowClass: 'xen-modal' });
    modalRef.componentInstance.validate = validate;
    modalRef.result
      .then((result: GenericMappingResponse) => {
        if (result) {
          this.reLoadData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
