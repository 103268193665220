export enum NotificationsType {
  release = 'release',
  other = 'other',
  network = 'network',
  billing = 'billing',
  cases = 'cases',
  onboarding = 'onboarding',
  connections = 'connections',
  apps = 'apps',
  users = 'users',
  inventory = 'inventory',
}
