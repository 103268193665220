<div class="xen-icon-app" [ngClass]="classes" [ngStyle]="{ 'height': width + 'px', 'width': width + 'px' }">
  <i
    *ngIf="!href"
    class="fa-duotone {{ icon }}"
    [ngStyle]="{ 'font-size': font_size + 'px' }"
    style="--fa-primary-color: #0b59de; --fa-secondary-color: #0b59de"
  ></i>
  <a *ngIf="href" class="xen-icon-app__href" href="{{ href }}">
    <i
      class="fa-duotone {{ icon }}"
      [ngStyle]="{ 'font-size': font_size + 'px' }"
      style="--fa-primary-color: #0b59de; --fa-secondary-color: #0b59de"
    ></i
  ></a>
</div>
