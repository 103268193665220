import { gql } from 'apollo-angular';

export const getProfilesCsvData = gql`
  query {
    social {
      allProfilesV2 {
        edges {
          node {
            code
            profileData {
              name
              owner {
                code
              }
              status
              buyerCategory
              sellerCategory
              country
              buyerPartnerStatus
              sellerPartnerStatus
              buyerCategory
              sellerCategory
              buyerSubCategory
              sellerSubCategory
              buyerIntegrationType
              sellerIntegrationType
              isDemandBridge
              isSupplyBridge
              buyerTechBridge
              sellerTechBridge
            }
          }
        }
      }
    }
  }
`;
