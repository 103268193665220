<nb-card class="mt-2">
  <nb-card-header> Main {{ title }} Stats </nb-card-header>
  <nb-card-body>
    <p-table
      [value]="mainSource"
      styleClass="p-datatable-gridlines p-datatable-striped"
      [tableStyle]="{ 'min-width': '50rem' }"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let head of mainHeader">
            {{ head.label }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-info>
        <tr>
          <td>{{ info.status }}</td>
          <td>{{ info.updated }}</td>
          <td>{{ info.notModified }}</td>
          <td>{{ info.new }}</td>
          <td>{{ info.deleted }}</td>
          <td>{{ info.dbErrors }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="10" class="text-center">No info found.</td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>
<nb-card class="mt-2">
  <nb-card-header> Language {{ title }} Stats </nb-card-header>
  <nb-card-body>
    <p-table
      [value]="langSource"
      styleClass="p-datatable-gridlines p-datatable-striped"
      [tableStyle]="{ 'min-width': '50rem' }"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let head of langHeader">
            {{ head }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-lang>
        <tr>
          <td *ngFor="let head of langHeader">{{ lang[head] }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="10" class="text-center">No lang found.</td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>
<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()">Close</button>
</div>
