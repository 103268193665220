import { gql } from 'apollo-angular';

export const getAllConnections = gql`
  query ($where: ConnectionWhereInput) {
    admin {
      allConnections(where: $where) {
        edges {
          node {
            connectionData {
              code
              buyer {
                organizationsData {
                  code
                  label
                }
              }
              seller {
                organizationsData {
                  code
                  label
                }
              }
              isActive
            }
          }
        }
      }
    }
  }
`;
