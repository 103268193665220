<div [formGroup]="form" [ngClass]="classes">
  <input
    class="form-check-input"
    [ngClass]="inputClasses"
    type="checkbox"
    [value]="value"
    id="{{ id ?? formControlName }}"
    [formControlName]="formControlName"
    [checked]="checked"
    [disabled]="disabled"
  />
  <label class="form-check-label" for="{{ id ?? formControlName }}" [innerHtml]="label">
    {{ label }}
  </label>
  <div class="xen-checkbox__feedback" [ngClass]="feedbackClasses" *ngIf="validation">{{ feedback }}</div>
</div>
