<div class="px-5 xen-meta-links">
  <div class="d-flex gap-2">
    <p class="mb-0">Suggested for you:</p>
    <ul class="list-unstyled mb-0 d-flex gap-2">
      <li *ngFor="let link of links">
        <xen-link
          [size]="'body'"
          [weight]="'medium'"
          [label]="link.label"
          [routerLink]="'/' + link.router"
          [icon]="true"
          [icon_snippet]="link.icon"
          [icon_placement]="'left'"
          [theme]="'blue'"
          [underlined]="true"
        ></xen-link>
      </li>
    </ul>
  </div>
  <div>
    <p class="mb-0 xen-meta-links__info">{{ linkInfo }}</p>
  </div>
</div>
