import { gql } from 'apollo-angular';

export const getSupplierMetaInfo = gql`
  query ($supplierCode: ID!) {
    admin {
      supplierMetaInfo(where: { search: $supplierCode, searchBy: CODE }) {
        totalCount
        edges {
          node {
            key
            value
          }
        }
        adviseMessage {
          code
          description
        }
      }
    }
  }
`;
