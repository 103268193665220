import { Injectable } from '@angular/core';
import { GqlService } from '@tgx/shared/data-access/graphql';
import { BehaviorSubject } from 'rxjs';
import { SupplierSelector } from '../interfaces/_index-entities.interfaces';
import { RetrieveAllSuppliersByGroupsInput } from '../interfaces/inputs/retrieve-all-suppliers-by-groups-input';
import { SupplierCreateInput } from '../interfaces/inputs/supplier-create-input.interface';
import { SupplierUpdateInput } from '../interfaces/inputs/supplier-update-input.interface';
import { SupplierWhereInput } from '../interfaces/inputs/supplier-where-input.interface';
import { SupplierWhereUniqueInput } from '../interfaces/inputs/supplier-where-unique-input.interface';
import { Supplier, SupplierEdge } from '../interfaces/models/suppliers.interface';
import { GraphqlGatewayMutations } from './mutations/_index-entities.mutations';
import { GraphqlGatewayQueries } from './queries/_index-entities.queries';

@Injectable({
  providedIn: 'root',
})
export class SuppliersService {
  onToggleTable$: BehaviorSubject<any> = new BehaviorSubject(null);
  onToggleArchive$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private gqlService: GqlService) {}

  async getSupplierMetaInfo(code: string): Promise<any[]> {
    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getSupplierMetaInfo, { supplierCode: code })
        .then((response) => {
          if (response['admin']['supplierMetaInfo']['edges']) {
            resolve(response['admin']['supplierMetaInfo']['edges']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async grantSupplierToGroups(where: SupplierWhereUniqueInput, groupCodes: string[]) {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.grantSupplierToGroups, { where: where, groupCodes: groupCodes })
        .then((response) => {
          if (response['admin']['grantSupplierToGroups']['supplierData']) {
            resolve(response['admin']['grantSupplierToGroups']['supplierData']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async updateSupplierGiata(where: SupplierWhereUniqueInput, giataCode: string, giataType: string) {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.updateSupplierGiata, {
          where: where,
          giataCode: giataCode,
          giataType: giataType,
        })
        .then((response) => {
          if (response['admin']['updateSupplierGiata']['supplierData']) {
            resolve(response['admin']['updateSupplierGiata']['supplierData']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async revokeSupplierFromGroups(where: SupplierWhereUniqueInput, groupCodes: string[]) {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.revokeSupplierFromGroups, { where: where, groupCodes: groupCodes })
        .then((response) => {
          if (response['admin']['revokeSupplierFromGroups']['supplierData']) {
            resolve(response['admin']['revokeSupplierFromGroups']['supplierData']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getAllSuppliersGroups(input: SupplierWhereInput): Promise<SupplierEdge[]> {
    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getAllSuppliersGroups, { where: input })
        .then((response) => {
          if (response['admin']['allSuppliers']['edges'].length > 0) {
            resolve(response['admin']['allSuppliers']['edges']);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async getAllSuppliers(input: RetrieveAllSuppliersByGroupsInput): Promise<SupplierEdge[]> {
    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getAllSuppliers, { where: input })
        .then((response) => {
          if (response['admin']['allSuppliers']['edges'].length > 0) {
            resolve(response['admin']['allSuppliers']['edges']);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }
  async getSupplier(code: string): Promise<Supplier> {
    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getSupplier, { supplierCode: code })
        .then((response) => {
          if (response['admin']['supplier']) {
            resolve(response['admin']['supplier']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async getSupplierOrgCode(code: string): Promise<Supplier> {
    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getSupplierOrgCode, { supplierCode: code })
        .then((response) => {
          if (response['admin']['supplier']) {
            resolve(response['admin']['supplier']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async getAllSuppliersWithoutFilter(): Promise<SupplierEdge[]> {
    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getAllSuppliersWithoutFilter)
        .then((response) => {
          if (response['admin']['allSuppliers']['edges'].length > 0) {
            resolve(response['admin']['allSuppliers']['edges']);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async setIsPublicSupplier(input: SupplierWhereUniqueInput, isPublic: boolean): Promise<Supplier> {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.setIsPublicSupplier, { where: input, isPublic: isPublic })
        .then((response) => {
          if (response['admin']['setIsPublicSupplier']['code']) {
            resolve(response['admin']['setIsPublicSupplier']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async activateSupplier(input: SupplierWhereUniqueInput): Promise<Supplier> {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.activateSupplier, { where: input })
        .then((response) => {
          if (response['admin']['activateSupplier']['code']) {
            resolve(response['admin']['activateSupplier']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async deactivateSupplier(input: SupplierWhereUniqueInput): Promise<Supplier> {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.deactivateSupplier, { where: input })
        .then((response) => {
          if (response['admin']['deactivateSupplier']['code']) {
            resolve(response['admin']['deactivateSupplier']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async createSupplier(input: SupplierCreateInput): Promise<Supplier> {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.createSupplier, { data: input })
        .then((response) => {
          if (response['admin']['createSupplier']) {
            resolve(response['admin']['createSupplier']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async updateSupplier(where: SupplierWhereUniqueInput, input: SupplierUpdateInput): Promise<Supplier> {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.updateSupplier, { where: where, data: input })
        .then((response) => {
          if (response['admin']['updateSupplier']['code']) {
            resolve(response['admin']['updateSupplier']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async setSupplierDefaultSharedAccess(where: SupplierWhereUniqueInput, sharedID: string) {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.setSupplierDefaultSharedAccess, { where: where, sharedCode: sharedID })
        .then((response) => {
          if (response['admin']['setSupplierDefaultSharedAccess']['code']) {
            resolve(response['admin']['setSupplierDefaultSharedAccess']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async removeSupplierDefaultSharedAccess(where: SupplierWhereUniqueInput) {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.removeSupplierDefaultSharedAccess, { where: where })
        .then((response) => {
          if (response['admin']['removeSupplierDefaultSharedAccess']['code']) {
            resolve(response['admin']['removeSupplierDefaultSharedAccess']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async getGroupsCodeFromSupplierEdge(assignedGroupsSource: any): Promise<String[]> {
    return await new Promise((resolve) => {
      const groupsCode = [];
      assignedGroupsSource.forEach((accessGroups) => {
        accessGroups['node']['supplierData']['groups']['edges'].forEach((group) => {
          const code = group['node']['groupData'].code;
          groupsCode.push(code);
        });
      });
      resolve(groupsCode);
    });
  }

  async getGroupsBySupplierCode(where: any) {
    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getGroupsBySupplierCode, { where: where })
        .then((response) => {
          const groups = [];
          if (response['admin']['allSuppliers']['edges'].length > 0) {
            response['admin']['allSuppliers']['edges'].forEach((slot) => {
              if (
                slot['node']['supplierData']['groups']['edges'] &&
                slot['node']['supplierData']['groups']['edges'].length > 0
              ) {
                slot['node']['supplierData']['groups']['edges'].forEach((node) => {
                  groups.push(node['node']['code']);
                });
              }
            });

            resolve(groups);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async archiveSupplier(input: SupplierWhereUniqueInput): Promise<Supplier> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.archiveSupplier, { where: input })
        .then((response) => {
          if (response['admin']['archiveSupplier']['adviseMessage']?.length > 0) {
            reject(response['admin']['archiveSupplier']['adviseMessage'][0].description);
          }

          if (response['admin']['archiveSupplier']['code']) {
            resolve(response['admin']['archiveSupplier']);
          } else {
            reject('The supplier code was not in the archive response');
          }
        })
        .catch((error) => {
          reject('There was a problem archiving the supplier: ' + error);
        });
    });
  }

  async unArchiveSupplier(input: SupplierWhereUniqueInput): Promise<Supplier> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.unArchiveSupplier, { where: input })
        .then((response) => {
          if (response['admin']['unArchiveSupplier']['adviseMessage']?.length > 0) {
            reject(response['admin']['unArchiveSupplier']['adviseMessage'][0].description);
          }
          if (response['admin']['unArchiveSupplier']['code']) {
            resolve(response['admin']['unArchiveSupplier']);
          } else {
            reject('The supplier code was not in the unarchive response');
          }
        })
        .catch((error) => {
          reject('There was a problem unarchiving the supplier: ' + error);
        });
    });
  }

  async getSuppliersSelector(): Promise<SupplierSelector[]> {
    const suppliers: SupplierSelector[] = [];

    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getMinInfoSupplier, {})
        .then((response) => {
          if (response['admin']['allSuppliers']['edges'] && response['admin']['allSuppliers']['edges'].length > 0) {
            response['admin']['allSuppliers']['edges'].forEach((sup) => {
              suppliers.push({ code: sup.node?.supplierData.code, label: sup.node?.supplierData.name });
            });
            resolve(suppliers);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }
}
