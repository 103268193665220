import { gql } from 'apollo-angular';

export const archiveApikey = gql`
  mutation ($where: ApikeyOrganizationInput!) {
    admin {
      archiveApikey(where: $where) {
        code
        adviseMessage {
          code
          description
        }
      }
    }
  }
`;
