<nb-card>
  <nb-card-body>
    <div class="row mb-3">
      <div class="col-1">
        <label class="mt-2">Contract: </label>
      </div>
      <div class="col-6">
        <p-dropdown
          (onHide)="getL2BCosts()"
          [options]="contractsSelector"
          [appendTo]="'body'"
          baseZIndex="999"
          [disabled]="contractsSelector[0]?.code === null"
          [(ngModel)]="selectedContract"
          optionLabel="label"
          filterBy="label"
        >
          <ng-template pTemplate="selectedItem">
            <div *ngIf="selectedContract">
              <div>{{ selectedContract?.label }}</div>
            </div>
          </ng-template>
          <ng-template let-ci pTemplate="ci">
            <div>
              <div>{{ ci.label }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
  </nb-card-body>
</nb-card>

<nb-card>
  <nb-card-header> L2B Costs </nb-card-header>

  <nb-card-body>
    <div *ngIf="isEditor" class="mb-3">
      <p-button label="Create" icon="pi pi-plus" (onClick)="onCreate()"></p-button>
    </div>
    <p-table
      #dt
      [value]="costsL2BLoadSource"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      styleClass="p-datatable-gridlines p-datatable-striped"
      [tableStyle]="{ 'min-width': '50rem' }"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
      [resizableColumns]="true"
      columnResizeMode="expand"
      [loading]="isLoading"
      loadingIcon="pi pi-spin pi-spinner"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let head of header" [pSortableColumn]="head.field">
            {{ head.label }}
            <p-sortIcon *ngIf="head.field !== 'Options'" [field]="head.field"></p-sortIcon>
          </th>
        </tr>
        <tr>
          <th *ngFor="let head of header" [style]="head.field === 'Options' ? 'width: 3rem' : ''">
            <p-columnFilter
              type="text"
              *ngIf="head.field !== 'Options'"
              [field]="head.field"
              [showClearButton]="false"
              [showMenu]="false"
              (input)="filter($event.target.value, head.field)"
            ></p-columnFilter>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-cost>
        <tr>
          <td>{{ cost.id }}</td>
          <td>{{ cost.minl2b }}</td>
          <td>{{ cost.maxl2b }}</td>
          <td>{{ cost.minBook }}</td>
          <td>{{ cost.maxBook }}</td>
          <td>{{ cost.amount }}</td>
          <td>{{ cost.paymentType }}</td>
          <td>
            <div class="flex align-items-center justify-content-center gap-2">
              <button
                pButton
                pRipple
                type="button"
                icon="pi {{ isEditor ? 'pi-pencil' : 'pi-search' }}"
                (click)="onEdit(cost)"
                class="p-button-rounded p-button-text"
              ></button>
              <button
                [disabled]="!isEditor"
                pButton
                pRipple
                type="button"
                icon="pi pi-times"
                (click)="onDelete(cost)"
                class="p-button-rounded p-button-text"
              ></button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="10" class="text-center">No costs found.</td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>
