import { gql } from 'apollo-angular';

export const allEntityAudits = gql`
  query ($where: EntityAuditWhereInput, $orderBy: EntityAuditOrderByEnum) {
    admin {
      allEntityAudits(where: $where, orderBy: $orderBy) {
        totalCount
        edges {
          node {
            updatedAt
            createdAt
            entityAuditData {
              id
              actionType
              modifiedBy
              code
              entityType
              correlationID
              auditDetails {
                field
                current
                previous
              }
            }
            adviseMessage {
              ...AdviseMessageFields
            }
          }
        }
      }
    }
  }

  fragment AdviseMessageFields on AdviseMessage {
    code
    description
    level
  }
`;
