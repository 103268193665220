import { gql } from 'apollo-angular';

export const getL2BCosts = gql`
  query GetL2bCosts($where: BookingL2BCostWhereInput!) {
    bookingL2BCosts(where: $where) {
      id
      minl2b
      maxl2b
      minbookings
      maxbookings
      amount
      paymentType
    }
  }
`;
