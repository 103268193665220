import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SubCategoryUpdateInput } from '../../../../interfaces/inputs/sub-category-update-input copy';
import { SubCategoryWhereUniqueInput } from '../../../../interfaces/inputs/sub-category-where-unique-input.interface';

@Component({
  selector: 'tgx-update-subcategory-form',
  templateUrl: './update-subcategory-form.component.html',
  styleUrls: ['./update-subcategory-form.component.scss'],
})
export class UpdateSubcategoryFormComponent implements OnInit {
  @Input() selectedSubCategory: any;
  @Input() selectedCategoryCode: string;

  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    // TODO: add validations to form fields: max length, allowed chars, ...
    this.form = this.fb.group({
      name: [this.selectedSubCategory.name],
      description: [this.selectedSubCategory.description],
      isBuyer: [this.selectedSubCategory.isBuyer, Validators.required],
      isSeller: [this.selectedSubCategory.isSeller, Validators.required],
      isDemandBridge: [this.selectedSubCategory.isDemandBridge, Validators.required],
      isSupplyBridge: [this.selectedSubCategory.isSupplyBridge, Validators.required],
    });

    this.form.get('name').disable();
  }

  sendUpdateSubCategory() {
    const response: SubCategoryUpdateInput = {
      description: this.form.get('description').value,
      defaultIsBuyer: this.form.get('isBuyer').value,
      defaultIsSeller: this.form.get('isSeller').value,
      defaultDemandBridge: this.form.get('isDemandBridge').value,
      defaultSupplyBridge: this.form.get('isSupplyBridge').value,
    };

    const where: SubCategoryWhereUniqueInput = {
      code: this.selectedSubCategory.name,
      categoryCode: this.selectedCategoryCode,
    };

    this.activeModal.close({ data: response, where });
  }

  closeModal() {
    this.activeModal.close();
  }
}
