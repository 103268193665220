<div class="modal-header">
  <span>{{ header }}</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="legacyConnectionForm">
    <nb-card>
      <nb-card-header> Legacy connections </nb-card-header>
      <nb-card-body>
        <div class="row"></div>
        <div class="row mt-2">
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger':
                  legacyConnectionForm.get('hubUser')?.errors && legacyConnectionForm.get('hubUser').touched,
              }"
            >
              <label class="mb-1" for="label">Hub user</label>
              <tooltip-error
                [element]="legacyConnectionForm.get('hubUser')"
                [errors]="legacyConnectionForm.get('hubUser').errors"
              >
              </tooltip-error>
              <input
                type="text"
                class="form-control"
                formControlName="hubUser"
                [ngClass]="{
                  'form-control-danger':
                    legacyConnectionForm.get('hubUser')?.errors && legacyConnectionForm.get('hubUser').touched,
                }"
                placeholder="Enter a valid Hub user"
                name="hubUser"
              />
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger':
                  legacyConnectionForm.get('hubProvider')?.errors && legacyConnectionForm.get('hubProvider').touched,
              }"
            >
              <label class="mb-1" for="label">Hub provider</label>

              <tooltip-error
                [element]="legacyConnectionForm.get('hubProvider')"
                [errors]="legacyConnectionForm.get('hubProvider').errors"
              >
              </tooltip-error>
              <input
                type="text"
                class="form-control"
                formControlName="hubProvider"
                [ngClass]="{
                  'form-control-danger':
                    legacyConnectionForm.get('hubProvider')?.errors && legacyConnectionForm.get('hubProvider').touched,
                }"
                placeholder="Enter a valid Hub provider"
                name="hubProvider"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="mb-1" for="isActive">Is Active</label>
            <tooltip-error
              [element]="legacyConnectionForm.get('isActive')"
              [errors]="legacyConnectionForm.get('isActive').errors"
            >
            </tooltip-error>
            <nb-checkbox
              class="form-control"
              [ngClass]="{
                'form-control-danger':
                  legacyConnectionForm.get('isActive').errors && legacyConnectionForm.get('isActive').touched,
              }"
              name="isActive"
              formControlName="isActive"
            ></nb-checkbox>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()">Close</button>
  <button class="btn btn-md btn-primary" [disabled]="legacyConnectionForm.invalid" (click)="onSave()">
    {{ isCreating ? 'Create' : 'Grant' }}
  </button>
</div>
