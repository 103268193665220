import { Component, OnInit } from '@angular/core';
import { PropertyStatus, SignupData } from './interfaces/signup-data.interface';
import { SignupStatusService } from './signup-status.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormValidator } from '@tgx/shared/utils';

@Component({
  selector: 'tgx-admin-network-signup-status',
  templateUrl: './signup-status.component.html',
  styleUrls: ['./signup-status.component.scss'],
})
export class SignupStatusComponent implements OnInit {
  form: FormGroup;

  data: SignupData;

  loading: boolean;

  toggleProperties: {
    hubspotData: {
      contact: boolean;
      company: boolean;
    };
    coreData: {
      member: boolean;
      organization: boolean;
    };
    socialData: {
      orgProfile: boolean;
      userProfile: boolean;
      userOnboarding: boolean;
    };
    billingData: {
      partner: boolean;
      billingAccount: boolean;
      contracts: boolean;
    };
  };

  constructor(
    private fb: FormBuilder,
    private signupStatusService: SignupStatusService,
  ) {}

  ngOnInit(): void {
    this.initToogleProperties();

    this.form = this.fb.group({
      email: this.fb.control('', [FormValidator.emailRegex, Validators.required]),
    });
  }

  initToogleProperties() {
    this.toggleProperties = {
      hubspotData: {
        contact: false,
        company: false,
      },
      coreData: {
        member: false,
        organization: false,
      },
      socialData: {
        orgProfile: false,
        userProfile: false,
        userOnboarding: false,
      },
      billingData: {
        partner: false,
        billingAccount: false,
        contracts: false,
      },
    };
  }

  async retrieveSignupFeedback() {
    this.loading = true;
    this.data = await this.signupStatusService.checkSignupStatus(this.form.value.email);
    this.loading = false;
  }

  showProperties(section: string, property: string) {
    this.toggleProperties[section][property] = !this.toggleProperties[section][property];
  }

  objectKeys(obj: object) {
    return Object.keys(obj ?? {});
  }

  addSpaceBeforeCapitals(input: string): string {
    // Ensure the first letter is uppercase
    input = input.charAt(0).toUpperCase() + input.slice(1);
    // Insert spaces before capitals in the rest of the string
    return input.replace(/([A-Z])/g, ' $1').trim();
  }

  checkSuccessStatus(section: string, property: string): 'text-success' | 'text-warning' {
    const properties = this.data[section][property].properties;

    // Helper function to check if all provided properties exist
    const allPropertiesExist = (...props) => props.every((prop) => !!properties[prop]);

    // Helper function to check if any provided property exists
    const anyPropertyExists = (...props) => props.some((prop) => !!properties[prop]);

    // Validation rules configuration
    const validationRules = {
      hubspotData: {
        contact: () => properties.is_qualified,
        company: () =>
          allPropertiesExist('name', 'tcp_id') &&
          anyPropertyExists('buyer_category__synced_', 'seller_category__synced_') &&
          anyPropertyExists('buyer_annex_id', 'seller_annex_id', 'buyer_tp_annex_id', 'seller_tp_annex_id'),
      },
      coreData: {
        member: () => properties.isActive,
        organization: () => !properties.isTest && !properties.blacklisted,
      },
      socialData: {
        orgProfile: () =>
          allPropertiesExist('crmId', 'ownerCode') && anyPropertyExists('buyerCategory', 'sellerCategory'),
        userOnboarding: () =>
          allPropertiesExist('tcpId') &&
          anyPropertyExists('annexBuyerId', 'annexSellerId', 'annexPlatformBuyerId', 'annexPlatformSellerId'),
      },
      billingData: {
        contracts: () => anyPropertyExists('buyerContractID', 'sellerContractID'),
      },
    };

    // Execute validation rule if defined, default to 'success'
    const rule = validationRules[section]?.[property];
    return !rule || rule() ? 'text-success' : 'text-warning';
  }

  handlePaste(): void {
    // Delay execution of retrieveSignupFeedback by 100ms to ensure pasted value is processed
    setTimeout(() => {
      this.retrieveSignupFeedback();
    }, 100);
  }

  emptyData() {
    this.data = null;
  }
}
