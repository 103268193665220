import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import {
  Contract,
  Partner,
  ContractConnection,
  Connection,
  ConnectionFullInfo,
} from '../../../../interfaces/_index.billing.interfaces';
import {
  PartnerService,
  ContractService,
  ContractConnectionService,
  ConnectionService,
} from '../../../../services/_index-billing.services';
import { NotificationService, WebAdminService } from './../../../../../../@core/services/_index-core.services';
import { OrganizationSelector } from './../../../../../entities/interfaces/_index-entities.interfaces';
import { Options } from './../../../../../../@core/interfaces/_index.interfaces';
import { BillingConnectionsModalComponent } from '../connections-modal/connections-modal.component';
import { BillingConnectionCloneModalComponent } from '../connections-clone-modal/connections-clone-modal.component';
import { Table } from 'primeng/table';
import { ModalDeleteComponent } from './../../../../../../@core/shared/modal-delete/modal-delete.component';

@Component({
  selector: 'tgx-admin-billing-connections-table',
  templateUrl: './connections-table.component.html',
  styleUrls: ['./connections-table.component.scss'],
})
export class BillingConnectionsTableComponent implements OnInit, OnDestroy {
  @ViewChild('dt', { static: false }) dataTable!: Table;
  $subs: Subscription[] = [];

  header = [
    { label: 'ID', field: 'id' },
    { label: 'ActDate', field: 'activationDateTime' },
    { label: 'EndDate', field: 'endDate' },
    { label: 'IsBuy', field: 'isBuy' },
    { label: 'ConType', field: 'connectionType' },
    { label: 'Buyer', field: 'buyer' },
    { label: 'Seller', field: 'seller' },
    { label: 'Options', field: 'Options' },
  ];

  connectionsLoadSource: any[];

  contracts: Contract[];

  contractConnections: ContractConnection[];
  connections: ConnectionFullInfo[] = [];

  isLoading: boolean;

  orgSelected: OrganizationSelector;
  partner: Partner;

  contractsSelector: Options[] = [{ code: null, label: 'Without contracts' }];
  selectedContract: Options;

  isEditor = false;

  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private webAdminService: WebAdminService,
    private partnerService: PartnerService,
    private contractService: ContractService,
    private contractConnectionService: ContractConnectionService,
    private connectionService: ConnectionService,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;

    this.buildOptions();

    this.$subs.push(
      this.webAdminService.orgSelected.subscribe(async (orgSelected) => {
        if (orgSelected?.code) {
          this.orgSelected = orgSelected;
          await this.partnerService.getPartner(orgSelected.code).then((partner) => {
            if (partner) {
              this.partner = partner;
              this.getContracts(this.partner.orgCode);
            } else {
              this.partner = null;
              this.isLoading = false;
            }
          });
        }
      }),
    );
  }

  ngOnDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
  }

  buildOptions() {
    this.isEditor = this.webAdminService.isBillingEditorOrAdmin();
  }

  getContracts(orgCode: string) {
    if (orgCode) {
      this.contractService
        .getContracts(orgCode)
        .then(async (cnts) => {
          this.contracts = cnts;
          if (this.contracts?.length > 0) {
            this.contractsSelector = [];
            this.buildSelector();
            this.selectedContract = this.contractsSelector[0];
            await this.getConnections();
          } else {
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
          this.isLoading = false;
        });
    }
  }

  buildSelector() {
    if (this.contracts?.length > 0) {
      this.contracts.forEach((c) => {
        this.contractsSelector.push({
          code: c.id.toString(),
          label:
            '(' +
            c.id +
            ') ' +
            c.partnerType +
            (c.modelType ? ' - ' + c.modelType : '') +
            (c.ContractType ? ' - ' + c.ContractType.name : ''),
        });
      });
    }
  }

  async getConnections() {
    this.isLoading = true;
    if (this.dataTable) {
      this.dataTable.clearFilterValues();
    }

    this.connectionsLoadSource = [];

    if (this.selectedContract?.code) {
      await this.contractConnectionService
        .getContractConnections(Number(this.selectedContract?.code))
        .then((rs) => {
          this.contractConnections = rs;
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
          this.isLoading = false;
        });

      const connectionCodes: number[] = [];

      if (this.contractConnections?.length > 0) {
        this.contractConnections.forEach((con) => {
          const index = connectionCodes.findIndex((x) => x === con.connection);

          if (index === -1) {
            connectionCodes.push(con.connection);
          }
        });
      }

      if (connectionCodes.length > 0) {
        await this.connectionService
          .getConnectionsFullInfoById(connectionCodes)
          .then((rs) => {
            this.connections = rs;
          })
          .catch((err) => {
            this.notificationService.handleGatewayAndGraphqlErrors(err);
            this.isLoading = false;
          });
      }

      if (this.contractConnections?.length > 0 && this.connections?.length > 0) {
        this.contractConnections.forEach((con) => {
          const index = this.connections?.findIndex((x) => x.id === con.connection);

          if (index > -1) {
            const { buyerCode, buyerName, sellerCode, sellerName } = this.connections[index];

            const { id, setupPaid, activationDateTime, endDate, isBuy, connectionType, connection, free, isDeletable } =
              con;

            const buyer = `${buyerName} (${buyerCode})`;
            const seller = `${sellerName} (${sellerCode})`;

            this.connectionsLoadSource.push({
              id,
              activationDateTime,
              endDate,
              isBuy,
              connectionType,
              buyer,
              seller,
              //Not show in table, but passed to its modal
              free,
              connection,
              setupPaid,
              //Only to show or not delete button
              isDeletable,
            });
          }
        });
        this.connectionsLoadSource = [...this.connectionsLoadSource];
        this.connectionsLoadSource.sort((a, b) => (a.id < b.id ? 1 : -1));

        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    } else {
      this.isLoading = false;
    }
  }

  getFilterType(field: string) {
    switch (field) {
      case 'setupPaid':
      case 'isBuy':
        return 'boolean';
      default:
        return 'text';
    }
  }

  async onCreate(): Promise<void> {
    if (this.selectedContract.code === null) {
      this.notificationService.warning('You can not create a connection, first you must to create a contract.');
      return;
    }

    const activeModal = this.modalService.open(BillingConnectionsModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = 'Create Contract Connection';
    activeModal.componentInstance.contractId = Number(this.selectedContract.code);
    activeModal.componentInstance.isEditor = this.isEditor;
    activeModal.componentInstance.contractConnections = this.contractConnections;

    const self = this;
    activeModal.result.then(async (res) => {
      // on close

      if (typeof res === 'boolean' && res) {
        //todo do nothing at the moment
      } else if (typeof res === 'boolean' && !res) {
        //todo do nothing at the moment
      }
      // OK
      else if (typeof res === 'object') {
        await self.getConnections();
      }
    });
  }

  async onEdit(event): Promise<void> {
    const connection: ContractConnection = event;

    const activeModal = this.modalService.open(BillingConnectionsModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = (this.isEditor ? 'Edit ' : 'View ') + 'Contract Connection';
    activeModal.componentInstance.connection = connection;
    activeModal.componentInstance.contractId = Number(this.selectedContract.code);
    activeModal.componentInstance.isEditor = this.isEditor;

    const self = this;
    activeModal.result.then(async (res) => {
      // on close

      if (typeof res === 'boolean' && res) {
        //todo do nothing at the moment
      } else if (typeof res === 'boolean' && !res) {
        //todo do nothing at the moment
      }
      // OK
      else if (typeof res === 'object') {
        await self.getConnections();
      }
    });
  }

  onDelete(event): void {
    const connection: ContractConnection = event;

    const modalRef = this.modalService.open(ModalDeleteComponent, {
      backdrop: 'static',
      container: 'nb-layout',
    });

    modalRef.componentInstance.message =
      'Are you sure that you want to delete connection with id ' + connection.id + '?';
    modalRef.componentInstance.title = 'Delete Connection';
    modalRef.componentInstance.buttonOkTitle = 'Delete';

    const self = this;

    modalRef.result
      .then(async (res) => {
        // on close
        if (res) {
          await self.contractConnectionService
            .deleteConnection({ id: connection.id })
            .then(async (rs) => {
              self.notificationService.success('Contract connection succesfully deleted', 'Success');
              await self.getConnections();
            })
            .catch((err) => {
              self.notificationService.handleGatewayAndGraphqlErrors(err);
            });
        }
      })
      .catch((error) => {
        self.notificationService.handleGatewayAndGraphqlErrors(error);
      });
  }

  async onClone(event): Promise<void> {
    const connection: ContractConnection = event;

    if (connection.endDate) {
      this.notificationService.warning('You can not clone this connection, is ended.');
      return;
    }

    const activeModal = this.modalService.open(BillingConnectionCloneModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = 'Clone Contract Connection';
    activeModal.componentInstance.connection = connection;

    const self = this;
    activeModal.result.then(async (res) => {
      // on close

      if (typeof res === 'boolean' && res) {
        //todo do nothing at the moment
      } else if (typeof res === 'boolean' && !res) {
        //todo do nothing at the moment
      }
      // OK
      else if (typeof res === 'object') {
        await self.getConnections();
      }
    });
  }

  filter(value: any, field: string) {
    if (value) {
      this.dataTable.filter(value, field, 'contains');
    }
  }
}
