<div>
  <form [formGroup]="queryFormAuditory">
    <nb-card>
      <nb-card-header> Request logs for </nb-card-header>
      <nb-card-body>
        <div class="row mb-3">
          <div class="col-6">
            <div class="form-group">
              <div class="d-inline-block w-100">
                Section/Option:<br />
                <p-multiSelect
                  [group]="true"
                  [options]="entities_types"
                  [(ngModel)]="selectedEntityTypes"
                  optionLabel="label"
                  optionGroupLabel="group_label"
                  optionGroupChildren="section_options"
                  defaultLabel="Select an option..."
                  optionValue="code"
                  [appendTo]="'body'"
                  baseZIndex="999"
                  selectedItemsLabel="{0} options/s selected"
                  formControlName="sections"
                >
                </p-multiSelect>
              </div>
            </div>
          </div>

          <div class="col-3">
            <div class="form-group">
              <div class="d-inline-block w-100">
                From date:
                <p-calendar
                  [(ngModel)]="from_date"
                  [appendTo]="'body'"
                  dateFormat="dd/mm/yy"
                  formControlName="start_date"
                ></p-calendar>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <div class="d-inline-block w-100">
                To date:
                <p-calendar
                  [(ngModel)]="to_date"
                  [appendTo]="'body'"
                  dateFormat="dd/mm/yy"
                  formControlName="end_date"
                ></p-calendar>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button class="btn btn-md btn-primary" (click)="runQuery()">Search logs</button>
        </div>
      </nb-card-body>
    </nb-card>
  </form>
</div>

<nb-card>
  <nb-card-body>
    <p-table
      #dt1
      [value]="auditorySource"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      styleClass="p-datatable-gridlines p-datatable-striped"
      [tableStyle]="{ 'min-width': '50rem' }"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
      [globalFilterFields]="['code', 'modifiedBy', 'actionType', 'entityType']"
    >
      <ng-template pTemplate="caption">
        <div class="flex, align-right">
          <span class="p-input-icon-left ml-auto">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              (input)="dt1.filterGlobal($event.target.value, 'contains')"
              placeholder="Search Code/Action"
              class="search-input"
            />
          </span>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let head of header" [pSortableColumn]="head.field">
            {{ head.label }}
            <p-sortIcon [field]="head.field"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-auditory>
        <tr>
          <td>{{ auditory.entityType }}</td>
          <td>{{ auditory.code }}</td>
          <td>{{ auditory.actionType }}</td>
          <td>
            <p-table
              [value]="auditory.auditDetails"
              rowGroupMode="rowspan"
              groupRowsBy="field"
              [rows]="10"
              styleClass="p-datatable-gridlines p-datatable-striped"
              [tableStyle]="{ 'min-width': '50rem' }"
            >
              <ng-template
                pTemplate="body"
                let-audit
                let-rowIndex="rowIndex"
                let-rowgroup="rowgroup"
                let-rowspan="rowspan"
              >
                <tr>
                  <td *ngIf="rowgroup" [attr.rowspan]="rowspan">
                    {{ audit.field }}
                  </td>
                  <td>
                    {{ audit.previous }}
                  </td>
                  <td>
                    {{ audit.current }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </td>
          <td>{{ auditory.createdAt }}</td>
          <td>{{ auditory.modifiedBy }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="10" class="text-center">No auditories found.</td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>
