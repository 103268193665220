export interface SignupData {
  coreData: CoreData;
  socialData: SocialData;
  billingData: BillingData;
  hubspotData: HubspotData;
}

export interface CoreData {
  member: {
    status: PropertyStatus;
    properties: MemberProperties;
  };
  organization: {
    status: PropertyStatus;
    properties: OrganizationProperties;
  };
}

export interface MemberProperties {
  id: number;
  code: string;
  isActive: boolean;
}

export interface OrganizationProperties {
  id: number;
  code: string;
  isTest: boolean;
  blacklisted: boolean;
}

export interface SocialData {
  orgProfile: {
    status: PropertyStatus;
    properties: OrgProfileProperties;
  };
  userProfile: {
    status: PropertyStatus;
    properties: UserProfileProperties;
  };
  userOnboarding: {
    status: PropertyStatus;
    properties: UserOnboardingProperties;
  };
}

export interface OrgProfileProperties {
  id: number;
  name: string;
  slug: string;
  websiteUrl: string;
  status: string;
  crmId: number;
  ownerCode: string;
  isDemandBridge: boolean;
  isSupplyBridge: boolean;
  buyerCategory: string;
  buyerSubCategory: string;
  sellerCategory: string;
  sellerSubCategory: string;
}

export interface UserProfileProperties {
  firstName: string;
  lastName: string;
  headquarters: string;
  jobTitle: string;
  department: string;
}

export interface UserOnboardingProperties {
  startedAt: string;
  userDetailsFinishedAt: string;
  billingAccountCreated: string;
  tcpAcceptedAt: string;
  annexBuyerAcceptedAt: string;
  annexPlatformBuyerAcceptedAt: string;
  annexSellerAcceptedAt: string;
  annexPlatformSellerAcceptedAt: string;
  finishedAt: string;

  tcpId: string;
  annexBuyerId: string;
  annexPlatformBuyerId: string;
  annexSellerId: string;
  annexPlatformSellerId: string;
  developmentNeeded: boolean;
}

export interface BillingData {
  partner: {
    status: PropertyStatus;
    properties: PartnerProperties;
  };
  billingAccount: {
    status: PropertyStatus;
    properties: BillingAccountProperties;
  };
  contracts: {
    status: PropertyStatus;
    properties: ContractProperties;
  };
}

export interface PartnerProperties {
  id: number;
}

export interface BillingAccountProperties {
  id: number;
}

export interface ContractProperties {
  buyerContractID: number;
  sellerContractID: number;
}

export interface HubspotData {
  contact: {
    status: PropertyStatus;
    properties: ContactProperties;
  };
  company: {
    status: PropertyStatus;
    properties: CompanyProperties;
  };
}

export interface ContactProperties {
  id: number;
  is_qualified: boolean;
}

export interface CompanyProperties {
  id: number;
  name: string;
  website: string;
  headquarter: string;
  organization_code: string;
  buyer_category__synced_: string;
  buyer_subcategory__synced_: string;
  seller_category__synced_: string;
  seller_subcategory__synced_: string;
  buyertechbridge: string;
  sellertechbridge: string;
  tcp_id: string;
  buyer_annex_id: string;
  seller_annex_id: string;
  buyer_tp_annex_id: string;
  seller_tp_annex_id: string;
}

export enum PropertyStatus {
  CHECKED = 'CHECKED',
  NO_CHECKED = 'NO_CHECKED',
}
