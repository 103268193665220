<nb-card class="mt-4">
  <nb-card-header> Default GMV NP2023 Costs </nb-card-header>
  <nb-card-body>
    <p-table
      #dt
      [value]="costsLoadSource"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      styleClass="p-datatable-gridlines p-datatable-striped"
      [tableStyle]="{ 'min-width': '50rem' }"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
      [resizableColumns]="true"
      columnResizeMode="expand"
      [loading]="isLoading"
      loadingIcon="pi pi-spin pi-spinner"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let head of header" [pSortableColumn]="head.field">
            {{ head.label }}
            <p-sortIcon [field]="head.field"></p-sortIcon>
          </th>
        </tr>
        <tr>
          <th *ngFor="let head of header">
            <p-columnFilter
              *ngIf="head.field !== 'createdAt'"
              type="text"
              [field]="head.field"
              [showMenu]="false"
              [showClearButton]="false"
              (input)="filter($event.target.value, head.field)"
            ></p-columnFilter>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-cost>
        <tr>
          <td>{{ cost.id }}</td>
          <td>{{ cost.amount }}</td>
          <td>{{ cost.partnerType }}</td>
          <td>{{ cost.partnerSubType }}</td>
          <td>{{ cost.paymentType }}</td>
          <td>{{ cost.createdAt | date: 'dd-MM-yyyy' }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="10" class="text-center">No costs found.</td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>
