import { Injectable } from '@angular/core';
import { ClientSuppliersResponse } from './../interfaces/_index.billing.interfaces';
import { GraphqlApiBillingQueries } from './queries/_index-billing-queries';
import { GqlService } from '@tgx/shared/data-access/graphql';

@Injectable({
  providedIn: 'root',
})
export class BillingGatewayService {
  constructor(private gqlService: GqlService) {}

  async getAllClientSuppliers(): Promise<ClientSuppliersResponse> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .queryGateway(GraphqlApiBillingQueries.getGatewayClientSuppliers, {})
        .then((res: any) => {
          if (res?.admin) {
            resolve(res.admin);
          } else {
            reject(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
