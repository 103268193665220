import { gql } from 'apollo-angular';

export const updatePartner = gql`
  mutation UpdatePartner($where: PartnerWhereUniqueInput!, $data: PartnerUpdateInput!) {
    updatePartner(where: $where, data: $data) {
      id
      orgCode
    }
  }
`;
