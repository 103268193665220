import { Injectable } from '@angular/core';
import { GqlService } from '@tgx/shared/data-access/graphql';
import { EntityAuditWhereInput } from '../../entities/interfaces/_index-entities.interfaces';
import { GraphqlGatewayQueries } from '../../entities/services/queries/_index-entities.queries';

@Injectable({
  providedIn: 'root',
})
export class AuditoryService {
  gqlService: GqlService;
  constructor(gqlService: GqlService) {
    this.gqlService = gqlService;
  }

  async getAuditory(where: EntityAuditWhereInput, orderBy: string): Promise<any[]> {
    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.allEntityAudits, { where: where, orderBy: orderBy })
        .then((response) => {
          if (response['admin']['allEntityAudits']['edges'].length > 0) {
            resolve(response['admin']['allEntityAudits']['edges']);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }
}
