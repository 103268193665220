import { Inject, Injectable } from '@angular/core';
import {
  DuplicatedConnectionContractRS,
  DuplicatedConnectionPartnerRS,
  CheckSuppliersRS,
  CheckConnectionCostsRS,
  CheckCM22SupRS,
  CheckAgreementCM23StdRS,
  CheckDPConnectionNotSupRS,
  GetAmountsByCurrencyRS,
  GetAmountsByContractRS,
} from './../interfaces/_index.billing.interfaces';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

export interface PostConfig {
  step: number;
  date_from: Date;
  date_to: Date;
}

@Injectable({
  providedIn: 'root',
})
export class PreAndPostCheckService {
  refreshPreStepPage = new BehaviorSubject<number>(0);
  currentPreStepPage = new BehaviorSubject<number>(1);
  currentPostConfig = new BehaviorSubject<PostConfig>(null);

  urlBillingCalculation: string;

  constructor(
    @Inject('urlBillingCalculation') urlBillingCalculation: string,
    private http: HttpClient,
  ) {
    this.urlBillingCalculation = urlBillingCalculation;
  }

  async getDuplicatedConnectionsContract(): Promise<DuplicatedConnectionContractRS> {
    const url = this.urlBillingCalculation + 'checks/pre-billing?step=duplicate_contract';

    const headers = new HttpHeaders({ Accept: 'text/json' });
    const options = { headers };

    return await new Promise((resolve, reject) => {
      this.http.post(url, null, options).subscribe(
        (res: any) => {
          resolve(res);
        },
        (err) => {
          if (err?.error?.errors?.length > 0) {
            reject(err?.error?.errors[0]?.error);
          } else if (err.error?.warnings?.length > 0) {
            reject(err.error?.warnings[0].description);
          }
        },
      );
    });
  }

  async getDuplicatedConnectionsPartner(): Promise<DuplicatedConnectionPartnerRS> {
    const url = this.urlBillingCalculation + 'checks/pre-billing?step=duplicate_partner';

    const headers = new HttpHeaders({ Accept: 'text/json' });
    const options = { headers };

    return await new Promise((resolve, reject) => {
      this.http.post(url, null, options).subscribe(
        (res: any) => {
          resolve(res);
        },
        (err) => {
          if (err?.error?.errors?.length > 0) {
            reject(err?.error?.errors[0]?.error);
          } else if (err.error?.warnings?.length > 0) {
            reject(err.error?.warnings[0].description);
          }
        },
      );
    });
  }

  async checkSuppliers(): Promise<CheckSuppliersRS> {
    const url = this.urlBillingCalculation + 'checks/pre-billing?step=suppliers';

    const headers = new HttpHeaders({ Accept: 'text/json' });
    const options = { headers };

    return await new Promise((resolve, reject) => {
      this.http.post(url, null, options).subscribe(
        (res: any) => {
          resolve(res);
        },
        (err) => {
          if (err?.error?.errors?.length > 0) {
            reject(err?.error?.errors[0]?.error);
          } else if (err.error?.warnings?.length > 0) {
            reject(err.error?.warnings[0].description);
          }
        },
      );
    });
  }

  async checkConnectionCosts(): Promise<CheckConnectionCostsRS> {
    const url = this.urlBillingCalculation + 'checks/pre-billing?step=connection_costs';

    const headers = new HttpHeaders({ Accept: 'text/json' });
    const options = { headers };

    return await new Promise((resolve, reject) => {
      this.http.post(url, null, options).subscribe(
        (res: any) => {
          resolve(res);
        },
        (err) => {
          if (err?.error?.errors?.length > 0) {
            reject(err?.error?.errors[0]?.error);
          } else if (err.error?.warnings?.length > 0) {
            reject(err.error?.warnings[0].description);
          }
        },
      );
    });
  }

  async checkDPConnectionsNotSup(): Promise<CheckDPConnectionNotSupRS> {
    const url = this.urlBillingCalculation + 'checks/pre-billing?step=dp_sup';

    const headers = new HttpHeaders({ Accept: 'text/json' });
    const options = { headers };

    return await new Promise((resolve, reject) => {
      this.http.post(url, null, options).subscribe(
        (res: any) => {
          resolve(res);
        },
        (err) => {
          if (err?.error?.errors?.length > 0) {
            reject(err?.error?.errors[0]?.error);
          } else if (err.error?.warnings?.length > 0) {
            reject(err.error?.warnings[0].description);
          }
        },
      );
    });
  }

  async getCM22SupConnections(): Promise<CheckCM22SupRS> {
    const url = this.urlBillingCalculation + 'checks/pre-billing?step=cm22_sup';

    const headers = new HttpHeaders({ Accept: 'text/json' });
    const options = { headers };

    return await new Promise((resolve, reject) => {
      this.http.post(url, null, options).subscribe(
        (res: any) => {
          resolve(res);
        },
        (err) => {
          if (err?.error?.errors?.length > 0) {
            reject(err?.error?.errors[0]?.error);
          } else if (err.error?.warnings?.length > 0) {
            reject(err.error?.warnings[0].description);
          }
        },
      );
    });
  }

  async getCM23WithoutSTDCosts(): Promise<CheckAgreementCM23StdRS> {
    const url = this.urlBillingCalculation + 'checks/pre-billing?step=cm23_std';

    const headers = new HttpHeaders({ Accept: 'text/json' });
    const options = { headers };

    return await new Promise((resolve, reject) => {
      this.http.post(url, null, options).subscribe(
        (res: any) => {
          resolve(res);
        },
        (err) => {
          if (err?.error?.errors?.length > 0) {
            reject(err?.error?.errors[0]?.error);
          } else if (err.error?.warnings?.length > 0) {
            reject(err.error?.warnings[0].description);
          }
        },
      );
    });
  }

  async getBillingScheduledCSV(idBlob: string): Promise<any> {
    const headers = new HttpHeaders({ Accept: 'text/csv' });
    const options = { headers, responseType: 'text' as any };

    return await new Promise((resolve, reject) => {
      this.http.post(`${this.urlBillingCalculation}utils/getScheduledCSV?blobId=${idBlob}`, null, options).subscribe(
        (res: any) => {
          resolve(new Blob([res], { type: 'text/csv' }));
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  async getAmountsByCurrency(month1: string, month2: string): Promise<GetAmountsByCurrencyRS> {
    const url =
      this.urlBillingCalculation + `checks/post-billing?startDate1=${month1}&startDate2=${month2}&step=total_amounts`;

    const headers = new HttpHeaders({ Accept: 'text/json' });
    const options = { headers };

    return await new Promise((resolve, reject) => {
      this.http.post(url, null, options).subscribe(
        (res: any) => {
          resolve(res);
        },
        (err) => {
          if (err?.error?.errors?.length > 0) {
            reject(err?.error?.errors[0]?.error);
          } else if (err.error?.warnings?.length > 0) {
            reject(err.error?.warnings[0].description);
          }
        },
      );
    });
  }

  async getAmountsByContract(month1: string, month2: string): Promise<GetAmountsByContractRS> {
    const url =
      this.urlBillingCalculation +
      `checks/post-billing?startDate1=${month1}&startDate2=${month2}&step=contract_amounts`;

    const headers = new HttpHeaders({ Accept: 'text/json' });
    const options = { headers };

    return await new Promise((resolve, reject) => {
      this.http.post(url, null, options).subscribe(
        (res: any) => {
          resolve(res);
        },
        (err) => {
          if (err?.error?.errors?.length > 0) {
            reject(err?.error?.errors[0]?.error);
          } else if (err.error?.warnings?.length > 0) {
            reject(err.error?.warnings[0].description);
          }
        },
      );
    });
  }

  refreshPreStep(stepNumber: number) {
    this.refreshPreStepPage.next(stepNumber);
  }
  changeCurrentPreStep(stepNumber: number) {
    this.currentPreStepPage.next(stepNumber);
  }

  changePostConfig(currentConfig: PostConfig) {
    this.currentPostConfig.next(currentConfig);
  }

  resetSteps() {
    this.refreshPreStepPage.next(0);
    this.currentPreStepPage.next(1);
  }
}
