<button
  type="button"
  [ngClass]="classes"
  [disabled]="disabled"
  [attr.id]="id"
  (click)="onClickEvent($event)"
  ngbTooltip="{{ tooltip_text }}"
  placement="{{ tooltip_placement }}"
  tooltipClass="xen-tooltip"
>
  <i class="{{ icon_snippet }}" *ngIf="icon"></i>
  {{ label }}
</button>
