import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConnectionFullInfo } from '../../../../interfaces/_index.billing.interfaces';
import { ConnectionService } from '../../../../services/_index-billing.services';
import { NotificationService, WebAdminService } from './../../../../../../@core/services/_index-core.services';
import { Table } from 'primeng/table';
import { BillingConnectionsMainModalComponent } from '../connections-main-modal/connections-main-modal.component';

@Component({
  selector: 'tgx-admin-billing-connections-main-table',
  templateUrl: './connections-main-table.component.html',
  styleUrls: ['./connections-main-table.component.scss'],
})
export class BillingConnectionsMainTableComponent implements OnInit {
  @ViewChild('dt', { static: false }) dataTable!: Table;

  header = [
    { label: 'ID', field: 'id' },
    { label: 'Buyer', field: 'buyer' },
    { label: 'Seller', field: 'seller' },
    { label: 'IsPush', field: 'isPush' },
    { label: 'Api', field: 'api' },
    { label: 'CreatedAt', field: 'createdAt' },
    { label: 'UpdatedAt', field: 'updatedAt' },
  ];

  connectionsLoadSource: any[];

  connections: ConnectionFullInfo[];

  isLoading: boolean;

  isEditor = false;

  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private webAdminService: WebAdminService,
    private connectionService: ConnectionService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.buildOptions();
    await this.getConnections();
  }

  buildOptions() {
    this.isEditor = this.webAdminService.isBillingEditorOrAdmin();
  }

  async getConnections() {
    this.isLoading = true;

    if (this.dataTable) {
      this.dataTable.clearFilterValues();
    }

    this.connectionsLoadSource = [];
    this.connections = [];

    await this.connectionService
      .getConnectionsFullInfo()
      .then((rs) => {
        this.connections = rs;
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
        this.isLoading = false;
      });

    if (this.connections?.length > 0) {
      this.connections.forEach((con) => {
        const { id, buyerName, buyerCode, sellerName, sellerCode, isPush, api, createdAt, updatedAt } = con;

        const buyer = `${buyerName} (${buyerCode})`;
        const seller = `${sellerName} (${sellerCode})`;

        this.connectionsLoadSource.push({
          id,
          buyer,
          seller,
          isPush,
          api,
          createdAt,
          updatedAt,
        });
      });
      this.connectionsLoadSource = [...this.connectionsLoadSource];
      this.connectionsLoadSource.sort((a, b) => (a.id < b.id ? 1 : -1));

      this.isLoading = false;
    } else {
      this.isLoading = false;
    }
  }

  getFilterType(field: string) {
    switch (field) {
      case 'isPush':
        return 'boolean';
      default:
        return 'text';
    }
  }

  async onCreate(): Promise<void> {
    const activeModal = this.modalService.open(BillingConnectionsMainModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.connections = this.connections;

    const self = this;
    activeModal.result.then(async (res) => {
      // on close

      if (typeof res === 'boolean' && res) {
        //todo do nothing at the moment
      } else if (typeof res === 'boolean' && !res) {
        //todo do nothing at the moment
      }
      // OK
      else if (typeof res === 'object') {
        await self.getConnections();
      }
    });
  }

  filter(value: any, field: string) {
    if (value) {
      this.dataTable.filter(value, field, 'contains');
    }
  }
}
