<div class="modal-header">
  <span>{{ header }}</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="supplierForm">
    <nb-card>
      <nb-card-header> Supplier </nb-card-header>
      <nb-card-body>
        <div class="row"></div>
        <div class="row mt-2">
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger': supplierForm.get('code')?.errors && supplierForm.get('code').touched,
              }"
            >
              <label class="mb-1" for="label">Code</label>
              <tooltip-error [element]="supplierForm.get('code')" [errors]="supplierForm.get('code').errors">
              </tooltip-error>
              <input
                type="text"
                class="form-control"
                formControlName="code"
                [ngClass]="{
                  'form-control-danger': supplierForm.get('code')?.errors && supplierForm.get('code').touched,
                }"
                placeholder="Enter a valid code"
                name="code"
              />
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger': supplierForm.get('name')?.errors && supplierForm.get('name').touched,
              }"
            >
              <label class="mb-1" for="label">Name</label>
              <tooltip-error [element]="supplierForm.get('name')" [errors]="supplierForm.get('name').errors">
              </tooltip-error>
              <input
                type="text"
                class="form-control"
                formControlName="name"
                [ngClass]="{
                  'form-control-danger': supplierForm.get('name')?.errors && supplierForm.get('name').touched,
                }"
                placeholder="Enter a valid name"
                name="name"
              />
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger': supplierForm.get('serviceApi')?.errors && supplierForm.get('serviceApi').touched,
              }"
            >
              <label class="mb-1" for="label">Service API</label>
              <tooltip-error
                [element]="supplierForm.get('serviceApi')"
                [errors]="supplierForm.get('serviceApi').errors"
              >
              </tooltip-error>
              <p-dropdown
                [options]="serviceApiEnum"
                [(ngModel)]="serviceApi"
                [virtualScroll]="true"
                itemSize="30"
                [showClear]="true"
                [filter]="true"
                optionsValue="serviceApi.code"
                formControlName="serviceApi"
                appendTo="body"
                baseZIndex="999"
                placeholder="Select serviceApi"
              >
              </p-dropdown>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger': supplierForm.get('provCode')?.errors && supplierForm.get('provCode').touched,
              }"
            >
              <label class="mb-1" for="label">Provider</label>
              <tooltip-error [element]="supplierForm.get('provCode')" [errors]="supplierForm.get('provCode').errors">
              </tooltip-error>
              <p-dropdown
                [options]="providers"
                [(ngModel)]="selectedProvider"
                [virtualScroll]="true"
                itemSize="30"
                [showClear]="true"
                [filter]="true"
                optionsValue="provider.code"
                filterby="provider.label"
                appendTo="body"
                baseZIndex="999"
                placeholder="Select Provider"
                formControlName="provCode"
              >
              </p-dropdown>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger':
                  supplierForm.get('groupContentCode')?.errors && supplierForm.get('groupContentCode').touched,
              }"
            >
              <label class="mb-1" for="label">Group Content:Context</label>
              <tooltip-error
                [element]="supplierForm.get('groupContentCode')"
                [errors]="supplierForm.get('groupContentCode').errors"
              >
              </tooltip-error>
              <p-dropdown
                [options]="groupContent_context"
                [(ngModel)]="selectedGroupContentContext"
                [virtualScroll]="true"
                itemSize="30"
                [showClear]="true"
                [filter]="true"
                optionsValue="groupContentCode.code"
                filterby="groupContentCode.label"
                appendTo="body"
                baseZIndex="999"
                placeholder="Select Group Content / Context"
                formControlName="groupContentCode"
              >
              </p-dropdown>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger': supplierForm.get('orgCode')?.errors && supplierForm.get('orgCode').touched,
              }"
            >
              <label class="mb-1" for="label">Owner Code</label>
              <tooltip-error [element]="supplierForm.get('orgCode')" [errors]="supplierForm.get('orgCode').errors">
              </tooltip-error>
              <p-dropdown
                [options]="organizations"
                [(ngModel)]="selectedOrg"
                [virtualScroll]="true"
                itemSize="30"
                [showClear]="true"
                [filter]="true"
                optionsValue="orgCode.code"
                filterby="orgCode.label"
                appendTo="body"
                baseZIndex="999"
                placeholder="Select an Organization"
                formControlName="orgCode"
              >
              </p-dropdown>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="form-group">
            <label class="mb-1" for="isActive">Is Active</label>
            <tooltip-error [element]="supplierForm.get('isActive')" [errors]="supplierForm.get('isActive').errors">
            </tooltip-error>
            <nb-toggle
              class="form-control"
              [ngClass]="{
                'form-control-danger': supplierForm.get('isActive').errors && supplierForm.get('isActive').touched,
              }"
              name="isActive"
              formControlName="isActive"
            ></nb-toggle>
          </div>
        </div>
        <div class="col-6" *ngIf="!isCreating">
          <div
            class="form-group"
            [ngClass]="{
              'form-control-danger':
                supplierForm.get('sharedDefault')?.errors && supplierForm.get('sharedDefault').touched,
            }"
          >
            <label class="mb-1" for="label">SharedDefault</label>
            <tooltip-error
              [element]="supplierForm.get('sharedDefault')"
              [errors]="supplierForm.get('sharedDefault').errors"
            >
            </tooltip-error>
            <input
              type="text"
              class="form-control"
              formControlName="sharedDefault"
              [ngClass]="{
                'form-control-danger':
                  supplierForm.get('sharedDefault')?.errors && supplierForm.get('sharedDefault').touched,
              }"
              placeholder="Enter a valid sharedDefault"
              name="sharedDefault"
            />
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()">Close</button>
  <button class="btn btn-md btn-primary" [disabled]="supplierForm.invalid" (click)="onSave()">
    {{ isCreating ? 'Create' : 'Update' }}
  </button>
</div>
