import { gql } from 'apollo-angular';

export const updateSupplierGiata = gql`
  mutation ($where: SupplierWhereUniqueInput!, $giataCode: String, $giataType: GiataType) {
    admin {
      updateSupplierGiata(where: $where, data: { giataCode: $giataCode, giataType: $giataType }) {
        supplierData {
          code
        }
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
