import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GraphqlGatewayQueries } from './queries/_index-entities.queries';
import { GqlService } from '@tgx/shared/data-access/graphql';
import { AccessEdge, Access, AccessData } from '../interfaces/models/accesses.interface';
import { GraphqlGatewayMutations } from './mutations/_index-entities.mutations';
import { RetrieveAllAccessesByOwnerInput } from '../interfaces/inputs/retrieve-all-accesses-by-owner-input';
import { AccessUpdateInput } from '../interfaces/inputs/access-update-input.interface';
import { AccessWhereUniqueInput } from '../interfaces/inputs/access-where-unique-input.interface';
import { AccessWhereInput } from '../interfaces/inputs/access-where-input.interface';
import { AccessCreateInput } from '../interfaces/inputs/access-create-input.interface';
import { AccessParameterUpdateInput } from '../interfaces/inputs/access-parameters-update-input.interface';
import { NotificationService } from '../../../@core/services/notification.service';
@Injectable({
  providedIn: 'root',
})
export class AccessesService {
  onToggleTable$: BehaviorSubject<any> = new BehaviorSubject(null);
  onToggleArchive$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private gqlService: GqlService,
    private notificationService: NotificationService,
  ) {}

  async grantAccessToGroups(where: AccessWhereUniqueInput, groupCodes: string[]) {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.grantAccessToGroups, { where: where, groupCodes: groupCodes })
        .then((response) => {
          const adviseMessage = response['admin']['grantAccessToGroups']['adviseMessage'];
          if (adviseMessage && adviseMessage.length > 0) {
            const adviseMessage = response['admin']['grantAccessToGroups']['adviseMessage'][0];
            throw new Error(adviseMessage.description);
          }
          if (response['admin']['grantAccessToGroups']['accessData']) {
            resolve(response['admin']['grantAccessToGroups']['accessData']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          this.notificationService.error(error);
        });
    });
  }

  async revokeAccessFromGroups(where: AccessWhereUniqueInput, groupCodes: string[]) {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.revokeAccessFromGroups, { where: where, groupCodes: groupCodes })
        .then((response) => {
          const adviseMessage = response['admin']['revokeAccessFromGroups']['adviseMessage'];
          if (adviseMessage && adviseMessage.length > 0) {
            const adviseMessage = response['admin']['revokeAccessFromGroups']['adviseMessage'][0];
            throw new Error(adviseMessage.description);
          }
          if (response['admin']['revokeAccessFromGroups']['accessData']) {
            resolve(response['admin']['revokeAccessFromGroups']['accessData']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getAllAccessesGroups(input: AccessWhereInput): Promise<AccessEdge[]> {
    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getAllAccessesGroups, { where: input })
        .then((response) => {
          if (response['admin']['allAccesses']['edges'].length > 0) {
            resolve(response['admin']['allAccesses']['edges']);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          this.notificationService.error(error);
        });
    });
  }

  async getGroupsCodeFromAccessEdge(assignedGroupsSource: any): Promise<String[]> {
    return await new Promise((resolve) => {
      const groupsCode = [];
      assignedGroupsSource.forEach((accessGroups) => {
        accessGroups['node']['accessData']['groups']['edges'].forEach((group) => {
          const code = group['node']['groupData'].code;
          groupsCode.push(code);
        });
      });
      resolve(groupsCode);
    });
  }

  async getAllAccesses(ownerCode: RetrieveAllAccessesByOwnerInput): Promise<AccessEdge[]> {
    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getAllAccesses, { where: ownerCode })
        .then((response) => {
          if (response['admin']['allAccesses']['edges'].length > 0) {
            resolve(response['admin']['allAccesses']['edges']);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          this.notificationService.error(error);
        });
    });
  }

  async activateAccess(input: AccessWhereUniqueInput): Promise<Access> {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.activateAccess, { where: input })
        .then((response) => {
          const adviseMessage = response['admin']['activateAccess']['adviseMessage'];
          if (adviseMessage && adviseMessage.length > 0) {
            const adviseMessage = response['admin']['activateAccess']['adviseMessage'][0];
            throw new Error(adviseMessage.description);
          }
          if (response['admin']['activateAccess']['code']) {
            resolve(response['admin']['activateAccess']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          this.notificationService.error(error);
        });
    });
  }

  async deactivateAccess(input: AccessWhereUniqueInput): Promise<Access> {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.deactivateAccess, { where: input })
        .then((response) => {
          const adviseMessage = response['admin']['deactivateAccess']['adviseMessage'];
          if (adviseMessage && adviseMessage.length > 0) {
            const adviseMessage = response['admin']['deactivateAccess']['adviseMessage'][0];
            throw new Error(adviseMessage.description);
          }
          if (response['admin']['deactivateAccess']['code']) {
            resolve(response['admin']['deactivateAccess']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          this.notificationService.error(error);
        });
    });
  }

  async safeDeactivateAccess(input: AccessWhereUniqueInput): Promise<Access> {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.safeDeactivateAccess, { where: input })
        .then((response) => {
          const adviseMessage = response['admin']['safeDeactivateAccess']['adviseMessage'];
          if (adviseMessage && adviseMessage.length > 0) {
            const adviseMessage = response['admin']['safeDeactivateAccess']['adviseMessage'][0];
            throw new Error(adviseMessage.description);
          }
          if (response['admin']['safeDeactivateAccess']) {
            resolve(response['admin']['safeDeactivateAccess']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          this.notificationService.error(error);
        });
    });
  }

  async createAccess(input: AccessCreateInput): Promise<Access> {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.createAccess, { data: input })
        .then((response) => {
          const adviseMessage = response['admin']['createAccess']['adviseMessage'];
          if (adviseMessage && adviseMessage.length > 0) {
            const adviseMessage = response['admin']['createAccess']['adviseMessage'][0];
            throw new Error(adviseMessage.description);
          }
          if (response['admin']['createAccess']) {
            resolve(response['admin']['createAccess']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          this.notificationService.error(error);
        });
    });
  }

  async updateAccess(where: AccessWhereUniqueInput, data: AccessUpdateInput): Promise<Access> {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.updateAccess, { where: where, data: data })
        .then((response) => {
          const adviseMessage = response['admin']['updateAccess']['adviseMessage'];
          if (adviseMessage && adviseMessage.length > 0) {
            const adviseMessage = response['admin']['updateAccess']['adviseMessage'][0];
            throw new Error(adviseMessage.description);
          }
          if (response['admin']['updateAccess']['code']) {
            resolve(response['admin']['updateAccess']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          this.notificationService.error(error);
        });
    });
  }

  async updateParameters(
    where: AccessWhereUniqueInput,
    data: AccessParameterUpdateInput,
    originalParametersKeys: String[],
  ): Promise<Access> {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.deleteAccessParameters, { where: where, data: originalParametersKeys })
        .then((response) => {
          const adviseMessage = response['admin']['deleteAccessParameters']['adviseMessage'];
          if (adviseMessage && adviseMessage.length > 0) {
            const adviseMessage = response['admin']['deleteAccessParameters']['adviseMessage'][0];
            throw new Error(adviseMessage.description);
          }
          if (response['admin']['deleteAccessParameters']['code']) {
            this.gqlService
              .mutationGateway(GraphqlGatewayMutations.appendAccessParameters, { where: where, data: data })
              .then((response) => {
                const adviseMessage = response['admin']['appendAccessParameters']['adviseMessage'];
                if (adviseMessage && adviseMessage.length > 0) {
                  const adviseMessage = response['admin']['appendAccessParameters']['adviseMessage'][0];
                  throw new Error(adviseMessage.description);
                }
                if (response['admin']['appendAccessParameters']['code']) {
                  resolve(response['admin']['appendAccessParameters']);
                } else {
                  resolve(null);
                }
              })
              .catch((error) => {
                this.notificationService.error(error);
              });
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          this.notificationService.error(error);
        });
    });
  }

  async updateRatesRules(where: AccessWhereUniqueInput, data: String[], originalRateRules: String[]): Promise<Access> {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.deleteAccessRateRules, { where: where, data: originalRateRules })
        .then((response) => {
          if (response['admin']['deleteAccessRateRules']['code']) {
            this.gqlService
              .mutationGateway(GraphqlGatewayMutations.appendAccessRateRules, { where: where, data: data })
              .then((response) => {
                const adviseMessage = response['admin']['appendAccessRateRules']['adviseMessage'];
                if (adviseMessage && adviseMessage.length > 0) {
                  const adviseMessage = response['admin']['appendAccessRateRules']['adviseMessage'][0];
                  throw new Error(adviseMessage.description);
                }
                if (response['admin']['appendAccessRateRules']['code']) {
                  resolve(response['admin']['appendAccessRateRules']);
                } else {
                  resolve(null);
                }
              })
              .catch(() => {
                resolve(null);
              });

            resolve(response['admin']['deleteAccessRateRules']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          this.notificationService.error(error);
        });
    });
  }

  async setAccessShared(where: AccessWhereUniqueInput, sharedID: string) {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.setAccessShared, { where: where, sharedCode: sharedID })
        .then((response) => {
          const adviseMessage = response['admin']['setAccessShared']['adviseMessage'];
          if (adviseMessage && adviseMessage.length > 0) {
            const adviseMessage = response['admin']['setAccessShared']['adviseMessage'][0];
            throw new Error(adviseMessage.description);
          }
          if (response['admin']['setAccessShared']['code']) {
            resolve(response['admin']['setAccessShared']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          this.notificationService.error(error);
        });
    });
  }

  async removeAccessShared(where: AccessWhereUniqueInput) {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.removeAccessShared, { where: where })
        .then((response) => {
          const adviseMessage = response['admin']['removeAccessShared']['adviseMessage'];
          if (adviseMessage && adviseMessage.length > 0) {
            const adviseMessage = response['admin']['removeAccessShared']['adviseMessage'][0];
            throw new Error(adviseMessage.description);
          }
          if (response['admin']['removeAccessShared']['code']) {
            resolve(response['admin']['removeAccessShared']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          this.notificationService.error(error);
        });
    });
  }

  async setAccessMaster(where: AccessWhereUniqueInput, masterID: string) {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.setAccessMaster, { where: where, masterCode: masterID })
        .then((response) => {
          const adviseMessage = response['admin']['setAccessMaster']['adviseMessage'];
          if (adviseMessage && adviseMessage.length > 0) {
            const adviseMessage = response['admin']['setAccessMaster']['adviseMessage'][0];
            throw new Error(adviseMessage.description);
          }
          if (response['admin']['setAccessMaster']['code']) {
            resolve(response['admin']['setAccessMaster']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          this.notificationService.error(error);
        });
    });
  }

  async removeAccessMaster(where: AccessWhereUniqueInput) {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.removeAccessMaster, { where: where })
        .then((response) => {
          const adviseMessage = response['admin']['removeAccessMaster']['adviseMessage'];
          if (adviseMessage && adviseMessage.length > 0) {
            const adviseMessage = response['admin']['removeAccessMaster']['adviseMessage'][0];
            throw new Error(adviseMessage.description);
          }
          if (response['admin']['removeAccessMaster']['code']) {
            resolve(response['admin']['removeAccessMaster']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          this.notificationService.error(error);
        });
    });
  }

  async updateMarkets(where: AccessWhereUniqueInput, data: String[], originalMarkets: String[]): Promise<Access> {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.deleteAccessMarkets, { where: where, data: originalMarkets })
        .then((response) => {
          if (response['admin']['deleteAccessMarkets']['code']) {
            this.gqlService
              .mutationGateway(GraphqlGatewayMutations.appendAccessMarkets, { where: where, data: data })
              .then((response) => {
                const adviseMessage = response['admin']['appendAccessMarkets']['adviseMessage'];
                if (adviseMessage && adviseMessage.length > 0) {
                  const adviseMessage = response['admin']['appendAccessMarkets']['adviseMessage'][0];
                  throw new Error(adviseMessage.description);
                }

                if (response['admin']['appendAccessMarkets']['code']) {
                  resolve(response['admin']['appendAccessMarkets']);
                } else {
                  resolve(null);
                }
              })
              .catch((error) => {
                this.notificationService.error(error);
              });
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async archiveAccess(input: AccessWhereUniqueInput): Promise<Access> {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.archiveAccess, { where: input })
        .then((response) => {
          const adviseMessage = response['admin']['archiveAccess']['adviseMessage'];
          if (adviseMessage && adviseMessage.length > 0) {
            const adviseMessage = response['admin']['archiveAccess']['adviseMessage'][0];
            throw new Error(adviseMessage.description);
          }
          if (response['admin']['archiveAccess']['code']) {
            resolve(response['admin']['archiveAccess']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          this.notificationService.error(error);
        });
    });
  }

  async unArchiveAccess(input: AccessWhereUniqueInput): Promise<Access> {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.unArchiveAccess, { where: input })
        .then((response) => {
          const adviseMessage = response['admin']['unArchiveAccess']['adviseMessage'];
          if (adviseMessage && adviseMessage.length > 0) {
            const adviseMessage = response['admin']['unArchiveAccess']['adviseMessage'][0];
            throw new Error(adviseMessage.description);
          }

          if (response['admin']['unArchiveAccess']['code']) {
            resolve(response['admin']['unArchiveAccess']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          this.notificationService.error(error);
        });
    });
  }

  async getGroupsByAccessCode(where: any) {
    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getGroupsByAccessCode, { where: where })
        .then((response) => {
          const groups = [];
          if (response['admin']['allAccesses']['edges'].length > 0) {
            response['admin']['allAccesses']['edges'].forEach((slot) => {
              if (
                slot['node']['accessData']['groups']['edges'] &&
                slot['node']['accessData']['groups']['edges'].length > 0
              ) {
                slot['node']['accessData']['groups']['edges'].forEach((node) => {
                  groups.push(node['node']['code']);
                });
              }
            });

            resolve(groups);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }
}
