import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { OrganizationsComponent } from './components/organizations.component';
import { OrganizationsTableComponent } from './components/organizations-table/organizations-table.component';
import { OrganizationModalComponent } from './components/organitzations-modal/organization-modal.component';
import { OrganizationsRoutingModule } from './organizations.routing-module';
import { SharedModule } from './../../../../@core/shared/shared.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { NgModule } from '@angular/core';
import { NbActionsModule, NbCardModule, NbCheckboxModule, NbIconModule, NbToggleModule } from '@nebular/theme';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CheckboxModule } from 'primeng/checkbox';

@NgModule({
  imports: [
    TableModule,
    InputTextModule,
    CheckboxModule,
    InputSwitchModule,
    ButtonModule,
    SharedModule,
    OrganizationsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NbCardModule,
    NbCheckboxModule,
    NbActionsModule,
    NbIconModule,
    AutoCompleteModule,
    MultiSelectModule,
    CommonModule,
    DropdownModule,
    NbToggleModule,
  ],
  declarations: [OrganizationsComponent, OrganizationsTableComponent, OrganizationModalComponent],
})
export class OrganizationsModule {}
