import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GraphqlGatewayQueries } from './queries/_index-entities.queries';
import { GqlService } from '@tgx/shared/data-access/graphql';
import { GroupContent } from '../interfaces/models/group-content-context.interface';
import { GraphqlGatewayMutations } from './mutations/_index-entities.mutations';

export interface CreateGroupContentInput {
  code: string;
  contextCode: string;
}

@Injectable({
  providedIn: 'root',
})
export class GroupContentContextService {
  onToggleTable$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private gqlService: GqlService) {}

  async getAll(): Promise<GroupContent[]> {
    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getAllGroupContentContext)
        .then((response) => {
          if (response['admin']['allGroupContents'].length > 0) {
            resolve(response['admin']['allGroupContents']);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async createGroupContent(input: CreateGroupContentInput): Promise<GroupContent> {
    return await new Promise((resolve, _reject) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.createGroupContent, { data: input })
        .then((response) => {
          if (response['admin']['createGroupContent']) {
            resolve(response['admin']['createGroupContent']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }
}
