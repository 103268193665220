import { Injectable } from '@angular/core';
import {
  Contract,
  ContractWhereInput,
  ContractUpdateInput,
  ObjectWhereUniqueInputById,
  ContractCreateInput,
  EntityGroupBy,
} from './../interfaces/_index.billing.interfaces';
import { GraphqlApiBillingMutations } from './mutations/_index-billing-mutations';
import { GraphqlApiBillingQueries } from './queries/_index-billing-queries';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class ContractService {
  constructor(private apollo: Apollo) {}

  async getContracts(orgCode: string): Promise<Contract[]> {
    const where: ContractWhereInput = { Partner: { is: { orgCode: { equals: orgCode } } } };

    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .watchQuery<any>({
          query: GraphqlApiBillingQueries.getContracts,
          variables: { where: where },
          fetchPolicy: 'network-only',
        })
        .valueChanges.subscribe((res: any) => {
          if (res?.data) {
            resolve(((res || {}).data || {}).contracts);
          } else {
            reject(res);
          }
        });
    });
  }

  async getContractsGroupedByType(): Promise<EntityGroupBy[]> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .watchQuery<any>({
          query: GraphqlApiBillingQueries.getContractsGroupedByType,
          fetchPolicy: 'network-only',
        })
        .valueChanges.subscribe((res: any) => {
          if (res?.data) {
            resolve(((res || {}).data || {}).contractsGroupedByType);
          } else {
            reject(res);
          }
        });
    });
  }

  async getContractsCountByType(): Promise<EntityGroupBy[]> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .watchQuery<any>({
          query: GraphqlApiBillingQueries.getContractsCountByType,
          fetchPolicy: 'network-only',
        })
        .valueChanges.subscribe((res: any) => {
          if (res?.data) {
            resolve(((res || {}).data || {}).contractsGroupedByType);
          } else {
            reject(res);
          }
        });
    });
  }

  async createContract(create: ContractCreateInput, loadPDF: boolean): Promise<Contract> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.createContract,
          variables: { data: create, avoidLoadPdf: !loadPDF },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).createContract);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }

  async updateContract(update: ContractUpdateInput, where: ObjectWhereUniqueInputById): Promise<Contract> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.updateContract,
          variables: { data: update, where: where },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).updateContract);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }
}
