import { Subscription } from 'rxjs';
import { switchMap, startWith } from 'rxjs/operators';
import { interval } from 'rxjs/internal/observable/interval';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class BannerMessageService {
  bannerObject: { visible: boolean; message: string; type: string; dismiss: boolean };
  timeInterval: Subscription;

  constructor(
    @Inject('urlNotificationsService') private urlNotificationsService: string,
    private http: HttpClient,
  ) {}

  getBannerMessage() {
    return this.http.get<{ visible: boolean; message: string; type: string; dismiss: boolean }>(
      `${this.urlNotificationsService}admin/get-banner-message`,
      {
        observe: 'response',
      },
    );
  }

  pollingMessage() {
    this.timeInterval = interval(60000)
      .pipe(
        startWith(0),
        switchMap(() => this.getBannerMessage()),
      )
      .subscribe({
        next: (res) => {
          const body = {
            visible: res.body.visible,
            message: res.body.message,
            type: res.body.type ? res.body.type : 'warning',
            dismiss: res.body.dismiss,
          };
          this.bannerObject = body;
        },
        error: (err) => {
          console.log(err);
        },
      });
  }
}
