export interface ClientSuppliersResponse {
  allClients?: AllClients;
  allSuppliers?: AllSuppliers;
}

export interface AllClients {
  edges?: AllClientsEdge[];
}

export interface AllClientsEdge {
  node?: ClientNode;
}

export interface ClientNode {
  clientData?: ClientData;
}

export interface ClientData {
  name?: string;
  owner?: Owner | null;
}

export interface Owner {
  code?: string;
}

export interface AllSuppliers {
  edges?: AllSuppliersEdge[];
}

export interface AllSuppliersEdge {
  node?: SupplierNode;
}

export interface SupplierNode {
  supplierData?: SupplierData;
}

export interface SupplierData {
  code?: string;
  name?: string;
  owner?: Owner | null;
}
