import { Masters, SchedulerFilter, Task, EnqueuerFilter, AddTaskResult } from '../interfaces/_index-content.interfaces';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { copyObject } from '@tgx/shared/utils';

@Injectable({
  providedIn: 'root',
})
export class ConApiService {
  urlContentApi: string;

  constructor(
    @Inject('urlContentApi') urlContentApi: string,
    private http: HttpClient,
  ) {
    this.urlContentApi = urlContentApi;
  }

  async getMasterData(): Promise<Masters> {
    return await new Promise((resolve, reject) => {
      this.http.get(`${this.urlContentApi}enqueuer/masters`).subscribe(
        (res: any) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  async getListQueue(filter: SchedulerFilter): Promise<Task[]> {
    return new Promise((resolve, reject) => {
      this.http.post<any>(`${this.urlContentApi}enqueuer/listQueue/`, JSON.stringify(filter)).subscribe(
        (res: any) => {
          if ((res.adviseMessages || []).length && !(res.tasks || []).length) {
            return reject(res.adviseMessages[0]);
          }

          if (!(res.adviseMessages || []).length && !(res.tasks || []).length) {
            return resolve([]);
          } else {
            return resolve(copyObject(res.tasks));
          }
        },
        (err) => reject(err),
      );
    });
  }

  abortTask(taskID) {
    return new Promise<void>((resolve, reject) => {
      this.http
        .delete(`${this.urlContentApi}enqueuer/abortTask`, { body: { taskID: taskID, schedulerStatus: 'AdminAbort' } })
        .subscribe(
          (res: any) => {
            if (res?.adviseMessages?.length === 0 && res?.numberTasksAborted === 1 && res?.successful) {
              resolve();
            } else {
              reject(res.adviseMessages[0]);
            }
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }

  dateMonthsDiff(dateFrom: Date, dateTo: Date): number {
    return dateTo.getMonth() - dateFrom.getMonth() + 12 * (dateTo.getFullYear() - dateFrom.getFullYear());
  }

  isLeapYear(year: number): boolean {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }

  getDaysInMonth(year: number, month: number) {
    return [31, this.isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
  }

  addMonths(date: Date, months: number): Date {
    const tempDate = new Date(copyObject(date));
    const n = tempDate.getDate();
    tempDate.setDate(1);
    tempDate.setMonth(date.getMonth() + months);
    return new Date(tempDate.setDate(Math.min(n, this.getDaysInMonth(date.getFullYear(), date.getMonth()))));
  }

  addTaskByAccess(enqueuerFilter: EnqueuerFilter) {
    return new Promise<AddTaskResult>((resolve, reject) => {
      this.http
        .put(`${this.urlContentApi}enqueuer/addtask/byaccess`, {
          accesses: enqueuerFilter.accesses,
          serviceOperations: [enqueuerFilter.serviceOperations],
          serviceType: enqueuerFilter.serviceType,
          force: enqueuerFilter.force,
          Priority: enqueuerFilter.priority,
          Parameters: { ObsoleteDays: enqueuerFilter.obsoleteDays },
        })
        .subscribe(
          (res: AddTaskResult) => {
            resolve(res);
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }

  addTaskAll(enqueuerFilter: EnqueuerFilter) {
    return new Promise<AddTaskResult>((resolve, reject) => {
      this.http
        .put(`${this.urlContentApi}enqueuer/addtask/all`, {
          serviceOperations: [enqueuerFilter.serviceOperations],
          force: enqueuerFilter.force,
          Priority: enqueuerFilter.priority,
          serviceType: enqueuerFilter.serviceType,
        })
        .subscribe(
          (res: AddTaskResult) => {
            resolve(res);
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }
}
