import { Injectable } from '@angular/core';
import {
  ObjectWhereUniqueInputById,
  ContractPlugin,
  ContractPluginCreateInput,
  ContractPluginUpdateInput,
  Plugin,
} from './../interfaces/_index.billing.interfaces';
import { GraphqlApiBillingMutations } from './mutations/_index-billing-mutations';
import { GraphqlApiBillingQueries } from './queries/_index-billing-queries';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class ContractPluginService {
  constructor(private apollo: Apollo) {}

  async getPluginTypes(): Promise<Plugin[]> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .watchQuery<any>({
          query: GraphqlApiBillingQueries.getPlugins,
          fetchPolicy: 'network-only',
        })
        .valueChanges.subscribe((res: any) => {
          if (res?.data) {
            resolve(((res || {}).data || {}).plugins);
          } else {
            reject(res);
          }
        });
    });
  }

  async getContractPlugins(contractId: number): Promise<ContractPlugin[]> {
    const where = { contract: { equals: contractId } };

    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .watchQuery<any>({
          query: GraphqlApiBillingQueries.getContractPlugins,
          variables: { where: where },
          fetchPolicy: 'network-only',
        })
        .valueChanges.subscribe((res: any) => {
          if (res?.data) {
            resolve(((res || {}).data || {}).contractPlugins);
          } else {
            reject(res);
          }
        });
    });
  }

  async createContractPlugin(create: ContractPluginCreateInput): Promise<ContractPlugin> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.createContractPlugin,
          variables: { data: create },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).createContractPlugin);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }

  async updateContractPlugin(
    update: ContractPluginUpdateInput,
    where: ObjectWhereUniqueInputById,
  ): Promise<ContractPlugin> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.updateContractPlugin,
          variables: { data: update, where: where },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).updateContractPlugin);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }
}
