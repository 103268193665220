import { BehaviorSubject } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { PlatformInfoService } from './platform-info.service';
import { PlatformBrowserService } from './platform-browser.service';
import { decodeJWT } from '@tgx/shared/utils';
import { MembersRole } from '@tgx/shared/enums';
import { configLocalStorage } from '@tgx/shared/constants';
import { authQueries } from '@tgx/shared/data-access/graphql';
import { BearerPermissions, IAuth0Permissions, IAuth0User } from '@tgx/shared/interfaces';

@Injectable()
export class PlatformAuthService {
  userLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isBrowser: boolean;

  constructor(
    private apollo: Apollo,
    private cookieService: CookieService,
    private platformInfoService: PlatformInfoService,
    private platformBrowserService: PlatformBrowserService,
  ) {
    this.isBrowser = this.platformBrowserService.isBrowser();
  }

  setUserSessionData() {
    // Get session data from local storage
    const loggedInData = localStorage.getItem(configLocalStorage.cookieLoggedin);
    if (!loggedInData) {
      // Redirect to login if no session data
      this.redirectToLogin();
      return;
    }

    // Parse session data
    const login = JSON.parse(loggedInData as string);
    if (!login) {
      // Redirect to login if session data is invalid
      this.redirectToLogin();
      return;
    }

    // Set user session and fetch profile
    this.setSession(login, login.expiresAt);
    // this.getProfileByTokenId(login.idToken);

    // Handle impersonation if required
    if (this.isBrowser && this.cookieService.check(configLocalStorage.cookieImpersonation)) {
      this.handleImpersonation();
    } else {
      this.getProfileByTokenId(login.idToken);

      // Mark user as logged in
      this.userLoggedIn.next(true);
    }
  }

  private handleImpersonation() {
    // Get the impersonation cookie and impersonation data from local storage
    const emailImpersCookie = this.cookieService.get(configLocalStorage.cookieImpersonation);
    const idTokenImpersStorage = this.platformBrowserService.GetItemFromLocalStorage(
      configLocalStorage.idTokenImpersonation,
    );
    const profile = this.platformInfoService.userAuthenticated$.value;

    // Update local storage with the impersonation cookie
    const emailImpersStorage = emailImpersCookie;

    // If there is impersonation data but no impersonation cookie, clear impersonation
    if (
      (!!emailImpersStorage || !!idTokenImpersStorage) &&
      !this.cookieService.check(configLocalStorage.cookieImpersonation)
    ) {
      this.clearImpersonation(true);
      return;
    }

    // If impersonation data does not match the impersonation cookie, clear impersonation
    if ((!!emailImpersStorage || !!idTokenImpersStorage) && emailImpersCookie !== emailImpersStorage) {
      this.clearImpersonation(true);
      return;
    }

    // Update the user's profile with impersonation data and mark the user as logged in
    (profile as IAuth0User).impersonation = emailImpersStorage;

    this.platformInfoService.userAuthenticated$.next(profile);
    this.platformInfoService.userImpersonated$.next(this.decodeToken(idTokenImpersStorage));
    this.userLoggedIn.next(true);
  }

  private redirectToLogin() {
    this.platformBrowserService.ClearLocalStorage();
    window.location.href = '/';
  }

  /**
   * Check whether the id_token is expired or not
   */
  public initAuthorizationUniversal() {
    if (this.isBrowser) {
      this.isAuthenticated(false);
    }
  }

  public isAuthenticated(redirectToHome = true): boolean {
    // Removes cookies in case the user has some stuck
    this.cookieService.delete(configLocalStorage.accessToken);
    this.cookieService.delete(configLocalStorage.idToken);
    this.cookieService.delete(configLocalStorage.expiresAt);

    const expiresAtValue = this.platformBrowserService.GetItemFromLocalStorage('expires_at');

    if (expiresAtValue !== '') {
      const expiresAt = JSON.parse(expiresAtValue);
      if (new Date().getTime() < expiresAt) {
        return true;
      } else {
        this.resetUserData();
        if (redirectToHome) {
          window.location.href = '/';
        }
      }
    }
    return false;
  }

  setSession(authResult: any, expiresAt: any, setCookie = false): void {
    if (this.isBrowser) {
      this.platformBrowserService.SetItemInLocalStorage(configLocalStorage.accessToken, authResult.accessToken);
      this.platformBrowserService.SetItemInLocalStorage(configLocalStorage.idToken, authResult.idToken);
      this.platformBrowserService.SetItemInLocalStorage(configLocalStorage.expiresAt, expiresAt);
    }
    if (setCookie) {
      this.saveCookies();
    }
  }

  decodeToken(idToken: string): IAuth0User {
    const profileByTokenId = decodeJWT(idToken);

    const profile: IAuth0User = {
      email: profileByTokenId['https://travelgatex.com/member_id'],
      impersonation: profileByTokenId['https://travelgatex.com/impersonated_id'] ?? null,
      permissions: this.convertPermissionsToMap(profileByTokenId['https://travelgatex.com/org']),
      picture: profileByTokenId.picture,
      expiration_date: new Date(profileByTokenId['exp'] * 1000),
      firstName: profileByTokenId.given_name,
    };

    return profile;
  }

  getProfileByTokenId(idToken: any, isOnlyEmiting?: boolean): void {
    const profileByTokenId = decodeJWT(idToken);

    const profile: IAuth0User = {
      email: profileByTokenId['https://travelgatex.com/member_id'],
      impersonation: profileByTokenId['https://travelgatex.com/impersonated_id'] ?? null,
      permissions: this.convertPermissionsToMap(profileByTokenId['https://travelgatex.com/org']),
      picture: profileByTokenId.picture,
      expiration_date: new Date(profileByTokenId['exp'] * 1000),
      firstName: profileByTokenId.given_name,
    };

    if (!isOnlyEmiting) {
      this.cookieService.set(
        configLocalStorage.userID,
        profileByTokenId.email,
        0,
        '/',
        this.platformBrowserService.getCookieDomain(),
      );
      this.platformBrowserService.SetItemInLocalStorage(configLocalStorage.userProfile, JSON.stringify(profile));
    }
    this.platformInfoService.userAuthenticated$.next(profile);
  }

  private convertPermissionsToMap(permissions: BearerPermissions[]): Map<string, IAuth0Permissions> {
    const firstMap = new Map<string, IAuth0Permissions>();

    permissions.forEach((permissionsByOrg) => {
      let secondMap: Map<string, string>;

      if (permissionsByOrg.s) {
        secondMap = new Map<string, any>();
        permissionsByOrg.s.forEach((service) => {
          // Aquí, en lugar de solo guardar service.r, guardamos un objeto con todas las propiedades
          secondMap!.set(service.c, service.r);
        });
      }

      // Ahora guardamos un objeto que incluye tanto el secondMap como la propiedad "r" del primer nivel
      firstMap.set(permissionsByOrg.o, {
        role: permissionsByOrg.r as MembersRole,
        services: secondMap ?? new Map<string, any>(),
      });
    });

    return firstMap;
  }

  private saveCookies() {
    if (this.isBrowser) {
      const idTokenImpersonation = this.platformBrowserService.GetItemFromLocalStorage(
        configLocalStorage.idTokenImpersonation,
      );
      const memberCodeImpersonation = this.platformBrowserService.GetItemFromLocalStorage(
        configLocalStorage.emailImpersonation,
      );

      if (!!idTokenImpersonation && !!memberCodeImpersonation) {
        this.cookieService.set(configLocalStorage.cookieImpersonation, memberCodeImpersonation, {
          path: '/',
          domain: this.platformBrowserService.getCookieDomain(),
        });
      }

      const accessToken = this.platformBrowserService.GetItemFromLocalStorage(configLocalStorage.accessToken);
      const idToken = this.platformBrowserService.GetItemFromLocalStorage(configLocalStorage.idToken);
      const expiresAt = this.platformBrowserService.GetItemFromLocalStorage(configLocalStorage.expiresAt);

      const dataToStore = JSON.stringify({
        accessToken: accessToken,
        idToken: idToken,
        expiresAt: expiresAt,
      });

      if (accessToken && idToken && expiresAt) {
        localStorage.setItem(configLocalStorage.cookieLoggedin, dataToStore);
      }
    }
  }

  impersonate(memberCode: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.apollo
        .use('iam')
        .query({
          query: authQueries.members.getImpersonation,
          variables: {
            memberCode: memberCode,
          },
        })
        .subscribe(
          (res: any) => {
            if (res.data?.admin?.members?.edges[0]?.node?.memberData?.impersonationJWT?.adviseMessage) {
              return reject(
                res.data?.admin?.members?.edges[0]?.node?.memberData?.impersonationJWT?.adviseMessage[0]?.description,
              );
            } else if (res.data?.admin?.members?.edges[0]?.node?.memberData) {
              return resolve(res.data?.admin?.members?.edges[0]?.node?.memberData.impersonationJWT);
            }
            return reject(false);
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }

  setImpersonation(idToken: any, memberCode: any) {
    if (this.isBrowser) {
      this.platformBrowserService.SetItemInLocalStorage(configLocalStorage.idTokenImpersonation, idToken);
      this.platformBrowserService.SetItemInLocalStorage(configLocalStorage.emailImpersonation, memberCode);
      this.platformBrowserService.RemoveItemFromLocalStorage('organization');
      this.platformBrowserService.RemoveItemFromLocalStorage('filter');
      this.platformBrowserService.RemoveItemFromLocalStorage('loggingFilter');
    }
  }

  clearImpersonation(noReload = false) {
    if (this.isBrowser) {
      this.platformBrowserService.RemoveItemFromLocalStorage(configLocalStorage.idTokenImpersonation);
      this.platformBrowserService.RemoveItemFromLocalStorage(configLocalStorage.emailImpersonation);
      if (this.cookieService.check(configLocalStorage.cookieImpersonation)) {
        this.cookieService.delete(
          configLocalStorage.cookieImpersonation,
          '/',
          this.platformBrowserService.getCookieDomain(),
        );
      }
      if (!noReload) {
        window.location.reload();
      }
    }
  }

  // REDIRECTS

  goUrlTgx(href: string) {
    this.saveCookies();
    window.location.href = href;
  }

  // LOGOUT

  /**
   * Remove user data from localStorage
   */
  logout(): void {
    this.resetUserData();
    window.location.href = 'https://www.travelgate.com/';
  }

  resetUserData(): void {
    this.platformBrowserService.ClearLocalStorage();
    this.platformInfoService.userAuthenticated$.next(null);
    if (this.cookieService.check(configLocalStorage.userID)) {
      this.cookieService.delete(configLocalStorage.userID, '/', this.platformBrowserService.getCookieDomain());
    }
  }
}
