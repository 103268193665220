<tgx-spinner [class]="'routingLoading'" [isLoading]="isLoading" [top]="'180px'"></tgx-spinner>

<nb-card *ngIf="!isLoading">
  <nb-card-header>
    <div class="row">
      <div class="col-3" *ngIf="selectedLegacyConnections?.length === 0">
        <span>Legacy connections</span>
      </div>
      <div class="col-3" *ngIf="selectedLegacyConnections?.length > 0">
        <span> Selected {{ selectedClients?.length }} Clients of {{ clients?.length }} </span>
      </div>
      <div class="col-6" *ngIf="selectedLegacyConnections?.length > 0">
        <nb-actions size="medium">
          <nb-action *ngIf="legacyConnections?.length > 10">
            <nb-checkbox (checkedChange)="selectAll($event)"><span>Select All</span></nb-checkbox>
          </nb-action>
          <nb-action (click)="onEditMassive(event)">
            <nb-icon options="iconOptions" class="action-icon" icon="settings-2-outline"></nb-icon>
            <span>Modify</span>
          </nb-action>
        </nb-actions>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <p-table
      #dt1
      *ngIf="!isLoading"
      [value]="legacyConnectionsLoadSource"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      [tableStyle]="{ 'min-width': '50rem' }"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
      styleClass="p-datatable-gridlines p-datatable-striped"
      #dataTable
    >
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="hubUser">
            Hub user
            <p-sortIcon field="hubUser"></p-sortIcon>
          </th>
          <th pSortableColumn="hubProvider">
            Hub provider
            <p-sortIcon field="hubProvider"></p-sortIcon>
          </th>
          <th pSortableColumn="isActive">
            IsActive
            <p-sortIcon field="isActive"></p-sortIcon>
          </th>
          <th>Options</th>
        </tr>
        <tr>
          <th>
            <p-columnFilter
              [showClearButton]="false"
              placeholder="Hub user"
              type="text"
              field="hubUser"
              matchMode="contains"
              (input)="dt1.filter($event.target.value, 'hubUser', 'contains')"
            ></p-columnFilter>
          </th>
          <th>
            <p-columnFilter
              [showClearButton]="false"
              placeholder="Hub provider"
              type="text"
              field="hubProvider"
              matchMode="contains"
              (input)="dt1.filter($event.target.value, 'hubProvider', 'contains')"
            ></p-columnFilter>
          </th>
          <th>
            <p-columnFilter [showClearButton]="false" type="boolean" field="isActive"></p-columnFilter>
          </th>
          <th>
            <p-button class="p-button" icon="pi pi-plus" (onClick)="onCreate()"></p-button>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-legacyClients>
        <tr>
          <td>{{ legacyClients.hubUser }}</td>
          <td>{{ legacyClients.hubProvider }}</td>
          <td>
            <p-inputSwitch
              [(ngModel)]="legacyClients.isActive"
              (onChange)="onToggleActivateDeactiveClient(legacyClients)"
            ></p-inputSwitch>
          </td>
          <td width="3rem"></td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>
