import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'xen-subtitle-page',
  templateUrl: './subtitle-page.component.html',
  styleUrls: ['./subtitle-page.component.scss'],
})
export class SubtitlePageComponent implements OnInit {
  @Input() back = true;
  @Input() title: string;
  @Input() label = 'en el archivo ts';
  @Input() href: string;

  @Output() subtitleLinkEvent = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  subtitleLink(value: any) {
    this.subtitleLinkEvent.emit(value);
  }
}
