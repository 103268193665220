import { Component, Input } from '@angular/core';

@Component({
  selector: 'xen-subtitle-section',
  templateUrl: './subtitle-section.component.html',
  styleUrls: ['./subtitle-section.component.scss'],
})
export class SubtitleSectionComponent {
  @Input() title: string;
  constructor() {}
}
