<div class="modal-header">
  <span>{{ header }}</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <nb-card>
    <nb-card-header> Data </nb-card-header>
    <nb-card-body *ngIf="!isLoading">
      <tgx-spinner [class]="'routingLoading'" [isLoading]="isLoading" [top]="'0px'"></tgx-spinner>
      <form [formGroup]="connectionForm">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="id">ID</label>
              <input type="text" class="form-control" name="id" formControlName="id" />
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="isBuy">Is Buy</label>
              <nb-checkbox class="form-control" name="isBuy" formControlName="isBuy"></nb-checkbox>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-3">
            <div class="form-group">
              <label class="mb-1" for="free">Free</label>
              <nb-checkbox class="form-control" name="free" formControlName="free"></nb-checkbox>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label class="mb-1" for="setupPaid">Setup Paid</label>
              <nb-checkbox class="form-control" name="setupPaid" formControlName="setupPaid"></nb-checkbox>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="connectionType"
                >ConnectionType
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="connectionForm.get('connectionType')"
                [errors]="connectionForm.get('connectionType').errors"
              ></tooltip-error>
              <div class="form-group">
                <p-dropdown
                  (onHide)="validDropdown('connectionType')"
                  [options]="connectionTypes"
                  [appendTo]="'body'"
                  baseZIndex="999"
                  formControlName="connectionType"
                  optionLabel="label"
                  filterBy="label"
                >
                  <ng-template pTemplate="selectedItem">
                    <div *ngIf="connectionForm.get('connectionType').value">
                      <div>{{ connectionForm.get('connectionType').value.label }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-ci pTemplate="ci">
                    <div>
                      <div>{{ ci.label }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="activationDateTime"
                >Activation Date
                <span class="mandatory ml-1">*</span>
              </label>
              <p-calendar
                [appendTo]="'body'"
                baseZIndex="999"
                class="form-control p-0 border-0 w-100"
                formControlName="activationDateTime"
                dateFormat="dd/mm/yy"
                [showIcon]="true"
                inputId="icon"
                [readonlyInput]="true"
                placeholder="Enter a date.."
              ></p-calendar>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="endDate">End Date</label>
              <p-calendar
                [appendTo]="'body'"
                baseZIndex="999"
                class="form-control p-0 border-0 w-100"
                formControlName="endDate"
                dateFormat="dd/mm/yy"
                [showIcon]="true"
                inputId="icon"
                [readonlyInput]="true"
                placeholder="Enter a date.."
              ></p-calendar>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="buyer">Buyer<span class="mandatory ml-1">*</span> </label>
              <tooltip-error
                [element]="connectionForm.get('buyer')"
                [errors]="connectionForm.get('buyer').errors"
              ></tooltip-error>
              <div class="form-group">
                <p-dropdown
                  [options]="buyerOptions"
                  placeholder="Select a Buyer"
                  optionLabel="title"
                  appendTo="body"
                  filter="true"
                  baseZIndex="999"
                  formControlName="buyer"
                >
                </p-dropdown>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="seller">Seller<span class="mandatory ml-1">*</span> </label>
              <tooltip-error
                [element]="connectionForm.get('seller')"
                [errors]="connectionForm.get('seller').errors"
              ></tooltip-error>
              <div class="form-group">
                <p-dropdown
                  [options]="sellerOptions"
                  placeholder="Select a Seller"
                  optionLabel="title"
                  appendTo="body"
                  filter="true"
                  baseZIndex="999"
                  formControlName="seller"
                >
                </p-dropdown>
              </div>
            </div>
          </div>
        </div>
      </form>
    </nb-card-body>
    <nb-card-body *ngIf="isLoading">
      <span>Data is being loaded, please wait..</span>
    </nb-card-body>
  </nb-card>
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()">
    {{ ended ? 'Close' : isEditor ? 'Cancel' : 'Close' }}
  </button>
  <button *ngIf="isEditor && !ended" class="btn btn-md btn-primary" [disabled]="!validForm()" (click)="onSave()">
    {{ isCreating ? 'Create' : 'Update' }}
  </button>
</div>
