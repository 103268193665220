import { Component, Input } from '@angular/core';

@Component({
  selector: 'xen-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
  @Input() type: 'info' | 'success' | 'warning' | 'danger' = 'info';
  @Input() content: string;
  @Input() icon: boolean;
  @Input() icon_snippet: string;
  @Input() dismiss: boolean;
  @Input() system: boolean;
  @Input() hasbutton: boolean;

  public get classes(): Array<string> {
    return [
      'xen-alert',
      `xen-alert--${this.type}`,
      this.system ? '--is-system' : '',
      this.dismiss ? 'alert-dismissible fade show' : '',
    ];
  }

  ngOnInit(): void {}
}
