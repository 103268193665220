<div [formGroup]="form" class="form-check form-switch xen-switch" [ngClass]="classes">
  <input
    class="form-check-input"
    [ngClass]="inputClasses"
    type="checkbox"
    [id]="id"
    [name]="formControlName"
    [formControlName]="formControlName"
    role="switch"
    [checked]="checked"
    [disabled]="disabled"
  />
  <label class="form-check-label" [for]="id">
    {{ label }}
  </label>
  <div class="xen-switch__feedback" [ngClass]="feedbackClasses" *ngIf="validation">{{ feedback }}</div>
</div>
