import { SharedModule } from './../../../../@core/shared/shared.module';
import { NgModule } from '@angular/core';
import { NbCardModule, NbCheckboxModule, NbTabsetModule } from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { NgbModule, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { PaymentRequestRoutingModule } from './payment-requests-routing.module';
import { BillingPaymentRequestComponent } from './payment-requests.component';
import { BillingPaymentRequestsContractComponent } from './components/payment-requests-contract/payment-requests-contract.component';
import { BillingPaymentRequestsGlobalComponent } from './components/payment-requests-global/payment-requests-global.component';

@NgModule({
  imports: [
    TableModule,
    CheckboxModule,
    ButtonModule,
    SharedModule,
    PaymentRequestRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NbCardModule,
    NbCheckboxModule,
    DropdownModule,
    NbTabsetModule,
    CommonModule,
    NgbModule,
  ],
  declarations: [
    BillingPaymentRequestComponent,
    BillingPaymentRequestsContractComponent,
    BillingPaymentRequestsGlobalComponent,
  ],
  providers: [NgbTooltipConfig],
})
export class PaymentRequestModule {}
