import { NotificationService, WebAdminService } from './../../../../../../@core/services/_index-core.services';
import { BillScheduled } from './../../../../interfaces/_index.billing.interfaces';
import { BillScheduledService, PreAndPostCheckService } from './../../../../services/_index-billing.services';
import { Table } from 'primeng/table';
import { Component, OnInit, ViewChild } from '@angular/core';
import { saveAs } from 'file-saver';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDeleteComponent } from './../../../../../../@core/shared/modal-delete/modal-delete.component';

@Component({
  selector: 'tgx-invoicing-schedules',
  templateUrl: './invoicing-schedules.component.html',
  styleUrls: ['./invoicing-schedules.component.scss'],
})
export class InvoicingSchedulesTableComponent implements OnInit {
  @ViewChild('dt', { static: false }) dataTable!: Table;

  schedules: BillScheduled[];
  schedulesLoadSource: any[];
  isLoading: boolean;
  isEditor = false;

  header = [
    { label: 'ID', field: 'ID' },
    { label: 'StartDate', field: 'StartDate' },
    { label: 'EndDate', field: 'EndDate' },
    { label: 'ExecDate', field: 'ExecDate' },
    { label: 'DateStarted', field: 'DateStarted' },
    { label: 'DateFinished', field: 'DateFinished' },
    { label: 'CreatedAt', field: 'CreatedAt' },
    { label: 'UpdatedAt', field: 'UpdatedAt' },
    { label: 'Options', field: 'Options' },
  ];

  constructor(
    private notificationService: NotificationService,
    private webAdminService: WebAdminService,
    private billScheduledService: BillScheduledService,
    private preAndPostCheckService: PreAndPostCheckService,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.buildOptions();
    this.getSchedules();
  }

  buildOptions() {
    this.isEditor = this.webAdminService.isBillingEditorOrAdmin();
  }

  getSchedules() {
    this.isLoading = true;

    this.billScheduledService
      .getAllBillScheduled()
      .then((schds) => {
        this.schedules = schds;
        this.buildTable();
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  buildTable() {
    this.schedulesLoadSource = [];

    if (this.schedules?.length > 0) {
      this.schedules.forEach((s) => {
        this.schedulesLoadSource.push({
          ID: s.id,
          StartDate: s.startDate,
          EndDate: new Date(s.endDate.toString().split('T')[0]),
          ExecDate: s.executionDate,
          DateStarted: s.startingDate,
          DateFinished: s.finishDate,
          CreatedAt: s.createdAt,
          UpdatedAt: s.updatedAt,
        });
      });
      this.schedulesLoadSource = [...this.schedulesLoadSource];

      this.schedulesLoadSource.sort((a, b) => (a.id < b.id ? 1 : -1));
    }
  }

  async onDownload(request: any) {
    if (request.DateFinished) {
      const current = this.schedules.filter((x) => x.id === request.ID)[0];
      const values: any[] = current.endDate.toString().split('T');

      await this.preAndPostCheckService
        .getBillingScheduledCSV(current.idBlob)
        .then((csv) => {
          const endDate = values[0].replaceAll('-', '');
          const fileName = 'billing_' + endDate + '.csv';
          saveAs(csv, fileName);
          this.isLoading = false;
          this.notificationService.success('CSV successfully downloaded.', 'Success');
        })
        .catch((err) => {
          const parsedErr = JSON.parse(err.error);
          this.notificationService.handleGatewayAndGraphqlErrors(parsedErr.errors[0]);
          this.isLoading = false;
        });
    } else {
      this.notificationService.warning('This execution has not finished, try it when it has DateFinished filled.');
      return;
    }
  }

  onDelete(request: any): void {
    const modalRef = this.modalService.open(ModalDeleteComponent, {
      backdrop: 'static',
      container: 'nb-layout',
    });

    modalRef.componentInstance.message = 'Are you sure that you want to delete the current scheduled?';
    modalRef.componentInstance.title = 'Delete Scheduled';
    modalRef.componentInstance.buttonOkTitle = 'Delete';

    const self = this;

    modalRef.result
      .then(async (res) => {
        // on close
        if (res) {
          await self.billScheduledService
            .deleteBillScheduled({ id: request.ID })
            .then((rs) => {
              self.getSchedules();
              this.notificationService.success('Bill scheduled succesfully deleted', 'Success');
            })
            .catch((err) => {
              this.notificationService.handleGatewayAndGraphqlErrors(err);
            });
        }
      })
      .catch((error) => {
        self.notificationService.handleGatewayAndGraphqlErrors(error);
      });
  }
}
