<tgx-spinner [class]="'routingLoading'" [isLoading]="isLoading" [top]="'200px'"></tgx-spinner>
<nb-card>
  <nb-card-header>
    {{ isEditor ? 'Select a contract' : 'Warning' }}
  </nb-card-header>
  <nb-card-body *ngIf="!isEditor">
    You are in the billing group, but you must be an admin to view and execute this operation.
  </nb-card-body>
  <nb-card-body *ngIf="isEditor">
    <div class="row mb-3">
      <div class="col-6">
        <label class="mt-2 mb-1">Contract: </label>
        <p-dropdown
          (onHide)="getConnections()"
          [options]="contractsSelector"
          [appendTo]="'body'"
          baseZIndex="999"
          [disabled]="contractsSelector[0]?.code === null"
          [(ngModel)]="selectedContract"
          optionLabel="label"
          filterBy="label"
          (onHide)="resetTable(); getConnections()"
        >
          <ng-template pTemplate="selectedItem">
            <div *ngIf="selectedContract">
              <div>{{ selectedContract?.label }}</div>
            </div>
          </ng-template>
          <ng-template let-ci pTemplate="ci">
            <div>
              <div>{{ ci.label }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="mt-2 mb-1" for="startDate"
            >Start Date
            <span class="mandatory ml-1">*</span>
          </label>
          <p-calendar
            [appendTo]="'body'"
            [disabled]="contractsSelector[0]?.code === null"
            baseZIndex="999"
            class="form-control p-0 border-0 w-100"
            [(ngModel)]="startDate"
            dateFormat="dd/mm/yy"
            [showIcon]="true"
            inputId="icon"
            [readonlyInput]="true"
            placeholder="Enter a date.."
          ></p-calendar>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="mt-2 mb-1" for="endDate"
            >End Date
            <span class="mandatory ml-1">*</span>
          </label>
          <p-calendar
            [appendTo]="'body'"
            [disabled]="contractsSelector[0]?.code === null || !startDate"
            baseZIndex="999"
            class="form-control p-0 border-0 w-100"
            [(ngModel)]="endDate"
            [minDate]="startDate"
            dateFormat="dd/mm/yy"
            [showIcon]="true"
            inputId="icon"
            [readonlyInput]="true"
            placeholder="Enter a date.."
          ></p-calendar>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        class="btn btn-md btn-danger"
        [disabled]="source?.data?.length === 0 && !startDate && !endDate"
        (click)="resetTable()"
      >
        Reset
      </button>
      <button
        class="btn btn-md btn-primary"
        [disabled]="source?.data?.length === 0 || !startDate || !endDate"
        (click)="runConciliate()"
      >
        Bill
      </button>
    </div>
  </nb-card-body>
</nb-card>

<nb-card *ngIf="contractsSelector[0]?.code !== null">
  <nb-card-header> Conciliate a Invoice </nb-card-header>
  <nb-card-body *ngIf="!isLoading">
    <div class="col-12 mb-3">
      <span
        ><i
          >Generate one invoice manually conciliating some inconsistencies billed on a <b>CM2022</b> contract
          <b>ONLY</b>.</i
        ></span
      >
    </div>
    <p-table
      #dataTable
      [value]="billSource"
      styleClass="p-datatable-gridlines p-datatable-striped"
      [tableStyle]="{ 'min-width': '50rem' }"
      editMode="row"
      dataKey="id"
      [resizableColumns]="true"
      columnResizeMode="expand"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let head of header">
            {{ head.label }}
          </th>
        </tr>
        <tr>
          <th *ngFor="let head of header" [style]="head.field === 'Options' ? 'width: 3rem' : ''">
            <p-button
              *ngIf="head.field === 'Options'"
              class="p-button"
              icon="pi pi-plus"
              (onClick)="addRow()"
            ></p-button>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-invoice let-editing="editing">
        <tr [pEditableRow]="invoice">
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-dropdown
                  [(ngModel)]="invoice.connection"
                  [options]="connectionSuggestion"
                  placeholder="Select connection"
                  optionLabel="title"
                  appendTo="body"
                  filter="true"
                  baseZIndex="999"
                  (onHide)="validConnection(invoice, 'CONNECTION')"
                >
                </p-dropdown>
              </ng-template>
              <ng-template pTemplate="output"> {{ invoice.connection.title }} </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-dropdown
                  [(ngModel)]="invoice.client"
                  [options]="clientSuggestion"
                  placeholder="Select client"
                  optionLabel="title"
                  appendTo="body"
                  filter="true"
                  baseZIndex="999"
                  [disabled]="!validConnectionSelected"
                  (onHide)="validConnection(invoice, 'CLIENT')"
                >
                </p-dropdown>
              </ng-template>
              <ng-template pTemplate="output"> {{ invoice.client.title }} </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-dropdown
                  [(ngModel)]="invoice.supplier"
                  [options]="supplierSuggestion"
                  placeholder="Select supplier"
                  optionLabel="title"
                  appendTo="body"
                  filter="true"
                  baseZIndex="999"
                  [disabled]="!validClientSelected"
                >
                </p-dropdown>
              </ng-template>
              <ng-template pTemplate="output"> {{ invoice.supplier.title }} </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="invoice.amount" required />
              </ng-template>
              <ng-template pTemplate="output"> {{ invoice.amount }} </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="invoice.quantity" required />
              </ng-template>
              <ng-template pTemplate="output"> {{ invoice.quantity }} </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-dropdown
                  [(ngModel)]="invoice.type"
                  [options]="types"
                  placeholder="Select type"
                  optionLabel="title"
                  [appendTo]="'body'"
                  baseZIndex="999"
                ></p-dropdown>
              </ng-template>
              <ng-template pTemplate="output"> {{ invoice.type.title }} </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <div class="flex align-items-center justify-content-center gap-2">
              <button
                *ngIf="!editing"
                [disabled]="!isEditor"
                pButton
                pRipple
                type="button"
                pInitEditableRow
                icon="pi pi-pencil"
                class="p-button-rounded p-button-text"
              ></button>
              <button
                *ngIf="editing"
                pButton
                pRipple
                type="button"
                pSaveEditableRow
                icon="pi pi-check"
                (click)="onCreate(invoice)"
                class="p-button-rounded p-button-text p-button-success mr-2"
              ></button>
              <button
                *ngIf="editing"
                pButton
                pRipple
                type="button"
                pCancelEditableRow
                icon="pi pi-times"
                (click)="onDelete(invoice)"
                class="p-button-rounded p-button-text p-button-danger"
              ></button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="10" class="text-center">Please introduce a breakdown.</td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
  <nb-card-body *ngIf="isLoading"> Your csv is being generated, please be patient.. </nb-card-body>
</nb-card>
