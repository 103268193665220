import { Injectable } from '@angular/core';
import {
  DefaultCost,
  DefaultCostUpdateInput,
  ObjectWhereUniqueInputById,
} from './../interfaces/_index.billing.interfaces';
import { GraphqlApiBillingMutations } from './mutations/_index-billing-mutations';
import { GraphqlApiBillingQueries } from './queries/_index-billing-queries';
import { GqlService } from '@tgx/shared/data-access/graphql';

@Injectable({
  providedIn: 'root',
})
export class DefaultCostsService {
  constructor(private gqlService: GqlService) {}

  async getDefaultCosts(): Promise<DefaultCost[]> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .queryBilling(GraphqlApiBillingQueries.getDefaultCosts, {})
        .then((res: any) => {
          if (res?.defaultCosts) {
            resolve(res.defaultCosts);
          } else {
            reject(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async updateDefaultCost(update: DefaultCostUpdateInput, where: ObjectWhereUniqueInputById): Promise<DefaultCost> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationBilling(GraphqlApiBillingMutations.updateDefaultCost, { data: update, where: where })
        .then((res: any) => {
          if (res?.updateDefaultCost) {
            resolve(res.updateDefaultCost);
          } else {
            reject(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
