import { RootState } from './../interfaces/_index.interfaces';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

import * as FromUser from './../interfaces/store/_index.store';

@Injectable({ providedIn: 'root' })
export class AccessTokenInterceptor implements HttpInterceptor {
  constructor(private store: Store<RootState>) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(FromUser.selectAccessToken).pipe(
      first(),
      switchMap((authorization) => {
        if (authorization) {
          req = req.clone({ setHeaders: { authorization } });
        }
        return next.handle(req);
      }),
    );
  }
}
