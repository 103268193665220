<tgx-spinner [class]="'routingLoading'" [isLoading]="isLoading" [top]="'200px'"></tgx-spinner>
<nb-card size="tiny">
  <nb-card-header>All CM23 Contract Costs</nb-card-header>
  <nb-card-body *ngIf="!isLoading">
    <div class="modal-footer">
      <button class="btn btn-md btn-danger" (click)="getCSV()" [disabled]="isLoading">Download CSV</button>
    </div>
  </nb-card-body>
  <nb-card-body *ngIf="isLoading"> Your csv is being generated, please be patient.. </nb-card-body>
</nb-card>
