export const getMissingConnections = `
{
  getMissingConnections {
    partnerId
    partnerName
    contractId
    partnerType
    partnerSubType
    modelType
    connectionType
    buyerCode
    buyerName
    sellerCode
    sellerName
  }
}

`;
