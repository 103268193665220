import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BillingAccount, BillingAccountContact } from '../../../../interfaces/_index.billing.interfaces';
import { BillingAccountContactService } from '../../../../services/_index-billing.services';
import { NotificationService } from './../../../../../../@core/services/_index-core.services';
import { Table } from 'primeng/table';
import { BillingAccountContactModalComponent } from '../billing-accounts-contact-modal/billing-accounts-contact-modal.component';
import { ModalDeleteComponent } from 'apps/web-admin/src/app/@core/shared/modal-delete/modal-delete.component';

@Component({
  selector: 'tgx-billing-account-contact-table',
  templateUrl: './billing-accounts-contact-table.component.html',
  styleUrls: ['./billing-accounts-contact-table.component.scss'],
})
export class BillingAccountContactTableComponent implements OnChanges {
  @Input() baId: number;
  @Input() isEditor: boolean;
  @Input() selectedBA: BillingAccount;

  @ViewChild('dt2', { static: false }) dataTable2!: Table;

  header = [
    { label: 'ID', field: 'id' },
    { label: 'Email', field: 'email' },
    { label: 'ReceiveInvoices', field: 'receiveInvoices' },
    { label: 'OdooCode', field: 'odooCode' },
    { label: 'CreatedAt', field: 'createdAt' },
    { label: 'UpdatedAt', field: 'updatedAt' },
    { label: 'Options', field: 'Options' },
  ];

  contactsLoadSource: any[];

  contacts: BillingAccountContact[];

  isLoading: boolean;

  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private billingAccountContactService: BillingAccountContactService,
  ) {}

  async ngOnChanges() {
    if (this.baId) {
      await this.getBillingAccountContacts();
    }
  }

  async getBillingAccountContacts() {
    this.isLoading = true;
    this.contactsLoadSource = [];

    await this.billingAccountContactService
      .getBillingAccountContacts(this.baId)
      .then((cnts) => {
        this.contacts = [];
        if (cnts.length > 0) {
          this.contacts = cnts;
        }
        this.buildContacts();
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  buildContacts() {
    if (this.contacts?.length > 0) {
      this.contacts.forEach((cnt) => {
        this.contactsLoadSource.push({
          id: cnt.id,
          email: cnt.email,
          receiveInvoices: cnt.receiveInvoices,
          odooCode: cnt.odooCode,
          createdAt: cnt.createdAt,
          updatedAt: cnt.updatedAt,
        });
      });
      this.contactsLoadSource = [...this.contactsLoadSource];
      this.contactsLoadSource.sort((a, b) => (a.id < b.id ? 1 : -1));
    }
  }

  onEdit(event): void {
    const contact: BillingAccountContact = event;

    const activeModal = this.modalService.open(BillingAccountContactModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = (this.isEditor ? 'Edit ' : 'View ') + 'BillingAccountContact';
    activeModal.componentInstance.billingAccountContact = contact;
    activeModal.componentInstance.baId = this.baId;
    activeModal.componentInstance.isEditor = this.isEditor;

    const self = this;
    activeModal.result.then((res) => {
      // OK
      if (typeof res === 'object') {
        self.getBillingAccountContacts();
      }
    });
  }

  onDelete(event): void {
    const modalRef = this.modalService.open(ModalDeleteComponent, {
      backdrop: 'static',
      container: 'nb-layout',
    });

    modalRef.componentInstance.message = 'Are you sure that you want to delete contact ' + event.email + '?';
    modalRef.componentInstance.title = 'Delete Contact';
    modalRef.componentInstance.buttonOkTitle = 'Delete';

    const self = this;

    modalRef.result
      .then((res) => {
        // on close
        if (res) {
          self.billingAccountContactService
            .deleteBillingAccountContact({ id: event.id })
            .then((rs) => {
              this.notificationService.success('Contact succesfully deleted', 'Success');
              modalRef.close(rs);
              self.getBillingAccountContacts();
            })
            .catch((err) => {
              this.notificationService.handleGatewayAndGraphqlErrors(err);
            });
        }
      })
      .catch((error) => {
        self.notificationService.handleGatewayAndGraphqlErrors(error);
      });
  }
}
