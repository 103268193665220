<div class="modal-header">
  <span>{{ header }}</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <nb-card>
    <nb-card-header> Data </nb-card-header>
    <nb-card-body *ngIf="!isLoading">
      <tgx-spinner [class]="'routingLoading'" [isLoading]="isLoading" [top]="'0px'"></tgx-spinner>
      <form [formGroup]="pluginForm">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="activationDateTime"
                >Activation Date
                <span class="mandatory ml-1">*</span>
              </label>
              <p-calendar
                [appendTo]="'body'"
                baseZIndex="999"
                class="form-control p-0 border-0 w-100"
                formControlName="activationDateTime"
                dateFormat="dd/mm/yy"
                [showIcon]="true"
                inputId="icon"
                [readonlyInput]="true"
                placeholder="Enter a date.."
              ></p-calendar>
            </div>
          </div>
          <div class="col-6" *ngIf="!isCreating">
            <div class="form-group">
              <label class="mb-1" for="endDate">End Date</label>
              <p-calendar
                [appendTo]="'body'"
                baseZIndex="999"
                class="form-control p-0 border-0 w-100"
                formControlName="endDate"
                dateFormat="dd/mm/yy"
                [showIcon]="true"
                inputId="icon"
                [readonlyInput]="true"
                placeholder="Enter a date.."
              ></p-calendar>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="pluginType"
                >PluginType
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="pluginForm.get('pluginType')"
                [errors]="pluginForm.get('pluginType').errors"
              ></tooltip-error>
              <div class="form-group">
                <p-dropdown
                  (onHide)="validDropdown('pluginType')"
                  [options]="pluginTypes"
                  [appendTo]="'body'"
                  baseZIndex="999"
                  formControlName="pluginType"
                  optionLabel="label"
                  filterBy="label"
                >
                  <ng-template pTemplate="selectedItem">
                    <div *ngIf="pluginForm.get('pluginType').value">
                      <div>{{ pluginForm.get('pluginType').value.label }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-ci pTemplate="ci">
                    <div>
                      <div>{{ ci.label }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>
          <div class="col-6" *ngIf="isCX">
            <div class="form-group">
              <label class="mb-1" for="id">CliCode from Inventory (Only ChannelX)</label>
              <input type="text" class="form-control" name="code" formControlName="code" />
            </div>
          </div>
        </div>
      </form>
    </nb-card-body>
    <nb-card-body *ngIf="isLoading">
      <span>Data is being loaded, please wait..</span>
    </nb-card-body>
  </nb-card>
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()">
    {{ ended ? 'Close' : isEditor ? 'Cancel' : 'Close' }}
  </button>
  <button *ngIf="isEditor && !ended" class="btn btn-md btn-primary" [disabled]="!validForm()" (click)="onSave()">
    {{ isCreating ? 'Create' : 'Update' }}
  </button>
</div>
