export enum lifecycle_stage {
  LEAD = 'LEAD',
  OPPORTUNITY = 'OPPORTUNITY',
  OPPORTUNITY_LOST = 'OPPORTUNITY_LOST',
  ONBOARDING_IMPLEMENTATION = 'ONBOARDING_IMPLEMENTATION',
  ONBOARDING_ENGAGEMENT = 'ONBOARDING_ENGAGEMENT',
  ONBOARDING_LOST = 'ONBOARDING_LOST',
  CUSTOMER = 'CUSTOMER',
  EX_CUSTOMER = 'EX_CUSTOMER',
  PARTNER = 'PARTNER',
}

export enum Status {
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

export enum AssetType {
  BANNER = 'BANNER',
  DOCUMENT = 'DOCUMENT',
  ICON = 'ICON',
  LOGO = 'LOGO',
}
export enum PartnerStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Passive = 'PASSIVE',
}

export interface AssetsProfile {
  url: string;
  type: AssetType;
  name?: string;
  id: number;
  createdAt?: Date;
  updatedAt?: Date;
}

export enum seller_integration_type {
  HotelPullTgx = 'HOTEL_PULL_TGX',
  HotelPull = 'HOTEL_PULL_GENERIC',
  HotelPullTgxV2 = 'HOTEL_PULL_TGX_V2',
  HotelPullV2 = 'HOTEL_PULL_GENERIC_V2',
  Distribution = 'DISTRIBUTION',
  HotelPush = 'HOTEL_PUSH_TGX',
  HotelPushGeneric = 'HOTEL_PUSH_GENERIC',
}

export enum buyer_integration_type {
  B2B_VIRTUAL_AGENCY_TOOL = 'B2B_VIRTUAL_AGENCY_TOOL',
  CHANNELX = 'CHANNELX',
  EASY_CONNECT = 'EASY_CONNECT',
  HOTELX = 'HOTELX',
  LEGACY = 'LEGACY',
}

export enum company_type {
  BEDBANK_WHOLESALER = 'BEDBANK_WHOLESALER',
  DMC_DESTINATION_MANAGEMENT_COMPANY = 'DMC_DESTINATION_MANAGEMENT_COMPANY',
  HOTEL_CHAIN = 'HOTEL_CHAIN',
  INDEPENDENT_HOTEL = 'INDEPENDENT_HOTEL',
  OTA_ONLINE_TRAVEL_AGENCY = 'OTA_ONLINE_TRAVEL_AGENCY',
  TOUR_OPERATOR = 'TOUR_OPERATOR',
  TRAVEL_AGENCY = 'TRAVEL_AGENCY',
  TRAVEL_GROUP = 'TRAVEL_GROUP',
  VACATION_RENTALS = 'VACATION_RENTALS',
  CONTENT_PROVIDER = 'CONTENT_PROVIDER',
}

export enum buyer_tech_solution_company_type {
  TECH_PLATFORM = 'TECH_PLATFORM',
}

export enum seller_tech_solution_company_type {
  CHANNEL_MANAGER = 'CHANNEL_MANAGER',
  CRS_CENTRAL_RESERVATION_SYSTEM = 'CRS_CENTRAL_RESERVATION_SYSTEM',
  PMS_PROPERTY_MANAGEMENT_SYSTEM = 'PMS_PROPERTY_MANAGEMENT_SYSTEM',
  TECH_PLATFORM = 'TECH_PLATFORM',
}
