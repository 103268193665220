import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RegionNetworkService {
  constructor(private http: HttpClient) {}

  allRegions(): Observable<any> {
    return this.http.get('https://social.travelgatex.com/rest-api/get-regions');
  }

  createRegion(data: string) {
    const region = { 'region': data };

    return this.http.post('https://social.travelgatex.com/rest-api/add-region', region);
  }

  deleteRegion(data: string) {
    const region = { 'region': data };

    return this.http.delete('https://social.travelgatex.com/rest-api/delete-region', { body: region });
  }
}
