import { gql } from 'apollo-angular';

export const getGroupsByOrg = gql`
  query getGroupsByOrg($code: [ID!]) {
    admin {
      allOrganizations(where: { code_in: $code }) {
        edges {
          node {
            organizationsData {
              isBuyer
              isSeller
              groups(type: PRODUCT) {
                edges {
                  node {
                    groupData {
                      code
                      label
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
